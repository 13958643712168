import { GET_TABS, setSelectedTab } from '../actions/selectedTabActions';
import { put, takeEvery } from 'redux-saga/effects';

export function* getTab(action) {
  try {
    yield put(setSelectedTab(action.payload));
  } catch (e) {
    // error message
  }
}

export default function* tabRootSaga() {
  yield takeEvery(GET_TABS, getTab);
}
