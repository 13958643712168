import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class CellLink extends Component {
  transition = (id, property) => {
    const { transition, item } = this.props;
    transition(id, property, item);
  }

  getRenderValue = (value, column) => {
    if (typeof value === 'number' && column.toFixed) {
      return value.toFixed(2);
    }
    return value;
  }

  render() {
    const { item, column } = this.props;
    const renderValue = item[column.Id] && (item[column.Id].Name || item[column.Id]);

    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        <span
          className='col-span'
          style={{ WebkitBoxOrient: 'vertical' }}
        >
          <span className='item-link item-link-no-hover' onClick={() => this.transition(item.Id || item, column.Id)}>
            {this.getRenderValue(renderValue, column)}
          </span>
        </span>
      </div>
    );
  }
}

CellLink.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
