import moment from 'moment';

export const getTheBiggestDateMilestones = (maxDate, projectMilestones, value, field) => {
  if (field === 'EndDate') {
    const milestones = projectMilestones.reduce((acc, milestones) => {
      return acc.concat(milestones.Milestones);
    }, []);
    if (!milestones.length) {
      return true;
    }
    const milestonesDate = milestones.reduce((prevDate, currentDate) => {
      return moment(currentDate.Milestone).isAfter(prevDate.Milestone) ? currentDate : prevDate;
    }, milestones[0]);
    if (moment(milestonesDate.Milestone).isAfter(maxDate) || moment(milestonesDate.Milestone).isAfter(value)) {
      return false;
    } return true;
  } return true;
};
