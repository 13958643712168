import {
  CHANGE_CLIENT_RATES_COLUMNS_TO_DISPLAY,
  RESET_CLIENT_RATES_FILTERS_VALUE,
  RESET_CLIENT_RATES_LIST_SETTINGS,
  SET_CLIENT_RATES_FILTERS_VALUE,
  SET_CLIENT_RATES_LIST_SETTINGS,
} from '../actions/clientRatesActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Employee',
  sortDirection: 'Up',
  filters: {},
  takeAmountReports: 50,
};

const defaultTextDataForFilters = {
  StartDate: '',
  EndDate: '',
};

export const initialState = {
  listSettings: defaultListSettings,
  textDataForFilters: defaultTextDataForFilters,
  columnsToDisplay: [
    {
      Name: 'Employee', Id: 'Employee', className: 'biggest-col', isObject: true, isSelected: true, necessary: true,
    }, {
      Name: 'Office', Id: 'Office', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Location', Id: 'Location', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Project', Id: 'Project', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Role Type', Id: 'RoleType', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Billable Status', Id: 'BillableStatus', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Role Name', Id: 'Role', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Role Seniority', Id: 'RoleSeniority', className: 'medium-col', isObject: true, isSelected: true,
    }, {
      Name: 'Assignment', Id: 'Assignment', className: 'medium-col', isSelected: true,
    }, {
      Name: 'Currency', Id: 'Currency', className: 'medium-col', isSelected: true,
    }, {
      Name: 'Rate', Id: 'Rate', className: 'medium-col', isSelected: true, necessary: true,
    }, {
      Name: 'Total', Id: 'Total', className: 'medium-col', isSelected: true, necessary: true, isBudget: true,
    },
  ],
};

const clientRatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_RATES_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_CLIENT_RATES_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_CLIENT_RATES_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: {
          ...state.textDataForFilters,
          ...action.payload,
        },
      };
    case RESET_CLIENT_RATES_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: defaultTextDataForFilters,
      };
    case CHANGE_CLIENT_RATES_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    default:
      return state;
  }
};

export default clientRatesReducer;
