import {
  call, put, takeEvery, all,
} from 'redux-saga/effects';
import http from 'services/http';

import { GET_INFO_FOR_ADMIN, setDataForManageUsers } from '../actions/modalActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* openModals() {
  try {
    yield put(startRequest());

    const [employees, securityRoles, securityRoleSubstitutesConfig] = yield all([
      call(http, { method: 'GET', url: 'employees' }),
      call(http, { method: 'GET', url: 'users/security-roles' }),
      call(http, { method: 'GET', url: 'administration/users/security-roles/substitutes-config' }),
    ]);

    yield put(setDataForManageUsers(employees.data.Employees, securityRoles.data, securityRoleSubstitutesConfig.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export default function* adminModalRootSaga() {
  yield takeEvery(GET_INFO_FOR_ADMIN, openModals);
}
