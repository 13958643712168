import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import http from 'services/http';
import {
  setWorkforceReports,
  GET_WORKFORCE_REPORTS,
  GET_FILTERS_WORKFORCE,
  setFiltersWorkforce,
  setWorkforceCounter,
} from '../actions/workforceActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

function* getWorkforceReports(action) {
  try {
    yield put(startRequest());
    const {
      filters, take, skip, searchValue, sortSetting,
    } = action.payload;
    const orderByDesc = sortSetting && sortSetting.sortDirection === 'Up' ? false : true;
    const workforceReports = yield call(http, {
      method: 'GET',
      url: `workforce-reports?Name=${
        encodeURIComponent(searchValue)}&skip=${skip}&take=${take}&SortField=${sortSetting && sortSetting.column}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setWorkforceCounter(workforceReports.data.TotalCount));
    yield put(setWorkforceReports(workforceReports.data.Workforces));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getFilters() {
  try {
    const billabilitystatuses = yield call(http, {
      method: 'GET',
      url: 'role-billability-statuses',
    });
    const assignmenttypes = yield call(http, {
      method: 'GET',
      url: 'role-assignment-types',
    });
    const projectsName = yield call(http, {
      method: 'GET',
      url: 'projects_filter_list',
    });
    const result = {
      billableStatus: billabilitystatuses.data,
      assignmentTypes: assignmenttypes.data,
      projectsName: projectsName.data,
    };
    yield put(setFiltersWorkforce(result));
  } catch (e) {
    // error message
  }
}

export default function* reportsWorkforceSaga() {
  yield takeEvery(GET_WORKFORCE_REPORTS, getWorkforceReports);
  yield takeEvery(GET_FILTERS_WORKFORCE, getFilters);
}
