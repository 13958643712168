import React, { Component } from 'react';
import CustomIcon from 'common/components/icon/Icon';

export class CustomMessage extends Component {
  render() {
    const { customFields } = this.props;
    return (
      <div className='notification-message'>
        <div className='s-alert-box-inner' />
        <CustomIcon iconName={customFields.icon} className='notification-message-icon' />
        <h3>{customFields.message}</h3>
      </div>
    );
  }
}
