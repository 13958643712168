import React from 'react';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import '../redux-form.css';
import { getConfigBasedOnCheckedDates } from 'utils/getConfigBasedOnCheckedDates';

export default class renderDatePicker extends React.Component {
  onChange = date => {
    const {
      input, showConfirmationDialog, showAlertDialog, startDates, endDates, typeCurrentDocument, isStartDate,
      datesForCheckChildContracts, startDateContract, endDateContract,
    } = this.props;
    const config = getConfigBasedOnCheckedDates(
      { startDatesParentContracts: startDates, endDatesParentContracts: endDates },
      datesForCheckChildContracts,
      date,
      typeCurrentDocument,
      isStartDate,
      false,
      startDateContract || endDateContract,
    );
    if (!config) {
      return input.onChange(date);
    }
    if (config.isAlert) {
      showAlertDialog(config.message);
    } else {
      input.onChange(date);
      showConfirmationDialog && showConfirmationDialog(config);
    }
  }

  render() {
    const {
      input, meta, label, isStartDate, isEndDate, setPrevValueOnError, styles, formClassName, datePickerClassName, pickerName, showAsterix, disabled,
      placeholder, minDate, maxDate,
    } = this.props;
    return (
      <div className={styles.container}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              showAsterix && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          <DatePicker
            formClassName={formClassName}
            datePickerClassName={datePickerClassName}
            disabled={disabled}
            value={input.value}
            onChange={this.onChange}
            isStartDate={isStartDate}
            isEndDate={isEndDate}
            name={pickerName}
            placeholder={placeholder}
            setPrevValueOnError={setPrevValueOnError}
            showCustomHelperText={false}
            minVal={minDate}
            maxVal={maxDate}
          />
          {
            (meta.invalid && meta.error !== 'Value required') && (
              <div className={styles.fieldError}>
                <span>{meta.error}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
