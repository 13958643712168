export const getCorrectSkype = (skype) => {
  const onlyWordAndDigits = /((^(?!\d{1})(\w{0}))(([a-zA-Z0-9]+)(([:]{0,1})([a-zA-Z0-9.]+)?)(([_]{0,1})([a-zA-Z0-9.]+)?)))/;
  const findExtraMarks = /[!@#$%^&*()]+/;
  const checkLength = skype.length >= 4 || skype.length === 0 ? true : false;
  const result = {
    value: skype.trim().toLowerCase(),
    valid: checkLength && onlyWordAndDigits.test(skype) && !findExtraMarks.test(skype),
  };
  return result;
};
