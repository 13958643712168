import { getId } from 'services/location';

export const getDeleteConfig = (deleteUrlData, searchUpdateSaga, entityId = false, customUrl = false, projectId) => {
  return {
    entityId: entityId ? entityId : getId(),
    deleteUrlData,
    searchUpdateSaga,
    customUrl,
    projectId,
  };
};
