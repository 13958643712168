export const SET_VERSION = '@version/SET_VERSION';
export const GET_VERSION = '@version/GET_VERSION';

export const setVersion = (version) => {
    return {
        type: SET_VERSION,
        payload: version,
    }
}

export const getVersion = () => {
    return {
        type: GET_VERSION,
    }
}
