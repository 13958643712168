export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function promiseDebounce(func, wait) {
  let timeout = null;
  let resolves = [];

  return function process(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const result = func(...args);
      resolves.forEach(r => r(result));
      resolves = [];
    }, wait);

    return new Promise(r => {
      resolves.push(r);
    });
  };
}
