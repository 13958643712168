import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import {
  loadManageCertificates,
  putManageCertificates,
  postManageCertificates,
} from '../../../actions/adminCertificatesActions';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageCertificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Certificates', isSelected: true, Id: 'Name',
        },
      ],
      showAddCertificateFields: false,
      addCertificate: { Name: '' },
      blockAddProjectType: false,
      blockSaveType: false,
    };
  }

  componentDidMount() {
    const { loadManageCertificates } = this.props;
    loadManageCertificates();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewCertificate = () => {
    const { blockAddProjectType } = this.state;
    if (!blockAddProjectType) {
      this.setState({
        showAddCertificateFields: true,
      });
    }
  }

  setNewCertificate = ({ target }) => {
    const { addCertificate } = this.state;
    this.setState({
      addCertificate: {
        ...addCertificate,
        [target.name]: target.value,
      },
    }, this.newTypeValidation);
  }

  newTypeValidation = () => {
    const { addCertificate } = this.state;
    this.setState({
      blockSaveType: !!addCertificate.Name.trim(),
      blockAddProjectType: !!addCertificate.Name.trim(),
    });
  };

  newTypeCancel = () => {
    this.setState({
      addCertificate: { Name: '' },
      showAddCertificateFields: false,
      blockSaveType: false,
      blockAddProjectType: false,
    });
  }

  newCertificateApply = () => {
    const { postManageCertificates } = this.props;
    const { blockSaveType, addCertificate } = this.state;
    if (blockSaveType) {
      postManageCertificates(addCertificate);
      this.setState({
        addCertificate: { Name: '' },
        showAddCertificateFields: false,
        blockSaveType: false,
        blockAddProjectType: false,
      });
    }
  }

  deleteEntity = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `certificates/${element.Id}`,
      searchUpdateSaga: 'lookup_certificates',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageCertificates } = this.props;
    putManageCertificates(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  render() {
    const { certificates, listSettings } = this.props;
    const {
      columnsToDisplay, showAddCertificateFields, addCertificate, blockAddProjectType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsCertificates')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewCertificate}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search Certificates'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={certificates ? recursiveSearch(sortList(certificates, sortColumn, sortDirection), searchValue) : []}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteEntity}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {showAddCertificateFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addCertificate}
              onChangeNewDocumentObj={this.setNewCertificate}
              disableSaveDocument={blockAddProjectType}
              newDocumentCancel={this.newTypeCancel}
              newDocumentApply={this.newCertificateApply}
            />
          </div>
        </div> : null}
      </div>
    );
  }
}

ManageCertificates.propTypes = {
  certificates: PropTypes.array.isRequired,
  loadManageCertificates: PropTypes.func.isRequired,
  putManageCertificates: PropTypes.func.isRequired,
  postManageCertificates: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  certificates: store.adminManageLookups.certificates,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageCertificates,
  putManageCertificates,
  postManageCertificates,
  showDialog,
  setListSettingsLookups,
})(ManageCertificates);
