import { call, put, takeEvery } from 'redux-saga/effects';
import { finishRequest, startRequest } from '../../../common/actions/loadingActions';
import http from '../../../services/http';
import {
  GET_MANAGE_BACK_FILL_TYPES,
  POST_MANAGE_BACK_FILL_TYPES,
  PUT_MANAGE_BACK_FILL_TYPES,
  setBackFillTypes,
} from '../actions/adminManageBackFillTypes';

export function* getBackFillTypes() {
  try {
    yield put(startRequest());
    const backFillTypes = yield call(http, {
      method: 'GET',
      url: 'back-fill-types',
    });

    yield put(setBackFillTypes(backFillTypes.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* putBackFillType(action) {
  try {
    yield put(startRequest());

    yield call(http, {
      method: 'PUT',
      url: 'back-fill-types',
      data: action.payload,
    });
    yield call(getBackFillTypes);
  } finally {
    yield put(finishRequest());
  }
}

export function* postBackFillType(action) {
  try {
    yield put(startRequest());

    yield call(http, {
      method: 'POST',
      url: 'back-fill-types',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(getBackFillTypes);
  } finally {
    yield put(finishRequest());
  }
}

export default function* adminManageBackFillTypes() {
  yield takeEvery(GET_MANAGE_BACK_FILL_TYPES, getBackFillTypes);
  yield takeEvery(PUT_MANAGE_BACK_FILL_TYPES, putBackFillType);
  yield takeEvery(POST_MANAGE_BACK_FILL_TYPES, postBackFillType);
}
