import React from 'react';
import Checkbox from 'common/components/form-items/checkbox/checkbox';
import '../redux-form.css';

export default class renderCheckbox extends React.Component {
  onChange = () => {
    const { input } = this.props;
    input.onChange(!input.value);
  }

  render() {
    const {
      input, meta, disabled, label, styles, isRequired, checkboxContainerStyle,
    } = this.props;
    return (
      <div className={styles.container}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              isRequired && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          <Checkbox
            onClick={this.onChange}
            checked={input.value || false}
            disabled={disabled}
            checkboxContainerStyle={checkboxContainerStyle}
          />
          {
            (meta.invalid && meta.error !== 'Value required') && (
              <div className={styles.fieldError}>
                <span>{meta.error}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
