/* eslint-disable indent */
import React from 'react';
import Select from 'common/components/form-items/select/select';
import { svgEditPencil } from 'services/svgEditPencil';
import classNames from 'classnames';
import '../../redux-form.css';
import { Tooltip, Typography } from '@material-ui/core';
import CustomIcon from '../../../icon/Icon';

export default class renderTogglingSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  toggleEditMode = (editMode) => this.setState({ editMode });

  onChange = (data) => {
    const { input, options } = this.props;
    input.onChange({
      Id: data.target.value,
      Name: options.find(item => item.Id === data.target.value).Name,
    });
    input.onBlur();
    this.toggleEditMode(false);
  }

  clearValue = () => {
    const { input } = this.props;
    input.onChange({ Id: '', Name: '' });
    this.setState({
      editMode: false,
    });
  }

  onBlur = () => {
    const { input } = this.props;
    input.onBlur();
    this.toggleEditMode(false);
  }

  render() {
    const { editMode } = this.state;
    const {
      input, meta, valueToShow, options, isEditable, label, styles, inputProps, showClear, isOptionObject, renderIcon,
      placeholder, showAsterix, showAsterixOnFocus, showValueRequired, valueId, maxHeight, showInfoTooltip, showInfoTooltipMessage,
      isSaved = true,
    } = this.props;
    const isAsterixVisible = showAsterixOnFocus ? (editMode && isEditable) : showAsterix;
    const isValueRequiredVisible = showValueRequired ? meta.invalid : (meta.invalid && meta.error !== 'Value required');
    return (
      <div
        className={styles.container}
        onMouseLeave={this.onBlur}
      >
        {
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          label && <label className={styles.label} onClick={() => this.toggleEditMode(false)}>
            <span>{label}</span>
            {
              isAsterixVisible && <span className='redux-form__required'> *</span>
            }
            {
              showInfoTooltip && !isAsterixVisible
              && <Tooltip title={<Typography>{showInfoTooltipMessage}</Typography>}>
                <div className='info-tooltip'>
                  <CustomIcon iconName='question-mark' />
                </div>
              </Tooltip>
            }
          </label>
        }
        <div className={styles.field}>
          {
            editMode && isEditable
              ? <Select
                value={input.value.Id || valueId}
                selectOptions={options}
                onChange={this.onChange}
                onBlur={this.onBlur}
                showClear={showClear}
                clearValue={this.clearValue}
                inputProps={inputProps}
                isOptionObject={isOptionObject}
                placeholder={placeholder}
                onMouseLeave={this.onBlur}
                MenuProps={{
                  MenuListProps: {
                    onMouseLeave: this.onBlur,
                  },
                  PaperProps: {
                    style: {
                      maxHeight: maxHeight ? maxHeight : 300,
                    }
                  }
                }}
              /> : (
                <div className={styles.fieldView}>
                  <div
                    onClick={() => this.toggleEditMode(true)}
                    className={classNames(
                      styles.fieldContent,
                      { 'field--editable': isEditable },
                      { 'field--invalid': isValueRequiredVisible || !isSaved },
                    )}
                  >
                    {
                      renderIcon && (
                        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' style={{ 'margin': '0 5px' }} xmlns='http://www.w3.org/2000/svg'>
                          {renderIcon(input.value.Name || '')}
                        </svg>
                      )
                    }
                    <span>{valueToShow}</span>
                    {isEditable && svgEditPencil(input.name)}
                  </div>
                  {
                    isValueRequiredVisible && (
                      <div className={styles.fieldError}>
                        <span>{meta.error}</span>
                      </div>
                    )
                  }
                  {
                    !isSaved && (
                      <div className={styles.fieldError}>
                        <span>Value wasn't saved</span>
                      </div>
                    )
                  }
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
