import {
  CLOSE_MANAGE_USER_CONFIRMATION,
  REQUEST_CONFLICTING_USER_MANAGERIAL_INFO, REQUEST_CONFLICTING_USER_MANAGERIAL_INFO_SUCCESS,
  SHOW_MANAGE_USER_CONFIRMATION,
} from '../actions/editUserConfirmationActions';

export const DELETE_MANAGE_KIND = 'delete';
export const UPDATE_MANAGE_KIND = 'update';

const initialState = {
  isOpen: false,
  userId: undefined,
  conflictingUserManagerialInfo: [],
  manageKind: DELETE_MANAGE_KIND,
  updatedPermissions: undefined,
  updatedSecurityRoleIds: [],
  updatedOfficeIds: [],
};

const manageUserConfirmationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_MANAGE_USER_CONFIRMATION:
      return {
        ...state,
        isOpen: true,
        userId: action.payload.userId,
        manageKind: action.payload.manageKind,
        updatedPermissions: action.payload.updatedPermissions || initialState.updatedPermissions,
        updatedSecurityRoleIds: action.payload.updatedSecurityRoleIds || initialState.updatedSecurityRoleIds,
        updatedOfficeIds: action.payload.updatedOfficeIds || initialState.updatedOfficeIds,
      };
    case CLOSE_MANAGE_USER_CONFIRMATION:
      return initialState;
    case REQUEST_CONFLICTING_USER_MANAGERIAL_INFO:
      return {
        ...state,
        conflictingUserManagerialInfo: initialState.conflictingUserManagerialInfo,
      };
    case REQUEST_CONFLICTING_USER_MANAGERIAL_INFO_SUCCESS:
      return {
        ...state,
        conflictingUserManagerialInfo: action.payload,
      };
    default:
      return state;
  }
};

export default manageUserConfirmationReducer;
