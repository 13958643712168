import moment from 'moment';
import { MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS } from 'utils/const-variable';

export const getConfigForMessageDialog = (value, field, theBiggestDateRange, theBiggestStartDateForContracts, isCorrectStartAndEndDate) => {
  const { forStartDate, forEndDate } = theBiggestDateRange;
  switch (field) {
    case 'StartDate':
      return !isCorrectStartAndEndDate ? {
        isNewValueWrong: true,
        message: MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS,
      }
        : forStartDate && {
          isNewValueWrong: moment(value).isAfter(forStartDate.contract.StartDate, 'day'),
          message: `There are ${forStartDate.type} with start date less than project start date.`,
        };
    case 'EndDate':
      return !isCorrectStartAndEndDate && moment(value).isValid() ? {
        isNewValueWrong: true,
        message: MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS,
      } : forEndDate && forEndDate.contract.EndDate
        ? {
          isNewValueWrong: moment(value).isBefore(forEndDate.contract.EndDate, 'day'),
          message: `There are ${forEndDate.type} with end date greater than project end date.`,
        } : (
          theBiggestStartDateForContracts
          && moment(value).isBefore(theBiggestStartDateForContracts, 'day')
        )
          ? {
            isNewValueWrong: true,
            message: 'There are contracts with start date greater than project end date.',
          } : null;
    default:
      return null;
  }
};
