import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MultiSelect from 'common/components/form-items/multi-select/multi-select';

const multiSelectStyles = {
  relcontClass: 'field__multi-select-rel-cont',
  contClass: 'field__multi-select-cont',
  optionContClass: 'field__multi-select-option-cont',
};

export class CellMultiSelect extends Component {
  constructor(props) {
    super(props);
    const { item, column } = this.props;
    this.state = {
      value: item[column.Id],
      isMultiSelect: false,
    };
  }

  toogleViewCell = () => {
    const { isMultiSelect } = this.state;
    this.setState({
      isMultiSelect: !isMultiSelect,
    });
  }

  handleChange = (data) => {
    const { value } = this.state;
    const newValue = value.some(id => id === data) ? value.filter(id => id !== data) : value.concat([data]);
    this.setState({
      value: newValue,
    });
  }

  onBlur = () => {
    const { column, config, item } = this.props;
    const { value } = this.state;
    const { valueSetter } = config[column.Id];
    const newItem = {
      ...item,
      [column.Id]: value,
    };
    valueSetter(newItem);
    this.toogleViewCell();
  }

  viewItemTitle = () => {
    const { item, column, config } = this.props;
    const { options } = config[column.Id];
    const title = options.reduce((acc, option) => {
      item[column.Id] && item[column.Id].forEach(elem => {
        if (elem === option.Id) {
          acc.push(option.Name);
        }
      });
      return acc;
    }, []);
    return title.length ? title.join(',') : '---';
  }

  render() {
    const {
      item,
      column,
      isHaveRights,
      config,
    } = this.props;
    const {
      value,
      isMultiSelect,
    } = this.state;
    const { options } = config[column.Id];
    return (
      <div
        className={classNames('c-list__item-column c-skill__item', column.className ? column.className : 'small-col')}
      >
        {
          isMultiSelect && isHaveRights ? (
            <div className='field__multi-select-cell'>
              <MultiSelect
                relcontClass={multiSelectStyles.relcontClass}
                contClass={multiSelectStyles.contClass}
                optionContClass={multiSelectStyles.optionContClass}
                selected={value}
                onChange={this.handleChange}
                onMouseLeave={this.onBlur}
                options={options}
                placeholder='Select'
              />
            </div>
          ) : (
            <span
              onClick={this.toogleViewCell}
              className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
            >
              {item ? this.viewItemTitle() : null}
            </span>
          )
        }
      </div>
    );
  }
}

CellMultiSelect.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
