import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
    GET_EMPLOYEE_TRAININGS,
    setEmployeeTrainings,
    CREATE_NEW_TRAINING,
    UPDATE_TRAINING,
    DELETE_TRAINING,
    GET_EMPLOYEE_TRAINING_CLIENTS,
    setEmployeeTrainingClients
  } from '../actions/adminManageEmployeeTrainings';

import {
    startRequest,
    finishRequest,
  } from 'common/actions/loadingActions';

  export function* loadEmployeeTrainings() {
    try {
      yield put(startRequest());
      const roleTypes = yield call(http, {
        method: 'GET',
        url: 'trainings',
      });
  
      yield put(setEmployeeTrainings(roleTypes.data));
      yield put(finishRequest());
    } catch (e) {
      yield put(finishRequest());
    }
  }
  
  export function* addTraining(action) {
    try {
      const data = action.payload;
      yield call(http, {
        method: 'POST',
        url: 'trainings',
        data,
      });
      yield call(loadEmployeeTrainings);
    } catch (e) {
      // error message
    }
  }
  
  export function* putTraining(action) {
    yield call(http, {
      method: 'PUT',
      url: `trainings/${action.payload.trainingId}`,
      data: action.payload.data,
    });
    yield call(loadEmployeeTrainings);
  }

  export function* deleteTraining(action) {
    yield call(http, {
      method: 'DELETE',
      url: `trainings/${action.payload.trainingId}`,
      data: { Name: action.payload.Name },
    });
    yield call(loadEmployeeTrainings);
  }

  export function* loadEmployeeTrainingClients() {
    try {
      yield put(startRequest());
      const roleTypes = yield call(http, {
        method: 'GET',
        url: 'clients',
      });
  
      yield put(setEmployeeTrainingClients(roleTypes.data));
      yield put(finishRequest());
    } catch (e) {
      yield put(finishRequest());
    }
  }
  
  export default function* adminManageEmployeeTrainingsSaga() {
    yield takeEvery(GET_EMPLOYEE_TRAININGS, loadEmployeeTrainings);
    yield takeEvery(CREATE_NEW_TRAINING, addTraining);
    yield takeEvery(UPDATE_TRAINING, putTraining);
    yield takeEvery(DELETE_TRAINING, deleteTraining);
    yield takeEvery(GET_EMPLOYEE_TRAINING_CLIENTS, loadEmployeeTrainingClients);
  }