import React from 'react';
import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import isObjectNotEquals from 'utils/object-comparison';
import history from 'utils/history';
import {
  NON_BILLABLE_TRAINING, TIME_AND_MATERIAL, FINISHED, MONTH_AMOUNT_FOR_FIVE_YEARS, MILESTONE_BACKUP_USED_COLUMN_NAME,
} from 'utils/const-variable';
import { getStatusesForRequiredComment } from 'pages/projects/components/utils/createArrayWithStatusMatch';
import { EditAssociatedRoles } from 'pages/projects/components/edit-associated-roles/edit-associated-roles';
import { editRole } from 'pages/projects/actions/roleModalActions';
import { addNewAmendment, editCurrentAmendment } from 'pages/projects/actions/contractsActions';
import { getCorrectDataForEditRole } from 'services/getCorrectDataForEditRole.js';
import {
  required, validateDate, complexRequired, validateMilestone,
} from 'services/redux-form-validators.js';
import { getAllCheckedRolesAndEmployees } from 'utils/getAllCheckedRolesAndEmployees.js';

import renderMilestoneList from '../components/milestone-list';
import renderTextField from '../components/text-field';
import renderSelect from '../components/select';
import renderDatePicker from '../components/date-picker';
import renderDocumentList from '../components/document-list';
import renderSearchDropdown from '../components/search-dropdown';
import Button from '../components/button';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { getChangeConfigForPatchRequests, combinedConfigsForPatchRequests, getConfigBasedOnCheckedDates } from 'utils/getConfigBasedOnCheckedDates';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { addAmendmentToPartialContract, cancelImport } from 'pages/projects/actions/importContractAction';

import '../redux-form.css';

const defaultStyles = {
  container: 'redux-form__field-container edit-SOW__simple-container',
  label: 'redux-form__SOW--label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const validateApprover = (value, values, props, field) => {
  const condition = !(props.projectType && props.projectType.Name === NON_BILLABLE_TRAINING
    && props.SOWDetails && props.SOWDetails.Type && props.SOWDetails.Type.Name === TIME_AND_MATERIAL);
  return complexRequired(value, values, props, field, condition);
};

const validateStatusComments = (value, values, props, field) => {
  const isMatchObject = { docStatus: values.Status && values.Status.Id, approveStatus: values.ApprovalStatus && values.ApprovalStatus.Id };
  const condition = !!getStatusesForRequiredComment(props.allStatuses, props.allApprovalStatuses).find((item) => !isObjectNotEquals(item, isMatchObject));
  return complexRequired(value, values, props, field, condition);
};

const additionalConditionForContract = (role, StartDateContract, EndDateContract) => moment(role.StartDate).isSameOrAfter(StartDateContract, 'day')
      && moment(role.EndDate).isSameOrBefore(EndDateContract, 'day');

class ProjectEditAmendmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      associatedRoles: [],
      showAssociatedRolesModal: false,
      isNeedToChangeDate: false,
      showConfirmationDialog: false,
      messageForConfirmDialog: '',
      configPatchRequestsForConfirmDialog: null,
      importContractDatesCheck: [],
    };

    this.showAsterix = {
      Approver: () => {
        const { projectType, SOWDetails } = this.props;
        if (SOWDetails && projectType && SOWDetails.Type && SOWDetails.Type.Name) {
          return projectType.Name !== NON_BILLABLE_TRAINING || SOWDetails.Type.Name !== TIME_AND_MATERIAL;
        } return true;
      },
      StatusComments: () => {
        const {
          allStatuses, allApprovalStatuses, Status, ApprovalStatus,
        } = this.props;
        const isMatchObject = {
          docStatus: Status && Status.Id,
          approveStatus: ApprovalStatus && ApprovalStatus.Id,
        };
        return !!getStatusesForRequiredComment(allStatuses, allApprovalStatuses).find((item) => !isObjectNotEquals(item, isMatchObject));
      },
    };
  }

  componentDidMount() {
    const {
      data, initialize, importContract,
    } = this.props;
    initialize(data);
    const importContractDatesCheck = [];
    if (Object.keys(importContract).length) {
      const startDates = this.getStartDatesForDatePicker();
      const endDates = this.getEndDatesForDatePicker();
      const isNeedToChangeEndDate = data.EndDate && endDates.some(item => item.date && (new Date(item.date) < new Date(data.EndDate)));
      const isNeedToChangeStartDate = data.StartDate && startDates.some(item => item.date && (new Date(item.date) > new Date(data.StartDate)));
      if (isNeedToChangeEndDate) {
        const config = getConfigBasedOnCheckedDates(
          { startDatesParentContracts: this.getStartDatesForDatePicker(), endDatesParentContracts: this.getEndDatesForDatePicker() },
          null,
          new Date(data.EndDate),
          'Amendment',
          false,
          false,
          new Date(data.EndDate),
        );
        config && importContractDatesCheck.push(config);
      }
      if (isNeedToChangeStartDate) {
        const config = getConfigBasedOnCheckedDates(
          { startDatesParentContracts: this.getStartDatesForDatePicker(), endDatesParentContracts: this.getEndDatesForDatePicker() },
          null,
          new Date(data.StartDate),
          'Amendment',
          true,
          false,
          new Date(data.StartDate),
        );
        config && importContractDatesCheck.push(config);
      }
    }
    this.setState({
      importContractDatesCheck,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      configPatchRequestsForConfirmDialog, isNeedToChangeDate, importContractDatesCheck, showConfirmationDialog,
    } = this.state;
    const {
      data: { Milestones: existMilestones }, Milestones, change, StartDate, EndDate, showAlertDialog,
    } = this.props;
    if (!prevProps.data.Milestones.length && JSON.stringify(prevProps.data.Milestones) !== JSON.stringify(existMilestones)) {
      change('Milestones', existMilestones);
    }
    if (importContractDatesCheck.length && !showConfirmationDialog) {
      this.showConfirmationDialog(importContractDatesCheck.at(-1));
      this.setState({
        importContractDatesCheck: importContractDatesCheck.slice(0, -1),
      });
    }
    if (prevProps.StartDate !== StartDate || prevProps.EndDate !== EndDate) {
      const isStartDateChanged = prevProps.StartDate !== StartDate;
      const isEndDateChanged = prevProps.EndDate !== EndDate;
      const endDateForMilestones = EndDate || moment(StartDate).clone().add(MONTH_AMOUNT_FOR_FIVE_YEARS, 'months').format();
      const milestonesOutOfRange = Milestones.filter(milestone => {
        return ((isStartDateChanged
          && StartDate && !moment(moment(StartDate).startOf('month').format()).isBefore(moment(milestone.Milestone, 'MMMM, YYYY').days(15).format()))
          || (isEndDateChanged
            && endDateForMilestones
              && !moment(moment(endDateForMilestones).endOf('month').format()).isAfter(moment(milestone.Milestone, 'MMMM, YYYY').days(15).format())));
      });
      if (milestonesOutOfRange.length) {
        showAlertDialog(`${moment(milestonesOutOfRange[0].Milestone).format('MMMM YYYY')} milestone is out of range. Please, delete it first.`);
        isStartDateChanged && change('StartDate', null);
        isEndDateChanged && change('EndDate', null);
      }
      if (!EndDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'endDate'),
        });
      }
      if (!StartDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'startDate'),
        });
      }
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  onChange = (data, value, prevValue, field) => {
    const { change } = this.props;

    switch (field) {
      case 'Company':
        change('Approver', '');
        break;
      default: break;
    }
  }

  checkForAssociatedRoles = () => {
    const {
      StartDate, EndDate, initial, isOnlyActiveRoles, SOWDetails, Status,
    } = this.props;
    if (initial.Status && initial.Status.Id !== Status.Id && Status.Name === FINISHED) {
      this.changeAssociatedPORoleStatus();
      return false;
    }
    if (initial.StartDate !== StartDate || initial.EndDate !== EndDate) {
      this.changeAssociatePORolesDate();
      return false;
    }
    this.editAmendment(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  changeAssociatedPORoleStatus = () => {
    const {
      RolesForContarct, Id, isOnlyActiveRoles, SOWDetails,
    } = this.props;
    const associatedRoles = RolesForContarct.filter(role => role.SowPoId === Id);
    const allCheckedRolesAndEmployees = getAllCheckedRolesAndEmployees(associatedRoles);
    if (allCheckedRolesAndEmployees.length) {
      this.setState({
        associatedRoles: allCheckedRolesAndEmployees,
        showAssociatedRolesModal: true,
      });
      return false;
    }
    this.editAmendment(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  changeAssociatePORolesDate = () => {
    const {
      RolesForContarct, Id, isOnlyActiveRoles, SOWDetails, StartDate, EndDate,
    } = this.props;
    const associatedRoles = RolesForContarct.filter(role => role.SowPoId === Id);
    const checkedRoles = associatedRoles.reduce((roles, role) => {
      const rolesWithCheckedEmployees = role.Employees.reduce((employees, employee) => {
        employees.push({
          ...employee,
          checked: employee.Status.Name !== 'Removed',
          disabledItem: employee.Status.Name === 'Removed' || !additionalConditionForContract(employee, StartDate, EndDate),
        });
        return employees;
      }, []);
      roles.push({
        ...role,
        checked: role.Status.Name !== 'Removed',
        Employees: [...rolesWithCheckedEmployees],
        disabledItem: role.Status.Name === 'Removed' || !additionalConditionForContract(role, StartDate, EndDate),
      });
      return roles;
    }, []);
    if (checkedRoles.length) {
      this.setState({
        associatedRoles: checkedRoles,
        showAssociatedRolesModal: true,
      });
      return false;
    }
    this.editAmendment(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  closeAssociatedRolesModal = () => {
    this.setState({ showAssociatedRolesModal: false });
  }

  applyAssociatedRoles = (roles) => {
    const {
      StartDate, EndDate, isOnlyActiveRoles, editRole, SOWDetails, Status, initial,
    } = this.props;
    if (roles.length) {
      const checkedRoles = roles.filter(role => role.checked);
      if (checkedRoles.length) {
        const startDateForUpdateRoles = !moment(StartDate).isSame(initial.StartDate) ? StartDate : null;
        const endDateForUpdateRoles = !moment(EndDate).isSame(initial.EndDate) ? EndDate : null;
        const rolesToUpdate = getCorrectDataForEditRole(checkedRoles, startDateForUpdateRoles, endDateForUpdateRoles, Status, SOWDetails.Project.Id);
        rolesToUpdate.forEach(role => editRole(role, !isOnlyActiveRoles));
      }
    }
    this.closeAssociatedRolesModal();
    this.editAmendment(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  applyForm = () => {
    const {
      Id, isOnlyActiveRoles, SOWDetails, cancelContractImport, importContract,
    } = this.props;
    Id ? this.checkForAssociatedRoles()
      : this.addAmendment(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
    Object.keys(importContract).length && cancelContractImport(SOWDetails.Project.Id);
  }

  addAmendment = (projectId, SOWId, isOnlyActiveRoles) => {
    const { addNewAmendment } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    addNewAmendment(projectId, SOWId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
    history.push({
      pathname: `/projects/${projectId}`,
    });
  }

  editAmendment = (projectId, SOWId, isOnlyActiveRoles) => {
    const { editAmendment, location } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    const saveCallback = (location.state && location.state.saveCallback) || `/projects/${projectId}`;

    editAmendment(
      projectId,
      SOWId,
      isOnlyActiveRoles,
      isNeedToChangeDate,
      configPatchRequestsForConfirmDialog,
      saveCallback,
    );
  }

  closeConfirmationDialog = () => {
    const { change } = this.props;
    const { typeDateForDeleteIfUserCloseConfirmationDialog } = this.state;
    this.setState({
      showConfirmationDialog: false,
    });
    change(typeDateForDeleteIfUserCloseConfirmationDialog, null);
  }

  showConfirmationDialog = (configForConfirmDialog) => {
    const { configPatchRequestsForConfirmDialog } = this.state;
    this.setState({
      showConfirmationDialog: true,
      messageForConfirmDialog: configForConfirmDialog.message,
      typeDateForDeleteIfUserCloseConfirmationDialog: configForConfirmDialog.typeChangedDate,
      configPatchRequestsForConfirmDialog:
        combinedConfigsForPatchRequests(configForConfirmDialog.configForPatchRequests, configPatchRequestsForConfirmDialog),
    });
  }

  addNewMilestone = (milestones) => {
    const { change } = this.props;
    change('Milestones', milestones);
  }

  onConfirmDialog = () => {
    const {
      showAlertDialog, RolesForContarct, SOWDetails,
    } = this.props;
    const isRolesUnderParentContract = RolesForContarct.some((role) => role.SowPoId === SOWDetails.Id);
    this.setState({
      isNeedToChangeDate: true,
      showConfirmationDialog: false,
    }, () => {
      isRolesUnderParentContract && showAlertDialog('Please note that Role and Employee dates will not be updated in parent contracts ');
    });
  }

  getStartDatesForDatePicker = () => {
    const { SOWDetails, projectDetails } = this.props;
    return [{ date: projectDetails.StartDate, type: 'Project', id: SOWDetails.Project.Id },
      {
        date: SOWDetails.StartDate, type: 'SOW', id: SOWDetails.Id, SOWId: SOWDetails.Id, projectId: SOWDetails.Project.Id,
      }];
  }

  getEndDatesForDatePicker = () => {
    const { SOWDetails, projectDetails } = this.props;
    return [{ date: projectDetails.EndDate, type: 'Project', id: SOWDetails.Project.Id },
      {
        date: SOWDetails.EndDate, type: 'SOW', id: SOWDetails.Id, SOWId: SOWDetails.Id, projectId: SOWDetails.Project.Id,
      }];
  }

onSaveAndCreateRoles = () => {
  const {
    addImportAmendment, SOWDetails, isOnlyActiveRoles,
  } = this.props;
  const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
  addImportAmendment(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
}

onCancelClick = () => {
  const { cancelContractImport, SOWDetails } = this.props;
  cancelContractImport(SOWDetails.Project.Id);
}

render() {
  const {
    showAssociatedRolesModal, associatedRoles, showConfirmationDialog, messageForConfirmDialog,
  } = this.state;
  const {
    disabled, allApprovalStatuses, allClients, allApprovers, allStatuses, columnsToDisplay, viewFinancialInfo, allDocumentTypes, Contract,
    ApprovalStatus, Company, Approver, StartDate, EndDate, Status, Links, formErrors, showAlertDialog, Id,
    BudgetComments, handleSubmit, StatusComments, ProjectEndDate, Milestones, SOWDetails, allRolesForContract, importRoles,
  } = this.props;
  const isSOWTypeActualTime = SOWDetails.Type && SOWDetails.Type.Name === TIME_AND_MATERIAL;
  const milestonesListColumnsToDisplay = isSOWTypeActualTime ? columnsToDisplay.milestonesList
    : columnsToDisplay.milestonesList.filter(column => column.Name !== MILESTONE_BACKUP_USED_COLUMN_NAME);

  return (
    <form className='redux-form height-100pc' onSubmit={handleSubmit}>
      {
        showAssociatedRolesModal ? (
          <EditAssociatedRoles
            items={associatedRoles}
            closeModal={this.closeAssociatedRolesModal}
            apply={this.applyAssociatedRoles}
            startDateContract={StartDate}
            endDateContract={EndDate}
            allRoles={allRolesForContract}
            idContract={Id}
          />
        ) : null
      }
      {
        showConfirmationDialog ? (
          <ConfirmationDialog
            dialogHeader='CHANGE DATE'
            dialogTitle={messageForConfirmDialog}
            closeButtonTitle='Cancel'
            confirmButtonTitle='OK'
            closeDialog={this.closeConfirmationDialog}
            confirmDialog={this.onConfirmDialog}
          />
        ) : null
      }
      <div>
        <div className='redux-form__row justify-start'>
          <Field
            name='Contract'
            value={Contract}
            component={renderTextField}
            label='Amendment Number'
            placeholder='Set Amendment Number'
            maxlength='100'
            styles={defaultStyles}
            disabled={disabled}
            validate={required}
            showAsterix
            formClassName='edit-SOW__item-form'
            inputClassName='edit-SOW__text-field'
          />
          <Field
            name='ApprovalStatus'
            value={ApprovalStatus}
            component={renderSelect}
            options={allApprovalStatuses}
            label='Amendment Approval Status'
            placeholder='Set Amendment Approval Status'
            styles={defaultStyles}
            disabled={disabled}
            isOptionObject
            extendedValue
            inputProps={{ id: 'amendment_approval_status' }}
            validate={required}
            showAsterix
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__select'
          />
        </div>
        <div className='redux-form__row justify-start'>
          <Field
            name='StartDate'
            value={StartDate}
            component={renderDatePicker}
            validate={[required, validateDate.startDate, validateDate.isValid]}
            disabled={disabled}
            showAsterix
            isStartDate
            setPrevValueOnError
            placeholder='--/--/--'
            label='Amendment Start Date'
            pickerName='startDatePO'
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            datePickerClassName='edit-SOW__date-picker'
            showAlertDialog={showAlertDialog}
            showConfirmationDialog={this.showConfirmationDialog}
            typeParent={{
              forStartDate: 'project',
              forEndDate: 'project',
            }}
            endDates={this.getEndDatesForDatePicker()}
            startDates={this.getStartDatesForDatePicker()}
            typeCurrentDocument='Amendment'
            endDateContract={EndDate}
          />
          <Field
            name='EndDate'
            value={EndDate}
            component={renderDatePicker}
            validate={[required, validateDate.endDate, validateDate.isValid]}
            showAsterix
            disabled={disabled}
            isEndDate
            setPrevValueOnError
            placeholder='--/--/--'
            label='Amendment End Date'
            pickerName='endDatePO'
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            datePickerClassName='edit-SOW__date-picker'
            minDate={StartDate}
            showAlertDialog={showAlertDialog}
            showConfirmationDialog={this.showConfirmationDialog}
            endDates={this.getEndDatesForDatePicker()}
            startDates={this.getStartDatesForDatePicker()}
            typeCurrentDocument='Amendment'
            typeParent='project'
            startDateContract={StartDate}
          />
        </div>
        <div className='redux-form__row justify-start'>
          <Field
            name='Company'
            value={Company}
            options={allClients}
            component={renderSearchDropdown}
            onChange={this.onChange}
            label='Company Name'
            placeholder='Set Company Name'
            validate={required}
            disabled={disabled}
            inputProps={{ id: 'client' }}
            showAsterix
            isAddingFeature
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__search-dropdown'
          />
          <Field
            name='Approver'
            value={Approver}
            options={allApprovers.filter(approver => Company && approver.ClientId === Company)}
            component={renderSearchDropdown}
            label='Client Amendment Approver'
            placeholder='Set Client Amendment Approver'
            validate={validateApprover}
            inputProps={{ id: 'invoice_approver' }}
            showAsterix={this.showAsterix.Approver()}
            isAddingFeature
            styles={defaultStyles}
            parentEntityId={Company}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__search-dropdown'
          />
          <Field
            name='Status'
            value={Status}
            component={renderSelect}
            options={allStatuses}
            label='Document Status'
            placeholder='Set Document Status'
            styles={defaultStyles}
            disabled={disabled}
            validate={required}
            showAsterix
            isOptionObject
            extendedValue
            inputProps={{ id: 'document_status' }}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__select'
          />
        </div>
        <div className='redux-form__row justify-start row__items--align-start'>
          <Field
            name='StatusComments'
            value={StatusComments}
            component={renderTextField}
            label='Status Comments'
            placeholder='Add Status Comments'
            styles={{
              ...defaultStyles,
              container: 'redux-form__field-container flexible-container margin-left-right-10 ololo',
              field: 'full-width-field height-100pc ololo1',
              fieldContent: 'standart-content-padding align-center height-100pc ololo2',
              fieldView: 'field__view-container height-100pc ololo3',
            }}
            disabled={disabled}
            showAsterix={this.showAsterix.StatusComments()}
            validate={validateStatusComments}
            maxlength='4000'
            fullWidth
            multiline
            inputClassName='edit-SOW__text-field'
          />
          {
            viewFinancialInfo
              && <Field
                name='BudgetComments'
                value={BudgetComments}
                component={renderTextField}
                label='Budget Comments'
                placeholder='Add Budget Comments'
                styles={{
                  ...defaultStyles,
                  container: 'redux-form__field-container flexible-container margin-left-right-10',
                  field: 'full-width-field height-100pc',
                  fieldContent: 'standart-content-padding align-center height-100pc',
                  fieldView: 'field__view-container height-100pc',
                }}
                disabled={disabled}
                maxlength='4000'
                fullWidth
                multiline
                inputClassName='edit-SOW__text-field'
              />
          }
        </div>
        <div className='redux-form__row justify-start'>
          {viewFinancialInfo && <Field
            name='Milestones'
            value={Milestones}
            component={renderMilestoneList}
            disabled={disabled}
            onChange={this.onChange}
            onAddNewMilestone={this.addNewMilestone}
            columnsToDisplay={milestonesListColumnsToDisplay}
            viewFinancialInfo={viewFinancialInfo}
            startDate={StartDate}
            validate={validateMilestone}
            endDate={EndDate}
            formName='projectEditAmendmentForm'
            projectEndDate={ProjectEndDate}
            AmendmentId={Id}
            projectId={SOWDetails.Project.Id}
            styles={{
              ...defaultStyles,
              container: 'redux-form__field-container flexible-container margin-left-right-10',
              field: 'full-width-field height-100pc',
              fieldContent: 'standart-content-padding align-center height-100pc',
              fieldView: 'field__view-container height-100pc',
            }}
          />}
        </div>
        <div className='redux-form__row justify-start'>
          {viewFinancialInfo && <Field
            name='Links'
            value={Links}
            component={renderDocumentList}
            disabled={disabled}
            columnsToDisplay={columnsToDisplay.documentsList}
            viewFinancialInfo={viewFinancialInfo}
            documentTypes={allDocumentTypes}
            isDocumentType
            styles={{
              ...defaultStyles,
              container: 'redux-form__field-container flexible-container margin-left-right-10',
              field: 'full-width-field height-100pc',
              fieldContent: 'standart-content-padding align-center height-100pc',
              fieldView: 'field__view-container height-100pc',
            }}
          />}
        </div>
      </div>
      <div className='redux-form__SOW--buttons'>
        { (importRoles && !!importRoles.length) && <Button
          onClick={this.onSaveAndCreateRoles}
          disabled={!!formErrors}
          visible={!disabled}
          className={classNames('button next button-create-roles', { 'btn-disabled': !!formErrors })}
          text='Save and Create Roles'
        /> }
        <Button
          onClick={this.onCancelClick}
          className='button cancel'
          text={disabled ? 'Back' : 'Cancel'}
        />
        <Button
          onClick={this.applyForm}
          disabled={!!formErrors}
          visible={!disabled}
          className={classNames('button next', { 'btn-disabled': !!formErrors })}
          text='Apply'
        />
      </div>
    </form>
  );
}
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('projectEditAmendmentForm', data)),
    destroy: () => dispatch(destroy('projectEditAmendmentForm')),
    change: (field, value) => dispatch(change('projectEditAmendmentForm', field, value)),
    addNewAmendment:
    (projectId, SOWId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => (
      dispatch(addNewAmendment(projectId, SOWId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog))),
    editAmendment:
    (projectId, SOWId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, redirectTo) => (
      dispatch(editCurrentAmendment(projectId, SOWId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, redirectTo))),
    editRole: (role, isOnlyActiveRoles) => dispatch(editRole(role, isOnlyActiveRoles)),
    showAlertDialog: (message) => dispatch(showAlertDialog(message)),
    addImportAmendment: (ProjectId, SOWDetailsId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => (
      dispatch(addAmendmentToPartialContract(ProjectId, SOWDetailsId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog))),
    cancelContractImport: (id) => dispatch(cancelImport(id)),
  };
};

export default connect(state => {
  const isFormReducerExists = state.form.projectEditAmendmentForm && state.form.projectEditAmendmentForm.values;
  return {
    Id: isFormReducerExists && state.form.projectEditAmendmentForm.values.Id,
    Contract: isFormReducerExists && state.form.projectEditAmendmentForm.values.Contract,
    ApprovalStatus: isFormReducerExists && state.form.projectEditAmendmentForm.values.ApprovalStatus,
    StartDate: isFormReducerExists && state.form.projectEditAmendmentForm.values.StartDate,
    EndDate: isFormReducerExists && state.form.projectEditAmendmentForm.values.EndDate,
    Company: isFormReducerExists && state.form.projectEditAmendmentForm.values.Company,
    Approver: isFormReducerExists && state.form.projectEditAmendmentForm.values.Approver,
    Status: isFormReducerExists && state.form.projectEditAmendmentForm.values.Status,
    StatusComments: isFormReducerExists && state.form.projectEditAmendmentForm.values.StatusComments,
    BudgetComments: isFormReducerExists && state.form.projectEditAmendmentForm.values.BudgetComments,
    Links: isFormReducerExists && state.form.projectEditAmendmentForm.values.Links,
    formErrors: isFormReducerExists && state.form.projectEditAmendmentForm.syncErrors,
    initial: isFormReducerExists && state.form.projectEditAmendmentForm.initial,
    isOnlyActiveRoles: state.showRoles.isOnlyActiveRoles,
    ProjectEndDate: state.projectsReducer.projectDetails.EndDate,
    ProjectStartDate: state.projectsReducer.projectDetails.StartDate,
    Milestones: isFormReducerExists && state.form.projectEditAmendmentForm.values.Milestones,
    importRoles: state.importContractReducer.contract.Roles,
    importContract: state.importContractReducer.contract,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'projectEditAmendmentForm',
})(ProjectEditAmendmentForm));
