import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import TextField from 'common/components/form-items/input/text-field';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchDropdown from '../../../../common/components/form-items/search-dropdown/search-dropdown';
import classNames from 'classnames';
import Select from 'common/components/form-items/select/select';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import { sortFilterList } from 'common/components/list/sortList';
import { isCorrectRoleEmployeeAssignment } from '../utils/isCorrectRoleAssignments';

export class EditAssignmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EmployeeId: '',
      EmployeeName: '',
      EmployeeStartDate: null,
      EmployeeEndDate: null,
      EmployeeAssignment: '',
      EmployeeComment: '',
      RoleType: '',
      PrimaryAssignment: '',
      EmployeeStatus: '',
      EmployeeStatusId: '',
      employeeAssignmentIsUnvalid: false,
      isModalFieldsRequired: false,
    };
  }

  componentDidMount() {
    this.getAssignmentData();
  }

  componentDidUpdate(prevProps) {
    const { assignment } = this.props;
    if (prevProps.assignment !== assignment) {
      this.getAssignmentData();
    }
  }

  getAssignmentData = () => {
    const { assignment, editNewProject } = this.props;
    this.setState({
      EmployeeId: editNewProject ? assignment.Id : assignment.EmployeeId,
      EmployeeName: assignment.Name,
      EmployeeStartDate: assignment.StartDate,
      EmployeeEndDate: assignment.EndDate,
      EmployeeAssignment: assignment.Assignment,
      RoleType: assignment.Role,
      EmployeeComment: assignment.Comment,
      PrimaryAssignment: assignment.PrimaryAssignmentId,
      EmployeeStatus: editNewProject ? assignment.Status : assignment.Status.Name,
      EmployeeStatusId: editNewProject ? assignment.StatusId : assignment.Status.Id,
    }, () => this.requireFields());
  }

  requireFields = () => {
    const {
      EmployeeStartDate, EmployeeEndDate, EmployeeAssignment, EmployeeStatusId,
    } = this.state;
    (EmployeeStartDate !== null
      && EmployeeEndDate !== null
      && EmployeeAssignment !== ''
      && EmployeeStatusId !== ''
    )
      ? this.setState({
        isModalFieldsRequired: false,
      })
      : this.setState({
        isModalFieldsRequired: true,
      });
  }

  // Employee Assignment
  onChangeEmployeeAssignment = (e) => {
    this.setState({
      employeeAssignmentIsUnvalid: isCorrectRoleEmployeeAssignment(e.target.value) ? false : true,
      EmployeeAssignment: e.target.value,
      createEmployeeAssignmentError: false,
    }, () => this.requireFields());
  }

  // Employee Comment
  onChangeEmployeeComment = (e) => {
    this.setState({
      EmployeeComment: e.target.value,
    });
  }

  // Employee Start Data
  handleEmployeeStartDateChange = date => {
    this.setState({
      EmployeeStartDate: date,
    }, () => this.requireFields());
  }

  // Employee Data
  handleEmployeeEndDateChange = date => {
    this.setState({
      EmployeeEndDate: date,
    }, () => this.requireFields());
  }

  // Employee Status
  onChangeEmployeeStatus = ({ item }) => {
    this.setState({
      EmployeeStatus: item.Name,
      EmployeeStatusId: item.Id,
    }, () => this.requireFields());
  }

  clearValue = (property) => () => {
    this.setState({
      [property]: '',
    }, () => this.requireFields());
  };

  clearStatus = () => {
    this.setState({
      EmployeeStatus: '',
      EmployeeStatusId: '',
    }, () => this.requireFields());
  }

  createApplyAssignmentObject = () => {
    const {
      EmployeeId, EmployeeName, EmployeeStartDate, EmployeeComment,
      EmployeeEndDate, EmployeeAssignment, RoleType,
      PrimaryAssignment, EmployeeStatusId, EmployeeStatus,
      employeeAssignmentIsUnvalid, isModalFieldsRequired,
    } = this.state;
    const { assignment, applyModal, editNewProject } = this.props;
    let newAssignment = {};
    if (!isModalFieldsRequired && !employeeAssignmentIsUnvalid && moment(EmployeeStartDate).isBefore(EmployeeEndDate)) {
      newAssignment = {
        AssignmentKey: assignment.AssignmentKey || assignment.Id,
        Id: assignment.Id,
        Name: EmployeeName,
        Assignment: EmployeeAssignment,
        EndDate: EmployeeEndDate,
        PrimaryAssignmentId: PrimaryAssignment,
        Role: RoleType,
        StartDate: EmployeeStartDate,
        Comment: EmployeeComment,
      };
      if (editNewProject) {
        newAssignment.EmployeeId = EmployeeId;
        newAssignment.Status = EmployeeStatus;
        newAssignment.StatusId = EmployeeStatusId;
      } else {
        newAssignment.EmployeeId = assignment.EmployeeId;
        newAssignment.Status = {
          Id: EmployeeStatusId,
          Name: EmployeeStatus,
        };
      }
      applyModal(newAssignment);
    }
  }

  render() {
    const {
      EmployeeId,
      EmployeeStartDate,
      EmployeeEndDate,
      EmployeeAssignment,
      EmployeeComment,
      employeeAssignmentIsUnvalid,
      createEmployeeAssignmentError,
      RoleType,
      PrimaryAssignment,
      EmployeeStatusId,
      createEmployeeStatusError,
      isModalFieldsRequired,
    } = this.state;
    const {
      showModal,
      closeModal,
      employees,
      roleEmployeeStatus,
    } = this.props;
    return (
      <div>
        <Dialog
          maxWidth='lg'
          open={showModal}
          onClose={closeModal}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            <div className='page-header title-border'>
              <span className='title-text'>EDIT ASSIGNMENT</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className='form-block  '>
              <div className='step__row'>
                <div>
                  <SearchDropdown
                    formClassName='step__form'
                    selectClassName='step__search-dropdown dropdown-select-blocked'
                    label='Employee'
                    placeholder='Choose Employee'
                    value={EmployeeId}
                    onChange={this.onChangeEmployee}
                    options={sortFilterList(employees)}
                    disabled
                    inputProps={{
                      name: 'Employee',
                      id: 'employee',
                    }}
                  />
                </div>
                <DatePicker
                  formClassName='role-page-project__form-150  margin-right-50'
                  datePickerClassName='c-role-modal__date-picker'
                  placeholder='--/--/--'
                  label='Start Date'
                  value={EmployeeStartDate}
                  onChange={this.handleEmployeeStartDateChange}
                  isRequired
                  name='startDate'
                  isStartDate
                />
                <DatePicker
                  formClassName='role-page-project__form-150  margin-right-50'
                  datePickerClassName='c-role-modal__date-picker'
                  placeholder='--/--/--'
                  label='End Date'
                  minVal={EmployeeStartDate}
                  value={EmployeeEndDate}
                  onChange={this.handleEmployeeEndDateChange}
                  helperText='Choose Start Date first'
                  isRequired
                  name='endDate'
                  isEndDate
                />
                <div className='u-flex-1 u-flex-row'>
                  <div>
                    <TextField
                      formClassName='c-role-modal__form u-flex-1'
                      inputClassName='c-role-modal__input'
                      id='assignmentEmployee'
                      label='Assignment'
                      type='number'
                      placeholder='0'
                      isRequired
                      value={EmployeeAssignment.toString()}
                      onChange={this.onChangeEmployeeAssignment}
                      error={employeeAssignmentIsUnvalid}
                      clearValue={this.clearValue('EmployeeAssignment')}
                    />
                    {createEmployeeAssignmentError
                      ? <p className='project-input-error project-input-error-margin'>Assignment cannot be empty</p>
                      : null
                    }
                  </div>
                  <div className='u-flex-1' />
                </div>
              </div>
              <div className='step__row margin-up-role'>
                <div className='role-page-project-radio__form-150 margin-right-50'>
                  <RadioGroup
                    aria-label='roleType'
                    name='roleType'
                    value={RoleType}
                    onChange={this.handleChangeRoleType}
                    row
                  >
                    <FormControlLabel
                      disabled
                      value='Primary'
                      control={<Radio />}
                      label='Primary'
                    />
                    <FormControlLabel
                      disabled
                      className='radio-margin-left'
                      value='Shadow'
                      control={<Radio />}
                      label='Backup'
                    />
                  </RadioGroup>
                </div>
                <SearchDropdown
                  formClassName='step__form'
                  selectClassName='step__search-dropdown dropdown-select-blocked'
                  id='primaryEmployee'
                  label='Primary Employee'
                  placeholder='  '
                  value={PrimaryAssignment}
                  onChange={this.onChangeEmployee}
                  options={sortFilterList(employees)}
                  disabled
                  inputProps={{
                    name: 'PrEmployee',
                    id: 'pr_employee',
                  }}
                />
                <div>
                  <Select
                    formClassName='role-page-project__form margin-right-50'
                    selectClassName='c-SOW-edit__select'
                    optionsClassName='option'
                    label='Status'
                    placeholder='Choose Status'
                    value={EmployeeStatusId.toString()}
                    onChange={this.onChangeEmployeeStatus}
                    selectOptions={roleEmployeeStatus}
                    isOptionObject
                    extendedValue
                    inputProps={{
                      name: 'EmployeeStatusId',
                      id: 'RoleEmployeeStatus',
                    }}
                    isRequired
                    clearValue={this.clearStatus}
                  />
                  {createEmployeeStatusError
                    ? <p className='project-input-error project-input-error-margin'>Status cannot be empty</p>
                    : null
                  }
                </div>
                <div className='step__row margin-up-role'>
                  <TextField
                    formClassName='step__form'
                    inputClassName='step__input'
                    id='assignmentComment'
                    label='Comment'
                    value={EmployeeComment.toString()}
                    onChange={this.onChangeEmployeeComment}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className='form-block buttons margin-down-role'>
              <div className='right-side-buttons'>
                <div className='button cancel' onClick={closeModal}>
                  <span>Cancel</span>
                </div>
                <div
                  className={classNames('button next',
                    { 'btn-disabled': isModalFieldsRequired || employeeAssignmentIsUnvalid || !moment(EmployeeStartDate).isBefore(EmployeeEndDate) })}
                  onClick={this.createApplyAssignmentObject}
                >
                  <span>Apply</span>
                </div>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditAssignmentModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  applyModal: PropTypes.func,
  employees: PropTypes.array,
  roleEmployeeStatus: PropTypes.array,
};

export default connect(store => {
  return {
    employees: store.roleModalReducer.employees,
    roleEmployeeStatus: store.roleModalReducer.employeeStatus,
    isOnlyActiveRoles: store.showRoles.isOnlyActiveRoles,
  };
}, {
  updateEditAssignment: '',
})(EditAssignmentModal);
