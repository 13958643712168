import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class CellSkill extends Component {
  getNormalSkillsView = (skills) => {
    const sortSkills = skills.length && skills.sort((a, b) => a.Level.Priority > b.Level.Priority ? -1 : (a.Level.Priority === b.Level.Priority ? 0 : 1));
    let skillsView = '';
    if (sortSkills !== 0) {
      sortSkills.forEach((item) => {
        skillsView += item.Level.Name + '-' + item.Skill + ';';
      });
    }
    return skillsView;
  }

  render() {
    const { item, column } = this.props;
    return (
      <div
        key={column.Id}
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
      >
        <span
          className='col-span cell_column-orient'
          title={this.getNormalSkillsView(item[column.Id])}
        >
          {this.getNormalSkillsView(item[column.Id])}
        </span>
      </div>
    );
  }
}

CellSkill.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
