import { SET_VERSION } from '../actions/versionActions';

const initialState = {
  version: '',
  prevVersion: '',
};

export default function versionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VERSION:
      return {
        ...state,
        version: action.payload,
        prevVersion: state.version,
      };
    default:
      return state;
  }
}
