import moment from 'moment';

export const getTheBiggestDateForRoles = (Employees) => {
  const arrayEndDate = Employees.map((employee) => employee.EmployeeEndDate);
  return arrayEndDate.length ? moment.min(arrayEndDate.map(date => moment(date))) : null;
};

export const getTheSmallestDateForRoles = (Employees) => {
  const arrayStartDate = Employees.map((employee) => employee.EmployeeStartDate);
  return arrayStartDate.length ? moment.min(arrayStartDate.map(date => moment(date))) : null;
};
