import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MultiSelectAll from 'common/components/form-items/multi-select/multi-select-with-all-checker';
import { ADMIN_ROLE_NAME, API_ACCESS_ROLE_NAME } from '../../../../../utils/const-variable';

export class RenderRoleTypes extends Component {
  render() {
    const {
      roles, isDisabled, onChange, checkedRoles, selectedUserOffices, handleChangeSelectedOffices, officesOptions,
    } = this.props;
    return (
      <div className='admin-modal-roles'>
        {
          roles.map((item, i) => {
            const checked = checkedRoles.some(role => role === item.Id);
            const isAdmin = item.Name === ADMIN_ROLE_NAME;
            const isApiAccess = item.Name === API_ACCESS_ROLE_NAME;
            const isApiAccessChecked = checkedRoles.find(checkedRoleId => checkedRoleId === roles.find(role => role.Name === API_ACCESS_ROLE_NAME).Id);
            const disabled = (!isAdmin && isDisabled)
              || (isApiAccess && !checked && checkedRoles && checkedRoles.length)
              || (!isApiAccess && isApiAccessChecked);
            return (
              <span
                key={i}
                className={classNames(
                  'checkbox-cont-list-item configuration-option checkbox-margin two-cols-view',
                  { 'check-box-disabled': (isDisabled && item.Name !== ADMIN_ROLE_NAME || disabled) },
                )}
              >
                <label>
                  <input
                    id={item.Id}
                    name={item.Name}
                    type='checkbox'
                    checked={checked}
                    defaultChecked={item.isSelected}
                    onClick={onChange}
                    disabled={disabled}
                  />
                  <span className='configuration-option__checkbox' />
                  <span className='configuration-option__label'>
                    <span className='role-style'>{item.DisplayName}</span>
                  </span>
                </label>
                {item.Name === 'API Access' && checked && (
                  <div className='item-append'>
                    <MultiSelectAll
                      isRequired
                      contClass='multi-select--inline'
                      selected={selectedUserOffices}
                      title='Offices'
                      placeholder='Select offices'
                      onChange={handleChangeSelectedOffices}
                      options={officesOptions}
                    />
                  </div>
                )}
              </span>
            );
          })
        }
      </div>
    );
  }
}

RenderRoleTypes.propTypes = {
  roles: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
