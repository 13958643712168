import moment from 'moment';

export const addNewNotification = (notify, target, customText) => {
  const notifications = JSON.parse(sessionStorage.getItem('notifications'));
  const notification = {
    status: notify && notify.status || '',
    statusText: notify && notify.statusText || '',
    customText,
    target,
    method: notify && notify.config && notify.config.method || '',
    time: moment().format('YYYY-MM-DD hh:mm'),
  };
  notifications.notifications.unshift(notification);
  sessionStorage.setItem('notifications', JSON.stringify(notifications));
  return notification;
};
