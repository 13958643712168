export const GET_ACCOUNTING_REPORTS = '@reports/GET_ACCOUNTING_REPORTS';
export const SET_ACCOUNTING_REPORTS = '@reports/SET_ACCOUNTING_REPORTS';
export const SET_ACCOUNTING_TOTAL_COUNT = '@reports/SET_ACCOUNTING_TOTAL_COUNT';
export const ACCOUNTING_TRANSITION_TO_COMPANY = '@reports/ACCOUNTING_TRANSITION_TO_COMPANY';
export const ACCOUNTING_TRANSITION_TO_PROJECT = '@reports/ACCOUNTING_TRANSITION_TO_PROJECT';
export const ACCOUNTING_TRANSITION_TO_EMPLOYEE = '@reports/ACCOUNTING_TRANSITION_TO_EMPLOYEE';
export const CHANGE_ACCOUNTING_COLUMNS_TO_DISPLAY = '@reports/CHANGE_ACCOUNTING_COLUMNS_TO_DISPLAY';
export const SET_ACCOUNTING_LIST_SETTINGS = '@reports/SET_ACCOUNTING_LIST_SETTINGS';
export const RESET_ACCOUNTING_LIST_SETTINGS = '@reports/RESET_ACCOUNTING_LIST_SETTINGS';
export const SET_ACCOUNTING_LIST_FILTERS_VALUE = '@reports/SET_ACCOUNTING_LIST_FILTERS_VALUE';
export const RESET_ACCOUNTING_LIST_FILTERS_VALUE = '@reports/RESET_ACCOUNTING_LIST_FILTERS_VALUE';

export const getAccountingReports = (searchvalue = '', take = 50, skip = 0, column, sortDirection, filters) => ({
  type: GET_ACCOUNTING_REPORTS,
  payload: {
    searchvalue,
    take,
    skip,
    column,
    sortDirection,
    filters,
  },
});

export const setAccountingReports = (reports) => ({
  type: SET_ACCOUNTING_REPORTS,
  payload: reports,
});

export const setAccountCount = (count) => ({
  type: SET_ACCOUNTING_TOTAL_COUNT,
  payload: count,
});

export const transitionToCompany = (Id) => ({
  type: ACCOUNTING_TRANSITION_TO_COMPANY,
  payload: { Id },
});

export const transitionToProject = (id, isOnlyActive) => ({
  type: ACCOUNTING_TRANSITION_TO_PROJECT,
  payload: { id, isOnlyActive },
});


export const transitionToEmployee = (id) => ({
  type: ACCOUNTING_TRANSITION_TO_EMPLOYEE,
  payload: { id },
});

export const changeColumnsToDisplay = (columnsToDisplay) => {
  return {
    type: CHANGE_ACCOUNTING_COLUMNS_TO_DISPLAY,
    payload: {
      columnsToDisplay,
    },
  };
};

export const setAccountingListSettings = (settings) => ({
  type: SET_ACCOUNTING_LIST_SETTINGS,
  payload: settings,
});

export const resetAccountingListSettings = () => ({
  type: RESET_ACCOUNTING_LIST_SETTINGS,
});

export const setAccountingListFiltersValue = (filters) => ({
  type: SET_ACCOUNTING_LIST_FILTERS_VALUE,
  payload: filters,
});

export const resetAccountingListFiltersValue = () => ({
  type: RESET_ACCOUNTING_LIST_FILTERS_VALUE,
});
