import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class CellToggleCheckbox extends Component {
  constructor(props) {
    super(props);
    const {
      item, column, isEditingMode, isControlledByListItem,
    } = this.props;
    this.state = {
      checked: item[column.Id],
      isEditable: isControlledByListItem && isEditingMode,
    };
  }

  toggleViewCell = () => {
    const { isEditable } = this.state;
    const { isEditingMode, isControlledByListItem } = this.props;
    this.setState({
      isEditable: isControlledByListItem ? isEditingMode : !isEditable,
    });
  }

  handleClick = () => {
    const {
      column, config, item, isControlledByListItem,
    } = this.props;
    const { checked } = this.state;
    const { valueSetter, editingValueSetter } = config[column.Id];
    const newItem = isControlledByListItem ? {
      [column.Id]: !checked,
    } : {
      ...item,
      [column.Id]: !checked,
    };
    this.setState(({ checked }) => ({ checked: !checked }));
    valueSetter && valueSetter(newItem);
    this.toggleViewCell();
    if (isControlledByListItem) {
      editingValueSetter && editingValueSetter(newItem, item);
    }
  }

  render() {
    const {
      column,
      isHaveRights,
    } = this.props;
    const {
      checked,
      isEditable,
    } = this.state;
    return (
      <div
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
      >
        <label className='checkbox-cont-list-item configuration-option check_role'>
          <input
            type='checkbox'
            onChange={this.handleClick}
            checked={checked}
            disabled={!(isEditable && isHaveRights)}
          />
          <span className='configuration-option__checkbox' />
        </label>
      </div>
    );
  }
}

CellToggleCheckbox.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
