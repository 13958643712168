import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import {
  getManageSkillLevels,
  createNewSkillLevels,
  updateSkillLevels,
  updateOrderSkillLevels,
} from 'pages/admin/actions/adminManageSkillLevels';
import { SortableManageList } from 'common/components/documents-list/sortable-manage-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';

export class ManageSkillLevels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsToDisplay: [
        {
          Name: 'Skill Levels', isSelected: true, className: 'without-width-col', Id: 'Skill Levels',
        },
      ],
      showAddRoleFields: false,
      addSkillLevel: { Name: '' },
      blockSkillLevel: false,
      blockSaveSkillLevel: false,
    };
  }

  componentDidMount() {
    const { loadManageSkillLevels } = this.props;
    loadManageSkillLevels(true);
  }

  addNewSkillLevel = () => {
    const { blockSkillLevel } = this.state;
    if (!blockSkillLevel) {
      this.setState({
        showAddRoleFields: true,
      });
    }
  }

  onChangeSkillLevelObj = ({ target }) => {
    const { addSkillLevel } = this.state;
    this.setState({
      addSkillLevel: {
        ...addSkillLevel,
        [target.name]: target.value,
      },
    }, this.newSkillLevelValidation);
  }

  newSkillLevelValidation = () => {
    const { addSkillLevel } = this.state;
    this.setState({
      blockSaveSkillLevel: !!addSkillLevel.Name.trim(),
      blockSkillLevel: !!addSkillLevel.Name.trim(),
    });
  };

  newSkillLeveleCancel = () => {
    this.setState({
      addSkillLevel: { Name: '' },
      showAddRoleFields: false,
      blockSaveSkillLevel: false,
      blockSkillLevel: false,
    });
  }

  newSkillLevelApply = () => {
    const { createNewSkillLevels, manageSkillLevels } = this.props;
    const { blockSaveSkillLevel, addSkillLevel } = this.state;
    const priorityArray = manageSkillLevels.map((key) => key.Priority);
    const sendNewSkillLevelObj = {
      ...addSkillLevel,
      Priority: Math.max.apply(null, priorityArray) + 1,
    };
    if (blockSaveSkillLevel) {
      createNewSkillLevels(sendNewSkillLevelObj);
      this.setState({
        addSkillLevel: { Name: '' },
        showAddRoleFields: false,
        blockSaveSkillLevel: false,
        blockSkillLevel: false,
      });
    }
  }


  deleteSkillLevel = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `skill_levels/${data.Id}`,
      searchUpdateSaga: 'skill_levels',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateSkillLevels } = this.props;
    const { Name, Id } = data;
    updateSkillLevels(Name, Id);
  }

  newOrderSkillLevels = (data) => {
    const { updateOrderSkillLevels } = this.props;
    updateOrderSkillLevels(data);
  }

  render() {
    const { manageSkillLevels } = this.props;
    const {
      columnsToDisplay, showAddRoleFields, addSkillLevel, blockSkillLevel,
    } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsSkillLevels')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewSkillLevel}
          searchField={false}
        />
        <SortableManageList
          columnsToDisplay={columnsToDisplay}
          items={manageSkillLevels}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteSkillLevel}
          onUpdateOrder={this.newOrderSkillLevels}
          showItemsEditMenu
          showSortIcons={false}
        />
        {
          showAddRoleFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addSkillLevel}
                onChangeNewDocumentObj={this.onChangeSkillLevelObj}
                disableSaveDocument={blockSkillLevel}
                newDocumentCancel={this.newSkillLeveleCancel}
                newDocumentApply={this.newSkillLevelApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageSkillLevels.propTypes = {
  loadManageSkillLevels: PropTypes.func,
  createNewSkillLevels: PropTypes.func,
  updateSkillLevels: PropTypes.func,
  updateOrderSkillLevels: PropTypes.func,
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  manageSkillLevels: store.adminManageLookups.skillLevels,
}), {
  loadManageSkillLevels: getManageSkillLevels,
  createNewSkillLevels,
  updateSkillLevels,
  updateOrderSkillLevels,
  showDialog,
})(ManageSkillLevels);
