import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from 'utils/history';
import Scrollbars from 'react-custom-scrollbars';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from '../../../../common/components/list/sortList';
import { getOffices, searchOffices, setListSettings } from '../../actions/officesActions';
import { setSelectedTab } from '../../../../common/actions/selectedTabActions';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { setComponent, resetComponent } from 'common/actions/headerActions';

export class OfficesListPage extends Component {
  componentDidMount() {
    const {
      offices, setComponent, getOffices, listSettings, searchOffices,
    } = this.props;
    listSettings && listSettings.searchValue ? searchOffices && searchOffices(listSettings.searchValue) : getOffices();
    setComponent({ title: `Offices (${offices && offices.length})` });
  }

  componentDidUpdate(prevProps) {
    const { offices, setComponent } = this.props;
    if (prevProps.offices && prevProps.offices.length !== offices.length) {
      setComponent({ title: `Offices (${offices.length})` });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  searchOffices = (searchValue) => {
    const { searchOffice, setListSettings } = this.props;
    setListSettings({ searchValue });
    searchOffice(searchValue);
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettings } = this.props;
    setListSettings({ sortColumn, sortDirection });
  };

  getLinkOfficesDetails = (id) => {
    return {
      pathname: `/offices/${id}`,
    };
  }

  routeOfficesDetails = (id) => {
    history.push({
      pathname: `/offices/${id}`,
    });
  };

  render() {
    const {
      listSettings, offices, columnsToDisplay,
    } = this.props;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <Scrollbars
        autoHide
        autoHideTimeout={300}
      >
        <div className='page-cont'>
          <ListToolbar
            searchValue={searchValue}
            onSearchClick={this.searchOffices}
            columnsToDisplay={columnsToDisplay}
            searchPlaceholder='Search'
          />
          <List
            columnsToDisplay={columnsToDisplay}
            items={sortList(offices, sortColumn, sortDirection)}
            setSortSettings={this.setSortSettings}
            sortColumnName={sortColumn}
            sortDirection={sortDirection}
            transition={this.getLinkOfficesDetails}
            onItemClick={this.routeOfficesDetails}
            onEditClick={this.routeOfficesDetails}
            config={{
              'Name': { cell: CellLinkForRedirect },
            }}
          />
        </div>
      </Scrollbars>
    );
  }
}

OfficesListPage.propTypes = {
  offices: PropTypes.array,
  columnsToDisplay: PropTypes.array,
  setListSettings: PropTypes.func,
  searchOffice: PropTypes.func,
  listSettings: PropTypes.object,
};

export default connect((store) => ({
  offices: store.officesReducer.offices,
  columnsToDisplay: store.officesReducer.columnsToDisplay,
  listSettings: store.officesReducer.listSettings,
}), {
  searchOffice: searchOffices,
  setSelectedTab,
  getOffices,
  setComponent,
  resetComponent,
  setListSettings,
})(OfficesListPage);
