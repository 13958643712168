import moment from 'moment';

export const updateCheckedFlagOnRoles = (roles, idRole, parentId, startDateContract, endDateContract, forExtend) => {
  const additionalConditionForContract = (role) => moment(role.StartDate).isSameOrAfter(startDateContract, 'day')
    && moment(role.EndDate).isSameOrBefore(endDateContract, 'day');
  return roles.map((role) => role.Id === idRole && !parentId && role.Status.Name !== 'Removed'
    && (forExtend ? additionalConditionForContract(role) : true)
    ? {
      ...role,
      checked: !role.checked,
      Employees: role.Employees.map((employee) => employee.Status.Name !== 'Removed'
        ? { ...employee, checked: !role.checked } : employee),
    }
    : (parentId && role.checked
      ? {
        ...role,
        Employees: role.Employees.map((employee) => employee.Id === idRole
          && employee.Status.Name !== 'Removed'
          && (forExtend ? additionalConditionForContract(employee) : true) ? { ...employee, checked: !employee.checked } : employee),
      }
      : role));
};
