import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SoWExtensionForecastList from './components/sow-list/sow-list';
import BenchSummary from './components/bench-summary-list/bench-list';
import Workforce from './components/workforce-list/workforce-list';
import BenchReports from './components/bench-list/bench-list';
import ProjectedRevenueReports from './components/finance-list/finance-list';
import Audit from './components/audit-list/audit-list';
import Accounting from './components/accounting-list/accounting-list';
import ClientRates from './components/client-rates-list/client-rates-list';
import TeamComposition from './components/team-composition/team-composition';
import Trainings from './components/trainings-list/trainings-list';
import BackgroundChecks from './components/background-checks-list/background-checks-list';
import Certifications from './components/certifications-list/certifications-list';

export class ReportsPage extends Component {
  SoWExtensionForecast = (props) => {
    const { HasFinancialAccess, ManageProjectRoles, ManageProjectContracts } = this.props;
    return (
      <SoWExtensionForecastList
        {...props}
        HasFinancialAccess={HasFinancialAccess}
        ManageProjectRoles={ManageProjectRoles}
        ManageProjectContracts={ManageProjectContracts}
      />
    );
  };

  BenchSummary = (props) => {
    return (
      <BenchSummary
        {...props}
      />
    );
  };

  BenchReports = (props) => {
    return (
      <BenchReports
        {...props}
      />
    );
  };

  Workforce = (props) => {
    return (
      <Workforce
        {...props}
      />
    );
  }

  Finance = (props) => {
    const { HasFinancialAccess } = this.props;
    return (
      <ProjectedRevenueReports
        {...props}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  Audit = (props) => {
    return (
      <Audit
        {...props}
      />
    );
  }

  Accounting = (props) => {
    return (
      <Accounting
        {...props}
      />
    );
  }

  ClientRates = (props) => {
    const { HasFinancialAccess } = this.props;
    return (
      <ClientRates
        {...props}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  TeamComposition = (props) => {
    const { HasFinancialAccess } = this.props;
    return (
      <TeamComposition
        {...props}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  Trainings = (props) => {
    return (
      <Trainings
        {...props}
      />
    );
  }

  BackgroundChecks = (props) => {
    return (
      <BackgroundChecks
        {...props}
      />
    );
  }

  Certifications = (props) => {
    return (
      <Certifications
        {...props}
      />
    );
  }

  render() {
    const { isAdmin, HasFinancialAccess } = this.props;
    return (
      <Switch>
        <Route exact path='/reports/sow-extension-forecast' render={this.SoWExtensionForecast} />
        <Route exact path='/reports/bench-summary' render={this.BenchSummary} />
        <Route exact path='/reports/workforce' render={this.Workforce} />
        <Route exact path='/reports/bench' render={this.BenchReports} />
        {HasFinancialAccess && <Route exact path='/reports/projected-revenue' render={this.Finance} />}
        {isAdmin && <Route exact path='/reports/audit' render={this.Audit} />}
        <Route exact path='/reports/accounting' render={this.Accounting} />
        {HasFinancialAccess && <Route exact path='/reports/client-rates' render={this.ClientRates} />}
        {HasFinancialAccess && <Route exact path='/reports/team-composition' render={this.TeamComposition} />}
        <Route exact path='/reports/trainings' render={this.Trainings} />
        <Route exact path='/reports/background-checks' render={this.BackgroundChecks} />
        <Route exact path='/reports/certifications' render={this.Certifications} />
        <Redirect to='/reports/sow-extension-forecast' />
      </Switch>
    );
  }
}

export default connect(() => ({}), {})(ReportsPage);
