import React, { Component } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core/';
import CustomIcon from '../../../../common/components/icon/Icon';
import TextField from '../../../../common/components/form-items/input/text-field';
import DatePicker from '../../../../common/components/form-items/date-picker/date-picker';
import Button from '../../../../common/components/redux-form/components/button';
import PropTypes from 'prop-types';
import { PROJECT_ROLES_STATUS_ACTIVE } from '../../../../utils/const-variable';

class ProjectInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Id: '',
      Alias: '',
      Date: null,
      isErrorForm: true,
    };
  }

  componentDidMount() {
    this.setDefaultEditData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { editProjectInfoObject } = this.props;
    if (prevProps.editProjectInfoObject !== editProjectInfoObject) {
      this.setDefaultEditData();
    }
  }

  setDefaultEditData = () => {
    const { editProjectInfoObject } = this.props;
    this.setState({
      Id: editProjectInfoObject.Id,
      Alias: editProjectInfoObject.Alias,
      Date: editProjectInfoObject.Date,
    }, this.checkForm);
  }

  checkForm = () => {
    const { Alias, Date } = this.state;
    const checkAlias = !Alias || !(Alias.length > 0);
    this.setState({
      isErrorForm: checkAlias || !Date,
    });
  }

  confirmEditProjectInfo = () => {
    const { confirmDialog, closeDialog } = this.props;
    const { Id, Alias, Date, isErrorForm } = this.state;
    if (!isErrorForm) {
      confirmDialog({ Id, Alias, Date });
      closeDialog();
    }
  }

  handleChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, this.checkForm);
  }

  handleChangeDate = (date) => {
    this.setState({
      Date: date,
    }, this.checkForm);
  }

  setIsError = () => {
    this.setState({ isErrorForm: true });
  }

  render() {
    const { closeDialog, editProjectInfoObject } = this.props;
    const { Alias, Date, isErrorForm } = this.state;

    const dateName = editProjectInfoObject.Status
      && (editProjectInfoObject.Status.Name === PROJECT_ROLES_STATUS_ACTIVE
        ? 'Account Join Date'
        : 'Account Left Date');

    return (
      <Dialog
        open
        aria-labelledby='responsive-dialog-rirle'
        className='certification__dialog-width'
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>Edit Project Info</span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className='background-check-modal-row'>
            <TextField
              formClassName='step__form'
              inputClassName='background-check-step__select background-check-step__text'
              id='Alias'
              placeholder='Alias'
              label='Alias'
              maxlength='30'
              name='Alias'
              value={Alias}
              onChange={this.handleChangeData}
              isRequired
            />
            <DatePicker
              formClassName='step__form svg-calendar-add-info  margin-left-10'
              datePickerClassName='background-check-step__select background-check-step__text'
              id='Date'
              placeholder={dateName}
              label={dateName}
              value={Date}
              onChange={this.handleChangeDate}
              isRequired
              name='Date'
              isStartDate
              onError={this.setIsError}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            className='button cancel background-check-dialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmEditProjectInfo}
            className={`button ${!isErrorForm ? 'next' : 'btn-disabled'} background-check-dialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

ProjectInfoModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
};

export default ProjectInfoModal;
