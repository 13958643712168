import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatCurrency from 'utils/formatCurrency';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';

export class CellRate extends Component {
  renderBudgetInfo = (budget, currency) => {
    return budget ? formatCurrency(currency) + getAmericanFormat(budget) : `${formatCurrency(currency)}0`;
  }

  render() {
    const {
      item, column, viewFinancialInfo, config,
    } = this.props;
    const currency = config.CurrencyType && config.CurrencyType.currency && config.CurrencyType.currency.Name;
    const rate = item.Rate
      ? this.renderBudgetInfo(item.Rate, currency) + '·' + item.RateUnit.Name[0]
      : '';
    const overtimeRate = item.RateOvertime
      ? this.renderBudgetInfo(item.RateOvertime, currency) + '·' + item.OvertimeRateUnit.Name[0]
      : '';
    return viewFinancialInfo ? (
      <div
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
      >
        <span
          className='col-span cell_column-orient'
        >
          {rate && overtimeRate ? rate + ' / ' + overtimeRate : rate || overtimeRate || 'No'}
        </span>
      </div>
    ) : '';
  }
}

CellRate.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  viewFinancialInfo: PropTypes.bool.isRequired,
};
