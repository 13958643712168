import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../icon/Icon';
import { UpdateManageItem } from './update-manage-item';

export class ManageItem extends React.Component {
  constructor(props) {
    super(props);
    const { item, itemId } = this.props;
    this.state = {
      Id: item.Id ? item.Id : itemId,
      Name: item.Name,
      showOptions: false,
      newManageItem: {
        Id: item.Id ? item.Id : itemId,
        Name: item.Name,
      },
      editResumeBlockShow: false,
      blockSaveDocument: false,
      isNeedUpOptions: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { item, itemId } = this.props;
    if (prevProps.item !== item) {
      this.setState({
        newManageItem: {
          Id: item.Id ? item.Id : itemId,
          Name: item.Name,
        },
      });
    }
  }

  toggleShowOptions = (event) => {
    const { showOptions } = this.state;
    const elementPositions = event.target.getBoundingClientRect();
    const windowHeight = document.documentElement.clientHeight;
    const minLimitToBottom = 50;
    this.setState({
      showOptions: !showOptions,
      isNeedUpOptions: ((windowHeight - elementPositions.bottom) < minLimitToBottom),
    });
    event.stopPropagation();
  }

  hideOptions = () => {
    this.setState({
      showOptions: false,
    });
  }

  showEditDocument = () => {
    this.setState({
      editResumeBlockShow: true,
    });
  }

  closeEditDocument = (event) => {
    const { item } = this.props;
    const { newManageItem } = this.state;
    event.stopPropagation();
    this.setState({
      newManageItem: {
        ...newManageItem,
        Name: item.Name,
      },
      editResumeBlockShow: false,
    });
  }

  deleteDocument = () => {
    const { onDeleteClick, item } = this.props;
    onDeleteClick(item);
  }

  onChangeNewDocumentObj = ({ target }) => {
    const { newManageItem } = this.state;
    this.setState({
      newManageItem: {
        ...newManageItem,
        [target.name]: target.value,
      },
    }, this.newDocumentValidation);
  }

  newDocumentValidation = () => {
    const { newManageItem } = this.state;
    this.setState({
      blockSaveDocument: !!newManageItem.Name.trim(),
    });
  };

  newDocumentApply = (event) => {
    const { onEditClick } = this.props;
    const { newManageItem, blockSaveDocument } = this.state;
    if (blockSaveDocument) {
      this.setState({
        blockSaveDocument: false,
      });
      onEditClick(newManageItem);
      this.closeEditDocument(event);
    }
  }

  render() {
    const {
      itemId,
      columnsToDisplay,
      isCheckboxShown,
      item,
      checkItem,
      checkedItem,
      isEditable,
      showItemsEditMenu,
    } = this.props;
    const {
      showOptions,
      editResumeBlockShow,
      blockSaveDocument,
      newManageItem,
      isNeedUpOptions,
    } = this.state;
    return (
      <div
        className={classNames('c-list__item-cont')}
        onClick={!isCheckboxShown ? this.showEditDocument : null}
        key={itemId}
      >
        {
          columnsToDisplay.map((column) => {
            return (
              <div
                key={itemId}
                className={classNames(
                  'c-list__item-column',
                  column.className,
                  { 'c-list__status': column.Id === 'Status' },
                )}
              >
                {isCheckboxShown ? <div className='c-list__column smallest-col'>
                  <div
                    className={classNames('c-list__item-column checkbox_width admin_merge-checkbox',
                      { 'checked_item-merge': checkedItem })
                    }
                  >
                    <label className='checkbox-cont-list-item configuration-option check_role'>
                      <input
                        type='checkbox'
                        value={item.Id}
                        onChange={checkItem}
                        checked={checkedItem}
                      />
                      <span className='configuration-option__checkbox' />
                    </label>
                  </div>
                </div> : null}
                {
                  editResumeBlockShow && isEditable ? <UpdateManageItem
                    id={itemId}
                    item={newManageItem}
                    onChangeNewDocumentObj={this.onChangeNewDocumentObj}
                    disableSaveDocument={blockSaveDocument}
                    newDocumentCancel={(event) => this.closeEditDocument(event)}
                    newDocumentApply={(event) => this.newDocumentApply(event)}
                  />
                    : <span> {newManageItem.Name} </span>
                }
              </div>
            );
          })
        }
        {showItemsEditMenu && <div className='c-list__options-column c-list__options-column__svg' onClick={this.toggleShowOptions}>
          <CustomIcon iconName='three-dots' />
          <div
            className={classNames('c-list-item__options-column__container', {
              'c-list-item_push-options-up_low': isNeedUpOptions,
            })}
            onMouseLeave={this.hideOptions}
          >
            {showOptions
              ? (<div>
                <div
                  className='c-editable-list-item-delete-block__option'
                  onClick={this.showEditDocument}
                >
                  <span>Edit item</span>
                </div>
                <div
                  className='c-editable-list-item-delete-block__option'
                  onClick={this.deleteDocument}
                >
                  <span>Delete item</span>
                </div>
              </div>)
              : null}
          </div>
        </div>}
      </div>
    );
  }
}

ManageItem.propTypes = {
  item: PropTypes.object,
  columnsToDisplay: PropTypes.array,
  onDeleteClick: PropTypes.func,
};

export default connect(store => ({
  isAdmin: store.userRoleReducer.isAdmin,
  isOnlyActive: store.showRoles.isOnlyActiveRoles,
  roles: store.userRoleReducer.roles,
}))(ManageItem);
