export const GET_WORKFORCE_REPORTS = '@reports/GET_WORKFORCE_REPORTS';
export const SET_WORKFORCE_REPORTS = '@reports/SET_WORKFORCE_REPORTS';
export const SET_WORKFORCE_COUNTER = '@reports/SET_WORKFORCE_COUNTER';
export const SET_FILTERS_WORKFORCE_REPORTS = '@reports/SET_FILTERS_WORKFORCE_REPORTS';
export const GET_FILTERS_WORKFORCE = '@reports/GET_FILTERS_WORKFORCE';
export const SET_WORKFORCE_LIST_SETTINGS = '@reports/SET_WORKFORCE_LIST_SETTINGS';
export const RESET_WORKFORCE_LIST_SETTINGS = '@reports/RESET_WORKFORCE_LIST_SETTINGS';
export const SET_WORKFORCE_FILTERS_VALUE = '@reports/SET_WORKFORCE_FILTERS_VALUE';
export const RESET_WORKFORCE_FILTERS_VALUE = '@reports/RESET_WORKFORCE_FILTERS_VALUE';
export const CHANGE_WORKFORCE_COLUMNS_TO_DISPLAY = '@reports/CHANGE_WORKFORCE_COLUMNS_TO_DISPLAY';

export const getWorkforceReports = (filters = {}, searchValue = '', take = 50, skip = 0, sortSetting) => ({
  type: GET_WORKFORCE_REPORTS,
  payload: {
    searchValue,
    filters,
    take,
    skip,
    sortSetting,
  },
});

export const getFiltersForWorkForce = () => ({
  type: GET_FILTERS_WORKFORCE,
});

export const setWorkforceReports = (reports) => ({
  type: SET_WORKFORCE_REPORTS,
  payload: reports,
});

export const setFiltersWorkforce = (filters) => ({
  type: SET_FILTERS_WORKFORCE_REPORTS,
  payload: filters,
});

export const setWorkforceCounter = (count) => ({
  type: SET_WORKFORCE_COUNTER,
  payload: count,
});

export const setWorkforceListSettings = (settings) => ({
  type: SET_WORKFORCE_LIST_SETTINGS,
  payload: settings,
});

export const resetWorkforceListSettings = () => ({
  type: RESET_WORKFORCE_LIST_SETTINGS,
});

export const setWorkforceFiltersValue = (filters) => ({
  type: SET_WORKFORCE_FILTERS_VALUE,
  payload: filters,
});

export const resetWorkforceFiltersValue = () => ({
  type: RESET_WORKFORCE_FILTERS_VALUE,
});

export const changeColumnsToDisplay = (columnsToDisplay) => {
  return {
    type: CHANGE_WORKFORCE_COLUMNS_TO_DISPLAY,
    payload: {
      columnsToDisplay,
    },
  };
};
