import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import {
  loadSOWRenewalStatuses,
  createSOWRenewalStatus,
  updateSOWRenewalStatus,
  deleteSOWRenewalStatus,
} from 'pages/admin/actions/adminManageSOWRenewalStatus';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';


export class ManageSOWRenewalStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'SOW Renewal Status', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddSOWRenewalStatusFields: false,
      addSOWRenewalStatus: { Name: '' },
      blockAddSOWRenewalStatus: false,
      blockSaveSOWRenewalStatus: false,
    };
  }

  componentDidMount() {
    const { loadSOWRenewalStatuses } = this.props;
    loadSOWRenewalStatuses();
  }

  addNewSOWRenewalStatus = () => {
    const { blockAddSOWRenewalStatus } = this.state;
    if (!blockAddSOWRenewalStatus) {
      this.setState({
        showAddSOWRenewalStatusFields: true,
      });
    }
  }

  getNewSOWRenewalStatusObj = ({ target }) => {
    const { addSOWRenewalStatus } = this.state;
    this.setState({
      addSOWRenewalStatus: {
        ...addSOWRenewalStatus,
        [target.name]: target.value,
      },
    }, this.newSOWRenewalStatusValidation);
  }

  newSOWRenewalStatusValidation = () => {
    const { addSOWRenewalStatus } = this.state;
    this.setState({
      blockSaveSOWRenewalStatus: !!addSOWRenewalStatus.Name.trim(),
      blockAddSOWRenewalStatus: !!addSOWRenewalStatus.Name.trim(),
    });
  };

  newSOWRenewalStatusCancel = () => {
    this.setState({
      addSOWRenewalStatus: { Name: '' },
      showAddSOWRenewalStatusFields: false,
      blockSaveSOWRenewalStatus: false,
      blockAddSOWRenewalStatus: false,
    });
  }

  newResumeApply = () => {
    const { createSOWRenewalStatus } = this.props;
    const { blockSaveSOWRenewalStatus, addSOWRenewalStatus } = this.state;
    if (blockSaveSOWRenewalStatus) {
      createSOWRenewalStatus(addSOWRenewalStatus);
      this.setState({
        addSOWRenewalStatus: { Name: '' },
        showAddSOWRenewalStatusFields: false,
        blockSaveSOWRenewalStatus: false,
        blockAddSOWRenewalStatus: false,
      });
    }
  }


  deletePaymentTerms = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `renewal-statuses/${data.Id}`,
      searchUpdateSaga: 'renewal_statuses',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateSOWRenewalStatus } = this.props;
    updateSOWRenewalStatus(data);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  render() {
    const { RenewalStatuses, listSettings } = this.props;
    const {
      columnsToDisplay, showAddSOWRenewalStatusFields, addSOWRenewalStatus, blockAddSOWRenewalStatus,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsRenewalStatus')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewSOWRenewalStatus}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search sow renewal status'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(RenewalStatuses, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deletePaymentTerms}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {
          showAddSOWRenewalStatusFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addSOWRenewalStatus}
                onChangeNewDocumentObj={this.getNewSOWRenewalStatusObj}
                disableSaveDocument={blockAddSOWRenewalStatus}
                newDocumentCancel={this.newSOWRenewalStatusCancel}
                newDocumentApply={this.newResumeApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageSOWRenewalStatus.propTypes = {
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  RenewalStatuses: store.adminManageLookups.sowRenewalStatuses,
  listSettings: store.adminManageLookups.listSettings,
}), {
  showDialog,
  setListSettingsLookups,
  loadSOWRenewalStatuses,
  createSOWRenewalStatus,
  updateSOWRenewalStatus,
  deleteSOWRenewalStatus,
})(ManageSOWRenewalStatus);
