import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { startRequest, finishRequest } from '../../../common/actions/loadingActions';
import http from '../../../services/http';
import { GET_CLIENT_RATES_REPORTS, setClientRatesCounter, setClientRatesReports } from '../actions/clientRatesActions';

function* getClientRatesReports(action) {
  try {
    yield put(startRequest());
    const {
      filters, take, skip, searchValue, sortSettings,
    } = action.payload;
    const orderByDesc = !(sortSettings && sortSettings.sortDirection === 'Up');
    const clientRatesReports = yield call(http, {
      method: 'GET',
      url: `client-rates-reports?SearchValue=${
        encodeURIComponent(searchValue)}&skip=${skip}&take=${take}&SortField=${sortSettings && sortSettings.column}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setClientRatesCounter(clientRatesReports.data.TotalCount));
    yield put(setClientRatesReports([...clientRatesReports.data.ClientRates, {
      Employee: { Id: 0, Name: 'Grand Total per EUR' },
      Currency: '€-EUR',
      Total: clientRatesReports.data.GrandTotalPerEur,
    }, {
      Employee: { Id: 0, Name: 'Grand Total per USD' },
      Currency: '$-USD',
      Total: clientRatesReports.data.GrandTotalPerUsd,
    }]));
  } finally {
    yield put(finishRequest());
  }
}

export default function* reportsClientRatesSaga() {
  yield takeEvery(GET_CLIENT_RATES_REPORTS, getClientRatesReports);
}
