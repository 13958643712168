export const SET_COMPONENT = '@header/SET_COMPONENT';
export const RESET_COMPONENT = '@header/RESET_COMPONENT';

export const setComponent = ({
  title = null, returnButton = null, secondReturnButton = null,
}) => ({
  type: SET_COMPONENT,
  payload: {
    title,
    returnButton,
    secondReturnButton,
  },
});

export const resetComponent = () => ({
  type: RESET_COMPONENT,
  payload: {
    title: null,
    returnButton: null,
    secondReturnButton: null,
  },
});
