import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  setManagesPaymentTerms,
  GET_MANAGE_PAYMENT_TERMS,
  CREATE_NEW_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM,
} from '../actions/adminManagePaymentTerms';

import {
  startRequest,
  finishRequest,
} from 'common/actions/loadingActions';

export function* getManagePaymentTerms() {
  try {
    yield put(startRequest());
    const paymentTerms = yield call(http, {
      method: 'GET',
      url: 'payment_term',
    });

    yield put(setManagesPaymentTerms(paymentTerms.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addPaymentTerm(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'payment_term',
      data,
    });
    yield call(getManagePaymentTerms);
  } catch (e) {
    // error message
  }
}

export function* putPaymentTerm(action) {
  yield call(http, {
    method: 'PUT',
    url: `payment_term/${action.payload.Id}`,
    data: { Name: action.payload.Name },
  });
  yield call(getManagePaymentTerms);
}

export default function* adminManagePaymentTerms() {
  yield takeEvery(GET_MANAGE_PAYMENT_TERMS, getManagePaymentTerms);
  yield takeEvery(CREATE_NEW_PAYMENT_TERM, addPaymentTerm);
  yield takeEvery(UPDATE_PAYMENT_TERM, putPaymentTerm);
}
