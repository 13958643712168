export const GET_MANAGE_EMPLOYEE_TYPES = '@admin/GET_MANAGE_EMPLOYEE_TYPES';
export const SET_MANAGE_EMPLOYEE_TYPES = '@admin/SET_MANAGE_EMPLOYEE_TYPES';
export const PUT_MANAGE_EMPLOYEE_TYPES = '@admin/PUT_MANAGE_EMPLOYEE_TYPES';
export const POST_MANAGE_EMPLOYEE_TYPES = '@admin/POST_MANAGE_EMPLOYEE_TYPES';

export const getEmployeeTypes = () => ({
  type: GET_MANAGE_EMPLOYEE_TYPES,
});

export const setEmployeeTypes = (employeeTypes) => ({
  type: SET_MANAGE_EMPLOYEE_TYPES,
  payload: employeeTypes,
});

export const putEmployeeType = (employeeType) => ({
  type: PUT_MANAGE_EMPLOYEE_TYPES,
  payload: employeeType,
});

export const postEmployeeType = (employeeType) => ({
  type: POST_MANAGE_EMPLOYEE_TYPES,
  payload: employeeType,
});
