import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../../icon/Icon';
import { getProjectStatusColor, getStatusColor } from 'services/getStatusColor';

export class CustomStatusCell extends Component {
  getDefaultTitleText = () => {
    const { item } = this.props;
    const status = item.ProjectStatus || item.Status;
    const statusColor = status.split(' & ').filter(
      (item) => item === 'Approve SOW/PO' || item === 'Unblock Project' || item === 'Finish or Extend Project',
    );
    let returnString = '';

    if (!item.ProjectStatus && item.Comments.length && statusColor.length) {
      returnString += item.Status + '.  \n\n';
      item.Comments.forEach(element => {
        if ((element.DocumentStatus === 'Draft' && element.ApprovalStatus === 'Approve')
          || (element.DocumentStatus === 'Active' && element.ApprovalStatus === 'Not Approve')
          || (element.DocumentStatus === 'Expired' && element.ApprovalStatus === 'Approve')) {
          returnString += '• ' + element.ContractName + ' : "';
          returnString += (element.Comment !== null ? element.Comment : '');
          returnString += '". \n';
        }
      });
    } else if (item.Status !== 'Active' && item.Status !== 'Draft') {
      returnString += status;
    }
    return returnString;
  }

  getTitle = (isStatusInvalid) => {
    const { item, column, config } = this.props;
    const columnConfig = config[column.Id];

    if (columnConfig.getTitle) {
      return columnConfig.getTitle(item);
    }

    return isStatusInvalid ? 'Some statuses are crossing' : column.title && this.getDefaultTitleText();
  }

  render() {
    const { item, column } = this.props;
    const status = typeof item[column.Id] === 'object' ? item[column.Id].Name : item[column.Id];
    const isStatusInvalid = typeof item.isStatusesCrossing === 'boolean' && item.isStatusesCrossing;

    return (
      <div
        title={this.getTitle(isStatusInvalid)}
        className={classNames('c-list__item-column c-list__status', { 'small-col': column.className })}
      >
        <span
          className={classNames('col-span', { 'c-list__status--with-error': isStatusInvalid })}
          style={{
            background: column.isNeedProjectStatus ? getProjectStatusColor(status) : getStatusColor(status),
            color: column.isNeedProjectStatus
              ? getProjectStatusColor(status) !== 'transparent' ? '#FFFFFF' : '#000000'
              : getStatusColor(status) !== 'transparent' ? '#FFFFFF' : '#000000',
          }}
        >
          {status}
        </span>
        {
          column.WithOpenRole ? (
            item.UnderstaffedRoles || item.UnderstaffedRole ? (
              <CustomIcon iconName='open-role' className='open-role' title='No role assignment' />
            ) : null
          ) : null
        }
      </div>
    );
  }
}

CustomStatusCell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
