import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, changeViewFinancInfo } from 'common/actions/authActions';
import CustomIcon from 'common/components/icon/Icon';
import { Notification } from '../notification/notification';
import { clearNotify } from '../../actions/notificationsActions';
import Alert from 'react-s-alert';
import { CustomMessage } from '../notification/notification-message';
import { Badge } from 'common/components/badges/badges';
import Checkbox from 'common/components/form-items/checkbox/checkbox';
import { getColorFromString } from 'services/getColor';
import 'react-s-alert/dist/s-alert-default.css';
import './header.css';

export class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserMenuExpanded: false,
      isNotificationOpen: false,
      allNotifications: [],
      showFinancTogling: false,
    };
  }

  componentDidMount() {
    const notifications = JSON.parse(sessionStorage.getItem('notifications'));
    if (notifications) {
      this.setState({
        allNotifications: notifications.notifications,
      });
    }
    this.isShowFinanceTogling();
  }

  componentDidUpdate(prevProps) {
    const { notify } = this.props;
    if (prevProps.notify !== notify) {
      const notifications = JSON.parse(sessionStorage.getItem('notifications'));
      this.setState({
        allNotifications: notifications.notifications,
      }, () => this.showAlert());
    }
  }

  isShowFinanceTogling = () => {
    const { HasFinancialAccess } = this.props;
    this.setState({
      showFinancTogling: HasFinancialAccess,
    });
  }

  showAlert = () => {
    const { allNotifications } = this.state;
    if (allNotifications.length) {
      const item = allNotifications[0];
      const alertMessage = this.getInfoForNotification(item);
      Alert.warning('custom', {
        timeout: 3000,
        offset: 0,
        customFields: {
          ...alertMessage,
        },
      });
    }
  }

  getInfoForNotification = (item) => {
    const status = item.statusText ? 'Created' : 'error';
    const statusForRender = item.customText ? item.customText : item.statusText ? ' is created' : ' is not created';
    return {
      message: item.target + statusForRender,
      icon: item.target + '-' + status,
    };
  }

  deleteNotify = (e) => {
    const target = +e.currentTarget.attributes.getNamedItem('data-notification').value;
    const { allNotifications } = this.state;
    const filteredNotify = allNotifications.filter((item, i) => i !== target && item);
    this.setState({
      allNotifications: filteredNotify,
    });
    sessionStorage.setItem('notifications', JSON.stringify({ notifications: filteredNotify }));
  }

  expandUserMenu = () => {
    const { isUserMenuExpanded } = this.state;
    this.setState({
      isUserMenuExpanded: !isUserMenuExpanded,
    });
  }

  collapseUserMenu = () => {
    this.setState({
      isUserMenuExpanded: false,
    });
  }

  toogleNotification = () => {
    const { isNotificationOpen } = this.state;
    this.setState({
      isNotificationOpen: !isNotificationOpen,
    });
  }

  clearNotifications = () => {
    const { clearNotify } = this.props;
    sessionStorage.setItem('notifications', JSON.stringify({ notifications: [] }));
    this.setState({
      allNotifications: [],
    });
    clearNotify();
  }

  changeFinacncialInfo = () => {
    const { changeViewFinancInfo, permissions } = this.props;
    changeViewFinancInfo(!permissions.HasFinancialAccess);
  }

  renderReturnButton = ({ text, goToPreviousPage }) => {
    return (
      <div className='back-button' onClick={goToPreviousPage}>
        <CustomIcon iconName='arrow-left-details' />
        <span>{text}</span>
      </div>);
  }

  render() {
    const {
      user, logout, permissions, returnButton, secondReturnButton, title,
    } = this.props;
    const {
      isUserMenuExpanded, isNotificationOpen, allNotifications, showFinancTogling,
    } = this.state;
    return (
      <div className='header-cont'>
        <div className='main-part'>
          <div className='back-button-wrapper'>
            {title ? <div className='projects-amount'><span>{title}</span></div> : null}
            {returnButton ? this.renderReturnButton(returnButton) : null}
            {secondReturnButton ? this.renderReturnButton(secondReturnButton) : null}
          </div>
          {<Alert
            stack={{ limit: 1 }}
            contentTemplate={CustomMessage}
          />}
          {isNotificationOpen ? (
            <Notification
              onClose={this.toogleNotification}
              items={allNotifications}
              clear={this.clearNotifications}
              deleteItem={this.deleteNotify}
              getInfoForNotification={this.getInfoForNotification}
            />
          ) : null}
          <div className='main-part-item'>
            <div className='notifications' onClick={this.toogleNotification}>
              {allNotifications.length ? (
                <CustomIcon iconName='bell_with_notification' />
              ) : (
                <CustomIcon iconName='bell_without_notification' />
              )}
            </div>
          </div>
        </div>
        <div
          className='user-menu-container'
          onMouseLeave={this.collapseUserMenu}
          onClick={this.expandUserMenu}
        >
          <div className='user-photo'>
            <Badge
              color={getColorFromString(user.UserName)}
              item={user.UserName}
            />
          </div>
          <div className='user-name'>
            <span>{user.UserName}</span>
          </div>
          <div className={classNames('user-menu-expander', { 'expanded__user-menu__expander': isUserMenuExpanded })}>
            <svg width='12' height='5' viewBox='0 0 12 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M0 0L5.97518 5L11.9504 0H0Z' fill='#4B4B4B' />
            </svg>
          </div>
          <div className={classNames('user-menu', { 'expanded-user-menu': isUserMenuExpanded })}>
            {showFinancTogling && <div className='user-menu-option'>
              <Checkbox
                value='Hide financial info'
                onClick={this.changeFinacncialInfo}
                checked={!permissions.HasFinancialAccess}
              />
            </div>}
            <div className='user-menu-option' onClick={logout}>
              <span>Log out</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HeaderComponent.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func,
  clearNotify: PropTypes.func.isRequired,
  notify: PropTypes.array.isRequired,
};

export default connect(store => ({
  user: store.authReducer.user,
  notify: store.showRoles.notifications,
  permissions: store.authReducer.user && store.authReducer.user.Permissions,
  HasFinancialAccess: store.authReducer.HasFinancialAccess,
  title: store.headerReducer.title,
  returnButton: store.headerReducer.returnButton,
  secondReturnButton: store.headerReducer.secondReturnButton,
}), {
  logout,
  clearNotify,
  changeViewFinancInfo,
})(HeaderComponent);
