import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../icon/Icon';

export class ListColumns extends Component {
  renderGroupArrows = (column) => {
    const { setSortSettings, sortColumnName, sortDirection } = this.props;
    const selectedUp = sortColumnName === column && sortDirection === 'Up' ? 'selected' : 'not-selected';
    const selectedDown = sortColumnName === column && sortDirection === 'Down' ? 'selected' : 'not-selected';
    const iconNameUp = `arrow-up-${selectedUp}`;
    const iconNameDown = `arrow-down-${selectedDown}`;
    return (
      <>
        <CustomIcon
          iconName={iconNameUp}
          onClick={setSortSettings(column, 'Up')}
          className='pointer'
        />
        <CustomIcon
          iconName={iconNameDown}
          onClick={setSortSettings(column, 'Down')}
          className='pointer'
        />
      </>
    );
  }

  render() {
    const {
      columnsToDisplay, viewFinancialInfo,
    } = this.props;
    return (
      columnsToDisplay.map((column) => {
        if (!viewFinancialInfo && column.isBudget) return null;
        return (column.isSelected || typeof column.isSelected === 'undefined') && (
          <div
            key={column.Id}
            className={classNames(
              'c-list__header-col',
              column.className ? column.className : 'small-col',
              column.hasBorder,
              column.Id === 'EndDate' ? 'end-date-col-with-warn' : undefined,
              column.Id === 'SowPo' ? 'sow-po-col-with-warn' : undefined,
            )}
          >
            <span
              className='col-span col-span-header'
              title={column.Name}
            >{column.Name}</span>
            {!column.hideArrows
              && <div className={classNames(
                'c-list__arrows',
                { [column.deleteArrow]: !!column.deleteArrow },
              )}
              >
                {this.renderGroupArrows(column.Id)}
              </div>}
          </div>);
      })
    );
  }
}

ListColumns.propTypes = {
  columnsToDisplay: PropTypes.array.isRequired,
  viewFinancialInfo: PropTypes.bool,
  setSortSettings: PropTypes.func,
  sortColumnName: PropTypes.string,
  sortDirection: PropTypes.string,
};
