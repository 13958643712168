import React from 'react';
import classNames from 'classnames';
import InputCurrency from 'common/components/form-items/input-currency/input-currency';
import Select from 'common/components/form-items/select/select';
import Checkbox from 'common/components/form-items/checkbox/checkbox';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import CustomIcon from 'common/components/icon/Icon';
import formatCurrency from 'utils/formatCurrency';
import {
  MILESTONE_APPROVED_COLUMN_NAME,
  MILESTONE_BACKUP_USED_COLUMN_NAME,
  MILESTONE_INVOICE_AMOUNT_COLUMN_NAME,
  MILESTONE_INVOICED_DATE_COLUMN_NAME,
  MILESTONE_REMAINING_BALANCE_COLUMN_NAME,
  MILESTONE_REVENUE_RECEIVED_COLUMN_NAME
} from 'utils/const-variable';
import { connect } from 'react-redux';
import moment from 'moment';

const ITEM_HEIGHT = 36;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12,
    },
  },
};

class MilestoneForm extends React.Component {
  render() {
    const {
      item, onChange, selectOptionForMilestone, key, onDeleteItem, onCopyItem, disabled, onTogglingEditMode,
      currencyProject, columnsToDisplay, disableInvoicedAmount,
    } = this.props;
    const columnNames = new Set(columnsToDisplay.map((column) => column.Name));
    const invoicedAmount = disableInvoicedAmount
      ? ''
      : (item && typeof item.InvoicedAmount === 'number' ? item.InvoicedAmount.toString() : item.InvoicedAmount);

    return (
      <div className='milestone-item__container' key={key}>
        <div className='step__form--milestone-block'>
          {item.Id ? item.Milestone : <Select
            formClassName={classNames('step__form--milestone-block--select')}
            id={`milestone-${item.Id}`}
            placeholder='Choose Milestone'
            value={item && item.Milestone}
            inputProps={{
              name: 'Milestone',
              id: `Milestone-${item.Id}`,
            }}
            selectOptions={selectOptionForMilestone}
            onChange={onChange}
            isRequired
            MenuProps={MenuProps}
          />}
        </div>
        <div className='step__form--milestone-block'>
          <InputCurrency
            formClassName={classNames('step__form--milestone-block--text-field')}
            id={`milestoneAmount-${item.Id}`}
            placeholder='Add Milestone Amount'
            name='MilestoneAmount'
            currency
            value={item && typeof item.MilestoneAmount === 'number' ? item.MilestoneAmount.toString() : item.MilestoneAmount}
            onChange={onChange}
            showClear={false}
            isRequired
            startAdornment={formatCurrency(currencyProject)}
          />
        </div>
        {columnNames.has(MILESTONE_INVOICE_AMOUNT_COLUMN_NAME) && (
          <div className='step__form--milestone-block'>
            <InputCurrency
              formClassName={classNames('step__form--milestone-block--text-field')}
              id={`invoiceAmount-${item.Id}`}
              placeholder='Add Invoice Amount'
              name='InvoicedAmount'
              currency
              value={invoicedAmount}
              onChange={onChange}
              showClear={false}
              startAdornment={formatCurrency(currencyProject)}
              disabled={disableInvoicedAmount}
            />
          </div>
        )}
        {columnNames.has(MILESTONE_REVENUE_RECEIVED_COLUMN_NAME) && (
          <div className='step__form--milestone-block'>
            <InputCurrency
              formClassName={classNames('step__form--milestone-block--text-field')}
              id={`revenueReceived-${item.Id}`}
              placeholder='Add Revenue Received'
              name='RevenueReceived'
              currency
              value={item && typeof item.RevenueReceived == 'number' ? item.RevenueReceived.toString() : item.RevenueReceived}
              onChange={onChange}
              showClear={false}
              startAdornment={formatCurrency(currencyProject)}
            />            
          </div>
        )}
        {columnNames.has(MILESTONE_INVOICED_DATE_COLUMN_NAME) && (
          <div className='step__form--milestone-block'>
            <DatePicker
              formClassName={classNames('step__form--milestone-block--date-picker')}
              id={`invoicedDate-${item.Id}`}
              placeholder='--/--/--'
              name='InvoicedDate'
              onChange={onChange}
              value={item && item.InvoicedDate}
            />
          </div>
        )}
        {columnNames.has(MILESTONE_REMAINING_BALANCE_COLUMN_NAME) && (
          <div className='step__form--milestone-block'>
            <InputCurrency
              formClassName={classNames('step__form--milestone-block--text-field')}
              id={`remainingBalance-${item.Id}`}
              placeholder={item.RemainingBalanceOverridePossibility ? 'Add Delta to Goal' : '---'}
              name='RemainingBalance'
              currency
              value={item && typeof item.RemainingBalance === 'number' ? item.RemainingBalance.toString() : item.RemainingBalance}
              onChange={onChange}
              endAdornment={item.RemainingBalanceOverridePossibility ? '*' : false}
              startAdornment={formatCurrency(currencyProject)}
              disabled={!item.RemainingBalanceOverridePossibility}
              allownegative
            />
          </div>
        )}
        {columnNames.has(MILESTONE_APPROVED_COLUMN_NAME) && (
          <div className='step__form--milestone-block'>
            <label className='checkbox-cont-list-item configuration-option check_role'>
              <input
                type='checkbox'
                onChange={onChange}
                checked={!!(item.Approved)}
                name='Approved'
              />
              <span className='configuration-option__checkbox' />
            </label>
          </div>
        )}
        {columnNames.has(MILESTONE_BACKUP_USED_COLUMN_NAME) && (
          <div className='step__form--milestone-block'>
            <Checkbox
              name='UsedAssignmentBackupUser'
              checkboxContainerStyle='checkbox-item'
              onClick={onChange}
              checked={item.UsedAssignmentBackupUser}
            />
          </div>
        )}
        <div className='step__form--milestone-block--action'>
          {columnNames.has(MILESTONE_REMAINING_BALANCE_COLUMN_NAME) && (
            <div
              className={classNames('MuiFormControl-root step__form--milestone-block--action--item')}
              onClick={onTogglingEditMode}
            >
              {!item.RemainingBalanceOverridePossibility
                ? <CustomIcon iconName='override' title='Override' />
                : <CustomIcon iconName='re-calculate' title='Re-calculate' />}
            </div>
          )}
          <div
            className={classNames(
              'MuiFormControl-root step__form--milestone-block--action--item action-copy',
              { 'milestone-item__action-disabled': disabled },
            )}
            onClick={onCopyItem}
          >
            <CustomIcon
              iconName='copy'
              title='Copy'
            />
          </div>
          <div className='MuiFormControl-root step__form--milestone-block--action--item' onClick={onDeleteItem}>
            <CustomIcon
              iconName='trash'
              title='Delete'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state, props) => ({
  disableInvoicedAmount: !!props.item.IsShadowed || state.projectsReducer.shadowingMilestones
    .map((m) => moment(m.milestoneDate))
    .some((d) => d.isSame(moment(props.item.Milestone), 'month')),
}))(MilestoneForm);
