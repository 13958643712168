export const GET_OFFICE_LOCATIONS = '@filters/GET_OFFICE_LOCATIONS';
export const SET_OFFICE_LOCATIONS = '@filters/SET_OFFICE_LOCATIONS';

export const loadOfficeLocations = () => {
  return {
    type: GET_OFFICE_LOCATIONS,
  };
};

export const setOfficeLocations = (filters) => {
  return {
    type: SET_OFFICE_LOCATIONS,
    payload: {
      filters,
    },
  };
};
