import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmptyObject from 'utils/isEmptyObject.js';
import moment from 'moment';
import {
  BarChart, Bar,
} from 'recharts';
import { STATUS_COLOR } from 'utils/const-variable';

export class CellBarChart extends Component {
  renderMiniBarCharts = (data) => {
    const { getDataForInsideComponent } = this.props;
    let dataForBarChart = [];
    const maxAssignment = 1;
    if (!isEmptyObject(data.Load)) {
      dataForBarChart = Object.entries(data.Load).reduce((acc, curValue) => {
        const sumBars = [];
        const dataForRender = {
          name: moment(curValue[0]).format('MM-DD-YYYY'),
          Billable: curValue[1] <= maxAssignment ? curValue[1] : maxAssignment,
          BillableOvertime: curValue[1] > maxAssignment ? curValue[1] - maxAssignment : null,
        };
        sumBars.push(dataForRender);
        return [...acc, ...sumBars];
      }, []);
    }
    return (
      <BarChart width={120} height={35} data={dataForBarChart} onClick={() => getDataForInsideComponent(dataForBarChart)} cursor='pointer'>
        <Bar dataKey='Billable' fill={STATUS_COLOR.slightlyDesaturatedBlue} stackId='someId' minPointSize={1} />
        <Bar dataKey='BillableOvertime' fill='red' stackId='someId' />
      </BarChart>
    );
  }

  render() {
    const { item } = this.props;
    return (
      <div className='c-list__item-column small-col'>
        <div className='sparkline'>
          {this.renderMiniBarCharts(item)}
        </div>
      </div>
    );
  }
}

CellBarChart.propTypes = {
  item: PropTypes.object.isRequired,
  getDataForInsideComponent: PropTypes.func.isRequired,
};
