import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_SOW_TYPES,
  PUT_MANAGE_SOW_TYPES,
  POST_MANAGE_SOW_TYPES,
  setManageSowTypes,
} from '../actions/adminManageSowTypes';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageSowTypes() {
  try {
    yield put(startRequest());
    const sowTypes = yield call(http, {
      method: 'GET',
      url: 'sow_types',
    });

    yield put(setManageSowTypes(sowTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putSowType(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `sow_types/${action.payload.Id}`,
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageSowTypes);
  } catch (e) {
    // error message
  }
}

export function* postSowType(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'sow_types',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageSowTypes);
  } catch (e) {
    // error message
  }
}

export default function* adminManageSowTypes() {
  yield takeEvery(GET_MANAGE_SOW_TYPES, manageSowTypes);
  yield takeEvery(PUT_MANAGE_SOW_TYPES, putSowType);
  yield takeEvery(POST_MANAGE_SOW_TYPES, postSowType);
}
