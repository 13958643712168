export const GET_PROJECTED_REVENUE_REPORTS = '@reports/GET_PROJECTED_REVENUE_REPORTS';
export const SET_PROJECTED_REVENUE_REPORTS = '@reports/SET_PROJECTED_REVENUE_REPORTS';
export const CHANGE_COLUMNS_TO_DISPLAY = '@reports/CHANGE_COLUMNS_TO_DISPLAY';
export const CHANGE_INNER_COLUMNS_TO_DISPLAY = '@reports/CHANGE_INNER_COLUMNS_TO_DISPLAY';
export const SET_FINANCE_LIST_SETTINGS = '@reports/SET_FINANCE_LIST_SETTINGS';
export const RESET_FINANCE_LIST_SETTINGS = '@reports/RESET_FINANCE_LIST_SETTINGS';
export const SET_FINANCE_LIST_DATES = '@reports/SET_FINANCE_LIST_DATES';
export const RESET_FINANCE_LIST_DATES = '@reports/RESET_FINANCE_LIST_DATES';

export const getProjectedRevenueReports = (search, column, sortDirection, filters) => ({
  type: GET_PROJECTED_REVENUE_REPORTS,
  payload: {
    search,
    column,
    sortDirection,
    filters,
  },
});

export const setProjectedRevenueReports = (reports) => ({
  type: SET_PROJECTED_REVENUE_REPORTS,
  payload: reports,
});

export const changeColumnsToDisplay = (columnsToDisplay) => ({
  type: CHANGE_COLUMNS_TO_DISPLAY,
  payload: { columnsToDisplay },
});

export const changeInnerColumnsToDisplay = (innerColumnsToDisplaySOWs) => ({
  type: CHANGE_INNER_COLUMNS_TO_DISPLAY,
  payload: { innerColumnsToDisplaySOWs },
});

export const setFinanceListSettings = (settings) => ({
  type: SET_FINANCE_LIST_SETTINGS,
  payload: settings,
});

export const resetFinanceListSettings = () => ({
  type: RESET_FINANCE_LIST_SETTINGS,
});

export const setFinanceListDates = (settings) => ({
  type: SET_FINANCE_LIST_DATES,
  payload: settings,
});

export const resetFinanceListDates = () => ({
  type: RESET_FINANCE_LIST_DATES,
});
