import React from 'react';
import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import { connect } from 'react-redux';
import isObjectNotEquals from 'utils/object-comparison';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import classNames from 'classnames';
import Button from '../components/button';
import { required, validateDate, complexRequired } from 'services/redux-form-validators.js';
import history from 'utils/history';
import { isCorrectRoleEmployeeAssignment } from '../../../../pages/projects/components/utils/isCorrectRoleAssignments';
import './project-edit-assignment-form.css';
import renderTextField from '../components/text-field';
import renderDatePicker from '../components/date-picker';
import renderSelect from '../components/select';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { setComponent, resetComponent } from 'common/actions/headerActions';

const defaultStyles = {
  container: 'redux-form__field-container standart-container-assignment',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const validateAssignment = (value, values, props, field) => {
  const condition = !!value || value === 0;
  return complexRequired(value, values, props, field, !condition);
};

const additionalValidatorForAssignment = (value) => {
  return isCorrectRoleEmployeeAssignment((value || value === 0) && value.toString()) ? false : 'Invalid assignment';
};

const validateStartDate = (value, values, props, field) => {
  return validateDate.startDate(value, values, props, field, 'EmployeeEndDate');
};

const validateEndDate = (value, values, props, field) => {
  return validateDate.endDate(value, values, props, field, 'EmployeeStartDate');
};

class ProjectEditAssignment extends React.Component {
  componentDidMount() {
    const {
      data, initialize, AllRoles, location, setComponent,
    } = this.props;
    !AllRoles.length && history.goBack({ state: location.state });
    initialize(data);
    setComponent({ title: 'EDIT ASSIGNMENT' });
  }

  componentDidUpdate(prevProps) {
    const { data, initialize } = this.props;
    if (isObjectNotEquals(prevProps.data, data)) {
      initialize(data);
    }
  }

  componentWillUnmount() {
    const { destroy, resetComponent } = this.props;
    destroy();
    resetComponent();
  }

  onSubmit = () => {
    const {
      onSubmit, location, isEditable, allData,
    } = this.props;
    if (!isEditable && !allData.syncErrors) {
      onSubmit(allData.values, () => history.goBack({ state: location.state }));
    }
  }

  getStartDateForAssigment = (StartDate) => {
    return [{
      date: StartDate,
      type: 'role',
    }];
  }

  getEndDateForAssigment = (EndDate) => {
    return [{
      date: EndDate,
      type: 'role',
    }];
  }

  render() {
    const {
      isEditable, EmployeeName, EmployeeStartDate, EmployeeEndDate, EmployeeAssignment, EmployeeComment, RoleType,
      primaryEmpl, roleEmployeeStatus, handleSubmit, Status, location, allData, editAssignment, AllRoles,
      showAlertDialog,
    } = this.props;
    const currentRole = AllRoles && AllRoles.find(role => role.Id === editAssignment.roleId);
    const { StartDate, EndDate } = currentRole || {};
    return (
      <form className='redux-form' onSubmit={handleSubmit}>
        <div className='redux-form-assignment__row redux-form__row--nargin-top'>
          <Field
            name='EmployeeName'
            value={EmployeeName}
            component={renderTextField}
            label='Employee'
            styles={defaultStyles}
            disabled
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
          />
          <div className='redux-form-assignment__row picker_container-form'>
            <Field
              name='EmployeeStartDate'
              value={EmployeeStartDate}
              component={renderDatePicker}
              onChange={this.onChange}
              validate={[required, validateStartDate, validateDate.isValid]}
              showAsterix
              isStartDate
              label='Start Date'
              pickerName='EmployeeStartDate'
              styles={defaultStyles}
              formClassName='edit-assignment__item-form assignment_picker-form'
              datePickerClassName='edit-SOW__date-picker'
              disabled={isEditable}
              minDate={StartDate}
              maxDate={EndDate}
              startDates={this.getStartDateForAssigment(StartDate)}
              endDates={this.getEndDateForAssigment(EndDate)}
              showAlertDialog={showAlertDialog}
              typeParent='Role'
              isNotContract
            />
            <Field
              name='EmployeeEndDate'
              value={EmployeeEndDate}
              component={renderDatePicker}
              onChange={this.onChange}
              validate={[required, validateEndDate, validateDate.isValid]}
              disabled={isEditable}
              isEndDate
              showAsterix
              label='End Date'
              pickerName='EmployeeEndDate'
              styles={defaultStyles}
              formClassName='edit-assignment__item-form assignment_picker-form'
              datePickerClassName='edit-SOW__date-picker'
              minDate={StartDate}
              maxDate={EndDate}
              startDates={this.getStartDateForAssigment(StartDate)}
              endDates={this.getEndDateForAssigment(EndDate)}
              showAlertDialog={showAlertDialog}
              typeParent='Role'
              isNotContract
            />
          </div>
          <Field
            name='EmployeeAssignment'
            value={EmployeeAssignment}
            component={renderTextField}
            onChange={this.onChange}
            label='Assignment'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form'
            inputClassName='edit-assignment__text-field'
            type='number'
            showAsterix
            validate={[additionalValidatorForAssignment, validateAssignment]}
          />
        </div>
        <div className='redux-form-offices__row redux-form__row--nargin-top'>
          <div className='role-page-project-radio__form-150 margin_container'>
            <RadioGroup
              aria-label='roleType'
              name='roleType'
              value={RoleType}
              row
            >
              <FormControlLabel
                disabled
                value='Primary'
                control={<Radio />}
                label='Primary'
              />
              <FormControlLabel
                disabled
                className='radio-margin-left'
                value='Shadow'
                control={<Radio />}
                label='Backup'
              />
            </RadioGroup>
          </div>
          <Field
            name='primaryEmpl'
            value={primaryEmpl}
            component={renderTextField}
            label='Employee'
            styles={defaultStyles}
            disabled
            placeholder='Primary Employee'
            formClassName='edit-assignment__item-form assignment_text-form_backup'
            inputClassName='edit-assignment__text-field'
          />
          <div className='assignment_select_container-form'>
            <Field
              name='Status'
              value={Status}
              component={renderSelect}
              options={roleEmployeeStatus}
              onChange={this.onChange}
              label='Status'
              placeholder='Status'
              styles={defaultStyles}
              disabled={isEditable}
              isOptionObject
              inputProps={{ id: 'Status' }}
              validate={required}
              showAsterix
              formClassName='edit-assignment__item-form assignment_text-form_backup'
              selectClassName='edit-SOW__select'
            />
          </div>
        </div>
        <div className='redux-form-offices__row redux-form__row--nargin-top'>
          <Field
            name='EmployeeComment'
            value={EmployeeComment}
            component={renderTextField}
            onChange={this.onChange}
            label='Comment'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form_backup'
            inputClassName='edit-assignment__text-field'
          />
        </div>
        <div className='redux-form__assignment--buttons'>
          <Button
            onClick={() => history.goBack({ state: location.state })}
            className='button cancel'
            text='Cancel'
          />
          <Button
            onClick={this.onSubmit}
            desible={isEditable}
            visible={!isEditable}
            className={classNames('button next', { 'btn-disabled': allData && allData.syncErrors })}
            text='Apply'
          />
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('ProjectEditAssignment', data)),
    destroy: () => dispatch(destroy('ProjectEditAssignment')),
    change: (field, value) => dispatch(change('ProjectEditAssignment', field, value)),
    showAlertDialog: (message) => dispatch(showAlertDialog(message)),
    setComponent: (component) => dispatch(setComponent(component)),
    resetComponent: () => dispatch(resetComponent()),
  };
};

export default connect(state => {
  const isFormReducerExists = state.form.ProjectEditAssignment && state.form.ProjectEditAssignment.values;
  return {
    EmployeeName: isFormReducerExists && state.form.ProjectEditAssignment.values.EmployeeName,
    EmployeeStartDate: isFormReducerExists && state.form.ProjectEditAssignment.values.EmployeeStartDate,
    EmployeeEndDate: isFormReducerExists && state.form.ProjectEditAssignment.values.EmployeeEndDate,
    EmployeeAssignment: isFormReducerExists && state.form.ProjectEditAssignment.values.EmployeeAssignment,
    primaryEmpl: isFormReducerExists && state.form.ProjectEditAssignment.values.primaryEmpl,
    Status: isFormReducerExists && state.form.ProjectEditAssignment.values.Status,
    allData: isFormReducerExists && state.form.ProjectEditAssignment,
    AllRoles: state.projectsReducer.projectDetails.AllRoles,
    editAssignment: state.roleModalReducer.editAssignment,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'ProjectEditAssignment',
})(ProjectEditAssignment));
