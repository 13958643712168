export const IMPORT_SOW = '@import/IMPORT_SOW';
export const SET_IMPORT_SOW = '@import/SET_IMPORT_SOW';
export const IMPORT_PO = '@import/IMPORT_PO';
export const SET_IMPORT_PO = '@import/SET_IMPORT_PO';
export const IMPORT_AMENDMENT = '@import/IMPORT_AMENDMENT';
export const SET_IMPORT_AMENDMENT = '@import/SET_IMPORT_AMENDMENT';
export const ADD_SOW_TO_PARTIAL_CONTRACT = '@import/ADD_SOW_TO_PARTIAL_CONTRACT';
export const ADD_PO_TO_PARTIAL_CONTRACT = '@import/ADD_PO_TO_PARTIAL_CONTRACT';
export const ADD_AMENDMENT_TO_PARTIAL_CONTRACT = '@import/ADD_AMENDMENT_TO_PARTIAL_CONTRACT';
export const ADD_ROLE_TO_PARTIAL_CONTRACT = '@import/ADD_ROLE_TO_PARTIAL_CONTRACT';
export const ADD_CONTRACT_TO_PARTIAL_CONTRACT = '@import/ADD_CONTRACT_TO_PARTIAL_CONTRACT';
export const REMOVE_ROLE = '@import/REMOVE_ROLE';
export const POST_ROLE = '@import/POST_ROLE';
export const SET_ROLE_TO_PARTIAL_CONTRACT = '@import/SET_ROLE_TO_PARTIAL_CONTRACT';
export const CANCEL_IMPORT = '@import/CANCEL_IMPORT';
export const DELETE_IMPORTED_CONTRACT = '@import/DELETE_IMPORTED_CONTRACT';
export const MOVE_ROLE_BACK_TO_CONTRACT = '@import/MOVE_ROLE_BACK_TO_CONTRACT';
export const STEP_BACK_IMPORT_ROLE = '@import/STEP_BACK_IMPORT_ROLE';
export const ADD_CONTRACT_CONFIG = '@import/ADD_CONTRACT_CONFIG';
export const START_IMPORT_LOADER = '@import/START_IMPORT_LOADER';
export const FINISH_IMPORT_LOADER = '@import/FINISH_IMPORT_LOADER';

export const importSow = (file, projectId) => ({
  type: IMPORT_SOW,
  payload: { file, projectId },
});

export const setImportSow = (file) => ({
  type: SET_IMPORT_SOW,
  payload: file,
});

export const importPo = (file, projectId, sowId) => ({
  type: IMPORT_PO,
  payload: { file, projectId, sowId },
});

export const setImportPo = (file) => ({
  type: SET_IMPORT_PO,
  payload: file,
});

export const importAmendment = (file, projectId, sowId) => ({
  type: IMPORT_AMENDMENT,
  payload: { file, projectId, sowId },
});

export const setImportAmendment = (file) => ({
  type: SET_IMPORT_AMENDMENT,
  payload: file,
});

export const addSowToPartialContract = (projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => ({
  type: ADD_SOW_TO_PARTIAL_CONTRACT,
  payload: {
    projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog,
  },
});
export const addPoToPartialContract = (projectId, SOWId, POMainId, isOnlyActive, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => ({
  type: ADD_PO_TO_PARTIAL_CONTRACT,
  payload: {
    projectId, SOWId, POMainId, isOnlyActive, isNeedToChangeDate, configPatchRequestsForConfirmDialog,
  },
});
export const addAmendmentToPartialContract = (projectId, SOWId, isOnlyActive, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => ({
  type: ADD_AMENDMENT_TO_PARTIAL_CONTRACT,
  payload: {
    projectId, SOWId, isOnlyActive, isNeedToChangeDate, configPatchRequestsForConfirmDialog,
  },
});

export const addContractToPartialContract = (file) => ({
  type: ADD_CONTRACT_TO_PARTIAL_CONTRACT,
  payload: file,
});

export const addRoleToPartialContract = (file) => ({
  type: ADD_ROLE_TO_PARTIAL_CONTRACT,
  payload: file,
});

export const postRole = (role) => ({
  type: POST_ROLE,
  payload: role,
});

export const setRoleToPartialContract = (file) => ({
  type: SET_ROLE_TO_PARTIAL_CONTRACT,
  payload: file,
});

export const removeRole = (file) => ({
  type: REMOVE_ROLE,
  payload: file,
});

export const cancelImport = (projectId) => ({
  type: CANCEL_IMPORT,
  payload: projectId,
});

export const deleteImportedContract = () => ({
  type: DELETE_IMPORTED_CONTRACT,
});

export const moveRoleBackToContract = ({ importRoles, partialRoles }) => ({
  type: MOVE_ROLE_BACK_TO_CONTRACT,
  payload: { importRoles, partialRoles },
});
export const stepBackImportRole = () => ({
  type: STEP_BACK_IMPORT_ROLE,
});

export const addContractConfig = (payload) => ({
  type: ADD_CONTRACT_CONFIG,
  payload,
});

export const startImportLoader = () => ({
  type: START_IMPORT_LOADER,
});

export const finishImportLoader = () => ({
  type: FINISH_IMPORT_LOADER,
});
