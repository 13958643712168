import isEmptyObject from 'utils/isEmptyObject';
import { getAmericanFormat } from 'services/getAmericanFormatDigit';
import { getTotalPercent } from 'services/getTotalPercentForRenewalStatuses';
import { TOTAL_PERCENT_FOR_RENEWAL_STATUSES } from 'utils/const-variable';
import moment from 'moment';

export const required = (value, values, props, field, message = 'Value required') => {
  if (!isEmptyObject(props.data)) {
    let isRequired;
    switch (typeof value) {
      case 'number':
        if (field === 'Budget' && value === 0) {
          isRequired = true;
        } else {
          isRequired = !!value;
        }
        break;
      case 'string':
        isRequired = !!value.trim();
        break;
      case 'object':
        isRequired = !isEmptyObject(value) && !!value;
        break;
      default:
        isRequired = !!value;
        break;
    }
    return isRequired ? false : message;
  }
};

export const currencyFormat = (value) => {
  if (value === null || value === 0) {
    return getAmericanFormat('0');
  }
  return getAmericanFormat(value);
};

export const complexRequired = (value, values, props, field, condition) => {
  return condition && required(value, values, props);
};

export const validateDate = {
  startDate: (value, values, props, field, EndDate = 'EndDate') => {
    if (!!value && moment(value).isValid() && moment(values[EndDate]).isValid()) {
      return moment(value).isSameOrBefore(moment(values[EndDate]), 'date')
        ? false
        : 'Start Date should be before End Date';
    }
  },
  endDate: (value, values, props, field, StartDate = 'StartDate') => {
    if (!!value && moment(value).isValid() && moment(values[StartDate]).isValid()) {
      return moment(value).isSameOrAfter(moment(values[StartDate]), 'date')
        ? false
        : 'End Date should be after Start Date';
    }
  },
  isValid: value => {
    return value === null || moment(value).isValid()
      ? false
      : 'Invalid date format';
  },
};

export const validateMilestone = (value, message = 'Value required') => {
  return !value || !value.find((item) => (
    item.Milestone === ''
    || item.MilestoneAmount === ''
    || (item.RemainingBalanceOverridePossibility
      && (item.RemainingBalance === '' || item.RemainingBalance === '-' || item.RemainingBalance === null))))
    ? false : message;
};

export const validateRenewalStatus = (value, message = 'Value required') => {
  const totalPercent = getTotalPercent(value);
  return !value || +totalPercent.toFixed(2) === TOTAL_PERCENT_FOR_RENEWAL_STATUSES ? false : message;
};
