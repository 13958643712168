export const SET_ROLES = '@roles/SET_ROLES';

export const setRoles = (roles) => {
  return {
    type: SET_ROLES,
    payload: {
      roles,
    },
  };
};
