import { combineReducers } from 'redux';
import {
  SHOW_DIALOG, CLOSE_DIALOG, CONFIRM_DELETE_DIALOG, SHOW_ALERT_DIALOG, CLOSE_ALERT_DIALOG, SHOW_BIG_ALERT_DIALOG,
} from '../actions/confirmationDialogActions';

const initialState = {
  isDialogOpened: false,
  actionData: {},
};

const confirmationDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        isDialogOpened: true,
        actionData: action.payload,
      };
    case CLOSE_DIALOG:
      return {
        isDialogOpened: false,
        actionData: {},
      };
    case CONFIRM_DELETE_DIALOG:
      return {
        isDialogOpened: false,
        actionData: action.payload,
      };
    default:
      return state;
  }
};

const alertInitialState = {
  alertIsOpened: false,
  errorMessage: '',
};

const alertDialogReducer = (state = alertInitialState, action) => {
  switch (action.type) {
    case SHOW_ALERT_DIALOG:
      return {
        alertIsOpened: true,
        errorMessage: action.payload,
      };
    case SHOW_BIG_ALERT_DIALOG:
      return {
        alertIsOpened: true,
        errorMessage: action.payload.message,
        errorData: action.payload.data,
      };
    case CLOSE_ALERT_DIALOG:
      return {
        alertIsOpened: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};

const dialogsReducer = combineReducers({
  confirmationDialogReducer,
  alertDialogReducer,
});

export default dialogsReducer;
