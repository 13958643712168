import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from '../../../../../utils/applicationTitle';
import ListToolbar from '../../../../../common/components/list-toolbar/list-toolbar';
import DocumentList from '../../../../../common/components/documents-list/documents-list';
import { recursiveSearch } from '../../../../../services/recursiveSearch';
import { sortList } from '../../../../../common/components/list/sortList';
import { UpdateManageItem } from '../../../../../common/components/documents-list/update-manage-item';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBackFillTypes, postBackFillTypes, putBackFillTypes } from '../../../actions/adminManageBackFillTypes';
import { showDialog } from '../../../../../common/actions/confirmationDialogActions';
import { setListSettingsLookups } from '../../../actions/adminActions';

export class ManageBackFillTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Backfill Types', isSelected: true, Id: 'Name',
        },
      ],
      showAddBackFillTypeFields: false,
      addBackFillType: { Name: '' },
      blockAddBackFillType: false,
      blockSaveType: false,
    };
  }

  componentDidMount() {
    const { getBackFillTypes } = this.props;
    getBackFillTypes();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewBackFillType = () => {
    const { blockAddBackFillType } = this.state;
    if (!blockAddBackFillType) {
      this.setState({
        showAddBackFillTypeFields: true,
      });
    }
  }

  getNewBackFillType = ({ target }) => {
    const { addBackFillType } = this.state;
    this.setState({
      addBackFillType: {
        ...addBackFillType,
        [target.name]: target.value,
      },
    }, this.newTypeValidation);
  }

  newTypeValidation = () => {
    const { addBackFillType } = this.state;
    this.setState({
      blockSaveType: !!addBackFillType.Name.trim(),
      blockAddBackFillType: !!addBackFillType.Name.trim(),
    });
  };

  newTypeCancel = () => {
    this.setState({
      addBackFillType: { Name: '' },
      showAddBackFillTypeFields: false,
      blockSaveType: false,
      blockAddBackFillType: false,
    });
  }

  newTypeApply = () => {
    const { postBackFillTypes } = this.props;
    const { blockSaveType, addBackFillType } = this.state;
    if (blockSaveType) {
      postBackFillTypes(addBackFillType);
      this.newTypeCancel();
    }
  }

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `back-fill-types/${element.Id}`,
      searchUpdateSaga: 'back-fill-types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putBackFillTypes } = this.props;
    putBackFillTypes(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  render() {
    const { backFillTypes, listSettings } = this.props;
    const {
      columnsToDisplay, showAddBackFillTypeFields, addBackFillType, blockAddBackFillType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};

    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsBackFillTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewBackFillType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search Backfill Type'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(backFillTypes, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.getElementForDelete}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {showAddBackFillTypeFields && <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addBackFillType}
              onChangeNewDocumentObj={this.getNewBackFillType}
              disableSaveDocument={blockAddBackFillType}
              newDocumentCancel={this.newTypeCancel}
              newDocumentApply={this.newTypeApply}
            />
          </div>
        </div>}
      </div>
    );
  }
}

ManageBackFillTypes.propTypes = {
  getBackFillTypes: PropTypes.func.isRequired,
  postBackFillTypes: PropTypes.func.isRequired,
  putBackFillTypes: PropTypes.func.isRequired,
};

export default connect((store) => ({
  backFillTypes: store.adminManageLookups.backFillTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  getBackFillTypes,
  putBackFillTypes,
  postBackFillTypes,
  showDialog,
  setListSettingsLookups,
})(ManageBackFillTypes);
