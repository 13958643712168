import React from 'react';
import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import { connect } from 'react-redux';
import compareArraysByItems from 'utils/compareArraysByItems';
import isObjectNotEquals from 'utils/object-comparison';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';
import { changeProjectBasicInfo } from 'common/actions/reduxFormActions';
import CustomIcon from 'common/components/icon/Icon';
import { getProjectStatusColor } from 'services/getStatusColor';
import { renderProjectTypeIcon } from 'services/renderProjectTypeIcon';
import {
  NON_BILLABLE_TRAINING,
  fieldDate,
  MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS,
  PROJECT_ROLES_STATUS_ACTIVE,
  STATUS_BILLABLE,
  STATUS_INVESTED_CLIENT,
  STATUS_INVESTED_AKVELON,
  STATUS_INTERNAL,
} from 'utils/const-variable';
import { required, currencyFormat } from 'services/redux-form-validators.js';
import { validateManagers } from './utils/validateManagers';
import renderTogglingSearchDropdown from './components/toggling/toggling-search-dropdown';
import renderTogglingMultiSelect from './components/toggling/toggling-multi-select';
import renderTogglingDatePicker from './components/toggling/toggling-date-picker';
import renderTogglingTextField from './components/toggling/toggling-text-field';
import renderTogglingMultiSuggestBox from './components/toggling/toggling-multi-suggest-box';
import renderTogglingSelect from './components/toggling/toggling-select';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import formatCurrency from 'utils/formatCurrency';
import { getConfigForMessageDialog } from 'pages/projects/components/utils/getConfigForMessageDialog';
import {
  getTheBiggestDateRangeForContracts, getTheBiggestStartDateForContracts,
} from 'pages/projects/components/utils/getTheBiggestDateRangeForContracts';
import { getTheBiggestDateMilestones } from 'pages/projects/components/utils/getTheBiggestDateMilestones';
import moment from 'moment';
import { getProjectDetails, updateFiscalYears } from 'pages/projects/actions/projectsActions';
import { getId } from 'services/location';
import './redux-form.css';

const defaultStyles = {
  container: 'redux-form__field-container standart-container',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const multiSelectStyles = {
  relcontClass: 'field__multi-select-rel-cont',
  contClass: 'field__multi-select-cont',
  optionContClass: 'field__multi-select-option-cont',
};

const validateClientManager = (value, values, props) => {
  if (values.ProjectType && values.ProjectType.Name !== NON_BILLABLE_TRAINING) {
    return required(value, values, props);
  }
};

class ProjectBasicInfoForm extends React.Component {
  constructor(props) {
    super(props);
    const { AccountManagerId } = props;
    this.isValidAccountManager = !!AccountManagerId;
    this.isEngagementTypeConfirmed = true;
    this.state = {
      showDelete: false,
      isValidDelivery: true,
      isValidEngineer: true,
      isValidAccount: true,
      checkValidDeliveryWithoutRequest: false,
      checkValidEngineerWithoutRequest: false,
      availableFiscalYears: [],
      fiscalYear: {
        Name: '',
      },
    };
  }

  componentDidMount() {
    this.onBlur();
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      initialize,
      allDeliveryManagers,
      allEngineeringManagers,
      allAccountManagers,
      projectDetails,
      allEngagementTypes,
    } = this.props;
    const { fiscalYear } = this.state;
    if (isObjectNotEquals(prevProps.data, data) || isObjectNotEquals(prevProps.projectDetails, projectDetails)) {
      initialize(data);
      this.validDelivery(undefined);
      this.validEngineer(undefined);
    }
    if (!compareArraysByItems(prevProps.allDeliveryManagers, allDeliveryManagers)) {
      this.validDelivery(undefined);
    }
    if (!compareArraysByItems(prevProps.allEngineeringManagers, allEngineeringManagers)) {
      this.validEngineer(undefined);
    }
    if (!compareArraysByItems(prevProps.allAccountManagers, allAccountManagers)) {
      this.validAccount();
    }
    if (!compareArraysByItems(prevProps.allEngagementTypes, allEngagementTypes)) {
      this.validAccount();
    }
    if (!fiscalYear.Id && projectDetails.FinancialData && projectDetails.FinancialData.AvailableFiscalYears.length) {
      const { AvailableFiscalYears, FiscalYear } = projectDetails.FinancialData;
      const availableFiscalYears = AvailableFiscalYears.map((e, i) => ({ Id: i + 1, Name: e }));
      const fiscalYear = availableFiscalYears.find((e) => e.Name === FiscalYear);
      this.setState({
        availableFiscalYears,
        fiscalYear,
      });
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  validDelivery = (value) => {
    const { allDeliveryManagers, data, isLoading } = this.props;
    if (!isLoading) {
      const validationDelivery = validateManagers(value, allDeliveryManagers, data.DeliveryManagers);
      this.setState({
        isValidDelivery: validationDelivery.isValid,
        checkValidDeliveryWithoutRequest: validationDelivery.checkValidWithoutRequest,
      });
    }
  };

  validEngineer = (value) => {
    const { allEngineeringManagers, data, isLoading } = this.props;
    if (!isLoading) {
      const validationEng = validateManagers(value, allEngineeringManagers, data.EngineeringManagers);
      this.setState({
        isValidEngineer: validationEng.isValid,
        checkValidEngineerWithoutRequest: validationEng.checkValidWithoutRequest,
      });
    }
  };

  validAccount = (managerId) => {
    const { allAccountManagers, data, isLoading } = this.props;
    if (!isLoading) {
      const choosenManagerId = managerId || data.AccountManagerId;
      const validManager = allAccountManagers.find(manager => manager.Id === choosenManagerId);
      this.setState({
        isValidAccount: !!validManager,
      });
    }
  };

  checkValidStartAndEndDate = (value, field, startDate, endDate) => {
    switch (field) {
      case 'StartDate':
        return !moment(value).isValid() ? false : !endDate ? true : moment(value).isSameOrBefore(endDate, 'day');
      case 'EndDate':
        return !value || moment(value).isSameOrAfter(startDate, 'day');
      default: return false;
    }
  }

  onChange = (data, value, prevValue, field) => {
    if (field === 'AccountManagerId') {
      this.isValidAccountManager = !!value;
    }
    const {
      change, allClients, allClientManagers, allDeliveryManagers, allEngineeringManagers, allAccountManagers,
      projectContracts, showAlertDialog, projectDetails, EndDate, StartDate, projectMilestones, initialize, data: projectData,
      PrimaryOffices, allEngagementTypes, openEngagementTypeDialog, projectTeams, getProjectDetails, isOnlyActiveRoles, updateFiscalYears,
    } = this.props;
    const { fiscalYear } = this.state;
    const newValue = (allOptions) => allOptions.find(item => item.Id === value).Name;
    const newValues = (allOptions) => value.map(id => allOptions.find(item => item.value === id));
    const isCorrectStartAndEndDate = this.checkValidStartAndEndDate(value, field, StartDate, EndDate);
    const { forStartDate, forEndDate } = getTheBiggestDateRangeForContracts(projectContracts, true);
    const theBiggestStartDateContracts = getTheBiggestStartDateForContracts(projectContracts);
    const biggestDateMilestones = forEndDate && forEndDate.contract
           && getTheBiggestDateMilestones(forEndDate.contract.EndDate, projectMilestones, value, field);
    const isCorrectMilestoneDate = typeof biggestDateMilestones === 'boolean' ? biggestDateMilestones : true;
    const configMessageDialog = getConfigForMessageDialog(
      value,
      field,
      { forStartDate, forEndDate },
      theBiggestStartDateContracts,
      isCorrectStartAndEndDate,
    );
    const { isNewValueWrong, message } = configMessageDialog || {};
    const correctDateValue = isNewValueWrong ? prevValue : value;
    change('Year', fiscalYear.Name);
    switch (field) {
      case 'ClientId':
        change('ClientName', newValue(allClients));
        change('ClientManagerId', '');
        change('ClientManagerName', '');
        this.onBlur();
        break;
      case 'ClientManagerId':
        change('ClientManagerName', newValue(allClientManagers));
        this.onBlur(field);
        break;
      case 'DeliveryManagers':
        change('DeliveryManagersValues', newValues(allDeliveryManagers));
        break;
      case 'EngineeringManagers':
        change('EngineeringManagersValues', newValues(allEngineeringManagers));
        break;
      case 'AccountManagerId':
        change('AccountManagerName', newValue(allAccountManagers));
        this.onBlur();
        break;
      case 'EngagementTypeId': {
        const individuals = allEngagementTypes.find(item => item.Name === 'Individuals');
        if (individuals && (individuals.Id === value) && projectTeams.Teams.length) {
          this.isEngagementTypeConfirmed = false;
          openEngagementTypeDialog(() => {
            change('EngagementTypeName', newValue(allEngagementTypes));
            this.isEngagementTypeConfirmed = true;
            this.onBlur(field);
          });
        } else {
          change('EngagementTypeName', newValue(allEngagementTypes));
          this.isEngagementTypeConfirmed = true;
          this.onBlur(field);
        }
        break;
      }
      case 'PrimaryOffices':
        setTimeout(() => {
          change(field, value.length ? value : prevValue);
        }, 0);
        !value.length && showAlertDialog(MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS);
        break;
      case 'Offices':
        setTimeout(() => {
          const newPrimaryOffices = PrimaryOffices.filter(item => value.find(elem => item === elem));
          change(field, value.length ? value : prevValue);
          change('PrimaryOffices', value.length ? newPrimaryOffices : PrimaryOffices);
        }, 0);
        !value.length && showAlertDialog(MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS);
        break;
      case 'StartDate':
      case 'EndDate':
        setTimeout(() => {
          change(field, correctDateValue);
          !isCorrectMilestoneDate && initialize(projectData);
        }, 0);
        isNewValueWrong && showAlertDialog(message);
        !isNewValueWrong && correctDateValue !== projectDetails[field] && this.onBlur(fieldDate, isCorrectMilestoneDate);
        break;
      case 'ProjectType':
        this.onBlur();
        break;
      case 'Currency':
        this.onBlur();
        break;
      case 'FiscalYear':
        if (fiscalYear.Name !== value.Name) {
          getProjectDetails(projectDetails.Id, isOnlyActiveRoles, true, { fiscalYear: value.Name });
          updateFiscalYears({ [getId()]: value.Name });
          this.setState({
            fiscalYear: value,
          });
        }
        break;
      case 'Budget':
        change('Budget', value);
        break;
      default: break;
    }
  }

  onBlur = (event, isCorrectMilestoneDate) => {
    const {
      changeProjectBasicInfo, showAlertDialog, valid, isOnlyActiveRoles, projectBasicInfoForm, fiscalYears,
    } = this.props;
    if (projectBasicInfoForm
          && projectBasicInfoForm.syncErrors
          && projectBasicInfoForm.syncErrors.AccountManagerId
          && !this.isValidAccountManager) {
      showAlertDialog('Please add Business Development Manager to a Project');
      return;
    }
    const {
      isValidDelivery, isValidEngineer, isValidAccount,
      checkValidDeliveryWithoutRequest, checkValidEngineerWithoutRequest,
    } = this.state;
    if (!valid
        && event !== 'ClientManagerId'
        && event !== 'EngagementTypeId'
        && event !== fieldDate
        && projectBasicInfoForm
        && projectBasicInfoForm.syncErrors) {
      let message = 'Data entered incorrectly and will not be saved';
      if (Object.keys(projectBasicInfoForm.syncErrors).length === 1 && projectBasicInfoForm.syncErrors.AccountManagerId) {
        message = null;
      }
      if (message) {
        showAlertDialog(message);
      }
    }
    const params = {};
    const projectId = getId();
    if (fiscalYears[projectId]) {
      params.FiscalYear = fiscalYears[projectId];
    }
    if (event && !!event.target) {
      changeProjectBasicInfo(isOnlyActiveRoles, params);
    } else {
      if (
        ((event === fieldDate) && isCorrectMilestoneDate)
        || (
          (event !== fieldDate)
          && ((isValidDelivery || checkValidDeliveryWithoutRequest) && (isValidEngineer || checkValidEngineerWithoutRequest) && isValidAccount)
        )
      ) {
        if (this.isEngagementTypeConfirmed) {
          changeProjectBasicInfo(isOnlyActiveRoles, params);
        }
      } else {
        !isCorrectMilestoneDate && (event === fieldDate)
          ? showAlertDialog('There are milestones with end date greater than project end date.')
          : showAlertDialog('Data entered incorrectly and will not be saved');
      }
    }
  }

  toggleDelete = position => () => {
    this.setState({
      showDelete: position,
    });
  }

  getTextForTooltip = () => {
    return (<p>
      <span>Committed Quota Goal values are updated in CRM and synced with Theodolite on a daily basis</span>
    </p>);
  }

  bookedRevenueFormat = (value) => {
    return !!value ? value : null;
  }

  roundHeadcountUtilization = (value) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  getHeadcount = () => {
    const { projectDetails } = this.props;

    const headcount = {
      totalHeadcount: 0,
      totalHeadcountUtilization: 0,
      billableResources: 0,
      billableResourcesUtilization: 0,
      investedResources: 0,
      investedResourcesUtilization: 0,
      internalResources: 0,
      internalResourcesUtilization: 0,
    };

    if (projectDetails.AllRoles) {
      for (const role of projectDetails.AllRoles) {
        if (role.Status && role.Status.Name !== PROJECT_ROLES_STATUS_ACTIVE) {
          continue;
        }

        for (const employee of role.Employees) {
          if (employee.Status && employee.Status.Name === PROJECT_ROLES_STATUS_ACTIVE) {
            if (role.BillableStatus && role.BillableStatus.Name === STATUS_BILLABLE) {
              headcount.billableResources++;
              headcount.billableResourcesUtilization += employee.Assignment;
            } else if (role.BillableStatus && (role.BillableStatus.Name === STATUS_INVESTED_CLIENT
              || role.BillableStatus.Name === STATUS_INVESTED_AKVELON)) {
              headcount.investedResources++;
              headcount.investedResourcesUtilization += employee.Assignment;
            } else if (role.BillableStatus && role.BillableStatus.Name === STATUS_INTERNAL) {
              headcount.internalResources++;
              headcount.internalResourcesUtilization += employee.Assignment;
            }

            headcount.totalHeadcount++;
            headcount.totalHeadcountUtilization += employee.Assignment;
          }
        }
      }
    }

    headcount.totalHeadcountUtilization = this.roundHeadcountUtilization(headcount.totalHeadcountUtilization);
    headcount.billableResourcesUtilization = this.roundHeadcountUtilization(headcount.billableResourcesUtilization);
    headcount.investedResourcesUtilization = this.roundHeadcountUtilization(headcount.investedResourcesUtilization);
    headcount.internalResourcesUtilization = this.roundHeadcountUtilization(headcount.internalResourcesUtilization);

    return headcount;
  }

  render() {
    const {
      showDelete, isValidDelivery, isValidEngineer, isValidAccount, availableFiscalYears, fiscalYear,
    } = this.state;
    const {
      isEditable, viewFinancialInfo, allClients, allOffices, allClientManagers, allDeliveryManagers, allEngineeringManagers, allAccountManagers,
      allBillingTypes, Name, Aliases, Status, ProjectType, ClientId, ClientName, Offices, StartDate, EndDate, RealizedRevenue, ClientManagerId,
      ClientManagerName, DeliveryManagers, DeliveryManagersValues, EngineeringManagers, EngineeringManagersValues, AccountManagerId, AccountManagerName,
      ProjectCode, Description, deleteProject, handleSubmit, Currency, Budget, RemainingBalance, Leftover, allCurrencies, EditProjectBasicFinanceInfo,
      BookedRevenue, allEngagementTypes, EngagementTypeId, EngagementTypeName, PrimaryOffices, RevenueReceived, projectDetails, ProjectSize,
      ProjectLeftover, CurrentMonthMilestones,
    } = this.props;
    const showFinancialInfo = viewFinancialInfo && availableFiscalYears && availableFiscalYears.length > 0;
    const officesToShow = projectDetails.Offices && projectDetails.Offices.length ? projectDetails.Offices.map(office => office.Name).join(', ') : '';
    const primaryOfficesToShow = projectDetails.PrimaryOffices && projectDetails.PrimaryOffices.length
      ? projectDetails.PrimaryOffices.map(office => office.Name).join(', ')
      : '';
    const delivaryManagersToShow = DeliveryManagersValues && DeliveryManagersValues.length
      ? DeliveryManagersValues.map(manager => manager && manager.label).join(', ')
      : '';

    const engineeringManagersToShow = EngineeringManagersValues && EngineeringManagersValues.length
      ? EngineeringManagersValues.map(manager => manager && manager.label).join(', ')
      : '';
    const currencyName = formatCurrency(Currency && Currency.Name);
    const headcount = this.getHeadcount();
    return (
      <form className='redux-form' onSubmit={handleSubmit}>
        <div className='redux-form__row'>
          <div className='redux-form__header-inputs'>
            <Field
              name='Name'
              value={Name}
              component={renderTogglingTextField}
              onChange={this.onChange}
              onBlur={this.onBlur}
              isEditable={isEditable}
              valueToShow={String(Name).toUpperCase()}
              autoFocus
              maxlength='100'
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container header-inputs-container',
                fieldContent: 'field__view-content standart-content-padding header-inputs__border',
                fieldView: 'field__view-container justify-center',
              }}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
            />
            <Field
              name='Aliases'
              value={Aliases}
              component={renderTogglingTextField}
              onChange={this.onChange}
              onBlur={this.onBlur}
              isEditable={isEditable}
              valueToShow={Aliases && String(Aliases).toUpperCase()}
              autoFocus
              maxlength='50'
              styles={{
                ...defaultStyles,
                field: `${defaultStyles.field} field__padding--left`,
                container: 'redux-form__field-container header-inputs-container',
                fieldContent: 'field__view-content standart-content-padding',
                fieldView: 'field__view-container justify-center aliaces-color',
              }}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
            />
            <span className='header-inputs__status' style={{ background: getProjectStatusColor(Status) }}>{Status}</span>
            <Field
              name='ProjectType'
              value={ProjectType}
              options={allBillingTypes}
              component={renderTogglingSelect}
              onChange={this.onChange}
              isEditable={isEditable}
              valueToShow={ProjectType && ProjectType.Name}
              isOptionObject
              renderIcon={renderProjectTypeIcon}
              showClear={false}
              inputProps={{ id: 'ProjectType' }}
              styles={{
                ...defaultStyles,
                field: `${defaultStyles.field} field__padding--left`,
                container: 'redux-form__field-container header-inputs-container',
                fieldContent: 'field__view-content standart-content-padding',
                fieldView: 'field__view-container justify-center aliaces-color',
              }}
            />
          </div>
          {
            isEditable ? (
              <div className='c-list__options-column c-list__options-column__svg'>
                <CustomIcon iconName='three-dots' onClick={this.toggleDelete(true)} />
                {
                  showDelete && (
                    <div className='c-list-item__options-column__container' onMouseLeave={this.toggleDelete(false)}>
                      <div className='c-list-item__options-column__option' onClick={deleteProject}>
                        <span>Delete</span>
                      </div>
                    </div>
                  )
                }
              </div>
            ) : null
          }
        </div>
        <div className='redux-form__grid'>
          <Field
            name='ClientId'
            value={ClientId}
            options={allClients}
            component={renderTogglingSearchDropdown}
            onChange={this.onChange}
            onFocus={this.onFocus}
            validate={required}
            isEditable={isEditable}
            valueToShow={ClientName}
            inputProps={{ id: 'client' }}
            label='Client'
            isAddingFeature
            styles={defaultStyles}
            showAsterixOnFocus
          />
          <Field
            name='Offices'
            value={Offices}
            options={allOffices}
            component={renderTogglingMultiSelect}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validate={required}
            isEditable={isEditable}
            valueToShow={officesToShow}
            label='Offices'
            placeholder='Choose offices'
            styles={defaultStyles}
            multiSelectStyles={multiSelectStyles}
            showAsterixOnFocus
          />
          <Field
            name='PrimaryOffices'
            value={PrimaryOffices}
            options={Offices && allOffices.filter(item => Offices.find(elem => elem === item.Id))}
            component={renderTogglingMultiSelect}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validate={required}
            isEditable={isEditable}
            valueToShow={primaryOfficesToShow}
            label='Primary Offices'
            placeholder='Choose primary offices'
            styles={defaultStyles}
            multiSelectStyles={multiSelectStyles}
            showAsterixOnFocus
            showValueRequired
          />
          <Field
            name='StartDate'
            value={StartDate}
            component={renderTogglingDatePicker}
            onChange={this.onChange}
            isEditable={isEditable}
            isStartDate
            setPrevValueOnError
            label='Start Date'
            styles={defaultStyles}
            showClear={false}
            showAsterixOnFocus
          />
          <Field
            name='EndDate'
            value={EndDate}
            component={renderTogglingDatePicker}
            onChange={this.onChange}
            isEditable={isEditable}
            isEndDate
            setPrevValueOnError
            label='End Date'
            styles={defaultStyles}
            showClear={false}
          />
          <Field
            name='ProjectCode'
            value={ProjectCode}
            component={renderTogglingTextField}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isEditable={isEditable}
            valueToShow={ProjectCode}
            label='Project Code'
            placeholder='Set Project Code'
            maxlength='10'
            autoFocus
            styles={defaultStyles}
            formClassName='change-border__input__project'
            inputClassName='change-name__input'
          />
          <Field
            name='ClientManagerId'
            value={ClientManagerId}
            options={allClientManagers.filter(item => item.ClientId === ClientId)}
            component={renderTogglingSearchDropdown}
            onChange={this.onChange}
            validate={validateClientManager}
            isEditable={isEditable}
            valueToShow={ClientManagerName}
            inputProps={{ id: 'client_manager' }}
            label='Client Manager'
            autoFocus
            isAddingFeature
            parentEntityId={ClientId}
            styles={defaultStyles}
            showAsterixOnFocus
            showValueRequired
          />
          <Field
            name='DeliveryManagers'
            value={DeliveryManagers}
            values={DeliveryManagersValues}
            options={allDeliveryManagers}
            component={renderTogglingMultiSuggestBox}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isEditable={isEditable}
            valueToShow={delivaryManagersToShow}
            label='Technical Account Managers'
            placeholder='Choose Technical Account Managers'
            isMulti
            blockAddNewItem
            autoFocus
            styles={defaultStyles}
            additionalClassName='suggestbox__control-index'
            customMassageValidation={!isValidDelivery}
            additionalValidator={this.validDelivery}
          />
          <Field
            name='EngineeringManagers'
            value={EngineeringManagers}
            values={EngineeringManagersValues}
            options={allEngineeringManagers}
            component={renderTogglingMultiSuggestBox}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isEditable={isEditable}
            valueToShow={engineeringManagersToShow}
            label='Delivery Managers'
            placeholder='Choose Delivery Managers'
            isMulti
            blockAddNewItem
            autoFocus
            styles={defaultStyles}
            additionalClassName='suggestbox__control-index'
            customMassageValidation={!isValidEngineer}
            additionalValidator={this.validEngineer}
          />
          <Field
            name='AccountManagerId'
            value={AccountManagerId}
            options={allAccountManagers}
            component={renderTogglingSearchDropdown}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validate={required}
            isEditable={isEditable}
            valueToShow={AccountManagerName}
            inputProps={{ id: 'account_manager' }}
            label='Business Development Manager'
            autoFocus
            isAddingFeature
            styles={defaultStyles}
            customMassageValidation={!isValidAccount}
            additionalValidator={this.validAccount}
            showAsterixOnFocus
            showValueRequired
          />
          {
            showFinancialInfo && <>
              <Field
                name='Budget'
                value={Budget ? getAmericanFormat(Budget) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!Budget ? currencyName + getAmericanFormat(Budget) : `${currencyName}0`}
                label={`Committed Quota Goal ${fiscalYear.Name}`}
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                isTooltip
                textForTooltip={this.getTextForTooltip()}
                tooltipClassName='tooltip-budget--text'
                disableLetters
              />
              <Field
                name='BookedRevenue'
                value={BookedRevenue}
                component={renderTogglingTextField}
                valueToShow={typeof BookedRevenue === 'number' || !!BookedRevenue ? currencyName + getAmericanFormat(BookedRevenue) : `${currencyName}0`}
                label={`Booked Revenue ${fiscalYear.Name}`}
                format={this.bookedRevenueFormat}
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              <Field
                name='RealizedRevenue'
                value={RealizedRevenue ? getAmericanFormat(RealizedRevenue) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!RealizedRevenue ? currencyName + getAmericanFormat(RealizedRevenue) : `${currencyName}0`}
                label={`Invoiced Revenue ${fiscalYear.Name}`}
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                maxlength='16'
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              <Field
                name='Revenue Received'
                value={RevenueReceived ? getAmericanFormat(RevenueReceived) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!RevenueReceived ? currencyName + getAmericanFormat(RevenueReceived) : `${currencyName}0`}
                label={`Revenue Received  ${fiscalYear.Name}`}
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              <Field
                name='RemainingBalance'
                value={RemainingBalance ? getAmericanFormat(RemainingBalance) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!RemainingBalance ? currencyName + getAmericanFormat(RemainingBalance) : `${currencyName}0`}
                label={`Delta to Goal ${fiscalYear.Name}`}
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              <Field
                name='ProjectSize'
                value={ProjectSize ? getAmericanFormat(ProjectSize) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!ProjectSize ? currencyName + getAmericanFormat(ProjectSize) : `${currencyName}0`}
                label='Project Size'
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              <Field
                name='ProjectLeftover'
                value={ProjectLeftover ? getAmericanFormat(ProjectSize) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!ProjectLeftover ? currencyName + getAmericanFormat(ProjectLeftover) : `${currencyName}0`}
                label='Project Leftover'
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              {/* Div for empty space in grid */}
              <div />
              <Field
                name='CurrentMonthMilestone'
                value={CurrentMonthMilestones ? getAmericanFormat(CurrentMonthMilestones) : 0}
                component={renderTogglingTextField}
                format={currencyFormat}
                valueToShow={!!CurrentMonthMilestones ? currencyName + getAmericanFormat(CurrentMonthMilestones) : `${currencyName}0`}
                label={`${moment().format('MMM')} ${moment().year()} Milestone`}
                autoFocus
                currencyName
                startAdornment={`${currencyName} `}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
              />
              {/* Div for empty space in grid */}
              <div />
            </>
          }
          {
            EditProjectBasicFinanceInfo
            && <Field
              name='Currency'
              value={Currency}
              component={renderTogglingSelect}
              options={allCurrencies}
              label='Currency'
              isOptionObject
              showClear={false}
              isEditable={isEditable}
              onChange={this.onChange}
              inputProps={{ id: 'Currency' }}
              valueToShow={Currency && Currency.Name}
              styles={defaultStyles}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
            />
          }
          {
            showFinancialInfo && availableFiscalYears.length > 0
            && <Field
              name='FiscalYear'
              value={fiscalYear}
              component={renderTogglingSelect}
              options={availableFiscalYears}
              label='Fiscal Year'
              isOptionObject
              showClear={false}
              isEditable={isEditable}
              onChange={this.onChange}
              inputProps={{ id: 'FiscalYear' }}
              valueToShow={fiscalYear.Name}
              valueId={fiscalYear.Id}
              styles={defaultStyles}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
            />
          }
          <Field
            name='EngagementTypeId'
            value={EngagementTypeId}
            options={allEngagementTypes}
            component={renderTogglingSearchDropdown}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validate={required}
            isEditable={isEditable}
            valueToShow={EngagementTypeName}
            inputProps={{ id: 'engagement_type' }}
            label='Engagement Type'
            autoFocus
            styles={defaultStyles}
            showAsterixOnFocus
            showValueRequired
          />
        </div>
        <div className='redux-form__grid-without-margin'>
          <Field
            name='TotalHeadcount'
            value={`${headcount.totalHeadcount}/${headcount.totalHeadcountUtilization}`}
            valueToShow={`${headcount.totalHeadcount}/${headcount.totalHeadcountUtilization}`}
            component={renderTogglingTextField}
            label='Total Headcount/Utilization'
            styles={defaultStyles}
          />
          <Field
            name='BillableResources'
            value={`${headcount.billableResources}/${headcount.billableResourcesUtilization}`}
            valueToShow={`${headcount.billableResources}/${headcount.billableResourcesUtilization}`}
            component={renderTogglingTextField}
            label='Billable Resources/Utilization'
            styles={defaultStyles}
          />
          <Field
            name='InvestedResources'
            value={`${headcount.investedResources}/${headcount.investedResourcesUtilization}`}
            valueToShow={`${headcount.investedResources}/${headcount.investedResourcesUtilization}`}
            component={renderTogglingTextField}
            label='Invested Resources/Utilization'
            styles={defaultStyles}
          />
          <Field
            name='InternalResources'
            value={`${headcount.internalResources}/${headcount.internalResourcesUtilization}`}
            valueToShow={`${headcount.internalResources}/${headcount.internalResourcesUtilization}`}
            component={renderTogglingTextField}
            label='Internal Resources/Utilization'
            styles={defaultStyles}
          />
        </div>
        <Field
          name='Description'
          value={Description}
          component={renderTogglingTextField}
          onChange={this.onChange}
          onBlur={this.onBlur}
          isEditable={isEditable}
          valueToShow={Description}
          label='Description'
          placeholder='Set Description'
          maxlength='4000'
          autoFocus
          multiline
          fullWidth
          styles={{
            ...defaultStyles,
            container: 'redux-form__field-container flexible-container',
            field: 'full-width-field',
            fieldContent: 'description-content-padding align-center',
            fieldView: 'field__view-container',
          }}
          formClassName='change-border__input__project'
          inputClassName='change-name__input'
        />
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('projectBasicInfoForm', data)),
    destroy: () => dispatch(destroy('projectBasicInfoForm')),
    change: (field, value) => dispatch(change('projectBasicInfoForm', field, value)),
    changeProjectBasicInfo: (isOnlyActive, params) => dispatch(changeProjectBasicInfo(isOnlyActive, params)),
    showAlertDialog: (massage) => dispatch(showAlertDialog(massage)),
    getProjectDetails: (id, isOnlyActive, isLoading, params) => dispatch(getProjectDetails(id, isOnlyActive, isLoading, params)),
    updateFiscalYears: (payload) => dispatch(updateFiscalYears(payload)),
  };
};

export default connect(state => {
  const isFormReducerExists = state.form.projectBasicInfoForm && state.form.projectBasicInfoForm.values;
  return {
    Name: isFormReducerExists ? state.form.projectBasicInfoForm.values.Name : '',
    Aliases: isFormReducerExists ? state.form.projectBasicInfoForm.values.Aliases : '',
    Status: isFormReducerExists && state.form.projectBasicInfoForm.values.Status,
    ProjectType: isFormReducerExists && state.form.projectBasicInfoForm.values.ProjectType,
    ClientId: isFormReducerExists && state.form.projectBasicInfoForm.values.ClientId,
    ClientName: isFormReducerExists && state.form.projectBasicInfoForm.values.ClientName,
    Offices: isFormReducerExists && state.form.projectBasicInfoForm.values.Offices,
    StartDate: isFormReducerExists && state.form.projectBasicInfoForm.values.StartDate,
    EndDate: isFormReducerExists && state.form.projectBasicInfoForm.values.EndDate,
    RealizedRevenue: isFormReducerExists && state.form.projectBasicInfoForm.values.RealizedRevenue,
    PrimaryOffices: isFormReducerExists && state.form.projectBasicInfoForm.values.PrimaryOffices,
    ClientManagerId: isFormReducerExists && state.form.projectBasicInfoForm.values.ClientManagerId,
    ClientManagerName: isFormReducerExists && state.form.projectBasicInfoForm.values.ClientManagerName,
    DeliveryManagers: isFormReducerExists && state.form.projectBasicInfoForm.values.DeliveryManagers,
    DeliveryManagersNames: isFormReducerExists && state.form.projectBasicInfoForm.values.DeliveryManagersNames,
    DeliveryManagersValues: isFormReducerExists && state.form.projectBasicInfoForm.values.DeliveryManagersValues,
    EngineeringManagers: isFormReducerExists && state.form.projectBasicInfoForm.values.EngineeringManagers,
    EngineeringManagersNames: isFormReducerExists && state.form.projectBasicInfoForm.values.EngineeringManagersNames,
    EngineeringManagersValues: isFormReducerExists && state.form.projectBasicInfoForm.values.EngineeringManagersValues,
    AccountManagerId: isFormReducerExists && state.form.projectBasicInfoForm.values.AccountManagerId,
    AccountManagerName: isFormReducerExists && state.form.projectBasicInfoForm.values.AccountManagerName,
    ProjectCode: isFormReducerExists && state.form.projectBasicInfoForm.values.ProjectCode,
    Description: isFormReducerExists && state.form.projectBasicInfoForm.values.Description,
    Budget: isFormReducerExists && state.form.projectBasicInfoForm.values.Budget,
    RemainingBalance: isFormReducerExists && state.form.projectBasicInfoForm.values.RemainingBalance,
    Currency: isFormReducerExists && state.form.projectBasicInfoForm.values.Currency,
    BookedRevenue: isFormReducerExists && state.form.projectBasicInfoForm.values.BookedRevenue,
    Leftover: isFormReducerExists && state.form.projectBasicInfoForm.values.Leftover,
    EngagementTypeId: isFormReducerExists && state.form.projectBasicInfoForm.values.EngagementTypeId,
    EngagementTypeName: isFormReducerExists && state.form.projectBasicInfoForm.values.EngagementTypeName,
    Year: isFormReducerExists && state.form.projectBasicInfoForm.values.Year,
    RevenueReceived: isFormReducerExists && state.form.projectBasicInfoForm.values.RevenueReceived,
    ProjectSize: isFormReducerExists && state.form.projectBasicInfoForm.values.ProjectSize,
    ProjectLeftover: isFormReducerExists && state.form.projectBasicInfoForm.values.ProjectLeftover,
    CurrentMonthMilestones: isFormReducerExists && state.form.projectBasicInfoForm.values.CurrentMonthMilestones,
    projectBasicInfoForm: state.form.projectBasicInfoForm,
    isLoading: state.loadingReducer.isLoading,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'projectBasicInfoForm',
})(ProjectBasicInfoForm));
