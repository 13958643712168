import React from 'react';
import MultiSuggestBox from 'common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import { svgEditPencil } from 'services/svgEditPencil';
import classNames from 'classnames';
import '../../redux-form.css';

export default class renderTogglingMultiSuggestBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  toggleEditMode = (editMode) => this.setState({ editMode });

  onChange = () => data => {
    const { input, additionalValidator } = this.props;
    const values = data.map(item => item.value);
    additionalValidator && additionalValidator(values);
    input.onChange(values);
  }

  onBlur = () => {
    const { input } = this.props;
    this.toggleEditMode(false);
    input.onBlur();
  }

  render() {
    const { editMode } = this.state;
    const {
      input, meta, valueToShow, options, isEditable, label, placeholder, isMulti, blockAddNewItem, values, autoFocus, styles,
      additionalClassName, customMassageValidation,
    } = this.props;

    return (
      <div
        className={styles.container}
        onMouseLeave={this.onBlur}
      >
        {
          label && <label className={styles.label}>{label}</label>
        }
        <div className={styles.field}>
          {
            editMode && isEditable
              ? <div className='redax-form__multi-box-wrapper multi-box-wrapper'>
                <MultiSuggestBox
                  placeholder={placeholder}
                  className={additionalClassName}
                  onAdd={this.onChange()}
                  options={options}
                  suggesValue={values}
                  isMulti={isMulti}
                  blockAddNewItem={blockAddNewItem}
                  onBlur={this.onBlur}
                  autoFocus={autoFocus}
                />
              </div> : (
                <div className={styles.fieldView}>
                  <div
                    onClick={() => this.toggleEditMode(true)}
                    className={classNames(
                      styles.fieldContent,
                      { 'field--not--editable': !isEditable },
                      { 'field--editable': isEditable },
                      { 'field--invalid': (meta.invalid && meta.error !== 'Value required') },
                    )}
                    title={valueToShow}
                  >
                    <span>{valueToShow}</span>
                    {isEditable && svgEditPencil(input.name)}
                  </div>
                  {
                    (meta.invalid && meta.error !== 'Value required') && (
                      <div className={styles.fieldError}>
                        <span>{meta.error}</span>
                      </div>
                    )
                  }
                  {customMassageValidation ? (
                    <span className='custom_validation-form_multisuggest'>
                      Incorrect Managers by office
                    </span>
                  ) : null }
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
