/* eslint-disable object-property-newline */
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import {
  changeColumnsToDisplay,
  searchBilling,
  resetBillingDetails,
  setListSettings,
} from '../../actions/billingActions';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortFilterList } from 'common/components/list/sortList';
import { getSelectedTab } from '../../../../common/actions/selectedTabActions';
import isObjectNotEquals from 'utils/object-comparison';
import isEmptyObject from 'utils/isEmptyObject';
import { scrollToLastElement } from 'services/scrollToLastElement.js';
import { debounce } from 'services/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { setBillingDetails } from '../../actions/billingActions';
import { setComponent, resetComponent } from 'common/actions/headerActions';

const billingData = [{
  Id: 1,
  Project: 'Vouched',
  Contract: 'SOW-7',
  Type: 'Retainer',
  Period: '11/01/2019-11/30/2019',
  Tern: 'NET-15',
  Accountant: 'Iurii Gorokh',
  Approver: 'Microsoft',
  TotalHours: '808',
  TotalAmount: '$15.756.00',
  Status: 'Ready',
  ExpectedHours: 260,
  ApprovedOvertimeHours: '8',
  ActualTotalHours: 808,
  ApprovedExpences: 23000,
  TravelBudgetIncluded: false,
  TravelReimbursement: true,
  Roles: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
    {
      Id: 2, Employee: { Id: 2, Name: 'Maxim Nikitin' }, Office: 'UA', RoleName: 'SDE (offshore)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '1',
      TotalOvertime: '0', TotalHours: '0', Rate: 50, TotalAmount: 0,
    },
    {
      Id: 3, Employee: { Id: 3, Name: 'Tim Cook' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
  ],
  JustificationOvertime: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '8', OvertimeCoefficient: '1.5', Billable: true, Attachment: [{ name: 'Approve.png' }], Comments: '',
    },
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '8', OvertimeCoefficient: '1.5', Billable: true,
      Attachment: [{ name: 'Approve1.png' }, { name: 'Approve2.png' }], Comments: 'Approve',
    },
  ],
  JustificationOther: [
    {
      Id: 14, Title: 'Travel', Amount: 23000, Description: 'Reimbursement for tickets',
      Attachment: [{ name: 'Approve.png' }], Comments: 'To be paid next month the same amount',
    },
  ],
  Invoice: [
    {
      Invoice: [{ name: 'Approve.png' }], ActualAmountPaid: 0, Comments: 'Processed in quickbooks',
    },
  ],
},
{
  Id: 2,
  Project: 'Vouched (bad)',
  Contract: 'PO-11111',
  Type: 'T&M Actual Time',
  Period: '11/01/2019-11/30/2019',
  Tern: 'NET-15',
  Accountant: 'Oleksandr Bardakov',
  Approver: 'SomeClient',
  TotalHours: '1500',
  TotalAmount: '$67.900.00',
  Status: 'Insufficient',
  ExpectedHours: 260,
  ApprovedOvertimeHours: '8',
  ActualTotalHours: 1780,
  ApprovedExpences: 23000,
  TravelBudgetIncluded: false,
  TravelReimbursement: true,
  Roles: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
    {
      Id: 2, Employee: { Id: 2, Name: 'Maxim Nikitin' }, Office: 'UA', RoleName: 'SDE (offshore)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '0',
      TotalOvertime: '0', TotalHours: '0', Rate: 50, TotalAmount: 0,
    },
    {
      Id: 3, Employee: { Id: 3, Name: 'Tim Cook' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
  ],
  JustificationOvertime: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '8', OvertimeCoefficient: '1.5', Billable: true, Attachment: [{ name: 'Approve.png' }], Comments: '',
    },
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '8', OvertimeCoefficient: '1.5', Billable: true,
      Attachment: [], Comments: 'Approve',
    },
  ],
  JustificationOther: [
    {
      Id: 14, Title: 'Travel', Description: 'Reimbursement for tickets', Attachment: [{ name: 'Approve.png' }],
      Comments: 'To be paid next month the same amount',
    },
  ],
  Invoice: [
    {
      Invoice: [], ActualAmountPaid: 0, Comments: 'Processed in quickbooks',
    },
  ],
},
{
  Id: 3,
  Project: 'Microsoft',
  Contract: 'SOW-29',
  Type: 'T&M Actual Time',
  Period: '11/01/2019-11/30/2019',
  Tern: 'NET-15',
  Accountant: 'Lyudmyla Kolmykova',
  Approver: 'Client',
  TotalHours: '1500',
  TotalAmount: '$67.900.00',
  Status: 'Sent',
  ExpectedHours: 780,
  ApprovedOvertimeHours: '8',
  ActualTotalHours: 800,
  ApprovedExpences: 13000,
  TravelBudgetIncluded: false,
  TravelReimbursement: true,
  Roles: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
    {
      Id: 2, Employee: { Id: 2, Name: 'Maxim Nikitin' }, Office: 'UA', RoleName: 'SDE (offshore)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '1',
      TotalOvertime: '0', TotalHours: '0', Rate: 50, TotalAmount: 0,
    },
    {
      Id: 3, Employee: { Id: 3, Name: 'Tim Cook' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
  ],
  JustificationOvertime: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '4', OvertimeCoefficient: '1.5', Billable: true, Attachment: [{ name: 'Approve.png' }], Comments: '',
    },
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '4', OvertimeCoefficient: '1.5', Billable: true,
      Attachment: [{ name: 'Approve1.png' }, { name: 'Approve2.png' }], Comments: 'Approve',
    },
  ],
  JustificationOther: [
    {
      Id: 14, Title: 'Travel', Amount: 23000, Description: 'Reimbursement for tickets',
      Attachment: [{ name: 'Approve.png' }], Comments: 'To be paid next month the same amount',
    },
  ],
  Invoice: [
    {
      Invoice: [{ name: 'Approve.png' }], ActualAmountPaid: 0, Comments: 'Processed in quickbooks',
    },
  ],
},

{
  Id: 4,
  Project: 'Vouched',
  Contract: 'SOW-7',
  Type: 'Retainer',
  Period: '11/01/2019-11/30/2019',
  Tern: 'NET-15',
  Accountant: 'Iurii Gorokh',
  Approver: 'Microsoft',
  TotalHours: '808',
  TotalAmount: '$15.756.00',
  Status: 'Ready',
  ExpectedHours: 5000,
  ApprovedOvertimeHours: '8',
  ActualTotalHours: 500,
  ApprovedExpences: 23000,
  TravelBudgetIncluded: false,
  TravelReimbursement: true,
  Roles: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
    {
      Id: 2, Employee: { Id: 2, Name: 'Maxim Nikitin' }, Office: 'UA', RoleName: 'SDE (offshore)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '1',
      TotalOvertime: '0', TotalHours: '0', Rate: 50, TotalAmount: 0,
    },
    {
      Id: 3, Employee: { Id: 3, Name: 'Tim Cook' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Expected: '160', Actual: '152', TotalOvertime: '16', TotalHours: '167', Rate: 50, TotalAmount: 880000,
    },
  ],
  JustificationOvertime: [
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '8', OvertimeCoefficient: '1.5', Billable: true, Attachment: [{ name: 'Approve.png' }], Comments: '',
    },
    {
      Id: 1, Employee: { Id: 1, Name: 'Vlad Topalov' }, Office: 'US', RoleName: 'SDE (onsite)', RoleType: 'Software Development Engineer',
      Assignment: '1', Overtime: '8', OvertimeCoefficient: '1.5', Billable: true,
      Attachment: [{ name: 'Approve1.png' }, { name: 'Approve2.png' }], Comments: 'Approve',
    },
  ],
  JustificationOther: [
    {
      Id: 14, Title: 'Travel', Amount: 23000, Description: 'Reimbursement for tickets',
      Attachment: [{ name: 'Approve.png' }], Comments: 'To be paid next month the same amount',
    },
  ],
  Invoice: [
    {
      Invoice: [{ name: 'Approve.png' }], ActualAmountPaid: 0, Comments: 'Processed in quickbooks',
    },
  ],
}];

export class BillingListComponent extends React.Component {
  componentDidMount() {
    const {
      resetBillingDetails, getSelectedTab, listSettings, setComponent,
      totalCount = billingData.length,
    } = this.props;
    const { scrollTo } = listSettings;
    resetBillingDetails();
    getSelectedTab(0);
    if (scrollTo !== '') {
      scrollToLastElement('billing', scrollTo, true);
    }
    setComponent({ title: `Billing (${totalCount})` });
  }

  componentDidUpdate(prevProps) {
    const {
      searchBilling, totalCount, listSettings,
    } = this.props;
    const {
      filters, sortColumn, sortDirection, searchValue, amountToTake,
    } = listSettings;
    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      searchBilling(searchValue, filters, amountToTake, 0, { sortColumn, sortDirection });
    }
    if (prevProps.totalCount !== totalCount) {
      setComponent({ title: `Billing (${totalCount})` });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  setSearchValue = (searchValue) => {
    const { setListSettings } = this.props;
    setListSettings({ searchValue });
  }

  applyFilters = (filters) => {
    const { setListSettings } = this.props;
    const filtersToSend = !isEmptyObject(filters) ? filters : { isEmpty: true };
    setListSettings({ filters: filtersToSend });
  }

  resetFilters = () => {
    const { setListSettings } = this.props;
    setListSettings({ filters: {} });
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettings } = this.props;
    setListSettings({ sortColumn, sortDirection });
  }

  routeToProjectDetails = (id) => {
    const { setBillingDetails } = this.props;
    history.push({
      pathname: `billing/${id}`,
    });
    setBillingDetails(billingData.find(item => item.Id === id));
  }

  changeAmountBilling = (page) => {
    const {
      isLoading, setListSettings, totalCount, listSettings,
    } = this.props;
    const { amountToTake } = listSettings;
    const magnificationFactor = 10;
    const increaseParams = amountToTake + page * magnificationFactor;
    if (!isLoading) {
      setListSettings({
        amountToTake: increaseParams,
        hasMoreBilling: increaseParams < totalCount,
      });
    }
  }

  handleChangeDate = (date) => {
    this.setState({
      BillingPeriod: date ? date : null,
    });
  }

  render() {
    const {
      columnsToDisplay,
      changeColumnsToDisplay,
      showDialog,
      listSettings,
    } = this.props;
    const {
      searchValue,
      sortColumn,
      sortDirection,
      filters,
      hasMoreBilling,
    } = listSettings;
    const projects = [{ Id: 1, Name: 'someProject' }];
    const contracts = [{ Id: 1, Name: 'someSow' }, { Id: 2, Name: 'somePo' }];
    const sowType = [{ Id: 1, Name: 'Retainer' }, { Id: 2, Name: 'T&M Actual time' }];
    const billingTerm = [{ Id: 1, Name: 'NET 15' }];
    const status = [{ Id: 1, Name: 'Ready' }, { Id: 2, Name: 'Sent' }, { Id: 3, Name: 'Insufficient' }];
    return (
      <div className='page-cont'>
        <ListToolbar
          items={[{
            name: 'Projects',
            id: 'Projects',
            options: sortFilterList(projects) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Projects',
            filtersItem: filters && filters.Projects,
            className: 'z-index_50',
          }, {
            name: 'Contracts',
            id: 'Contracts',
            options: sortFilterList(contracts) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Contracts',
            filtersItem: filters && filters.Contracts,
            className: 'z-index_48',
          }, {
            name: 'SOWType',
            id: 'SOWType',
            options: sortFilterList(sowType) || [],
            suggestBox: true,
            placeholder: 'All Types',
            filtersItem: filters && filters.SOWType,
            className: 'z-index_46',
          }, {
            name: 'Billing Period',
            id: 'BillingPeriod',
            datepicker: true,
            isMonthAndYear: true,
            formClassName: 'step__form svg-calendar-add-info date_picker-toolbar',
            datePickerClassName: 'step__date-picker data-pisker__filter--margin-sow',
            value: filters.BillingPeriod,
            onChange: this.handleChangeDate,
            className: 'z-index_44',
          }, {
            name: 'Billing Term',
            id: 'BillingTerm',
            options: sortFilterList(billingTerm) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Technical Account Managers',
            filtersItem: filters && filters.BillingTerm,
            className: 'z-index_42',
          }, {
            name: 'Status',
            id: 'Status',
            options: sortFilterList(status) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Statuses',
            filtersItem: filters && filters.Status,
            className: 'z-index_42',
          }]}
          searchValue={searchValue}
          applyFilters={this.applyFilters}
          filters={filters}
          resetFilters={this.resetFilters}
          columnsToDisplay={columnsToDisplay}
          changeColumnsToDisplay={changeColumnsToDisplay}
          searchPlaceholder='Search Billing'
          onSearchClick={this.setSearchValue}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll' id='billing'>
            <InfiniteScroll
              pageStart={1}
              loadMore={debounce(this.changeAmountBilling, 500)}
              hasMore={hasMoreBilling}
              useWindow={false}
              initialLoad={false}
            >
              <List
                page='billing'
                columnsToDisplay={columnsToDisplay}
                items={billingData}
                onItemClick={(id) => this.routeToProjectDetails(id)}
                onDeleteClick={showDialog}
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                hideDots
                dontShowOptions
                fixedHeader
                config={{
                  'Status': { cell: CustomStatusCell },
                }}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

BillingListComponent.propTypes = {
  columnsToDisplay: PropTypes.array,
  changeColumnsToDisplay: PropTypes.func,
  searchBilling: PropTypes.func,
  resetBillingDetails: PropTypes.func,
  totalCount: PropTypes.number.isRequired,
  setListSettings: PropTypes.func,
  listSettings: PropTypes.object,
};

export default connect((store) => ({
  columnsToDisplay: store.billingReducer.columnsToDisplay,
  listSettings: store.billingReducer.listSettings,
  projects: [...store.projectsReducer.projects],
  isLoading: store.loadingReducer.isLoading,
  // totalCount: store.projectsReducer.billingTotalCount,
}), {
  changeColumnsToDisplay,
  searchBilling,
  setBillingDetails,
  resetBillingDetails,
  getSelectedTab,
  setComponent,
  resetComponent,
  setListSettings,
})(BillingListComponent);
