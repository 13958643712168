import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomIcon from '../../icon/Icon';
import Button from '@material-ui/core/Button';

export class SearchField extends Component {
  constructor(props) {
    super(props);
    const { searchValue, location, searchValueInTabs } = this.props;
    const searchValueFromLocation = location && location.state && location.state.search;
    const childState = location && location.state && location.state.childState;
    this.state = {
      searchValue: searchValue || searchValueFromLocation || '',
      currentTab: '',
      searchValueInTabs: searchValueInTabs || childState || {},
    };
  }

  componentDidMount() {
    const { searchValueLocal } = this.props;
    if (searchValueLocal) {
      this.setState({
        searchValue: Object.keys(searchValueLocal).map((key) => searchValueLocal[key].search)[0],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { searchValueInTabs, currentTab } = this.state;
    const { searchValueLocal, searchValue } = this.props;
    const tabSearchName = searchValueLocal && Object.keys(searchValueLocal)[0];
    if (searchValueLocal && !(tabSearchName in searchValueInTabs)) {
      this.setState({
        searchValueInTabs: {
          ...searchValueInTabs,
          ...searchValueLocal,
        },
      });
    }
    if (searchValueLocal && (currentTab !== tabSearchName)) {
      this.setState({
        currentTab: tabSearchName,
        searchValue: (searchValueInTabs[tabSearchName] && searchValueInTabs[tabSearchName].search) || '',
      });
    }
    if (prevProps.searchValue !== searchValue) {
      this.setState({
        searchValue,
      });
    }
  }

  getInitialSearch = () => {
    const { searchValue, location, searchValueLocal } = this.props;
    if (!searchValueLocal) {
      return searchValue || location.state.search;
    }
    if (searchValueLocal) {
      return Object.keys(searchValueLocal).map((key) => searchValueLocal[key].search)[0];
    }
    return '';
  }

  handleClick = () => {
    const { searchValue } = this.state;
    const { onSearchClick } = this.props;
    onSearchClick && onSearchClick(searchValue);
  }

  handleSetSearchValue = (e) => {
    const { currentTab, searchValueInTabs } = this.state;
    const { searchValueLocal } = this.props;
    const stateToSet = searchValueLocal
      ? {
        searchValue: e.target.value,
        searchValueInTabs: {
          ...searchValueInTabs,
          [currentTab]: { ...[currentTab], search: e.target.value },
        },
      } : {
        searchValue: e.target.value,
      };
    this.setState(stateToSet);
  }

  handleKeyUp = (e) => {
    const enterButtonKeyCode = 13;
    const { searchValue } = this.state;
    const { onSearchClick } = this.props;
    if (e.keyCode === enterButtonKeyCode) {
      onSearchClick && onSearchClick(searchValue);
    }
  }

  clearSearch = () => {
    const { currentTab, searchValueInTabs } = this.state;
    const { searchValueLocal, onSearchClick } = this.props;
    const stateToSet = searchValueLocal
      ? {
        searchValue: '',
        searchValueInTabs: {
          ...searchValueInTabs,
          [currentTab]: { ...[currentTab], search: '' },
        },
      } : {
        searchValue: '',
      };
    this.setState(stateToSet);
    onSearchClick && onSearchClick('');
  }

  render() {
    const { searchValue } = this.state;
    const {
      searchPlaceholder,
      onSearchClick,
    } = this.props;
    const clearIconBtn = <Button onClick={this.clearSearch} className='search-action'><CustomIcon iconName='delete-searchbox' /></Button>;
    const clickableActions = <>
      {searchValue && clearIconBtn}
      <Button onClick={this.handleClick} className={`search-action search-action-search ${searchValue && 'active-action'}`}>
        <CustomIcon
          iconName='search-magnifier'
        />
      </Button>
    </>;
    return (
      <div className='search-cont'>
        <input
          className={`search-field ${onSearchClick && 'with-btn'}`}
          type='text'
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={this.handleSetSearchValue}
          onKeyUp={this.handleKeyUp}
        />
        <div className='search-actions'>
          {clickableActions}
        </div>
      </div>
    );
  }
}

SearchField.propTypes = {
  searchPlaceholder: PropTypes.string,
  onSearchClick: PropTypes.func,
};
