import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import http from 'services/http';
import { resetStore } from '../actions/storeActions';
import { GET_VERSION, setVersion } from '../actions/versionActions';

export function* getVersion() {
  try {
    const versions = yield call(http, {
      method: 'GET',
      url: 'version',
    });
    const version = yield select((state) => state.versionReducer.version);
    if (versions.data !== version) {
      yield put(resetStore());
    }

    yield put(setVersion(versions.data));
  } catch (e) {
    // error message
  }
}

export default function* versionRootSaga() {
  yield takeEvery(GET_VERSION, getVersion);
}
