import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getManageBusinessTrips, createNewBusinessTrips, updateBusinessTrips } from 'pages/admin/actions/adminManageBisunessTrips';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageBusinessTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Business Trips', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddRoleFields: false,
      addRoleType: { Name: '' },
      blockAddRoleType: false,
      blockSaveRoleType: false,
    };
  }

  componentDidMount() {
    const { loadManageBusinessTrips } = this.props;
    loadManageBusinessTrips();
  }

  addNewRoleType = () => {
    const { blockAddRoleType } = this.state;
    if (!blockAddRoleType) {
      this.setState({
        showAddRoleFields: true,
      });
    }
  }

  getNewRoleObj = ({ target }) => {
    const { addRoleType } = this.state;
    this.setState({
      addRoleType: {
        ...addRoleType,
        [target.name]: target.value,
      },
    }, this.newRoleValidation);
  }

  newRoleValidation = () => {
    const { addRoleType } = this.state;
    this.setState({
      blockSaveRoleType: !!addRoleType.Name.trim(),
      blockAddRoleType: !!addRoleType.Name.trim(),
    });
  };

  newRoleTypeCancel = () => {
    this.setState({
      addRoleType: { Name: '' },
      showAddRoleFields: false,
      blockSaveRoleType: false,
      blockAddRoleType: false,
    });
  }

  newResumeApply = () => {
    const { createNewBusinessTrips } = this.props;
    const { blockSaveRoleType, addRoleType } = this.state;
    if (blockSaveRoleType) {
      createNewBusinessTrips(addRoleType);
      this.setState({
        addRoleType: { Name: '' },
        showAddRoleFields: false,
        blockSaveRoleType: false,
        blockAddRoleType: false,
      });
    }
  }


  deleteRoleTypes = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `business_trips/${data.Id}`,
      searchUpdateSaga: 'business_trips',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateBusinessTrips } = this.props;
    const { Name, Id } = data;
    updateBusinessTrips(Name, Id);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  render() {
    const { businessTrips, listSettings } = this.props;
    const {
      columnsToDisplay, showAddRoleFields, addRoleType, blockAddRoleType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsBusinessTrips')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewRoleType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search business trips'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(businessTrips, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteRoleTypes}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {
          showAddRoleFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addRoleType}
                onChangeNewDocumentObj={this.getNewRoleObj}
                disableSaveDocument={blockAddRoleType}
                newDocumentCancel={this.newRoleTypeCancel}
                newDocumentApply={this.newResumeApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageBusinessTrips.propTypes = {
  loadManageBusinessTrips: PropTypes.func,
  createNewBusinessTrips: PropTypes.func,
  updateBusinessTrips: PropTypes.func,
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  businessTrips: store.adminManageLookups.businessTrips,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageBusinessTrips: getManageBusinessTrips,
  createNewBusinessTrips,
  updateBusinessTrips,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageBusinessTrips);
