import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import http from 'services/http';
import history from 'utils/history';
import {
  GET_SOW_REPORTS,
  TRANSITION_TO_CLIENT,
  TRANSITION_TO_PROJECT,
  TRANSITION_TO_CONTRACT,
  TRANSITION_TO_EMPLOYEE,
  TRANSITION_TO_ROLE,
  setSowReports,
} from '../actions/reportsActions';
import {
  startRequest, finishRequest,
} from 'common/actions/loadingActions';
import { getClientById } from '../../clients/sagas/clientsSaga';
import { transitionToEditProject } from '../../members/sagas/membersSaga';
import { getProjectDetails, getCreationConfig } from '../../projects/sagas/projectsSaga';
import { setProjectId } from '../../projects/actions/roleModalActions';

function* loadSowReports(action) {
  try {
    yield put(startRequest());
    const { column } = action.payload;
    const orderByDesc = action.payload.sortDirection === 'Up' ? false : true;
    const sowReports = yield call(http, {
      method: 'GET',
      url: `sow-extension-forecast-reports?Search=${encodeURIComponent(action.payload.searchValue)}&SortField=${column}&orderByDesc=${orderByDesc}`,
      params: action.payload.filters,
    });
    yield put(setSowReports(sowReports.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToClient(action) {
  try {
    yield put(startRequest());
    yield getClientById(action);
    const { payload } = action;
    history.push({
      pathname: `/clients/${payload.Id}`,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToProject(action) {
  try {
    yield transitionToEditProject(action);
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToContract(action) {
  try {
    yield put(startRequest());
    yield getCreationConfig();
    yield getProjectDetails(action);
    const { payload } = action;
    history.push({
      pathname: `/projects/${payload.id}/${payload.rights}-${payload.document}/${payload.contractId}`,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToEmployee(action) {
  try {
    yield put(startRequest());
    history.push({
      pathname: `employees/${action.payload.id}`,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToRole(action) {
  try {
    yield put(startRequest());
    yield getCreationConfig();
    yield put(setProjectId(action.payload.id));
    yield getProjectDetails(action);
    const { payload } = action;
    history.push({
      pathname: `/projects/${payload.id}/role/${payload.rights}/${payload.roleId}`,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export default function* reportsSaga() {
  yield takeEvery(GET_SOW_REPORTS, loadSowReports);
  yield takeEvery(TRANSITION_TO_CLIENT, transitionToClient);
  yield takeEvery(TRANSITION_TO_PROJECT, transitionToProject);
  yield takeEvery(TRANSITION_TO_CONTRACT, transitionToContract);
  yield takeEvery(TRANSITION_TO_EMPLOYEE, transitionToEmployee);
  yield takeEvery(TRANSITION_TO_ROLE, transitionToRole);
}
