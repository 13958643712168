import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getBenchReports, setBenchReports } from '../../actions/benchActions';
import { getFilters } from 'common/actions/filtersActions';
import moment from 'moment';
import { routeData } from './utils';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { emptyArray } from 'utils/const-variable';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { resetComponent, setComponent } from 'common/actions/headerActions';

const filtersNames = [
  'EmployeeStatusesNew',
  'Locations',
];

export class BenchSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Office',
          Id: 'OfficeName',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Office',
          hasBorder: 'header_col_has_border',
          shiftName: true,
        },
        {
          Name: 'Office',
          Id: 'TotalOffice',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Total',
        },
        {
          Name: 'Admin',
          Id: 'TotalAdmin',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Total',
        },
        {
          Name: 'Employee',
          Id: 'TotalEmployee',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Total',
        },
        {
          Name: 'Trainee',
          Id: 'TotalTrainee',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Total',
        },
        {
          Name: 'Technical Staff',
          Id: 'TotalTechStaff',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Total',
          hasBorder: 'header_col_has_border',
        },
        {
          Name: 'Contractors',
          Id: 'Contractors',
          className: 'medium-col big-report-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'NotIncludedInTotal',
          hasBorder: 'header_col_has_border',
        },
        {
          Name: 'Billable',
          Id: 'AllocationBillable',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Allocation',
          toFixed: true,
        },
        {
          Name: 'Invested Akvelon',
          Id: 'AllocationInvestedAkvelon',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Allocation',
        },
        {
          Name: 'Invested Client',
          Id: 'AllocationInvestedClient',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Allocation',
          hasBorder: 'header_col_has_border',
        },
        {
          Name: 'Bench',
          Id: 'CapacityBench',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Capacity',
          toFixed: true,
        },
        {
          Name: 'Bench in 30 days',
          Id: 'CapacityBenchIn30Days',
          className: 'xs-col',
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Capacity',
          hasBorder: 'header_col_has_border',
          toFixed: true,
        },
        {
          Name: 'Office Billable',
          Id: 'TotalOfficeBillable',
          className: 'xs-col',
          toFixed: true,
          deleteArrow: 'delete-arrow',
          connectedHeader: 'TotalOfficeBillable',
          shiftName: true,
        },
        {
          Name: 'Tech Billable',
          Id: 'TotalTechBillable',
          className: 'xs-col',
          toFixed: true,
          deleteArrow: 'delete-arrow',
          connectedHeader: 'TotalTechBillable',
          shiftName: true,
        },
        {
          Name: 'Bench Billable',
          Id: 'BenchBillable',
          className: 'xs-col',
          toFixed: true,
          deleteArrow: 'delete-arrow',
          connectedHeader: 'Bench/Billable',
          shiftName: true,
        },
      ],
      columnsHeaders: [
        {
          Name: '   ', className: 'header_col_has_border', Id: 'Office', width: 80,
        },
        {
          Name: 'Total', className: 'header_col_has_border', Id: 'Total', width: 400, needShowBorder: 'show_border-bottom',
        },
        {
          Name: 'Not Included in Total', className: 'header_col_has_border big-report-col', Id: 'NotIncludedInTotal', width: 150, needShowBorder: 'show_border-bottom',
        },
        {
          Name: 'Allocation', className: 'header_col_has_border', Id: 'Allocation', width: 240, needShowBorder: 'show_border-bottom',
        },
        {
          Name: 'Capacity', className: 'header_col_has_border', Id: 'Capacity', width: 160, needShowBorder: 'show_border-bottom',
        },
        {
          Name: '   ', className: 'header_col_hasnt_border', Id: 'TotalOfficeBillable', width: 80,
        },
        {
          Name: '   ', className: 'header_col_hasnt_border', Id: 'TotalTechBillable', width: 80,
        },
        {
          Name: '   ', className: 'header_col_hasnt_border', Id: 'Bench/Billable', width: 80,
        },
      ],
    };
  }

  componentDidMount() {
    const { getBenchReports, getFilters, setComponent } = this.props;
    getBenchReports();
    getFilters(filtersNames);
    setComponent({ title: `Bench Summary - ${moment().format('MM/DD/YYYY')}` });
  }

  componentWillUnmount() {
    const { setBenchReports, resetComponent } = this.props;
    setBenchReports(emptyArray);
    resetComponent();
  }

  buildCorrectWay = (item, column) => {
    const { filters, route} = this.getCorrectFiltersForTransition(column, item.OfficeId);

    return this.transferToViewWithFilters(filters, route);
  }

  transferToViewWithFilters = (filters, customRoute) => ({
    pathname: customRoute ? customRoute : '/employees',
    state: {
      filters,
      search: '',
    },
  })

  getCorrectFiltersForTransition = (column, officeId) => {
    const { reports: { benchSummary } } = this.props;

    const benchSummaryFilters = benchSummary.Filters;
    const employeeTypeFilter = benchSummaryFilters.EmployeeTypeFilters[column];
    const billableStatusFilter = benchSummaryFilters.BillableStatusFilters[column];

    return {
      filters: {
        Offices: [officeId],
        OfficeIds: [officeId],
        Statuses: employeeTypeFilter,
        BillableStatuses: billableStatusFilter,
      },
      route: routeData[column],
    };
  }

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument } = this.props;
    downloadXLSXDocument({}, '', 'bench_summary-reports/export-xlsx', 'Bench Summary Reports.xlsx');
  }

  render() {
    const { reports } = this.props;
    const { columnsToDisplay, columnsHeaders } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          searchField={false}
          showExportButton
          exportDocument={this.downloadXLSXDocument}
        />
        <List
          columnsHeaders={columnsHeaders}
          columnsToDisplay={columnsToDisplay}
          items={reports.benchSummary.Data}
          hideDots
          transition={this.buildCorrectWay}
          dontShowOptions
          config={{
            'TotalOffice': { cell: CellLinkForRedirect },
            'TotalAdmin': { cell: CellLinkForRedirect },
            'TotalEmployee': { cell: CellLinkForRedirect },
            'TotalTrainee': { cell: CellLinkForRedirect },
            'TotalTechStaff': { cell: CellLinkForRedirect },
            'Contractors': { cell: CellLinkForRedirect },
            'BillableOnSite': { cell: CellLinkForRedirect },
            'AllocationBillable': { cell: CellLinkForRedirect },
            'AllocationInvestedAkvelon': { cell: CellLinkForRedirect },
            'AllocationInvestedClient': { cell: CellLinkForRedirect },
            'CapacityBench': { cell: CellLinkForRedirect },
            'CapacityBenchIn30Days': { cell: CellLinkForRedirect },
          }}
        />
      </div>
    );
  }
}

BenchSummary.propTypes = {
  reports: PropTypes.object.isRequired,
  getBenchReports: PropTypes.func.isRequired,
  getFilters: PropTypes.func,
};

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  locations: store.filtersReducer.filters.Locations,
  statuses: store.filtersReducer.filters.EmployeeStatusesNew,
  billableStatus: store.reportsReducer.filters.billableStatus,
}), {
  getBenchReports,
  getFilters,
  downloadXLSXDocument,
  setComponent,
  resetComponent,
  setBenchReports,
})(BenchSummary);
