import {
  SET_BILLING,
  SET_BILLING_DETAILS,
  RESET_BILLING_DETAILS,
  CHANGE_COLUMNS_TO_DISPLAY,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
} from '../actions/billingActions.js';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Project',
  sortDirection: 'Up',
  filters: {},
  scrollTo: '',
  hasMoreBilling: true,
  amountToTake: 50,
  detailsTabsState: {
    roles: {
      sortColumn: 'Employee',
      sortDirection: 'Up',
    },
    justification: {
      sortColumn: 'Employee',
      sortDirection: 'Up',
    },
    justificationOther: {
      sortColumn: 'Title',
      sortDirection: 'Up',
    },
    invoice: {
      sortColumn: 'Invoice',
      sortDirection: 'Up',
    },
  },
};

const initialState = {
  projectTotalCount: 0,
  billing: [],
  billingDetails: {},
  columnsToDisplay: [
    {
      Name: 'Project', isSelected: true, Id: 'Project', className: 'without-width-col', necessary: true, hasBadge: true,
    },
    {
      Name: 'SOW/PO Number', isSelected: true, Id: 'Contract', className: 'big-col',
    },
    {
      Name: 'SOW Type', isSelected: true, Id: 'Type', className: 'medium-col',
    },
    {
      Name: 'Billing Period', isSelected: true, Id: 'Period', className: 'big-col',
    },
    {
      Name: 'Billing Term', isSelected: true, Id: 'Tern', className: 'small-col',
    },
    {
      Name: 'Accountant', isSelected: true, Id: 'Accountant', className: 'medium-col', hasBadge: true,
    },
    {
      Name: 'Approver', isSelected: true, Id: 'Approver', className: 'medium-col', hasBadge: true,
    },
    {
      Name: 'Total Hours', isSelected: true, Id: 'TotalHours', className: 'small-col',
    },
    {
      Name: 'Total Amount', isSelected: true, Id: 'TotalAmount', className: 'small-col',
    },
    {
      Name: 'Status', isSelected: true, Id: 'Status', className: 'big-col',
    },
  ],
  listSettings: defaultListSettings,
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BILLING:
      return {
        ...state,
        projects: action.payload.projects,
      };
    case SET_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: action.payload,
      };
    case RESET_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: {},
      };
    case CHANGE_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    default:
      return state;
  }
};

export default billingReducer;
