export const GET_BACKGROUND_CHECKS_REPORTS = '@reports/GET_BACKGROUND_CHECKS_REPORTS';
export const SET_BACKGROUND_CHECKS_REPORTS = '@reports/SET_BACKGROUND_CHECKS_REPORTS';
export const SET_BACKGROUND_CHECKS_COUNTER = '@reports/SET_BACKGROUND_CHECKS_COUNTER';
export const SET_BACKGROUND_CHECKS_LIST_SETTINGS = '@reports/SET_BACKGROUND_CHECKS_LIST_SETTINGS';
export const RESET_BACKGROUND_CHECKS_LIST_SETTINGS = '@reports/RESET_BACKGROUND_CHECKS_LIST_SETTINGS';
export const CHANGE_BACKGROUND_CHECKS_COLUMNS_TO_DISPLAY = '@reports/CHANGE_BACKGROUND_CHECKS_COLUMNS_TO_DISPLAY';

export const getBackgroundChecksReports = (filters, searchValue, take, skip, sortSettings) => ({
  type: GET_BACKGROUND_CHECKS_REPORTS,
  payload: {
    searchValue,
    filters,
    take,
    skip,
    sortSettings,
  },
});

export const setBackgroundChecksReports = (reports) => ({
  type: SET_BACKGROUND_CHECKS_REPORTS,
  payload: reports,
});

export const setBackgroundChecksCounter = (count) => ({
  type: SET_BACKGROUND_CHECKS_COUNTER,
  payload: count,
});

export const setBackgroundChecksReportsListSettings = (settings) => ({
  type: SET_BACKGROUND_CHECKS_LIST_SETTINGS,
  payload: settings,
});

export const resetBackgroundChecksReportsListSettings = () => ({
  type: RESET_BACKGROUND_CHECKS_LIST_SETTINGS,
});

export const changeColumnsToDisplay = (columnsToDisplay) => ({
  type: CHANGE_BACKGROUND_CHECKS_COLUMNS_TO_DISPLAY,
  payload: { columnsToDisplay },
});
