import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ManageUsers from './components/manage-users/manage-users';
import AdminModal from './components/modal-window/add-modal';
import { getInfoForAdmin } from './actions/modalActions';
import { getOffices } from 'pages/projects/actions/projectsActions.js';
import Settings from './components/settings/settings';
import ManageLookups from './components/manage-lookups/manage-lookups.js';
import './admin-page.css';
import ManageApiTokens from './components/manage-api-tokens/manage-api-tokens';

const AdminPage = () => {
  const dispatch = useDispatch();
  const isAdminModalVisible = useSelector((state) => state.adminModalReducer.isVisible);

  useEffect(() => {
    dispatch(getInfoForAdmin());
    dispatch(getOffices(true));
  }, []);

  return (
    <>
      <Switch>
        <Route exact path='/admin/users' component={ManageUsers} />
        <Route exact path='/admin/api_tokens' component={ManageApiTokens} />
        <Route path='/admin/settings' component={Settings} />
        <Route path='/admin/lookups' component={ManageLookups} />
        <Redirect to='/admin/users' />
      </Switch>
      {isAdminModalVisible && <AdminModal />}
    </>
  );
};

export default AdminPage;
