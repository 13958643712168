import * as React from 'react';
import { RaisedButton, FlatButton } from 'material-ui';

export default class Button extends React.Component {
    // Bug fix for material UI button(no more double click, when focused button, clicked by enter)
    preventDefaultOnClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onClick(event);
    }

    render() {
        const {
            className,
            label,
            isFlat,
            labelColor,
            fontSize,
            sentenceCase,
            height,
            fullWidth,
            boldLabel,
            backgroundColor,
            disabled,
            disabledBackgroundColor,
            spinner,
            id,
            shouldSubmit} = this.props;

        const loaderIcon = (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <img src="/assets/images/tail-spin.svg" style={{ height: '60%' }} alt=""/>
            </div>
        );

        return (
            <div id={id}> 
            {!isFlat
                // tslint:disable-next-line:jsx-wrap-multiline
                ? <RaisedButton
                    label={label}
                    className={className}
                    backgroundColor={backgroundColor || '#F26939'}
                    fullWidth={fullWidth}
                    labelStyle={{
                        fontFamily: 'Roboto-Regular',
                        color: labelColor || 'white',
                        fontWeight: boldLabel ? 'bold' : 'normal',
                        letterSpacing: '1px',
                        fontSize: fontSize || '16px',
                        textTransform: sentenceCase ? 'none' : 'uppercase',
                        lineHeight: height || '36px'
                    }}
                    overlayStyle={{
                        height: height || '36px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    icon={spinner && loaderIcon}
                    disabled={disabled}
                    disabledBackgroundColor={disabledBackgroundColor || backgroundColor || 'rgba(255, 133, 0, 0.3)'}
                    buttonStyle={{
                        height: height || '36px'
                    }}
                    type={shouldSubmit ? 'submit' : ''}
                    onClick={this.preventDefaultOnClick}
                />
                // tslint:disable-next-line:jsx-wrap-multiline
                : <FlatButton
                    label={label}
                    className={className}
                    backgroundColor={backgroundColor || '#F26939'}
                    fullWidth={fullWidth}
                    labelStyle={{
                        fontFamily: 'Roboto-Regular',
                        color: labelColor || 'white',
                        fontWeight: boldLabel ? 'bold' : 'normal',
                        letterSpacing: '1px',
                        fontSize: fontSize || '16px',
                        textTransform: sentenceCase ? 'none' : 'uppercase',
                    }}
                    icon={spinner && loaderIcon}
                    disabled={disabled}
                    type={shouldSubmit ? 'submit' : ''}
                    onClick={this.preventDefaultOnClick}
                />}
               </div>
            );
    }
}
