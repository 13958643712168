import React, { Component } from 'react';
import classNames from 'classnames';
import CustomIcon from 'common/components/icon/Icon';
import { SowRenewalStatusList } from 'common/components/sow-renewal-status-list/sow-renewal-status-list';
import { getTotalPercent } from 'services/getTotalPercentForRenewalStatuses';
import { TOTAL_PERCENT_FOR_RENEWAL_STATUSES } from 'utils/const-variable';
import isObjectNotEquals from 'utils/object-comparison';

import 'common/components/sow-renewal-status-list/sow-renewal-status-block.css';


export class renderRenewalStatusBlock extends Component {
  constructor() {
    super();
    this.state = {
      showPopover: false,
      totalPercent: 0,
    };
  }

  componentDidMount() {
    this.updateTotalPercent();
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props;
    if (isObjectNotEquals(prevProps.input.value, input.value)) {
      this.updateTotalPercent();
    }
  }

  onToggleShowSowRenewalStatusList = () => {
    const { isShowSowRenewalStatusList } = this.state;
    const { input: { value } } = this.props;
    const firstRenewalStatus = value.find((status) => status.Name === 'New business');
    const isCheckedStatus = value.find((status) => status.IsActive);
    this.setState({
      isShowSowRenewalStatusList: !isShowSowRenewalStatusList,
    }, () => {
      !isCheckedStatus && this.onChange({ ...firstRenewalStatus, IsActive: true, Value: 100 }, firstRenewalStatus.RenewalStatusId);
    });
  }

  onChange = (renewalStatus, renewalStatusId) => {
    const { input } = this.props;
    const rewenalStatuses = renewalStatusId ? input.value.reduce((acc, item) => {
      if (item.RenewalStatusId === renewalStatusId) {
        acc.push(renewalStatus);
      } else {
        acc.push(item);
      }
      return acc;
    }, []) : [...renewalStatus];
    input.onChange(rewenalStatuses);
  }

  handleChangeShowPopover = () => {
    const { showPopover } = this.state;
    this.setState({
      showPopover: !showPopover,
    });
  }

  updateTotalPercent = () => {
    const { input } = this.props;
    input.value.length && this.setState({
      totalPercent: +getTotalPercent(input.value).toFixed(2),
    });
  }

  render() {
    const { showPopover, totalPercent } = this.state;
    const { input, columnsToDisplay, styles } = this.props;
    return (
      <div className={classNames(styles.container)}>
        <div
          className={classNames('add-renewal-status__button--margin')}
        >
          <span>SOW Renewal Status</span>
          <span className='redux-form__required'> *</span>
        </div>
        <div>
          <SowRenewalStatusList
            items={input.value}
            columnsToDisplay={columnsToDisplay}
            onChange={this.onChange}
          />
          <div className={classNames('div__total-field',
            {
              'not-equals': totalPercent !== TOTAL_PERCENT_FOR_RENEWAL_STATUSES,
            })}
          >
            {
              showPopover ? (
                <div className='popover-renewal-status' onClick={this.handleChangeShowPopover}>
                  <p>Total percentage of SOW Renewal Status should be equal to 100%</p>
                </div>
              ) : null
            }
            <CustomIcon iconName='question-mark' onClick={this.handleChangeShowPopover} />
            <span>{`Total: ${totalPercent}%`}</span>
          </div>
        </div>
      </div>
    );
  }
}
