import { useSelector } from 'react-redux';
import { DELETE_MANAGE_KIND, UPDATE_MANAGE_KIND } from '../../reducers/manageUserConfirmationReducer';

export const useUserSelector = () => {
  const userId = useSelector((state) => state.manageUserConfirmation.userId);
  const users = useSelector((state) => state.adminReducer.users);

  if (!userId) {
    return null;
  }

  return users.find((u) => u.UserId === userId);
};

export const useConfirmationHeader = () => {
  const manageKind = useSelector((state) => state.manageUserConfirmation.manageKind);

  switch (manageKind) {
    case DELETE_MANAGE_KIND:
      return 'REMOVE ACCESS';
    case UPDATE_MANAGE_KIND:
    default:
      return 'WARNING!';
  }
};

export const useConfirmationMessage = (user) => {
  const conflictingUserManagerialInfo = useSelector((state) => state.manageUserConfirmation.conflictingUserManagerialInfo);
  const manageKind = useSelector((state) => state.manageUserConfirmation.manageKind);

  if (!user) {
    return null;
  }

  if (manageKind === DELETE_MANAGE_KIND && !conflictingUserManagerialInfo.some((i) => i)) {
    return 'Are you sure to remove access this user?';
  }

  const intro = `You are going to change access permissions for ${user.Name}.`;
  const explanation = 'If you save the changes, the user will be removed from:';
  const details = conflictingUserManagerialInfo.map((i) => ` • ${i.projectName} as ${i.roleName}`).join('\n');

  return [intro, explanation, details].join('\n\n');
};
