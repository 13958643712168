import { call, put, takeEvery } from 'redux-saga/effects';
import { finishRequest, startRequest } from '../../../common/actions/loadingActions';
import http from '../../../services/http';
import {
  GET_MANAGE_EMPLOYEE_TYPES,
  POST_MANAGE_EMPLOYEE_TYPES,
  PUT_MANAGE_EMPLOYEE_TYPES,
  setEmployeeTypes,
} from '../actions/adminManageEmployeeTypes';

export function* getEmployeeTypes() {
  try {
    yield put(startRequest());

    const employeeTypes = yield call(http, {
      method: 'GET',
      url: 'employee-types',
    });

    yield put(setEmployeeTypes(employeeTypes.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* putEmployeeType(action) {
  try {
    yield put(startRequest());

    yield call(http, {
      method: 'PUT',
      url: 'employee-types',
      data: action.payload,
    });

    yield call(getEmployeeTypes);
  } finally {
    yield put(finishRequest());
  }
}

export function* postEmployeeType(action) {
  try {
    yield put(startRequest());

    yield call(http, {
      method: 'POST',
      url: 'employee-types',
      data: {
        Name: action.payload.Name,
      },
    });

    yield call(getEmployeeTypes);
  } finally {
    yield put(finishRequest());
  }
}

export default function* adminManageEmployeeTypes() {
  yield takeEvery(GET_MANAGE_EMPLOYEE_TYPES, getEmployeeTypes);
  yield takeEvery(PUT_MANAGE_EMPLOYEE_TYPES, putEmployeeType);
  yield takeEvery(POST_MANAGE_EMPLOYEE_TYPES, postEmployeeType);
}
