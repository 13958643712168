export const SET_MAIN_BENCH_REPORTS = '@reports/SET_MAIN_BENCH_REPORTS';
export const SEARCH_MAIN_BENCH_REPORTS = '@reports/SEARCH_MAIN_BENCH_REPORTS';
export const BENCH_TRANSITION_TO_EMPLOYEE = '@reports/BENCH_TRANSITION_TO_EMPLOYEE';
export const SET_BENCH_LIST_SETTINGS = '@reports/SET_BENCH_LIST_SETTINGS';
export const RESET_BENCH_LIST_SETTINGS = '@reports/RESET_BENCH_LIST_SETTINGS';

export const searchBenchReports = (filters = {}, searchvalue = '') => ({
  type: SEARCH_MAIN_BENCH_REPORTS,
  payload: {
    searchvalue,
    filters,
  },
});

export const setBenchReports = (reports) => ({
  type: SET_MAIN_BENCH_REPORTS,
  payload: reports,
});

export const transitionToEmployeeDetails = (id) => ({
  type: BENCH_TRANSITION_TO_EMPLOYEE,
  payload: { id },
});

export const setBenchListSettings = (settings) => ({
  type: SET_BENCH_LIST_SETTINGS,
  payload: settings,
});

export const resetBenchListSettings = () => ({
  type: RESET_BENCH_LIST_SETTINGS,
});
