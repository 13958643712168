export const GET_MAIN_AUDIT_REPORTS = '@reports/GET_MAIN_AUDIT_REPORTS';
export const SET_MAIN_AUDIT_REPORTS = '@reports/SET_MAIN_AUDIT_REPORTS';
export const SET_MAIN_AUDIT_COUNT = '@reports/SET_MAIN_AUDIT_COUNT';
export const SET_AUDIT_LIST_SETTINGS = '@reports/SET_AUDIT_LIST_SETTINGS';
export const RESET_AUDIT_LIST_SETTINGS = '@reports/RESET_AUDIT_LIST_SETTINGS';
export const SET_AUDIT_LIST_DATES = '@reports/SET_FINANCE_LIST_DATES';
export const RESET_AUDIT_LIST_DATES = '@reports/RESET_FINANCE_LIST_DATES';

export const getAuditReports = (filters = {}, searchvalue = '', take = 50, skip = 0) => ({
  type: GET_MAIN_AUDIT_REPORTS,
  payload: {
    searchvalue,
    filters,
    take,
    skip,
  },
});

export const setAuditReports = (reports) => ({
  type: SET_MAIN_AUDIT_REPORTS,
  payload: reports,
});

export const setAuditReportsTotalCount = (count) => ({
  type: SET_MAIN_AUDIT_COUNT,
  payload: count,
});

export const setAuditListSettings = (settings) => ({
  type: SET_AUDIT_LIST_SETTINGS,
  payload: settings,
});

export const resetAuditListSettings = () => ({
  type: RESET_AUDIT_LIST_SETTINGS,
});

export const setAuditListDates = (settings) => ({
  type: SET_AUDIT_LIST_DATES,
  payload: settings,
});

export const resetAuditListDates = () => ({
  type: RESET_AUDIT_LIST_DATES,
});
