import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class CellPercent extends Component {
  render() {
    const {
      item, column,
    } = this.props;
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        <span
          className='col-span'
          style={{ WebkitBoxOrient: 'vertical' }}
        >
          {item[column.Id] === null ? '———' : `${item[column.Id].toFixed(2)} %`}
        </span>
      </div>
    );
  }
}

CellPercent.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
