import {
  put,
  takeEvery,
  call,
} from 'redux-saga/effects';

import {
  ADD_ROLE_TO_PROJECT,
  GET_EMPLOYEES,
  GET_PROJECT_EMPLOYEES,
  setEmployees,
  setStatus,
  setEmployeeStatus,
  GET_STATUS,
  GET_EMPLOYEE_STATUS,
  PUT_ROLE,
  PUT_EDIT_ASSIGNMENT,
  GET_RATE_UNITS,
  setRateUnits,
} from '../actions/roleModalActions';
import { getProjectDetails } from './projectsSaga';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import http from 'services/http';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { ROLE_ASSIGNMENT_ERROR_CODE } from 'utils/const-variable';
import moment from 'moment';

export function* addRoleToProject(action) {
  try {
    const { role } = action.payload;
    const roleObj = {
      RoleName: role.RoleName,
      projectId: role.projectId,
      ContractName: role.BillingCode,
      rate: role.Rate,
      rateOvertime: role.OvertimeRate,
      startDate: role.StartDate,
      endDate: role.EndDate,
      SeniorityId: role.SeniorityId,
      expertRoleId: role.RoleId,
      type: role.AssignmentTypeId,
      sow: { sowid: role.BillingCodeId },
      allocation: role.Assignment,
      billability: role.BillableStatusId,
      status: role.StatusId,
      rateUnit: role.rateUnit,
      overtimeRateUnit: role.overtimeRateUnit,
      Comment: role.Comment,
      assignments: role.Employees.filter(employee => employee && employee.EmployeeId).map(employee => getAssignmentData(employee, role)),
    };

    yield call(http, {
      method: 'POST',
      url: `projects/${role.projectId}/roles`,
      data: {
        ...roleObj,
      },
    });

    yield call(getProjectDetails, {
      payload: {
        id: role.projectId,
        isOnlyActive: action.payload.isOnlyActive,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getEmployeesByOffices(action) {
  try {
    const { isReduced, offices, statuses } = action.payload;
    const employees = yield call(http, {
      method: 'GET',
      url: 'employees',
      params: {
        offices,
        reduced: isReduced,
        statuses,
      },
    });

    yield put(setEmployees(employees.data.Employees));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getEmployeesByProject(action) {
  try {
    yield put(startRequest());
    const { projectId } = action.payload;

    const employees = yield call(http, {
      method: 'GET',
      url: `projects/${projectId}/employees`,
    });

    yield put(setEmployees(employees.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* getStatus() {
  try {
    const status = yield call(http, {
      method: 'GET',
      url: 'position-statuses',
    });

    yield put(setStatus(status.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getEmployeesStatus() {
  try {
    const statusEmpl = yield call(http, {
      method: 'GET',
      url: 'role-employee-statuses',
    });

    yield put(setEmployeeStatus(statusEmpl.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

function getAssignmentData(employee, role) {
  return {
    employeeId: employee.EmployeeId,
    startDate: moment(employee.EmployeeStartDate || employee.StartDate).format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(employee.EmployeeEndDate || employee.EndDate).format('YYYY-MM-DD HH:mm:ss'),
    allocation: employee.EmployeeAssignment || employee.Assignment,
    status: employee.Status && typeof employee.Status === 'object' ? employee.Status.Id : employee.EmployeeStatusId,
    id: employee.createdOnFrontSide ? undefined : (employee.AssignmentKey || employee.Id),
    PrimaryAssignmentId: employee.primaryAssignmentId,
    role: employee.EmployeeRole || employee.Role,
    PositionId: role.ItemId,
    projectId: role.projectId,
    comment: employee.EmployeeComment,
    ShadowAssignments: employee.backupEmployees && employee.backupEmployees.length
      ? employee.backupEmployees.filter(employee => employee && employee.EmployeeId).map((employee) => getAssignmentData(employee, role)) : [],
  };
}

export function* editRole(action) {
  try {
    const { role } = action.payload;
    const roleObj = {
      Id: role.ItemId,
      RoleName: role.RoleName,
      projectId: role.projectId,
      rate: role.Rate,
      rateOvertime: role.OvertimeRate,
      startDate: role.StartDate,
      endDate: role.EndDate,
      SeniorityId: role.SeniorityId,
      expertRoleId: role.RoleId,
      type: role.AssignmentTypeId,
      contractName: role.BillingCode,
      allocation: role.Assignment,
      Comment: role.Comment,
      billability: role.BillableStatusId,
      status: role.StatusId,
      rateUnit: role.rateUnit || role.RateUnit.Id,
      overtimeRateUnit: role.overtimeRateUnit || role.OvertimeRateUnit.Id,
      assignments: role.Employees.filter(employee => employee && employee.EmployeeId).map(employee => getAssignmentData(employee, role)),
    };

    yield call(http, {
      method: 'PUT',
      url: `projects/${role.projectId}/roles`,
      data: {
        ...roleObj,
      },
    });

    yield call(getProjectDetails, {
      payload: {
        id: role.projectId,
        isOnlyActive: action.payload.isOnlyActive,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* editAssignment(action) {
  const updateAssignment = action.payload;
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `projects/${updateAssignment.projectId}/roles/${updateAssignment.roleId}/assignments/${updateAssignment.assignmentId}`,
      data: updateAssignment.assignment,
    });

    yield call(getProjectDetails, {
      payload: {
        id: updateAssignment.projectId,
        isOnlyActive: action.isOnlyActive,
      },
    });

    yield put(finishRequest());
  } catch (e) {
    if (e && e.response && e.response.data && e.response.data.code === ROLE_ASSIGNMENT_ERROR_CODE) {
      yield put(showAlertDialog(e.response.data.description));
    }
    yield put(finishRequest());
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getRateUnits() {
  try {
    const rateUnits = yield call(http, {
      method: 'GET',
      url: 'rate-units',
    });
    yield put(setRateUnits(rateUnits.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export default function* roleModalRootSaga() {
  yield takeEvery(ADD_ROLE_TO_PROJECT, addRoleToProject);
  yield takeEvery(GET_EMPLOYEES, getEmployeesByOffices);
  yield takeEvery(GET_PROJECT_EMPLOYEES, getEmployeesByProject);
  yield takeEvery(GET_STATUS, getStatus);
  yield takeEvery(GET_EMPLOYEE_STATUS, getEmployeesStatus);
  yield takeEvery(PUT_ROLE, editRole);
  yield takeEvery(PUT_EDIT_ASSIGNMENT, editAssignment);
  yield takeEvery(GET_RATE_UNITS, getRateUnits);
}
