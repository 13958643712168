import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomIcon from 'common/components/icon/Icon';
import Scrollbars from 'react-custom-scrollbars';
import './notification.css';

export class Notification extends Component {
  render() {
    const {
      onClose, clear, items, deleteItem, getInfoForNotification,
    } = this.props;
    return (
      <div className='notification-container'>
        <div className='notification-header'>
          <h1>notifications</h1>
          <CustomIcon iconName='cross' className='notification-header-cross' onClick={onClose} />
          <span className='notification-header-clear' onClick={clear}>Clear All</span>
        </div>
        <div className='notification-body'>
          <Scrollbars
            autoHideDuration={2000}
            autoHideTimeout={300}
            hideTracksWhenNotNeeded
            renderView={props => (
              <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
            )}
            renderTrackHorizontal={props => (
              <div {...props} style={{ display: 'none' }} />
            )}
            renderThumbHorizontal={props => (
              <div {...props} style={{ display: 'none' }} />
            )}
          >
            {items.length ? (
              items.map((item, i) => {
                const message = getInfoForNotification(item);
                return (
                  <div className='notification-body-item' key={item.time + i}>
                    <div onClick={deleteItem} data-notification={i}>
                      <CustomIcon iconName='cross' className='notification-cross' />
                    </div>
                    <CustomIcon iconName={message.icon} className='notification-icon' />
                    <p>{message.message}</p>
                    <span>{item.time}</span>
                  </div>
                );
              })
            ) : null}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

Notification.propTypes = {
  onClose: PropTypes.func.isRequired,
  getInfoForNotification: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  deleteItem: PropTypes.func.isRequired,
};
