import { put, takeEvery, call } from 'redux-saga/effects';
import http from 'services/http';
import { GET_DEPARTMENTS, setDepartments } from '../actions/authActions';

export function* getDepartmentsSaga() {
  try {
    const departments = yield call(http, {
      method: 'GET',
      url: 'offices/departments',
    });
    yield put(setDepartments(departments.data));
  } catch (e) {
    // error message
  }
}

export default function* AuthRootSaga() {
  yield takeEvery(GET_DEPARTMENTS, getDepartmentsSaga);
}
