import { combineReducers } from 'redux';
import authReducer from './authReducer';
import loginReducer from 'pages/login/reducers/loginReducer';
import projectsReducer from 'pages/projects/reducers/projectsReducer';
import billingReducer from '../../pages/billing/reducers/billingReducers';
import roleModalReducer from 'pages/projects/reducers/roleModalReducer';
import membersReducer from 'pages/members/reducers/membersReducer';
import clientsReducer from '../../pages/clients/reducers/clientsReducer';
import officesReducer from '../../pages/offices/reducers/officesReducers';
import adminReducer from '../../pages/admin/reducers/adminReducers';
import adminModalReducer from '../../pages/admin/reducers/adminModalReducers';
import adminManageLookups from '../../pages/admin/reducers/adminManageLookups';
import filtersReducer from './filtersReducer';
import userRoleReducer from './userRoleReducer';
import selectedTabReducer from './selectedTabsReducer';
import versionReducer from './versionReducer';
import loadingReducer from './loadingReducer';
import dialogsReducer from './confirmationDialogReducer';
import showRoles from './showRoles';
import contractsReducer from 'pages/contracts/reducers/contractsReducer';
import reportsReducer from 'pages/reports/reducers/reportsReducer';
import accountingReducer from 'pages/reports/reducers/accountingReducer';
import financeReducer from 'pages/reports/reducers/financeReducer';
import sowReducer from 'pages/reports/reducers/sowReducer';
import workforceReducer from 'pages/reports/reducers/workforceReducer';
import benchReportsReducer from 'pages/reports/reducers/benchReportsReducer';
import auditReducer from 'pages/reports/reducers/auditReducer';
import headerReducer from './headerReducer';
import { reduxFormReducer } from './reduxFormReducer';
import importContractReducer from 'pages/projects/reducers/importContractReducer';
import adminManageApiTokens from '../../pages/admin/reducers/adminManageApiTokens';
import { RESET_STORE } from '../actions/storeActions';
import manageUserConfirmationReducer from '../../pages/admin/reducers/manageUserConfirmationReducer';
import clientRatesReducer from '../../pages/reports/reducers/clientRatesReducer';
import teamCompositionReducer from '../../pages/reports/reducers/teamCompositionReducer';
import trainingsReducer from '../../pages/reports/reducers/trainingsReducer';
import backgroundChecksReducer from '../../pages/reports/reducers/backgroundChecksReducer';
import certificationsReducer from '../../pages/reports/reducers/certificationsReducer';

const appReducer = combineReducers({
  loginReducer,
  versionReducer,
  projectsReducer,
  roleModalReducer,
  membersReducer,
  clientsReducer,
  officesReducer,
  adminReducer,
  adminModalReducer,
  adminManageLookups,
  authReducer,
  filtersReducer,
  userRoleReducer,
  selectedTabReducer,
  loadingReducer,
  dialogsReducer,
  showRoles,
  contractsReducer,
  reportsReducer,
  accountingReducer,
  billingReducer,
  financeReducer,
  headerReducer,
  sowReducer,
  workforceReducer,
  benchReportsReducer,
  auditReducer,
  importContractReducer,
  adminManageApiTokens,
  clientRatesReducer,
  teamCompositionReducer,
  manageUserConfirmation: manageUserConfirmationReducer,
  trainingsReducer,
  backgroundChecksReducer,
  certificationsReducer,
  form: reduxFormReducer.form,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    const updatedState = {
      versionReducer: state.versionReducer,
    };
    return appReducer(updatedState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
