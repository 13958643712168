export const getDataForMultiBox = (arr, anotherIndetificator) => {
  const employeesForMultiBox = arr.reduce((acc, item) => {
    acc.push({
      label: item.Name || item[anotherIndetificator],
      value: item.Name || item[anotherIndetificator],
      valueId: item.Id || item.Name,
    });
    return acc;
  }, []);
  return employeesForMultiBox;
};
