export const parseFromAmericanFormat = (americanFormatNumber = 0) => {
  const valueWithOutCommas = americanFormatNumber.toString().replace(/[^\d.,]/g, '').split(',').join('');
  const splittedByDot = valueWithOutCommas.split('.');
  return splittedByDot.length > 2 ? splittedByDot[0] + '.' + splittedByDot.slice(1).join('') : valueWithOutCommas;
};

export const getAmericanFormat = (incoming) => {
  if (`${incoming}`.trim() === '') {
    return '';
  }

  const incomingData = parseFromAmericanFormat(incoming);
  const splittedByDot = incomingData.split('.');
  const intPart = splittedByDot[0];
  const fractPart = splittedByDot.length === 2 ? splittedByDot[1] : null;
  const isNegativeNum = incoming && typeof incoming === 'number' && `${incoming}`.length >= 2 && `${incoming}`[0] === '-';
  const isNumWithDot = splittedByDot.length >= 2;
  let result = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(intPart);
  
  result = isNumWithDot ? `${result}.${fractPart.length > 2 ? fractPart.substring(0, 2) : fractPart}` : result;
  result = isNegativeNum ? `-${result}` : result;
  return result || 0;
};
