import React from 'react';
import PropTypes from 'prop-types';

const getShortName = (name, onlyFirstLetter) => {
  if (!name || name.length === 0) {
    return '';
  }

  const firstLetter = name[0][0];
  const lastLetter = name[0][1];

  if (onlyFirstLetter) {
    return firstLetter;
  }

  if (name.length > 1) {
    const secondLetter = name[1][0];
    const isFullName = firstLetter + secondLetter;
    return isFullName;
  }
  const shortName = firstLetter + (lastLetter ? lastLetter : '');
  return shortName;
};

export const Badge = ({
  color,
  item,
  onlyFirstLetter,
}) => {
  const regExp = /\s{2,}/;
  const replacedWord = item.replace(regExp, ' ');
  const words = replacedWord.split(' ').filter((w) => w);
  return (
    <div
      style={{ background: color }}
      className={!onlyFirstLetter ? 'c-list-logo c-list-logo--badge' : 'c-list-logo c-list-logo--withoutTitle'}
    >
      {getShortName(words, onlyFirstLetter)}
    </div>
  );
};

Badge.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
};
