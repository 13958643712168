import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { checkAbilityForParentMilestoneDeleting } from 'pages/projects/actions/contractsActions';
import CustomIcon from 'common/components/icon/Icon';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { compare } from 'common/components/list/sortList';
import MilestoneList from 'common/components/milestones-list/milestone-list';
import { MONTH_AMOUNT_FOR_FIVE_YEARS } from 'utils/const-variable';
import '../redux-form.css';

const emptyMilestone = {
  Id: null,
  Milestone: '',
  InvoiceDate: '',
  MilestoneAmount: '',
  InvoicedAmount: '',
  InvoicedDate: '',
  RemainingBalance: null,
  RemainingBalanceOverridePossibility: false,
};

export class renderMilestoneList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      selectedMilestones: [],
      isCanAddNewItem: false,
      isShowOverride: true,
    };
  }

  componentDidMount() {
    const { input } = this.props;
    if (!input.value || (input.value && !input.value.length)) {
      this.setState({
        isCanAddNewItem: true,
      });
    } else {
      this.checkIsValidMilestones();
    }
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props;
    if (JSON.stringify(prevProps.input.value) !== JSON.stringify(input.value)) {
      this.updateSelectedMilestones();
      this.checkIsValidMilestones();
    }
  }

  onAddNewItem = () => {
    const {
      input,
    } = this.props;
    this.setState({
      isCanAddNewItem: false,
    }, () => {
      const milestones = input.value ? input.value : [];
      const Milestone = this.getNextDateForMilestone(milestones);
      input.onChange([...milestones, {
        ...emptyMilestone,
        Milestone,
      }]);
    });
  }

  getNextDateForMilestone = (milestones) => {
    const selectedMilestones = milestones.map((item) => item.Milestone);
    const optionsForMilestones = this.getSelectedOptionsForMilestones();
    const indexForNextMilestone = optionsForMilestones.indexOf(selectedMilestones[selectedMilestones.length - 1]) + 1;
    return milestones.length
      ? (
        indexForNextMilestone && indexForNextMilestone < optionsForMilestones.length
          ? optionsForMilestones[indexForNextMilestone]
          : optionsForMilestones.find((item) => !selectedMilestones.includes(item))
      )
      : optionsForMilestones[0];
  }

  checkIsValidMilestones = () => {
    const { input } = this.props;
    const isValid = !input.value.length
      || !input.value.find((item) => (
        item.Milestone === ''
        || item.MilestoneAmount === ''
        || (item.RemainingBalanceOverridePossibility && (item.RemainingBalance === '' || item.RemainingBalance === null))));
    this.setState({ isCanAddNewItem: isValid });
  }

  addSelectedMilestones = (milestone) => {
    const { selectedMilestone } = this.state;
    this.setState({
      selectedMilestone: [...selectedMilestone, milestone],
    });
  }

  getSelectedOptionsForMilestones = () => {
    const { startDate, endDate, projectEndDate } = this.props;
    const dateStart = moment(startDate).clone().startOf('month');
    const dateEnd = (endDate && moment(endDate).clone().startOf('month'))
      || (projectEndDate && moment(projectEndDate).clone().startOf('month').isBefore(moment(startDate).clone().add(MONTH_AMOUNT_FOR_FIVE_YEARS, 'months'))
        ? moment(projectEndDate).clone().startOf('month') : moment(startDate).clone().add(MONTH_AMOUNT_FOR_FIVE_YEARS, 'months'));
    const months = [];
    while (dateStart <= dateEnd) {
      months.push(dateStart.format('MMMM, YYYY'));
      dateStart.add(1, 'month');
    }
    return months;
  }

  onChange = (milestone, milestoneIndex) => {
    const { input } = this.props;
    const milestones = input.value.reduce((acc, item, i) => {
      if (i === milestoneIndex) {
        acc.push(milestone);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
    input.onChange(milestones);
  }

  createNewArrayWithMilestones = (milestones, count) => {
    return count === 0 ? milestones : this.createNewArrayWithMilestones(milestones.concat({
      ...emptyMilestone,
      Milestone: this.getNextDateForMilestone(milestones),
    }), count - 1);
  }

  onDeleteItem = (milestone) => {
    const {
      input, formName, checkAbilityForParentMilestoneDeleting, parentType, childType, projectId,
    } = this.props;
    const isExistingMilestone = !!(milestone.Id);
    const milestones = input.value.filter((item) => JSON.stringify(item) !== JSON.stringify(milestone));
    isExistingMilestone ? checkAbilityForParentMilestoneDeleting(
      milestone.Id, milestone.MilestoneStringified, formName, input.name, milestones, parentType, childType, projectId,
    ) : input.onChange(milestones);
  }

  checkAbilityToAddNewMilestone = () => {
    const { input } = this.props;
    const milestonesOptions = this.getSelectedOptionsForMilestones();
    return !milestonesOptions.every(option => input.value.some(milestone => milestone.Milestone === option));
  }

  updateSelectedMilestones = () => {
    const { input } = this.props;
    input.value && this.setState({
      selectedMilestones: input.value.map((item) => item.Milestone),
    });
  }

  onCopyItem = (milestone) => {
    const { input } = this.props;
    this.setState({
      isCanAddNewItem: false,
    }, () => {
      const milestones = input.value ? input.value : [];
      const copiedMilestone = {
        ...milestone,
        Milestone: this.getNextDateForMilestone(milestones),
        Id: null,
        RemainingBalance: !milestone.RemainingBalanceOverridePossibility ? null : milestone.RemainingBalance,
      };
      const newValue = [...milestones, copiedMilestone];
      input.onChange(newValue);
    });
  }

  sortMilestones = (milestones) => {
    return milestones && milestones.sort((a, b) => compare(
      moment(a.Milestone || a.MilestoneStringified, 'MMMM, YYYY').unix(),
      moment(b.Milestone || b.MilestoneStringified, 'MMMM, YYYY').unix(),
      'Up',
      true,
    ));
  }

  onClickForPreset = (count) => {
    const { input } = this.props;
    this.setState({
      isCanAddNewItem: false,
    }, () => {
      const milestones = input.value ? input.value : [];
      input.onChange([...this.createNewArrayWithMilestones(milestones, count)]);
    });
  }

  isCheckCountMilestonesForPerset = (count) => {
    const { selectedMilestones } = this.state;
    return this.getSelectedOptionsForMilestones().length - selectedMilestones.length >= count;
  }

  render() {
    const {
      input, styles, columnsToDisplay, startDate, endDate, currencyProject,
    } = this.props;
    const {
      showConfirmationDialog, isCanAddNewItem, selectedMilestones,
    } = this.state;
    return (
      <div className={styles.container}>
        <div
          className={classNames('add-document__button add-document__button--margin',
            { 'milestone-item__button-disabled': !(isCanAddNewItem && this.checkAbilityToAddNewMilestone()) })}
          onClick={this.onAddNewItem}
        >
          <CustomIcon iconName='plus-orange' />
          <span>Add Milestone</span>
        </div>
        <div className='block__button-presets'>
          <span
            className={classNames('span__button-presets',
              { 'span__button-presets__disabled': !(isCanAddNewItem && this.isCheckCountMilestonesForPerset(3)) })}
            onClick={() => this.onClickForPreset(3)}
          >3 months</span>
          <span
            className={classNames('span__button-presets',
              { 'span__button-presets__disabled': !(isCanAddNewItem && this.isCheckCountMilestonesForPerset(6)) })}
            onClick={() => this.onClickForPreset(6)}
          >6 months</span>
          <span
            className={classNames('span__button-presets',
              { 'span__button-presets__disabled': !(isCanAddNewItem && this.isCheckCountMilestonesForPerset(12)) })}
            onClick={() => this.onClickForPreset(12)}
          >12 months</span>
        </div>
        <br />
        <MilestoneList
          columnsToDisplay={columnsToDisplay}
          selectedMilestones={selectedMilestones}
          items={this.sortMilestones(input.value)}
          onChange={this.onChange}
          onDeleteItem={this.onDeleteItem}
          startDate={startDate}
          endDate={endDate}
          selectOptionsForMilestones={this.getSelectedOptionsForMilestones()}
          disabled={!(isCanAddNewItem && this.checkAbilityToAddNewMilestone())}
          onCopyItem={this.onCopyItem}
          onTogglingShowOverride={this.onTogglingShowOverride}
          currencyProject={currencyProject}
        />
        {
          showConfirmationDialog
          && <ConfirmationDialog
            dialogHeader='DELETE'
            dialogTitle='Are you sure to delete this item?'
            closeButtonTitle='Cancel'
            confirmButtonTitle='Delete'
            closeDialog={this.closeConfirmationDialog}
            confirmDialog={this.onDeleteItem}
          />
        }
      </div>
    );
  }
}

export default connect(null, { checkAbilityForParentMilestoneDeleting })(renderMilestoneList);
