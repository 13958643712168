import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import {
  loadManageSkills, putManageSkill, postManageSkill, mergeManageSkills,
} from '../../../actions/adminManageSkills';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import isEmptyObject from 'utils/isEmptyObject';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { ModalMerge } from '../components/modal-merge/modal-merge';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Skills', isSelected: true, Id: 'Name',
        },
      ],
      showAddSkillFields: false,
      addSkill: { Name: '' },
      blockAddSkill: false,
      blockSaveSkill: false,
      entityForMerge: [],
      showMergeModal: false,
      mainEntityForMerge: null,
      additionalEntityForMerge: [],
      isApplyDisabled: false,
      isDisabledSuggest: true,
    };
  }

  componentDidMount() {
    const { loadManageSkills } = this.props;
    loadManageSkills();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewSkill = () => {
    const { blockAddSkill } = this.state;
    if (!blockAddSkill) {
      this.setState({
        showAddSkillFields: true,
      });
    }
  }

  getNewSkill = ({ target }) => {
    const { addSkill } = this.state;
    this.setState({
      addSkill: {
        ...addSkill,
        [target.name]: target.value,
      },
    }, this.newSkillValidation);
  }

  newSkillValidation = () => {
    const { addSkill } = this.state;
    this.setState({
      blockSaveSkill: !!addSkill.Name.trim(),
      blockAddSkill: !!addSkill.Name.trim(),
    });
  };

  newSkillCancel = () => {
    this.setState({
      addSkill: { Name: '' },
      showAddSkillFields: false,
      blockSaveSkill: false,
      blockAddSkill: false,
    });
  }

  newSkillApply = () => {
    const { postManageSkill } = this.props;
    const { blockSaveSkill, addSkill } = this.state;
    if (blockSaveSkill) {
      postManageSkill(addSkill);
      this.setState({
        addSkill: { Name: '' },
        showAddSkillFields: false,
        blockSaveSkill: false,
        blockAddSkill: false,
      });
    }
  }

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `skills/${element.Id}`,
      searchUpdateSaga: 'skills',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageSkill } = this.props;
    putManageSkill(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  getElementsForMerge = () => {
    const { entityForMerge } = this.state;
    if (entityForMerge.length > 1) {
      this.toogleModalMerge();
    }
  }

  toogleModalMerge = () => {
    const { showMergeModal } = this.state;
    this.setState({
      showMergeModal: !showMergeModal,
    });
  }

  changeDataForMultiBox = (incomingItems) => {
    const employeesForMultiBox = [];
    incomingItems.map((item) => {
      return employeesForMultiBox.push({
        label: item.Name,
        value: item.Name,
        valueId: item.Id,
      });
    });
    return employeesForMultiBox;
  };

  handleChangeMainMergeEntity = (value) => {
    this.setState({
      mainEntityForMerge: value,
      isDisabledSuggest: !value,
    }, this.checkMergedFields);
  };

  handleGetMergeElements = (value) => {
    this.setState({
      additionalEntityForMerge: value,
    }, this.checkMergedFields);
  }

  onApply = () => {
    const { mergeManageSkills } = this.props;
    const { mainEntityForMerge, additionalEntityForMerge, isApplyDisabled } = this.state;
    if (isApplyDisabled) {
      const dataForMerge = {
        MergeTo: mainEntityForMerge.valueId,
        MergeFrom: additionalEntityForMerge.reduce((acc, curValue) => {
          const elementsForMerge = [];
          elementsForMerge.push(curValue.valueId);
          return [...acc, ...elementsForMerge];
        }, []),
      };
      mergeManageSkills(dataForMerge);
      this.cancelMerge();
    }
  }

  checkMergedFields = () => {
    const { mainEntityForMerge, additionalEntityForMerge } = this.state;
    this.setState({
      isApplyDisabled: mainEntityForMerge && !isEmptyObject(mainEntityForMerge) && !!additionalEntityForMerge.length,
    });
  }

  cancelMerge = () => {
    this.setState({
      mainEntityForMerge: null,
      additionalEntityForMerge: [],
      showMergeModal: false,
      entityForMerge: [],
    });
  }

  getDataForSuggestBox = (allJobs, choosenJobs, mainElement) => {
    const elementsForMerge = allJobs.filter((item) => (
      (choosenJobs.includes(item.Id) && (!mainElement || item.Id !== mainElement.valueId)) && true
    ));
    return this.changeDataForMultiBox(elementsForMerge);
  }

  checkItem = (e) => {
    const { entityForMerge } = this.state;
    const entity = e.currentTarget.value;
    const isReadyForMerge = entityForMerge.find(item => item === entity);
    const updatingEntity = entityForMerge;
    const index = updatingEntity.indexOf(isReadyForMerge);
    !isReadyForMerge ? updatingEntity.push(entity) : updatingEntity.splice(index, 1);
    this.setState({
      entityForMerge: updatingEntity,
    });
  }

  render() {
    const { skills, listSettings } = this.props;
    const {
      columnsToDisplay, showAddSkillFields, addSkill, blockAddSkill, showMergeModal,
      entityForMerge, mainEntityForMerge, isApplyDisabled, isDisabledSuggest,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsSkills')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewSkill}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search Skill'
          showMargeButton
          mergeElements={this.getElementsForMerge}
          isActiveMerge={entityForMerge.length > 1}
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(skills, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.getElementForDelete}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          isCheckboxShown
          checkItem={this.checkItem}
          entityForMerge={entityForMerge}
        />
        {showAddSkillFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addSkill}
              onChangeNewDocumentObj={this.getNewSkill}
              disableSaveDocument={blockAddSkill}
              newDocumentCancel={this.newSkillCancel}
              newDocumentApply={this.newSkillApply}
            />
          </div>
        </div> : null}
        {showMergeModal ? (
          <ModalMerge
            handleChangeMainMergeEntity={this.handleChangeMainMergeEntity}
            handleGetMergeElements={this.handleGetMergeElements}
            mainOptions={this.getDataForSuggestBox(skills, entityForMerge)}
            mergeOptions={this.getDataForSuggestBox(skills, entityForMerge, mainEntityForMerge)}
            isDisabledSuggest={isDisabledSuggest}
            isApplyDisabled={!isApplyDisabled}
            cancelMerge={this.cancelMerge}
            onClick={this.onApply}
            toogleModalMerge={this.toogleModalMerge}
            lookup='Skill'
          />
        ) : null}
      </div>
    );
  }
}

ManageSkills.propTypes = {
  loadManageSkills: PropTypes.func.isRequired,
  putManageSkill: PropTypes.func.isRequired,
  postManageSkill: PropTypes.func.isRequired,
  mergeManageSkills: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  skills: store.adminManageLookups.skills,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageSkills,
  putManageSkill,
  postManageSkill,
  mergeManageSkills,
  showDialog,
  setListSettingsLookups,
})(ManageSkills);
