import { all } from 'redux-saga/effects';

import authRootSaga from './authSaga';
import loginRootSaga from 'pages/login/sagas/loginSaga';
import projectsRootSaga from 'pages/projects/sagas/projectsSaga';
import roleModalSaga from 'pages/projects/sagas/roleModalSaga';
import contractsSaga from 'pages/projects/sagas/contractsSaga';
import membersRootSaga from 'pages/members/sagas/membersSaga';
import clientsRootSaga from 'pages/clients/sagas/clientsSaga';
import officesRootSage from 'pages/offices/sagas/officesSaga';
import adminRootSaga from 'pages/admin/sagas/adminSaga';
import adminManageRoleTypes from 'pages/admin/sagas/adminManageRoleTypes';
import adminModalRootSaga from 'pages/admin/sagas/adminModalSaga';
import FiltersRootSaga from './filtersSaga';
import tabRootSaga from './selectedTabSaga';
import versionRootSaga from './versionSaga';
import ConformationDialogRootSaga from './confirmationDialogSagas';
import addEntityRootSaga from './addEntitySaga';
import contractsExpiredSaga from 'pages/contracts/sagas/contracts-sagas';
import reportsSaga from 'pages/reports/sagas/reports-sagas';
import reportsBenchSaga from 'pages/reports/sagas/bench-sagas.js';
import reportsWorkforceSaga from 'pages/reports/sagas/workforce-sagas.js';
import reportsBenchMainSaga from 'pages/reports/sagas/bench-reports-sagas.js';
import reportsAuditSaga from 'pages/reports/sagas/audit-sagas';
import xlsxSaga from 'pages/reports/sagas/xlsx-saga.js';
import adminManageJobTitles from 'pages/admin/sagas/adminManageJobTitles';
import adminManageProjectTypes from 'pages/admin/sagas/adminManageProjectTypes';
import adminManageLocations from 'pages/admin/sagas/adminManageLocations';
import adminManageSkills from 'pages/admin/sagas/adminManageSkills';
import adminManageBusinessTrips from 'pages/admin/sagas/adminManageBisunessTrips';
import adminManagePaymentTerms from 'pages/admin/sagas/adminManagePaymentTerms';
import adminManageInvoiceDates from 'pages/admin/sagas/adminManageInvoiceDates';
import adminManageSeniorityLevels from 'pages/admin/sagas/adminManageSeniorityLevels';
import adminManageSkillLevels from 'pages/admin/sagas/adminManageSkillLevels';
import adminManageSowTypes from 'pages/admin/sagas/adminManageSowTypes';
import adminManageCountries from 'pages/admin/sagas/adminManageCountries';
import adminManageDocumentTypes from 'pages/admin/sagas/adminManageDocumentTypes';
import reportsFinanceMainSaga from 'pages/reports/sagas/finance-sagas';
import reportsAccountingSaga from 'pages/reports/sagas/accounting-sagas';
import adminManageCertificatesSagas from 'pages/admin/sagas/adminManageCertificatesSagas';
import adminManageSOWRenewalStatus from 'pages/admin/sagas/adminManageSOWRenewalStatus';
import adminManageAssignmentTypes from 'pages/admin/sagas/adminManageAssignmentTypes';
import importContractRootSaga from 'pages/projects/sagas/importContractSaga';
import adminManageEngagementTypes from 'pages/admin/sagas/adminManageEngagementTypes';
import adminManageApiTokens from '../../pages/admin/sagas/adminManageApiTokensSaga';
import manageUserConfirmationSaga from '../../pages/admin/sagas/manageUserConfirmationSaga';
import reportsClientRatesSaga from '../../pages/reports/sagas/client-rates-sagas';
import adminManageEmployeeTrainingsSaga from '../../pages/admin/sagas/adminManageEmployeeTrainingsSaga';
import reportsTrainingsSaga from '../../pages/reports/sagas/trainings-sagas';
import adminManageBackFillTypes from '../../pages/admin/sagas/adminManageBackFillTypes';
import reportsBackgroundChecksSaga from '../../pages/reports/sagas/background-checks-sagas';
import reportsCertificationsSaga from '../../pages/reports/sagas/certifications-sagas';
import adminManageEmployeeTypes from '../../pages/admin/sagas/adminManageEmployeeTypes';

// single entry point to start all Sagas at once
const sagas = function* rootSaga() {
  yield all([
    authRootSaga(),
    loginRootSaga(),
    versionRootSaga(),
    projectsRootSaga(),
    membersRootSaga(),
    clientsRootSaga(),
    officesRootSage(),
    roleModalSaga(),
    contractsSaga(),
    adminRootSaga(),
    adminManageRoleTypes(),
    adminModalRootSaga(),
    FiltersRootSaga(),
    tabRootSaga(),
    ConformationDialogRootSaga(),
    addEntityRootSaga(),
    contractsExpiredSaga(),
    reportsSaga(),
    reportsBenchSaga(),
    reportsWorkforceSaga(),
    reportsBenchMainSaga(),
    reportsAuditSaga(),
    xlsxSaga(),
    adminManageJobTitles(),
    adminManageProjectTypes(),
    adminManageLocations(),
    adminManageSkills(),
    adminManageBusinessTrips(),
    adminManagePaymentTerms(),
    adminManageInvoiceDates(),
    adminManageSeniorityLevels(),
    adminManageSkillLevels(),
    adminManageSowTypes(),
    adminManageCountries(),
    adminManageDocumentTypes(),
    adminManageEngagementTypes(),
    reportsFinanceMainSaga(),
    reportsAccountingSaga(),
    adminManageCertificatesSagas(),
    adminManageSOWRenewalStatus(),
    adminManageAssignmentTypes(),
    importContractRootSaga(),
    adminManageApiTokens(),
    manageUserConfirmationSaga(),
    reportsClientRatesSaga(),
    adminManageEmployeeTrainingsSaga(),
    reportsTrainingsSaga(),
    adminManageBackFillTypes(),
    reportsBackgroundChecksSaga(),
    reportsCertificationsSaga(),
    adminManageEmployeeTypes(),
  ]);
};

export default sagas;
