import * as React from 'react';
import PropTypes from 'prop-types';

import './checkbox.css';

class Checkbox extends React.Component {
  onClick = (e) => {
    const { onClick } = this.props;
    onClick && onClick(e);
  }

  render() {
    const {
      name = 'checkbox', checked = false, disabled, value = '', checkboxContainerStyle = 'checkbox__container',
    } = this.props;
    return (
      <div className={checkboxContainerStyle}>
        {
          value && <span className='checkbox__title'>{value}</span>
        }
        <label>
          <input type='checkbox' className='ios-switch green' name={name} checked={checked} disabled={disabled} onChange={this.onClick} />
          <div><div /></div>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Checkbox;
