import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  LOAD_MANAGE_DOCUMENT_TYPES,
  setManagesDocumentTypes,
  CREATE_NEW_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
} from '../actions/adminManageDocumentTypes';

import {
  startRequest,
  finishRequest,
} from 'common/actions/loadingActions';

export function* loadManageDocumentTypes() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'document_types',
    });

    yield put(setManagesDocumentTypes(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addDocumentTypes(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'document_types',
      data,
    });
    yield call(loadManageDocumentTypes);
  } catch (e) {
    // error message
  }
}

export function* putDocumentTypes(action) {
  yield call(http, {
    method: 'PUT',
    url: `document_types/${action.payload.Id}`,
    data: { Name: action.payload.Name },
  });
  yield call(loadManageDocumentTypes);
}

export default function* adminManageDocumentTypes() {
  yield takeEvery(LOAD_MANAGE_DOCUMENT_TYPES, loadManageDocumentTypes);
  yield takeEvery(CREATE_NEW_DOCUMENT_TYPE, addDocumentTypes);
  yield takeEvery(UPDATE_DOCUMENT_TYPE, putDocumentTypes);
}
