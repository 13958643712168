export const GET_MANAGE_JOB_TITLES = '@admin/GET_MANAGE_JOB_TITLES';
export const SET_MANAGE_JOB_TITLES = '@admin/SET_MANAGE_JOB_TITLES';
export const PUT_MANAGE_JOB_TITLES = '@admin/PUT_MANAGE_JOB_TITLES';
export const POST_MANAGE_JOB_TITLES = '@admin/POST_MANAGE_JOB_TITLES';
export const MERGE_MANAGE_JOB_TITLES = '@admin/MERGE_MANAGE_JOB_TITLES';

export const getManageJobTitles = () => ({
  type: GET_MANAGE_JOB_TITLES,
});

export const setManagesJobTitles = (jobTitles) => ({
  type: SET_MANAGE_JOB_TITLES,
  payload: jobTitles,
});

export const putManageJobTitles = (jobTitles) => ({
  type: PUT_MANAGE_JOB_TITLES,
  payload: jobTitles,
});

export const postManageJobTitles = (newJob) => ({
  type: POST_MANAGE_JOB_TITLES,
  payload: newJob,
});

export const mergeManageJobTitles = (mergedElements) => ({
  type: MERGE_MANAGE_JOB_TITLES,
  payload: mergedElements,
});
