import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { finishRequest, startRequest } from '../../../common/actions/loadingActions';
import http from '../../../services/http';
import { GET_TRAININGS_REPORTS, setTrainingsCounter, setTrainingsReports } from '../actions/trainingsActions';

function* getTrainingsReports(action) {
  try {
    yield put(startRequest());
    const {
      filters, take, skip, searchValue, sortSettings,
    } = action.payload;
    const orderByDesc = !(sortSettings && sortSettings.sortDirection === 'Up');
    const trainingsReports = yield call(http, {
      method: 'GET',
      url: `trainings-reports?SearchValue=${
        encodeURIComponent(searchValue)}&skip=${skip}&take=${take}&SortField=${sortSettings && sortSettings.column}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setTrainingsCounter(trainingsReports.data.TotalCount));
    yield put(setTrainingsReports(trainingsReports.data.Trainings));
  } finally {
    yield put(finishRequest());
  }
}

export default function* reportsTrainingsSaga() {
  yield takeEvery(GET_TRAININGS_REPORTS, getTrainingsReports);
}
