export const GET_FILTERS = '@filters/GET_FILTERS';
export const SET_FILTERS = '@filters/SET_FILTERS';
export const GET_FILTERS_CERTIFICATES = '@filters/GET_FILTERS_CERTIFICATES';
export const SET_FILTERS_CERTIFICATES = '@filters/SET_FILTERS_CERTIFICATES';
export const GET_DOCUMENT_STATUSES = '@filters/GET_DOCUMENT_STATUSES';
export const SET_DOCUMENT_STATUSES = '@filters/SET_DOCUMENT_STATUSES';

export const getFilters = (filters) => {
  return {
    type: GET_FILTERS,
    payload: {
      filters,
    },
  };
};

export const setFilters = (filters) => {
  return {
    type: SET_FILTERS,
    payload: {
      filters,
    },
  };
};

export const getFiltersCertifications = () => {
  return {
    type: GET_FILTERS_CERTIFICATES,
  };
};

export const setFiltersCertifications = (certifications) => {
  return {
    type: SET_FILTERS_CERTIFICATES,
    payload: certifications,
  };
};

export const getDocumentStatuses = () => ({
  type: GET_DOCUMENT_STATUSES,
});

export const setDocumentStatuses = (documentStatuses) => ({
  type: SET_DOCUMENT_STATUSES,
  payload: documentStatuses,
});
