import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import {
  loadManageSowTypes,
  putManageSowTypes,
  postManageSowTypes,
} from '../../../actions/adminManageSowTypes';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageSowTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'SoW Types', isSelected: true, Id: 'Name',
        },
      ],
      showAddSowTypeFields: false,
      addSowType: { Name: '' },
      blockAddProjectType: false,
      blockSaveType: false,
    };
  }

  componentDidMount() {
    const { loadManageSowTypes } = this.props;
    loadManageSowTypes();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewSowType = () => {
    const { blockAddProjectType } = this.state;
    if (!blockAddProjectType) {
      this.setState({
        showAddSowTypeFields: true,
      });
    }
  }

  setNewSowType = ({ target }) => {
    const { addSowType } = this.state;
    this.setState({
      addSowType: {
        ...addSowType,
        [target.name]: target.value,
      },
    }, this.newTypeValidation);
  }

  newTypeValidation = () => {
    const { addSowType } = this.state;
    this.setState({
      blockSaveType: !!addSowType.Name.trim(),
      blockAddProjectType: !!addSowType.Name.trim(),
    });
  };

  newTypeCancel = () => {
    this.setState({
      addSowType: { Name: '' },
      showAddSowTypeFields: false,
      blockSaveType: false,
      blockAddProjectType: false,
    });
  }

  newSowTypeApply = () => {
    const { postManageSowTypes } = this.props;
    const { blockSaveType, addSowType } = this.state;
    if (blockSaveType) {
      postManageSowTypes(addSowType);
      this.setState({
        addJobTitle: { Name: '' },
        showAddSowTypeFields: false,
        blockSaveType: false,
        blockAddProjectType: false,
      });
    }
  }

  deleteEntity = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `sow_types/${element.Id}`,
      searchUpdateSaga: 'sow_types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageSowTypes } = this.props;
    putManageSowTypes(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  render() {
    const { sowTypes, listSettings } = this.props;
    const {
      columnsToDisplay, showAddSowTypeFields, addSowType, blockAddProjectType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsProjectTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewSowType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search SoW type'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(sowTypes, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteEntity}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {showAddSowTypeFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addSowType}
              onChangeNewDocumentObj={this.setNewSowType}
              disableSaveDocument={blockAddProjectType}
              newDocumentCancel={this.newTypeCancel}
              newDocumentApply={this.newSowTypeApply}
            />
          </div>
        </div> : null}
      </div>
    );
  }
}

ManageSowTypes.propTypes = {
  sowTypes: PropTypes.array.isRequired,
  loadManageSowTypes: PropTypes.func.isRequired,
  putManageSowTypes: PropTypes.func.isRequired,
  postManageSowTypes: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  sowTypes: store.adminManageLookups.sowTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageSowTypes,
  putManageSowTypes,
  postManageSowTypes,
  showDialog,
  setListSettingsLookups,
})(ManageSowTypes);
