import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import http from 'services/http';
import { CONFIRM_DELETE_DIALOG, showAlertDialog } from '../actions/confirmationDialogActions.js';
import { setProjectRoles, setAllRoles } from 'pages/projects/actions/projectsActions.js';
import { getUppdateData } from 'utils/updateSagasConfig';
import { setEmployeeResume, setEmployeeProjects, setEmployeeSkills } from 'pages/members/actions/membersActions';
import history from 'utils/history';
import { BAD_REQUEST_STATUS } from 'utils/const-variable';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { resetContracts, setListSettings } from 'pages/contracts/actions/contractsActions';

export function* deleteItemConfirmation(action) {
  const updateData = getUppdateData(action.payload, history);
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'DELETE',
      url: `${action.payload.url}`,
    });

    if (action.payload.history) {
      history.push({
        pathname: action.payload.history,
        state: history.location.state,
      });
    }
    if (!updateData.noUppdate) {
      if (updateData.contractSaga) {
        const { searchValue } = yield select(state => state.contractsReducer.listSettings);
        if (searchValue) {
          yield put(setListSettings({ searchValue: '' }));
        } else {
          yield put(resetContracts());
          const expiredContracts = yield call(http, {
            method: 'GET',
            url: updateData.url,
          });
          yield put(updateData.saga.setExpiredContracts(expiredContracts.data.Contracts));
          yield put(updateData.saga.setTotalCountContracts(expiredContracts.data.TotalCount));
        }
      } else if (updateData.params) {
        const employee = yield call(http, {
          method: 'GET',
          url: updateData.url,
          params: updateData.params,
        });
        yield put(updateData.saga(employee.data));
      } else {
        const employee = yield call(http, {
          method: 'GET',
          url: updateData.url,
        });
        yield put(updateData.saga(employee.data));
      }
    }

    if (updateData.isEmployee) {
      const resume = yield call(http, {
        method: 'GET',
        url: `employees/${action.payload.entityId}/resumes`,
      });
      yield put(setEmployeeResume(resume.data));

      const projects = yield call(http, {
        method: 'GET',
        url: `employees/${action.payload.entityId}/projects`,
      });
      yield put(setEmployeeProjects(projects.data));

      const skills = yield call(http, {
        method: 'GET',
        url: `employees/${action.payload.entityId}/skills`,
      });
      yield put(setEmployeeSkills(skills.data));
    }

    if (updateData.roleSaga) {
      const roleData = yield call(http, {
        method: 'GET',
        url: `projects/${action.payload.entityId}/roles?isOnlyActive=${action.payload.isOnlyActive}`,
      });
      yield put(setProjectRoles(roleData.data));

      if (!action.payload.isOnlyActive) {
        yield put(setAllRoles(roleData.data));
      } else {
        const allRoles = yield call(http, {
          method: 'GET',
          url: `projects/${action.payload.entityId}/roles?isOnlyActive=false`,
        });
        yield put(setAllRoles(allRoles.data));
      }
    }

    if (updateData.isTeam) {
      const teams = yield call(http, {
        method: 'GET',
        url: `projects/${action.payload.entityId}/teams`,
      });
      yield put(updateData.saga(teams.data));
    }
  } catch (e) {
    if ((e.response && e.response.status === BAD_REQUEST_STATUS) || (e.config && e.config.method === 'delete')) {
      yield put(showAlertDialog(updateData.message));
    }
  } finally {
    yield put(finishRequest());
  }
}

export default function* ConformationDialogRootSaga() {
  yield takeEvery(CONFIRM_DELETE_DIALOG, deleteItemConfirmation);
}
