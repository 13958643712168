import {
  CLEAR_ERROR_MESSAGE,
  SET_COUNTRIES,
  SET_OFFICE_DETAILS,
  SET_OFFICE_ERROR,
  SET_OFFICES,
  SET_HOLIDAYS,
  RESET_OFFICES,
  RESET_OFFICE_DETAILS,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
  SET_BASE_BUSINESS_LOCATIONS,
  SET_OFFICE_BUSINESS_LOCATIONS,
} from '../actions/officesActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: '',
  sortDirection: '',
};

const initialState = {
  offices: [],
  officeDetails: {},
  countries: [],
  columnsToDisplay: [
    {
      Name: 'Office', Id: 'Name', className: 'biggest-col', necessary: true,
    },
    {
      Name: 'Domain name', Id: 'DomainName', className: 'biggest-col',
    },
    {
      Name: 'Address', Id: 'Address', className: 'biggest-col',
    },
    {
      Name: 'Members', Id: 'EmployeesCount', className: 'biggest-col',
    },
    {
      Name: 'Projects', Id: 'ProjectsCount', className: 'biggest-col',
    },
    {
      Name: 'Contracts', Id: 'ContractsCount', className: 'biggest-col',
    },
  ],
  holidays: [],
  error: false,
  listSettings: defaultListSettings,
  businessLocations: [],
  baseBusinessLocations: [],
};

const officesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFICES:
      return {
        ...state,
        offices: action.payload.offices,
      };
    case RESET_OFFICES:
      return {
        ...state,
        offices: [],
      };
    case SET_OFFICE_DETAILS:
      return {
        ...state,
        officeDetails: action.payload,
      };
    case RESET_OFFICE_DETAILS:
      return {
        ...state,
        officeDetails: {},
      };
    case SET_OFFICE_ERROR:
      return {
        ...state,
        error: true,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: false,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_HOLIDAYS:
      return {
        ...state,
        holidays: action.payload,
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_OFFICE_BUSINESS_LOCATIONS:
      return {
        ...state,
        businessLocations: action.payload,
      };
    case SET_BASE_BUSINESS_LOCATIONS:
      return {
        ...state,
        baseBusinessLocations: action.payload,
      };
    default:
      return state;
  }
};

export default officesReducer;
