export const routeData = {
  TotalOffice: '/employees',
  TotalAdmin: '/employees',
  TotalEmployee: '/employees',
  TotalTrainee: '/employees',
  TotalTechStaff: '/employees',
  Contractors: '/employees',
  BillableOnSite: '/employees',
  AllocationBillable: '/reports/workforce',
  AllocationInvestedAkvelon: '/reports/workforce',
  AllocationInvestedClient: '/reports/workforce',
  CapacityBench: '/reports/bench',
  CapacityBenchIn30Days: '/reports/bench',
};
