import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MilestoneForm from './milestone-form';

export class MilestoneItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowOverride: true,
    };
  }

  getSelectOptionsForItem = () => {
    const { selectedMilestones, selectOptionsForMilestones, item } = this.props;
    const selectedOptionsToFilter = item.Milestone ? [...selectOptionsForMilestones, item.Milestone] : selectOptionsForMilestones;
    return selectedOptionsToFilter.filter((option, index, array) => (
      array.indexOf(option) === index && (option === item.Milestone || !selectedMilestones.includes(option))
    ));
  }

  getNewValue = (data) => {
    switch (true) {
      case data === null:
        return null;
      case typeof data === 'string':
        return data;
      case data.target.name === 'InvoiceDate':
        return data.item;
      case data.target.name === 'Approved':
      case data.target.name === 'UsedAssignmentBackupUser':
        return data.target.checked;
      default: return data.target.value;
    }
  }

  onChangeItem = (data, option) => {
    const {
      onChange, item, itemId,
    } = this.props;
    const milestone = {
      ...item,
      [typeof option === 'string' ? option : data.target.name]: this.getNewValue(data),
    };
    onChange(milestone, itemId);
  }

  onDeleteItem = () => {
    const {
      onDeleteItem, item,
    } = this.props;
    onDeleteItem(item);
  }

  onCopyItem = () => {
    const {
      onCopyItem, item,
    } = this.props;
    onCopyItem(item);
  }

  onTogglingRemainingBalanceOverridePossibility = () => {
    const {
      onChange, item, itemId,
    } = this.props;
    const milestone = {
      ...item,
      RemainingBalanceOverridePossibility: !item.RemainingBalanceOverridePossibility,
    };
    onChange(milestone, itemId);
  }

  render() {
    const {
      item,
      disabled,
      currencyProject,
      columnsToDisplay,
    } = this.props;
    return (
      <div
        className={classNames('c-list__item-milestone')}
      >
        <MilestoneForm
          disabled={disabled}
          item={item}
          onChange={this.onChangeItem}
          onDeleteItem={this.onDeleteItem}
          onCopyItem={this.onCopyItem}
          selectOptionForMilestone={this.getSelectOptionsForItem()}
          onTogglingEditMode={this.onTogglingRemainingBalanceOverridePossibility}
          currencyProject={currencyProject}
          columnsToDisplay={columnsToDisplay}
        />
      </div>
    );
  }
}

MilestoneItem.propTypes = {
  item: PropTypes.object,
};

export default connect(store => ({
  isAdmin: store.userRoleReducer.isAdmin,
  isOnlyActive: store.showRoles.isOnlyActiveRoles,
  roles: store.userRoleReducer.roles,
}))(MilestoneItem);
