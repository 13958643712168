import {
  START_REQUEST, FINISH_REQUEST,
} from '../actions/loadingActions';

const initialState = {
  isLoading: 0,
};

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case START_REQUEST:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };
    case FINISH_REQUEST:
      return {
        ...state,
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };
    default:
      return state;
  }
}
