export const GET_MANAGE_SKILLS = '@admin/GET_MANAGE_SKILLS';
export const SET_MANAGE_SKILLS = '@admin/SET_MANAGE_SKILLS';
export const PUT_MANAGE_SKILLS = '@admin/PUT_MANAGE_SKILLS';
export const POST_MANAGE_SKILLS = '@admin/POST_MANAGE_SKILLS';
export const MERGE_MANAGE_SKILLS = '@admin/MERGE_MANAGE_SKILLS';

export const loadManageSkills = () => ({
  type: GET_MANAGE_SKILLS,
});

export const setManageSkills = (skills) => ({
  type: SET_MANAGE_SKILLS,
  payload: skills,
});

export const putManageSkill = (skill) => ({
  type: PUT_MANAGE_SKILLS,
  payload: skill,
});

export const postManageSkill = (newSkill) => ({
  type: POST_MANAGE_SKILLS,
  payload: newSkill,
});

export const mergeManageSkills = (mergedElements) => ({
  type: MERGE_MANAGE_SKILLS,
  payload: mergedElements,
});
