import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getManageRoleTypes, createNewRoleTypes, updateRoleTypes } from 'pages/admin/actions/adminManageRoleTypes.js';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';
import { List } from '../../../../../common/components/list/list';
import { CellTextField } from 'common/components/list/custom-cell/cell-toogle-textfield';
import TextField from 'common/components/form-items/input/text-field';
import classNames from 'classnames';

export class ManageRoleTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Role Types', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
        {
          Name: 'Code', isSelected: true, className: 'biggest-col', Id: 'Code',
        },
      ],
      showAddRoleFields: false,
      addRoleType: { Name: '' },
      blockAddRoleType: false,
      blockSaveRoleType: false,
      newRoleTypeName: '',
      newRoleTypeCode: '',
    };
  }

  componentDidMount() {
    const { getManageRoleTypes } = this.props;
    getManageRoleTypes();
  }

  addNewRoleType = () => {
    const { blockAddRoleType } = this.state;
    if (!blockAddRoleType) {
      this.setState({
        showAddRoleFields: true,
      });
    }
  }

  newRoleValidation = () => {
    const { newRoleTypeName } = this.state;
    this.setState({
      blockSaveRoleType: !!newRoleTypeName.trim(),
      blockAddRoleType: !!newRoleTypeName.trim(),
    });
  };

  newRoleTypeCancel = () => {
    this.setState({
      newRoleTypeName: '',
      newRoleTypeCode: '',
      showAddRoleFields: false,
      blockSaveRoleType: false,
      blockAddRoleType: false,
    });
  }

  newRoleTypeApply = () => {
    const { createNewRoleTypes } = this.props;
    const { blockSaveRoleType, newRoleTypeName, newRoleTypeCode } = this.state;
    if (blockSaveRoleType) {
      const data = {
        Name: newRoleTypeName,
        Code: newRoleTypeCode,
      };
      createNewRoleTypes(data);
      this.newRoleTypeCancel();
    }
  }

  deleteRoleTypes = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `role_types/${data.item.Id}`,
      searchUpdateSaga: 'role-types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateRoleTypes } = this.props;
    const { Name, Id, Code } = data;
    updateRoleTypes(Name, Id, Code);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  onChangeNewRoleTypeName = (event) => {
    this.setState({
      newRoleTypeName: event.target.value,
    }, this.newRoleValidation);
  };

  onChangeNewRoleTypeCode = (event) => {
    this.setState({
      newRoleTypeCode: event.target.value,
    }, this.newRoleValidation);
  };

  render() {
    const {
      manageRoleType,
      listSettings,
    } = this.props;
    const {
      columnsToDisplay, showAddRoleFields, blockAddRoleType, newRoleTypeName, newRoleTypeCode,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsRoleTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewRoleType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search role types'
          searchValue={searchValue}
        />
        <List
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(manageRoleType, sortColumn, sortDirection), searchValue)}
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          hideEntityEdit
          onDeleteClick={this.deleteRoleTypes}
          isHaveRights
          config={{
            'Name': {
              cell: CellTextField,
              valueSetter: this.editEntity,
            },
            'Code': {
              cell: CellTextField,
              valueSetter: this.editEntity,
              showPlaceholderIfNull: true,
            },
          }}
        />
        {
          showAddRoleFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <TextField
                formClassName='change-border__input-cell add-calendar--text-field'
                inputClassName='change-name__input'
                value={newRoleTypeName}
                name='newRoleTypeName'
                maxlength='4000'
                placeholder='Enter a name'
                onChange={this.onChangeNewRoleTypeName}
                autoFocus
              />
            </div>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <TextField
                formClassName='change-border__input-cell add-calendar--text-field'
                inputClassName='change-name__input'
                value={newRoleTypeCode}
                name='newRoleTypeCode'
                maxlength='4000'
                placeholder='Enter a code'
                onChange={this.onChangeNewRoleTypeCode}
              />
            </div>
            <div className='add-calendar--button-block'>
              <div className='toolbar-button resume-buttom-cancel' onClick={this.newRoleTypeCancel}>
                <span>Cancel</span>
              </div>
              <div
                className={classNames(
                  'button next resume-buttom-apply',
                  { 'btn-disabled': !blockAddRoleType },
                )}
                onClick={this.newRoleTypeApply}
              >
                <span>Apply</span>
              </div>
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageRoleTypes.propTypes = {
  getManageRoleTypes: PropTypes.func,
};

export default connect((store) => ({
  manageRoleType: store.adminManageLookups.roleTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  getManageRoleTypes,
  createNewRoleTypes,
  updateRoleTypes,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageRoleTypes);
