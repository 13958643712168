import { SET_MANAGE_API_TOKENS } from '../actions/adminManageApiTokens';
import {
  SET_LIST_SETTINGS_LOOKUPS,
  RESET_LIST_SETTINGS_LOOKUPS,
} from '../actions/adminActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
};

const initialState = {
  apiTokens: [],
  listSettings: defaultListSettings,
  expirationDate: '',
};

const adminManageApiTokens = (state = initialState, action) => {
  switch (action.type) {
    case SET_MANAGE_API_TOKENS:
      return {
        ...state,
        apiTokens: action.payload,
      };
    case SET_LIST_SETTINGS_LOOKUPS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS_LOOKUPS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    default:
      return state;
  }
};

export default adminManageApiTokens;
