export const SET_TABS = '@tabs/SET_TABS';
export const GET_TABS = '@tabs/GET_TABS';

export const setSelectedTab = (tabNumber) => {
    return {
        type: SET_TABS,
        payload: tabNumber,
    }
}

export const getSelectedTab = (tabNumber) => {
    return {
        type: GET_TABS,
        payload: tabNumber,
    }
}
