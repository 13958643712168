import { CLOSE_ADMIN_MODAL, SET_INFO_FOR_ADMIN, SHOW_ADMIN_MODAL } from '../actions/modalActions';
import { SET_USER_DETAILS, SET_USER_OFFICES } from '../actions/adminActions';

const initialState = {
  isVisible: false,
  userDetails: {},
  userOffices: [],
  employees: [],
  employeesRoles: [],
  securityRoleSubstitutesConfig: [],
  modalType: null,
};

const adminModalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_ADMIN_MODAL:
      return {
        ...state,
        isVisible: true,
        modalType: action.payload.modalType,
      };
    case CLOSE_ADMIN_MODAL:
      return {
        ...state,
        isVisible: false,
        userDetails: {},
        modalType: null,
      };
    case SET_INFO_FOR_ADMIN:
      return {
        ...state,
        employees: action.payload.employees,
        employeesRoles: action.payload.employeesRoles,
        securityRoleSubstitutesConfig: action.payload.securityRoleSubstitutesConfig,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.user,
      };
    case SET_USER_OFFICES:
      return {
        ...state,
        userOffices: action.payload,
      };
    default:
      return state;
  }
};

export default adminModalReducer;
