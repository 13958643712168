import {
  SET_AUDIT_LIST_SETTINGS,
  RESET_AUDIT_LIST_SETTINGS,
  SET_AUDIT_LIST_DATES,
  RESET_AUDIT_LIST_DATES,
} from '../actions/auditActions';
import moment from 'moment';

const defaultDateValues = {
  StartDate: moment().subtract(14, 'days').set({ 'hour': 12, 'minute': 0 }).format('YYYY-MM-DD HH:mm'),
  EndDate: moment().format('YYYY-MM-DD HH:mm'),
};

const defaultListSettings = {
  searchValue: '',
  filters: {
    ...defaultDateValues,
  },
};

const initialState = {
  listSettings: defaultListSettings,
  filtersDates: defaultDateValues,
};

const auditReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIT_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_AUDIT_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_AUDIT_LIST_DATES:
      return {
        ...state,
        filtersDates: {
          ...state.filtersDates,
          ...action.payload,
        },
      };
    case RESET_AUDIT_LIST_DATES:
      return {
        ...state,
        filtersDates: defaultDateValues,
      };
    default:
      return state;
  }
};

export default auditReducer;
