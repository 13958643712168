export const GET_XLSX_DOCUMENT = '@reports/GET_XLSX';

export const downloadXLSXDocument = (filters = {}, searchvalue = '', url, docName) => ({
  type: GET_XLSX_DOCUMENT,
  payload: {
    searchvalue,
    filters,
    url,
    docName,
  },
});
