import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FilterItemComponent from './filter-item';
import './list-toolbar.css';
import CustomIcon from '../icon/Icon';
import isEmptyObject from 'utils/isEmptyObject';
import { SearchField } from './components/search-field';
import { DateRange } from './components/date-range';
import { Slider } from 'common/components/slider/slider';
import { ButtonsGroup } from './components/buttons-group';
import { ToolBarCheckbox } from './components/toolbar-checkbox';
import isObjectNotEquals from 'utils/object-comparison';
import moment from 'moment';

class ListToolbar extends React.Component {
  constructor(props) {
    super(props);
    const { departments, filters } = this.props;
    this.state = {
      showFilters: false,
      showOptions: false,
      filters: filters || {},
      department: departments ? departments[0] : '',
      showFiltersChanging: false,
      Year: new Date().getFullYear(),
      filterOptions: [],
    };
  }

  componentDidMount() {
    this.checkFilters();
    this.renderFilterOptions();
    document.addEventListener('mousedown', this.onClickOutsideHandler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      isObjectNotEquals(nextProps, this.props)
      || isObjectNotEquals(nextState, this.state)
    );
  }

  componentDidUpdate(prevProps) {
    const { filters, items } = this.props;
    if (prevProps.filters !== filters) {
      this.setDefaultFilters(filters);
    }
    if (prevProps.items !== items) {
      this.renderFilterOptions();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutsideHandler);
  }

  onClickOutsideHandler = (event) => {
    const filtersMenu = document.getElementById('filters-menu');
    const dialog = document.getElementsByClassName('MuiDialog-root')[0];
    const paper = document.getElementsByClassName('MuiPaper-root')[0];
    const configurationOptions = document.getElementById('configuration-options');

    if (filtersMenu && !filtersMenu.contains(event.target) && !(dialog && dialog.contains(event.target)) && !(paper && paper.contains(event.target))) {
      this.setState({
        showFilters: false,
      });
    }
    if (configurationOptions && !configurationOptions.contains(event.target)) {
      this.setState({
        showOptions: false,
      });
    }
  }

  setDefaultFilters = (filters) => {
    this.setState(
      {
        filters,
      },
      this.checkFilters,
    );
  };

  handleChangeDate = ({ target }) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        Year: target.value,
      },
      Year: target.value,
    });
  };

  checkFilters = () => {
    const { filters } = this.props;
    if (typeof filters === 'undefined' || isEmptyObject(filters)) {
      this.setState({
        showFiltersChanging: false,
      });
    } else {
      this.setState({
        showFiltersChanging: !(
          Object.keys(filters).length === 1
          && Object.keys(filters)[0] === 'isEmpty'
        ),
      });
    }
  };

  onToggleFilters = () => {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters });
  };

  onToggleOptions = () => {
    const { showOptions } = this.state;
    this.setState({ showOptions: !showOptions });
  };

  changeColumnsToDisplay = (event) => {
    const index = event.target.value;
    const { columnsToDisplay, changeColumnsToDisplay } = this.props;
    const newColumnsArray = JSON.parse(JSON.stringify(columnsToDisplay));
    if (!newColumnsArray[index].necessary) {
      newColumnsArray[index].isSelected = !newColumnsArray[index].isSelected;
    }
    changeColumnsToDisplay(newColumnsArray);
  };

  applyFilters = () => {
    const { filters } = this.state;
    const { applyFilters } = this.props;
    this.onToggleFilters();
    const filtersCopy = filters;
    applyFilters(
      Object.keys(filtersCopy).reduce((acc, key) => {
        if (filtersCopy[key] !== '0') acc[key] = filtersCopy[key];
        return acc;
      }, {}),
    );
  };

  resetFilters = () => {
    const { resetFilters } = this.props;
    resetFilters && resetFilters();
    this.setState({
      filters: {},
      showFiltersChanging: false,
      Year: new Date().getFullYear(),
      filterOptions: [],
    }, this.renderFilterOptions);
  };

  renderFilterOptions = () => {
    const { searchDropdownYears, items } = this.props;
    const { Year } = this.state;
    items && this.setState({
      filterOptions: items.map((item, i) => {
        return (
          <FilterItemComponent
            key={i}
            index={i}
            title={item.name}
            id={item.id}
            selectOption={this.selectOption}
            multiSelect={item.multiSelect}
            multiple={item.multiple}
            suggestBox={item.suggestBox}
            textField={item.textField}
            searchDropdown={item.searchDropdown}
            itemList={item.options}
            placeholder={item.placeholder}
            filtersItem={item.filtersItem}
            datepicker={item.datepicker}
            formClassName={item.formClassName}
            datePickerClassName={item.datePickerClassName}
            value={searchDropdownYears ? Year : item.value}
            minVal={item.minVal}
            maxVal={item.maxVal}
            onChange={
              searchDropdownYears
                ? this.handleChangeDate
                : item.onChange
            }
            searchDropdownYears={item.searchDropdownYears}
            className={item.className}
            clearValue={item.clearValue}
            inputClassName={item.inputClassName}
            isMonthAndYear={item.isMonthAndYear}
            disabled={item.disabled}
            onError={item.onError}
            minErrorMassage={item.minErrorMassage}
            maxErrorMassage={item.maxErrorMassage}
            hideAllElement={item.hideAllElement}
          />
        );
      }),
    });
  };

  selectOption = (value, title) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [title]: value,
      },
    });
  };

  ADSync = () => {
    const { onADSync } = this.props;
    const { department } = this.state;
    onADSync(department);
  };

  onAddNew = () => {
    const { onAddNew, disabled } = this.props;
    !disabled && onAddNew();
  };

  checkValideDates = () => {
    const { items } = this.props;
    const datesFilters = items.filter((item) => item.validate);
    return datesFilters.every((item) => {
      if (!item.value || item.value === 'Invalid date') {
        return false;
      }
      if (
        item.value
        && item.minVal
        && item.maxVal
        && (moment(item.value).isBefore(item.minVal)
          || moment(item.value).isAfter(item.maxVal))
      ) {
        return false;
      }
      if (
        item.value
        && item.minVal
        && moment(item.value).isBefore(item.minVal)
      ) {
        return false;
      }
      return true;
    });
  };

  getTextForRolesTooltip = () => {
    return (
      <p>
        <span>Only non-removed roles and employees should be displayed by default.</span>
      </p>
    );
  };

  getTextForFinishedProjectsTooltip = () => {
    return (
      <p>
        <span>Filter excludes the projects which are finished by the current moment and does not depend on a report date range</span>
      </p>
    );
  }

  render() {
    const {
      title,
      detailsStyles,
      searchPlaceholder,
      items,
      applyFilters,
      columnsToDisplay,
      changeColumnsToDisplay,
      showAddButton,
      showExportButton,
      showMargeButton,
      exportDocument,
      showSyncButton,
      showTitleText,
      blockAddButton,
      addExtend,
      showToogleForRoles,
      isActiveExtend,
      extendRoles,
      defaultChecked,
      handleChangeShowRoles,
      searchField = true,
      showDateRange,
      startDateHistory,
      endDateHistory,
      handleChooseDate,
      clearStartDate,
      clearEndDate,
      onSearchClick,
      customAddButton,
      mergeElements,
      isActiveMerge,
      SOWExtensionOptions,
      handleChangeNestedItems,
      location,
      search,
      searchValue,
      searchValueLocal,
      validateFilterDates = false,
      showButtonOptions,
      onImportFile,
      onDownloadFile,
      showImportSOWButton,
      customImportSOWButton,
      blockImportSOWButton,
      showAddMSAButton,
      customAddMSAButton,
      blockAddMSAButton,
      onAddMSA,
      showToggleForFinishedProjects,
      handleToggleForFinishedProjects,
      containerClassName,
    } = this.props;
    const {
      showFiltersChanging, showFilters, showOptions, filterOptions,
    } = this.state;
    const applyIsDisabled = validateFilterDates
      ? !this.checkValideDates()
      : false;
    return (
      <div className={classNames('toolbar-container', containerClassName)}>
        <div className='toolbar-container__main-part'>
          {title && (
            <div className='projects-amount'>
              <span>{title}</span>
            </div>
          )}
          <div
            className={classNames('toolbar', {
              'details-toolbar': detailsStyles,
            })}
          >
            <div className='left-toolbar-part'>
              {searchField && (
                <SearchField
                  location={location}
                  searchPlaceholder={searchPlaceholder}
                  onSearchClick={onSearchClick}
                  search={search}
                  searchValue={searchValue}
                  searchValueLocal={searchValueLocal}
                />
              )}
              {showDateRange && (
                <DateRange
                  startDateHistory={startDateHistory}
                  endDateHistory={endDateHistory}
                  handleChooseDate={handleChooseDate}
                  clearEndDate={clearEndDate}
                  clearStartDate={clearStartDate}
                />
              )}
              <div className='filter-options'>
                {items && applyFilters ? (
                  <div className='filters' onClick={this.onToggleFilters}>
                    <CustomIcon
                      iconName={
                        !!showFiltersChanging
                          ? 'filters-settings-off'
                          : 'filters-settings-on'
                      }
                    />
                    <CustomIcon
                      iconName={
                        showFilters
                          ? 'configuration-icon-off'
                          : 'configuration-icon-on'
                      }
                    />
                  </div>
                ) : null}
                {columnsToDisplay && changeColumnsToDisplay ? (
                  <div className='configuration' onClick={this.onToggleOptions}>
                    <CustomIcon
                      iconName='list-toolbar-settings'
                      className={classNames({ 'i-active': showOptions })}
                    />
                  </div>
                ) : null}
                {columnsToDisplay && changeColumnsToDisplay ? (
                  <div
                    className={
                      showOptions
                        ? 'configuration-options-cont'
                        : 'configuration-options-cont hide-config'
                    }
                    id='configuration-options'
                  >
                    {columnsToDisplay.map((header, i) => {
                      return !header.necessary ? (
                        <ToolBarCheckbox
                          key={i}
                          value={i}
                          header={header}
                          onChange={this.changeColumnsToDisplay}
                        />
                      ) : null;
                    })}
                  </div>
                ) : null}
              </div>
              {showToogleForRoles && (
                <Slider
                  onChange={handleChangeShowRoles}
                  label='Show all roles'
                  defaultChecked={defaultChecked}
                  isTooltip
                  textForTooltip={this.getTextForRolesTooltip()}
                  tooltipClassName='tooltip-roles--text'
                />
              )}
              {SOWExtensionOptions && (
                <>
                  <Slider
                    onChange={handleChangeNestedItems}
                    label='Export reports with PO'
                    defaultChecked
                    name='POs'
                    htmlFor='POs'
                    id='POs'
                  />
                  <Slider
                    onChange={handleChangeNestedItems}
                    label='Export reports with Amendment'
                    defaultChecked
                    name='Amendments'
                    htmlFor='Amendments'
                    id='Amendments'
                  />
                </>
              )}
              {showToggleForFinishedProjects && (
                <Slider
                  onChange={handleToggleForFinishedProjects}
                  label='Include Finished Projects'
                  defaultChecked
                  isTooltip
                  textForTooltip={this.getTextForFinishedProjectsTooltip()}
                  tooltipClassName='tooltip-roles--text'
                />
              )}
            </div>
            <ButtonsGroup
              showImportSOWButton={showImportSOWButton}
              showSyncButton={showSyncButton}
              addExtend={addExtend}
              showAddButton={showAddButton}
              showExportButton={showExportButton}
              showMargeButton={showMargeButton}
              isActiveExtend={isActiveExtend}
              ADSync={this.ADSync}
              extendRoles={extendRoles}
              exportDocument={exportDocument}
              onAddNew={this.onAddNew}
              mergeElements={mergeElements}
              blockAddButton={blockAddButton}
              showTitleText={showTitleText}
              customAddButton={customAddButton}
              customImportSOWButton={customImportSOWButton}
              isActiveMerge={isActiveMerge}
              showButtonOptions={showButtonOptions}
              onImportFile={onImportFile}
              onDownloadFile={onDownloadFile}
              blockImportSOWButton={blockImportSOWButton}
              showAddMSAButton={showAddMSAButton}
              customAddMSAButton={customAddMSAButton}
              blockAddMSAButton={blockAddMSAButton}
              onAddMSA={onAddMSA}
            />
          </div>
        </div>
        {items && applyFilters ? (
          <div
            className={
              showFilters ? 'filters-menu' : 'filters-menu hide-filters'
            }
            id='filters-menu'
          >
            <div className='filter-options'>
              {filterOptions}
            </div>
            <div className='filter-buttons'>
              <div className='filter-button reset' onClick={this.resetFilters}>
                <span>Reset</span>
              </div>
              <div
                className={classNames('filter-button apply', {
                  'button-disabled': applyIsDisabled,
                })}
                onClick={this.applyFilters}
              >
                <span>Apply</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

ListToolbar.propTypes = {
  applyFilters: PropTypes.func,
  onSearchClick: PropTypes.func,
  changeColumnsToDisplay: PropTypes.func,
  columnsToDisplay: PropTypes.array,
  items: PropTypes.array,
  title: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  showAddButton: PropTypes.bool,
  showExportButton: PropTypes.bool,
  showImportSOWButton: PropTypes.bool,
  detailsStyles: PropTypes.bool,
  departments: PropTypes.array,
  onADSync: PropTypes.func,
  mergeElements: PropTypes.func,
};

export default connect(
  (store) => ({
    user: store.authReducer.user,
  }),
  {},
)(ListToolbar);
