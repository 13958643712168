import {
  call, put, takeEvery, select,
} from 'redux-saga/effects';
import {
  IMPORT_SOW,
  IMPORT_PO,
  IMPORT_AMENDMENT,
  ADD_SOW_TO_PARTIAL_CONTRACT,
  ADD_PO_TO_PARTIAL_CONTRACT,
  ADD_AMENDMENT_TO_PARTIAL_CONTRACT,
  ADD_ROLE_TO_PARTIAL_CONTRACT,
  CANCEL_IMPORT,
  POST_ROLE,
  STEP_BACK_IMPORT_ROLE,
  setImportSow,
  setImportPo,
  setImportAmendment,
  startImportLoader,
  finishImportLoader,
  addContractToPartialContract,
  setRoleToPartialContract,
  deleteImportedContract,
  removeRole,
  moveRoleBackToContract,
  addContractConfig,
} from '../actions/importContractAction';
import {
  getProjectEditSOWFormData,
  getProjectEditPOFormData,
  getProjectAmedmentFormData,
} from 'common/actions/reduxFormActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { getUpdatedDocuments } from 'utils/documentsUtils';
import history from 'utils/history';
import { getProjectDetails } from './projectsSaga';
import http from 'services/http';
import { addNewNotification } from 'services/addNewNotification.js';
import { addNotify } from 'common/actions/notificationsActions';
import { patchContractData } from './contractsSaga';
import { mapToChildContractMilestone, mapToMainContractMilestone } from '../../../services/milestone';

function getAssignmentData(employee, role) {
  return {
    employeeId: employee.EmployeeId,
    startDate: employee.EmployeeStartDate || employee.StartDate,
    endDate: employee.EmployeeEndDate || employee.EndDate,
    allocation: employee.EmployeeAssignment || employee.Assignment,
    status:
      employee.Status && typeof employee.Status === 'object'
        ? employee.Status.Id
        : employee.EmployeeStatusId,
    id: employee.Id ? null : employee.AssignmentKey,
    PrimaryAssignmentId: employee.primaryAssignmentId,
    role: employee.EmployeeRole || employee.Role,
    PositionId: role.ItemId,
    projectId: role.projectId,
    comment: employee.EmployeeComment,
    ShadowAssignments:
      employee.backupEmployees && employee.backupEmployees.length
        ? employee.backupEmployees
          .filter((employee) => employee && employee.EmployeeId)
          .map((employee) => getAssignmentData(employee, role))
        : [],
  };
}

function* fetchRole({ payload }) {
  try {
    const { roles, projectId } = payload;
    const requestModel = roles.map((role) => ({
      RoleName: role.RoleName,
      projectId: projectId,
      ContractName: role.BillingCode,
      rate: String(role.Rate),
      rateOvertime: role.OvertimeRate,
      startDate: role.StartDate,
      endDate: role.EndDate,
      SeniorityId: role.SeniorityId,
      expertRoleId: role.RoleId,
      type: role.AssignmentTypeId,
      sow: { sowid: role.BillingCodeId },
      allocation: role.Assignment,
      billability: role.BillableStatusId,
      status: role.StatusId,
      rateUnit: role.rateUnit,
      overtimeRateUnit: role.overtimeRateUnit,
      Comment: role.Comment,
      assignments: role.Employees.filter(
        (employee) => employee && employee.EmployeeId,
      ).map((employee) => getAssignmentData(employee, role)),
    }));

    yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/roles/batch`,
      data: requestModel,
    });
  } catch (e) {
    const message = addNewNotification(e, 'Document', ' cannot be created');
    yield put(addNotify(message));
  }
}

export function* importSow({ payload }) {
  try {
    yield put(startImportLoader());
    const { file, projectId } = payload;
    const formData = new FormData();
    formData.append('document', file);
    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/sows/documents`,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
      data: formData,
    });
    yield put(setImportSow(doc.data));
    history.push({
      pathname: `/projects/${projectId}/add-sow`,
    });
  } catch (e) {
    const message = addNewNotification(e, 'Document', ' cannot be parsed');
    yield put(addNotify(message));
  } finally {
    yield put(finishImportLoader());
  }
}
export function* setSow(action) {
  const form = yield select(getProjectEditSOWFormData);
  const {
    Contract,
    Type,
    ApprovalStatus,
    Approver,
    StatusComments,
    BudgetComments,
    Company,
    Project,
    Status,
    StartDate,
    EndDate,
    BillingCode,
    TravelBudget,
    TravelReimbursement,
    BusinessGroup,
    ProjectTeam,
    Links,
    PaymentTerm,
    Milestones,
    SowRenewalStatuses,
    InvoiceDate,
  } = form.values;
  let withoutEmptyObjSOW = [];
  if (Links.length) {
    withoutEmptyObjSOW = getUpdatedDocuments(Links, null, 'SOWId');
  }
  const arraySowRenewalStatuses = SowRenewalStatuses.map((status) => ({
    RenewalStatusId: status.RenewalStatusId,
    Value: status.Value,
    IsActive: status.IsActive,
  }));
  const data = {
    SOWId: Contract,
    SowTypeId: Type.Id,
    ApprovalStatus: ApprovalStatus.Id,
    SOWApproverId: Approver,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    ProjectId: Project.Id,
    Links: withoutEmptyObjSOW,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    BillingCode: BillingCode,
    TravelBudget: TravelBudget,
    TravelReimbursement: TravelReimbursement,
    BusinessGroup: BusinessGroup,
    ProjectTeam: ProjectTeam,
    PaymentTermId: PaymentTerm && PaymentTerm.Id,
    SowRenewalStatuses: arraySowRenewalStatuses,
    InvoiceDateId: InvoiceDate.Id,
    Milestones: Milestones,
  };
  const { configPatchRequestsForConfirmDialog } = action.payload;
  try {
    yield put(startRequest());
    yield put(addContractToPartialContract(data));
    yield put(addContractConfig(configPatchRequestsForConfirmDialog));
    history.push({
      pathname: '/projects/new/addrole',
    });
  } finally {
    yield put(finishRequest());
  }
}

export function* setRole(action) {
  try {
    const { projectId, isOnlyActive } = action.payload;
    const contract = yield select(
      (state) => state.importContractReducer.contract,
    );
    const partialContract = yield select(
      (state) => state.importContractReducer.partialContract,
    );
    yield put(startRequest());
    yield put(setRoleToPartialContract({ ...action.payload.role, projectId: projectId || partialContract.contract.ProjectId }));
    yield put(removeRole({ ...contract, Roles: contract.Roles.slice(1) }));
    if (contract.Roles.length === 1) {
      const roles = yield select(
        (state) => state.importContractReducer.partialContract.roles,
      );
      const contractType = yield select(
        (state) => state.importContractReducer.type,
      );
      const config = yield select(
        (state) => state.importContractReducer.config,
      );
      let doc;
      if (config && config.length) {
        for (let i = 0; i < config.length; i++) {
          const response = yield call(patchContractData, {
            payload: config[i],
          });
          const message = yield addNewNotification(response, 'Contract', ' updated');
          yield put(addNotify(message));
        }
      }

      const actualProjectId = projectId || partialContract.contract.ProjectId;

      switch (contractType) {
        case 'SOW': {
          doc = yield call(http, {
            method: 'POST',
            url: `projects/${actualProjectId}/sows`,
            data: partialContract.contract,
          });

          yield call(http, {
            method: 'PUT',
            url: `billing/projects/${actualProjectId}/statements-of-work/${doc.data}/milestones`,
            data: {
              milestones: (partialContract.contract.Milestones || []).map((milestone) => mapToMainContractMilestone(milestone)),
            },
          });

          break;
        }
        case 'PO': {
          doc = yield call(http, {
            method: 'POST',
            url: `projects/${actualProjectId}/sows/${partialContract.contract.SOWId}/pos`,
            data: partialContract.contract,
          });

          if (!partialContract.contract.POMainId) {
            yield call(http, {
              method: 'PUT',
              url: `billing/projects/${actualProjectId}/original-purchase-orders/${doc.data}/milestones`,
              data: {
                milestones: (partialContract.contract.Milestones || []).map((milestone) => mapToMainContractMilestone(milestone)),
              },
            });
          } else {
            yield call(http, {
              method: 'PUT',
              url: `billing/projects/${actualProjectId}/extension-purchase-orders/${doc.data}/milestones`,
              data: {
                milestones: (partialContract.contract.Milestones || []).map((milestone) => mapToChildContractMilestone(milestone)),
              },
            });
          }

          break;
        }
        case 'Amendment': {
          doc = yield call(http, {
            method: 'POST',
            url: `projects/${actualProjectId}/sows/${partialContract.contract.SOWId}/amendments`,
            data: partialContract.contract,
          });

          yield call(http, {
            method: 'PUT',
            url: `billing/projects/${actualProjectId}/amendments/${doc.data}/milestones`,
            data: {
              milestones: (partialContract.contract.Milestones || []).map((milestone) => mapToChildContractMilestone(milestone)),
            },
          });

          break;
        }
        default:
          break;
      }
      const message = addNewNotification(doc, 'Document', ' created');

      yield put(addNotify(message));

      yield* fetchRole({
        payload: {
          roles,
          projectId: actualProjectId,
        },
      });

      yield call(getProjectDetails, {
        payload: {
          id: actualProjectId,
          isOnlyActive: isOnlyActive,
        },
      });
      yield put(deleteImportedContract());
      history.push({
        pathname: `/projects/${actualProjectId}`,
      });
    } else {
      history.push({
        pathname: '/projects/new/addrole',
      });
    }
  } finally {
    yield put(finishRequest());
  }
}

export function* importPo({ payload }) {
  try {
    yield put(startImportLoader());
    const { file, projectId, sowId } = payload;
    const formData = new FormData();
    formData.append('document', file);
    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/sows/${sowId}/pos/documents`,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
      data: formData,
    });
    yield put(setImportPo(doc.data));
    history.push({
      pathname: `/projects/${projectId}/add-po/${sowId}`,
    });
  } catch (e) {
    const message = addNewNotification(e, 'Document', ' cannot be parsed');
    yield put(addNotify(message));
  } finally {
    yield put(finishImportLoader());
  }
}

export function* setPo(action) {
  const form = yield select(getProjectEditPOFormData);
  const {
    Contract,
    ApprovalStatus,
    Approver,
    StatusComments,
    BudgetComments,
    Company,
    Status,
    StartDate,
    EndDate,
    BillingCode,
    Links,
    PaymentTerm,
    Milestones,
    InvoiceDate,
  } = form.values;
  const { SOWId, configPatchRequestsForConfirmDialog, POMainId } = action.payload;
  let withoutEmptyObjPO = [];
  if (Links.length) {
    withoutEmptyObjPO = getUpdatedDocuments(Links, null, 'POId');
  }
  const data = {
    SOWId: SOWId,
    Number: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    POApproverId: Approver,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    Links: withoutEmptyObjPO,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    BillingCode: BillingCode,
    POMainId: POMainId,
    PaymentTermId: PaymentTerm && PaymentTerm.Id,
    InvoiceDateId: !POMainId && InvoiceDate && InvoiceDate.Id || null,
    Milestones: Milestones,
  };
  try {
    yield put(startRequest());
    yield put(addContractToPartialContract(data));
    yield put(addContractConfig(configPatchRequestsForConfirmDialog));
    history.push({
      pathname: '/projects/new/addrole',
    });
  } finally {
    yield put(finishRequest());
  }
}

export function* importAmendment({ payload }) {
  try {
    yield put(startImportLoader());
    const { file, projectId, sowId } = payload;
    const formData = new FormData();
    formData.append('document', file);
    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/sows/${sowId}/amendments/documents`,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
      data: formData,
    });
    yield put(setImportAmendment(doc.data));
    history.push({
      pathname: `/projects/${projectId}/add-amendment/${sowId}`,
    });
  } finally {
    yield put(finishImportLoader());
  }
}

export function* setAmendment(action) {
  const form = yield select(getProjectAmedmentFormData);
  const {
    Contract,
    ApprovalStatus,
    StatusComments,
    BudgetComments,
    Company,
    Status,
    StartDate,
    EndDate,
    Approver,
    Links,
    Milestones,
  } = form.data.values;
  const { configPatchRequestsForConfirmDialog } = action.payload;
  let withoutEmptyObj = [];
  if (Links.length) {
    withoutEmptyObj = getUpdatedDocuments(Links, null, 'AmendmentId');
  }
  const data = {
    SOWId: action.payload.SOWId,
    Number: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    Links: withoutEmptyObj,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    AmendmentApproverId: Approver,
    Milestones: Milestones,
  };
  try {
    yield put(startRequest());
    yield put(addContractToPartialContract(data));
    yield put(addContractConfig(configPatchRequestsForConfirmDialog));
    history.push({
      pathname: '/projects/new/addrole',
    });
  } catch (e) {
    const message = addNewNotification(e, 'Document', ' cannot be parsed');
    yield put(addNotify(message));
  } finally {
    yield put(finishRequest());
  }
}

export function* cancelContractImport(action) {
  try {
    yield put(deleteImportedContract());
  } finally {
    const { payload } = action;
    history.push({
      pathname: `/projects/${payload}`,
    });
  }
}

export function* moveRole() {
  const roles = yield select(
    (state) => state.importContractReducer.contract.Roles,
  );
  const partialContract = yield select(
    (state) => state.importContractReducer.partialContract,
  );
  const contractType = yield select(
    (state) => state.importContractReducer.type,
  );
  if (!partialContract.roles.length) {
    switch (contractType) {
      case 'SOW': {
        yield put(setImportSow({ ...partialContract.contract, Roles: roles }));
        history.push({
          pathname: `/projects/${partialContract.contract.ProjectId}/add-sow`,
        });
        break;
      }
      case 'PO': {
        yield put(setImportPo({ ...partialContract.contract, Roles: roles }));
        history.push({
          pathname: `/projects/${partialContract.contract.ProjectId}/add-po/${partialContract.contract.SOWId}`,
        });
        break;
      }
      case 'Amendment': {
        yield put(setImportAmendment({ ...partialContract.contract, Roles: roles }));
        history.push({
          pathname: `/projects/${partialContract.contract.ProjectId}/add-amendment/${partialContract.contract.SOWId}`,
        });
        break;
      }
      default:
        break;
    }
  } else {
    yield put(moveRoleBackToContract({
      importRoles: [partialContract.roles.at(-1), ...roles],
      partialRoles: partialContract.roles.slice(0, -1),
    }));
  }
}

export default function* importContractRootSaga() {
  yield takeEvery(IMPORT_SOW, importSow);
  yield takeEvery(IMPORT_PO, importPo);
  yield takeEvery(IMPORT_AMENDMENT, importAmendment);
  yield takeEvery(ADD_SOW_TO_PARTIAL_CONTRACT, setSow);
  yield takeEvery(ADD_PO_TO_PARTIAL_CONTRACT, setPo);
  yield takeEvery(ADD_AMENDMENT_TO_PARTIAL_CONTRACT, setAmendment);
  yield takeEvery(ADD_ROLE_TO_PARTIAL_CONTRACT, setRole);
  yield takeEvery(CANCEL_IMPORT, cancelContractImport);
  yield takeEvery(STEP_BACK_IMPORT_ROLE, moveRole);
  yield takeEvery(POST_ROLE, fetchRole);
}
