import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import reducers from 'common/reducers';
import sagas from 'common/sagas';

const composeEnhancers = composeWithDevTools({
  maxAge: 25,
  latency: 1500,
});

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'Theodolite',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'adminModalReducer',
    'dialogsReducer',
    'loadingReducer',
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
  ),
);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);
