import {
  all,
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import http from 'services/http';
import history from 'utils/history';
import {
  GET_EXPIRED_CONTRACTS,
  setExpiredContracts,
  EDIT_VIEW_CONTRACTS,
  IMPORT_FILE,
  setContractsAlerts,
  setContractsApprovalStatus,
  setContractsTypes,
  setTotalCountContracts,
  setImportFile,
  toggleFlagShow,
  DOWNLOAD_FILE,
  setStatusForDownloadedFile,
} from '../actions/contractsActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { setSettings } from '../../admin/actions/adminActions';
import { getProjectDetails, getCreationConfig } from '../../projects/sagas/projectsSaga';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { getDocumentStatuses } from '../../../common/actions/filtersActions';

export function* getExpiredContracts(action) {
  try {
    const {
      filters, skip, take, dontUploadFilters, sortSetting, searchValue,
    } = action.payload;

    yield put(startRequest());

    const searchString = encodeURIComponent(searchValue);
    const sortField = sortSetting && sortSetting.sortColumn;
    const orderByDesc = !(sortSetting && sortSetting.sortDirection === 'Up');
    const [settings, contracts] = yield all([
      call(http, { method: 'GET', url: 'settings' }),
      call(http, {
        method: 'GET',
        url: `contracts?SearchString=${searchString}&skip=${skip}&take=${take}&SortField=${sortField}&orderByDesc=${orderByDesc}`,
        params: filters,
      }),
      !dontUploadFilters && getAllFilters(),
    ]);

    yield put(setSettings(settings.data));
    yield put(setExpiredContracts(contracts.data.Contracts));
    yield put(setTotalCountContracts(contracts.data.TotalCount));
  } finally {
    yield put(finishRequest());
  }
}

export function* getAllFilters() {
  try {
    yield put(startRequest());

    const [
      contractAlerts,
      contractApprovalStatuses,
      contractTypes,
    ] = yield all([
      call(http, { method: 'GET', url: 'contract-alerts' }),
      call(http, { method: 'GET', url: 'projects/approval-statuses' }),
      call(http, {method: 'GET', url: 'contracts/contract-types'}),
      put(getDocumentStatuses()),
    ]);

    yield put(setContractsAlerts(contractAlerts.data));
    yield put(setContractsApprovalStatus(contractApprovalStatuses.data));
    yield put(setContractsTypes(contractTypes.data))
  } finally {
    yield put(finishRequest());
  }
}

export function* handleEditViewContract(action) {
  try {
    yield put(startRequest());
    yield getProjectDetails(action);
    yield getCreationConfig();
    const { payload } = action;

    history.push({
      pathname: `/projects/${payload.id}/${payload.rights}-${payload.document}/${payload.idDocument}`,
    }, {
      isWrongPath: 'Contracts',
      searchValue: payload.searchValue,
      saveCallback: '/contracts',
    });

    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* importFile(action) {
  try {
    yield put(startRequest());
    const file = yield call(http, {
      method: 'POST',
      url: 'billing/milestones/import',
      multipart: true,
      resType: 'arraybuffer',
      data: action.payload,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Import-Success-Count': '',
        'Access-Control-Allow-Headers': '*, X-Import-Success-Count, X-Import-Error-Count',
        'Access-Control-Expose-Headers': '*, X-Import-Success-Count, X-Import-Error-Count',
        'X-Import-Error-Count': '',
      },
    });
    const statusForDownloadedFile = {
      successCount: file.headers['x-import-success-count'],
      errorCount: file.headers['x-import-error-count'],
    };
    const fileBase = Buffer.from(file.data, 'binary').toString('base64');
    yield put(setImportFile(fileBase));
    yield put(setStatusForDownloadedFile(statusForDownloadedFile));
    yield put(toggleFlagShow(true));
    yield put(finishRequest());
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(showAlertDialog(new TextDecoder().decode(e.response.data)));
    }
    yield put(finishRequest());
  }
}

export function* downloadFile(action) {
  try {
    const downloadedFile = new Blob([Buffer.from(action.payload, 'base64')],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const nameFile = `billing_summary_${moment().format('MMMM')}_import_status.xlsx`;
    saveAs(downloadedFile, nameFile);
    yield put(toggleFlagShow(false));
  } catch {
    yield put(toggleFlagShow(false));
  }
}

export default function* contractsExpiredSaga() {
  yield takeLatest(GET_EXPIRED_CONTRACTS, getExpiredContracts);
  yield takeEvery(EDIT_VIEW_CONTRACTS, handleEditViewContract);
  yield takeEvery(IMPORT_FILE, importFile);
  yield takeEvery(DOWNLOAD_FILE, downloadFile);
}
