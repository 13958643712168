export const GET_MANAGE_BACK_FILL_TYPES = '@admin/GET_MANAGE_BACK_FILL_TYPES';
export const SET_MANAGE_BACK_FILL_TYPES = '@admin/SET_MANAGE_BACK_FILL_TYPES';
export const PUT_MANAGE_BACK_FILL_TYPES = '@admin/PUT_MANAGE_BACK_FILL_TYPES';
export const POST_MANAGE_BACK_FILL_TYPES = '@admin/POST_MANAGE_BACK_FILL_TYPES';

export const getBackFillTypes = () => ({
  type: GET_MANAGE_BACK_FILL_TYPES,
});

export const setBackFillTypes = (types) => ({
  type: SET_MANAGE_BACK_FILL_TYPES,
  payload: types,
});

export const putBackFillTypes = (types) => ({
  type: PUT_MANAGE_BACK_FILL_TYPES,
  payload: types,
});

export const postBackFillTypes = (newType) => ({
  type: POST_MANAGE_BACK_FILL_TYPES,
  payload: newType,
});
