import React, { Component } from 'react';
import Modal from '../../../../common/components/modal/modal';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CustomIcon from '../../../../common/components/icon/Icon';
import './sync-modal.css';
import classNames from 'classnames';
import { getOffices } from '../../../offices/actions/officesActions';
import { closeSyncModal, synchronizeEmployees } from '../../actions/membersActions';
import { sortFilterList } from '../../../../common/components/list/sortList';
import isObjectNotEquals from '../../../../utils/object-comparison';

export class SyncModal extends Component {
  constructor(props) {
    super(props);
    const { offices } = this.props;
    this.state = {
      offices: sortFilterList(offices),
      selectedOffices: [],
    };
  }

  componentDidMount() {
    const { getOffices } = this.props;
    getOffices();
  }

  componentDidUpdate(prevProps) {
    const { offices } = this.props;
    if (isObjectNotEquals(prevProps.offices, offices)) {
      this.setState({
        offices: sortFilterList(offices),
      });
    }
  }

  onChangeDepartment = (e) => {
    const department = e.target.id;
    const { selectedOffices } = this.state;
    const selectedItem = selectedOffices;
    if (selectedItem.includes(department)) {
      selectedItem.splice(selectedItem.indexOf(department), 1);
    } else {
      selectedItem.push(department);
    }
    this.setState({
      selectedOffices: selectedItem,
    });
  };

  onApply = () => {
    const { selectedOffices } = this.state;
    const { synchronizeEmployees, closeModal, options } = this.props;
    if (selectedOffices.length !== 0) {
      synchronizeEmployees(selectedOffices, options);
      closeModal();
    }
  };

  render() {
    const { closeModal } = this.props;
    const { offices, selectedOffices } = this.state;
    const syncableOffices = offices.filter(office => office.HasLdapPaths);
    return (
      <Modal>
        <div className='sync-modal-container employee-sync-modal admin-modal'>
          <div className='c-role-modal__block padding-bottom'>
            <div className='c-role-modal__block__row u-flex-row u-space-between'>
              <div className='sync-modal'>
                <CustomIcon iconName='sync' />
                <span className='c-role-modal__title'>AD synchronization</span>
              </div>
              <span className='c-role-modal__icon-cross' onClick={closeModal}>
                <CustomIcon iconName='cross' />
              </span>
            </div>
          </div>
          <div className='c-role-modal__block__row u-space-between employee-sync-modal__title--margin'>
            <span className='choose-role'>Choose office</span>
          </div>
          <div className='c-role-modal__block__row u-space-between admin-checkbox employee-sync-modal__title--margin'>
            <div className='admin-modal-checkbox'>
              {
                syncableOffices.map((item, i) => <label key={i} className='checkbox-cont-list-item configuration-option'>
                  <input
                    id={item.DomainName}
                    type='checkbox'
                    defaultChecked={item.isSelected}
                    onClick={this.onChangeDepartment}
                  />
                  <span className='configuration-option__checkbox' />
                  <label htmlFor={item.DomainName} className='configuration-option__label'>
                    <span className='role-style'>{item.Name}</span>
                  </label>
                </label>)
              }
            </div>
          </div>
          <div className='split-modal-bottom' />
          <div className='buttons'>
            <div className='right-side-buttons'>
              <div className='button cancel' onClick={closeModal}>
                <span>Cancel</span>
              </div>
              <div
                className={classNames('button next',
                  {
                    'btn-disabled': !selectedOffices.length,
                  })}
                onClick={this.onApply}
              >
                <span>Apply</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SyncModal.propTypes = {
  closeModal: PropTypes.func,
  offices: PropTypes.array,
  getOffices: PropTypes.func,
  synchronizeEmployees: PropTypes.func,
};

export default connect((store) => ({
  offices: store.officesReducer.offices,
  options: store.membersReducer.syncOptions,
}), {
  closeModal: closeSyncModal,
  getOffices,
  synchronizeEmployees,
})(SyncModal);
