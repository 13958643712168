import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_JOB_TITLES,
  setManagesJobTitles,
  PUT_MANAGE_JOB_TITLES,
  POST_MANAGE_JOB_TITLES,
  MERGE_MANAGE_JOB_TITLES,
} from '../actions/adminManageJobTitles';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageJobTitles() {
  try {
    yield put(startRequest());
    const jobTitles = yield call(http, {
      method: 'GET',
      url: 'jobTitles',
    });

    yield put(setManagesJobTitles(jobTitles.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putJobTitles(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `jobTitles/${action.payload.Id}`,
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageJobTitles);
  } catch (e) {
    // error message
  }
}

export function* postJobTitle(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'jobTitles',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageJobTitles);
  } catch (e) {
    // error message
  }
}

export function* mergeJobTitle(action) {
  try {
    yield call(http, {
      method: 'PATCH',
      url: 'jobTitles',
      data: action.payload,
    });
    yield call(manageJobTitles);
  } catch (e) {
    // error message
  }
}

export default function* adminManageJobTitles() {
  yield takeEvery(GET_MANAGE_JOB_TITLES, manageJobTitles);
  yield takeEvery(PUT_MANAGE_JOB_TITLES, putJobTitles);
  yield takeEvery(POST_MANAGE_JOB_TITLES, postJobTitle);
  yield takeEvery(MERGE_MANAGE_JOB_TITLES, mergeJobTitle);
}
