import moment from 'moment';
import {
  CHANGE_COLUMNS_TO_DISPLAY,
  CHANGE_INNER_COLUMNS_TO_DISPLAY,
  SET_FINANCE_LIST_SETTINGS,
  RESET_FINANCE_LIST_SETTINGS,
  SET_FINANCE_LIST_DATES,
  RESET_FINANCE_LIST_DATES,
} from '../actions/financeActions';

const defaultDateValues = {
  StartDate: moment().startOf('month').format('MM/DD/YYYY'),
  EndDate: moment().endOf('year').format('MM/DD/YYYY'),
};

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Company',
  sortDirection: 'Up',
  filters: {
    ...defaultDateValues,
    DocumentStatuses: [2, 3],
  },
};

const initialState = {
  columnsToDisplay: [
    {
      Name: 'Company', Id: 'Company', className: 'small-col big-report-col', isObject: true,
    },
    {
      Name: 'Project', Id: 'Project', className: 'small-col big-report-col', isObject: true,
    },
    {
      Name: 'Client Manager', Id: 'ClientManager', className: 'small-col big-report-col', isObject: true,
    },
    {
      Name: 'Business Development Manager', Id: 'AccountManager', className: 'small-col big-report-col', isObject: true,
    },
    {
      Name: 'Committed Quota Goal', Id: 'Budget', className: 'small-col big-report-col', isBudget: true,
    },
    {
      Name: 'Accrued Revenue to Date', Id: 'RealizedRevenueToDate', className: 'small-col big-report-col', isBudget: true,
    },
    {
      Name: 'Accrued Revenue', Id: 'RealizedRevenuePercent', className: 'xs-col medium-report-col', isPercent: true,
    },
    {
      Name: 'Revenue Received', Id: 'RevenueReceived', className: 'xs-col medium-report-col', isBudget: true,
    },
    {
      Name: 'Delta to Goal', Id: 'RemainingBalance', className: 'xs-col medium-report-col', isBudget: true,
    },
    {
      Name: 'Projected Revenue Total', Id: 'ProjectedRevenueTotal', className: 'xs-col medium-report-col', isPercent: true,
    },
    {
      Name: 'Projected Revenue', Id: 'PredictedRevenuePercent', className: 'xs-col medium-report-col', isPercent: true,
    },
    {
      Name: 'Projected Budget Utilization', Id: 'PredictedBudgetUtilizationPercent', className: 'xs-col medium-report-col', isPercent: true,
    },
    {
      Name: 'Leftover', Id: 'Leftover', className: 'xs-col medium-report-col', isBudget: true,
    },
  ],
  innerColumnsToDisplaySOWs: [
    {
      Name: 'Contract Type', Id: 'SowType', className: 'medium-col', isObject: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Name', Id: 'Sow', className: 'medium-col', isObject: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Committed Quota Goal', Id: 'Budget', className: 'medium-col', deleteArrow: 'delete-arrow', isBudget: true,
    },
    {
      Name: 'Accrued Revenue to Date', Id: 'RealizedRevenueToDate', className: 'medium-col', deleteArrow: 'delete-arrow', isDate: true,
    },
    {
      Name: 'Accrued Revenue', Id: 'RealizedRevenuePercent', className: 'medium-col', deleteArrow: 'delete-arrow', isPercent: true,
    },
    {
      Name: 'Revenue Received', Id: 'RevenueReceived', className: 'medium-col', deleteArrow: 'delete-arrow', isBudget: true,
    },
    {
      Name: 'Projected Revenue Total', Id: 'ProjectedRevenueTotal', className: 'medium-col', deleteArrow: 'delete-arrow', isBudget: true,
    },
    {
      Name: 'Projected Revenue', Id: 'PredictedRevenuePercent', className: 'medium-col', deleteArrow: 'delete-arrow', isPercent: true,
    },
    {
      Name: 'Projected Budget Utilization',
      Id: 'PredictedBudgetUtilizationPercent',
      className: 'medium-col',
      deleteArrow: 'delete-arrow',
      isPercent: true,
    },
    {
      Name: 'Leftover', Id: 'Leftover', className: 'medium-col', deleteArrow: 'delete-arrow', isBudget: true,
    },
  ],
  listSettings: defaultListSettings,
  filtersDates: {
    ...defaultDateValues,
  },
};

// eslint-disable-next-line default-param-last
const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    case CHANGE_INNER_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        innerColumnsToDisplaySOWs: action.payload.innerColumnsToDisplaySOWs,
      };
    case SET_FINANCE_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_FINANCE_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_FINANCE_LIST_DATES:
      return {
        ...state,
        filtersDates: {
          ...state.filtersDates,
          ...action.payload,
        },
      };
    case RESET_FINANCE_LIST_DATES:
      return {
        ...state,
        filtersDates: defaultDateValues,
      };
    default:
      return state;
  }
};

export default financeReducer;
