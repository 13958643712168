import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  editVacation,
  showAddVacationModal,
  setVacationToChange,
} from 'pages/members/actions/membersActions';
import { showDialog } from 'common/actions/confirmationDialogActions';
import CustomIcon from 'common/components/icon/Icon';

import { getId } from 'services/location';

import '../vacations.css';

export class VacationTag extends React.Component {
  changeVacationRange = () => {
    const { setVacationToChange, showAddVacationModal, vacation } = this.props;

    setVacationToChange(vacation);
    showAddVacationModal();
  }

  render() {
    const {
      vacation,
      key,
      isEditable,
      selectedYear,
      showDialog,
    } = this.props;
    return (
      <div className={classNames('period-container', { 'read-only-vacation-tag': !isEditable })} key={key}>
        <span>{vacation.startDate} - {vacation.endDate} - <b>{`${vacation.Duration} ${vacation.Duration > 1 ? 'days' : 'day'}`}</b></span>
        {
          isEditable && (
            <div className='vacations-tag-buttons'>
              <CustomIcon
                iconName='edit-pencil-small'
                onClick={isEditable && this.changeVacationRange}
                className='pointer'
              />
              <CustomIcon
                iconName='cross-small'
                onClick={() => isEditable && showDialog({
                  item: vacation,
                  url: `employees/${getId()}/vacations/${vacation.Id}`,
                  entityId: getId(),
                  searchUpdateSaga: 'employees/vacations',
                  year: selectedYear,
                })}
                className='pointer'
              />
            </div>
          )
        }
      </div>
    );
  }
}

VacationTag.propTypes = {
  vacation: PropTypes.object,
  key: PropTypes.string,
  showAddVacationModal: PropTypes.func,
  setVacationToChange: PropTypes.func,
};

export default connect(store => ({
  selectedYear: store.membersReducer.selectedYear,
}), {
  editVacation,
  showAddVacationModal,
  setVacationToChange,
  showDialog,
})(VacationTag);
