export const GET_MANAGE_SOW_TYPES = '@admin/GET_MANAGE_SOW_TYPES';
export const SET_MANAGE_SOW_TYPES = '@admin/SET_MANAGE_SOW_TYPES';
export const PUT_MANAGE_SOW_TYPES = '@admin/PUT_MANAGE_SOW_TYPES';
export const POST_MANAGE_SOW_TYPES = '@admin/POST_MANAGE_SOW_TYPES';

export const loadManageSowTypes = () => ({
  type: GET_MANAGE_SOW_TYPES,
});

export const setManageSowTypes = (sowTypes) => ({
  type: SET_MANAGE_SOW_TYPES,
  payload: sowTypes,
});

export const putManageSowTypes = (sowType) => ({
  type: PUT_MANAGE_SOW_TYPES,
  payload: sowType,
});

export const postManageSowTypes = (newType) => ({
  type: POST_MANAGE_SOW_TYPES,
  payload: newType,
});
