import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import moment from 'moment';
import {
  loadSowReports,
  transitionToContract,
  transitionToRole,
  setSowReports,
} from '../../actions/reportsActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import Scrollbars from 'react-custom-scrollbars';
import isObjectNotEquals from 'utils/object-comparison';
import isEmptyObject from 'utils/isEmptyObject';
import { CellLink } from 'common/components/list/custom-cell/cell-link';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { CellRate } from 'common/components/list/custom-cell/cell-rate';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { sortFilterList } from 'common/components/list/sortList';
import { getClients } from '../../../clients/actions/clientsActions';
import { getCreationConfig, getManagersByOffice } from '../../../projects/actions/projectsActions';
import { getFilters } from 'common/actions/filtersActions.js';
import { getManageRoleTypes } from 'pages/admin/actions/adminManageRoleTypes.js';
import { emptyArray } from 'utils/const-variable';
import { getFiltersForWorkForce } from '../../actions/workforceActions';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import {
  setSowListSettings,
  resetSowListSettings,
  setSowListFiltersValue,
  resetSowListFiltersValue,
} from '../../actions/sowActions';

const filtersNames = [
  'Offices',
  'JobTitles',
  'ProjectStatuses',
  'EmployeeAssignmentStatuses',
];

export class SoWExtensionForecastList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EndDate: '',
      NestedItems: ['POs', 'Amendments'],
      columnsToDisplay: [
        {
          Name: 'Company', Id: 'Company', className: 'big-col',
        },
        {
          Name: 'Project Name', Id: 'Project', className: 'medium-col',
        },
        {
          Name: 'Contract', Id: 'SowId', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Client Manager', Id: 'ClientManager', className: 'medium-col', hasBadge: true, isObject: true,
        },
        {
          Name: 'Business Development Manager', Id: 'AccountManager', className: 'medium-col', hasBadge: true, isObject: true,
        },
        {
          Name: 'Billing Code', Id: 'BillingCode',
        },
        {
          Name: 'Days to Expiry', Id: 'DaysToExpire',
        },
        {
          Name: 'Comment', Id: 'StatusComments', className: 'medium-col', isInnerComment: true,
        },
      ],
      innerColumnsToDisplay: [
        {
          Name: 'Role', Id: 'RoleType', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Role Name', Id: 'RoleName', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Employee Name', Id: 'Employee', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Email', Id: 'Email', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Job Title', Id: 'JobTitle', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Rate', Id: 'Rate', deleteArrow: 'delete-arrow', isBudget: true,
        },
        {
          Name: 'Office', Id: 'Office', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'End Date', Id: 'EndDate', isDate: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Availability', Id: 'Availability', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Ass. comment', Id: 'AssignmentComment', className: 'without-width-col', deleteArrow: 'delete-arrow', isInnerComment: true,
        },
        {
          Name: 'Status', Id: 'Status', className: 'small-col', isObject: true, deleteArrow: 'delete-arrow',
        },
      ],
      columnForDisplayPOs: [
        {
          Name: 'PO Name', Id: 'Number', className: 'small-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Company', Id: 'Company', className: 'medium-col', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Type', Id: 'PoType', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Client Manager', Id: 'ClientManager', className: 'small-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Days to Expiry', Id: 'DaysToExpire', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Comment', Id: 'StatusComments', className: 'medium-col', isInnerComment: true, deleteArrow: 'delete-arrow',
        },
      ],
      columnForDisplayAmendment: [
        {
          Name: 'Amendment Name', Id: 'Number', className: 'small-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Company', Id: 'Company', className: 'medium-col', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Client Manager', Id: 'ClientManager', className: 'small-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Days to Expiry', Id: 'DaysToExpire', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Comment', Id: 'StatusComments', className: 'medium-col', isInnerComment: true, deleteArrow: 'delete-arrow',
        },
      ],
    };
  }

  componentDidMount() {
    const {
      loadSowReports, getClients, getCreationConfig, getManagersByOffice,
      getFilters, getManageRoleTypes, getFiltersForWorkForce, setComponent,
      listSettings,
    } = this.props;
    const customColumnName = listSettings.sortColumn === 'SowId' ? 'Contract' : listSettings.sortColumn;
    loadSowReports(listSettings.searchValue, customColumnName, listSettings.sortDirection, listSettings.filters);
    getClients();
    getCreationConfig();
    getManagersByOffice();
    getFilters(filtersNames);
    getManageRoleTypes();
    getFiltersForWorkForce();
    setComponent({ title: `Sow Extension Forecast - ${moment().format('MM/DD/YYYY')}` });
  }

  componentDidUpdate(prevProps) {
    const { loadSowReports, listSettings } = this.props;
    const {
      searchValue, sortColumn, sortDirection, filters,
    } = listSettings;
    const customColumnName = sortColumn === 'SowId' ? 'Contract' : sortColumn;
    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      loadSowReports(searchValue, customColumnName, sortDirection, filters);
    }
  }

  componentWillUnmount() {
    const {
      setSowReports,
      resetComponent,
      resetSowListSettings,
      resetSowListFiltersValue,
    } = this.props;
    setSowReports(emptyArray);
    resetComponent();
    resetSowListSettings();
    resetSowListFiltersValue();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setSowListSettings } = this.props;
    setSowListSettings({ sortColumn, sortDirection });
  };

  transitionToEdit = (id, column) => {
    const {
      reports: { sow }, ManageProjectRoles, ManageProjectContracts,
      defaultCheckedShowRoles, transitionToContract,
      transitionToRole,
    } = this.props;
    const currentItem = sow.find(item => item.Id === id);
    const goFarAway = currentItem && currentItem[column];
    const contract = {
      id: currentItem && currentItem.Project.Id,
      rights: !ManageProjectContracts ? 'view' : 'edit',
      document: currentItem && currentItem.ParentId ? 'po' : 'sow',
      parentId: currentItem && currentItem.ParentId,
      contractId: currentItem && currentItem.SowId.Id,
      defaultCheckedShowRoles: !defaultCheckedShowRoles,
    };
    const parent = sow.find(item => item.Employees.find(employe => employe.RoleId === id.RoleId));
    const role = {
      id: parent && parent.Project.Id,
      roleId: id.RoleId,
      rights: !ManageProjectRoles ? 'view' : 'edit',
    };
    switch (column) {
      case 'Company':
        return goFarAway ? `/clients/${goFarAway.Id}` : '/';
      case 'Project':
        return goFarAway ? `/projects/${goFarAway.Id}` : '/';
      case 'SowId':
        transitionToContract(contract);
        break;
      case 'Employee':
        return {
          pathname: `employees/${id.Employee.Id}`,
          state: {
            filters: {},
            search: '',
          },
        };
      case 'RoleType':
        transitionToRole(role);
        break;
      default:
        break;
    }
  }

  getXLSXDocument = () => {
    const { NestedItems } = this.state;
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    downloadXLSXDocument({ ...filters, NestedItems }, searchValue, 'sow-extension-forecast-reports/export-xlsx?Search=', 'Contracts Reports.xlsx');
  }

  applyFilters = (filters) => {
    const { setSowListSettings, textDataForFilters } = this.props;
    const newFilters = {
      ...filters,
      ...textDataForFilters,
    };
    const isExistsFilters = Object.keys(newFilters).some((filter) => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setSowListSettings({ filters: isExistsFilters ? newFilters : {} });
  }

  resetFilters = () => {
    const { resetSowListFiltersValue, setSowListSettings } = this.props;
    resetSowListFiltersValue();
    setSowListSettings({ filters: {} });
  }

  handleChangeDate = (date) => {
    const { setSowListFiltersValue } = this.props;
    setSowListFiltersValue({ EndDate: date ? moment(date).format('MM/DD/YYYY') : null });
  }

  handleChangeTextField = ({ target }) => {
    const { setSowListFiltersValue } = this.props;
    const { name, value } = target;
    setSowListFiltersValue({ [name]: value });
  }

  clearValue = (target) => {
    const { setSowListFiltersValue } = this.props;
    setSowListFiltersValue({ [target]: '' });
  }

  renderPOsList = (items) => {
    const { columnForDisplayPOs, innerColumnsToDisplay } = this.state;
    const { HasFinancialAccess } = this.props;
    return (
      <div className='highorder_component-wrapper' key={JSON.stringify(items)}>
        <List
          columnsToDisplay={columnForDisplayPOs}
          items={items}
          hideDots
          viewFinancialInfo={HasFinancialAccess}
          dontShowOptions
          transition={this.transitionToEdit}
          dropdownList={{
            columnsToDisplay: innerColumnsToDisplay,
            items: items,
            transition: this.transitionToEdit,
            property: 'Employees',
            getDeeper: true,
            hideDots: true,
            withoutSort: true,
            dontShowOptions: true,
            dropdownList: {
              columnsToDisplay: innerColumnsToDisplay,
              items: items,
              property: 'Employees',
              deepestProperty: 'ShadowEmployees',
              getDeeper: true,
              hideDots: true,
              withoutSort: true,
              dontShowOptions: true,
            },
          }}
          config={{
            'RoleType': { cell: CellLink },
            'Employee': { cell: CellLinkForRedirect },
            'Rate': { cell: CellRate },
            'Status': { cell: CustomStatusCell },
            'Company': { cell: CellLinkForRedirect },
          }}
        />
      </div>
    );
  }

  renderAmendmentList = (items) => {
    const { columnForDisplayAmendment, innerColumnsToDisplay } = this.state;
    const { HasFinancialAccess } = this.props;
    return (
      <div className='highorder_component-wrapper' key={JSON.stringify(items)}>
        <List
          columnsToDisplay={columnForDisplayAmendment}
          items={items}
          hideDots
          viewFinancialInfo={HasFinancialAccess}
          dontShowOptions
          transition={this.transitionToEdit}
          dropdownList={{
            columnsToDisplay: innerColumnsToDisplay,
            items: items,
            transition: this.transitionToEdit,
            property: 'Employees',
            getDeeper: true,
            hideDots: true,
            withoutSort: true,
            dontShowOptions: true,
            dropdownList: {
              columnsToDisplay: innerColumnsToDisplay,
              items: items,
              property: 'Employees',
              deepestProperty: 'ShadowEmployees',
              getDeeper: true,
              hideDots: true,
              withoutSort: true,
              dontShowOptions: true,
            },
          }}
          config={{
            'RoleType': { cell: CellLink },
            'Employee': { cell: CellLinkForRedirect },
            'Rate': { cell: CellRate },
            'Status': { cell: CustomStatusCell },
            'Company': { cell: CellLinkForRedirect },
          }}
        />
      </div>
    );
  }

  handleChangeNestedItems = (event) => {
    const value = event.target.name;
    const { NestedItems } = this.state;
    let filteredItems = [];
    if (NestedItems.includes(value)) {
      filteredItems = NestedItems.filter(item => item !== value);
    } else {
      filteredItems = [...NestedItems, value];
    }
    this.setState({
      NestedItems: filteredItems,
    });
  }

  searchSowReports = (searchValue) => {
    const { setSowListSettings } = this.props;
    setSowListSettings({ searchValue });
  }

  render() {
    const {
      reports, HasFinancialAccess, companies, clientManagers,
      accountManagers, jobTitles, roles, offices, projectsName,
      employeeAssignmentStatuses, textDataForFilters, statuses,
      listSettings: {
        searchValue, sortColumn, sortDirection, filters,
      },
    } = this.props;
    const { columnsToDisplay, innerColumnsToDisplay } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          searchPlaceholder='Search Reports'
          searchValue={searchValue}
          showExportButton
          exportDocument={this.getXLSXDocument}
          filters={filters}
          handleChangeNestedItems={this.handleChangeNestedItems}
          SOWExtensionOptions
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchSowReports}
          items={[
            {
              name: 'Company name',
              id: 'Companies',
              options: sortFilterList(companies) || [],
              multiSelect: false,
              suggestBox: true,
              placeholder: 'All Company',
              filtersItem: filters && filters.Companies,
              className: 'z-index_50',
            },
            {
              name: 'Project name',
              id: 'Projects',
              options: sortFilterList(projectsName) || [],
              multiSelect: false,
              suggestBox: true,
              placeholder: 'All Project',
              filtersItem: filters && filters.Projects,
              className: 'z-index_48',
            },
            {
              name: 'Project statuses',
              id: 'ProjectStatuses',
              options: sortFilterList(statuses) || [],
              multiSelect: true,
              placeholder: 'All Statuses',
              filtersItem: filters && filters.statuses,
              className: 'z-index_46',
            },
            {
              name: 'Assigned status',
              id: 'EmployeeAssignmentStatuses',
              options: sortFilterList(employeeAssignmentStatuses) || [],
              multiSelect: true,
              placeholder: 'All Statuses',
              filtersItem: filters && filters.EmployeeAssignmentStatuses,
              className: 'z-index_44',
            },
            {
              name: 'Client Manager',
              id: 'ClientManagers',
              options: sortFilterList(clientManagers) || [],
              multiSelect: false,
              suggestBox: true,
              placeholder: 'All Managers',
              filtersItem: filters && filters.ClientManagers,
              className: 'z-index_42',
            },
            {
              name: 'Account Manager',
              id: 'AccountManagers',
              options: sortFilterList(accountManagers) || [],
              multiSelect: false,
              suggestBox: true,
              placeholder: 'All Business Development Managers',
              filtersItem: filters && filters.AccountManagers,
              className: 'z-index_40',
            },
            {
              name: 'Job Title',
              id: 'JobTitles',
              options: sortFilterList(jobTitles) || [],
              multiSelect: false,
              suggestBox: true,
              placeholder: 'All Job titles',
              filtersItem: filters && filters.JobTitles,
              className: 'z-index_38',
            },
            {
              name: 'Role',
              id: 'RoleTypes',
              options: sortFilterList(roles) || [],
              multiSelect: false,
              suggestBox: true,
              placeholder: 'All Roles',
              filtersItem: filters && filters.RoleTypes,
              className: 'z-index_36',
            },
            {
              name: 'Offices',
              id: 'Offices',
              options: sortFilterList(offices) || [],
              multiSelect: true,
              placeholder: 'All Offices',
              filtersItem: filters && filters.Offices,
              className: 'z-index_34',
            },
            {
              name: 'End Date',
              id: 'EndDate',
              datepicker: true,
              placeholder: 'Date',
              formClassName: 'step__form svg-calendar-add-info date_picker-toolbar',
              datePickerClassName: 'step__date-picker data-pisker__filter--margin-sow',
              value: textDataForFilters.EndDate,
              onChange: this.handleChangeDate,
              className: 'z-index_32',
            },
            {
              name: 'Contract',
              id: 'Contract',
              textField: true,
              placeholder: 'Contract',
              value: textDataForFilters.Contract,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_30',
            },
            {
              name: 'Days to Expiry',
              id: 'DaysToExpire',
              textField: true,
              placeholder: 'Days to Expiry',
              value: textDataForFilters.DaysToExpire,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_28',
            },
            {
              name: 'Comment',
              id: 'Comment',
              textField: true,
              placeholder: 'Comment',
              value: textDataForFilters.Comment,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_26',
            },
            {
              name: 'Role Name',
              id: 'RoleName',
              textField: true,
              placeholder: 'Role Name',
              value: textDataForFilters.RoleName,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_24',
            },
            {
              name: 'Employee Name',
              id: 'Employee',
              textField: true,
              placeholder: 'Employee Name',
              value: textDataForFilters.Employee,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_22',
            },
            {
              name: 'Email',
              id: 'Email',
              textField: true,
              placeholder: 'Email',
              value: textDataForFilters.Email,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_20',
            },
            {
              name: 'Rate',
              id: 'Rate',
              textField: true,
              placeholder: 'Rate',
              value: textDataForFilters.Rate,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_18',
            },
            {
              name: 'Availability',
              id: 'Availability',
              textField: true,
              placeholder: 'Availability',
              value: textDataForFilters.Availability,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_16',
            },
            {
              name: 'Ass. comment',
              id: 'AssignmentComment',
              textField: true,
              placeholder: 'Ass. comment',
              value: textDataForFilters.AssignmentComment,
              onChange: this.handleChangeTextField,
              clearValue: this.clearValue,
              className: 'z-index_14',
            },
          ]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <List
              columnsToDisplay={columnsToDisplay}
              items={reports.sow}
              setSortSettings={this.setSortSettings}
              sortColumnName={sortColumn}
              sortDirection={sortDirection}
              transition={this.transitionToEdit}
              dropdownList={{
                columnsToDisplay: innerColumnsToDisplay,
                items: reports.sow,
                property: 'Employees',
                getDeeper: true,
                hideDots: true,
                withoutSort: true,
                transition: this.transitionToEdit,
                dontShowOptions: true,
                dropdownList: {
                  columnsToDisplay: innerColumnsToDisplay,
                  items: reports.sow,
                  property: 'Employees',
                  deepestProperty: 'ShadowEmployees',
                  getDeeper: true,
                  hideDots: true,
                  withoutSort: true,
                  dontShowOptions: true,
                },
              }}
              viewFinancialInfo={HasFinancialAccess}
              fixedHeader
              SOWExtensionOptions
              highOrderConfig={{
                'Pos': this.renderPOsList,
                'Amendments': this.renderAmendmentList,
              }}
              config={{
                'Company': { cell: CellLinkForRedirect },
                'Project': { cell: CellLinkForRedirect },
                'SowId': { cell: CellLink },
                'RoleType': { cell: CellLink },
                'Employee': { cell: CellLinkForRedirect },
                'Rate': { cell: CellRate },
                'Status': { cell: CustomStatusCell },
              }}
            />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

SoWExtensionForecastList.propTypes = {
  reports: PropTypes.object.isRequired,
  loadSowReports: PropTypes.func.isRequired,
  transitionToContract: PropTypes.func.isRequired,
  transitionToRole: PropTypes.func.isRequired,
  ManageProjectRoles: PropTypes.bool.isRequired,
  ManageProjectContracts: PropTypes.bool.isRequired,
  HasFinancialAccess: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  clientManagers: PropTypes.array.isRequired,
  accountManagers: PropTypes.array.isRequired,
  jobTitles: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  offices: PropTypes.array.isRequired,
  projectsName: PropTypes.array,
  getClients: PropTypes.func.isRequired,
  getCreationConfig: PropTypes.func.isRequired,
  getManagersByOffice: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  getManageRoleTypes: PropTypes.func.isRequired,
  getFiltersForWorkForce: PropTypes.func.isRequired,
};

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  defaultCheckedShowRoles: store.showRoles.isOnlyActiveRoles,
  companies: store.clientsReducer.clients,
  clientManagers: store.projectsReducer.clientContacts,
  accountManagers: store.projectsReducer.accountManagersByOffice,
  jobTitles: store.filtersReducer.filters.JobTitles,
  roles: store.adminManageLookups.roleTypes,
  offices: store.filtersReducer.filters.Offices,
  projectsName: store.reportsReducer.filters.projectsName,
  statuses: store.filtersReducer.filters.ProjectStatuses,
  employeeAssignmentStatuses: store.filtersReducer.filters.EmployeeAssignmentStatuses,
  listSettings: store.sowReducer.listSettings,
  textDataForFilters: store.sowReducer.textDataForFilters,
}), {
  loadSowReports,
  transitionToContract,
  transitionToRole,
  downloadXLSXDocument,
  getClients,
  getCreationConfig,
  getManagersByOffice,
  getFilters,
  getManageRoleTypes,
  getFiltersForWorkForce,
  setComponent,
  resetComponent,
  setSowReports,
  setSowListSettings,
  resetSowListSettings,
  setSowListFiltersValue,
  resetSowListFiltersValue,
})(SoWExtensionForecastList);
