import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../../icon/Icon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export class CellFiles extends Component {
  render() {
    const { column, item } = this.props;
    const files = item[column.Id];
    return (
      <div
        className={classNames('c-list__item-column file-cell-block', column.className ? column.className : 'small-col')}
      >
        <span className='col-span file-cell-block'>
          {
            !!files.length
              ? files.map(item => <CustomIcon iconName='files' title={item.name} />)
              : <HighlightOffIcon style={{ width: '25px', color: 'red' }} />
          }
        </span>
        {
          (!!files.length && files.length === 1) && <span className='cell-file-title'>{files.map(item => item.name)}</span>
        }
      </div>
    );
  }
}

CellFiles.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
