export const LOAD_MANAGE_COUNTRIES = '@admin/LOAD_MANAGE_COUNTRIES';
export const SET_MANAGE_COUNTRIES = '@admin/SET_MANAGE_COUNTRIES';
export const PUT_MANAGE_COUNTRIES = '@admin/PUT_MANAGE_COUNTRIES';
export const POST_MANAGE_COUNTRIES = '@admin/POST_MANAGE_COUNTRIES';

export const loadManageCountries = () => ({
  type: LOAD_MANAGE_COUNTRIES,
});

export const setManagesCountries = (Countries) => ({
  type: SET_MANAGE_COUNTRIES,
  payload: Countries,
});

export const putManageCountries = (Name, Id) => {
  return {
    type: PUT_MANAGE_COUNTRIES,
    payload: {
      Name,
      Id,
    },
  };
};

export const postManageCountries = (newCountrie) => ({
  type: POST_MANAGE_COUNTRIES,
  payload: newCountrie,
});
