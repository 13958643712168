import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  GET_MANAGE_SENIORITY_LEVELS,
  setManagesSeniorityLevels,
  CREATE_NEW_SENIORITY_LEVEL,
  UPDATE_SENIORITY_LEVEL,
  UPDATE_ORDER_SENIORITY_LEVELS,
} from '../actions/adminManageSeniorityLevels';


import { finishRequest, startRequest } from 'common/actions/loadingActions';

export function* getManageSeniorityLevels() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'seniority_levels',
    });

    yield put(setManagesSeniorityLevels(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addSeniorityLevels(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'seniority_levels',
      data: {
        Name: data.Name.trim(),
        Priority: action.payload.Priority,
      },
    });
    yield call(getManageSeniorityLevels);
  } catch (e) {
    // error message
  }
}

export function* updateOrderSeniorityLevels(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'PATCH',
      url: 'seniority_levels',
      data,
    });
    yield call(getManageSeniorityLevels);
  } catch (e) {
    // error message
  }
}

export function* putSeniorityLevels(action) {
  yield call(http, {
    method: 'PUT',
    url: `seniority_levels/${action.payload.Id}`,
    data: {
      Name: action.payload.Name,
    },
  });
  yield call(getManageSeniorityLevels);
}

export default function* adminManageSeniorityLevels() {
  yield takeEvery(GET_MANAGE_SENIORITY_LEVELS, getManageSeniorityLevels);
  yield takeEvery(CREATE_NEW_SENIORITY_LEVEL, addSeniorityLevels);
  yield takeEvery(UPDATE_SENIORITY_LEVEL, putSeniorityLevels);
  yield takeEvery(UPDATE_ORDER_SENIORITY_LEVELS, updateOrderSeniorityLevels);
}
