import axios from 'axios';
import qs from 'qs';
import history from 'utils/history';
import config from '../config';
import { isHasPermissionForRoute } from '../utils/isHasPermissionForRoute';
import { UNAUTHORIZED_STATUS } from '../utils/const-variable';
import { promiseDebounce } from './debounce';
import { store } from '../store';
import { logout } from '../common/actions/authActions';
import { resetStore } from '../common/actions/storeActions';

const apiRoot = `${config.host}/api/`;
const axiosClient = axios.create({ baseURL: apiRoot });
const goBack = promiseDebounce(history.goBack, 1000);
let unauthorizedRequests = [];
let isRefreshing = false;

const processUnauthorizedRequests = (error, token) => {
  unauthorizedRequests.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  unauthorizedRequests = [];
};

axiosClient.interceptors.request.use((config) => {
  const { version, prevVersion } = store.getState().versionReducer;
  if (version !== prevVersion && config.url !== 'version') {
    const ac = new AbortController();
    config.signal = ac.signal;
    ac.abort();
    store.dispatch(resetStore());
  }
  return config;
});

axiosClient.interceptors.response.use(
  response => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (!isHasPermissionForRoute(error.response)) {
      return goBack().then(() => Promise.reject(error));
    }

    if (error.response.data && error.response.data.code === 1009) {
      store.dispatch(logout());

      return;
    }

    const originalRequest = error.config;
    if (error.response.status === UNAUTHORIZED_STATUS && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          unauthorizedRequests.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;

            return axiosClient(originalRequest);
          })
          .catch((error) => Promise.reject(error));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem('RefreshToken');

      return new Promise((resolve, reject) => {
        // axios doesn't resolve/reject the promise for some reason when refresh token is expired.
        fetch(`${apiRoot}auth/refresh-token?refreshToken=${refreshToken}`)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }

            throw new Error(`Error during access token refresh. Status code ${response.status}`);
          })
          .then((data) => {
            localStorage.setItem('AuthToken', data.AuthToken);
            localStorage.setItem('RefreshToken', data.RefreshToken);

            originalRequest.headers['Authorization'] = `Bearer ${data.AuthToken}`;

            processUnauthorizedRequests(null, data.AuthToken);

            resolve(axiosClient(originalRequest));
          })
          .catch((error) => {
            processUnauthorizedRequests(error, null);

            store.dispatch(logout());

            reject(error);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default function http({
  method, url, data, params, handleToken, resType, headers, transformResponse,
}) {
  const token = handleToken || localStorage.getItem('AuthToken');
  const config = {
    method: method.toLowerCase(),
    url,
    params,
    responseType: resType || 'json',
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' }),
    transformResponse,
  };
  if (data) config['data'] = data;

  if (token) {
    config['headers'] = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${token}`,
      ...headers,
    };
  }

  return axiosClient(config);
}
