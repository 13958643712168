import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import {
  getManageJobTitles, putManageJobTitles, postManageJobTitles,
  mergeManageJobTitles,
} from '../../../actions/adminManageJobTitles';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import isEmptyObject from 'utils/isEmptyObject';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { ModalMerge } from '../components/modal-merge/modal-merge';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageJobTitles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Job Titles', isSelected: true, Id: 'Name',
        },
      ],
      showAddRoleFields: false,
      addJobTitle: { Name: '' },
      blockAddJobTitle: false,
      blockSaveJob: false,
      entityForMerge: [],
      showMergeModal: false,
      mainEntityForMerge: null,
      additionalEntityForMerge: [],
      isApplyDisabled: false,
      isDisabledSuggest: true,
    };
  }

  componentDidMount() {
    const { getManageJobTitles } = this.props;
    getManageJobTitles();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewJobTitle = () => {
    const { blockAddJobTitle } = this.state;
    if (!blockAddJobTitle) {
      this.setState({
        showAddRoleFields: true,
      });
    }
  }

  getNewJobTitle = ({ target }) => {
    const { addJobTitle } = this.state;
    this.setState({
      addJobTitle: {
        ...addJobTitle,
        [target.name]: target.value,
      },
    }, this.newJobTitleValidation);
  }

  newJobTitleValidation = () => {
    const { addJobTitle } = this.state;
    this.setState({
      blockSaveJob: !!addJobTitle.Name.trim(),
      blockAddJobTitle: !!addJobTitle.Name.trim(),
    });
  };

  newJobTitleCancel = () => {
    this.setState({
      addJobTitle: { Name: '' },
      showAddRoleFields: false,
      blockSaveJob: false,
      blockAddJobTitle: false,
    });
  }

  newJobTitleApply = () => {
    const { postManageJobTitles } = this.props;
    const { blockSaveJob, addJobTitle } = this.state;

    if (blockSaveJob) {
      postManageJobTitles(addJobTitle);
      this.setState({
        addJobTitle: { Name: '' },
        showAddRoleFields: false,
        blockSaveJob: false,
        blockAddJobTitle: false,
      });
    }
  }

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `jobTitles/${element.Id}`,
      searchUpdateSaga: 'jobTitles',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageJobTitles } = this.props;
    putManageJobTitles(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  getElementsForMerge = () => {
    const { entityForMerge } = this.state;
    if (entityForMerge.length > 1) {
      this.toogleModalMerge();
    }
  }

  toogleModalMerge = () => {
    const { showMergeModal } = this.state;
    this.setState({
      showMergeModal: !showMergeModal,
    });
  }

  changeDataForMultiBox = (incomingItems) => {
    const employeesForMultiBox = [];
    incomingItems.map((item) => {
      return employeesForMultiBox.push({
        label: item.Name,
        value: item.Name,
        valueId: item.Id,
      });
    });
    return employeesForMultiBox;
  };

  handleChangeMainMergeEntity = (value) => {
    this.setState({
      mainEntityForMerge: value,
      isDisabledSuggest: !value,
    }, this.checkMergedFields);
  };

  handleGetMergeElements = (value) => {
    this.setState({
      additionalEntityForMerge: value,
    }, this.checkMergedFields);
  }

  checkMergedFields = () => {
    const { mainEntityForMerge, additionalEntityForMerge } = this.state;
    this.setState({
      isApplyDisabled: mainEntityForMerge && !isEmptyObject(mainEntityForMerge) && !!additionalEntityForMerge.length,
    });
  }

  onApply = () => {
    const { mergeManageJobTitles } = this.props;
    const { mainEntityForMerge, additionalEntityForMerge, isApplyDisabled } = this.state;
    if (isApplyDisabled) {
      const dataForMerge = {
        MergeTo: mainEntityForMerge.valueId,
        MergeFrom: additionalEntityForMerge.reduce((acc, curValue) => {
          const elementsForMerge = [];
          elementsForMerge.push(curValue.valueId);
          return [...acc, ...elementsForMerge];
        }, []),
      };
      mergeManageJobTitles(dataForMerge);
      this.cancelMerge();
    }
  }

  cancelMerge = () => {
    this.setState({
      mainEntityForMerge: null,
      additionalEntityForMerge: [],
      showMergeModal: false,
      entityForMerge: [],
    });
  }

  getDataForSuggestBox = (allJobs, choosenJobs, mainElement) => {
    const elementsForMerge = allJobs.filter((item) => (
      (choosenJobs.includes(item.Id) && (!mainElement || item.Id !== mainElement.valueId)) && true
    ));
    return this.changeDataForMultiBox(elementsForMerge);
  }

  checkItem = (e) => {
    const { entityForMerge } = this.state;
    const entity = e.currentTarget.value;
    const isReadyForMerge = entityForMerge.find(item => item === entity);
    const updatingEntity = entityForMerge;
    const index = updatingEntity.indexOf(isReadyForMerge);
    if (!isReadyForMerge) {
      updatingEntity.push(entity);
    } else {
      updatingEntity.splice(index, 1);
    }
    this.setState({
      entityForMerge: updatingEntity,
    });
  }

  render() {
    const {
      jobTitles,
      listSettings,
    } = this.props;
    const {
      columnsToDisplay, showAddRoleFields, addJobTitle, blockAddJobTitle,
      showMergeModal,
      entityForMerge, mainEntityForMerge, isApplyDisabled, isDisabledSuggest,
    } = this.state;
    const {
      searchValue,
      sortColumn,
      sortDirection,
    } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsJobTitles')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewJobTitle}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search job title'
          showMargeButton
          mergeElements={this.getElementsForMerge}
          isActiveMerge={entityForMerge.length > 1}
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(jobTitles, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.getElementForDelete}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          isCheckboxShown
          checkItem={this.checkItem}
          entityForMerge={entityForMerge}
        />
        {showAddRoleFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addJobTitle}
              onChangeNewDocumentObj={this.getNewJobTitle}
              disableSaveDocument={blockAddJobTitle}
              newDocumentCancel={this.newJobTitleCancel}
              newDocumentApply={this.newJobTitleApply}
            />
          </div>
        </div> : null}
        {showMergeModal ? (
          <ModalMerge
            handleChangeMainMergeEntity={this.handleChangeMainMergeEntity}
            handleGetMergeElements={this.handleGetMergeElements}
            mainOptions={this.getDataForSuggestBox(jobTitles, entityForMerge)}
            mergeOptions={this.getDataForSuggestBox(jobTitles, entityForMerge, mainEntityForMerge)}
            isDisabledSuggest={isDisabledSuggest}
            isApplyDisabled={!isApplyDisabled}
            cancelMerge={this.cancelMerge}
            onClick={this.onApply}
            toogleModalMerge={this.toogleModalMerge}
            lookup='Job title'
          />
        ) : null}
      </div>
    );
  }
}

ManageJobTitles.propTypes = {
  getManageJobTitles: PropTypes.func.isRequired,
  putManageJobTitles: PropTypes.func.isRequired,
  postManageJobTitles: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
  mergeManageJobTitles: PropTypes.func.isRequired,
};

export default connect((store) => ({
  jobTitles: store.adminManageLookups.jobTitles,
  listSettings: store.adminManageLookups.listSettings,
}), {
  getManageJobTitles,
  putManageJobTitles,
  postManageJobTitles,
  showDialog,
  mergeManageJobTitles,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageJobTitles);
