import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  ADD_NEW_SOW,
  EDIT_CURRENT_SOW,
  ADD_NEW_PO,
  EDIT_CURRENT_PO,
  ADD_NEW_AMENDMENT,
  EDIT_CURRENT_AMENDMENT,
  CHECK_ABILITY_FOR_PARENT_MILESTONE_DELETING,
  ADD_NEW_MSA,
  EDIT_CURRENT_MSA,
} from '../actions/contractsActions.js';
import { getProjectEditSOWFormData, getProjectEditPOFormData, getProjectAmedmentFormData, getProjectMSAFormData } from 'common/actions/reduxFormActions';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import http from 'services/http';
import { getProjectDetails } from './projectsSaga';
import { addNewNotification } from 'services/addNewNotification.js';
import { addNotify } from 'common/actions/notificationsActions';
import { getUpdatedDocuments, getEditedDocuments } from 'utils/documentsUtils';
import { deleteImportedContract } from '../actions/importContractAction';
import { getProjectMilestones } from '../actions/projectsActions';
import { mapToChildContractMilestone, mapToMainContractMilestone } from '../../../services/milestone';
import history from 'utils/history';

export function* addNewSOW(action) {
  const form = yield select(getProjectEditSOWFormData);
  const {
    Contract, Type, ApprovalStatus, Approver, StatusComments, BudgetComments, Company, Project, Status, StartDate, EndDate, BillingCode,
    TravelBudget, TravelReimbursement, BusinessGroup, ProjectTeam, Links, PaymentTerm, Milestones, SowRenewalStatuses, InvoiceDate,
  } = form.values;
  let withoutEmptyObjSOW = [];
  if (Links.length) {
    withoutEmptyObjSOW = getUpdatedDocuments(Links, null, 'SOWId');
  }
  const arraySowRenewalStatuses = SowRenewalStatuses.map((status) => (
    { RenewalStatusId: status.RenewalStatusId, Value: status.Value, IsActive: status.IsActive }));
  const data = {
    SOWId: Contract,
    SowTypeId: Type.Id,
    ApprovalStatus: ApprovalStatus.Id,
    SOWApproverId: Approver,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    ProjectId: Project.Id,
    Links: withoutEmptyObjSOW,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    BillingCode: BillingCode,
    TravelBudget: TravelBudget,
    TravelReimbursement: TravelReimbursement,
    BusinessGroup: BusinessGroup,
    ProjectTeam: ProjectTeam,
    PaymentTermId: PaymentTerm && PaymentTerm.Id,
    SowRenewalStatuses: arraySowRenewalStatuses,
    InvoiceDateId: InvoiceDate.Id,
  };
  try {
    const {
      projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests,
    } = action.payload;
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/sows`,
      data,
    });

    yield call(http, {
      method: 'PUT',
      url: `billing/projects/${projectId}/statements-of-work/${doc.data}/milestones`,
      data: {
        milestones: (Milestones || []).map((milestone) => mapToMainContractMilestone(milestone))
      },
    });

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' created');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not created');
    yield put(addNotify(message));
  } finally {
    yield put(deleteImportedContract());
  }
}

export function* editCurrentSOW(action) {
  const form = yield select(getProjectEditSOWFormData);
  const {
    Id, Contract, Type, ApprovalStatus, Approver, StatusComments, BudgetComments, Company, Project, Status, StartDate, EndDate, BillingCode,
    TravelBudget, TravelReimbursement, BusinessGroup, ProjectTeam, Links, PaymentTerm, Milestones, SowRenewalStatuses, InvoiceDate
  } = form.values;
  const withoutEmptyObjSOW = getEditedDocuments(Links, Id, 'SOWId');
  const arraySowRenewalStatuses = SowRenewalStatuses.map((status) => (
    { RenewalStatusId: status.RenewalStatusId, Value: status.Value, IsActive: status.IsActive }));
  const data = {
    Id: Id,
    SOWId: Contract,
    SowTypeId: Type.Id,
    ApprovalStatus: ApprovalStatus.Id,
    SOWApproverId: Approver,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    ProjectId: Project.Id,
    Links: withoutEmptyObjSOW,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    BillingCode: BillingCode,
    TravelBudget: TravelBudget,
    TravelReimbursement: TravelReimbursement,
    BusinessGroup: BusinessGroup,
    ProjectTeam: ProjectTeam,
    PaymentTermId: PaymentTerm && PaymentTerm.Id,
    InvoiceDateId: InvoiceDate.Id,
  };

  const {
    projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests, saveCallback,
  } = action.payload;

  try {
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'PUT',
      url: `projects/${projectId}/sows/${data.Id}`,
      data,
    });
    yield call(editSowRenewalStatus, {
      payload: {
        sowId: data.Id,
        renewalStatuses: arraySowRenewalStatuses,
      },
    });

    yield call(http, {
      method: 'PUT',
      url: `billing/projects/${projectId}/statements-of-work/${Id}/milestones`,
      data: {
        milestones: (Milestones || []).map((milestone) => mapToMainContractMilestone(milestone))
      },
    });

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' updated');
    yield put(addNotify(message));
    yield put(getProjectMilestones(projectId));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not updated');
    if (e.response && e.response.data && e.response.data.code === 1000) {
      yield put(showAlertDialog(e.response.data.description));
    }
    yield put(addNotify(message));
  } finally {
    if (saveCallback) {
      history.push({
        pathname: saveCallback,
      });
    }
  }
}

export function* addNewMSA(action) {
  const form = yield select(getProjectMSAFormData);
  console.log(form);
  const {
    Contract, ApprovalStatus, Signer, Comments, Company, Project, Status, StartDate, EndDate, Links
  } = form.data.values;
  let withoutEmptyObjMSA = [];
  if (Links.length) {
    withoutEmptyObjMSA = getUpdatedDocuments(Links, null, 'MSAId');
  }
  
  const data = {
    MSAId: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    SignerId: Signer,
    Comments: Comments && Comments.trim(),
    ClientId: Company,
    ProjectId: Project.Id,
    Links: withoutEmptyObjMSA,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate
  };
  try {
    const {
      projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests,
    } = action.payload;
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }

    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/msas`,
      data,
    });

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' created');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not created');
    yield put(addNotify(message));
  } finally {
    yield put(deleteImportedContract());
  }
}

export function* editCurrentMSA(action) {
  const form = yield select(getProjectMSAFormData);
  const {
    Id, Contract, ApprovalStatus, Signer, Comments, Company, Project, Status, StartDate, EndDate, Links
  } = form.data.values;
  const withoutEmptyObjMSA = getEditedDocuments(Links, Id, 'MSAId');
  const data = {
    Id: Id,
    MSAId: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    SignerId: Signer,
    Comments: Comments && Comments.trim(),
    ClientId: Company,
    ProjectId: Project.Id,
    Links: withoutEmptyObjMSA,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate
  }

  const {
    projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests, saveCallback
  } = action.payload;

  try {
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'PUT',
      url: `projects/${projectId}/msas/${data.Id}`,
      data,
    });
    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' updated');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not updated');
    if (e.response && e.response.data && e.response.data.code === 1000) {
      yield put(showAlertDialog(e.response.data.description));
    }
    yield put(addNotify(message));
  } finally {
    if (saveCallback) {
      history.push({
        pathname: saveCallback,
      });
    }
  }
}

export function* addNewPO(action) {
  const form = yield select(getProjectEditPOFormData);
  const {
    Contract, ApprovalStatus, Approver, StatusComments, BudgetComments, Company, Status, StartDate, EndDate, BillingCode,
    Links, PaymentTerm, Milestones, InvoiceDate,
  } = form.values;
  const {
    isNeedToChangeDate, projectId, isOnlyActive, configPatchRequests, SOWId, POMainId,
  } = action.payload;
  let withoutEmptyObjPO = [];
  if (Links.length) {
    withoutEmptyObjPO = getUpdatedDocuments(Links, null, 'POId');
  }

  const data = {
    SOWId: SOWId,
    Number: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    POApproverId: Approver,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    Links: withoutEmptyObjPO,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    BillingCode: BillingCode,
    POMainId: action.payload.POMainId,
    PaymentTermId: PaymentTerm && PaymentTerm.Id,
    InvoiceDateId: !POMainId && InvoiceDate && InvoiceDate.Id || null
  };
  try {
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/sows/${SOWId}/pos`,
      data,
    });

    if (!POMainId) {
      yield call(http, {
        method: 'PUT',
        url: `billing/projects/${projectId}/original-purchase-orders/${doc.data}/milestones`,
        data: {
          milestones: (Milestones || []).map((milestone) => mapToMainContractMilestone(milestone))
        },
      });
    } else {
      yield call(http, {
        method: 'PUT',
        url: `billing/projects/${projectId}/extension-purchase-orders/${doc.data}/milestones`,
        data: {
          milestones: (Milestones || []).map((milestone) => mapToChildContractMilestone(milestone))
        },
      });
    }

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' created');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not created');
    yield put(addNotify(message));
  } finally {
    yield put(deleteImportedContract());
  }
}

export function* editCurrentPO(action) {
  const form = yield select(getProjectEditPOFormData);
  const {
    Id, Contract, ApprovalStatus, Approver, StatusComments, BudgetComments, Company, Status, StartDate, EndDate, BillingCode,
    POMainId, Links, PaymentTerm, Milestones, InvoiceDate,
  } = form.values;
  const {
    isNeedToChangeDate, projectId, isOnlyActive, configPatchRequests, SOWId, isOriginal, saveCallback,
  } = action.payload;
  let withoutEmptyObjPO = [];
  if (Links.length) {
    withoutEmptyObjPO = getEditedDocuments(Links, Id, 'POId');
  }

  const data = {
    SOWId: SOWId,
    Number: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    POApproverId: Approver,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    Links: withoutEmptyObjPO,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    BillingCode: BillingCode,
    POMainId: POMainId || null,
    PaymentTermId: PaymentTerm && PaymentTerm.Id,
    InvoiceDateId: !POMainId && InvoiceDate && InvoiceDate.Id || null
  };
  try {
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'PUT',
      url: `projects/${projectId}/sows/${SOWId}/pos/${Id}`,
      data,
    });

    if (isOriginal) {
      yield call(http, {
        method: 'PUT',
        url: `billing/projects/${projectId}/original-purchase-orders/${Id}/milestones`,
        data: {
          milestones: (Milestones || []).map((milestone) => mapToMainContractMilestone(milestone))
        },
      });
    } else {
      yield call(http, {
        method: 'PUT',
        url: `billing/projects/${projectId}/extension-purchase-orders/${Id}/milestones`,
        data: {
          milestones: (Milestones || []).map((milestone) => mapToChildContractMilestone(milestone))
        },
      });
    }

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' updated');
    yield put(addNotify(message));
    yield put(getProjectMilestones(projectId));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not updated');
    if (e.response && e.response.data && e.response.data.code === 1000) {
      yield put(showAlertDialog(e.response.data.description));
    }
    yield put(addNotify(message));
  } finally {
    if (saveCallback) {
      history.push({
        pathname: saveCallback,
      });
    }
  }
}

export function* addNewAmendment(action) {
  const form = yield select(getProjectAmedmentFormData);
  const {
    Contract, ApprovalStatus, StatusComments, BudgetComments, Company, Status,
    StartDate, EndDate, Approver, Links, Milestones,
  } = form.data.values;
  let withoutEmptyObj = [];
  if (Links.length) {
    withoutEmptyObj = getUpdatedDocuments(Links, null, 'AmendmentId');
  }

  const data = {
    SOWId: action.payload.SOWId,
    Number: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    Links: withoutEmptyObj,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    AmendmentApproverId: Approver,
  };
  try {
    const {
      projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests,
    } = action.payload;
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/sows/${data.SOWId}/amendments`,
      data,
    });

    yield call(http, {
      method: 'PUT',
      url: `billing/projects/${projectId}/amendments/${doc.data}/milestones`,
      data: {
        milestones: (Milestones || []).map((milestone) => mapToChildContractMilestone(milestone))
      },
    });

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' created');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not created');
    yield put(addNotify(message));
  } finally {
    yield put(deleteImportedContract());
  }
}

export function* editCurrentAmendment(action) {
  const form = yield select(getProjectAmedmentFormData);
  const {
    Id, Contract, ApprovalStatus, StatusComments, BudgetComments, Company, Status,
    StartDate, EndDate, Links, Approver, Milestones,
  } = form.data.values;
  let withoutEmptyObj = [];
  if (Links.length) {
    withoutEmptyObj = getEditedDocuments(Links, Id, 'AmendmentId');
  }
  const {
    projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests, SOWId, saveCallback,
  } = action.payload;
  const data = {
    SOWId: SOWId,
    Number: Contract,
    ApprovalStatus: ApprovalStatus.Id,
    StatusComments: StatusComments && StatusComments.trim(),
    BudgetComments: BudgetComments && BudgetComments.trim(),
    ClientId: Company,
    Links: withoutEmptyObj,
    DocumentStatus: Status.Id,
    StartDate: StartDate,
    EndDate: EndDate,
    AmendmentApproverId: Approver,
  };
  try {
    if (isNeedToChangeDate && configPatchRequests) {
      for (let i = 0; i < configPatchRequests.length; i++) {
        const response = yield call(patchContractData, {
          payload: configPatchRequests[i],
        });
        const message = yield addNewNotification(response, 'Contract', ' updated');
        yield put(addNotify(message));
      }
    }
    const doc = yield call(http, {
      method: 'PUT',
      url: `projects/${projectId}/sows/${SOWId}/amendments/${Id}`,
      data,
    });

    yield call(http, {
      method: 'PUT',
      url: `billing/projects/${projectId}/amendments/${Id}/milestones`,
      data: {
        milestones: (Milestones || []).map((milestone) => mapToChildContractMilestone(milestone))
      },
    });

    yield call(getProjectDetails, {
      payload: {
        id: projectId,
        isOnlyActive: isOnlyActive,
      },
    });
    const message = addNewNotification(doc, 'Contract', ' updated');
    yield put(addNotify(message));
    yield put(getProjectMilestones(projectId));
  } catch (e) {
    const message = addNewNotification(e, 'Contract', ' not updated');
    if (e.response && e.response.data && e.response.data.code === 1000) {
      yield put(showAlertDialog(e.response.data.description));
    }
    yield put(addNotify(message));
  } finally {
    if (saveCallback) {
      history.push({
        pathname: saveCallback,
      });
    }
  }
}

export function* checkAbilityForParentMilestoneDeleting(action) {
  try {
    const {
      milestoneId, milestoneName, formName, fieldName, newMilestonesValue, parentType, childType, projectId,
    } = action.payload;
    const response = yield call(http, {
      method: 'GET',
      url: `billing/projects/${projectId}/milestones/${milestoneId}/can-delete`,
    });
    const canDeleteMilestone = response.data;
    yield put(
      canDeleteMilestone ? change(formName, fieldName, newMilestonesValue)
        : showAlertDialog(
          `The same ${childType} milestones must be deleted before the ${parentType} milestone can be deleted. Milestone - ${milestoneName}`,
        ),
    );
  } catch {
    yield put(showAlertDialog());
  }
}

export function* editSowRenewalStatus(action) {
  const { sowId, renewalStatuses } = action.payload;
  yield call(http, {
    method: 'put',
    url: `sows/${sowId}/sow-renewal-statuses`,
    data: { SowRenewalStatuses: renewalStatuses },
  });
}

export function* patchContractData(action) {
  const { url, data } = action.payload;
  const contract = yield call(http, {
    method: 'PATCH',
    url,
    data,
  });
  return contract;
}

export default function* SOWEditRootSaga() {
  yield takeEvery(ADD_NEW_SOW, addNewSOW);
  yield takeEvery(EDIT_CURRENT_SOW, editCurrentSOW);
  yield takeEvery(ADD_NEW_MSA, addNewMSA);
  yield takeEvery(EDIT_CURRENT_MSA, editCurrentMSA);
  yield takeEvery(ADD_NEW_PO, addNewPO);
  yield takeEvery(EDIT_CURRENT_PO, editCurrentPO);
  yield takeEvery(ADD_NEW_AMENDMENT, addNewAmendment);
  yield takeEvery(EDIT_CURRENT_AMENDMENT, editCurrentAmendment);
  yield takeEvery(CHECK_ABILITY_FOR_PARENT_MILESTONE_DELETING, checkAbilityForParentMilestoneDeleting);
}
