/* eslint-disable indent */
import React from 'react';
import TextField from 'common/components/form-items/input/text-field';
import { svgEditPencil } from 'services/svgEditPencil';
import Tooltip from 'common/components/tooltip/tooltip';
import classNames from 'classnames';
import '../../redux-form.css';

export default class renderTogglingTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  toggleEditMode = (editMode) => this.setState({ editMode });

  onChange = event => {
    const { input, disableLetters } = this.props;
    if (disableLetters) {
      input.onChange(event.target.value.replace(/[^\d.]/g, ''));
    } else {
      input.onChange(event.target.value);
    }
  }

  onBlur = () => {
    const { input } = this.props;
    input.onBlur();
    this.toggleEditMode(false);
  }

  render() {
    const { editMode } = this.state;
    const {
      input, meta, valueToShow, isEditable, label, autoFocus, startAdornment, placeholder, maxlength, multiline, fullWidth, styles,
      formClassName, inputClassName, lableIcon, showAsterix, showAsterixOnFocus, isTooltip, textForTooltip, tooltipClassName, InputProps,
      isSaved = true,
    } = this.props;

    const isAsterixVisible = showAsterixOnFocus ? (editMode && isEditable) : showAsterix;
    return (
      <div
        className={styles.container}
        onMouseLeave={this.onBlur}
      >
        {
          label && <label className={styles.label}>
            <span>{label}{lableIcon && lableIcon()}</span>
            {
              isAsterixVisible && <span className='redux-form__required'> *</span>
            }
            {
              isTooltip
                ? (
                  <Tooltip
                    textForTooltip={textForTooltip}
                    className={tooltipClassName}
                  />
                )
                : null
            }
          </label>
        }
        <div className={styles.field}>
          {
            editMode && isEditable
              ? <TextField
                  formClassName={formClassName}
                  inputClassName={inputClassName}
                  placeholder={placeholder}
                  maxlength={maxlength}
                  value={input.value}
                  onChange={this.onChange}
                  startAdornment={startAdornment}
                  onBlur={this.onBlur}
                  autoFocus={autoFocus}
                  multiline={multiline}
                  fullWidth={fullWidth}
                  InputProps={InputProps}
              /> : (
                <div className={styles.fieldView} title={input.value}>
                  <div
                    onClick={() => this.toggleEditMode(true)}
                    className={classNames(
                      styles.fieldContent,
                      { 'field--not--editable': !isEditable },
                      { 'field--editable': isEditable },
                      { 'field--invalid': (meta.invalid && meta.error !== 'Value required') || !isSaved },
                    )}
                  >
                    {
                      multiline
                        ? <pre>{valueToShow}</pre>
                        : <span>{valueToShow}</span>
                    }
                    {isEditable && svgEditPencil(input.name)}
                  </div>
                  {
                    (meta.invalid && meta.error !== 'Value required') && (
                      <div className={styles.fieldError}>
                        <span>{meta.error}</span>
                      </div>
                    )
                  }
                  {
                    !isSaved && (
                      <div className={styles.fieldError}>
                        <span>Value wasn't saved</span>
                      </div>
                    )
                  }
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
