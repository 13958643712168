import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_PROJECT_TYPES,
  PUT_MANAGE_PROJECT_TYPES,
  POST_MANAGE_PROJECT_TYPES,
  setManageProjectTypes,
} from '../actions/adminManageProjectTypes';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageProjectTypes() {
  try {
    yield put(startRequest());
    const projectTypes = yield call(http, {
      method: 'GET',
      url: 'project_types',
    });

    yield put(setManageProjectTypes(projectTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putProjectType(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: 'project_types',
      data: action.payload,
    });
    yield call(manageProjectTypes);
  } catch (e) {
    // error message
  }
}

export function* postProjectType(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'project_types',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageProjectTypes);
  } catch (e) {
    // error message
  }
}

export default function* adminManageProjectTypes() {
  yield takeEvery(GET_MANAGE_PROJECT_TYPES, manageProjectTypes);
  yield takeEvery(PUT_MANAGE_PROJECT_TYPES, putProjectType);
  yield takeEvery(POST_MANAGE_PROJECT_TYPES, postProjectType);
}
