import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import './SOW-edit-page.css';
import { sortFilterList } from 'common/components/list/sortList';
import { loadManageDocumentTypes } from 'pages/admin/actions/adminManageDocumentTypes';
import { getManagePaymentTerms } from 'pages/admin/actions/adminManagePaymentTerms';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import ProjectEditSOWForm from 'common/components/redux-form/project-edit-SOW-form/project-edit-SOW-form';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import {
  getMilestonesForSOW,
  setMilestonesForSOW,
  getRenewalStatusesForSow,
  setRenewalStatusesForSow,
  getShadowingMilestones,
  resetShadowingMilestones,
} from 'pages/projects/actions/projectsActions';
import { loadSOWRenewalStatuses } from 'pages/admin/actions/adminManageSOWRenewalStatus';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import isObjectNotEquals from 'utils/object-comparison';
import { getTheBiggestDateRangeForContracts } from 'pages/projects/components/utils/getTheBiggestDateRangeForContracts';
import { convertMilestones } from 'utils/convertMilestones';
import { formatContractDate } from '../../utils/formatContractDate';
import { cancelImport } from 'pages/projects/actions/importContractAction';
import { getEmployees } from 'pages/projects/actions/roleModalActions';

export class SOWEditPage extends React.Component {
  constructor(props) {
    super(props);
    const { projectDetails, ManageProjectContracts, HasFinancialAccess } = this.props;
    this.state = {
      columnsToDisplay: {
        documentsList: [
          {
            Name: 'Documents Links',
            isSelected: true,
            Id: 'Comment',
            className: 'without-width-col',
            pressArrowLeft: true,
          },
          {
            Name: 'Documents Types',
            isType: true,
            Id: 'DocumentType',
            className: 'without-width-col',
            pressArrowLeft: true,
          },
        ],
        milestonesList: [
          {
            Name: 'Milestone',
            Id: 'Milestone',
            className: 'without-width-col',
            isRequired: true,
          },
          {
            Name: 'Milestone Amount',
            Id: 'MilestoneAmount',
            className: 'without-width-col',
            isRequired: true,
          },
          {
            Name: 'Invoice Amount',
            Id: 'InvoicedAmount',
            className: 'without-width-col',
          },
          {
            Name: 'Revenue Received',
            Id: 'RevenueReceived',
            className: 'without-width-col',
          },
          {
            Name: 'Invoiced Date',
            Id: 'InvoicedDate',
            className: 'without-width-col',
          },
          {
            Name: 'Delta to Goal',
            Id: 'RemainingBalance',
            className: 'without-width-col',
          },
          {
            Name: 'Approved',
            Id: 'Approved',
            className: 'without-width-col',
          },
          {
            Name: 'Backup used',
            Id: 'UsedAssignmentBackupUser',
            className: 'without-width-col',
          },
        ],
        sowRenewalStatusesList: [
          {
            Name: 'Status Type',
            Id: 'Status',
            className: 'col__renewal-status-type',
          },
          {
            Name: 'Value',
            Id: 'Value',
            className: 'col__renewal-value',
          },
        ],
      },
      ManageProjectContracts,
      HasFinancialAccess,
    };
    this.Id = history.location.pathname.split('/')[4] || null;
    // eslint-disable-next-line prefer-destructuring
    this.location = history.location.pathname.split('/')[3];
    this.emptyForm = {
      Id: '',
      Contract: this.autoGenerateSOWNumber(projectDetails.Contracts),
      Company: '',
      Approver: '',
      Type: {},
      BillingCode: '',
      Project: { Id: projectDetails.Id, Name: projectDetails.Name },
      Budget: '',
      StartDate: null,
      EndDate: null,
      ApprovalStatus: {},
      BusinessGroup: '',
      ProjectTeam: '',
      TravelBudget: false,
      TravelReimbursement: false,
      RealizedRevenue: '',
      StatusComments: '',
      BudgetComments: '',
      Status: {},
      Links: [],
      Milestones: [],
      POs: [],
      PaymentTerm: '',
      SowRenewalStatuses: [],
    };
  }

  componentDidMount() {
    const {
      projectDetails,
      location,
      loadManageDocumentTypes,
      getManagePaymentTerms,
      getMilestonesForSOW,
      setComponent,
      loadSOWRenewalStatuses,
      getRenewalStatusesForSow,
      getShadowingMilestones,
      HasFinancialAccess,
      importedRoles,
      getEmployees,
    } = this.props;
    if (importedRoles && importedRoles.length) {
      const offices = projectDetails.Offices.map((office) => office.Id);
      getEmployees(offices, true);
    }
    this.defineRightsForEdit();
    !projectDetails.Name && history.goBack({ state: location.state });
    if (HasFinancialAccess) {
      this.Id && getMilestonesForSOW(this.Id, projectDetails.Id);
      this.Id && getShadowingMilestones(this.Id, projectDetails.Id);
    }
    this.Id && getRenewalStatusesForSow(this.Id);
    loadManageDocumentTypes();
    getManagePaymentTerms();
    loadSOWRenewalStatuses();
    const rightPath = location.state && location.state.isWrongPath ? 'contracts' : 'projects';
    setComponent({
      returnButton: { text: rightPath, goToPreviousPage: this.goTyMainList },
      secondReturnButton: {
        text: `${projectDetails && projectDetails.Name} details`,
        goToPreviousPage: this.goToProjectDetails,
      },
    });
  }

  componentWillUnmount() {
    const {
      setMilestonesForSOW, resetComponent, setRenewalStatusesForSow, resetShadowingMilestones,
    } = this.props;

    const emptyArray = [];
    setMilestonesForSOW(emptyArray);
    setRenewalStatusesForSow(emptyArray);
    resetComponent();
    resetShadowingMilestones();
  }

  defineRightsForEdit = () => {
    if (this.location === 'add-sow') {
      this.setState({
        HasFinancialAccess: true,
      });
    }
  };

  autoGenerateSOWNumber = (contracts) => {
    return 'SOW-' + (contracts.length + 1);
  };

  getTitleText = () => {
    const titleText = this.location.replace('-', ' ');
    return titleText === 'view sow'
      ? 'viewSow'
      : titleText === 'edit sow'
        ? 'editSow'
        : 'addSow';
  };

  goTyMainList = () => {
    const { location } = this.props;
    const rightPath = location.state && location.state.isWrongPath ? 'contracts' : 'projects';
    history.push({ pathname: `/${rightPath}`, state: location.state });
  };

  goToProjectDetails = () => {
    const { projectDetails, location, cancelImport } = this.props;

    cancelImport(projectDetails.Id);
    history.push({
      pathname: `/projects/${projectDetails.Id}`,
      state: location.state,
    });
  };

  getTheBiggestDateRangeForChildContract = (SOWDetails) => {
    const arrayForParam = SOWDetails ? [SOWDetails] : [];
    return getTheBiggestDateRangeForContracts(arrayForParam, false);
  };

  render() {
    const { columnsToDisplay, HasFinancialAccess, ManageProjectContracts } = this.state;
    const {
      projectDetails,
      SOWTypes,
      approvalStatuses,
      clients,
      clientContacts,
      documentStatuses,
      location,
      documentTypes,
      paymentTerms,
      invoiceDates,
      milestones,
      allRenewalStatuses,
      SowRenewalStatuses,
      allRoles,
      importContract,
      partialContract,
    } = this.props;
    const SOWDetails = projectDetails
      && projectDetails.Contracts.find((contract) => contract.Id === this.Id);
    const formTitle = this.location === 'add-sow' ? 'Add SOW' : 'Edit SOW ';
    const arrayMilestones = importContract && importContract.Milestones ? convertMilestones(importContract.Milestones) : convertMilestones(milestones);
    const arrayRenewalStatuses = allRenewalStatuses.map((status) => {
      const currentSowRenewalStatus = SowRenewalStatuses
        && SowRenewalStatuses.find(
          (sowStatus) => !isObjectNotEquals(sowStatus.RenewalStatus, status),
        );
      return !SowRenewalStatuses.length && status.Name === 'New business'
        ? {
          RenewalStatusId: status.Id,
          Name: status.Name,
          Value: 100,
          IsActive: true,
        }
        : {
          RenewalStatusId: status.Id,
          Name: status.Name,
          Value: currentSowRenewalStatus ? currentSowRenewalStatus.Value : 0,
          IsActive: currentSowRenewalStatus
            ? currentSowRenewalStatus.IsActive
            : false,
        };
    });
    return (
      <div className='page-container'>
        <DocumentTitle
          title={applicationTitle.getTitile(this.getTitleText())}
        />
        <div className='form-header'>
          <span>{formTitle}</span>
          <span className='highlighted-text'>
            {SOWDetails && SOWDetails.Contract}
          </span>
        </div>
        <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
          <ProjectEditSOWForm
            data={
              SOWDetails
                ? {
                  ...SOWDetails,
                  Company:
                    SOWDetails && SOWDetails.Company && SOWDetails.Company.Id,
                  Approver:
                    SOWDetails
                    && SOWDetails.Approver
                    && SOWDetails.Approver.Id,
                  BillingCode: SOWDetails && SOWDetails.BillingCode,
                  Milestones: arrayMilestones,
                  SowRenewalStatuses: [...arrayRenewalStatuses],
                }
                : Object.keys(importContract).length
                  ? {
                    ...this.emptyForm,
                    BillingCode:
                      (SOWDetails && SOWDetails.BillingCode)
                      || (projectDetails && projectDetails.BillingCode),
                    ...importContract,
                    SowRenewalStatuses: Object.keys(partialContract).length
                      && partialContract.SowRenewalStatuses
                      && Array.isArray(partialContract.SowRenewalStatuses)
                      ? partialContract.SowRenewalStatuses.map(status => {
                        const renewalStatusName = allRenewalStatuses.find(item => item.Id === status.RenewalStatusId);
                        return { ...status, Name: renewalStatusName.Name };
                      })
                      : [...arrayRenewalStatuses],
                    Milestones: arrayMilestones,
                    EndDate: formatContractDate(importContract.EndDate) || formatContractDate(projectDetails.EndDate),
                    StartDate: formatContractDate(importContract.StartDate) || formatContractDate(projectDetails.StartDate),
                    Type: SOWTypes.find(item => item.Id === importContract.SowTypeId) || {},
                    ApprovalStatus: approvalStatuses.find(item => item.Id === importContract.ApprovalStatus) || {},
                    Company: importContract.ClientId || '',
                    Approver: importContract.SOWApproverId || '',
                    Status: documentStatuses.find(item => item.Id === importContract.DocumentStatus) || {},
                    PaymentTerm: importContract.PaymentTerm || paymentTerms.find(item => item.Id === importContract.PaymentTermId) || '',
                  }
                  : {
                    ...this.emptyForm,
                    BillingCode:
                      (SOWDetails && SOWDetails.BillingCode)
                      || (projectDetails && projectDetails.BillingCode),
                    SowRenewalStatuses: [...arrayRenewalStatuses],
                  }
            }
            disabled={!ManageProjectContracts}
            allSOWTypes={sortFilterList(SOWTypes)}
            allApprovalStatuses={sortFilterList(approvalStatuses)}
            allClients={sortFilterList(clients)}
            allApprovers={sortFilterList(clientContacts)}
            allStatuses={documentStatuses}
            allDocumentTypes={documentTypes}
            RolesForContarct={projectDetails.Roles}
            allRolesForContract={allRoles}
            allPaymentTerms={paymentTerms}
            columnsToDisplay={columnsToDisplay}
            viewFinancialInfo={HasFinancialAccess}
            projectType={projectDetails.Billability}
            location={location}
            currencyProject={
              projectDetails
              && projectDetails.Currency
              && projectDetails.Currency.Name
            }
            projectDetails={projectDetails}
            handleSubmit={formHandleSubmit}
            invoiceDates={invoiceDates}
            milestonesFromDB={milestones}
            allRenewalStatuses={allRenewalStatuses}
            projectId={projectDetails && projectDetails.Id}
            datesForCheckChildContracts={this.getTheBiggestDateRangeForChildContract(
              SOWDetails,
            )}
          />
        </Scrollbars>
      </div>
    );
  }
}

SOWEditPage.propTypes = {
  projectDetails: PropTypes.object,
  SOWTypes: PropTypes.array,
  approvalStatuses: PropTypes.array,
  clients: PropTypes.array,
  clientContacts: PropTypes.array,
  documentStatuses: PropTypes.array,
  documentTypes: PropTypes.array,
  loadManageDocumentTypes: PropTypes.func,
  ManageProjectContracts: PropTypes.bool.isRequired,
  HasFinancialAccess: PropTypes.bool.isRequired,
};

export default connect(
  (store) => {
    return {
      projectDetails: store.projectsReducer.projectDetails,
      SOWTypes: store.projectsReducer.SOWTypes,
      approvalStatuses: store.projectsReducer.approvalStatuses,
      clients: store.filtersReducer.filters.Clients,
      clientContacts: store.projectsReducer.clientContacts,
      documentStatuses: store.filtersReducer.filters.documentStatuses,
      isOnlyActive: store.showRoles.isOnlyActiveRoles,
      UserOfficeId: store.authReducer.user.UserOfficeId,
      Offices: store.projectsReducer.projectDetails.Offices,
      documentTypes: store.adminManageLookups.documentTypes,
      EmployeeId: store.authReducer.user.EmployeeId,
      currentRoles: store.userRoleReducer.roles,
      paymentTerms: store.adminManageLookups.paymentTerms,
      resultAlert: store.dialogsReducer.alertDialogReducer.resultAlert,
      invoiceDates: store.adminManageLookups.invoiceDates,
      milestones: store.projectsReducer.Milestones.SOW,
      allRenewalStatuses: store.adminManageLookups.sowRenewalStatuses,
      SowRenewalStatuses: store.projectsReducer.SowRenewalStatuses,
      allRoles: store.projectsReducer.projectDetails.AllRoles,
      importContract: store.importContractReducer.contract,
      partialContract: store.importContractReducer.partialContract.contract,
      importedRoles: store.importContractReducer.contract.Roles,
    };
  },
  {
    loadManageDocumentTypes,
    getManagePaymentTerms,
    getMilestonesForSOW,
    setMilestonesForSOW,
    setComponent,
    resetComponent,
    loadSOWRenewalStatuses,
    getRenewalStatusesForSow,
    setRenewalStatusesForSow,
    cancelImport,
    getShadowingMilestones,
    resetShadowingMilestones,
    getEmployees,
  },
)(SOWEditPage);
