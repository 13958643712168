import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import {
  downloadManageProjectTypes,
  putManageProjectTypes,
  postManageProjectTypes,
} from '../../../actions/adminManageProjectTypes';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageProjectTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Project Types', isSelected: true, Id: 'Name',
        },
      ],
      showAddProjectTypeFields: false,
      addProjectType: { Name: '' },
      blockAddProjectType: false,
      blockSaveType: false,
    };
  }

  componentDidMount() {
    const { downloadManageProjectTypes } = this.props;
    downloadManageProjectTypes();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewProjectType = () => {
    const { blockAddProjectType } = this.state;
    if (!blockAddProjectType) {
      this.setState({
        showAddProjectTypeFields: true,
      });
    }
  }

  getNewProjectType = ({ target }) => {
    const { addProjectType } = this.state;
    this.setState({
      addProjectType: {
        ...addProjectType,
        [target.name]: target.value,
      },
    }, this.newTypeValidation);
  }

  newTypeValidation = () => {
    const { addProjectType } = this.state;
    this.setState({
      blockSaveType: !!addProjectType.Name.trim(),
      blockAddProjectType: !!addProjectType.Name.trim(),
    });
  };

  newTypeCancel = () => {
    this.setState({
      addProjectType: { Name: '' },
      showAddProjectTypeFields: false,
      blockSaveType: false,
      blockAddProjectType: false,
    });
  }

  newJobTitleApply = () => {
    const { postManageProjectTypes } = this.props;
    const { blockSaveType, addProjectType } = this.state;
    if (blockSaveType) {
      postManageProjectTypes(addProjectType);
      this.setState({
        addJobTitle: { Name: '' },
        showAddProjectTypeFields: false,
        blockSaveType: false,
        blockAddProjectType: false,
      });
    }
  }

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `project_types/${element.Id}`,
      searchUpdateSaga: 'project_types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageProjectTypes } = this.props;
    putManageProjectTypes(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  render() {
    const {
      projectTypes,
      listSettings,
    } = this.props;
    const {
      columnsToDisplay, showAddProjectTypeFields, addProjectType, blockAddProjectType,
    } = this.state;
    const {
      searchValue,
      sortColumn,
      sortDirection,
    } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsProjectTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewProjectType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search project type'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(projectTypes, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.getElementForDelete}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {showAddProjectTypeFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addProjectType}
              onChangeNewDocumentObj={this.getNewProjectType}
              disableSaveDocument={blockAddProjectType}
              newDocumentCancel={this.newTypeCancel}
              newDocumentApply={this.newJobTitleApply}
            />
          </div>
        </div> : null}
      </div>
    );
  }
}

ManageProjectTypes.propTypes = {
  downloadManageProjectTypes: PropTypes.func.isRequired,
  putManageProjectTypes: PropTypes.func.isRequired,
  postManageProjectTypes: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  projectTypes: store.adminManageLookups.projectTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  downloadManageProjectTypes,
  putManageProjectTypes,
  postManageProjectTypes,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageProjectTypes);
