import { STATUS_COLOR } from '../utils/const-variable';

export const getStatusColor = (status) => {
  const statusColor = {
    'Active': STATUS_COLOR.green,
    'Prospective': STATUS_COLOR.blue,
    'Removed': STATUS_COLOR.red,
    'Draft': STATUS_COLOR.blue,
    'Expired': STATUS_COLOR.grey,
    'Completed': STATUS_COLOR.blue,
    'Employee': STATUS_COLOR.lightRed,
    'Contractor': STATUS_COLOR.yellow,
    'Trainee': STATUS_COLOR.blue,
    'Disabled': STATUS_COLOR.charcoal,
    'Admin Staff': STATUS_COLOR.violet,
    'On Hold': STATUS_COLOR.grey,
    'Former employee': STATUS_COLOR.emerald,
    'Early Active': STATUS_COLOR.emerald,
    'Early Active & Active': STATUS_COLOR.turquoise,
    'Late Active': STATUS_COLOR.mediumTurquoise,
    'Hold': STATUS_COLOR.grey,
    'Finished': STATUS_COLOR.blue,
    'Extended': STATUS_COLOR.green,
    'InProgress': STATUS_COLOR.blue,
    'In Progress': STATUS_COLOR.blue,
    'Insufficient': STATUS_COLOR.yellow,
    'Ready': STATUS_COLOR.green,
    'Sent': STATUS_COLOR.grey,
    'Perpetual': STATUS_COLOR.violet,
  };

  return statusColor[status] !== undefined ? statusColor[status] : 'transparent';
};

export const getProjectStatusColor = (status) => {
  if (!status) return '';
  const statusColor = {
    'Approve SOW/PO': STATUS_COLOR.red,
    'Approve new SOW/PO': STATUS_COLOR.red,
    'Prospective': STATUS_COLOR.grey,
    'Active': STATUS_COLOR.green,
    'Unblock Project': STATUS_COLOR.red,
    'Finish or Extend Project': STATUS_COLOR.red,
    'Finished': STATUS_COLOR.grey,
    'Draft': STATUS_COLOR.grey,
    'Complete the Phase': STATUS_COLOR.red,
    'Complete the Project': STATUS_COLOR.red,
  };

  if (!statusColor[status]) {
    const warningStatuses = [
      'Approve SOW/PO',
      'Unblock Project',
      'Finish or Extend Project',
      'Complete the Phase',
      'Approve new SOW/PO',
      'Complete the Project',
    ];
    return warningStatuses.some(item => status.includes(item)) ? STATUS_COLOR.red : STATUS_COLOR.green;
  }

  return statusColor[status];
};
