import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  label: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default ({
  title, onChange, children, id, disabled,
}) => {
  const classes = useStyles();

  return (
    <form className={classes.root}>
      <input
        accept='.pdf'
        className={classes.input}
        id={`contained-button-file-${id}`}
        name='ImportFile'
        type='file'
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={`contained-button-file-${id}`} className={classes.label}>
        {children}
        {title}
      </label>
    </form>
  );
};
