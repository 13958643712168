import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import renderTextField from 'common/components/redux-form//components/text-field';
import renderInputFile from 'common/components/redux-form/components/input-type-file';
import Button from 'common/components/redux-form/components/button';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { required } from 'services/redux-form-validators.js';
import CustomIcon from 'common/components/icon/Icon';

const defaultStyles = {
  container: 'redux-form__field-container standart-container add-feedback__content-block',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

class BillingOtherModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      initialize, initData,
    } = this.props;
    initialize(initData);
  }

  componentDidUpdate() { }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  confirmJustificationModal = () => {
    const {
      confirmDialog,
      valid, formData,
    } = this.props;
    if (valid) {
      confirmDialog(formData);
    }
  }


  onChange = (fieldData, value, prevValue, field) => {
    const { change } = this.props;
    switch (field) {
      case 'files':
        change('Attachment', typeof prevValue !== 'undefined' ? [...prevValue, value] : [value]);
        break;
      default: break;
    }
  };

  render() {
    const {
      closeDialog,
      valid,
      formData,
      editMode,
    } = this.props;

    return (
      <Dialog
        open
        onClose={closeDialog}
        aria-labelledby='responsive-dialog-title'
        className='justification__modal-width'
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>{!editMode ? 'Create Other Justification' : 'Edit Other Justification'}</span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent className='justification__dialog'>
          <form className='redux-form redux-form-justification-width' onSubmit={formHandleSubmit}>
            <div key='justification-form' className='justification-modal-body'>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create'>
                <Field
                  name='Title'
                  component={renderTextField}
                  label='Title'
                  placeholder='Add Title'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Description'
                  component={renderTextField}
                  label='Description'
                  placeholder='Add Description'
                  validate={required}
                  maxlength='500'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Amount'
                  component={renderTextField}
                  label='Amount'
                  placeholder='Add Amount'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
              </div>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create'>
                <Field
                  name='Comments'
                  component={renderTextField}
                  label='Comments'
                  placeholder='Add Comments'
                  validate={required}
                  maxlength='4000'
                  showAsterix
                  fullWidth
                  multiline
                  styles={{
                    ...defaultStyles,
                    container: 'redux-form__field-container flexible-container margin-left-right-10',
                    field: 'full-width-field justification-other-full-width-field height-100pc',
                    fieldContent: 'standart-content-padding align-center height-100pc',
                    fieldView: 'field__view-container height-100pc',
                    label: 'redux-form__label justification-other-redux-form__label',
                  }}
                  inputClassName='edit-SOW__text-field justification-big-text-field'
                />
              </div>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create form__justification-file-block'>
                <Field
                  name='Attachment'
                  label='Attachment'
                  component={renderInputFile}
                  changeEditForm={this.onChange}
                  postFile={formData && formData.Attachment}
                  showAsterix
                  accept='.jpeg, .jpg, .txt, .doc, .docx, .pdf, .png'
                  formClassName='cdp-form-file'
                  styles={{
                    ...defaultStyles,
                  }}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions className='justification-modal--dialog-action'>
          <Button
            onClick={closeDialog}
            className='button cancel confirmationdialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmJustificationModal}
            className={`button ${valid ? 'next' : 'btn-disabled'} confirmationdialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

BillingOtherModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    initialize: () => dispatch(initialize('overtimeJustificationModal')),
    destroy: () => dispatch(destroy('overtimeJustificationModal')),
    change: (field, value) => dispatch(change('overtimeJustificationModal', field, value)),
  };
};

const mapStateDispatchToProps = (store) => {
  const isFormValues = store.form.otherJustificationModal && store.form.otherJustificationModal.values;
  return {
    hasError: isFormValues && store.form.otherJustificationModal.syncErrors,
    formData: isFormValues && store.form.otherJustificationModal.values,
  };
};

export default connect(
  mapStateDispatchToProps,
  mapDispatchToProps,
)(reduxForm({
  form: 'otherJustificationModal',
})(BillingOtherModal));
