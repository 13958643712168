import { SET_TABS } from '../actions/selectedTabActions';

const initialState = {
    selectedTab: 0,
}

export default function selectedTabReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TABS:
            return {
                ...state,
                selectedTab: action.payload,
            };
        default:
            return state;
    }
}
