import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export class CellLinkForRedirect extends Component {
  transition = (id, property) => {
    const { transition, item } = this.props;
    return transition(id, property, item);
  }

  getRenderValue = (value, column) => {
    if (typeof value === 'number' && column.toFixed) {
      return value.toFixed(2);
    }
    return value;
  }

  render() {
    const { item, column, transition } = this.props;
    const renderValue = item[column.Id] && (item[column.Id].Name || item[column.Id]);
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        {
          transition && item ? (
            <Link to={this.transition(item.Id || item, column.Id)}>
              <span
                className='col-span'
                style={{ WebkitBoxOrient: 'vertical' }}
              >
                <span className='item-link'>
                  {this.getRenderValue(renderValue, column)}
                </span>
              </span>
            </Link>
          ) : (
            <span
              className='col-span'
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {this.getRenderValue(renderValue, column)}
            </span>
          )
        }
      </div>
    );
  }
}

CellLinkForRedirect.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
