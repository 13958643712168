import React from 'react';
import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import isObjectNotEquals from 'utils/object-comparison';
import history from 'utils/history';
import {
  NON_BILLABLE_TRAINING,
  TIME_AND_MATERIAL,
  FINISHED,
  MONTH_AMOUNT_FOR_FIVE_YEARS,
  MILESTONE_BACKUP_USED_COLUMN_NAME,
  MILESTONE_INVOICE_AMOUNT_COLUMN_NAME,
  MILESTONE_INVOICED_DATE_COLUMN_NAME, MILESTONE_REMAINING_BALANCE_COLUMN_NAME, MILESTONE_APPROVED_COLUMN_NAME,
} from 'utils/const-variable';
import { getStatusesForRequiredComment } from 'pages/projects/components/utils/createArrayWithStatusMatch';
import { EditAssociatedRoles } from 'pages/projects/components/edit-associated-roles/edit-associated-roles';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { editRole } from 'pages/projects/actions/roleModalActions';
import { addNewPO, editCurrentPO } from 'pages/projects/actions/contractsActions';
import { getCorrectDataForEditRole } from 'services/getCorrectDataForEditRole.js';
import {
  required, validateDate, complexRequired, validateMilestone,
} from 'services/redux-form-validators.js';
import { getAllCheckedRolesAndEmployees } from 'utils/getAllCheckedRolesAndEmployees.js';

import renderTextField from '../components/text-field';
import renderSelect from '../components/select';
import renderDatePicker from '../components/date-picker';
import renderDocumentList from '../components/document-list';
import renderSearchDropdown from '../components/search-dropdown';
import renderMilestoneList from '../components/milestone-list';
import Button from '../components/button';
import { updateTheBiggestDateRange } from 'pages/projects/components/utils/getTheBiggestDateRangeForContracts';
import { getChangeConfigForPatchRequests, combinedConfigsForPatchRequests, getConfigBasedOnCheckedDates } from 'utils/getConfigBasedOnCheckedDates';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { addPoToPartialContract, cancelImport } from 'pages/projects/actions/importContractAction';

import '../redux-form.css';
import './project-edit-PO-form.css';

const defaultStyles = {
  container: 'redux-form__field-container edit-SOW__simple-container',
  label: 'redux-form__SOW--label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const validateApprover = (value, values, props, field) => {
  const condition = !(props.projectType && props.projectType.Name === NON_BILLABLE_TRAINING
    && props.SOWDetails && props.SOWDetails.Type && props.SOWDetails.Type.Name === TIME_AND_MATERIAL);
  return complexRequired(value, values, props, field, condition);
};

const validateStatusComments = (value, values, props, field) => {
  const isMatchObject = { docStatus: values.Status && values.Status.Id, approveStatus: values.ApprovalStatus && values.ApprovalStatus.Id };
  const condition = !!getStatusesForRequiredComment(props.allStatuses, props.allApprovalStatuses).find((item) => !isObjectNotEquals(item, isMatchObject));
  return complexRequired(value, values, props, field, condition);
};

const additionalConditionForContract = (role, StartDateContract, EndDateContract) => moment(role.StartDate).isSameOrAfter(StartDateContract, 'day')
      && moment(role.EndDate).isSameOrBefore(EndDateContract, 'day');

class ProjectEditPOForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      associatedRoles: [],
      showAssociatedRolesModal: false,
      isNeedToChangeDate: false,
      showEditDateConfirmDialog: false,
      messageForConfirmDialog: '',
      configPatchRequestsForConfirmDialog: null,
      importContractDatesCheck: [],
    };

    this.showAsterix = {
      Approver: () => {
        const { projectType, SOWDetails } = this.props;
        if (SOWDetails && projectType && SOWDetails.Type && SOWDetails.Type.Name) {
          return projectType.Name !== NON_BILLABLE_TRAINING || SOWDetails.Type.Name !== TIME_AND_MATERIAL;
        } return true;
      },
      StatusComments: () => {
        const {
          allStatuses, allApprovalStatuses, Status, ApprovalStatus,
        } = this.props;
        const isMatchObject = {
          docStatus: Status && Status.Id,
          approveStatus: ApprovalStatus && ApprovalStatus.Id,
        };
        return !!getStatusesForRequiredComment(allStatuses, allApprovalStatuses).find((item) => !isObjectNotEquals(item, isMatchObject));
      },
    };
    this.disable = {
      Approver: () => {
        const { Company } = this.props;
        return !Company;
      },
    };
  }

  componentDidMount() {
    const {
      data, initialize, importContract, POMain,
    } = this.props;
    initialize(data);
    const importContractDatesCheck = [];
    if (Object.keys(importContract).length) {
      const startDates = this.getStartDatesForDatePicker();
      const endDates = this.getEndDatesForDatePicker();
      const isNeedToChangeEndDate = data.EndDate && endDates.some(item => item.date && (new Date(item.date) < new Date(data.EndDate)));
      const isNeedToChangeStartDate = data.StartDate && startDates.some(item => item.date && (new Date(item.date) > new Date(data.StartDate)));
      if (isNeedToChangeEndDate) {
        const config = getConfigBasedOnCheckedDates(
          { startDatesParentContracts: this.getStartDatesForDatePicker(), endDatesParentContracts: this.getEndDatesForDatePicker() },
          this.getTheBiggestRangeChildContractsDate(),
          new Date(data.EndDate),
          POMain ? 'PO Original' : 'PO Extension',
          false,
          false,
          new Date(data.EndDate),
        );
        config && importContractDatesCheck.push(config);
      }
      if (isNeedToChangeStartDate) {
        const config = getConfigBasedOnCheckedDates(
          { startDatesParentContracts: this.getStartDatesForDatePicker(), endDatesParentContracts: this.getEndDatesForDatePicker() },
          this.getTheBiggestRangeChildContractsDate(),
          new Date(data.StartDate),
          POMain ? 'PO Original' : 'PO Extension',
          true,
          false,
          new Date(data.StartDate),
        );
        config && importContractDatesCheck.push(config);
      }
    }
    this.setState({
      importContractDatesCheck,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      configPatchRequestsForConfirmDialog, isNeedToChangeDate, importContractDatesCheck, showEditDateConfirmDialog,
    } = this.state;
    const {
      StartDate, EndDate, data: { Milestones: existMilestones }, Milestones, change, showAlertDialog,
    } = this.props;
    if (!prevProps.data.Milestones.length && JSON.stringify(prevProps.data.Milestones) !== JSON.stringify(existMilestones)) {
      change('Milestones', existMilestones);
    }
    if (importContractDatesCheck.length && !showEditDateConfirmDialog) {
      this.showEditDateConfirmDialog(importContractDatesCheck.at(-1));
      this.setState({
        importContractDatesCheck: importContractDatesCheck.slice(0, -1),
      });
    }
    if (prevProps.StartDate !== StartDate || prevProps.EndDate !== EndDate) {
      const isStartDateChanged = prevProps.StartDate !== StartDate;
      const isEndDateChanged = prevProps.EndDate !== EndDate;
      const endDateForMilestones = EndDate || moment(StartDate).clone().add(MONTH_AMOUNT_FOR_FIVE_YEARS, 'months').format();
      const milestonesOutOfRange = Milestones.filter(milestone => {
        if (!milestone.Milestone) {
          return false;
        }
        return ((isStartDateChanged
          && StartDate && !moment(moment(StartDate).startOf('month').format()).isBefore(moment(milestone.Milestone, 'MMMM, YYYY').days(15).format()))
          || (isEndDateChanged
            && endDateForMilestones
            && !moment(moment(endDateForMilestones).endOf('month').format()).isAfter(moment(milestone.Milestone, 'MMMM, YYYY').days(15).format())));
      });
      if (milestonesOutOfRange.length) {
        showAlertDialog(`${moment(milestonesOutOfRange[0].Milestone).format('MMMM YYYY')} milestone is out of range. Please, delete it first.`);
        isStartDateChanged && change('StartDate', null);
        isEndDateChanged && change('EndDate', null);
        this.setState({
          showEditDateConfirmDialog: false,
        });
      }
      if (!EndDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'endDate'),
        });
      }
      if (!StartDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'startDate'),
        });
      }
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  onChange = (data, value, prevValue, field) => {
    const { change } = this.props;

    switch (field) {
      case 'Company':
        change('Approver', '');
        break;
      default: break;
    }
  }

  checkForAssociatedRoles = () => {
    const {
      StartDate, EndDate, initial, isOnlyActiveRoles, SOWDetails, Status,
    } = this.props;
    if (initial.Status && initial.Status.Id !== Status.Id && Status.Name === FINISHED) {
      this.changeAssociatedPORoleStatus();
      return false;
    }
    if (initial.StartDate !== StartDate || initial.EndDate !== EndDate) {
      this.changeAssociatePORolesDate();
      return false;
    }
    this.editPO(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  changeAssociatedPORoleStatus = () => {
    const {
      RolesForContarct, Id, isOnlyActiveRoles, SOWDetails,
    } = this.props;
    const associatedRoles = RolesForContarct.filter(role => role.SowPoId === Id);
    const allCheckedRolesAndEmployees = getAllCheckedRolesAndEmployees(associatedRoles);
    if (allCheckedRolesAndEmployees.length) {
      this.setState({
        associatedRoles: allCheckedRolesAndEmployees,
        showAssociatedRolesModal: true,
      });
      return false;
    }
    this.editPO(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  changeAssociatePORolesDate = () => {
    const {
      RolesForContarct, Id, isOnlyActiveRoles, SOWDetails, StartDate, EndDate,
    } = this.props;
    const associatedRoles = RolesForContarct.filter(role => role.SowPoId === Id);
    const checkedRoles = associatedRoles.reduce((roles, role) => {
      const rolesWithCheckedEmployees = role.Employees.reduce((employees, employee) => {
        employees.push({
          ...employee,
          checked: employee.Status.Name !== 'Removed',
          disabledItem: employee.Status.Name === 'Removed' || !additionalConditionForContract(employee, StartDate, EndDate),
        });
        return employees;
      }, []);
      roles.push({
        ...role,
        checked: role.Status.Name !== 'Removed',
        Employees: [...rolesWithCheckedEmployees],
        disabledItem: role.Status.Name === 'Removed' || !additionalConditionForContract(role, StartDate, EndDate),
      });
      return roles;
    }, []);
    if (checkedRoles.length) {
      this.setState({
        associatedRoles: checkedRoles,
        showAssociatedRolesModal: true,
      });
      return false;
    }
    this.editPO(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  closeAssociatedRolesModal = () => {
    this.setState({ showAssociatedRolesModal: false });
  }

  applyAssociatedRoles = (roles) => {
    const {
      StartDate, EndDate, isOnlyActiveRoles, editRole, SOWDetails, Status, initial,
    } = this.props;
    if (roles.length) {
      const checkedRoles = roles.filter(role => role.checked);
      if (checkedRoles.length) {
        const startDateForUpdateRoles = !moment(StartDate).isSame(initial.StartDate) ? StartDate : null;
        const endDateForUpdateRoles = !moment(EndDate).isSame(initial.EndDate) ? EndDate : null;
        const rolesToUpdate = getCorrectDataForEditRole(checkedRoles, startDateForUpdateRoles, endDateForUpdateRoles, Status, SOWDetails.Project.Id);
        rolesToUpdate.forEach(role => editRole(role, !isOnlyActiveRoles));
      }
    }
    this.closeAssociatedRolesModal();
    this.editPO(SOWDetails.Project.Id, SOWDetails.Id, !isOnlyActiveRoles);
  }

  applyForm = () => {
    const {
      Id, isOnlyActiveRoles, SOWDetails, POMain, cancelContractImport, importContract,
    } = this.props;
    const POMainId = POMain && POMain.Id;
    Id ? this.checkForAssociatedRoles() : this.addPO(SOWDetails.Project.Id, SOWDetails.Id, POMainId, !isOnlyActiveRoles);
    Object.keys(importContract).length && cancelContractImport(SOWDetails.Project.Id);
  }

  addPO = (projectId, SOWId, POMainId, isOnlyActiveRoles) => {
    const { addPO } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    addPO(projectId, SOWId, POMainId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
    history.push({
      pathname: `/projects/${projectId}`,
    });
  }

  editPO = (projectId, SOWId, isOnlyActiveRoles) => {
    const { editPO, POMain, location } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    const saveCallback = (location.state && location.state.saveCallback) || `/projects/${projectId}`;

    editPO(
      projectId,
      SOWId,
      isOnlyActiveRoles,
      isNeedToChangeDate,
      configPatchRequestsForConfirmDialog,
      !POMain,
      saveCallback,
    );
  }

  addNewMilestone = (milestones) => {
    const { change } = this.props;
    change('Milestones', milestones);
  }

  closeEditProjectEndDateConfirmDialog = () => {
    const { change } = this.props;
    const { typeDateForDeleteIfUserCloseConfirmationDialog } = this.state;
    this.setState({
      showEditDateConfirmDialog: false,
    });
    change(typeDateForDeleteIfUserCloseConfirmationDialog, null);
  }

  showEditDateConfirmDialog = (configForConfirmDialog) => {
    const { configPatchRequestsForConfirmDialog } = this.state;
    this.setState({
      showEditDateConfirmDialog: true,
      messageForConfirmDialog: configForConfirmDialog.message,
      typeDateForDeleteIfUserCloseConfirmationDialog: configForConfirmDialog.typeChangedDate,
      configPatchRequestsForConfirmDialog:
        combinedConfigsForPatchRequests(configForConfirmDialog.configForPatchRequests, configPatchRequestsForConfirmDialog),
    });
  }

  onConfirmEditProjectEndDateDialog = () => {
    const {
      showAlertDialog, RolesForContarct, POMain, SOWDetails,
    } = this.props;
    const isRolesUnderParentContract = RolesForContarct.some((role) => role.SowPoId === SOWDetails.Id || (POMain && role.SowPoId === POMain.Id));
    this.setState({
      isNeedToChangeDate: true,
      showEditDateConfirmDialog: false,
    }, () => {
      isRolesUnderParentContract && showAlertDialog('Please note that Role and Employee dates will not be updated in parent contracts ');
    });
  }

  getStartDatesForDatePicker = () => {
    const { SOWDetails, POMain, projectStartDate } = this.props;
    return POMain ? [{ date: projectStartDate, type: 'Project', id: SOWDetails.Project.Id },
      {
        date: SOWDetails.StartDate, type: 'SOW', id: SOWDetails.Id, projectId: SOWDetails.Project.Id,
      },
      {
        date: POMain.StartDate, type: 'PO Original', id: POMain.Id, projectId: SOWDetails.Project.Id, SOWId: SOWDetails.Id,
      }]
      : [{ date: projectStartDate, type: 'Project', id: SOWDetails.Project.Id },
        {
          date: SOWDetails.StartDate, type: 'SOW', id: SOWDetails.Id, projectId: SOWDetails.Project.Id,
        },
      ];
  }

  getEndDatesForDatePicker = () => {
    const { SOWDetails, projectEndDate, POMain } = this.props;
    return POMain ? [{ date: projectEndDate, type: 'Project', id: SOWDetails.Project.Id },
      {
        date: SOWDetails.EndDate, type: 'SOW', id: SOWDetails.Id, projectId: SOWDetails.Project.Id,
      },
      {
        date: POMain.EndDate, type: 'PO Original', id: POMain.Id, projectId: SOWDetails.Project.Id, SOWId: SOWDetails.Id,
      }]
      : [{ date: projectEndDate, type: 'Project', id: SOWDetails.Project.Id },
        {
          date: SOWDetails.EndDate, type: 'SOW', id: SOWDetails.Id, projectId: SOWDetails.Project.Id,
        },
      ];
  }

  getTheBiggestRangeChildContractsDate = () => {
    const { POMain, SOWDetails, Id } = this.props;
    const childContractForPO = SOWDetails.POs.filter((po) => !POMain && po.Id !== Id);
    const theBiggestDateRange = childContractForPO.length && {
      forStartDate: { contract: childContractForPO[0], type: 'POs' },
      forEndDate: { contract: childContractForPO[0], type: 'POs' },
    };
    childContractForPO.forEach((PO) => updateTheBiggestDateRange({ contract: PO, type: 'POs' }, theBiggestDateRange));
    return theBiggestDateRange;
  }

onSaveAndCreateRoles = () => {
  const {
    addImportPo, SOWDetails, POMain, isOnlyActiveRoles,
  } = this.props;
  const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
  const POMainId = POMain && POMain.Id;
  addImportPo(SOWDetails.Project.Id, SOWDetails.Id, POMainId, !isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
}

onCancelClick = () => {
  const { cancelContractImport, SOWDetails } = this.props;
  cancelContractImport(SOWDetails.Project.Id);
}

render() {
  const {
    showAssociatedRolesModal, associatedRoles, showEditDateConfirmDialog, messageForConfirmDialog,
  } = this.state;
  const {
    disabled, allApprovalStatuses, allClients, allApprovers, allStatuses, columnsToDisplay, viewFinancialInfo, allDocumentTypes, Contract,
    ApprovalStatus, Company, Approver, BillingCode, StartDate, EndDate, Status, Links, formErrors,
    BudgetComments, handleSubmit, StatusComments, allPaymentTerms, PaymentTerm, SOWDetails, data, showAlertDialog, projectEndDate,
    Milestones, invoiceDates, Id, currencyProject, allRolesForContract, importRoles, InvoiceDate,
  } = this.props;
  const isPoOriginal = data.Type === 'Original' || !(SOWDetails && SOWDetails.POs.length);
  const currentPOStatusTitle = isPoOriginal ? 'PO Original' : 'PO Extension';
  const isSOWTypeActualTime = SOWDetails.Type && SOWDetails.Type.Name === TIME_AND_MATERIAL;
  const milestonesListColumnsToDisplay = columnsToDisplay
    .milestonesList.filter((column) => (!isSOWTypeActualTime || column.Name !== MILESTONE_BACKUP_USED_COLUMN_NAME)
    && (isPoOriginal || (
      column.Name !== MILESTONE_INVOICE_AMOUNT_COLUMN_NAME
        && column.Name !== MILESTONE_INVOICED_DATE_COLUMN_NAME
        && column.Name !== MILESTONE_REMAINING_BALANCE_COLUMN_NAME
        && column.Name !== MILESTONE_APPROVED_COLUMN_NAME)));

  return (
    <form className='redux-form height-100pc' onSubmit={handleSubmit}>
      {
        showEditDateConfirmDialog ? (
          <ConfirmationDialog
            dialogHeader='CHANGE DATE'
            dialogTitle={messageForConfirmDialog}
            closeButtonTitle='Cancel'
            confirmButtonTitle='OK'
            closeDialog={this.closeEditProjectEndDateConfirmDialog}
            confirmDialog={this.onConfirmEditProjectEndDateDialog}
          />
        ) : null
      }

      {
        showAssociatedRolesModal ? (
          <EditAssociatedRoles
            items={associatedRoles}
            closeModal={this.closeAssociatedRolesModal}
            apply={this.applyAssociatedRoles}
            startDateContract={StartDate}
            endDateContract={EndDate}
            allRoles={allRolesForContract}
            idContract={Id}
          />
        ) : null
      }
      <div>
        <div className='redux-form__row justify-start'>
          <Field
            name='Contract'
            value={Contract}
            component={renderTextField}
            label='PO Number'
            placeholder='Set PO Number'
            maxlength='100'
            styles={defaultStyles}
            disabled={disabled}
            validate={required}
            showAsterix
            formClassName='edit-SOW__item-form'
            inputClassName='edit-SOW__text-field'
          />
          <Field
            name='ApprovalStatus'
            value={ApprovalStatus}
            component={renderSelect}
            options={allApprovalStatuses}
            label='PO Approval Status'
            placeholder='Set PO Approval Status'
            styles={defaultStyles}
            disabled={disabled}
            isOptionObject
            extendedValue
            inputProps={{ id: 'po_approval_status' }}
            validate={required}
            showAsterix
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__select'
          />
          <Field
            name='BillingCode'
            value={BillingCode}
            component={renderTextField}
            label='Billing Code'
            placeholder='Set Billing Code'
            maxlength='50'
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            inputClassName='edit-SOW__text-field'
          />
        </div>
        <div className='redux-form__row justify-start'>
          <Field
            name='StartDate'
            value={StartDate}
            component={renderDatePicker}
            validate={[required, validateDate.startDate, validateDate.isValid]}
            disabled={disabled}
            showAsterix
            isStartDate
            setPrevValueOnError
            placeholder='--/--/--'
            label='PO Start Date'
            pickerName='startDatePO'
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            datePickerClassName='edit-SOW__date-picker'
            endDateContract={EndDate}
            showAlertDialog={showAlertDialog}
            endDates={this.getEndDatesForDatePicker()}
            startDates={this.getStartDatesForDatePicker()}
            typeCurrentDocument={currentPOStatusTitle}
            showConfirmationDialog={this.showEditDateConfirmDialog}
            datesForCheckChildContracts={this.getTheBiggestRangeChildContractsDate()}
            typeParent={{
              forStartDate: 'project',
              forEndDate: 'project',
            }}
            SOWId={SOWDetails.Id}
            projectId={SOWDetails.Project.Id}
          />
          <Field
            name='EndDate'
            value={EndDate}
            component={renderDatePicker}
            validate={[required, validateDate.endDate, validateDate.isValid]}
            showAsterix
            disabled={disabled}
            isEndDate
            setPrevValueOnError
            placeholder='--/--/--'
            label='PO End Date'
            pickerName='endDatePO'
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            datePickerClassName='edit-SOW__date-picker'
            endDates={this.getEndDatesForDatePicker()}
            startDates={this.getStartDatesForDatePicker()}
            datesForCheckChildContracts={this.getTheBiggestRangeChildContractsDate()}
            showAlertDialog={showAlertDialog}
            typeParent='project'
            typeCurrentDocument={currentPOStatusTitle}
            showConfirmationDialog={this.showEditDateConfirmDialog}
            SOWId={SOWDetails.Id}
            projectId={SOWDetails.Project.Id}
            startDateContract={StartDate}
          />
          {isPoOriginal && <Field
            name='InvoiceDate'
            value={InvoiceDate}
            component={renderSelect}
            options={invoiceDates}
            onChange={this.onChange}
            label='Invoice Date'
            placeholder='Set Invoice Date'
            styles={defaultStyles}
            disabled={disabled}
            isOptionObject
            extendedValue
            inputProps={{ id: 'invoice_date' }}
            validate={required}
            showAsterix
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__select'
          />}
        </div>
        <div className='redux-form__row justify-start'>
          <Field
            name='Company'
            value={Company}
            options={allClients}
            component={renderSearchDropdown}
            onChange={this.onChange}
            label='Company Name'
            placeholder='Set Company Name'
            validate={required}
            disabled={disabled}
            inputProps={{ id: 'client' }}
            showAsterix
            isAddingFeature
            styles={defaultStyles}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__search-dropdown'
          />
          <Field
            name='Approver'
            value={Approver}
            options={allApprovers.filter(approver => Company && approver.ClientId === Company)}
            component={renderSearchDropdown}
            label='Client`s PO Approver'
            placeholder='Set Client`s PO Approver'
            validate={validateApprover}
            disabled={this.disable.Approver() || disabled}
            inputProps={{ id: 'invoice_approver' }}
            showAsterix={this.showAsterix.Approver()}
            isAddingFeature
            styles={defaultStyles}
            parentEntityId={Company}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__search-dropdown'
          />
          <Field
            name='Status'
            value={Status}
            component={renderSelect}
            options={allStatuses}
            label='Document Status'
            placeholder='Set Document Status'
            styles={defaultStyles}
            disabled={disabled}
            validate={required}
            showAsterix
            isOptionObject
            extendedValue
            inputProps={{ id: 'document_status' }}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__select'
          />
          {isPoOriginal && <Field
            name='PaymentTerm'
            value={PaymentTerm}
            options={allPaymentTerms}
            component={renderSelect}
            onChange={this.onChange}
            label='Payment Term'
            placeholder='Choose Payment Term'
            styles={defaultStyles}
            disabled={disabled}
            isOptionObject
            extendedValue
            inputProps={{ id: 'payment_term' }}
            formClassName='edit-SOW__item-form'
            selectClassName='edit-SOW__select'
          />}
        </div>
        <div className='redux-form__row justify-start row__items--align-start'>
          <Field
            name='StatusComments'
            value={StatusComments}
            component={renderTextField}
            label='Status Comments'
            placeholder='Add Status Comments'
            styles={{
              ...defaultStyles,
              container: 'redux-form__field-container flexible-container margin-left-right-10 ololo',
              field: 'full-width-field height-100pc ololo1',
              fieldContent: 'standart-content-padding align-center height-100pc ololo2',
              fieldView: 'field__view-container height-100pc ololo3',
            }}
            disabled={disabled}
            showAsterix={this.showAsterix.StatusComments()}
            validate={validateStatusComments}
            maxlength='4000'
            fullWidth
            multiline
            inputClassName='edit-SOW__text-field'
          />
          {
            viewFinancialInfo
              && <Field
                name='BudgetComments'
                value={BudgetComments}
                component={renderTextField}
                label='Budget Comments'
                placeholder='Add Budget Comments'
                styles={{
                  ...defaultStyles,
                  container: 'redux-form__field-container flexible-container margin-left-right-10',
                  field: 'full-width-field height-100pc',
                  fieldContent: 'standart-content-padding align-center height-100pc',
                  fieldView: 'field__view-container height-100pc',
                }}
                disabled={disabled}
                maxlength='4000'
                fullWidth
                multiline
                inputClassName='edit-SOW__text-field'
              />
          }
        </div>
        <div className='redux-form__row justify-start'>
          {viewFinancialInfo && <Field
            name='Milestones'
            formName='projectEditPOForm'
            parentType={isPoOriginal && 'PO Original'}
            childType={isPoOriginal && 'PO Extensions'}
            value={Milestones}
            component={renderMilestoneList}
            disabled={disabled}
            onChange={this.onChange}
            onAddNewMilestone={this.addNewMilestone}
            columnsToDisplay={milestonesListColumnsToDisplay}
            viewFinancialInfo={viewFinancialInfo}
            startDate={StartDate}
            currencyProject={currencyProject}
            validate={validateMilestone}
            endDate={EndDate}
            projectEndDate={projectEndDate}
            POId={Id}
            projectId={SOWDetails.Project.Id}
            styles={{
              ...defaultStyles,
              container: 'redux-form__field-container flexible-container margin-left-right-10',
              field: 'full-width-field height-100pc',
              fieldContent: 'standart-content-padding align-center height-100pc',
              fieldView: 'field__view-container height-100pc',
            }}
          />}
        </div>
        <div className='redux-form__row justify-start'>
          {viewFinancialInfo && <Field
            name='Links'
            value={Links}
            component={renderDocumentList}
            disabled={disabled}
            columnsToDisplay={columnsToDisplay.documentsList}
            viewFinancialInfo={viewFinancialInfo}
            documentTypes={allDocumentTypes}
            isDocumentType
            styles={{
              ...defaultStyles,
              container: 'redux-form__field-container flexible-container margin-left-right-10',
              field: 'full-width-field height-100pc',
              fieldContent: 'standart-content-padding align-center height-100pc',
              fieldView: 'field__view-container height-100pc',
            }}
          />}
        </div>
      </div>
      <div className='redux-form__SOW--buttons'>
        { (importRoles && !!importRoles.length) && <Button
          onClick={this.onSaveAndCreateRoles}
          disabled={!!formErrors}
          visible={!disabled}
          className={classNames('button next button-create-roles', { 'btn-disabled': !!formErrors })}
          text='Save and Create Roles'
        /> }
        <Button
          onClick={this.onCancelClick}
          className='button cancel'
          text={disabled ? 'Back' : 'Cancel'}
        />
        <Button
          onClick={this.applyForm}
          disabled={!!formErrors}
          visible={!disabled}
          className={classNames('button next', { 'btn-disabled': !!formErrors })}
          text='Apply'
        />
      </div>
    </form>
  );
}
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('projectEditPOForm', data)),
    destroy: () => dispatch(destroy('projectEditPOForm')),
    change: (field, value) => dispatch(change('projectEditPOForm', field, value)),
    addPO: (projectId, SOWId, POMainId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => {
      return dispatch(addNewPO(projectId, SOWId, POMainId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog));
    },
    editPO: (projectId, SOWId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, isOriginal, saveCallback) => {
      return dispatch(editCurrentPO(
        projectId,
        SOWId,
        isOnlyActiveRoles,
        isNeedToChangeDate,
        configPatchRequestsForConfirmDialog,
        isOriginal,
        saveCallback,
      ));
    },
    editRole: (role, isOnlyActiveRoles) => dispatch(editRole(role, isOnlyActiveRoles)),
    showAlertDialog: (message) => dispatch(showAlertDialog(message)),
    addImportPo: (ProjectId, SOWDetailsId, POMainId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => (
      dispatch(addPoToPartialContract(ProjectId, SOWDetailsId, POMainId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog))),
    cancelContractImport: (id) => dispatch(cancelImport(id)),

  };
};

export default connect(state => {
  const isFormReducerExists = state.form.projectEditPOForm && state.form.projectEditPOForm.values;
  return {
    Id: isFormReducerExists && state.form.projectEditPOForm.values.Id,
    Contract: isFormReducerExists && state.form.projectEditPOForm.values.Contract,
    ApprovalStatus: isFormReducerExists && state.form.projectEditPOForm.values.ApprovalStatus,
    BillingCode: isFormReducerExists && state.form.projectEditPOForm.values.BillingCode,
    StartDate: isFormReducerExists && state.form.projectEditPOForm.values.StartDate,
    EndDate: isFormReducerExists && state.form.projectEditPOForm.values.EndDate,
    Company: isFormReducerExists && state.form.projectEditPOForm.values.Company,
    Approver: isFormReducerExists && state.form.projectEditPOForm.values.Approver,
    Status: isFormReducerExists && state.form.projectEditPOForm.values.Status,
    PaymentTerm: isFormReducerExists && state.form.projectEditPOForm.values.PaymentTerm,
    StatusComments: isFormReducerExists && state.form.projectEditPOForm.values.StatusComments,
    BudgetComments: isFormReducerExists && state.form.projectEditPOForm.values.BudgetComments,
    Links: isFormReducerExists && state.form.projectEditPOForm.values.Links,
    Milestones: isFormReducerExists && state.form.projectEditPOForm.values.Milestones,
    InvoiceDate: isFormReducerExists && state.form.projectEditPOForm.values.InvoiceDate,
    formErrors: isFormReducerExists && state.form.projectEditPOForm.syncErrors,
    initial: isFormReducerExists && state.form.projectEditPOForm.initial,
    isOnlyActiveRoles: state.showRoles.isOnlyActiveRoles,
    projectEndDate: state.projectsReducer.projectDetails.EndDate,
    projectStartDate: state.projectsReducer.projectDetails.StartDate,
    projectId: state.projectsReducer.projectDetails.Id,
    importRoles: state.importContractReducer.contract.Roles,
    importContract: state.importContractReducer.contract,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'projectEditPOForm',
})(ProjectEditPOForm));
