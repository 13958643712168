import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import http from 'services/http';

import {
  ADD_NEW_USER,
  GET_USER_DETAILS,
  GET_USER_OFFICES,
  GET_USERS,
  SEARCH_USERS,
  setUserDetails,
  setUserOffices,
  setUsers,
  GET_SETTINGS,
  setSettings,
  PUT_SETTINGS,
} from '../actions/adminActions';
import { showModal } from '../actions/modalActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { ENGINEERING_MANAGER_ROLE_NAME, DELIVARY_MANAGER_ROLE_NAME } from 'utils/const-variable';

export function* getAllUsers() {
  try {
    yield put(startRequest());
    const users = yield call(http, {
      method: 'GET',
      url: 'users',
    });
    yield put(setUsers(users && users.data && users.data.map(item => ({ ...item, SecurityRoles: item.SecurityRoles.map(role => role === ENGINEERING_MANAGER_ROLE_NAME ? DELIVARY_MANAGER_ROLE_NAME : role)}))));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* searchUsers(action) {
  try {
    yield put(startRequest());
    const users = yield call(http, {
      method: 'GET',
      url: `users?searchName=${encodeURIComponent(action.payload.searchValue)}`,
    });
    const sortedUsers = (
      users
      && users.data
      && Array.isArray(users.data)
      && users.data.map(item => ({ ...item, SecurityRoles: item.SecurityRoles.map(role => role === ENGINEERING_MANAGER_ROLE_NAME ? DELIVARY_MANAGER_ROLE_NAME : role).sort((curr, next) => curr > next ? 1 : curr < next ? -1 : 0) }))
    );
    yield put(setUsers(sortedUsers));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addNewUser(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'users',
      data: action.payload.user,
    });
    yield call(getAllUsers);
  } catch (e) {
    // error message
  }
}

export function* getUserDetails(action) {
  try {
    const user = yield call(http, {
      method: 'GET',
      url: `users/${action.payload}`,
    });
    yield put(setUserDetails(user.data));
    yield put(showModal(false));
  } catch (e) {
    // error message
  }
}

export function* getUserOffices(action) {
  try {
    const offices = yield call(http, {
      method: 'GET',
      url: `users/${action.payload}/offices`,
    });
    yield put(setUserOffices(offices.data || []));
  } catch (e) {
    // error message
  }
}

export function* getSettings() {
  try {
    const settings = yield call(http, {
      method: 'GET',
      url: 'settings',
    });
    yield put(setSettings(settings.data));
  } catch (e) {
    // error message
  }
}

export function* putSettings(action) {
  try {
    const settings = yield call(http, {
      method: 'PUT',
      url: `settings/${action.payload.id}`,
      data: action.payload.settings,
    });
    yield put(setSettings(settings.data));
  } catch (e) {
    // error message
  }
}

export default function* adminRootSaga() {
  yield takeEvery(GET_USERS, getAllUsers);
  yield takeLatest(SEARCH_USERS, searchUsers);
  yield takeEvery(ADD_NEW_USER, addNewUser);
  yield takeEvery(GET_USER_DETAILS, getUserDetails);
  yield takeEvery(GET_USER_OFFICES, getUserOffices);
  yield takeEvery(GET_SETTINGS, getSettings);
  yield takeEvery(PUT_SETTINGS, putSettings);
}
