export const GET_USERS = '@admin/GET_USERS';
export const SET_USERS = '@admin/SET_USERS';
export const SEARCH_USERS = '@admin/SEARCH_USERS';
export const ADD_NEW_USER = '@admin/ADD_NEW_USER';
export const GET_USER_DETAILS = '@admin/GET_USER_DETAILS';
export const SET_USER_DETAILS = '@admin/SET_USER_DETAILS';
export const GET_USER_OFFICES = '@admin/GET_USER_OFFICES';
export const SET_USER_OFFICES = '@admin/SET_USER_OFFICES';
export const SET_SETTINGS = '@admin/SET_SETTINGS';
export const GET_SETTINGS = '@admin/GET_SETTINGS';
export const PUT_SETTINGS = '@admin/PUT_SETTINGS';
export const SET_LIST_SETTINGS = '@admin/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@admin/RESET_LIST_SETTINGS';
export const RESET_USERS = '@admin/RESET_USERS';
export const SET_LIST_SETTINGS_LOOKUPS = '@lookups/SET_LIST_SETTINGS_LOOKUPS';
export const RESET_LIST_SETTINGS_LOOKUPS = '@lookups/RESET_LIST_SETTINGS_LOOKUPS';

export const getSettings = () => ({
  type: GET_SETTINGS,
});

export const setSettings = (settings) => ({
  type: SET_SETTINGS,
  payload: settings,
});

export const putSettings = (id, settings) => ({
  type: PUT_SETTINGS,
  payload: {
    id,
    settings,
  },
});

export const getUsers = () => ({
  type: GET_USERS,
});

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: {
    users,
  },
});

export const searchUsers = (searchValue) => ({
  type: SEARCH_USERS,
  payload: {
    searchValue,
  },
});

export const addNewUser = (user) => ({
  type: ADD_NEW_USER,
  payload: {
    user,
  },
});

export const getUserDetails = (userId) => ({
  type: GET_USER_DETAILS,
  payload: userId,
});

export const setUserDetails = (user) => ({
  type: SET_USER_DETAILS,
  payload: {
    user,
  },
});

export const getUserOffices = (userId) => ({
  type: GET_USER_OFFICES,
  payload: userId,
});

export const setUserOffices = (offices) => ({
  type: SET_USER_OFFICES,
  payload: offices,
});

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const resetUsers = () => ({
  type: RESET_USERS,
});

export const setListSettingsLookups = (settings) => ({
  type: SET_LIST_SETTINGS_LOOKUPS,
  payload: settings,
});

export const resetListSettingsLookups = () => ({
  type: RESET_LIST_SETTINGS_LOOKUPS,
});
