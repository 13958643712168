import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

export class CellDate extends Component {
  render() {
    const { item, column, config } = this.props;
    const format = config[column.Id].format || 'MM/DD/YYYY';
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        {moment(item[column.Id]).format(format)}
      </div>
    );
  }
}

CellDate.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
