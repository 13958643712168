import moment from 'moment';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';
import { isStringAndNotEmpty } from 'utils/isStringAndNotEmpty.js';
import { isNumber } from 'utils/isNumber.js';

const convertMoneyValue = (value) => isStringAndNotEmpty(value) || isNumber(value) ? getAmericanFormat(value) : '';

export function convertMilestones(milestones) {
  return milestones.map((item) => ({
    ...item,
    Milestone: item.Milestone ? moment(item.Milestone).format('MMMM, YYYY') : '',
    InvoicedAmount: convertMoneyValue(item.InvoicedAmount),
    MilestoneAmount: convertMoneyValue(item.MilestoneAmount),
    RemainingBalance: convertMoneyValue(item.RemainingBalance),
  }));
}
