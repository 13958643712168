export const CHANGE_PROJECT_BASIC_INFO = '@redux-form/CHANGE_PROJECT_BASIC_INFO';
export const CHANGE_OFFICE_BASIC_INFO = '@redux-form/CHANGE_OFFICE_BASIC_INFO';
export const CHANGE_PROJECT_SOW = '@redux-form/CHANGE_PROJECT_SOW';
export const CHANGE_EMPLOYEE_BASIC_INFO = '@redux-form/CHANGE_EMPLOYEE_BASIC_INFO';

export const changeProjectBasicInfo = (isOnlyActive, params) => ({
  type: CHANGE_PROJECT_BASIC_INFO,
  payload: { isOnlyActive, params },
});

export const getProjectBasicInfoFormData = state => ({
  data: state.form.projectBasicInfoForm,
});

export const changeProjectSOW = () => ({
  type: CHANGE_PROJECT_SOW,
});

export const getProjectEditSOWFormData = state => ({
  ...state.form.projectEditSOWForm,
});

export const getProjectEditPOFormData = state => ({
  ...state.form.projectEditPOForm,
});

export const changeOfficeBasicInfo = () => ({
  type: CHANGE_OFFICE_BASIC_INFO,
});

export const getOfficeBasicInfoFormData = state => ({
  data: state.form.OfficeBasicInfoForm,
});

export const changeEmployeeBasicInfo = (effectiveDate=null, year=null) => ({
  type: CHANGE_EMPLOYEE_BASIC_INFO,
  payload: {
    effectiveDate,
    year,
  },
});

export const getEmployeeBasicInfoFormData = state => ({
  data: state.form.employeeBasicInfoForm,
});

export const getProjectAmedmentFormData = state => ({
  data: state.form.projectEditAmendmentForm,
});

export const getProjectMSAFormData = state => ({
  data: state.form.projectEditMSAForm,
});
