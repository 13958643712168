export const SET_LIST_SETTINGS = '@clients/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@clients/RESET_LIST_SETTINGS';
export const GET_CLIENTS = '@clients/GET_CLIENTS';
export const SET_CLIENTS = '@clients/SET_CLIENTS';
export const GET_CLIENT_BY_ID = '@clients/GET_CLIENT_BY_ID';
export const SET_CLIENT_BY_ID = '@clients/SET_CLIENT_BY_ID';
export const PUT_CLIENT_CHANGES = '@clients/PUT_CLIENT_CHANGE';
export const FILTER_CLIENT_INFO = '@clients/FILTER_CLIENT_INFO';
export const SET_CLIENT_INFO = '@clients/SET_CLIENT_INFO';
export const ADD_NEW_CLIENT = '@clients/ADD_NEW_CLIENT';
export const ADD_CLIENT_INFO = '@clients/ADD_CLIENT_INFO';
export const GET_FILTER_CLIENTS = '@clients/GET_FILTER_CLIENTS';
export const GET_CLIENTS_NAME_ERROR = '@clients/GET_CLIENTS_NAME_ERROR';
export const SET_CLIENTS_NAME_ERROR = '@clients/SET_CLIENTS_NAME_ERROR';
export const CLEAR_ERROR_MESSAGE = '@clients/CLEAR_ERROR_MESSAGE';
export const EDIT_CONTACT_INFO = '@clients/EDIT_CONTACT_INFO';
export const TRANSITION_TO_PROJECT = '@clients/TRANSITION_TO_PROJECT';
export const SET_CHANGE_WORKING_PERIOD_RESTRICTION = '@clients/SET_CHANGE_WORKING_PERIOD_RESTRICTION';
export const POST_WORKING_PERIOD_RESTRICTION = '@clients/POST_WORKING_PERIOD_RESTRICTION';
export const PUT_WORKING_PERIOD_RESTRICTION = '@clients/PUT_WORKING_PERIOD_RESTRICTION';
export const DELETE_WORKING_PERIOD_RESTRICTION = '@clients/DELETE_WORKING_PERIOD_RESTRICTION';
export const GET_SOW_TYPES = '@clients/GET_SOW_TYPES';
export const SET_SOW_TYPES = '@clients/SET_SOW_TYPES';

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const getClients = () => ({
  type: GET_CLIENTS,
});

export const setClients = (clients) => ({
  type: SET_CLIENTS,
  payload: {
    clients: clients,
  },
});

export const getClientById = (Id) => ({
  type: GET_CLIENT_BY_ID,
  payload: {
    Id,
  },
});

export const setClientById = (client) => ({
  type: SET_CLIENT_BY_ID,
  payload: {
    client,
  },
});

export const putClient = (client, property) => ({
  type: PUT_CLIENT_CHANGES,
  payload: {
    client,
    property,
  },
});

export const filterClientInfo = (id, filters = {}) => ({
  type: FILTER_CLIENT_INFO,
  payload: {
    id: id,
    filters,
  },
});

export const setClientsInfo = (filterInfo) => ({
  type: SET_CLIENT_INFO,
  payload: {
    filterInfo,
  },
});

export const addNewClient = (client, location) => ({
  type: ADD_NEW_CLIENT,
  payload: { client, location },
});

export const addClientInfo = (clientInfo) => ({
  type: ADD_CLIENT_INFO,
  payload: clientInfo,
});

export const searchClients = (searchValue, sortSettings) => ({
  type: GET_FILTER_CLIENTS,
  payload: { searchValue, sortSettings },
});

export const setError = (error) => ({
  type: SET_CLIENTS_NAME_ERROR,
  payload: error,
});

export const getError = () => ({
  type: SET_CLIENTS_NAME_ERROR,
});

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR_MESSAGE,
});

export const editContactInfo = (contactInfo) => ({
  type: EDIT_CONTACT_INFO,
  payload: contactInfo,
});

export const transitionToProjectData = (id, path) => ({
  type: TRANSITION_TO_PROJECT,
  payload: {
    id,
    path,
  },
});

export const setChangeWorkingPeriodRestriction = (clientId, value) => ({
  type: SET_CHANGE_WORKING_PERIOD_RESTRICTION,
  payload: {
    clientId,
    value,
  },
});

export const createWorkingPeriodRestriction = (clientId, workingPeriodRestriction) => ({
  type: POST_WORKING_PERIOD_RESTRICTION,
  payload: {
    clientId,
    workingPeriodRestriction,
  },
});

export const updateWorkingPeriodRestriction = (clientId, workingPeriodRestriction) => ({
  type: PUT_WORKING_PERIOD_RESTRICTION,
  payload: {
    clientId,
    workingPeriodRestriction,
  },
});

export const deleteWorkingPeriodRestriction = (clientId, id) => ({
  type: DELETE_WORKING_PERIOD_RESTRICTION,
  payload: {
    clientId,
    id,
  },
});

export const getSowTypes = () => ({
  type: GET_SOW_TYPES,
});

export const setSowTypes = (sowTypes) => ({
  type: SET_SOW_TYPES,
  payload: sowTypes,
});
