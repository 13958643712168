import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_API_TOKENS,
  PUT_MANAGE_API_TOKENS,
  POST_MANAGE_API_TOKENS,
  setManageApiTokens,
} from '../actions/adminManageApiTokens';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageApiTokens() {
  try {
    yield put(startRequest());
    const apiTokens = yield call(http, {
      method: 'GET',
      url: 'auth/api_tokens',
    });
    yield put(setManageApiTokens(apiTokens.data));
  } catch (e) {
    // error message
  } finally {
    yield put(finishRequest());
  }
}

export function* putApiToken(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'auth/api_tokens',
      data: action.payload,
    });
    yield call(manageApiTokens);
  } catch (e) {
    // error message
  }
}

export function* postApiToken(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: 'auth/api_tokens',
      data: action.payload,
    });
    yield call(manageApiTokens);
  } catch (e) {
    // error message
  }
}

export default function* adminManageApiTokens() {
  yield takeEvery(GET_MANAGE_API_TOKENS, manageApiTokens);
  yield takeEvery(PUT_MANAGE_API_TOKENS, postApiToken);
  yield takeEvery(POST_MANAGE_API_TOKENS, putApiToken);
}
