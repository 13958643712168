import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import {
  closeModal, editRole, addRoleToProject, showModal, getEmployees, getStatus, getEmployeeStatus, setBillingData,
} from '../../actions/roleModalActions';
import classNames from 'classnames';
import history from 'utils/history';
import Select from 'common/components/form-items/select/select';
import TextField from 'common/components/form-items/input/text-field';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import InputCurrency from 'common/components/form-items/input-currency/input-currency';
import { sortFilterList } from 'common/components/list/sortList';
import './role-modal.css';
import { getProjectDetails } from '../../actions/projectsActions';
import {
  PROJECT_ROLES_EMPLOYEES_PRIMARY,
  PROJECT_ROLES_EMPLOYEES_DISABLED,
  PROJECT_ROLES_EMPLOYEES_FORMER,
  DEFAULT_EMPLOYEE_ASSIGNMENTS,
  PROJECT_ROLES_STATUS_ACTIVE,
} from 'utils/const-variable';
import { sortRoleEmployees } from 'utils/sortRoleEmployees';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';
import AddEmployee from './add-employee/add-employee';
import sortArrayByPriority from 'utils/sortArrayByPriority';
import { isCorrectRoleAssignment } from '../utils/isCorrectRoleAssignments';
import { getTheBiggestDateForRoles, getTheSmallestDateForRoles } from '../utils/getTheBiggestDateRangeForRoles';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import formatCurrency from 'utils/formatCurrency';

const billable = 'Billable';
// eslint-disable-next-line no-useless-escape
const reqularExpRate = /^((?!0)|(0\.))\d{1,9}(\,\d{1,9}){0,3}?(\.\d{1,5})?$/;

export class EditRoleModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      currentRoles, projectId, roleStatus, roleEmployeeStatus, billingCodes, rateUnits,
      ManageProjectRoles, HasFinancialAccess, ManageProjectRoleAssignments,
    } = this.props;
    this.state = {
      ItemId: (currentRoles.length > 0 ? currentRoles[currentRoles.length - 1].ItemId : -1),
      projectId,
      RoleName: '',
      RoleId: '',
      RoleType: '',
      SeniorityId: '',
      SeniorityLevel: '',
      BillableStatusId: '',
      BillableStatus: '',
      Rate: '',
      OvertimeRate: '',
      AssignmentTypeId: 1,
      AssignmentType: 'Offshore',
      Comment: '',
      Assignment: 1,
      StartDate: null,
      EndDate: null,
      BillingCodeId: '',
      BillingCode: '',
      PrimaryAssignmentId: '',
      SendPrimaryEmployeeId: '',
      PrimaryEmployeeName: '',
      StatusId: '',
      Status: '',
      roleType: PROJECT_ROLES_EMPLOYEES_PRIMARY,
      Employees: [],
      isModalFieldsRequired: false,
      assignmentIsUnvalid: false,
      employeeAssignmentIsUnvalid: false,
      employeeNameError: false,
      roleStatus: roleStatus || [],
      roleEmployeeStatus: roleEmployeeStatus || [],
      billingCodes: billingCodes || [],
      showEditAssignmentModal: false,
      editAssignment: {},
      isDialogOpened: false,
      actionData: {},
      showConfirm: false,
      showConfirmStatus: false,
      invalidRateData: false,
      invalidOverRateData: false,
      rateUnit: rateUnits && rateUnits.length ? rateUnits[0].Id : '',
      overtimeRateUnit: rateUnits && rateUnits.length ? rateUnits[0].Id : '',
      isEmployeeListValid: true,
      haveSameEmployees: false,
      ManageProjectRoles,
      HasFinancialAccess,
      ManageProjectRoleAssignments,
      isLateInit: false,
    };
  }

  componentDidMount() {
    const {
      editRoles, location, setComponent, getProjectDetails, isOnlyActive,
    } = this.props;
    const projectId = location.pathname.split('/')[2];
    if (editRoles.Name) {
      this.init();
    } else if (projectId) {
      this.setState({ isLateInit: true });
      getProjectDetails(projectId, isOnlyActive);
    } else history.goBack({ state: location.state });
    setComponent({ title: 'EDIT ROLE' });
  }

  componentDidUpdate(prevProps) {
    const {
      editRoles, roleStatus, roleEmployeeStatus, isLoading, billingCodes: billingCodesProps,
    } = this.props;
    const { isLateInit, billingCodes } = this.state;
    if (editRoles.Name && isLateInit && !isLoading) {
      this.init();
    }
    if (prevProps.roleStatus !== roleStatus || prevProps.roleEmployeeStatus !== roleEmployeeStatus) {
      this.setState({
        roleStatus,
        roleEmployeeStatus,
      }, () => this.requireFields());
    }
    if (billingCodesProps.length !== billingCodes.length) {
      this.setState({ billingCodes: billingCodesProps });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  init = () => {
    const {
      assignmentTypes, roleStatus, roleEmployeeStatus, getStatus, getEmployeeStatus, projectDetails: { Contracts }, setBillingData,
    } = this.props;
    const { isLateInit } = this.state;
    this.findArrValue(assignmentTypes);
    if (isLateInit) {
      getStatus();
      const billingCodes = [],
        billingCodesStartDate = [],
        billingCodesEndDate = [];
      Contracts.forEach((SOW) => {
        billingCodes.push(SOW.Contract);
        billingCodesStartDate.push(SOW.StartDate);
        billingCodesEndDate.push(SOW.EndDate);
        SOW.POs.forEach((PO) => {
          billingCodes.push(PO.Contract);
          billingCodesStartDate.push(PO.StartDate);
          billingCodesEndDate.push(PO.EndDate);
        });
        SOW.Amendments.forEach((Amendment) => {
          billingCodes.push(Amendment.Contract);
          billingCodesStartDate.push(Amendment.StartDate);
          billingCodesEndDate.push(Amendment.EndDate);
        });
      });
      setBillingData(billingCodes, billingCodesStartDate, billingCodesEndDate);
    }
    getEmployeeStatus();
    this.getRoleEditDataInputs();
    this.setState({
      roleStatus,
      roleEmployeeStatus,
    }, () => this.requireFields());
    this.setState({ isLateInit: false });
  }

  getEmployeeDataModel = (employee, backups) => {
    return {
      AssignmentKey: employee.Id,
      EmployeeId: employee.EmployeeId,
      EmployeeName: employee.Name,
      EmployeeAssignment: employee.Assignment,
      EmployeeStartDate: employee.StartDate,
      EmployeeEndDate: employee.EndDate,
      EmployeeStatusId: employee.Status && (employee.Status.Id || employee.StatusId),
      EmployeeStatus: employee.Status && (employee.Status.Name || employee.Status),
      EmployeeRole: employee.EmployeeRole || employee.Role,
      EmployeeComment: employee.Comment,
      EmployeeOffice: employee.Office && employee.Office.Name,
      primaryAssignmentId: employee.PrimaryAssignmentId,
      isValidEmployee: true,
      backupEmployees: backups,
      createdOnFrontSide: false,
    };
  }

  getRoleEditDataInputs = () => {
    const {
      roleStatus, roleEmployeeStatus, billingCodes, editRoles, location, isLoading,
    } = this.props;
    const { billingCodes: { length } } = this.state;
    if (editRoles !== null) {
      const currentRole = editRoles.AllRoles.find(role => { return role.Id === location.pathname.split('/')[5]; });
      const assignedEmployees = sortRoleEmployees(currentRole.Employees).reduce((assignedEmployees, employee, index, employees) => {
        if (!employee.PrimaryAssignmentId) {
          const backups = employees.reduce((backups, backup) => {
            backup.PrimaryAssignmentId === employee.Id && backups.push(this.getEmployeeDataModel(backup, []));
            return backups;
          }, []);
          assignedEmployees.push(this.getEmployeeDataModel(employee, backups));
        }
        return assignedEmployees;
      }, []);

      this.setState({
        RoleName: currentRole.RoleName || '',
        ItemId: currentRole.Id,
        RoleId: currentRole.Role.Id,
        RoleType: currentRole.Role.Name,
        SeniorityId: currentRole.SeniorityLevel.Id,
        SeniorityLevel: currentRole.SeniorityLevel.Name,
        BillableStatusId: currentRole.BillableStatus.Id,
        BillableStatus: currentRole.BillableStatus.Name,
        Rate: currentRole.Rate && getAmericanFormat(currentRole.Rate),
        OvertimeRate: currentRole.RateOvertime && (getAmericanFormat(currentRole.RateOvertime) || ''),
        AssignmentTypeId: currentRole.AssignmentType.Id,
        AssignmentType: currentRole.AssignmentType.Name,
        Assignment: currentRole.Assignment,
        StartDate: currentRole.StartDate,
        EndDate: currentRole.EndDate,
        BillingCodeId: currentRole.SowPo,
        BillingCode: currentRole.SowPo,
        PrimaryAssignmentId: '',
        SendPrimaryEmployeeId: '',
        PrimaryEmployeeName: '',
        StatusId: currentRole.Status.Id,
        Status: currentRole.Status.Name,
        roleType: PROJECT_ROLES_EMPLOYEES_PRIMARY,
        Employees: assignedEmployees,
        Comment: currentRole.Comment,
        roleStatus,
        roleEmployeeStatus,
        billingCodes,
        rateUnit: currentRole.RateUnit.Id,
        overtimeRateUnit: currentRole.OvertimeRateUnit.Id,
      }, () => {
        this.requireFields();
      });
    } else {
      if (length === 0 && !isLoading) {
        const currentProjectId = location.pathname.split('/')[2];
        history.push({
          pathname: `/projects/${currentProjectId}`,
          state: location.state,
        });
      }
    }
  }

  findArrValue = (arr) => {
    arr.forEach(item => {
      if (item.Name === 'Offshore') {
        this.setState({
          AssignmentTypeId: item.Id,
          AssignmentType: item.Name,
        });
      }
    });
  }

  sortArrays = (array) => {
    return array.sort((a, b) => {
      if (a.Name > b.Name) {
        return 1;
      }
      if (a.Name === b.Name) {
        return 0;
      }
      return -1;
    });
  }

  requireFields = () => {
    const {
      RoleName, RoleId, SeniorityId, BillableStatusId, BillableStatus, Rate, AssignmentTypeId, assignmentIsUnvalid,
      StartDate, EndDate, BillingCodeId, Status, invalidRateData, invalidOverRateData,
    } = this.state;
    const { HasFinancialAccess } = this.props;
    (!!RoleName && RoleId !== '' && SeniorityId !== '' && BillableStatusId !== ''
      && (BillableStatus === billable && HasFinancialAccess ? Rate : true)
      && AssignmentTypeId !== '' && !assignmentIsUnvalid && moment(StartDate).isAfter(EndDate) === false
      && StartDate !== null && EndDate !== null && (BillableStatus === billable ? !!BillingCodeId : true)
      && Status !== '' && !invalidRateData && !invalidOverRateData) ? (
        this.setState({
          isModalFieldsRequired: true,
        })
      ) : (
        this.setState({
          isModalFieldsRequired: false,
          EmployeeStartDate: null,
          EmployeeEndDate: null,
          EmployeeAssignment: '',
        })
      );
  }

  onChangeRoleName = (event) => {
    this.setState({
      RoleName: event.target.value,
    }, () => this.requireFields());
  }

  onChangeRole = ({ item }) => {
    this.setState({
      RoleId: item.Id,
      RoleType: item.Name,
    }, () => this.requireFields());
  }

  onChangeSeniority = ({ item }) => {
    this.setState({
      SeniorityId: item.Id,
      SeniorityLevel: item.Name,
    }, () => this.requireFields());
  }

  onChangeComment = (event) => {
    this.setState({
      Comment: event.target.value,
    }, () => this.requireFields());
  }

  onChangeBillable = ({ item }) => {
    this.setState({
      BillableStatusId: item.Id,
      BillableStatus: item.Name,
    }, () => this.requireFields());
  }

  onChangeRate = (e) => {
    if ((e.target.value.length && !reqularExpRate.test(e.target.value)) || (parseFloat(e.target.value) === 0)) {
      this.setState({
        invalidRateData: true,
      });
    } else {
      this.setState({
        invalidRateData: false,
      });
    }
    this.setState({
      Rate: e.target.value,
    }, () => this.requireFields());
  }

  onChangeOvertimeRate = (e) => {
    if ((e.target.value.length && !reqularExpRate.test(e.target.value)) || (parseFloat(e.target.value) === 0)) {
      this.setState({
        invalidOverRateData: true,
      });
    } else {
      this.setState({
        invalidOverRateData: false,
      });
    }
    this.setState({
      OvertimeRate: e.target.value,
    }, () => this.requireFields());
  }

  onChangeAssignmentType = ({ item }) => {
    this.setState({
      AssignmentTypeId: item.Id,
      AssignmentType: item.Name,
    }, () => this.requireFields());
  }

  onChangeAssignment = (e) => {
    if (isCorrectRoleAssignment(e.target.value)) {
      this.setState({
        assignmentIsUnvalid: false,
      });
    } else {
      this.setState({
        assignmentIsUnvalid: true,
      });
    }
    this.setState({
      Assignment: e.target.value,
    }, () => this.requireFields());
  }

  onChangeBillingCode = ({ target }) => {
    const { projectDetails } = this.props;
    projectDetails && projectDetails.Contracts.forEach((contract) => {
      if (contract.Contract === target.value && contract.Status.Name !== PROJECT_ROLES_STATUS_ACTIVE) {
        this.toogleDialog();
      }
      if (contract.POs.length) {
        contract.POs.forEach((PO) => {
          if (PO.Contract === target.value && PO.Status.Name !== PROJECT_ROLES_STATUS_ACTIVE) {
            this.toogleDialog();
          }
        });
      }
    });
    this.setState({
      BillingCodeId: target.value,
      BillingCode: target.value,
    }, () => this.requireFields());
  }

  toogleDialog = () => {
    const { showConfirm } = this.state;
    this.setState({
      showConfirm: !showConfirm,
    });
  }

  handleStatusDialog = () => {
    const { showConfirmStatus } = this.state;
    this.setState({
      showConfirmStatus: !showConfirmStatus,
    });
  }

  handleStartDateChange = date => {
    const { BillingCodeId, Employees } = this.state;
    const {
      projectDetails: { StartDate },
      showAlertDialog,
      editRoles,
      contracts,
      location,
    } = this.props;

    const billingIsSelected = !!BillingCodeId;
    const currentRole = editRoles.AllRoles.find(role => { return role.Id === location.pathname.split('/')[5]; });
    const sowForRole = contracts.find((item) => item.Id === currentRole.SowPoId);
    const parentForRole = contracts.reduce((acc, item) => {
      if (!acc && item.Id === currentRole.SowPoId) {
        acc = item;
      }
      const havePOForRole = item.POs.find((po) => po.Id === currentRole.SowPoId);
      const haveAmendmentForRole = item.Amendments.find((amendment) => amendment.Id === currentRole.SowPoId);
      if (!acc && havePOForRole) {
        acc = havePOForRole;
      }
      if (!acc && haveAmendmentForRole) {
        acc = haveAmendmentForRole;
      }
      return acc;
    }, null);
    const theSmallestDate = getTheSmallestDateForRoles(Employees);
    if (!billingIsSelected && moment(StartDate).isAfter(date)) {
      return showAlertDialog('Does the date less than the Project start date?');
    }
    if (parentForRole && parentForRole.StartDate && moment(parentForRole.StartDate).isAfter(date)) {
      return showAlertDialog(`Does the date less than the ${sowForRole ? 'SOW'
        : 'PO'} start date, please change ${sowForRole ? 'SOW' : 'PO'} start date.`);
    }
    if (parentForRole && parentForRole.EndDate && moment(parentForRole.EndDate).isBefore(date)) {
      return showAlertDialog(`Does the date less than the ${sowForRole ? 'SOW'
        : 'PO'} end date, please change ${sowForRole ? 'SOW' : 'PO'} end date.`);
    }
    if (theSmallestDate && theSmallestDate.isValid() && moment(theSmallestDate.format('YYYY-MM-DD')).isBefore(moment(date).format('YYYY-MM-DD'))) {
      return showAlertDialog('Does the date less than the assign start date, please change assign start date.');
    }

    this.setState({
      StartDate: date,
    }, () => this.requireFields());
  };

  handleEndDateChange = date => {
    const { BillingCodeId, Employees } = this.state;
    const {
      projectDetails: { EndDate },
      showAlertDialog,
      billingCodesEndDate,
      editRoles,
      billingCodes,
      contracts,
      location,
    } = this.props;

    const billingIsSelected = !!BillingCodeId;
    const billingCodeEndDate = billingCodesEndDate[billingCodes.indexOf(BillingCodeId)];
    const currentRole = editRoles.AllRoles.find(role => { return role.Id === location.pathname.split('/')[5]; });
    const sowForRole = contracts.find((item) => item.Id === currentRole.SowPoId);
    const endDateForRole = contracts.reduce((acc, item) => {
      if (!acc && item.Id === currentRole.SowPoId) {
        acc = item.EndDate;
      }
      const havePOForRole = item.POs.find((po) => po.Id === currentRole.SowPoId);
      const haveAmendmentForRole = item.Amendments.find((amendment) => amendment.Id === currentRole.SowPoId);
      if (!acc && havePOForRole) {
        acc = havePOForRole.EndDate;
      }
      if (!acc && haveAmendmentForRole) {
        acc = haveAmendmentForRole.EndDate;
      }
      return acc;
    }, null);
    const theBiggestDate = getTheBiggestDateForRoles(Employees);
    if (!billingIsSelected && moment(date).isAfter(EndDate)) {
      return showAlertDialog('Does the date exceed the Project end date?');
    }
    if (billingIsSelected && billingCodeEndDate && moment(date).isAfter(endDateForRole)) {
      return showAlertDialog(`Does the date exceed the ${sowForRole ? 'SOW' : 'PO'} end date, please change ${sowForRole ? 'SOW' : 'PO'} end date.`);
    }
    if (billingIsSelected && !billingCodeEndDate && moment(date).isAfter(EndDate)) {
      return showAlertDialog('Does the date exceed the Project end date? (SOW/PO doesn`t have end date)');
    }
    if (theBiggestDate && theBiggestDate.isValid() && moment(moment(date).format('YYYY-MM-DD')).isBefore(moment(theBiggestDate.format('YYYY-MM-DD')))) {
      return showAlertDialog('Does the date exceed the assign end date, please change assign end date.');
    }

    this.setState({
      EndDate: date,
    }, () => this.requireFields());
  };

  onChangeStatus = ({ item }) => {
    this.setState({
      Status: item.Name,
      StatusId: item.Id,
    }, () => this.requireFields());
  }

  onChangeRateUnit = ({ item }) => {
    this.setState({
      rateUnit: item.Id,
    }, () => this.requireFields());
  }

  onChangeOvertimeRateUnit = ({ item }) => {
    this.setState({
      overtimeRateUnit: item.Id,
    }, () => this.requireFields());
  }

  clearStatus = () => {
    this.setState({
      Status: '',
      StatusId: '',
    }, () => this.requireFields());
  }

  clearValue = (property) => {
    if (property === 'BillingCodeId') {
      this.setState({
        [property]: '',
        BillingCode: '',
      }, () => this.requireFields());
      return;
    }
    if (property === 'Rate') {
      this.setState({
        [property]: '',
        invalidRateData: '',
      }, () => this.requireFields());
      return;
    }
    if (property === 'OvertimeRate') {
      this.setState({
        [property]: '',
        invalidOverRateData: '',
      }, () => this.requireFields());
      return;
    }
    this.setState({
      [property]: '',
    }, () => this.requireFields());
  };

  checkEmployeeListValid = (isEmployeeListValid, Employees) => {
    this.setState({
      isEmployeeListValid,
      Employees,
    });
  }

  getFlatEmployeeList = (employees) => {
    const flatEmployeesArray = employees.reduce((flatArray, primary) => {
      !!primary.EmployeeId && flatArray.push(primary);
      primary.backupEmployees.forEach(backup => !!backup.EmployeeId && flatArray.push(backup));
      return flatArray;
    }, []);
    return flatEmployeesArray;
  }

  applyModal = () => () => {
    const { editRole, isOnlyActive, location } = this.props;
    const { isEmployeeListValid, isModalFieldsRequired } = this.state;
    const state = { ...this.state };
    const currentProjectId = location.pathname.split('/')[2];
    if (isEmployeeListValid && isModalFieldsRequired) {
      editRole(state, !isOnlyActive);
      history.push({
        pathname: `/projects/${currentProjectId}`,
        state: location.state,
      });
    }
  }

  cancelRolePage = () => () => {
    const { location } = this.props;
    const currentProjectId = location.pathname.split('/')[2];
    history.push({
      pathname: `/projects/${currentProjectId}`,
      state: location.state,
    });
  }

  getRoleTitle = () => {
    const titleLocation = history.location.pathname.split('/')[4];
    return titleLocation === 'edit' ? 'editRole' : 'viewRole';
  }

  getCurrency = () => {
    const { projectDetails } = this.props;
    const currencyProject = projectDetails && projectDetails.Currency && projectDetails.Currency.Name;
    return formatCurrency(currencyProject);
  }

  getAssignerEmployeesToProject = () => {
    const { projectDetails } = this.props;
    const employees = [];
    if (projectDetails.Roles) {
      for (const role of projectDetails.Roles) {
        employees.push.apply(employees, role.Employees);
      }
    }
    return employees;
  }

  render() {
    const {
      showConfirm, RoleId, isModalFieldsRequired, SeniorityId, isEmployeeListValid,
      BillableStatusId, Rate, BillableStatus, OvertimeRate, AssignmentTypeId, assignmentIsUnvalid,
      Assignment, BillingCodeId, billingCodes, StartDate, EndDate, StatusId, roleStatus, Status,
      Employees, RoleName, invalidOverRateData, invalidRateData, rateUnit, overtimeRateUnit, Comment,
      ManageProjectRoles, HasFinancialAccess, ManageProjectRoleAssignments, showConfirmStatus,
    } = this.state;
    const {
      roles, roleModalAction, seniorityLevels, billableStatuses, assignmentTypes, rateUnits, employees, projectTeams, projectDetails,
    } = this.props;
    const suitableEmployees = employees.filter(employee => {
      return employee.Type !== PROJECT_ROLES_EMPLOYEES_DISABLED
        && employee.Type !== PROJECT_ROLES_EMPLOYEES_FORMER;
    });
    const assignedEmployeesToProject = this.getAssignerEmployeesToProject();
    return (
      <div className='page-container page-block'>
        <DocumentTitle title={applicationTitle.getTitile(this.getRoleTitle())} />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          {showConfirm ? (
            <AlertDialog
              dialogHeader='Warning!'
              dialogTitle='Selected contract doesnt have active status. Are you sure ?'
              closeDialog={this.toogleDialog}
              confirmDialog={this.toogleDialog}
              confirmButtonTitle='OK'
            />
          ) : null}
          {showConfirmStatus ? (
            <AlertDialog
              dialogHeader='Warning!'
              dialogTitle='If you change the status of an assignment, it will be removed from teams.'
              closeDialog={this.handleStatusDialog}
              confirmDialog={this.handleStatusDialog}
              confirmButtonTitle='OK'
            />
          ) : null}
          <div className='form-block'>
            <div className='step__row'>
              <TextField
                formClassName='role-page-project__form margin-right-50'
                inputClassName='c-SOW-edit__select'
                id='RoleName'
                label='Role Name'
                placeholder='Role Name'
                name='RoleName'
                maxlength='50'
                value={RoleName}
                onChange={this.onChangeRoleName}
                disabled={!ManageProjectRoles}
                isRequired
                clearValue={() => this.clearValue('RoleName')}
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50 role-page__role-type-svg--margin'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Role Type'
                placeholder='Choose Type'
                value={RoleId.toString()}
                onChange={this.onChangeRole}
                selectOptions={this.sortArrays(roles)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Role',
                  id: 'role',
                }}
                isRequired
                disabled={!ManageProjectRoles}
                clearValue={() => this.clearValue('RoleId')}
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50 role-page__role-type-svg--margin'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Seniority Level'
                placeholder='Choose Level'
                value={SeniorityId.toString()}
                onChange={this.onChangeSeniority}
                selectOptions={sortArrayByPriority(seniorityLevels)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Seniority Level',
                  id: 'client',
                }}
                isRequired
                disabled={!ManageProjectRoles}
                clearValue={() => this.clearValue('SeniorityId')}
              />
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Billable Status'
                placeholder='Choose Status'
                value={BillableStatusId.toString()}
                onChange={this.onChangeBillable}
                selectOptions={billableStatuses}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Client',
                  id: 'client',
                }}
                isRequired
                disabled={!ManageProjectRoles}
                clearValue={() => this.clearValue('BillableStatusId')}
              />
            </div>
            <div className='step__row margin-up-role'>
              {
                HasFinancialAccess && (
                  <div>
                    <InputCurrency
                      formClassName='role-page-project__form-150 margin-right-50'
                      inputClassName='c-SOW-edit__input'
                      id='rate'
                      label='Rate'
                      placeholder='0'
                      maxlength='16'
                      value={Rate}
                      onChange={this.onChangeRate}
                      startAdornment={this.getCurrency()}
                      isRequired={BillableStatus === billable}
                      disabled={!ManageProjectRoles}
                      clearValue={() => this.clearValue('Rate')}
                    />
                    {invalidRateData ? <p className='project-input-error project-input-error-margin'>Invalid Rate</p> : null}
                  </div>
                )
              }
              {
                HasFinancialAccess && (
                  <Select
                    formClassName='role-page-project__form-150 margin-right-50'
                    selectClassName='c-SOW-edit__select'
                    optionsClassName='option'
                    label='Rate Unit'
                    placeholder='Choose Rate Unit'
                    value={rateUnit}
                    onChange={this.onChangeRateUnit}
                    selectOptions={rateUnits ? rateUnits : []}
                    disabled={!ManageProjectRoles}
                    isOptionObject
                    extendedValue
                    inputProps={{
                      name: 'rateUnit',
                      id: 'rateUnit',
                    }}
                    isRequired
                  />
                )
              }
              {
                HasFinancialAccess && (
                  <div>
                    <InputCurrency
                      formClassName='role-page-project__form-150 margin-right-50'
                      inputClassName='c-SOW-edit__input'
                      id='rate'
                      label='Overtime Rate'
                      placeholder='0'
                      maxlength='16'
                      value={OvertimeRate}
                      onChange={this.onChangeOvertimeRate}
                      startAdornment={this.getCurrency()}
                      disabled={!ManageProjectRoles}
                      clearValue={() => this.clearValue('OvertimeRate')}
                    />
                    {invalidOverRateData ? <p className='project-input-error project-input-error-margin'>Invalid Rate</p> : null}
                  </div>
                )
              }
              {
                HasFinancialAccess && (
                  <Select
                    formClassName='role-page-project__form-150 margin-right-50'
                    selectClassName='c-SOW-edit__select'
                    optionsClassName='option'
                    label='Overtime Rate Unit'
                    placeholder='Choose Overtime Rate Unit'
                    value={overtimeRateUnit}
                    onChange={this.onChangeOvertimeRateUnit}
                    selectOptions={rateUnits ? rateUnits : []}
                    disabled={!ManageProjectRoles}
                    isOptionObject
                    extendedValue
                    inputProps={{
                      name: 'overtimeRateUnit',
                      id: 'overtimeRateUnit',
                    }}
                    isRequired
                  />
                )
              }
              <Select
                formClassName='role-page-project__form-150 margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Assignment Type'
                placeholder='Choose Type'
                value={AssignmentTypeId.toString()}
                onChange={this.onChangeAssignmentType}
                selectOptions={this.sortArrays(assignmentTypes)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Assignment Type',
                  id: 'assignmentType',
                }}
                isRequired
                disabled={!ManageProjectRoles}
                clearValue={() => this.clearValue('AssignmentTypeId')}
              />
              <TextField
                formClassName='role-page-project__form-150 margin-right-50'
                inputClassName='c-role-modal__input'
                id='assignment'
                label='Assignment'
                type='number'
                placeholder='0'
                isRequired
                error={assignmentIsUnvalid}
                value={String(Assignment)}
                onChange={this.onChangeAssignment}
                disabled={!ManageProjectRoles}
                clearValue={() => this.clearValue('Assignment')}
              />
            </div>
            <div className='step__row margin-up-role'>
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='SOW/PO Number'
                placeholder='Choose SoW Id/PO Number'
                value={BillingCodeId}
                onChange={this.onChangeBillingCode}
                selectOptions={sortFilterList(billingCodes)}
                isOptionObject={false}
                extendedValue
                inputProps={{
                  name: 'SOW/PO Number',
                  id: 'SOW/PONumber',
                }}
                isRequired={BillableStatus === billable}
                disabled={!ManageProjectRoles}
                clearValue={() => this.clearValue('BillingCodeId')}
              />
              <DatePicker
                formClassName='role-page-project__form-150 margin-right-50'
                datePickerClassName='c-role-modal__date-picker'
                placeholder='--/--/--'
                label='Start Date'
                value={moment(StartDate)}
                onChange={this.handleStartDateChange}
                isRequired
                disabled={!ManageProjectRoles}
                name='startDate'
                isStartDate
              />
              <DatePicker
                formClassName='role-page-project__form-150 margin-right-50'
                datePickerClassName='c-role-modal__date-picker'
                placeholder='--/--/--'
                label='End Date'
                minVal={moment(StartDate)}
                disabled={!ManageProjectRoles}
                value={moment(EndDate)}
                onChange={this.handleEndDateChange}
                helperText='Choose Start Date first'
                isRequired
                name='endDate'
                isEndDate
              />
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Status'
                placeholder='Choose Status'
                value={StatusId.toString()}
                onChange={this.onChangeStatus}
                selectOptions={roleStatus ? sortFilterList(roleStatus) : []}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Name',
                  id: 'Id',
                }}
                isRequired
                disabled={!ManageProjectRoles}
                clearValue={this.clearStatus}
              />
            </div>
            <div className='step__row margin-up-role role-modal__comment--width-wraper'>
              <TextField
                formClassName='role-page-project__form margin-right-50'
                inputClassName='c-SOW-edit__select'
                id='Comment'
                label='Comment'
                placeholder='Comment'
                name='Comment'
                maxlength='100'
                value={Comment ? Comment : ''}
                onChange={this.onChangeComment}
                isRequired={false}
                showClear
                clearValue={() => this.clearValue('Comment')}
                disabled={!ManageProjectRoles}
              />
            </div>
          </div>
          <AddEmployee
            RoleStartDate={StartDate}
            RoleEndDate={EndDate}
            RoleAssignment={Assignment && DEFAULT_EMPLOYEE_ASSIGNMENTS}
            RoleStatus={Status}
            handleStatusDialog={this.handleStatusDialog}
            disabled={!isModalFieldsRequired || !ManageProjectRoleAssignments}
            employees={suitableEmployees}
            assignedEmployees={Employees}
            checkEmployeeListValid={this.checkEmployeeListValid}
            projectTeams={projectTeams}
            getFlatEmployeeList={this.getFlatEmployeeList}
            assignedEmployeesToProject={assignedEmployeesToProject}
          />
        </Scrollbars>
        <div className='form-block buttons margin-down-role'>
          <div className='right-side-buttons'>
            <div className='button cancel' onClick={this.cancelRolePage(roleModalAction)}>
              <span>{ManageProjectRoles ? 'Cancel' : 'Back'}</span>
            </div>
            {
              (ManageProjectRoles || ManageProjectRoleAssignments) ? (
                <div
                  className={classNames('button next', { 'btn-disabled': !isModalFieldsRequired || assignmentIsUnvalid || !isEmployeeListValid })}
                  onClick={this.applyModal(roleModalAction)}
                >
                  <span>Save Role</span>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

EditRoleModal.propTypes = {
  roles: PropTypes.array,
  seniorityLevels: PropTypes.array,
  billableStatuses: PropTypes.array,
  assignmentTypes: PropTypes.array,
  billingCodes: PropTypes.array,
  employees: PropTypes.array,
  projectId: PropTypes.string,
  roleModalAction: PropTypes.string,
  editRoles: PropTypes.object,
  currentRoles: PropTypes.array,
  roleStatus: PropTypes.array,
  roleEmployeeStatus: PropTypes.array,
  getStatus: PropTypes.func,
  getEmployeeStatus: PropTypes.func,
  editRole: PropTypes.func,
  isOnlyActive: PropTypes.bool,
  rateUnits: PropTypes.array,
};

export default connect(store => {
  const {
    BillableStatuses = [],
    SeniorityLevels = [],
    Roles = [],
    AssignmentTypes = [],
  } = store.projectsReducer.projectRoleConfig;

  return {
    editRoles: store.projectsReducer.projectDetails,
    billableStatuses: BillableStatuses,
    seniorityLevels: SeniorityLevels,
    roles: Roles,
    assignmentTypes: AssignmentTypes,
    employees: store.roleModalReducer.employees,
    roleEmployees: store.roleModalReducer.roleEmployees,
    billingCodes: store.roleModalReducer.billingCodes,
    currentRoles: store.roleModalReducer.roles,
    projectId: store.roleModalReducer.projectId,
    roleModalAction: store.roleModalReducer.roleModalAction,
    roleStatus: store.roleModalReducer.status,
    roleEmployeeStatus: store.roleModalReducer.employeeStatus,
    isOnlyActive: store.showRoles.isOnlyActiveRoles,
    projectDetails: store.projectsReducer.projectDetails,
    rateUnits: store.roleModalReducer.rateUnits,
    Offices: store.projectsReducer.projectDetails.Offices,
    EngineeringManagers: store.projectsReducer.projectDetails.EngineeringManagers,
    currentRole: store.userRoleReducer.roles,
    billingCodesStartDate: store.roleModalReducer.billingCodesStartDate,
    billingCodesEndDate: store.roleModalReducer.billingCodesEndDate,
    contracts: store.projectsReducer.projectDetails.Contracts,
    projectTeams: store.projectsReducer.projectTeams.Teams,
    isLoading: store.loadingReducer.isLoading,
  };
}, {
  getProjectDetails,
  closeModal,
  addRoleToProject,
  showModal,
  getEmployees,
  getStatus,
  getEmployeeStatus,
  editRole,
  showAlertDialog,
  setComponent,
  resetComponent,
  setBillingData,
})(EditRoleModal);
