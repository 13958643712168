export const GET_EMPLOYEE_TRAININGS = '@admin/GET_EMPLOYEE_TRAININGS';
export const SET_EMPLOYEE_TRAININGS = '@admin/SET_EMPLOYEE_TRAININGS';
export const CREATE_NEW_TRAINING = '@admin/CREATE_NEW_TRAINING';
export const UPDATE_TRAINING = '@admin/UPDATE_TRAINING';
export const DELETE_TRAINING = '@admin/DELETE_TRAINING';

export const getEmployeeTrainings = () => ({
  type: GET_EMPLOYEE_TRAININGS,
});

export const setEmployeeTrainings = (employeeTraining) => ({
  type: SET_EMPLOYEE_TRAININGS,
  payload: employeeTraining,
});

export const createNewTraining = (data) => {
  return {
    type: CREATE_NEW_TRAINING,
    payload: data,
  };
};

export const updateTraining = (trainingId, data) => {
  return {
    type: UPDATE_TRAINING,
    payload: {
      trainingId,
      data,
    },
  };
};

export const deleteTraining = (trainingId) => {
  return {
    type: DELETE_TRAINING,
    payload: {
      trainingId,
    },
  };
};


export const GET_EMPLOYEE_TRAINING_CLIENTS = '@admin/GET_EMPLOYEE_TRAININGS_CLIENTS';
export const SET_EMPLOYEE_TRAINING_CLIENTS = '@admin/SET_EMPLOYEE_TRAININGS_CLIENTS';

export const getEmployeeTrainingClients = () => ({
  type: GET_EMPLOYEE_TRAINING_CLIENTS,
});

export const setEmployeeTrainingClients = (clients) => ({
  type: SET_EMPLOYEE_TRAINING_CLIENTS,
  payload: clients,
});
