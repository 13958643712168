import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '../input/text-field';


const isMaxLengthForValue = (value) => {
  return value.includes('.') ? value.length <= 15 : value.length <= 12;
};

const MAX_VALUE_FOR_NUMBER = 999999999999.99; // so that you can enter 999,999,999,999.99. It is max value on back-end.

export function NumberFormatCustom(props) {
  const {
    inputRef,
    onChange,
    maxValue = MAX_VALUE_FOR_NUMBER,
    ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      isAllowed={(values) => {
        const { formattedValue, floatValue, value } = values;
        return formattedValue === '' || formattedValue === '-' || (floatValue <= maxValue && isMaxLengthForValue(value));
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function InputCurrency(props) {
  const {
    value,
    maxValue,
    suffix,
    ...other
  } = props;
  const valueInput = (typeof value === 'number' && value.toString()) || value;
  return (
    <TextField
      {...other}
      value={valueInput}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      maxValue={maxValue}
      suffix={suffix}
    />
  );
}
