import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  LOAD_MANAGE_COUNTRIES,
  setManagesCountries,
  POST_MANAGE_COUNTRIES,
  PUT_MANAGE_COUNTRIES,
} from '../actions/adminManageCountries';

import {
  startRequest,
  finishRequest,
} from 'common/actions/loadingActions';

export function* loadManageCountries() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'countries',
    });

    yield put(setManagesCountries(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addCountries(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'countries',
      data,
    });
    yield call(loadManageCountries);
  } catch (e) {
    // error message
  }
}

export function* putCountries(action) {
  yield call(http, {
    method: 'PUT',
    url: `countries/${action.payload.Id}`,
    data: { Name: action.payload.Name },
  });
  yield call(loadManageCountries);
}

export default function* adminManageCountries() {
  yield takeEvery(LOAD_MANAGE_COUNTRIES, loadManageCountries);
  yield takeEvery(POST_MANAGE_COUNTRIES, addCountries);
  yield takeEvery(PUT_MANAGE_COUNTRIES, putCountries);
}
