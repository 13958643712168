import React from 'react';
import Select from 'common/components/form-items/select/select';
import '../redux-form.css';

export default class renderSelect extends React.Component {
  onChange = data => {
    const { input, options } = this.props;
    input.onChange({
      Id: data.target.value,
      Name: options.find(item => item.Id === data.target.value).Name,
    });
  }

  render() {
    const {
      input, meta, disabled, label, options, isOptionObject, inputProps, showClear, styles, placeholder, extendedValue, formClassName, selectClassName,
      showAsterix,
    } = this.props;
    return (
      <div className={styles.container}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              showAsterix && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          <Select
            value={input.value.Id}
            selectOptions={options}
            onChange={this.onChange}
            showClear={showClear}
            inputProps={inputProps}
            isOptionObject={isOptionObject}
            disabled={disabled}
            placeholder={placeholder}
            extendedValue={extendedValue}
            formClassName={formClassName}
            selectClassName={selectClassName}
          />
          {
            (meta.invalid && meta.error !== 'Value required') && (
              <div className={styles.fieldError}>
                <span>{meta.error}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
