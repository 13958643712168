import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getUserDetails, setListSettings } from '../../actions/adminActions';
import { showModal } from '../../actions/modalActions';
import {
  useHeader, useReset, useSearch, useSortedUsers,
} from './manage-users.hooks';
import { showDeleteUserConfirmation } from '../../actions/editUserConfirmationActions';
import ManageUserConfirmation from '../manage-user-confirmation/manage-user-confirmation';

const ManageUsers = () => {
  const dispatch = useDispatch();
  const users = useSortedUsers();
  const columnsToDisplay = useSelector((state) => state.adminReducer.columnsToDisplay);
  const searchValue = useSelector((state) => state.adminReducer.listSettings.searchValue);
  const sortColumn = useSelector((state) => state.adminReducer.listSettings.sortColumn);
  const sortDirection = useSelector((state) => state.adminReducer.listSettings.sortDirection);

  useHeader();
  useSearch();
  useReset();

  const handleSearchClick = (searchValue) => dispatch(setListSettings({ searchValue }));
  const handleAddNew = () => dispatch(showModal(true));
  const handleEditClick = (userId) => dispatch(getUserDetails(userId));
  const handleDeleteClick = (userId) => dispatch(showDeleteUserConfirmation(userId));
  const handleSetSortingSettings = (sortColumn, sortDirection) => dispatch(setListSettings({
    sortColumn,
    sortDirection,
  }));

  return (
    <div className='page-cont'>
      <DocumentTitle title={applicationTitle.getTitile('manageUsers')} />
      <ListToolbar
        searchPlaceholder='Search Users'
        onSearchClick={handleSearchClick}
        searchValue={searchValue}
        columnsToDisplay={columnsToDisplay}
        showAddButton
        showExportButton={false}
        onAddNew={handleAddNew}
      />
      <div className='manage-users__main-block--buttom-size'>
        <List
          page='users'
          columnsToDisplay={columnsToDisplay}
          items={users}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          setSortSettings={handleSetSortingSettings}
          editSpan='Edit User'
          deleteSpan='Remove access'
          isHaveRights
          hideAddNewEntity
        />
      </div>
      <ManageUserConfirmation />
    </div>
  );
};

export default ManageUsers;
