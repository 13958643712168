import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getManagePaymentTerms, createNewPaymentTerm, updatePaymentTerm } from 'pages/admin/actions/adminManagePaymentTerms';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';


export class ManagePaymentTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Payment Terms', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddPaymentTermFields: false,
      addPaymentTerm: { Name: '' },
      blockAddPaymentTerm: false,
      blockSavePaymentTerm: false,
    };
  }

  componentDidMount() {
    const { loadManagePaymentTerms } = this.props;
    loadManagePaymentTerms();
  }

  addNewPaymentTerm = () => {
    const { blockAddPaymentTerm } = this.state;
    if (!blockAddPaymentTerm) {
      this.setState({
        showAddPaymentTermFields: true,
      });
    }
  }

  getNewPaymentTermObj = ({ target }) => {
    const { addPaymentTerm } = this.state;
    this.setState({
      addPaymentTerm: {
        ...addPaymentTerm,
        [target.name]: target.value,
      },
    }, this.newPaymentTermValidation);
  }

  newPaymentTermValidation = () => {
    const { addPaymentTerm } = this.state;
    this.setState({
      blockSavePaymentTerm: !!addPaymentTerm.Name.trim(),
      blockAddPaymentTerm: !!addPaymentTerm.Name.trim(),
    });
  };

  newPaymentTermCancel = () => {
    this.setState({
      addPaymentTerm: { Name: '' },
      showAddPaymentTermFields: false,
      blockSavePaymentTerm: false,
      blockAddPaymentTerm: false,
    });
  }

  newResumeApply = () => {
    const { createNewPaymentTerm } = this.props;
    const { blockSavePaymentTerm, addPaymentTerm } = this.state;
    if (blockSavePaymentTerm) {
      createNewPaymentTerm(addPaymentTerm);
      this.setState({
        addPaymentTerm: { Name: '' },
        showAddPaymentTermFields: false,
        blockSavePaymentTerm: false,
        blockAddPaymentTerm: false,
      });
    }
  }


  deletePaymentTerms = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `payment_term/${data.Id}`,
      searchUpdateSaga: 'payment_term',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updatePaymentTerm } = this.props;
    const { Name, Id } = data;
    updatePaymentTerm(Name, Id);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  render() {
    const { PaymentTerms, listSettings } = this.props;
    const {
      columnsToDisplay, showAddPaymentTermFields, addPaymentTerm, blockAddPaymentTerm,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsPaymentTerms')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewPaymentTerm}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search payment terms'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(PaymentTerms, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deletePaymentTerms}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {
          showAddPaymentTermFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addPaymentTerm}
                onChangeNewDocumentObj={this.getNewPaymentTermObj}
                disableSaveDocument={blockAddPaymentTerm}
                newDocumentCancel={this.newPaymentTermCancel}
                newDocumentApply={this.newResumeApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManagePaymentTerms.propTypes = {
  loadManagePaymentTerms: PropTypes.func,
  createNewPaymentTerm: PropTypes.func,
  updatePaymentTerm: PropTypes.func,
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  PaymentTerms: store.adminManageLookups.paymentTerms,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManagePaymentTerms: getManagePaymentTerms,
  createNewPaymentTerm,
  updatePaymentTerm,
  showDialog,
  setListSettingsLookups,
})(ManagePaymentTerms);
