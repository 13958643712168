import moment from 'moment';

export const getTheSmallestDate = (prev, current) => {
  if (!prev || !prev.contract || !prev.contract.EndDate) {
    return current;
  }
  if (!current || !current.contract || !current.contract.EndDate) {
    return prev;
  }
  return moment(prev.contract.StartDate).isBefore(current.contract.StartDate, 'day') ? prev : current;
};
export const getTheBiggestDate = (prev, current) => {
  if (!prev || !prev.contract || !prev.contract.EndDate) {
    return current;
  }
  if (!current || !current.contract || !current.contract.EndDate) {
    return prev;
  }
  return moment(prev.contract.EndDate).isAfter(current.contract.EndDate, 'day') ? prev : current;
};

export const updateTheBiggestDateRange = (contract, theBiggestDateRange) => {
  theBiggestDateRange.forStartDate = getTheSmallestDate(theBiggestDateRange.forStartDate, contract);
  theBiggestDateRange.forEndDate = getTheBiggestDate(theBiggestDateRange.forEndDate, contract);
};

export const getTheBiggestDateRangeForContracts = (contracts, checkDateForSow) => {
  if (!contracts || !contracts.length) {
    return {};
  }

  const theBiggestDateRange = {
    forStartDate: checkDateForSow ? { contract: contracts[0], type: 'contracts' } : null,
    forEndDate: checkDateForSow ? { contract: contracts[0], type: 'contracts' } : null,
  };
  contracts.forEach(contract => {
    checkDateForSow && updateTheBiggestDateRange({ contract, type: 'contracts' }, theBiggestDateRange);

    contract.POs
      && contract.POs.length
      && contract.POs.forEach((PO) => { updateTheBiggestDateRange({ contract: PO, type: 'POs' }, theBiggestDateRange); });
    contract.Amendments
      && contract.Amendments.length
      && contract.Amendments.forEach((Amendment) => { updateTheBiggestDateRange({ contract: Amendment, type: 'amendments' }, theBiggestDateRange); });
  });
  return theBiggestDateRange;
};

export const getTheBiggestStartDateForContracts = (contracts) => {
  return contracts && contracts.length && moment.max(contracts.map((contract) => moment(contract.StartDate)));
};
