import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import './add-employee.css';

import NewEmployee from './new-employee';
import CustomIcon from 'common/components/icon/Icon';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog';
import isObjectNotEquals from 'utils/object-comparison';
import checkIsDatesCrossing from 'utils/checkIsDatesCrossing';
import {
  PROJECT_ROLES_STATUS_REMOVED,
  PROJECT_ROLES_EMPLOYEES_PRIMARY,
  PROJECT_ROLES_EMPLOYEES_SHADOW,
  DEFAULT_EMPLOYEE_ASSIGNMENTS,
} from 'utils/const-variable';
import { getRandomNumber } from 'utils/getRandomNumber';
import { Slider } from 'common/components/slider/slider';
import { connect } from 'react-redux';
import { toggleAssignmentsStatus } from 'pages/projects/actions/roleModalActions';
import ViewportList from 'react-viewport-list';

const emptyEmployee = {
  AssignmentKey: '',
  EmployeeId: '',
  EmployeeName: '',
  EmployeeOffice: '',
  EmployeeAssignment: '',
  EmployeeStartDate: null,
  EmployeeEndDate: null,
  EmployeeStatusId: '',
  EmployeeStatus: '',
  EmployeeComment: '',
  EmployeeRole: '',
  isValidEmployee: true,
  backupEmployees: [],
  primaryAssignmentId: '',
  createdOnFrontSide: true,
  Id: true,
};

export class AddEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedEmployees: [{
        ...emptyEmployee,
        backupEmployees: [],
        AssignmentKey: getRandomNumber(),
      }],
      isEmployeeListValid: true,
      isDeleteDialogOpened: false,
      actionData: {},
      crossingAssignmentsDates: [],
    };
    this.viewportListRef = React.createRef(null);
  }

  componentDidUpdate(prevProps) {
    const { assignedEmployees, importedAssignments, employees } = this.props;
    if (isObjectNotEquals(prevProps.assignedEmployees, assignedEmployees)) {
      this.setState({
        assignedEmployees: typeof prevProps.assignedEmployees === 'undefined' ? this.getNewAssignedEmployees(assignedEmployees) : assignedEmployees,
      });
    }
    if (isObjectNotEquals(prevProps.importedAssignments, importedAssignments)) {
      const filteredImportedEmployees = [...importedAssignments]
        .filter((assignment) => employees.findIndex((employee) => assignment.EmployeeId === employee.Id) !== -1);
      const newAssignedEmployees = [...assignedEmployees];
      const employeeIds = new Set(newAssignedEmployees.map((e) => e.EmployeeId));
      filteredImportedEmployees.forEach((employee) => {
        if (!employeeIds.has(employee.EmployeeId)) {
          newAssignedEmployees.push({
            ...emptyEmployee,
            backupEmployees: [],
            AssignmentKey: getRandomNumber(),
            EmployeeRole: PROJECT_ROLES_EMPLOYEES_PRIMARY,
            EmployeeAssignment: DEFAULT_EMPLOYEE_ASSIGNMENTS,
            ...employee,
          });
        }
      });
      this.setState({
        assignedEmployees: newAssignedEmployees,
      }, () => this.checkIsValidEmployeeList());
    }
  }

  getNewAssignedEmployees = (employees) => {
    if (!!employees.length) {
      return employees.reduce((acc, item) => {
        if (item.backupEmployees) {
          acc.push({
            ...item,
            backupEmployees: this.getNewAssignedEmployees(item.backupEmployees),
            AssignmentKey: item.AssignmentKey || getRandomNumber(),
          });
        } else {
          acc.push({
            ...item,
            backupEmployees: [],
            AssignmentKey: item.AssignmentKey || getRandomNumber(),
          });
        }
        return acc;
      }, []);
    }
    return [];
  }

  checkIsValidEmployeeList = () => {
    const { assignedEmployees } = this.state;
    const {
      checkEmployeeListValid, getFlatEmployeeList, RoleStartDate, RoleEndDate,
    } = this.props;
    const getNormalizedDate = (date) => moment(moment(date).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'));
    const normalizedRoleStartDate = getNormalizedDate(RoleStartDate);
    const normalizedRoleEndDate = getNormalizedDate(RoleEndDate);
    const isInvalidEmployee = assignedEmployees.some(employee => {
      const normalizedEmployeeStartDate = getNormalizedDate(employee.EmployeeStartDate);
      const normalizedEmployeeEndDate = getNormalizedDate(employee.EmployeeEndDate);

      const startDateDiff = normalizedEmployeeStartDate.diff(normalizedRoleStartDate);
      const endDateDiff = normalizedEmployeeEndDate.diff(normalizedRoleEndDate);

      const isStartDateInvalid = startDateDiff < 0;
      const isEndDateInvalid = endDateDiff > 0;

      return (
        !employee.isValidEmployee
        || (employee.backupEmployees && employee.backupEmployees.some(backup => !backup.isValidEmployee))
        || isStartDateInvalid || isEndDateInvalid
      );
    });
    let crossingAssignmentsDates = getFlatEmployeeList(assignedEmployees).reduce((crossingDates, assignment, index, flatEmployeesList) => {
      const sameEmployees = flatEmployeesList.filter(sameCandidate => (
        sameCandidate.EmployeeId === assignment.EmployeeId
        && sameCandidate.AssignmentKey !== assignment.AssignmentKey
        && ((!sameCandidate.primaryAssignmentId && !assignment.primaryAssignmentId)
        || sameCandidate.primaryAssignmentId === assignment.primaryAssignmentId)
      ));
      sameEmployees.forEach(sameEmployee => (
        checkIsDatesCrossing(sameEmployee, assignment)
        && crossingDates.push(assignment.AssignmentKey)
      ));

      return crossingDates;
    }, []);
    crossingAssignmentsDates = [...new Set(crossingAssignmentsDates)];
    const isValid = !isInvalidEmployee && !crossingAssignmentsDates.length;
    this.setState({
      isEmployeeListValid: isValid,
      crossingAssignmentsDates,
    }, () => checkEmployeeListValid(isValid, assignedEmployees));
  }

  addPrimaryAssignment = () => {
    const { disabled } = this.props;
    !disabled && this.addEmployee();
  }

  addEmployee = (primaryAssignment) => {
    const newEmployee = { ...emptyEmployee };
    const { assignedEmployees } = this.state;
    newEmployee.backupEmployees = [];
    if (primaryAssignment && primaryAssignment.EmployeeId) {
      primaryAssignment.backupEmployees.unshift({
        ...newEmployee,
        EmployeeStartDate: primaryAssignment.EmployeeStartDate,
        EmployeeEndDate: primaryAssignment.EmployeeEndDate,
        backupEmployees: [],
        primaryAssignmentId: primaryAssignment.AssignmentKey,
        AssignmentKey: getRandomNumber(),
        EmployeeRole: PROJECT_ROLES_EMPLOYEES_SHADOW,
      });
      const nextEmployees = assignedEmployees.map(employee => {
        return employee.AssignmentKey === primaryAssignment.AssignmentKey ? primaryAssignment : employee;
      });
      this.setState({
        assignedEmployees: [...nextEmployees],
      });
      return;
    }

    this.setState({
      assignedEmployees: [{
        ...newEmployee,
        AssignmentKey: getRandomNumber(),
        EmployeeRole: PROJECT_ROLES_EMPLOYEES_PRIMARY,
      }, ...assignedEmployees],
    });
  }

  editAssignedEmployee = (newEmployee, primaryAssignmentKey, isValidEmployee, showStatusDialog = true) => {
    const { assignedEmployees } = this.state;
    const { projectTeams, handleStatusDialog } = this.props;
    const nextEmployees = [...assignedEmployees];
    const primaryAssignmentIndex = assignedEmployees
      .findIndex(primary => primary.AssignmentKey === newEmployee.AssignmentKey
        || primary.AssignmentKey === primaryAssignmentKey);
    let backupEmployeeIndex;
    const isTeamMember = projectTeams && projectTeams.some(team => {
      return (
        team.Members
        && team.Members.some(member => (member.Id === newEmployee.AssignmentKey) && (member.Status && member.Status.Id !== newEmployee.EmployeeStatusId))
      )
      || team.SecondInCommand && (team.SecondInCommand.Id === newEmployee.EmployeeId) && (newEmployee.EmployeeStatusId !== 2);
    });
    if (newEmployee.primaryAssignmentId) {
      backupEmployeeIndex = assignedEmployees[primaryAssignmentIndex].backupEmployees
        .findIndex(backup => backup.AssignmentKey === newEmployee.AssignmentKey);
      if (nextEmployees[primaryAssignmentIndex].backupEmployees[backupEmployeeIndex].EmployeeStatusId !== newEmployee.EmployeeStatusId) {
        showStatusDialog && isTeamMember && handleStatusDialog && handleStatusDialog();
      }
      nextEmployees[primaryAssignmentIndex].backupEmployees[backupEmployeeIndex] = {
        ...newEmployee,
        isValidEmployee,
      };
    } else {
      if (nextEmployees[primaryAssignmentIndex].EmployeeStatusId !== newEmployee.EmployeeStatusId) {
        showStatusDialog && isTeamMember && handleStatusDialog && handleStatusDialog();
      }
      nextEmployees[primaryAssignmentIndex] = {
        ...newEmployee,
        isValidEmployee,
      };
    }
    this.setState({
      assignedEmployees: [...nextEmployees],
    }, () => this.checkIsValidEmployeeList());
  }

  deleteAssignedEmployee = (employee, primaryAssignmentKey) => {
    const { assignedEmployees } = this.state;
    const nextEmployees = [...assignedEmployees];
    const primaryAssignmentIndex = assignedEmployees
      .findIndex(primary => primary.AssignmentKey === employee.AssignmentKey || primary.AssignmentKey === primaryAssignmentKey);
    if (employee.EmployeeRole === PROJECT_ROLES_EMPLOYEES_PRIMARY) {
      nextEmployees.splice(primaryAssignmentIndex, 1);
    } else if (employee.EmployeeRole === PROJECT_ROLES_EMPLOYEES_SHADOW) {
      const backupEmployeeIndex = assignedEmployees[primaryAssignmentIndex].backupEmployees
        .findIndex(backup => backup.AssignmentKey === employee.AssignmentKey);
      nextEmployees[primaryAssignmentIndex].backupEmployees.splice(backupEmployeeIndex, 1);
    }

    this.setState({
      assignedEmployees: [...nextEmployees],
      isDeleteDialogOpened: false,
    }, () => this.checkIsValidEmployeeList());
  }

  promoteBackupToPrimary = (promotedEmployee, backupEmployee, primaryAssignmentKey, isValidEmployee) => {
    const { assignedEmployees } = this.state;
    const nextEmployees = [...assignedEmployees];
    primaryAssignmentKey && this.editAssignedEmployee(backupEmployee, primaryAssignmentKey, isValidEmployee, false);
    nextEmployees.push({ ...promotedEmployee, Id: true, AssignmentKey: getRandomNumber() });

    this.setState({
      assignedEmployees: [...nextEmployees],
    }, () => this.checkIsValidEmployeeList());
  }

  filterAssignedEmployees = () => {
    const { employees } = this.props;
    const { assignedEmployees } = this.state;

    return employees.filter(employee => {
      const isSameEmployee = assignedEmployees.some(primary => {
        return primary.EmployeeId === employee.Id && primary && primary.EmployeeStatus !== PROJECT_ROLES_STATUS_REMOVED;
      });
      const isSameBackupEmployee = assignedEmployees.some(primary => primary.backupEmployees && primary.backupEmployees.some(backup => {
        return backup.EmployeeId === employee.Id && backup && backup.EmployeeStatus !== PROJECT_ROLES_STATUS_REMOVED;
      }));
      return !isSameEmployee && !isSameBackupEmployee;
    });
  }

  showDeleteModal = (employee, primaryAssignmentKey, autoDelete) => {
    const { projectTeams } = this.props;
    const isTeamMember = projectTeams.some(team => {
      return (
        team.Members
        && team.Members.some(member => (member.Id === employee.AssignmentKey))
      )
      || team.SecondInCommand && (team.SecondInCommand.Id === employee.EmployeeId);
    });
    const { backupEmployees } = employee;
    const hasBackupEmployees = backupEmployees.length > 0;
    const backupAssignmentNames = backupEmployees.map((e) => e.EmployeeName);
    const message = `After this action employee history will be lost.
    ${isTeamMember
      ? primaryAssignmentKey
        ? 'Also, this employee will be deleted from the teams.'
        : 'Also, this employee and his backup employees will be deleted from the teams.'
      : ''}
    ${hasBackupEmployees ? `Also, ${backupAssignmentNames.join(', ')} as backup will be deleted.` : ''}
    Click OK to confirm the action.`;

    if (employee.EmployeeId && !autoDelete) {
      this.setState({
        isDeleteDialogOpened: true,
        actionData: { employee, primaryAssignmentKey },
        confirmDialogMessage: message,
      });
      return;
    }
    this.deleteAssignedEmployee(employee, primaryAssignmentKey);
  }

  closeDialog = () => {
    this.setState({
      isDeleteDialogOpened: false,
    });
  };

  handleChangeShowAssignments = () => {
    const { toggleAssignmentsStatus } = this.props;
    toggleAssignmentsStatus();
  }

  renderAssignedEmployee = (employee, filteredAssignedEmployees) => {
    const { assignedEmployees, crossingAssignmentsDates } = this.state;
    const {
      RoleStartDate,
      RoleEndDate,
      RoleAssignment,
      RoleStatus,
      disabled,
      assignedEmployeesToProject,
      isOnlyActiveAssignments,
    } = this.props;

    const employeesToShow = isOnlyActiveAssignments && employee.backupEmployees
      ? employee.backupEmployees.filter(backupEmployee => backupEmployee.EmployeeStatus !== PROJECT_ROLES_STATUS_REMOVED)
      : employee.backupEmployees;

    const backupEmployees = employeesToShow && !!employeesToShow.length
      ? employeesToShow.map((backupEmployee) => {
        const backupEmployees = this.filterAssignedEmployees().filter(employee => employee.Id !== employee.EmployeeId);
        return (
          <NewEmployee
            key={employee.AssignmentKey + '-' + backupEmployee.AssignmentKey}
            primaryAssignmentIndex={0}
            primaryAssignment={employee}
            employee={backupEmployee}
            emptyEmployee={emptyEmployee}
            RoleStartDate={moment(RoleStartDate)}
            RoleEndDate={moment(RoleEndDate)}
            RoleAssignment={RoleAssignment}
            RoleStatus={RoleStatus}
            disabled={disabled}
            editAssignedEmployee={this.editAssignedEmployee}
            deleteAssignedEmployee={this.showDeleteModal}
            promoteBackupToPrimary={this.promoteBackupToPrimary}
            disabledPromote={backupEmployee.EmployeeStatus === PROJECT_ROLES_STATUS_REMOVED}
            employees={backupEmployees}
            assignedEmployees={assignedEmployees}
            showOptionDots={!disabled}
            isDatesCrossing={crossingAssignmentsDates.some(employeeKey => employeeKey === backupEmployee.AssignmentKey)}
            assignedEmployeesToProject={assignedEmployeesToProject}
          />
        );
      }) : [];

    return (
      <div key={employee.AssignmentKey}>
        <NewEmployee
          employee={employee}
          emptyEmployee={emptyEmployee}
          RoleStartDate={moment(RoleStartDate)}
          RoleEndDate={moment(RoleEndDate)}
          RoleAssignment={RoleAssignment}
          RoleStatus={RoleStatus}
          disabled={disabled}
          addBackupEmployee={this.addEmployee}
          editAssignedEmployee={this.editAssignedEmployee}
          deleteAssignedEmployee={this.showDeleteModal}
          employees={filteredAssignedEmployees}
          assignedEmployees={assignedEmployees}
          showOptionDots={!disabled}
          isDatesCrossing={crossingAssignmentsDates.some(employeeKey => employeeKey === employee.AssignmentKey)}
          assignedEmployeesToProject={assignedEmployeesToProject}
        />
        {backupEmployees}
      </div>
    );
  }

  getTextForTooltip = () => {
    return (
      <p>
        <span>By default only active assignments are shown.</span>
      </p>
    );
  };

  render() {
    const {
      isDeleteDialogOpened, actionData, confirmDialogMessage, assignedEmployees
    } = this.state;
    const { disabled, isOnlyActiveAssignments } = this.props;

    const employeesToShow = isOnlyActiveAssignments && assignedEmployees
      ? assignedEmployees.filter(employee => employee.EmployeeStatus !== PROJECT_ROLES_STATUS_REMOVED)
      : assignedEmployees;
    const filteredAssignedEmployees = this.filterAssignedEmployees();

    return (
      <div className='add-employee-container'>
        <div className='add-employee-header'>
          <div className='left-toolbar-part'>
            <span className='add-employee-title'>Assign employees to role</span>
            <Slider
              label='Show only active assignments'
              isTooltip
              textForTooltip={this.getTextForTooltip()}
              tooltipClassName='tooltip-roles--text'
              defaultChecked={isOnlyActiveAssignments}
              onChange={this.handleChangeShowAssignments}
            />
          </div>
          <div
            className={classNames('add-employee-button', { 'add-employee-button-disabled': disabled })}
            onClick={this.addPrimaryAssignment}
          >
            <CustomIcon iconName='plus-orange' />
            <span>Add New</span>
          </div>
        </div>
        <div className='add-employee-list'>
          <div className='add-employee-list-line add-employee-list-header'>
            <div className='add-employee-list-column employee-col'>
              <span className='add-employee-list-column-title'>Employee</span>
            </div>
            <div className='add-employee-list-column'>
              <span className='add-employee-list-column-title'>Office</span>
            </div>
            <div className='add-employee-list-column'>
              <span className='add-employee-list-column-title'>Assignment</span>
            </div>
            <div className='add-employee-list-column'>
              <span className='add-employee-list-column-title'>Start Date</span>
            </div>
            <div className='add-employee-list-column'>
              <span className='add-employee-list-column-title'>End Date</span>
            </div>
            <div className='add-employee-list-column'>
              <span className='add-employee-list-column-title'>Status</span>
            </div>
            <div className='add-employee-list-column'>
              <span className='add-employee-list-column-title'>Comment</span>
            </div>
            <div className='add-employee-list-column svg-col'>
              <span className='add-employee-list-column-title' />
            </div>
          </div>
          <div ref={this.viewportListRef}>
            <ViewportList viewportRef={this.viewportListRef} items={employeesToShow}>
              {(item) => (
                this.renderAssignedEmployee(item, filteredAssignedEmployees)
              )}
            </ViewportList>
          </div>
        </div>
        {
          isDeleteDialogOpened && (
            <ConfirmationDialog
              dialogHeader='DELETE'
              dialogTitle={confirmDialogMessage}
              closeButtonTitle='Cancel'
              confirmButtonTitle='OK'
              actionData={actionData}
              closeDialog={this.closeDialog}
              confirmDialog={() => this.deleteAssignedEmployee(actionData.employee, actionData.primaryAssignmentKey)}
            />
          )
        }
      </div>
    );
  }
}

AddEmployee.propTypes = {
  RoleStartDate: PropTypes.string,
  RoleEndDate: PropTypes.string,
  RoleAssignment: PropTypes.number,
  RoleStatus: PropTypes.string,
  disabled: PropTypes.bool,
  checkEmployeeListValid: PropTypes.func,
  getFlatEmployeeList: PropTypes.func,
  assignedEmployees: PropTypes.array,
  projectTeams: PropTypes.array,
  toggleAssignmentsStatus: PropTypes.func,
};

export default connect(
  (store) => ({
    isOnlyActiveAssignments: store.roleModalReducer.isOnlyActiveAssignments,
  }),
  {
    toggleAssignmentsStatus,
  },
)(AddEmployee);