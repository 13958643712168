import { SET_COMPONENT, RESET_COMPONENT } from '../actions/headerActions';

const initialState = {
  title: null,
  returnButton: null,
  secondReturnButton: null,
};

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT:
    case RESET_COMPONENT:
      return {
        ...state,
        title: action.payload.title,
        returnButton: action.payload.returnButton,
        secondReturnButton: action.payload.secondReturnButton,
      };
    default:
      return state;
  }
}
