import {
  SET_USERS,
  SET_SETTINGS,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
  RESET_USERS,
} from '../actions/adminActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
};

const initialState = {
  users: [],
  columnsToDisplay: [
    {
      Name: 'Name', Id: 'Name', hasBadge: true, className: 'biggest-col',
    },
    {
      Name: 'Office', Id: 'Office', className: 'biggest-col',
    },
    {
      Name: 'Current position', Id: 'Position', className: 'biggest-col',
    },
    {
      Name: 'Role', Id: 'SecurityRoles', className: 'biggest-col', isArray: true,
    },
  ],
  settings: {
    Id: null,
    ContractFirstNotification: null,
    ContractSecondNotification: null,
    ContractThirdNotification: null,
    RoleFirstNotification: null,
    RoleSecondNotification: null,
    RoleThirdNotification: null,
    ProjectExpiration: null,
  },
  listSettings: defaultListSettings,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload.users,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: {
          ...action.payload,
        },
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case RESET_USERS:
      return {
        ...state,
        users: [],
      };
    default:
      return state;
  }
};

export default adminReducer;
