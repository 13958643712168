export const defaultData = [
  {
    HasSiteAccess: {
      label: 'Site Access',
    },
    IsNotificationsEnable: {
      label: 'Notifications',
    },
    HasFinancialAccess: {
      label: 'Finance Access',
    },
    AddToCCInNotifications: {
      label: 'Admin CC Notifications',
    },
    label: 'Basic Settings',
  },
  {
    CanCreateClient: {
      label: 'Create new client',
    },
    EditClientBasicInfo: {
      label: 'Edit client information',
    },
    ManageClientContact: {
      label: 'Edit client contact',
    },
    label: 'Client',
  },
  {
    EditOfficeBasicInfo: {
      label: 'Edit office info',
    },
    ManageOfficeCalendar: {
      label: 'Edit calendar',
    },
    ManageOfficeLdapPath: {
      label: 'Edit ldap-path',
    },
    label: 'Office',
  },
  {
    CanSyncAD: {
      label: 'Can sync AD',
    },
    EditEmployeeBasicInfo: {
      label: 'Edit employee info',
    },
    ManageEmployeeSkills: {
      label: 'Edit employee skills',
    },
    ManageEmployeeVacations: {
      label: 'Edit employee vacation',
    },
    ManageEmployeeResume: {
      label: 'Edit employee resume',
    },
    ManageEmployeeCertificates: {
      label: 'Edit employee certificates',
    },
    ViewEmployeesAssignedToProject: {
      label: 'View employees assigned to the project'
    },
    label: 'Employee',
  },
  {
    CanCreateProject: {
      label: 'Create project',
    },
    EditProjectBasicInfo: {
      label: 'Edit basic info',
    },
    EditProjectBasicFinanceInfo: {
      label: 'Edit finance info',
    },
    ManageProjectContracts: {
      label: 'Edit contracts',
    },
    ManageProjectRoles: {
      label: 'Edit roles',
    },
    ManageProjectRoleAssignments: {
      label: 'Edit assignments',
    },
    label: 'Project',
  },
];
