export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const addNotify = (notify) => ({
  type: ADD_NOTIFICATIONS,
  payload: notify,
});

export const clearNotify = () => ({
  type: CLEAR_NOTIFICATIONS,
});
