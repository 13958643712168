import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signinRequest } from './actions/loginActions';
import Button from 'common/components/form-items/button/button';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CustomIcon from 'common/components/icon/Icon';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import replaceHistoryLocationState from 'utils/replaceHistoryLocationState';
import { MESSAGE_ACCESS_DENIED } from 'utils/const-variable';
import './login-page.css';

export class LoginPage extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      isChanged: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    this.replaceAdminPageHistory();
  }

  replaceAdminPageHistory = () => {
    const filtersDefault = {};
    const searchDefault = '';
    const sortDefault = { column: '', sortDirection: '' };
    const scrollTo = '';
    replaceHistoryLocationState(filtersDefault, searchDefault, sortDefault, scrollTo);
  }

  onLoginButtonClick = (event) => {
    const { username, password } = this.state;
    const { signinRequest } = this.props;
    event.preventDefault();
    if (username && password) {
      signinRequest(username, password);
    }
    this.setState({
      isChanged: false,
    });
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  isFormValid = () => {
    const { username, password } = this.state;
    return !!(username && password);
  }

  handleChange = (event) => {
    const { target: { name, value } } = event;
    this.setState({
      [name]: value,
      isChanged: true,
    });
  }

  getVersionText = (version) => {
    const currentVersion = version.split('.');
    const lightPart = [currentVersion[0], currentVersion[1]].join('.');
    const darkPart = [currentVersion[2], currentVersion[3]].join('.');
    return (
      <>
        <span className='light-part'>{lightPart}</span>
        <span className='dark-part'>{darkPart}</span>
      </>
    );
  };

  render() {
    const {
      isLoading, error, version, message,
    } = this.props;
    const {
      username, password, showPassword, isChanged,
    } = this.state;
    const errorMessage = message || MESSAGE_ACCESS_DENIED;
    return (
      <div className='page-wrapper'>
        <DocumentTitle title={applicationTitle.getTitile('loginPage')} />
        <div className='version-wrapper'>
          <div className='version-container'>{this.getVersionText(version)}</div>
        </div>
        <div className='content-wrapper logo-wrapper'>
          <div>
            <CustomIcon iconName='theodelite-big-icon' />
          </div>
        </div>
        <div className='content-wrapper'>
          <form onSubmit={this.OnLoginButtonClick}>
            <h1>Sign In</h1>
            <h2>to Akvelon Theodolite</h2>
            <h3>Enter your details below.</h3>
            <TextField
              label='User name'
              fullWidth
              name='username'
              value={username}
              margin='normal'
              onChange={this.handleChange}
              InputProps={{ endAdornment: <InputAdornment position='end'>@akvelon.com</InputAdornment> }}
              variant='outlined'
            />
            <TextField
              label='Password'
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              margin='normal'
              fullWidth
              onChange={this.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>),
              }}
              variant='outlined'
            />
            <div className='sign-error-container'>
              {error && !isChanged ? <label>{errorMessage}</label> : null}
            </div>
            <Button
              label={isLoading ? '' : 'Login'}
              fullWidth
              disabled={!this.isFormValid()}
              spinner={isLoading}
              fontSize='18px'
              height='60px'
              shouldSubmit
              onClick={this.onLoginButtonClick}
              id='button_submit'
            />
          </form>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  isLoading: PropTypes.bool,
  signinRequest: PropTypes.func,
  error: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.authReducer.isLoading,
  error: state.loginReducer.error,
  version: state.versionReducer.version,
  message: state.loginReducer.message,
});

const mapDispatchToProps = {
  signinRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
