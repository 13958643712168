import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import classNames from 'classnames';

import TextField from 'common/components/form-items/input/text-field';
import MultiSelect from 'common/components/form-items/multi-select/multi-select';
import MultiSuggestBox from 'common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import Select from 'common/components/form-items/select/select';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import SearchDropdown from '../../../../common/components/form-items/search-dropdown/search-dropdown';
import isObjectNotEquals from '../../../../utils/object-comparison';
import { NON_BILLABLE_TRAINING } from '../../../../utils/const-variable';
import { getCorrectManagersForMultiSuggest } from '../utils/getCorrectManagersForMultiSuggest';

export class Step extends React.Component {
  constructor(props) {
    super(props);

    const { initialState } = this.props;

    this.state = {
      aliases: initialState.aliases,
      projectCode: initialState.projectCode,
      name: initialState.name,
      offices: initialState.offices,
      primaryOffices: initialState.primaryOffices,
      clientId: initialState.clientId,
      clientManagerId: initialState.clientManagerId,
      description: initialState.description,
      accountManagerId: initialState.accountManagerId,
      deliveryManagerId: initialState.deliveryManagerId,
      // budget: initialState.budget,
      projectType: initialState.projectType,
      projectTypeName: initialState.projectTypeName,
      startDate: initialState.startDate,
      endDate: initialState.endDate,
      isStep1FieldsRequired: initialState.isStep1FieldsRequired,
      // disabledStep1Selects: initialState.disabledStep1Selects,
      endDateDisebled: true,
      clientManagerDisebled: true,
      SOWClientManagerId: initialState.SOWClientManagerId,
      projectNameError: false,
      isBillableTrainee: true,
      deliveryManagersOptions: [],
      currentDeliveryManagers: initialState.currentDeliveryManagers,
      engineeringManagersOption: [],
      currency: initialState.currency,
      ProjectBillingCode: initialState.ProjectBillingCode,
      currentEngineeringManagers: initialState.currentEngineeringManagers,
      engagementTypeId: initialState.engagementTypeId,
    };
  }

  componentDidMount() {
    const { clientId, startDate } = this.state;
    const { projectTypes } = this.props;
    this.setState(clientId
      ? { clientManagerDisebled: false }
      : { clientManagerDisebled: true });
    this.setState(startDate
      ? { endDateDisebled: false }
      : { endDateDisebled: true });
    if (projectTypes.length) {
      this.checkBillableStatus();
    }
    this.setAllManagersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { projectType, offices } = this.state;
    const {
      projectTypes, deliveryManagersByOffice, engineeringManagersByOffice, getManagersByOffice, setSOWType,
    } = this.props;
    if (prevState.projectType !== projectType || prevProps.projectTypes !== projectTypes) {
      this.checkBillableStatus();
      setSOWType(projectType);
    }
    if (isObjectNotEquals(prevProps.deliveryManagersByOffice, deliveryManagersByOffice)) {
      this.setAllManagersOptions();
      this.filterManagersByOffice();
    }
    if (isObjectNotEquals(prevProps.engineeringManagersByOffice, engineeringManagersByOffice)) {
      this.setAllManagersOptions();
      this.filterManagersByOffice();
    }
    if (prevState.offices !== offices) {
      getManagersByOffice(offices);
    }
  }

  setAllManagersOptions = () => {
    const { deliveryManagersByOffice, engineeringManagersByOffice } = this.props;
    this.setState({
      deliveryManagersOptions: getCorrectManagersForMultiSuggest(deliveryManagersByOffice),
      engineeringManagersOption: getCorrectManagersForMultiSuggest(engineeringManagersByOffice),
    });
  }

  filterManagersByOffice = () => {
    const { currentDeliveryManagers, currentEngineeringManagers, accountManagerId } = this.state;
    const { deliveryManagersByOffice, engineeringManagersByOffice, accountManagersByOffice } = this.props;
    const correctDelivery = currentDeliveryManagers.filter((item) => {
      return deliveryManagersByOffice.find(manager => item.valueId === manager.Id);
    });
    const correctEngineering = currentEngineeringManagers.filter((item) => {
      return engineeringManagersByOffice.find(manager => item.valueId === manager.Id);
    });
    this.setState({
      currentDeliveryManagers: correctDelivery,
      currentEngineeringManagers: correctEngineering,
    });
    if (!!accountManagersByOffice.length) {
      const correctAccount = accountManagersByOffice.find(manager => manager.Id === accountManagerId);
      this.setState({
        accountManagerId: correctAccount ? correctAccount.Id : '',
      });
    }
  };

  requireFields = () => {
    const {
      startDate,
      clientId,
      name,
      projectType,
      offices,
      primaryOffices,
      accountManagerId,
      isBillableTrainee,
      clientManagerId,
      currency,
      engagementTypeId,
    } = this.state;

    this.setState(startDate
      ? { endDateDisebled: false }
      : { endDateDisebled: true });
    this.setState(clientId
      ? { clientManagerDisebled: false }
      : { clientManagerDisebled: true });
    (name.trim() !== ''
      && projectType !== ''
      && offices.length > 0
      && primaryOffices.length > 0
      && (accountManagerId !== '' || isBillableTrainee)
      && clientId !== ''
      && !!startDate
      && (clientManagerId !== '' || isBillableTrainee))
      && currency !== ''
      && !!engagementTypeId
      ? this.setState({ isStep1FieldsRequired: true })
      : this.setState({ isStep1FieldsRequired: false });
  }

  checkBillableStatus = () => {
    const { projectType } = this.state;
    const { projectTypes, checkIsBillableTrainee } = this.props;

    const trainingType = projectTypes.find((item) => {
      return item.Name.toLowerCase() === NON_BILLABLE_TRAINING.toLowerCase();
    });
    const isTrainee =  trainingType && trainingType.Id === projectType;
    this.setState({
      isBillableTrainee: isTrainee,
    }, () => this.requireFields());
    checkIsBillableTrainee(isTrainee);
  };

  onChangeDataProject = (e) => {
    if (e.target.name === 'name') {
      this.checkProjectNameDublicateError(e);
      this.clearProjectError();
    }
    if (e.target.name === 'projectType') {
      const { projectTypes } = this.props;
      this.checkBillableStatus();
      this.setState({
        projectTypeName: projectTypes.find(item => item.Id === e.target.value).Name,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    }, () => this.requireFields());
  }

  onChangeOffice = (Id) => {
    const { offices, primaryOffices } = this.state;

    const newOffices = offices.includes(Id)
      ? offices.filter(id => id !== Id)
      : offices.concat([Id]);
    this.setState({
      offices: newOffices,
      primaryOffices: primaryOffices.filter(id => id !== Id),
    }, () => {
      this.requireFields();
      this.filterManagersByOffice();
    });
  }

  onChangePrimaryOffices = (Id) => {
    const { primaryOffices } = this.state;

    const newOffices = primaryOffices.includes(Id)
      ? primaryOffices.filter(id => id !== Id)
      : primaryOffices.concat([Id]);
    this.setState({
      primaryOffices: newOffices,
    }, () => {
      this.requireFields();
    });
  };

  onChangeClient = (option) => {
    this.setState({
      clientId: option ? option.value : '',
      clientManagerId: '',
    }, () => this.requireFields());
  }

  onChangeClientManager = (option) => {
    this.setState({
      clientManagerId: option ? option.value : '',
      SOWClientManagerId: option ? option.value : '',
    }, () => this.requireFields());
  };

  onChangeAccountManager = (option) => {
    this.setState({
      accountManagerId: option ? option.value : '',
    }, () => this.requireFields());
  }

  onChangeEngagementType = (option) => {
    this.setState({
      engagementTypeId: option ? option.value : '',
    }, () => this.requireFields());
  }

  handleChangeDate = (date, name) => {
    this.requireFields();
    if (name) {
      this.setState({
        [name]: date,
      }, () => this.requireFields());
    }
  }

  changeDeliveryManagersValue = (values) => {
    const managersIdArray = values.reduce((acc, item) => {
      acc.push(item.valueId);
      return acc;
    }, []);
    this.setState({
      DeliveryManagers: values ? managersIdArray : [],
    }, () => this.requireFields());
  }

  changeEngineeringManagersValue = (values) => {
    const managersIdArray = values.reduce((acc, item) => {
      acc.push(item.valueId);
      return acc;
    }, []);
    this.setState({
      EngineeringManagers: values ? managersIdArray : [],
    }, () => this.requireFields());
  }

  clearEndDateProject = () => {
    this.setState({
      endDate: null,
    }, () => this.requireFields());
  }

  onSave = () => {
    const { isStep1FieldsRequired } = this.state;
    const { onSave, isProjectNameDublicateError } = this.props;
    if (isProjectNameDublicateError) {
      this.setState({
        projectNameError: true,
        isStep1FieldsRequired: false,
      });
      return;
    }
    if (isStep1FieldsRequired) {
      onSave(this.state);
    }
  }

  onNextStep = () => {
    const {
      isStep1FieldsRequired,
      clientId,
    } = this.state;
    const {
      onStepChange, setClientId, isProjectNameDublicateError,
    } = this.props;
    if (isProjectNameDublicateError) {
      this.setState({
        projectNameError: true,
        isStep1FieldsRequired: false,
      });
      return;
    }
    if (isStep1FieldsRequired) {
      onStepChange({
        ...this.state,
        step: 1,
      });
      setClientId(clientId);
    }
  }

  onCancel = () => {
    const { location } = this.props;
    history.push({
      pathname: '/projects',
      state: location.state,
    });
  }

  clearValue = () => (property) => {
    this.setState(
      property === 'clientId' ? {
        [property]: '',
        clientManagerId: '',
      }
        : { [property]: '' },
      () => this.requireFields(),
    );
  }

  checkProjectNameDublicateError = (e) => {
    const { checkProjectNameDublicateError } = this.props;
    checkProjectNameDublicateError(e.target.value);
  }

  clearProjectError = () => {
    this.setState({
      projectNameError: false,
    });
  }

  clearOffices = () => {
    this.setState(
      { offices: [], primaryOffices: [] },
      () => this.requireFields(),
    );
  }

  clearPrimaryOffices = () => {
    this.setState(
      { primaryOffices: [] },
      () => this.requireFields(),
    );
  }

  handleChangeSuggestBox = (value) => {
    this.setState({
      currentDeliveryManagers: value,
    });
  };

  handleChangeSuggestBoxEngManagers = (value) => {
    this.setState({
      currentEngineeringManagers: value,
    });
  };

  handleChangeCurrency = (e) => {
    this.setState({
      currency: e.target.value,
    }, () => this.requireFields());
  }

  render() {
    const {
      projectNameError,
      name,
      projectType,
      projectCode,
      offices,
      primaryOffices,
      clientId,
      clientManagerId,
      clientManagerDisebled,
      isBillableTrainee,
      accountManagerId,
      startDate,
      aliases,
      endDate,
      endDateDisebled,
      description,
      isStep1FieldsRequired,
      deliveryManagersOptions,
      currentDeliveryManagers,
      engineeringManagersOption,
      currentEngineeringManagers,
      currency,
      ProjectBillingCode,
      engagementTypeId,
    } = this.state;
    const {
      projectTypes,
      officesProps,
      clients,
      clientContacts,
      accountManagersByOffice,
      allCurrencies,
      allEngagementTypes,
    } = this.props;
    return (
      <div className='step'>
        <Scrollbars
          className='scrollbars scrollbars__add-project'
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          <div className='step__row border'>
            <div>
              <TextField
                formClassName='step__form'
                inputClassName='step__input'
                id='projectNameInput'
                label='Project Name'
                placeholder='Add Name'
                maxlength='100'
                name='name'
                error={projectNameError}
                value={name}
                onChange={this.onChangeDataProject}
                isRequired
                clearValue={this.clearValue('name')}
              />
              {projectNameError ? <p className='project-input-error'>Project with the same name already exists</p> : null}
            </div>
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Project Type'
              value={projectType}
              placeholder='Choose Project Type'
              name='projectType'
              onChange={this.onChangeDataProject}
              selectOptions={projectTypes}
              isOptionObject
              isRequired
              inputProps={{
                name: 'projectType',
                id: 'project_type',
              }}
              clearValue={this.clearValue('projectType')}
            />
            <div>
              <TextField
                formClassName='step__form'
                inputClassName='step__input'
                id='projectCodeInput'
                label='Project Code'
                placeholder='Project Code'
                maxlength='10'
                name='projectCode'
                value={projectCode}
                onChange={this.onChangeDataProject}
                isRequired={false}
                clearValue={this.clearValue('projectCode')}
              />
            </div>
            <SearchDropdown
              formClassName='step__form'
              selectClassName='step__search-dropdown step__search-dropdown-row-1'
              label='Engagement Type'
              placeholder='Choose Engagement Type'
              value={engagementTypeId}
              onChange={this.onChangeEngagementType}
              options={allEngagementTypes}
              isRequired
              inputProps={{
                name: 'engagementTypeId',
                id: 'engagement_type',
              }}
              showClear
            />
          </div>
          <div className='step__row border'>
            <MultiSelect
              className='step__multiselect'
              selected={offices}
              title='Office'
              onChange={this.onChangeOffice}
              options={officesProps}
              placeholder='Choose Offices'
              isRequired
              clearValue={this.clearOffices}
              tabId='office-tabindex'
            />
            <MultiSelect
              className='step__multiselect'
              selected={primaryOffices}
              title='Primary Offices'
              onChange={this.onChangePrimaryOffices}
              options={officesProps.filter(item => offices.find(elem => item.Id === elem))}
              placeholder='Choose Primary Offices'
              isRequired
              clearValue={this.clearPrimaryOffices}
              tabId='primaryOffices-tabindex'
              disabled={!offices.length}
            />
            <SearchDropdown
              formClassName='step__form media_z-index_create-project'
              selectClassName='step__search-dropdown step__search-dropdown-row-2'
              label='Company name'
              placeholder='Choose Company name'
              value={clientId}
              onChange={this.onChangeClient}
              options={clients}
              isRequired
              inputProps={{
                name: 'Client',
                id: 'client',
              }}
              isAddingFeature
            />
            <SearchDropdown
              formClassName='step__form create-project__search-dropdown--disabled'
              selectClassName='step__search-dropdown  step__search-dropdown-row-2'
              label='Client Manager'
              placeholder='Choose Client Manager'
              value={clientManagerId}
              onChange={this.onChangeClientManager}
              options={clientContacts.filter(item => item.ClientId === clientId)}
              disabled={clientManagerDisebled}
              isRequired={!isBillableTrainee}
              inputProps={{
                name: 'Client Manager',
                id: 'client_manager',
              }}
              helperText='Choose Client first'
              showClear
              isAddingFeature
              parentEntityId={clientId}
              isClientManager
            />
          </div>
          <div className='step__row border'>
            <SearchDropdown
              formClassName='step__form create-project__search-dropdown--disabled'
              selectClassName='step__search-dropdown step__search-dropdown-row-3'
              label='Business Development Manager'
              placeholder='Choose Business Development Manager'
              value={accountManagerId}
              onChange={this.onChangeAccountManager}
              options={accountManagersByOffice}
              isRequired={!isBillableTrainee}
              inputProps={{
                name: 'accountManagerId',
                id: 'account_manager',
              }}
              showClear
              disabled={!offices.length}
            />
            <div className='step__multi-suggest-box multi-box-wrapper multi-sagges-box__size'>
              <MultiSuggestBox
                onAdd={this.changeDeliveryManagersValue}
                label='Technical Account Manager'
                placeholder='Choose Technical Account Manager'
                options={deliveryManagersOptions}
                isMulti
                suggesValue={currentDeliveryManagers}
                handleChangeSuggestBox={this.handleChangeSuggestBox}
                blockAddNewItem
                isDisabled={!offices.length}
              />
            </div>
            <div className='step__multi-suggest-box multi-box-wrapper multi-sagges-box__size'>
              <MultiSuggestBox
                onAdd={this.changeEngineeringManagersValue}
                label='Delivery Manager'
                placeholder='Choose Delivery manager'
                options={engineeringManagersOption}
                isMulti
                suggesValue={currentEngineeringManagers}
                handleChangeSuggestBox={this.handleChangeSuggestBoxEngManagers}
                blockAddNewItem
                isDisabled={!offices.length}
              />
            </div>
          </div>
          <div className='step__row border'>
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='projectCodeInput'
              label='Project Alias'
              placeholder='Add Alias'
              maxlength='50'
              name='aliases'
              value={aliases}
              onChange={this.onChangeDataProject}
              isRequired={false}
              clearValue={this.clearValue('aliases')}
            />
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              placeholder='Choose Start Date'
              label='Start Date'
              value={startDate}
              onChange={this.handleChangeDate}
              isRequired
              name='startDate'
              isStartDate
            />
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              placeholder='Choose End Date'
              label='End Date'
              disabled={endDateDisebled}
              minVal={startDate}
              value={endDate}
              onChange={this.handleChangeDate}
              helperText='Choose Start Date first'
              name='endDate'
              clearValue={this.clearEndDateProject}
              isEndDate
            />
          </div>
          <div className='step__row border'>
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Currency'
              value={currency}
              placeholder='Choose Currency'
              name='currency'
              onChange={this.handleChangeCurrency}
              selectOptions={allCurrencies}
              isOptionObject
              isRequired
              inputProps={{
                name: 'Currency',
                id: 'currency',
              }}
              clearValue={this.clearValue('currency')}
            />
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='projectBillingCode'
              label='Billing Code'
              placeholder='Add Billing Code'
              maxlength='50'
              name='ProjectBillingCode'
              value={ProjectBillingCode}
              onChange={this.onChangeDataProject}
              isRequired={false}
              clearValue={this.clearValue('ProjectBillingCode')}
            />
          </div>
          <div className='step__row description-row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__description'
              id='project-description'
              label='Project Description'
              placeholder='Add Description'
              maxlength='4000'
              name='description'
              value={description}
              onChange={this.onChangeDataProject}
              fullWidth
              multiline
            />
          </div>
        </Scrollbars>
        <div className='buttons'>
          <div
            className={classNames(
              'button save-and-close',
              { 'btn-disabled': !isStep1FieldsRequired },
            )}
            onClick={this.onSave}
            title={!isStep1FieldsRequired ? 'All required fields should be filled' : null}
          >
            <span>Save and Close</span>
          </div>
          <div className='right-side-buttons'>
            <div className='button cancel' onClick={this.onCancel}>
              <span>Cancel</span>
            </div>
            <div
              className={classNames(
                'button next',
                { 'btn-disabled': !isStep1FieldsRequired },
              )}
              onClick={this.onNextStep}
              title={!isStep1FieldsRequired ? 'All required fields should be filled' : null}
            >
              <span>Next</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Step.propTypes = {
  onStepChange: PropTypes.func,
  initialState: PropTypes.object,
  deliveryManagersByOffice: PropTypes.array.isRequired,
  engineeringManagersByOffice: PropTypes.array.isRequired,
  accountManagersByOffice: PropTypes.array.isRequired,
  clients: PropTypes.array,
  clientContacts: PropTypes.array,
  projectTypes: PropTypes.array,
  setClientId: PropTypes.func,
};

export default connect((store) => ({
  projectTypes: store.projectsReducer.projectTypes,
  projects: store.projectsReducer.projects,
  clients: store.filtersReducer.filters.Clients,
  clientContacts: store.projectsReducer.clientContacts,
  officesProps: store.filtersReducer.filters.Offices,
  isProjectNameDublicateError: store.projectsReducer.isProjectNameDublicateError,
  deliveryManagersByOffice: store.projectsReducer.deliveryManagersByOffice,
  engineeringManagersByOffice: store.projectsReducer.engineeringManagersByOffice,
  accountManagersByOffice: store.projectsReducer.accountManagersByOffice,
  allCurrencies: store.projectsReducer.allCurrencies,
  allEngagementTypes: store.projectsReducer.allEngagementTypes,
}))(Step);
