import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomIcon from '../../../../common/components/icon/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../../common/components/redux-form/components/button';
import PropTypes from 'prop-types';
import Select from '../../../../common/components/form-items/select/select';
import TextField from '../../../../common/components/form-items/input/text-field';
import Checkbox from '../../../../common/components/form-items/checkbox/checkbox';

class WorkingPeriodRestrictionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SowTypeId: '',
      MaxPeriod: null,
      Interval: null,
      IsNotRestricted: true,
      isErrorForm: true,
    };
  }

  componentDidMount() {
    this.setDefaultEditData();
  }

  componentDidUpdate(prevProps) {
    const { editWorkingPeriodRestrictionObject } = this.props;
    if (prevProps.editWorkingPeriodRestrictionObject !== editWorkingPeriodRestrictionObject) {
      this.setDefaultEditData();
    }
  }

  setDefaultEditData = () => {
    const { editWorkingPeriodRestrictionObject, isEditMode } = this.props;
    if (isEditMode) {
      this.setState({
        SowTypeId: editWorkingPeriodRestrictionObject.SowType.Id,
        MaxPeriod: editWorkingPeriodRestrictionObject.MaxPeriod,
        Interval: editWorkingPeriodRestrictionObject.Interval,
        IsNotRestricted: editWorkingPeriodRestrictionObject.IsNotRestricted,
      }, this.checkForm);
    }
  }

  checkForm = () => {
    const { sowTypes } = this.props;
    const {
      SowTypeId, MaxPeriod, Interval, IsNotRestricted,
    } = this.state;

    const isSowTypeValid = sowTypes.find(item => item.Id === SowTypeId) || SowTypeId === 0;
    const isMaxPeriodValid = MaxPeriod > 0;
    const isIntervalValid = Interval > 0;

    const isInvalid = !(isSowTypeValid && (IsNotRestricted || (isMaxPeriodValid && isIntervalValid)));
    this.setState({
      isErrorForm: isInvalid,
    });
  }

  confirmAddWorkingPeriodRestriction = () => {
    const { confirmDialog, closeDialog, isEditMode } = this.props;
    const {
      isErrorForm, SowTypeId, MaxPeriod, Interval, IsNotRestricted,
    } = this.state;
    if (!isErrorForm) {
      confirmDialog({
        SowTypeId, MaxPeriod, Interval, IsNotRestricted,
      }, isEditMode);
      closeDialog();
    }
  }

  handleChangeData = (e) => {
    let value;

    switch (e.target.name) {
      case 'MaxPeriod':
      case 'Interval':
        // eslint-disable-next-line no-case-declarations
        const parsedValue = parseInt(e.target.value, 10);
        if (Number.isNaN(parsedValue) && e.target.value !== '') {
          return;
        }

        value = parsedValue;
        break;
      default:
        value = e.target.value;
        break;
    }

    this.setState({
      [e.target.name]: value,
    }, this.checkForm);
  }

  handleChangeIsNotRestricted = () => {
    const { IsNotRestricted } = this.state;
    this.setState({
      IsNotRestricted: !IsNotRestricted,
      MaxPeriod: null,
      Interval: null,
    }, this.checkForm);
  }

  render() {
    const { closeDialog, isEditMode, sowTypes } = this.props;
    const {
      SowTypeId, MaxPeriod, Interval, IsNotRestricted, isErrorForm,
    } = this.state;

    return (
      <Dialog
        open
        aria-labelledby='responsive-dialog-title'
        className='working-period-restriction__dialog-width'
      >
        <DialogTitle>
          <span className='show-dialog-title'>{!isEditMode ? 'Add Working Period Restriction' : 'Edit Working Period Restriction'}</span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className='background-check-modal-row'>
            <Select
              formClassName='step__form'
              selectClassName='certification-step__select certification-step__select-padding'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='SOW Type'
              value={SowTypeId}
              placeholder='Choose SOW Type'
              name='SowTypeId'
              onChange={this.handleChangeData}
              selectOptions={SowTypeId === 0 ? [{ Id: 0, Name: 'Default' }] : sowTypes}
              isOptionObject
              isRequired
              inputProps={{
                name: 'SowTypeId',
                id: 'SowTypeId',
              }}
              disabled={isEditMode}
            />
            <Checkbox
              checkboxContainerStyle='employee-training-step__checkbox margin-left-10'
              label='Is Not Restricted'
              value='Is Not Restricted'
              checked={IsNotRestricted}
              name='IsNotRestricted'
              onClick={this.handleChangeIsNotRestricted}
            />
          </div>
          {!IsNotRestricted && <div className='background-check-modal-row'>
            <TextField
              formClassName='step__form'
              inputClassName='certification-step__select certification-step__text'
              label='Max period (months)'
              placeholder='Set max period'
              maxlength='2'
              name='MaxPeriod'
              value={MaxPeriod}
              onChange={this.handleChangeData}
              isRequired
              disabled={IsNotRestricted}
            />
            <TextField
              formClassName='step__form margin-left-10'
              inputClassName='certification-step__select certification-step__text'
              label='Interval (months)'
              placeholder='Set interval'
              maxlength='2'
              name='Interval'
              value={Interval}
              onChange={this.handleChangeData}
              isRequired
              disabled={IsNotRestricted}
            />
          </div>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            className='button cancel background-check-dialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmAddWorkingPeriodRestriction}
            className={`button ${!isErrorForm ? 'next' : 'btn-disabled'} background-check-dialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

WorkingPeriodRestrictionModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
  isEditMode: PropTypes.bool,
};

export default WorkingPeriodRestrictionModal;
