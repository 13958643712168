import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import isObjectNotEquals from 'utils/object-comparison';
import isEmptyObject from 'utils/isEmptyObject';
import { PROJECT_ROLES_STATUS_ACTIVE } from 'utils/const-variable';
import moment from 'moment';
import {
  getAccountingReports, changeColumnsToDisplay, setAccountingReports,
  setAccountingListFiltersValue, resetAccountingListFiltersValue,
  setAccountingListSettings, resetAccountingListSettings,
} from '../../actions/accountingActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { debounce } from 'services/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import Scrollbars from 'react-custom-scrollbars';
import { CellRate } from './cell-accounting-rate';
import { CellLink } from 'common/components/list/custom-cell/cell-link';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { sortFilterList } from 'common/components/list/sortList';
import { getFilters } from 'common/actions/filtersActions.js';
import { getClients } from '../../../clients/actions/clientsActions';
import { getManagersByOffice } from '../../../projects/actions/projectsActions';
import { handleEditViewContract } from 'pages/contracts/actions/contractsActions';
import { getFiltersForWorkForce } from '../../actions/workforceActions';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { emptyArray } from 'utils/const-variable';

const filtersNames = [
  'ProjectStatuses',
  'Offices',
  'EmployeeAssignmentStatuses',
];

export class Accounting extends React.Component {
  constructor(props) {
    super(props);
    this.headerWrapperRef = React.createRef();
    this.scrollBarRef = React.createRef();
    this.state = {
      currentDate: moment().format('MM/DD/YYYY'),
      hasMoreReports: true,
      scrollBarValues: null,
    };
  }

  componentDidMount() {
    const {
      getFilters, getFiltersForWorkForce, getManagersByOffice, getClients, statuses,
      setComponent,
    } = this.props;
    const { currentDate } = this.state;
    getFilters(filtersNames);
    getFiltersForWorkForce();
    this.setDefaultFilter(statuses);
    getManagersByOffice();
    getClients();
    setComponent({ title: `Accounting report - ${currentDate}` });
  }

  componentDidUpdate(prevProps) {
    const {
      getAccountingReports, statuses, listSettings,
    } = this.props;
    const {
      searchValue, sortColumn, sortDirection, filters, takeAmountReports,
    } = listSettings;
    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      getAccountingReports(searchValue, takeAmountReports, 0, sortColumn, sortDirection, filters);
    }
    if (isEmptyObject(filters) && prevProps.statuses !== statuses) {
      this.setDefaultFilter(statuses);
    }
  }

  componentWillUnmount() {
    const {
      resetComponent, setAccountingReports, resetAccountingListFiltersValue, resetAccountingListSettings,
    } = this.props;
    setAccountingReports(emptyArray);
    resetComponent();
    resetAccountingListFiltersValue();
    resetAccountingListSettings();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setAccountingListSettings } = this.props;
    setAccountingListSettings({ sortColumn, sortDirection });
  };

  downloadXLSXDocument = () => {
    const { currentDate } = this.state;
    const { downloadXLSXDocument, listSettings } = this.props;
    const { filters, searchValue } = listSettings;
    downloadXLSXDocument(filters, searchValue, 'accounting-reports/export-xlsx?Search=', `Accounting Reports ${currentDate}.xlsx`);
  }

  changeAmountReports = (page) => {
    const {
      isLoading,
      listSettings: {
        takeAmountReports,
      },
      setAccountingListSettings,
    } = this.props;
    const magnificationFactor = 15;
    const increaseParams = takeAmountReports + page * magnificationFactor;
    if (!isLoading) {
      this.checkIfNeedMoreReports(increaseParams);
      setAccountingListSettings({ takeAmountReports: increaseParams });
    }
  }

  checkIfNeedMoreReports = (takeAmountReports) => {
    const { totalCount } = this.props;
    this.setState({
      hasMoreReports: takeAmountReports > totalCount ? false : true,
    });
  }

  getLinkCompany = (id) => ({
    pathname: `/clients/${id}`,
    state: {
      filters: {},
      search: '',
    },
  });

  getLinkProject = (id) => ({
    pathname: `/projects/${id}`,
    state: {
      filters: {},
      search: '',
    },
  });

  getLinkEmployee = (id) => ({
    pathname: `/employees/${id}`,
    state: {
      filters: {},
      search: '',
    },
  });

  transitionOnApplication = (reportItem, property) => {
    const {
      defaultCheckedShowRoles,
      handleEditViewContract, role,
    } = this.props;
    const rights = role.Permissions.ManageProjectContracts ? 'edit' : 'view';
    switch (property) {
      case 'Company':
        return this.getLinkCompany(reportItem[property].Id);
      case 'Project':
        return this.getLinkProject(reportItem[property].Id, defaultCheckedShowRoles);
      case 'Sow':
        handleEditViewContract(reportItem.Project.Id, defaultCheckedShowRoles, 'sow', reportItem[property].Id, rights);
        break;
      case 'PoOriginal':
        handleEditViewContract(reportItem.Project.Id, defaultCheckedShowRoles, 'po', reportItem[property].Id, rights);
        break;
      case 'Amendment':
        handleEditViewContract(reportItem.Project.Id, defaultCheckedShowRoles, 'amendment', reportItem[property].Id, rights);
        break;
      case 'Employee':
        return this.getLinkEmployee(reportItem[property].Id);
      default:
        break;
    }
  }

  setDefaultFilter = (statuses) => {
    const { getAccountingReports, listSettings: { filters } } = this.props;
    const defaultFilters = statuses.reduce((acc, curValue) => {
      const defaultEmpl = [];
      if (
        curValue.Name === PROJECT_ROLES_STATUS_ACTIVE
      ) {
        defaultEmpl.push(curValue.Id);
      }
      return [...acc, ...defaultEmpl];
    }, []);
    const currentFilters = defaultFilters.length ? {
      ...filters,
      ProjectStatuses: defaultFilters,
    } : {
      ...filters,
    };
    this.setState(({
      filters: currentFilters,
    }), () => {
      const {
        listSettings: {
          searchValue, filters, sortColumn, sortDirection, takeAmountReports,
        },
      } = this.props;
      getAccountingReports(searchValue, takeAmountReports, 0, sortColumn, sortDirection, filters);
    });
  }

  getAccountingReports = (searchValue) => {
    const { setAccountingListSettings } = this.props;
    setAccountingListSettings({ searchValue });
  }

  applyFilters = (filters) => {
    const { textDataForFilters, setAccountingListSettings } = this.props;
    const newFilters = {
      ...filters,
      ...textDataForFilters,
    };
    const isExistsFilters = Object.keys(newFilters).some((filter) => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setAccountingListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  }

  resetFilters = () => {
    const { setAccountingListSettings } = this.props;
    setAccountingListSettings({ filters: {} });
  }

  handleChangeTextField = ({ target }) => {
    const { setAccountingListFiltersValue } = this.props;
    const { name, value } = target;
    setAccountingListFiltersValue({ [name]: value });
  }

  clearValue = (target) => {
    const { setAccountingListFiltersValue } = this.props;
    setAccountingListFiltersValue({ [target]: '' });
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  renderTrackHorizontal = (props) => (<div {...props} className='track-horizontal' />);

  handleScrollbarsUpdate = (values) => {
    const { scrollBarValues } = this.state;
    if (scrollBarValues) {
      if (values.scrollLeft === 0) {
        this.scrollBarRef.current.scrollLeft(scrollBarValues.scrollLeft);
      }
      if (values.scrollTop === 0) {
        this.scrollBarRef.current.scrollTop(scrollBarValues.scrollTop);
      }
    }
    if (this.headerWrapperRef.current) {
      this.headerWrapperRef.current.scrollLeft = values.scrollLeft;
    }
    this.setState({
      scrollBarValues: values,
    });
  }

  getAssignmentStatusTitle = (item) => {
    return item && item.AssignmentStatus && item.AssignmentStatus.Name;
  }

  render() {
    const {
      location,
      reports,
      statuses,
      offices,
      accountManagers,
      companies,
      projectsName,
      hasFinancAccess,
      changeColumnsToDisplay,
      columnsToDisplay,
      employeeAssignmentStatuses,
      textDataForFilters,
      listSettings: {
        sortColumn, sortDirection, filters,
      },
    } = this.props;
    const { hasMoreReports } = this.state;
    const projectStatuses = statuses && statuses.length ? statuses : [];
    const employeeStatuses = employeeAssignmentStatuses && employeeAssignmentStatuses.length ? employeeAssignmentStatuses : [];
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          location={location}
          showExportButton
          exportDocument={this.downloadXLSXDocument}
          searchPlaceholder='Search Reports'
          columnsToDisplay={columnsToDisplay}
          changeColumnsToDisplay={changeColumnsToDisplay}
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.getAccountingReports}
          items={[{
            name: 'Project statuses',
            id: 'ProjectStatuses',
            options: sortFilterList(projectStatuses) || [],
            multiSelect: true,
            placeholder: 'All Statuses',
            filtersItem: filters && filters.ProjectStatuses,
            className: 'z-index_50',
          }, {
            name: 'Offices',
            id: 'OfficeIds',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.OfficeIds,
            className: 'z-index_48',
          },
          {
            name: 'Account Manager',
            id: 'AccountManagerIds',
            options: sortFilterList(accountManagers) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Business Development Managers',
            filtersItem: filters && filters.AccountManagerIds,
            className: 'z-index_46',
          },
          {
            name: 'Company name',
            id: 'CompanyIds',
            options: sortFilterList(companies) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Company',
            filtersItem: filters && filters.CompanyIds,
            className: 'z-index_44',
          },
          {
            name: 'Project name',
            id: 'ProjectIds',
            options: sortFilterList(projectsName) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Project',
            filtersItem: filters && filters.ProjectIds,
            className: 'z-index_42',
          },
          {
            name: 'Billing code',
            id: 'BillingCode',
            textField: true,
            placeholder: 'Billing code',
            value: textDataForFilters.BillingCode,
            onChange: this.handleChangeTextField,
            clearValue: this.clearValue,
            className: 'z-index_40',
          },
          {
            name: 'Employee Status',
            id: 'EmployeeStatuses',
            options: sortFilterList(employeeStatuses) || [],
            multiSelect: true,
            placeholder: 'All status',
            filters: filters && filters.EmployeeAssignmentStatuses,
            className: 'z-index_38',
          }]}
        />
        <List
          columnsToDisplay={columnsToDisplay}
          items={reports.accounting}
          hideDots
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          fixedHeader
          dontShowOptions
          viewFinancialInfo={hasFinancAccess}
          transition={this.transitionOnApplication}
          config={{
            'Rate': { cell: CellRate },
            'Company': { cell: CellLinkForRedirect },
            'Project': { cell: CellLinkForRedirect },
            'Sow': { cell: CellLink },
            'PoOriginal': { cell: CellLink },
            'Amendment': { cell: CellLink },
            'Employee': { cell: CellLinkForRedirect },
            'ProjectStatus': { cell: CustomStatusCell },
            'AssignmentStatus': { cell: CustomStatusCell, getTitle: this.getAssignmentStatusTitle },
          }}
          isAccountingList
          // eslint-disable-next-line react/no-unstable-nested-components
          headerWrapper={(props) => <div style={{ overflow: 'hidden' }} ref={this.headerWrapperRef}>{props.children}</div>}
          // eslint-disable-next-line react/no-unstable-nested-components
          contentWrapper={(props) => (
            <Scrollbars
              ref={this.scrollBarRef}
              style={{ height: 'calc(100% - 50px)', flex: 1 }}
              renderTrackHorizontal={this.renderTrackHorizontal}
              onUpdate={this.handleScrollbarsUpdate}
            >
              <InfiniteScroll
                pageStart={1}
                loadMore={debounce(this.changeAmountReports, 500)}
                hasMore={hasMoreReports}
                useWindow={false}
                initialLoad={false}
                threshold={500}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                {props.children}
              </InfiniteScroll>
            </Scrollbars>
          )}
        />
      </div>
    );
  }
}

Accounting.propTypes = {
  location: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired,
  offices: PropTypes.array,
  accountManagers: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  projectsName: PropTypes.array,
  getAccountingReports: PropTypes.func.isRequired,
  downloadXLSXDocument: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  getFiltersForWorkForce: PropTypes.func.isRequired,
  getManagersByOffice: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
};

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  statuses: store.filtersReducer.filters.ProjectStatuses,
  offices: store.filtersReducer.filters.Offices,
  accountManagers: store.projectsReducer.accountManagersByOffice,
  employeeAssignmentStatuses: store.filtersReducer.filters.EmployeeAssignmentStatuses,
  companies: store.clientsReducer.clients,
  projectsName: store.reportsReducer.filters.projectsName,
  totalCount: store.reportsReducer.accountingTotalCoutn,
  hasFinancAccess: store.authReducer.user.Permissions.HasFinancialAccess,
  defaultCheckedShowRoles: store.showRoles.isOnlyActiveRoles,
  role: store.authReducer.user,
  columnsToDisplay: store.accountingReducer.columnsToDisplay,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.accountingReducer.listSettings,
  textDataForFilters: store.accountingReducer.textDataForFilters,
}), {
  getAccountingReports,
  downloadXLSXDocument,
  handleEditViewContract,
  changeColumnsToDisplay,
  getFilters,
  getFiltersForWorkForce,
  getManagersByOffice,
  getClients,
  setComponent,
  resetComponent,
  setAccountingReports,
  setAccountingListFiltersValue,
  resetAccountingListFiltersValue,
  setAccountingListSettings,
  resetAccountingListSettings,
})(Accounting);
