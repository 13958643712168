export const filterChoosenExtendRoles = (extendedRoles, value, parent, isSelectedCheckbox, openedRoles) => {
  const roles = [...extendedRoles];
  if (!parent) {
    roles.map(item => item.Id === value ? item.checked = isSelectedCheckbox : null);
    roles.forEach((role, index) => {
      if (role.checked && openedRoles && openedRoles.includes(index.toString())) {
        role.Employees.forEach((child) => {
          if (typeof child.checked === 'undefined' && child.Status.Name !== 'Removed') {
            child.checked = isSelectedCheckbox;
          }
        });
      }
    });
    return roles;
  }
  roles.map((item) => item.Id === parent && item.Employees.map(child => child.Id === value ? child.checked = (child.checked === true ? false : isSelectedCheckbox) : null));
  return roles;
};
