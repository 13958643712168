import React from 'react';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from '../../../../utils/applicationTitle';
import ListToolbar from '../../../../common/components/list-toolbar/list-toolbar';
import moment from 'moment/moment';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { getFilters } from '../../../../common/actions/filtersActions';
import { resetComponent, setComponent } from '../../../../common/actions/headerActions';
import { connect } from 'react-redux';
import { sortFilterList } from '../../../../common/components/list/sortList';
import isEmptyObject from '../../../../utils/isEmptyObject';
import { resetTeamCompositionListSettings, setTeamCompositionListSettings } from '../../actions/teamCompositionActions';
import { PROJECT_TYPE_BILLABLE, TEAM_COMPOSITION_PROJECT_BASED } from '../../../../utils/const-variable';

const filterNames = [
  'Offices',
  'TeamCompositionProjects',
  'ProjectTypes',
  'TeamCompositionReportTypes',
];

export class TeamComposition extends React.Component {
  constructor(props) {
    super(props);

    this.datesToSelect = [];
  }

  componentDidMount() {
    const { setComponent, getFilters } = this.props;

    getFilters(filterNames);
    this.datesToSelect = this.getDatesToSelect();
    this.setDefaultFilters();

    setComponent({ title: `Team Composition Report - ${moment().format('MMMM')} ${moment().year()}` });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { setComponent, listSettings } = this.props;
    const { filters } = listSettings;
    const month = this.datesToSelect.find(date => date.Id === filters.Month);
    const monthName = month.Name && month.Name.replace(' - ', ' ');

    setComponent({ title: `Team Composition Report - ${monthName}` });
  }

  componentWillUnmount() {
    const { resetComponent, resetTeamCompositionListSettings } = this.props;

    resetComponent();
    resetTeamCompositionListSettings();
  }

  setDefaultFilters = () => {
    const {
      listSettings: { filters }, setTeamCompositionListSettings,
    } = this.props;

    const currentMonth = this.datesToSelect.find(date => date.Name === moment().format('MMMM - YYYY'));

    const currentFilters = {
      ProjectTypes: filters.ProjectTypes || [PROJECT_TYPE_BILLABLE],
      Offices: filters.Offices || [],
      ReportType: filters.ReportType || TEAM_COMPOSITION_PROJECT_BASED,
      Month: filters.Month || currentMonth.Id,
    };

    setTeamCompositionListSettings({ filters: currentFilters });

    return currentFilters;
  };

  getDatesToSelect = () => {
    const monthsToEndOfYear = 12 - moment().month();
    const datesToSelect = [];
    let id = 1;
    for (let i = 12; i > 0; i--) {
      const date = moment().subtract(i, 'month');
      datesToSelect.push({ Id: id++, Name: date.format('MMMM - YYYY') });
    }
    for (let i = 0; i < monthsToEndOfYear; i++) {
      const date = moment().add(i, 'month');
      datesToSelect.push({ Id: id++, Name: date.format('MMMM - YYYY') });
    }

    return datesToSelect;
  }

  applyFilters = (filters) => {
    const { setTeamCompositionListSettings } = this.props;
    const newFilters = {
      ...filters,
    };

    const isExistsFilters = Object.keys(newFilters).some(filter => newFilters[filter] && (typeof newFilters[filter] === 'number'
      || !isEmptyObject(newFilters[filter])));
    setTeamCompositionListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  };

  resetFilters = () => {
    const { setTeamCompositionListSettings } = this.props;

    const currentMonth = this.datesToSelect.find(date => date.Name === moment().format('MMMM - YYYY'));

    const newFilters = {
      ProjectTypes: [PROJECT_TYPE_BILLABLE],
      ReportType: TEAM_COMPOSITION_PROJECT_BASED,
      Month: currentMonth.Id,
    };

    setTeamCompositionListSettings({ filters: { ...newFilters } });
  };

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { filters } = listSettings;
    const month = this.datesToSelect.find(date => date.Id === filters.Month);
    const downloadFilters = {
      ...filters,
      Month: month.Name,
    };

    downloadXLSXDocument(
      downloadFilters,
      '',
      'team-composition-reports/export-xlsx?SearchValue=',
      `Team Composition Report - ${month.Name}.xlsx`,
    );
  };

  render() {
    const {
      offices, projects, projectTypes, teamCompositionReportTypes, listSettings: { filters },
    } = this.props;

    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          containerClassName='without-search'
          searchField={false}
          showExportButton
          exportDocument={this.downloadXLSXDocument}
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          items={[{
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
          }, {
            name: 'Report Type',
            id: 'ReportType',
            options: sortFilterList(teamCompositionReportTypes) || [],
            multiSelect: false,
            suggestBox: false,
            searchDropdown: true,
            placeholder: 'Select Report Type',
            filtersItem: filters && filters.ReportType,
          }, {
            name: 'Projects',
            id: 'Projects',
            options: sortFilterList(projects) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Projects',
            filtersItem: filters && filters.Projects,
          }, {
            name: 'Project Types',
            id: 'ProjectTypes',
            options: sortFilterList(projectTypes) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Project Types',
            filtersItem: filters && filters.ProjectTypes,
          }, {
            name: 'Month',
            id: 'Month',
            options: this.datesToSelect || [],
            placeholder: 'Select Month',
            hideAllElement: true,
            value: filters && filters.Month,
            filtersItem: filters && filters.Month,
          }]}
        />
      </div>
    );
  }
}

export default connect(store => ({
  offices: store.filtersReducer.filters.Offices,
  projects: store.filtersReducer.filters.TeamCompositionProjects,
  projectTypes: store.filtersReducer.filters.ProjectTypes,
  teamCompositionReportTypes: store.filtersReducer.filters.TeamCompositionReportTypes,
  listSettings: store.teamCompositionReducer.listSettings,
}), {
  getFilters,
  downloadXLSXDocument,
  setComponent,
  resetComponent,
  setTeamCompositionListSettings,
  resetTeamCompositionListSettings,
})(TeamComposition);
