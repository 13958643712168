import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../icon/Icon';
import { getId } from 'services/location';
import { UpdateDocumentItem } from './update-document-item';
import { getDocumentTypeName } from 'utils/documentsUtils';

const correctUrlRegExp = new RegExp('^(https?:\\/\\/)' // protocol
  + '(([a-z\\d]{1,}:[a-z\\d]{1,}@)?)' // auth
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // ip address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port
  + '(\\?[;&amp;a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i');
const httpRegExp = new RegExp('^(https?:?)');

export class DocumentItem extends React.Component {
  constructor(props) {
    super(props);
    const { item, itemId } = this.props;
    this.state = {
      comment: item.Comment,
      showOptions: false,
      entityId: getId(),
      addDocument: {
        Id: item.Id ? item.Id : itemId,
        URL: item.URL,
        Comment: item.Comment,
        DocumentType: this.getDocumentType(),
      },
      editResumeBlockShow: false,
      blockSaveDocument: false,
      isNeedUpOptions: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { item, itemId } = this.props;
    if (prevProps.item !== item) {
      this.setState({
        addDocument: {
          Id: item.Id ? item.Id : itemId,
          URL: item.URL,
          Comment: item.Comment,
          DocumentType: this.getDocumentType(),
        },
      });
    }
  }

  getDocumentType = () => {
    const { item, isDocumentType } = this.props;
    return isDocumentType && item.DocumentType ? {
      Id: item.DocumentType.Id,
      Name: item.DocumentType.Name,
    } : null;
  }

  toggleShowOptions = (item, event) => {
    const { showOptions } = this.state;
    const { onItemClick } = this.props;
    if (item === 'options') {
      const elementPositions = event.target.getBoundingClientRect();
      const windowHeight = document.documentElement.clientHeight;
      const minLimitToBottom = 50;
      this.setState({
        showOptions: !showOptions,
        isNeedUpOptions: ((windowHeight - elementPositions.bottom) < minLimitToBottom),
      });
      event.stopPropagation();
    } else {
      onItemClick && onItemClick(item);
    }
  }

  hideOptions = () => {
    this.setState({
      showOptions: false,
    });
  }

  showEditDocument = () => {
    this.setState({
      editResumeBlockShow: true,
    });
  }

  closeEditDocument = (event) => {
    const { item } = this.props;
    const { addDocument } = this.state;
    event.stopPropagation();
    this.setState({
      addDocument: {
        ...addDocument,
        URL: item.URL,
        Comment: item.Comment,
      },
      editResumeBlockShow: false,
    });
  }

  deleteDocument = () => {
    const { onDeleteClick, item } = this.props;
    const { entityId, addDocument } = this.state;
    onDeleteClick({
      item,
      entityId,
      url: `employees/resume?id=${item.Id}`,
      searchUpdateSaga: 'employees/resume',
      stateItemId: addDocument.Id,
    });
    this.setState({
      editResumeBlockShow: false,
    });
  }

  onChangeNewDocumentObj = ({ target }) => {
    const { addDocument } = this.state;
    const { documentSelectOptions } = this.props;
    if (target.name === 'DocumentType') {
      this.setState({
        addDocument: {
          ...addDocument,
          DocumentType: {
            Id: target.value,
            Name: getDocumentTypeName(target.value, documentSelectOptions),
          },
        },
      }, this.newDocumentValidation);
    } else {
      this.setState({
        addDocument: {
          ...addDocument,
          [target.name]: target.value,
        },
      }, this.newDocumentValidation);
    }
  }

  newDocumentValidation = () => {
    const { addDocument } = this.state;
    const { isDocumentType } = this.props;
    if (isDocumentType) {
      this.setState({
        blockSaveDocument: addDocument.URL !== '' && addDocument.Comment !== '' && addDocument.DocumentType ? true : false,
      });
    } else {
      this.setState({
        blockSaveDocument: addDocument.URL !== '' && addDocument.Comment !== '' ? true : false,
      });
    }
  };

  newDocumentApply = (event) => {
    const { onEditClick } = this.props;
    const { addDocument, blockSaveDocument } = this.state;
    if (blockSaveDocument) {
      this.setState({
        blockSaveDocument: false,
      });
      onEditClick(addDocument);
      this.closeEditDocument(event);
    }
  }

  urlValidation = () => {
    const { addDocument } = this.state;
    let newString = addDocument.URL;
    if (newString && !correctUrlRegExp.test(newString)) {
      newString = newString.replace(httpRegExp, '');
      return 'https://' + newString;
    }
    return addDocument.URL;
  }

  generateTimeKey = () => {
    return new Date().getTime();
  }

  render() {
    const {
      itemId,
      columnsToDisplay,
      hideDots,
      onDeleteClick,
      onEditClick,
      isEditable,
      isDocumentType,
      documentSelectOptions,
      keyId,
    } = this.props;
    const {
      showOptions,
      editResumeBlockShow,
      blockSaveDocument,
      addDocument,
      isNeedUpOptions,
    } = this.state;
    return (
      <div
        className={classNames('c-list__item-cont')}
        onClick={this.showEditDocument}
        key={keyId}
      >
        {
          columnsToDisplay.map((column) => {
            return column.isSelected ? (
              <div
                title={this.urlValidation()}
                key={itemId + JSON.stringify(column)}
                className={classNames(
                  'c-list__item-column',
                  column.className,
                  { 'c-list__status': column.Id === 'Status' },
                )}
              >
                {
                  editResumeBlockShow && isEditable ? <UpdateDocumentItem
                    item={addDocument}
                    onChangeNewDocumentObj={this.onChangeNewDocumentObj}
                    disableSaveDocument={blockSaveDocument}
                    newDocumentCancel={(event) => this.closeEditDocument(event)}
                    newDocumentApply={(event) => this.newDocumentApply(event)}
                    isDocumentType={isDocumentType}
                    documentSelectOptions={documentSelectOptions}
                  />
                    : <div className='c-list__header-col without-width-col'>
                      <a
                        href={this.urlValidation()}
                        target='_blank'
                        onClick={(event) => event.stopPropagation()}
                        rel='noopener noreferrer'
                      >
                        {addDocument.Comment}
                      </a>
                    </div>
                }
              </div>
            ) : column.isType
              ? (
                editResumeBlockShow && isEditable
                  ? null : <div className='c-list__header-col without-width-col' key={itemId + JSON.stringify(column)}>
                    <span
                      href={this.urlValidation()}
                      target='_blank'
                      onClick={(event) => event.stopPropagation()}
                    >
                      {addDocument.DocumentType && addDocument.DocumentType.Name}
                    </span>
                  </div>
              )
              : null;
          })
        }
        {!hideDots && isEditable ? <div
          className='c-list__options-column c-list__options-column__svg doclist__options--svg'
          onClick={this.toggleShowOptions.bind(this, 'options')}
        >
          <CustomIcon iconName='three-dots' />
          {showOptions
            ? (
              <div
                className={classNames('c-list-item__options-column__container', {
                  'c-list-item_push-options-up_low': isNeedUpOptions,
                })}
                onMouseLeave={this.hideOptions}
              >
                {
                  onEditClick && isEditable && (
                    <div
                      className='c-editable-list-item-delete-block__option'
                      onClick={onEditClick ? this.showEditDocument : null}
                    >
                      <span>Edit item</span>
                    </div>
                  )
                }
                {
                  onDeleteClick && isEditable && (
                    <div
                      className='c-editable-list-item-delete-block__option'
                      onClick={this.deleteDocument}
                    >
                      <span>Delete item</span>
                    </div>
                  )
                }
              </div>
            ) : null
          }
        </div>
          : <div className='c-list-item__options-column-container--no-option' />}
      </div>
    );
  }
}

DocumentItem.propTypes = {
  item: PropTypes.object,
  columnsToDisplay: PropTypes.array,
  onItemClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default connect(store => ({
  isAdmin: store.userRoleReducer.isAdmin,
  isOnlyActive: store.showRoles.isOnlyActiveRoles,
  roles: store.userRoleReducer.roles,
}))(DocumentItem);
