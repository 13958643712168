import {
  CHANGE_ACCOUNTING_COLUMNS_TO_DISPLAY,
  SET_ACCOUNTING_LIST_SETTINGS,
  RESET_ACCOUNTING_LIST_SETTINGS,
  SET_ACCOUNTING_LIST_FILTERS_VALUE,
  RESET_ACCOUNTING_LIST_FILTERS_VALUE,
} from '../actions/accountingActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'AccountManager',
  sortDirection: 'Up',
  filters: {},
  takeAmountReports: 50,
};

const defaultTextDataForFilters = {
  BillingCode: '',
};

const initialState = {
  columnsToDisplay: [
    {
      Name: 'Business Development Manager', Id: 'AccountManager', className: 'fixed-medium-report-col', isSelected: true, isObject: true, necessary: true,
    },
    {
      Name: 'Company name', Id: 'Company', className: 'fixed-medium-report-col', isSelected: true, isObject: true, necessary: true,
    },
    {
      Name: 'Project name', Id: 'Project', isSelected: true, isObject: true, className: 'fixed-medium-report-col',
    },
    {
      Name: 'SOW', Id: 'Sow', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'PO original', Id: 'PoOriginal', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'PO Extension', Id: 'PoExtension', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'Amendment', Id: 'Amendment', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'Billing Code', Id: 'BillingCode', className: 'fixed-medium-report-col', isSelected: true,
    },
    {
      Name: 'Role Name', Id: 'RoleName', className: 'fixed-medium-report-col', isSelected: true,
    },
    {
      Name: 'Rate', Id: 'Rate', className: 'fixed-medium-report-col', isSelected: true,
    },
    {
      Name: 'Currency', Id: 'Currency', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'Rate Unit', Id: 'RateUnit', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'Employee', Id: 'Employee', isObject: true, isSelected: true, className: 'fixed-medium-report-col',
    },
    {
      Name: 'Employee Status',
      Id: 'AssignmentStatus',
      isSelected: true,
      title: true,
      className: 'fixed-medium-report-col',
      isObject: true,
    },
    {
      Name: 'Comment', Id: 'AssignmentComment', className: 'fixed-medium-report-col', isSelected: true,
    },
    {
      Name: 'Office', Id: 'Office', className: 'fixed-medium-report-col', isSelected: true, isObject: true,
    },
    {
      Name: 'Start Date on project', Id: 'StartDate', className: 'fixed-medium-report-col', isSelected: true, isDate: true,
    },
    {
      Name: 'End Date on project', Id: 'EndDate', className: 'fixed-medium-report-col', isSelected: true, isDate: true,
    },
    {
      Name: 'Project Status',
      Id: 'ProjectStatus',
      isSelected: false,
      className: 'fixed-medium-report-col',
      WithOpenRole: true,
      isNeedProjectStatus: true,
      title: true,
    },
  ],
  listSettings: defaultListSettings,
  textDataForFilters: defaultTextDataForFilters,
};

// eslint-disable-next-line default-param-last
const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ACCOUNTING_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    case SET_ACCOUNTING_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_ACCOUNTING_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_ACCOUNTING_LIST_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: {
          ...state.textDataForFilters,
          ...action.payload,
        },
      };
    case RESET_ACCOUNTING_LIST_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: defaultTextDataForFilters,
      };
    default:
      return state;
  }
};

export default accountingReducer;
