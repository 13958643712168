export const ADD_ENTITY = 'ADD_ENTITY';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_CONTACT = 'SET_CLIENT_CONTACT';
export const SET_SENIORITY_LEVEL = 'SET_SENIORITY_LEVEL';

export const addEntity = (entityId, data, parentEntityId) => ({
  type: ADD_ENTITY,
  payload: {
    entityId,
    data,
    parentEntityId,
  },
});

export const setClient = (client) => ({
  type: SET_CLIENT,
  payload: client,
});

export const setClientContact = (clientContact) => ({
  type: SET_CLIENT_CONTACT,
  payload: clientContact,
});

export const setSeniorityLevel = (seniorityLevel) => ({
  type: SET_SENIORITY_LEVEL,
  payload: seniorityLevel,
});
