import { SET_SOW_REPORTS } from '../actions/reportsActions';
import { SET_BENCH_REPORTS } from '../actions/benchActions';
import { SET_WORKFORCE_REPORTS, SET_FILTERS_WORKFORCE_REPORTS, SET_WORKFORCE_COUNTER } from '../actions/workforceActions';
import { SET_MAIN_BENCH_REPORTS } from '../actions/benchReportsActions';
import { SET_PROJECTED_REVENUE_REPORTS } from '../actions/financeActions';
import { SET_MAIN_AUDIT_REPORTS, SET_MAIN_AUDIT_COUNT } from '../actions/auditActions';
import { SET_ACCOUNTING_REPORTS, SET_ACCOUNTING_TOTAL_COUNT } from '../actions/accountingActions';
import { SET_CLIENT_RATES_COUNTER, SET_CLIENT_RATES_REPORTS } from '../actions/clientRatesActions';
import { SET_TRAININGS_COUNTER, SET_TRAININGS_REPORTS } from '../actions/trainingsActions';
import { SET_BACKGROUND_CHECKS_COUNTER, SET_BACKGROUND_CHECKS_REPORTS } from '../actions/backgroundChecksActions';
import { SET_CERTIFICATIONS_COUNTER, SET_CERTIFICATIONS_REPORTS } from '../actions/certificationsActions';

const initialState = {
  reports: {
    sow: [],
    benchSummary: [],
    workforce: [],
    bench: [],
    finance: [],
    audit: [],
    accounting: [],
    clientRates: [],
    trainings: [],
    backgroundChecks: [],
    certifications: [],
  },
  filters: {
    billableStatus: [],
    assignmentTypes: [],
    projectsName: [],
  },
  workforceTotalCount: 0,
  auditTotalCount: 0,
  accountingTotalCoutn: 0,
  clientRatesTotalCount: 0,
  trainingsTotalCount: 0,
  backgroundChecksTotalCount: 0,
  certificationsTotalCount: 0,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTED_REVENUE_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          finance: action.payload,
        },
      };
    case SET_SOW_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          sow: action.payload,
        },
      };
    case SET_WORKFORCE_COUNTER:
      return {
        ...state,
        workforceTotalCount: action.payload,
      };
    case SET_BENCH_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          benchSummary: action.payload,
        },
      };
    case SET_MAIN_BENCH_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          bench: action.payload,
        },
      };
    case SET_WORKFORCE_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          workforce: action.payload,
        },
      };
    case SET_FILTERS_WORKFORCE_REPORTS:
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    case SET_MAIN_AUDIT_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          audit: action.payload,
        },
      };
    case SET_MAIN_AUDIT_COUNT:
      return {
        ...state,
        auditTotalCount: action.payload,
      };
    case SET_ACCOUNTING_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          accounting: action.payload,
        },
      };
    case SET_ACCOUNTING_TOTAL_COUNT:
      return {
        ...state,
        accountingTotalCoutn: action.payload,
      };
    case SET_CLIENT_RATES_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          clientRates: action.payload,
        },
      };
    case SET_CLIENT_RATES_COUNTER:
      return {
        ...state,
        clientRatesTotalCount: action.payload,
      };
    case SET_TRAININGS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          trainings: action.payload,
        },
      };
    case SET_TRAININGS_COUNTER:
      return {
        ...state,
        trainingsTotalCount: action.payload,
      };
    case SET_BACKGROUND_CHECKS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          backgroundChecks: action.payload,
        },
      };
    case SET_BACKGROUND_CHECKS_COUNTER:
      return {
        ...state,
        backgroundChecksTotalCount: action.payload,
      };
    case SET_CERTIFICATIONS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          certifications: action.payload,
        },
      };
    case SET_CERTIFICATIONS_COUNTER:
      return {
        ...state,
        certificationsTotalCount: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reportsReducer;
