export const SET_SOW_LIST_SETTINGS = '@reports/SET_SOW_LIST_SETTINGS';
export const RESET_SOW_LIST_SETTINGS = '@reports/RESET_SOW_LIST_SETTINGS';
export const SET_SOW_LIST_FILTERS_VALUE = '@reports/SET_SOW_LIST_FILTERS_VALUE';
export const RESET_SOW_LIST_FILTERS_VALUE = '@reports/RESET_SOW_LIST_FILTERS_VALUE';

export const setSowListSettings = (settings) => ({
  type: SET_SOW_LIST_SETTINGS,
  payload: settings,
});

export const resetSowListSettings = () => ({
  type: RESET_SOW_LIST_SETTINGS,
});

export const setSowListFiltersValue = (filters) => ({
  type: SET_SOW_LIST_FILTERS_VALUE,
  payload: filters,
});

export const resetSowListFiltersValue = () => ({
  type: RESET_SOW_LIST_FILTERS_VALUE,
});
