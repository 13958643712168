import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import { List } from 'common/components/list/list';
import classNames from 'classnames';
import { setSelectedTab } from '../../../../common/actions/selectedTabActions';
import * as PropTypes from 'prop-types';
import { getId } from '../../../../services/location';
import {
  clearErrorMessage, getOfficeById, updateOfficeData, updateLdap,
  loadHolidays, addHoliday, updateHoliday, deleteHoliday, resetOfficeDetails,
  addBusinessLocation, deleteOfficeBusinessLocation, loadOfficeBusinessLocations, updateBusinessLocation,
} from '../../actions/officesActions';
import moment from 'moment';
import { sortFilterList } from '../../../../common/components/list/sortList';
import CustomIcon from '../../../../common/components/icon/Icon';
import OfficeBasicInfoForm from 'common/components/redux-form/office-basic-info-form/office-basic-info-form.js';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { CellTextField } from 'common/components/list/custom-cell/cell-toogle-textfield';
import { CellDatePicker } from 'common/components/list/custom-cell/cell-toogle-datepicker';
import { CellSelect } from 'common/components/list/custom-cell/cell-employee-level';
import Tabs from 'common/components/tabs/tabs';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import TextField from 'common/components/form-items/input/text-field';
import Select from 'common/components/form-items/select/select';
import { getFilters } from 'common/actions/filtersActions';
import { setComponent, resetComponent } from 'common/actions/headerActions';

import './offices-details.css';

const FIRST_MOUNTH_ON_YEAR = 0;
const LAST_MOUNTH_ON_YEAR = 11;
const FIRST_DAY_OF_MOUNTH = 1;
const LAST_DAY_OF_MOUNTH = 31;
const DEFAULT_DELETE_DIALOG_TITLE = 'Please confirm you want to delete?';

export class OfficesDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      office: {},
      officeId: '',
      changeInputFields: {},
      selectedTab: 0,
      LdapPaths: [],
      newCalendarDate: null,
      newCalendarDescription: '',
      newCalendarLocation: '',
      columnsToDisplay: [
        {
          Name: 'Ldap Path', Id: 'Name', deleteArrow: 'delete-arrow',
        },
      ],
      columnsForCalendar: {
        Locations: [{
          Name: 'Location', Id: 'Location', className: 'without-width-col', deleteArrow: 'delete-arrow', isObject: true,
        }],
        Holidays: [
          {
            Name: 'Business Location', Id: 'Location', className: 'big-col', deleteArrow: 'delete-arrow', isObject: true,
          },
          {
            Name: 'Date', Id: 'Date', className: 'big-col', deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Description', Id: 'Description', className: 'without-width-col', deleteArrow: 'delete-arrow',
          },
        ],
      },
      blockAddPath: false,
      showAddPathField: false,
      blockSaveLdapPath: false,
      addNewPath: { Name: '' },
      confirmToDelete: false,
      dataForDeletePath: {},
      dataForDeleteHoliday: {},
      officeBasicInfoData: {},
      showAddHoliday: false,
      isValidAddHoliday: false,
      yearForTheHolidays: moment().format('YYYY'),
      years: [],
      holidays: [],
      detailsId: getId(),
      deleteDialogTitle: DEFAULT_DELETE_DIALOG_TITLE,
      copyLocationName: '',
      copyLocationOffices: [],
      showCopyLocationFields: false,
      blockSaveLocation: false,
      blockAddLocation: false,
      yearForTheCopyHolidays: moment().format('YYYY'),
    };
  }

  componentDidMount() {
    const filtersNames = ['Locations'];
    const {
      getOfficeById, clearErrorMessage, loadHolidays, getFilters, setComponent, loadOfficeBusinessLocations,
    } = this.props;
    const { yearForTheHolidays } = this.state;
    const id = getId();
    getOfficeById(id);
    this.setState({
      officeId: id,
    });
    getFilters(filtersNames);
    loadOfficeBusinessLocations(id);
    clearErrorMessage();
    loadHolidays(id, yearForTheHolidays);
    this.setOptionYears();
    setComponent({ returnButton: { text: 'Offices', goToPreviousPage: this.goToOfficesList } });
  }

  componentDidUpdate(prevProps) {
    const { selectedTab, officeDetails, holidays } = this.props;
    if (prevProps.selectedTab !== selectedTab) {
      this.setState({
        selectedTab,
      });
    }
    if (prevProps.officeDetails !== officeDetails) {
      this.setDefaultData();
      this.setState({
        office: officeDetails,
      });
      this.setCorrectDataForList();
    }
    if (prevProps.holidays !== holidays) {
      this.setState({
        holidays,
      });
    }
  }

  componentWillUnmount() {
    const { resetComponent, resetOfficeDetails } = this.props;
    resetOfficeDetails();
    resetComponent();
  }

  setCorrectDataForList = () => {
    const { officeDetails } = this.props;
    const newFormatData = [];
    officeDetails.LdapPaths.forEach((item, index) => {
      newFormatData.push({ Id: index, Name: item });
    });
    this.setState({
      LdapPaths: newFormatData,
    });
  }

  setDefaultData = () => {
    const { officeDetails } = this.props;
    this.setState({
      officeBasicInfoData: {
        Id: getId(),
        Address: officeDetails.Address,
        CountryId: officeDetails.Country.Id,
        CountryName: officeDetails.Country.Name,
        DomainName: officeDetails.DomainName,
        Name: officeDetails.Name,
      },
    });
  };

  canEditOffice = () => {
    const { EditOfficeBasicInfo, user, isAdmin } = this.props;
    const { officeId } = this.state;
    return EditOfficeBasicInfo && (user.UserOfficeId === officeId || isAdmin);
  }

  canEditLdap = () => {
    const { ManageOfficeLdapPath, user, isAdmin } = this.props;
    const { officeId } = this.state;
    return ManageOfficeLdapPath && (user.UserOfficeId === officeId || isAdmin);
  }

  canEditCalendar = () => {
    const { ManageOfficeCalendar, user, isAdmin } = this.props;
    const { officeId } = this.state;
    return ManageOfficeCalendar && (user.UserOfficeId === officeId || isAdmin);
  }

  updateHoliday = (item) => {
    const {
      updateHoliday, showAlertDialog, businessLocations,
    } = this.props;
    const { yearForTheHolidays } = this.state;

    const businessLocation = businessLocations.find(location => typeof item.Location === 'object'
      ? location.Id === item.Location.Id
      : location.Id === item.Location);
    const officeId = getId();

    if (!item.Description.length) {
      showAlertDialog('Description cannot be empty');
      loadHolidays(getId(), yearForTheHolidays);
      return false;
    }

    const updateHolidayData = {
      Id: item.Id,
      Date: moment(item.Date).format('L'),
      Description: item.Description,
      LocationId: item.Location.Id || item.Location,
      OfficeId: getId(),
      Year: yearForTheHolidays,
    };
    if (businessLocation.IsPrimary) {
      updateHolidayData.Location = {
        Name: businessLocation.Name,
        BaseBusinessLocationId: item.Location,
        OfficeId: officeId,
        Offices: [officeId],
      };
    }

    updateHoliday(updateHolidayData);
  }

  goToOfficesList = () => {
    const { location } = this.props;
    history.push({ pathname: '/offices', state: location.state });
  }

  setOptionYears = () => {
    const years = [new Date().getFullYear()];
    for (let i = 1; i < 10; i++) {
      years.push(new Date().getFullYear() - i);
      years.push(new Date().getFullYear() + i);
    }
    this.setState({ years: years.sort() });
  }

  addNewOffice = () => {
    const { blockAddPath } = this.state;
    if (!blockAddPath) {
      this.setState({
        showAddPathField: true,
        addNewPath: { Name: '' },
        blockAddPath: true,
      });
    }
  };

  toogleConfirmWindow = (path, dialogTitle) => {
    const { confirmToDelete, selectedTab } = this.state;
    this.setState(selectedTab === 0 ? {
      confirmToDelete: !confirmToDelete,
      dataForDeletePath: path && path.Name ? path : {},
      deleteDialogTitle: dialogTitle,
    } : {
      confirmToDelete: !confirmToDelete,
      dataForDeleteHoliday: path && path.Id ? path : {},
      deleteDialogTitle: dialogTitle,
    });
  }

  showConfirmToDelete = (path) => {
    this.toogleConfirmWindow(path, DEFAULT_DELETE_DIALOG_TITLE);
  }

  confirmDeletePath = () => {
    const {
      dataForDeletePath, selectedTab,
    } = this.state;
    if (selectedTab === 0) {
      this.deleteLdapPath(dataForDeletePath);
    } else {
      this.applyDeleteHolidays();
    }
    this.toogleConfirmWindow();
  }

  applyDeleteHolidays = () => {
    const { deleteHoliday } = this.props;
    const { dataForDeleteHoliday, yearForTheHolidays } = this.state;

    const deleteHolidayData = {
      holiday: dataForDeleteHoliday,
      officeId: getId(),
      year: yearForTheHolidays,
    };

    deleteHoliday(deleteHolidayData);
  }

  deleteLdapPath = (path) => {
    const { office } = this.state;
    const ldapPaths = office.LdapPaths;
    this.setState({
      office: {
        ...office,
        LdapPaths: ldapPaths.filter((item, i) => i !== path.Id),
      },
    }, () => {
      const { updateLdap } = this.props;
      const { office, officeId } = this.state;
      updateLdap(office.LdapPaths, officeId);
    });
  }

  editLdapPath = (path) => {
    const { office } = this.state;
    const { showAlertDialog } = this.props;
    office.LdapPaths.some(item => item === path.Name)
      ? showAlertDialog('Ldap paths cannot be the same')
      : this.setState({
        office: {
          ...office,
          LdapPaths: office.LdapPaths.map((item, index) => index === path.Id ? path.Name : item),
        },
      }, () => {
        const { updateLdap } = this.props;
        const { office, officeId } = this.state;
        updateLdap(office.LdapPaths, officeId);
      });
  }

  getNewPathObj = ({ target }) => {
    const { addNewPath } = this.state;
    this.setState({
      addNewPath: {
        ...addNewPath,
        [target.name]: target.value,
      },
    }, this.newPathValidation);
  }

  newPathValidation = () => {
    const { addNewPath } = this.state;
    this.setState({
      blockSaveLdapPath: !!addNewPath.Name.trim(),
    });
  };

  newLdapPathCancel = () => {
    this.setState({
      addNewPath: { Name: '' },
      showAddPathField: false,
      blockSaveLdapPath: false,
      blockAddPath: false,
    });
  }

  newLdapPathApply = () => {
    const { blockSaveLdapPath, addNewPath, office } = this.state;
    const { showAlertDialog } = this.props;
    if (blockSaveLdapPath) {
      office.LdapPaths.some(item => item === addNewPath.Name)
        ? showAlertDialog('Ldap paths cannot be the same')
        : this.setState({
          office: {
            ...office,
            LdapPaths: [...office.LdapPaths, addNewPath.Name],
          },
        }, () => {
          const { updateLdap } = this.props;
          const { office, officeId } = this.state;
          updateLdap(office.LdapPaths, officeId);
        });
      this.setState({
        addNewPath: { Name: '' },
        showAddPathField: false,
        blockSaveLdapPath: false,
        blockAddPath: false,
      });
    }
  }

  onTab = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  }

  showAddHoliday = () => {
    this.setState({
      showAddHoliday: true,
      showCopyLocationFields: false,
      blockAddLocation: false,
    });
  }

  cancelAddHoliday = () => {
    this.setState({
      showAddHoliday: false,
      newCalendarDate: null,
      newCalendarDescription: '',
      newCalendarLocation: '',
      isValidAddHoliday: false,
    });
  }

  applyAddHoliday = () => {
    const {
      newCalendarDate, newCalendarDescription, newCalendarLocation, isValidAddHoliday, yearForTheHolidays,
    } = this.state;
    const { addHoliday, businessLocations } = this.props;

    const businessLocation = businessLocations.find(item => item.Id === newCalendarLocation);
    const officeId = getId();

    if (isValidAddHoliday) {
      const newHolidayData = {
        Date: moment(newCalendarDate).format('L'),
        Description: newCalendarDescription,
        LocationId: newCalendarLocation,
        OfficeId: officeId,
        year: yearForTheHolidays,
      };
      if (businessLocation.IsPrimary) {
        newHolidayData.Location = {
          Name: `${businessLocation.Name}`,
          BaseBusinessLocationId: newCalendarLocation,
          OfficeId: officeId,
          Offices: [officeId],
        };
      }

      addHoliday(newHolidayData);
      this.cancelAddHoliday();
    }
  }

  validateAddHoliday = () => {
    const { newCalendarDate, newCalendarDescription, newCalendarLocation } = this.state;
    this.setState({
      isValidAddHoliday: !!(newCalendarDate && newCalendarDescription.length && newCalendarLocation.toString().length),
    });
  }

  onChandeNewCalendarDate = (date) => {
    this.setState({
      newCalendarDate: moment(date).format('L'),
    }, this.validateAddHoliday);
  }

  onChandeNewCalendarDescription = (event) => {
    this.setState({
      newCalendarDescription: event.target.value,
    }, this.validateAddHoliday);
  }

  onChangeNewCalendarLocation = (event) => {
    this.setState({
      newCalendarLocation: event.target.value,
    }, this.validateAddHoliday);
  }

  showDeleteHolidayModal = ({ item }) => {
    const dialogTitle = `Please confirm you want to delete ${moment(item.Date).format('MM-DD')} ${item.Description}?`;

    this.toogleConfirmWindow(item, dialogTitle);
  }

  handleChangeYear = ({ target }) => {
    const { loadHolidays } = this.props;
    this.setState({
      yearForTheHolidays: target.value,
    });
    loadHolidays(getId(), target.value);
  }

  handleChangeYearForCopy = ({ target }) => {
    this.setState({
      yearForTheCopyHolidays: target.value,
    }, this.copyLocationValidation);
  }

  copyLocation = (item) => {
    const { blockAddLocation } = this.state;
    if (!blockAddLocation) {
      this.setState({
        locationToCopy: item,
        showCopyLocationFields: true,
        addLocation: { Name: '' },
        blockAddLocation: true,
        showAddHoliday: false,
      });
    }
  }

  copyLocationCancel = () => {
    this.setState({
      copyLocationName: '',
      copyLocationOffices: [],
      showCopyLocationFields: false,
      blockSaveLocation: false,
      blockAddLocation: false,
    });
  }

  copyLocationApply = () => {
    const {
      businessLocations, addBusinessLocation, updateBusinessLocation,
    } = this.props;
    const {
      locationToCopy, copyLocationName, blockSaveLocation, yearForTheCopyHolidays,
    } = this.state;

    if (blockSaveLocation) {
      const businessLocationToCopy = businessLocations.find(item => item.Id === locationToCopy.Location.Id);
      const baseBusinessLocation = businessLocations.find(item => item.Id === copyLocationName);
      const officesIds = businessLocationToCopy.Offices.map(office => office.Id);

      const data = {
        Name: `${baseBusinessLocation.Name}`,
        OfficeDaysOff: locationToCopy.Holidays.map(holiday => ({
          Date: moment(holiday.Date).set('year', yearForTheCopyHolidays).format('L'),
          Description: holiday.Description,
        })),
        year: yearForTheCopyHolidays,
      };

      if (baseBusinessLocation.IsPrimary) {
        data.OfficeId = getId();
        data.Offices = officesIds;
        data.BaseBusinessLocationId = copyLocationName;

        addBusinessLocation(data);
      } else {
        data.Id = baseBusinessLocation.Id;
        data.OfficeId = baseBusinessLocation.OfficeId;
        data.Offices = baseBusinessLocation.Offices.map(office => office.Id);
        data.BaseBusinessLocationId = baseBusinessLocation.BaseBusinessLocation.Id;
        data.EmployeeId = baseBusinessLocation.Employee ? baseBusinessLocation.Employee.Id : null;

        updateBusinessLocation(data);
      }

      this.copyLocationCancel();
      this.setState({
        yearForTheHolidays: yearForTheCopyHolidays,
      });
    }
  }

  copyLocationValidation = () => {
    const { holidays } = this.props;
    const { copyLocationName, yearForTheCopyHolidays } = this.state;
    const holidaysExists = holidays.find(item => item.Location.Id === copyLocationName
      && item.YearsWithHolidays
      && item.YearsWithHolidays.find(year => year === parseInt(yearForTheCopyHolidays, 10)));
    const isValid = !holidaysExists;
    this.setState({
      blockSaveLocation: isValid,
    });
  }

  setNewLocationName = ({ target }) => {
    this.setState({
      copyLocationName: target.value,
    }, this.copyLocationValidation);
  }

  showDeleteBusinessLocationModal = ({ item }) => {
    this.setState({
      showDialogToDeleteBusinessLocation: true,
      businessLocationToDelete: item.Location,
    });
  }

  closeDeleteBusinessLocationModal = () => {
    this.setState({
      showDialogToDeleteBusinessLocation: false,
      businessLocationToDelete: '',
    });
  }

  applyDeleteBusinessLocationModal = () => {
    const { deleteOfficeBusinessLocation, officeDetails } = this.props;
    const { businessLocationToDelete, yearForTheHolidays } = this.state;

    const deleteBusinessLocationData = {
      businessLocation: businessLocationToDelete,
      officeId: getId(),
      officeName: officeDetails.Name && officeDetails.Name,
      year: yearForTheHolidays,
    };

    deleteOfficeBusinessLocation(deleteBusinessLocationData);
    this.closeDeleteBusinessLocationModal();
  }

  render() {
    const {
      columnsToDisplay, officeBasicInfoData, selectedTab, newCalendarDate, newCalendarDescription, newCalendarLocation, showAddHoliday, holidays,
      columnsForCalendar, LdapPaths, showAddPathField, blockAddPath, addNewPath, confirmToDelete, isValidAddHoliday,
      yearForTheHolidays, years, deleteDialogTitle, showCopyLocationFields, blockSaveLocation, copyLocationName, showDialogToDeleteBusinessLocation,
      yearForTheCopyHolidays,
    } = this.state;
    const {
      countries, showAlertDialog, businessLocations,
    } = this.props;
    return (
      <Scrollbars
        autoHide
        autoHideTimeout={300}
        hideTracksWhenNotNeeded
      >
        <div className='office-container'>
          {
            confirmToDelete ? <ConfirmationDialog
              dialogHeader='DELETE'
              dialogTitle={deleteDialogTitle}
              closeButtonTitle='Cancel'
              confirmButtonTitle='Confirm'
              closeDialog={this.toogleConfirmWindow}
              confirmDialog={this.confirmDeletePath}
            /> : null
          }
          {
            showDialogToDeleteBusinessLocation && <ConfirmationDialog
              dialogHeader='DELETE'
              dialogTitle='Do you confirm that you want to delete the business location?'
              closeButtonTitle='Cancel'
              confirmButtonTitle='Confirm'
              closeDialog={this.closeDeleteBusinessLocationModal}
              confirmDialog={this.applyDeleteBusinessLocationModal}
            />
          }
          <div className='project-info'>
            <OfficeBasicInfoForm
              data={officeBasicInfoData}
              isEditable={this.canEditOffice()}
              isEditableLdap={this.canEditLdap()}
              isEdatableCalendar={this.canEditCalendar()}
              allCountries={sortFilterList(countries)}
              columnsToDisplay={columnsToDisplay}
              showItemsEditMenu={this.canEditOffice()}
              handleSubmit={formHandleSubmit}
              dontRepeatName={showAlertDialog}
              dontRepeatNameMassage='Ldap Path already exists'
            />
            <Tabs
              className='project-info__tabs'
              list={['Ldap path', 'Calendar']}
              selectedIndex={selectedTab}
              onTab={this.onTab}
            />
            {selectedTab === 0 ? (
              <div className='calendar_holidays-container'>
                {this.canEditLdap() ? <div
                  className={classNames('toolbar-button_office-details')}
                  onClick={this.addNewOffice}
                >
                  <CustomIcon iconName='plus-orange' />
                  <span>Add New Ldap</span>
                </div> : null}
                <DocumentList
                  columnsToDisplay={columnsToDisplay}
                  items={LdapPaths}
                  onEditClick={this.editLdapPath}
                  isEditable={this.canEditLdap()}
                  editManageItem
                  showItemsEditMenu={this.canEditLdap()}
                  onDeleteClick={this.showConfirmToDelete}
                />
                {
                  showAddPathField ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
                    <div className='c-list__item-column resume-member-inputs c-list__item-column'>
                      <UpdateManageItem
                        item={addNewPath}
                        onChangeNewDocumentObj={this.getNewPathObj}
                        disableSaveDocument={blockAddPath}
                        newDocumentCancel={this.newLdapPathCancel}
                        newDocumentApply={this.newLdapPathApply}
                      />
                    </div>
                  </div> : null
                }
              </div>
            ) : selectedTab === 1 ? (
              <div className='calendar_holidays-container'>
                <div className='calendar-title__container'>
                  <Select
                    formClassName='vacation-year-selector calendar-office-select'
                    value={yearForTheHolidays}
                    selectOptions={years}
                    inputProps={{
                      name: 'yearForTheHolidays',
                      id: 'yearForTheHolidays',
                    }}
                    showClear={false}
                    onChange={this.handleChangeYear}
                  />
                  {this.canEditCalendar() ? <div
                    className={classNames('toolbar-button_office-details tolbar-button_ofice-calendar')}
                    onClick={this.showAddHoliday}
                  >
                    <CustomIcon iconName='plus-orange' />
                    <span>Add New Date</span>
                  </div> : null}
                </div>
                {showAddHoliday ? <div className='c-list__item-cont add-calendar--block'>
                  <div className='add-calendar--selectors-block c-list__item-column c-skill__item biggest-col'>
                    <Select
                      formClassName='skills-level-select_cell-list add-calendar--select'
                      value={newCalendarLocation}
                      selectOptions={businessLocations}
                      isOptionObject
                      inputProps={{
                        name: 'newCalendarLocation',
                        id: 'newCalendarLocation',
                      }}
                      showClear={false}
                      onChange={this.onChangeNewCalendarLocation}
                      placeholder='Choose Locations'
                      MenuProps={{
                        style: {
                          maxHeight: 600,
                        },
                      }}
                    />
                    <DatePicker
                      formClassName='cell-container_datepicker'
                      datePickerClassName='cell-container_datepicker add-calendar--datepicker'
                      placeholder='Choose Date'
                      value={newCalendarDate}
                      name='newCalendarDate'
                      onChange={this.onChandeNewCalendarDate}
                      minVal={new Date(yearForTheHolidays, FIRST_MOUNTH_ON_YEAR, FIRST_DAY_OF_MOUNTH)}
                      maxVal={new Date(yearForTheHolidays, LAST_MOUNTH_ON_YEAR, LAST_DAY_OF_MOUNTH)}
                    />
                  </div>
                  <div className='c-list__item-column c-skill__item without-width-col add-calendar--text-field-block'>
                    <TextField
                      formClassName='change-border__input-cell add-calendar--text-field'
                      inputClassName='change-name__input'
                      value={newCalendarDescription}
                      name='newCalendarDescription'
                      onChange={this.onChandeNewCalendarDescription}
                      maxlength='4000'
                      placeholder='Enter a description'
                    />
                  </div>
                  <div className='add-calendar--button-block'>
                    <div className='toolbar-button resume-buttom-cancel' onClick={this.cancelAddHoliday}>
                      <span>Cancel</span>
                    </div>
                    <div
                      className={classNames(
                        'button next resume-buttom-apply',
                        { 'btn-disabled': !isValidAddHoliday },
                      )}
                      onClick={this.applyAddHoliday}
                    >
                      <span>Apply</span>
                    </div>
                  </div>
                </div> : null}
                {showCopyLocationFields && (
                  <div className='c-list__item-cont add-calendar--block'>
                    <div className='add-calendar--selectors-block c-list__item-column c-skill__item biggest-col'>
                      <Select
                        formClassName='skills-level-select_cell-list add-calendar--select'
                        value={copyLocationName}
                        selectOptions={businessLocations}
                        isOptionObject
                        inputProps={{
                          name: 'copyLocationName',
                          id: 'copyLocationName',
                        }}
                        showClear={false}
                        onChange={this.setNewLocationName}
                        placeholder='Choose Locations'
                      />
                      <Select
                        formClassName='skills-level-select_cell-list add-calendar--select'
                        value={yearForTheCopyHolidays}
                        selectOptions={years}
                        inputProps={{
                          name: 'yearForTheCopyHolidays',
                          id: 'yearForTheCopyHolidays',
                        }}
                        showClear={false}
                        onChange={this.handleChangeYearForCopy}
                      />
                    </div>
                    <div className='add-calendar--button-block'>
                      <div className='toolbar-button resume-buttom-cancel add-locations__cancel-button' onClick={this.copyLocationCancel}>
                        <span>Cancel</span>
                      </div>
                      <div
                        className={classNames(
                          'button next resume-buttom-apply',
                          { 'btn-disabled': !blockSaveLocation },
                        )}
                        onClick={this.copyLocationApply}
                      >
                        <span>Apply</span>
                      </div>
                    </div>
                  </div>
                )}
                <List
                  columnsToDisplay={columnsForCalendar.Locations}
                  items={holidays}
                  isHaveRights={this.canEditCalendar()}
                  hideAddNewEntity
                  hideEntityEdit
                  hideCopyEntity={false}
                  onCopyClick={this.copyLocation}
                  onDeleteClick={this.showDeleteBusinessLocationModal}
                  dontShowOptions
                  defaultOpenDropDown
                  dropdownList={{
                    columnsToDisplay: columnsForCalendar.Holidays,
                    items: holidays,
                    isHaveRights: this.canEditCalendar(),
                    onDeleteClick: this.showDeleteHolidayModal,
                    hideDots: !this.canEditCalendar(),
                    hideAddNewEntity: true,
                    hideEntityEdit: true,
                    property: 'Holidays',
                    getDeeper: true,
                    config: {
                      'Location': {
                        cell: CellSelect,
                        valueSetter: this.updateHoliday,
                        options: businessLocations,
                      },
                      'Description': {
                        cell: CellTextField,
                        valueSetter: this.updateHoliday,
                      },
                      'Date': {
                        cell: CellDatePicker,
                        valueSetter: this.updateHoliday,
                      },
                    },
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Scrollbars>
    );
  }
}

OfficesDetailPage.propTypes = {
  selectedTab: PropTypes.number,
  EditOfficeBasicInfo: PropTypes.bool,
  getOfficeById: PropTypes.func,
  officeDetails: PropTypes.object,
  clearErrorMessage: PropTypes.func,
  countries: PropTypes.array,
  ManageOfficeLdapPath: PropTypes.bool,
  ManageOfficeCalendar: PropTypes.bool,
};

export default connect((store) => ({
  selectedTab: store.selectedTabReducer.selectedTab,
  roles: store.userRoleReducer.roles,
  officeDetails: store.officesReducer.officeDetails,
  error: store.officesReducer.error,
  countries: store.officesReducer.countries,
  user: store.authReducer.user,
  isAdmin: store.userRoleReducer.isAdmin,
  holidays: store.officesReducer.holidays,
  locations: store.filtersReducer.filters.Locations,
  officesLocations: store.projectsReducer.OfficeLocations,
  businessLocations: store.officesReducer.businessLocations,
}), {
  setSelectedTab,
  getOfficeById,
  updateOfficeData,
  clearErrorMessage,
  showAlertDialog,
  loadHolidays,
  addHoliday,
  updateHoliday,
  deleteHoliday,
  updateLdap,
  getFilters,
  setComponent,
  resetComponent,
  resetOfficeDetails,
  addBusinessLocation,
  loadOfficeBusinessLocations,
  deleteOfficeBusinessLocation,
  updateBusinessLocation,
})(OfficesDetailPage);
