import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import history from 'utils/history';
import Scrollbars from 'react-custom-scrollbars';
import { searchClients, setClients, setListSettings } from '../../actions/clientsActions';
import { getSelectedTab } from '../../../../common/actions/selectedTabActions';
import { showDialog } from '../../../../common/actions/confirmationDialogActions';
import isObjectNotEquals from 'utils/object-comparison';
import { scrollToLastElement } from 'services/scrollToLastElement.js';
import InfiniteScroll from 'react-infinite-scroller';
import { destroy } from 'redux-form';
import emptyRequiredFunction from 'utils/emptyRequiredFunction';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { emptyArray } from 'utils/const-variable';

export class ClientsListPage extends React.Component {
  componentDidMount() {
    const {
      getSelectedTab, listSettings, destroy, clients, setComponent, searchClients,
    } = this.props;
    const {
      searchValue,
      sortColumn,
      sortDirection,
      scrollTo,
    } = listSettings;
    destroy('CreateClient');
    getSelectedTab(0);
    if (scrollTo !== '') {
      scrollToLastElement('clients', scrollTo, true);
    }
    searchClients(searchValue, { sortColumn, sortDirection });
    setComponent({ title: `Clients (${clients.length})` });
  }

  componentDidUpdate(prevProps) {
    const {
      clients, setComponent, listSettings, searchClients,
    } = this.props;
    const { searchValue, sortColumn, sortDirection } = listSettings;
    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      searchClients(searchValue, { sortColumn, sortDirection });
    }
    if (prevProps.clients.length !== clients.length) {
      searchClients(searchValue, { sortColumn, sortDirection });
      setComponent({ title: `Clients (${clients.length})` });
    }
  }

  componentWillUnmount() {
    const { resetComponent, setClients } = this.props;
    setClients(emptyArray);
    resetComponent();
  }

  routeToClientDetails = (id) => {
    history.push({
      pathname: `clients/${id}`,
    });
  };

  setSearchValue = (searchValue) => {
    const { setListSettings } = this.props;
    setListSettings({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettings } = this.props;
    setListSettings({ sortColumn, sortDirection });
  };

  addNewClient = () => {
    history.push({ pathname: '/clients/new' });
  }

  render() {
    const {
      clients,
      columnsToDisplay,
      CanCreateClient,
      listSettings,
    } = this.props;
    const {
      searchValue,
      sortColumn,
      sortDirection,
    } = listSettings;
    return (
      <div className='page-cont'>
        <ListToolbar
          searchPlaceholder='Search Clients'
          searchValue={searchValue}
          columnsToDisplay={columnsToDisplay}
          showAddButton={CanCreateClient}
          onAddNew={this.addNewClient}
          onSearchClick={this.setSearchValue}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
        >
          <div className='page-container_for-scroll' id='clients'>
            <InfiniteScroll
              pageStart={1}
              useWindow={false}
              initialLoad={false}
              threshold={50}
              loadMore={emptyRequiredFunction}
            >
              <List
                page='clients'
                columnsToDisplay={columnsToDisplay}
                items={clients}
                onItemClick={this.routeToClientDetails}
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                hideDots
                dontShowOptions
                fixedHeader
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

ClientsListPage.propTypes = {
  columnsToDisplay: PropTypes.array,
  clients: PropTypes.array,
  searchClients: PropTypes.func,
  getSelectedTab: PropTypes.func,
  CanCreateClient: PropTypes.bool.isRequired,
  setListSettings: PropTypes.func,
  listSettings: PropTypes.object,
};

export default connect((store) => ({
  columnsToDisplay: store.clientsReducer.columnsToDisplay,
  clients: store.clientsReducer.clients,
  listSettings: store.clientsReducer.listSettings,
}), {
  searchClients,
  getSelectedTab,
  showDialog,
  destroy,
  setComponent,
  resetComponent,
  setClients,
  setListSettings,
})(ClientsListPage);
