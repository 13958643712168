import { FINISHED, ROLE_STATUS_REMOVED, ROLE_STATUS_REMOVED_ID } from 'utils/const-variable';

export const getCorrectDataForEditRole = (roles, startDate, endDate, Status, projectId) => {
  const result = roles.map((item) => {
    item.ItemId = item.Id;
    item.projectId = projectId;
    item.OvertimeRate = item.RateOvertime;
    item.SeniorityId = item.SeniorityLevel.Id;
    item.RoleId = item.Role.Id;
    item.AssignmentTypeId = item.AssignmentType.Id;
    item.BillingCode = item.SowPo;
    item.BillableStatusId = item.BillableStatus.Id;
    item.StatusId = Status.Name === FINISHED ? ROLE_STATUS_REMOVED_ID : item.Status.Id;
    item.StartDate = !!startDate ? startDate : item.StartDate;
    item.EndDate = !!endDate ? endDate : item.EndDate;
    item.Employees = item.Employees.map((empl) => {
      const employee = {
        EmployeeId: empl.EmployeeId,
        StartDate: empl.checked === true && !!startDate ? startDate : empl.StartDate,
        EndDate: empl.checked === true && !!endDate ? endDate : empl.EndDate,
        Assignment: empl.Assignment,
        Status: empl.checked === true && Status.Name === FINISHED ? { Id: ROLE_STATUS_REMOVED_ID, Name: ROLE_STATUS_REMOVED } : empl.Status,
        Id: empl.Id,
        PrimaryAssignmentId: empl.PrimaryAssignmentId,
        Role: empl.Role,
        EmployeeComment: empl.Comment,
        ItemId: item.Id,
        projectId,
        backupEmployees: empl.backupEmployees.map((backup) => {
          const backupEmployee = {
            EmployeeId: backup.EmployeeId,
            StartDate: backup.checked === true && !!startDate ? startDate : backup.StartDate,
            EndDate: backup.checked === true && !!endDate ? endDate : backup.EndDate,
            Assignment: backup.Assignment,
            Status: backup.checked === true && Status.Name === FINISHED ? { Id: ROLE_STATUS_REMOVED_ID, Name: ROLE_STATUS_REMOVED } : backup.Status,
            Id: backup.Id,
            PrimaryAssignmentId: backup.PrimaryAssignmentId,
            Role: backup.Role,
            EmployeeComment: backup.Comment,
            ItemId: item.Id,
            projectId,
          };
          return backupEmployee;
        }),
      };
      return employee;
    });
    return item;
  });
  return result;
};
