export const SET_LIST_SETTINGS = '@offices/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@offices/RESET_LIST_SETTINGS';
export const GET_OFFICES = '@offices/GET_OFFICES';
export const SET_OFFICES = '@offices/SET_OFFICES';
export const SEARCH_OFFICE = '@offices/SEARCH_OFFICE';
export const GET_OFFICE_BY_ID = '@offices/GET_OFFICE_BY_ID';
export const SET_OFFICE_DETAILS = '@offices/SET_OFFICE_DETAILS';
export const PUT_OFFICE_DATA = '@offices/PUT_OFFICE_DATA';
export const SET_OFFICE_ERROR = '@offices/SET_OFFICE_ERROR';
export const CLEAR_ERROR_MESSAGE = '@offices/CLEAR_ERROR_MESSAGE';
export const GET_COUNTRIES = '@offices/GET_COUNTRIES';
export const SET_COUNTRIES = '@offices/SET_COUNTRIES';
export const GET_HOLIDAYS = '@offices/GET_HOLIDAYS';
export const SET_HOLIDAYS = '@offices/SET_HOLIDAYS';
export const PUT_HOLIDAYS = '@offices/PUT_HOLIDAYS';
export const POST_HOLIDAYS = '@offices/POST_HOLIDAYS';
export const DELETE_HOLIDAYS = '@offices/DELETE_HOLIDAYS';
export const PUT_LDAP_PATH = '@offices/PUT_LDAP_PATH';
export const RESET_OFFICES = '@offices/RESET_OFFICES';
export const RESET_OFFICE_DETAILS = '@offices/RESET_OFFICE_DETAILS';
export const GET_BASE_BUSINESS_LOCATIONS = '@offices/GET_BASE_BUSINESS_LOCATIONS';
export const POST_BUSINESS_LOCATION = '@offices/ADD_BUSINESS_LOCATION';
export const PUT_BUSINESS_LOCATION = '@offices/PUT_BUSINESS_LOCATION';
export const SET_BASE_BUSINESS_LOCATIONS = '@offices/SET_BASE_BUSINESS_LOCATIONS';
export const GET_OFFICE_BUSINESS_LOCATIONS = '@offices/GET_OFFICE_BUSINESS_LOCATIONS';
export const SET_OFFICE_BUSINESS_LOCATIONS = '@offices/SET_OFFICE_BUSINESS_LOCATIONS';
export const DELETE_OFFICE_BUSINESS_LOCATION = '@offices/DELETE_OFFICE_BUSINESS_LOCATION';

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const getOffices = () => ({
  type: GET_OFFICES,
});

export const setOffices = (offices) => ({
  type: SET_OFFICES,
  payload: {
    offices: offices,
  },
});
export const resetOffices = () => ({
  type: RESET_OFFICES,
});

export const searchOffices = (searchValue) => ({
  type: SEARCH_OFFICE,
  payload: {
    searchValue,
  },
});

export const getOfficeById = (id) => ({
  type: GET_OFFICE_BY_ID,
  payload: id,
});

export const setOfficeDetails = (officeDetails) => ({
  type: SET_OFFICE_DETAILS,
  payload: officeDetails,
});

export const resetOfficeDetails = () => ({
  type: RESET_OFFICE_DETAILS,
});

export const updateOfficeData = (officeDetails, officeId) => ({
  type: PUT_OFFICE_DATA,
  payload: {
    officeDetails,
    officeId,
  },
});

export const setOfficeError = () => ({
  type: SET_OFFICE_ERROR,
});

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR_MESSAGE,
});

export const getCountries = () => ({
  type: GET_COUNTRIES,
});

export const setCountries = (countries) => ({
  type: SET_COUNTRIES,
  payload: countries,
});

export const loadHolidays = (id, year) => ({
  type: GET_HOLIDAYS,
  payload: {
    id,
    year,
  },
});

export const setHolidays = (holidays) => ({
  type: SET_HOLIDAYS,
  payload: holidays,
});

export const addHoliday = (holiday) => ({
  type: POST_HOLIDAYS,
  payload: holiday,
});

export const updateHoliday = (holiday) => ({
  type: PUT_HOLIDAYS,
  payload: holiday,
});

export const deleteHoliday = (deletHoliday) => ({
  type: DELETE_HOLIDAYS,
  payload: deletHoliday,
});

export const updateLdap = (ldap, officeId) => ({
  type: PUT_LDAP_PATH,
  payload: {
    ldap,
    officeId,
  },
});

export const loadBaseBusinessLocations = () => {
  return {
    type: GET_BASE_BUSINESS_LOCATIONS,
  };
};

export const addBusinessLocation = (businessLocation) => {
  return {
    type: POST_BUSINESS_LOCATION,
    payload: businessLocation,
  };
};

export const updateBusinessLocation = (businessLocation) => {
  return {
    type: PUT_BUSINESS_LOCATION,
    payload: businessLocation,
  };
};

export const setBaseBusinessLocations = (businessLocations) => {
  return {
    type: SET_BASE_BUSINESS_LOCATIONS,
    payload: businessLocations,
  };
};

export const loadOfficeBusinessLocations = (officeId) => {
  return {
    type: GET_OFFICE_BUSINESS_LOCATIONS,
    payload: officeId,
  };
};

export const setOfficeBusinessLocations = (businessLocations) => {
  return {
    type: SET_OFFICE_BUSINESS_LOCATIONS,
    payload: businessLocations,
  };
};

export const deleteOfficeBusinessLocation = (businessLocation) => {
  return {
    type: DELETE_OFFICE_BUSINESS_LOCATION,
    payload: businessLocation,
  };
};
