import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  GET_MANAGE_ROLE_TYPES,
  setManagesRoleTypes,
  CREATE_NEW_ROLE_TYPE,
  UPDATE_ROLE_TYPE,
} from '../actions/adminManageRoleTypes';

import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* getManageRoleTypes() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'role_types',
    });

    yield put(setManagesRoleTypes(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addRoleTypes(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'role_types',
      data,
    });
    yield call(getManageRoleTypes);
  } catch (e) {
    // error message
  }
}

export function* putRoleTypes(action) {
  yield call(http, {
    method: 'PUT',
    url: `role_types/${action.payload.Id}`,
    data: { Name: action.payload.Name, Code: action.payload.Code },
  });
  yield call(getManageRoleTypes);
}

export default function* adminManageRoleTypes() {
  yield takeEvery(GET_MANAGE_ROLE_TYPES, getManageRoleTypes);
  yield takeEvery(CREATE_NEW_ROLE_TYPE, addRoleTypes);
  yield takeEvery(UPDATE_ROLE_TYPE, putRoleTypes);
}
