import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextField from '../../form-items/input/text-field';

export class CellTextField extends Component {
  constructor(props) {
    super(props);
    const { item, column } = this.props;
    this.state = {
      value: item[column.Id],
      isTextField: false,
    };
  }

  toggleViewCell = () => {
    const { isTextField } = this.state;
    this.setState({
      isTextField: !isTextField,
    });
  }

  handleChange = ({ target }) => {
    this.setState({
      value: target.value,
    });
  }

  onBlur = () => {
    const { column, config, item } = this.props;
    const { value } = this.state;
    const { valueSetter } = config[column.Id];
    const newItem = {
      ...item,
      [column.Id]: value,
    };
    valueSetter(newItem, value);
    this.toggleViewCell();
  }

  render() {
    const {
      item,
      column,
      isHaveRights,
      config,
    } = this.props;
    const {
      value,
      isTextField,
    } = this.state;
    const showPlaceholderIfNull = config[column.Id] && config[column.Id].showPlaceholderIfNull;
    const isEditable = config[column.Id] && 'isEditable' in config[column.Id] ? config[column.Id].isEditable : true;
    return (
      <div
        className={classNames('c-list__item-column c-skill__item', column.className ? column.className : 'small-col')}
      >
        {
          isTextField && isHaveRights && isEditable ? (
            <TextField
              formClassName='change-border__input-cell'
              inputClassName='change-name__input'
              autoFocus
              value={value}
              onChange={this.handleChange}
              onBlur={this.onBlur}
              maxlength='4000'
            />
          ) : (
            <span
              onClick={this.toggleViewCell}
              className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
            >
              {item && item[column.Id] ? item[column.Id] : showPlaceholderIfNull ? '———' : null}
            </span>
          )
        }
      </div>
    );
  }
}

CellTextField.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
