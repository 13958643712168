export const ADD_NEW_SOW = '@contracts/ADD_NEW_SOW';
export const EDIT_CURRENT_SOW = '@contracts/EDIT_CURRENT_SOW';
export const ADD_NEW_MSA = '@contracts/ADD_NEW_MSA';
export const EDIT_CURRENT_MSA = '@contracts/EDIT_CURRENT_MSA';
export const ADD_NEW_PO = '@contracts/ADD_NEW_PO';
export const EDIT_CURRENT_PO = '@contracts/EDIT_CURRENT_PO';
export const ADD_NEW_AMENDMENT = '@contracts/ADD_NEW_AMENDMENT';
export const EDIT_CURRENT_AMENDMENT = '@contracts/EDIT_CURRENT_AMENDMENT';
export const CHECK_ABILITY_FOR_PARENT_MILESTONE_DELETING = '@contracts/CHECK_ABILITY_FOR_PARENT_MILESTONE_DELETING';
export const DELETE_CURRENT_SOW = '@contracts/DELETE_CURRENT_SOW';
export const DELETE_CURRENT_PO = '@contracts/DELETE_CURRENT_PO';
export const DELETE_CURRENT_AMENDMENT = '@contracts/DELETE_CURRENT_AMENDMENT';

export const addNewSOW = (projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests) => ({
  type: ADD_NEW_SOW,
  payload: {
    projectId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
  },
});

export const editCurrentSOW = (projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests, saveCallback) => ({
  type: EDIT_CURRENT_SOW,
  payload: {
    projectId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
    saveCallback,
  },
});

export const addNewMSA = (projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests) => ({
  type: ADD_NEW_MSA,
  payload: {
    projectId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
  },
});

export const editCurrentMSA = (projectId, isOnlyActive, isNeedToChangeDate, configPatchRequests, saveCallback) => ({
  type: EDIT_CURRENT_MSA,
  payload: {
    projectId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
    saveCallback
  },
});

export const addNewPO = (projectId, SOWId, POMainId, isOnlyActive, isNeedToChangeDate, configPatchRequests) => ({
  type: ADD_NEW_PO,
  payload: {
    projectId,
    SOWId,
    POMainId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
  },
});

export const editCurrentPO = (
  projectId,
  SOWId,
  isOnlyActive,
  isNeedToChangeDate,
  configPatchRequests,
  isOriginal,
  saveCallback,
) => ({
  type: EDIT_CURRENT_PO,
  payload: {
    projectId,
    SOWId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
    isOriginal,
    saveCallback,
  },
});

export const addNewAmendment = (projectId, SOWId, isOnlyActive, isNeedToChangeDate, configPatchRequests) => ({
  type: ADD_NEW_AMENDMENT,
  payload: {
    projectId,
    SOWId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
  },
});


export const editCurrentAmendment = (
  projectId,
  SOWId,
  isOnlyActive,
  isNeedToChangeDate,
  configPatchRequests,
  saveCallback,
) => ({
  type: EDIT_CURRENT_AMENDMENT,
  payload: {
    projectId,
    SOWId,
    isOnlyActive,
    isNeedToChangeDate,
    configPatchRequests,
    saveCallback,
  },
});

export const checkAbilityForParentMilestoneDeleting = (milestoneId,
  milestoneName,
  formName,
  fieldName,
  newMilestonesValue,
  parentType,
  childType,
  projectId) => ({
  type: CHECK_ABILITY_FOR_PARENT_MILESTONE_DELETING,
  payload: {
    milestoneId, milestoneName, formName, fieldName, newMilestonesValue, parentType, childType, projectId,
  },
});

export const deleteCurrentSow = (projectId, SOWId) => ({
  type: '',
  payload: {
    projectId,
    SOWId,
  },
});
