import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import {
  closeManageUserConfirmation, CONFIRM_USER_CHANGES,
  confirmUserChanges,
  REQUEST_CONFLICTING_USER_MANAGERIAL_INFO,
  REQUEST_CONFLICTING_USER_MANAGERIAL_INFO_SUCCESS,
  requestConflictingUserManagerialInfoSuccess,
} from '../actions/editUserConfirmationActions';
import http from '../../../services/http';
import { DELETE_MANAGE_KIND, UPDATE_MANAGE_KIND } from '../reducers/manageUserConfirmationReducer';
import { getAllUsers } from './adminSaga';
import { finishRequest, startRequest } from '../../../common/actions/loadingActions';

const isConflictingManagerialInfo = (managerialInfo, userSecurityRoleIds, securityRoleSubstitutesConfig) => {
  const substituteIds = securityRoleSubstitutesConfig
    .filter((c) => c.securityRole.id === managerialInfo.roleId)
    .flatMap((c) => c.substitutes)
    .map((s) => s.securityRole.id);

  return !userSecurityRoleIds.some((securityRoleId) => substituteIds.some((substituteId) => substituteId === securityRoleId));
};

export function* requestConflictingUserManagerialInfo({ payload: user }) {
  const manageKind = yield select((state) => state.manageUserConfirmation.manageKind);
  const securityRoleSubstitutesConfig = yield select((state) => state.adminModalReducer.securityRoleSubstitutesConfig);

  try {
    yield put(startRequest());
    const managerialInfo = yield call(http, {
      method: 'GET',
      url: `administration/users/${user.UserId}/managerial-info`,
    });

    let securityRoleIds;
    switch (manageKind) {
      case UPDATE_MANAGE_KIND:
        securityRoleIds = yield select((state) => state.manageUserConfirmation.updatedSecurityRoleIds);
        break;
      default:
        securityRoleIds = [];
        break;
    }

    const conflictingManagerialInfo = (managerialInfo.data || []).filter((info) => isConflictingManagerialInfo(
      info,
      securityRoleIds,
      securityRoleSubstitutesConfig,
    ));

    yield put(requestConflictingUserManagerialInfoSuccess(conflictingManagerialInfo));
  } catch {
    yield put(closeManageUserConfirmation());
  } finally {
    yield put(finishRequest());
  }
}

export function* autoConfirmUserChanges({ payload: conflictingManagerialInfo }) {
  const manageKind = yield select((state) => state.manageUserConfirmation.manageKind);

  if (manageKind === UPDATE_MANAGE_KIND && !conflictingManagerialInfo.some((i) => i)) {
    yield put(confirmUserChanges());
  }
}

export function* handleConfirmUserChanges() {
  const manageKind = yield select((state) => state.manageUserConfirmation.manageKind);
  const userId = yield select((state) => state.manageUserConfirmation.userId);
  const updatedPermissions = yield select((state) => state.manageUserConfirmation.updatedPermissions);
  const updatedSecurityRoleIds = yield select((state) => state.manageUserConfirmation.updatedSecurityRoleIds);
  const updatedOfficeIds = yield select((state) => state.manageUserConfirmation.updatedOfficeIds);

  try {
    yield put(closeManageUserConfirmation());
    yield put(startRequest());

    switch (manageKind) {
      case DELETE_MANAGE_KIND:
        yield call(http, {
          method: 'DELETE',
          url: `administration/users/${userId}`,
        });
        break;
      case UPDATE_MANAGE_KIND:
        yield call(http, {
          method: 'PUT',
          url: `administration/users/${userId}`,
          data: {
            permissions: updatedPermissions,
            securityRoleIds: updatedSecurityRoleIds,
            officeIds: updatedOfficeIds,
          },
        });
        break;
      default:
        break;
    }
  } finally {
    yield put(finishRequest());
    yield call(getAllUsers);
  }
}

export default function* manageUserConfirmationSaga() {
  yield takeEvery(REQUEST_CONFLICTING_USER_MANAGERIAL_INFO, requestConflictingUserManagerialInfo);
  yield takeEvery(REQUEST_CONFLICTING_USER_MANAGERIAL_INFO_SUCCESS, autoConfirmUserChanges);
  yield takeEvery(CONFIRM_USER_CHANGES, handleConfirmUserChanges);
}
