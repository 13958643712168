import moment from 'moment';

const checkIsDatesCrossing = (comparedEmployee, employeeForComparing) => {
  const comparedStart = comparedEmployee.EmployeeStartDate || comparedEmployee.StartDate;
  const comparedEnd = comparedEmployee.EmployeeEndDate || comparedEmployee.EndDate;
  const comparingStart = employeeForComparing.EmployeeStartDate || employeeForComparing.StartDate;
  const comparingEnd = employeeForComparing.EmployeeEndDate || employeeForComparing.EndDate;

  return (employeeForComparing.EmployeeId === comparedEmployee.EmployeeId || employeeForComparing.Id === comparedEmployee.Id)
    && (
      moment(comparedStart).isBetween(comparingStart, comparingEnd, 'hour')
      || moment(comparedEnd).isBetween(comparingStart, comparingEnd, 'hour')
      || moment(comparingStart).isBetween(comparedStart, comparedEnd, 'hour')
      || moment(comparingEnd).isBetween(comparedStart, comparedEnd, 'hour')
      || moment(comparedStart).isSame(comparingStart)
      || moment(comparedEnd).isSame(comparingEnd)
      || moment(comparedStart).isSame(comparingEnd)
      || moment(comparedEnd).isSame(comparingStart)
    );
};

export default checkIsDatesCrossing;
