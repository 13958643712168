export const GET_TRAININGS_REPORTS = '@reports/GET_TRAININGS_REPORTS';
export const SET_TRAININGS_REPORTS = '@reports/SET_TRAININGS_REPORTS';
export const SET_TRAININGS_COUNTER = '@reports/SET_TRAININGS_COUNTER';
export const SET_TRAININGS_LIST_SETTINGS = '@reports/SET_TRAININGS_LIST_SETTINGS';
export const RESET_TRAININGS_LIST_SETTINGS = '@reports/RESET_TRAININGS_LIST_SETTINGS';
export const CHANGE_TRAININGS_COLUMNS_TO_DISPLAY = '@reports/CHANGE_TRAININGS_COLUMNS_TO_DISPLAY';

export const getTrainingsReports = (filters, searchValue, take, skip, sortSettings) => ({
  type: GET_TRAININGS_REPORTS,
  payload: {
    searchValue,
    filters,
    take,
    skip,
    sortSettings,
  },
});

export const setTrainingsReports = (reports) => ({
  type: SET_TRAININGS_REPORTS,
  payload: reports,
});

export const setTrainingsCounter = (count) => ({
  type: SET_TRAININGS_COUNTER,
  payload: count,
});

export const setTrainingsReportsListSettings = (settings) => ({
  type: SET_TRAININGS_LIST_SETTINGS,
  payload: settings,
});

export const resetTrainingsReportsListSettings = () => ({
  type: RESET_TRAININGS_LIST_SETTINGS,
});

export const changeColumnsToDisplay = (columnsToDisplay) => ({
  type: CHANGE_TRAININGS_COLUMNS_TO_DISPLAY,
  payload: { columnsToDisplay },
});
