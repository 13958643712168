import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  GET_MANAGE_SKILL_LEVELS,
  setManagesSkillLevels,
  CREATE_NEW_SKILL_LEVEL,
  UPDATE_SKILL_LEVEL,
  UPDATE_ORDER_SKILL_LEVELS,
} from '../actions/adminManageSkillLevels';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* getManageSkillLevels() {
  try {
    yield put(startRequest());
    const roleTypes = yield call(http, {
      method: 'GET',
      url: 'skill_levels',
    });
    yield put(setManagesSkillLevels(roleTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addSkillLevels(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'skill_levels',
      data: {
        Name: data.Name.trim(),
        Priority: action.payload.Priority,
      },
    });
    yield call(getManageSkillLevels);
  } catch (e) {
    // error message
  }
}

export function* updateOrderSkillLevels(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'PATCH',
      url: 'skill_levels',
      data,
    });
    yield call(getManageSkillLevels);
  } catch (e) {
    // error message
  }
}

export function* putSkillLevels(action) {
  yield call(http, {
    method: 'PUT',
    url: `skill_levels/${action.payload.Id}`,
    data: {
      Name: action.payload.Name,
    },
  });
  yield call(getManageSkillLevels);
}

export default function* adminManageSkillLevels() {
  yield takeEvery(GET_MANAGE_SKILL_LEVELS, getManageSkillLevels);
  yield takeEvery(CREATE_NEW_SKILL_LEVEL, addSkillLevels);
  yield takeEvery(UPDATE_SKILL_LEVEL, putSkillLevels);
  yield takeEvery(UPDATE_ORDER_SKILL_LEVELS, updateOrderSkillLevels);
}
