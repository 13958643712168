import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';
import formatCurrency from 'utils/formatCurrency';

export class CellBudget extends Component {
  renderBudgetInfo = (budget, currency) => {
    return budget ? formatCurrency(currency) + getAmericanFormat(budget) : `${formatCurrency(currency)}0`;
  }

  render() {
    const {
      item, column, viewFinancialInfo, config, onClick,
    } = this.props;
    const currency = (config.CurrencyType && config.CurrencyType.currency && config.CurrencyType.currency.Name)
      || (item.Currency && item.Currency.Name)
      || item.Currency;
    const budget = config[column.Id].isFixed ? item[column.Id].toFixed(2) : item[column.Id];
    const cellTextValue = item[column.Id] === null
        || item[column.Id] === undefined
        || (config[column.Id].allowEmpty && item[column.Id] === 0)
      ? '———'
      : this.renderBudgetInfo(budget, currency);
    return (
      viewFinancialInfo && <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')} onClick={onClick}>
        <span
          className='col-span'
          style={{ WebkitBoxOrient: 'vertical' }}
          title={cellTextValue}
        >
          {cellTextValue}
        </span>
      </div>
    );
  }
}

CellBudget.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  viewFinancialInfo: PropTypes.bool.isRequired,
};
