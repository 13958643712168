import React from 'react';
import TextField from 'common/components/form-items/input/text-field';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';
import '../redux-form.css';

export default class renderTextField extends React.Component {
  onChange = event => {
    const { input, currency } = this.props;
    const newValue = currency ? getAmericanFormat(event.target.value) : event.target.value;
    input.onChange(newValue);
  }

  render() {
    const {
      input, meta, disabled, label, autoFocus, startAdornment, endAdornment, placeholder,
      maxlength, multiline, fullWidth, styles, formClassName, inputClassName, showAsterix,
      type,
    } = this.props;
    return (
      <div className={styles.container}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              showAsterix && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          <TextField
            formClassName={formClassName}
            inputClassName={inputClassName}
            placeholder={placeholder}
            maxlength={maxlength}
            value={(input.value || input.value === 0) ? input.value.toString() : ''}
            onChange={this.onChange}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            autoFocus={autoFocus}
            multiline={multiline}
            fullWidth={fullWidth}
            disabled={disabled}
            type={type}
          />
          {
            (meta.invalid && meta.error !== 'Value required') && (
              <div className={styles.fieldError}>
                <span>{meta.error}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
