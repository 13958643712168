import { RESET_TEAM_COMPOSITION_LIST_SETTINGS, SET_TEAM_COMPOSITION_LIST_SETTINGS } from '../actions/teamCompositionActions';
import { PROJECT_TYPE_BILLABLE, TEAM_COMPOSITION_PROJECT_BASED } from '../../../utils/const-variable';

// Here 13 means current month, because we can choose from a range of 12 months before and up to the end of the current year.
const defaultListSettings = {
  filters: {
    ProjectTypes: [PROJECT_TYPE_BILLABLE],
    ReportType: TEAM_COMPOSITION_PROJECT_BASED,
    Month: 13,
  },
};

export const initialState = {
  listSettings: defaultListSettings,
};

const teamCompositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM_COMPOSITION_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_TEAM_COMPOSITION_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    default:
      return state;
  }
};

export default teamCompositionReducer;
