import React, { Fragment } from 'react';
import classNames from 'classnames';
import TextField from 'common/components/form-items/input/text-field';

export class UpdateManageItem extends React.Component {
  render() {
    const {
      id,
      item,
      onChangeNewDocumentObj,
      disableSaveDocument,
      newDocumentCancel,
      newDocumentApply,
    } = this.props;
    return (
      <Fragment>
        <div key={id} className='c-list__item-column without-width-col resume-input-link manage-resume__input-link'>
          <TextField
            formClassName='step__form resume-input'
            inputClassName='step__input'
            id='name'
            placeholder='Name'
            maxlength='4000'
            name='Name'
            value={item && item.Name}
            onChange={onChangeNewDocumentObj}
            autoFocus
            showClear={false}
          />
        </div>
        <div className='resume-input-discription manage-resume__input-discription'>
          <div className='toolbar-button resume-buttom-cancel' onClick={newDocumentCancel}>
            <span>Cancel</span>
          </div>
          <div
            className={classNames('button next resume-buttom-apply',
              { 'btn-disabled': !disableSaveDocument })}
            onClick={newDocumentApply}
          >
            <span>Apply</span>
          </div>
        </div>
      </Fragment>
    );
  }
}
