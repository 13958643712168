import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from 'react-select';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import Button from 'common/components/redux-form/components/button';
import CustomIcon from 'common/components/icon/Icon';

import {
  getAvailableTrainings,
  getAvailableTrainingClients,
} from '../../actions/membersActions';
import Checkbox from '../../../../common/components/form-items/checkbox/checkbox';

class EmployeeTrainingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Client: [],
      Training: '',
      DateOfPassing: null,
      ClientPlaceholder: '',
      IsNotApplicable: false,
    };
  }

  componentDidMount() {
    this.setDefaultEditData();
    const { loadManageEmployeeTrainings, loadManageEmployeeTrainingClients } = this.props;
    loadManageEmployeeTrainings();
    loadManageEmployeeTrainingClients();
  }

  componentDidUpdate(prevProps) {
    const { editEmployeeTrainingObject } = this.props;
    if (prevProps.editEmployeeTrainingObject !== editEmployeeTrainingObject) {
      this.setDefaultEditData();
    }
  }

  setDefaultEditData = () => {
    const { editEmployeeTrainingObject, isEditMode } = this.props;
    if (isEditMode) {
      const dateOfPassing = new Date(editEmployeeTrainingObject.DateOfPassing);

      if (!isNaN(dateOfPassing.getTime())) {
        this.setState({
          Client: editEmployeeTrainingObject.Client,
          Training: editEmployeeTrainingObject.Training,
          DateOfPassing: dateOfPassing,
          ClientPlaceholder: editEmployeeTrainingObject.Clients.map(
            (client) => client.Name,
          ).join(', '),
          IsNotApplicable: editEmployeeTrainingObject.IsNotApplicable,
        });
      } else {
        this.setState({
          Client: editEmployeeTrainingObject.Client,
          Training: editEmployeeTrainingObject.Training,
          ClientPlaceholder: editEmployeeTrainingObject.Clients.map(
            (client) => client.Name,
          ).join(', '),
          IsNotApplicable: editEmployeeTrainingObject.IsNotApplicable,
        });
      }
    }
  };

  confirmAddEmployeeTraining = () => {
    const { confirmDialog, closeDialog, isEditMode } = this.props;
    const {
      Client, Training, DateOfPassing, IsNotApplicable,
    } = this.state;
    confirmDialog(
      {
        Client,
        Training,
        DateOfPassing,
        IsNotApplicable,
      },
      isEditMode,
    );
    closeDialog();
  };

  handleChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeDate = (date) => {
    this.setState({
      DateOfPassing: date,
    });
  };

  handleChangeIsNotApplicable = () => {
    const { IsNotApplicable } = this.state;
    this.setState({
      IsNotApplicable: !IsNotApplicable,
      DateOfPassing: null,
    });
  }

  render() {
    const {
      closeDialog,
      isEditMode,
      employeeTrainings,
      empoyeeTrainingClients,
    } = this.props;
    const {
      Client, Training, DateOfPassing, ClientPlaceholder, IsNotApplicable,
    } = this.state;

    const anyClient = {
      Id: 'fixed-Any-Client-id',
      Value: 'Any',
      Name: 'Any',
      Label: 'Any',
    };
    const updatedEmployeeTrainingClients = [
      anyClient,
      ...empoyeeTrainingClients,
    ];

    const selectStyles = {
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    };

    const isDateSelected = DateOfPassing && DateOfPassing !== '' || IsNotApplicable;

    return (
      <Dialog
        open
        aria-labelledby='responsive-dialog-title'
        className='certification__dialog-width'
        style={{ zIndex: 999 }}
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>
            {!isEditMode ? 'Add Employee Training' : 'Set Training Date'}
          </span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent>
          {!isEditMode && (
            <div className='employee-training-modal-row'>
              <div
                className='customer-select-overlay'
                style={{ flex: '1', marginRight: '10px' }}
              >
                <div
                  className='customer-select-modal'
                  style={{ width: '100%' }}
                >
                  <Select
                    ref={this.selectRef}
                    menuPortalTarget={document.body}
                    placeholder={ClientPlaceholder}
                    options={updatedEmployeeTrainingClients.map((customer) => ({
                      id: customer.Id,
                      value: customer.Name,
                      name: customer.Name,
                      label: customer.Name,
                    }))}
                    onChange={(selectedOption) => {
                      this.setState({ Client: selectedOption.value });
                    }}
                    styles={selectStyles}
                    isDisabled={isEditMode}
                  />
                </div>
              </div>
              <div
                className='customer-select-overlay'
                style={{ flex: '1', marginLeft: '10px' }}
              >
                <div
                  className='customer-select-modal'
                  style={{ width: '100%' }}
                >
                  <Select
                    ref={this.selectRef}
                    menuPortalTarget={document.body}
                    value={isEditMode && Training}
                    placeholder={
                      isEditMode
                        ? Training
                        : Training.trim().length !== 0
                          ? Training
                          : 'Select Training'
                    }
                    options={employeeTrainings.map((training) => ({
                      id: training.Id,
                      value: training.Training,
                      name: training.Training,
                      label: training.Training,
                    }))}
                    onChange={(selectedOption) => {
                      this.setState({ Training: selectedOption.value });
                    }}
                    styles={selectStyles}
                    isDisabled={isEditMode}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className='employee-training-modal-row no-wrap'
            style={{ marginLeft: '-10px' }}
          >
            <DatePicker
              formClassName='step__form svg-calendar-add-info margin-left-10'
              datePickerClassName='employee-training-step__date employee-training-step__text'
              placeholder='Date Of Passing'
              label='Date Of Passing'
              value={DateOfPassing}
              onChange={this.handleChangeDate}
              isRequired
              name='DateOfPassing'
              isStartDate
              onBlur={this.handleChangeDate}
              maxVal={new Date()}
              disabled={IsNotApplicable}
            />
            <Checkbox
              checkboxContainerStyle='employee-training-step__checkbox margin-left-10'
              label='Is Not Applicable'
              value='Is Not Applicable'
              checked={IsNotApplicable}
              name='IsNotApplicable'
              onClick={this.handleChangeIsNotApplicable}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            className='button cancel employee-training-dialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmAddEmployeeTraining}
            disabled={!isDateSelected}
            className={`button ${
              isDateSelected ? 'next' : 'btn-disabled'
            } employee-training-dialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

EmployeeTrainingModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
  isEditMode: PropTypes.bool,
  loadManageEmployeeTrainings: PropTypes.func,
  loadManageEmployeeTrainingClients: PropTypes.func,
};

export default connect(
  (store) => ({
    employeeTrainings: store.membersReducer.availableTrainings,
    empoyeeTrainingClients: store.membersReducer.availableTrainingClients,
  }),
  {
    loadManageEmployeeTrainings: getAvailableTrainings,
    loadManageEmployeeTrainingClients: getAvailableTrainingClients,
  },
)(EmployeeTrainingModal);
