import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import {
  fetchProjects,
  changeColumnsToDisplay,
  searchProjects,
  resetProjectDetails,
  resetProjects,
  setListSettings,
} from '../../actions/projectsActions';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortFilterList } from 'common/components/list/sortList';
import { getSelectedTab } from '../../../../common/actions/selectedTabActions';
import { showDialog } from 'common/actions/confirmationDialogActions';
import isObjectNotEquals from 'utils/object-comparison';
import isEmptyObject from 'utils/isEmptyObject';
import { scrollToLastElement } from 'services/scrollToLastElement.js';
import { debounce } from 'services/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellProjectIcon } from 'common/components/list/custom-cell/cell-project-icon';
import { CellTimeleft } from 'common/components/list/custom-cell/cell-timeleft';
import { setComponent, resetComponent } from 'common/actions/headerActions';

const initialTakeAmount = 50;
const initialPage = 1;
const magnificationFactor = 10;

export class ProjectsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      takeAmount: initialTakeAmount,
      childState: {
        contracts: {
          search: '',
          sortColumn: 'StartDate',
          sortDirection: 'Up',
        },
        roles: {
          search: '',
          sortColumn: 'RoleName',
          sortDirection: 'Up',
        },
        history: {
          search: '',
          startDateHistory: null,
          endDateHistory: null,
        },
        teams: {
          search: '',
          sortColumn: 'TeamName',
          sortDirection: 'Up',
        },
      },
    };
  }

  componentDidMount() {
    const {
      resetProjectDetails,
      getSelectedTab,
      listSettings: {
        searchValue,
        scrollTo,
        sortColumn,
        sortDirection,
        filters,
      },
      searchProjects,
      setComponent,
      totalCount,
      defaultFilters,
      prevPath,
    } = this.props;

    const {
      childState,
      takeAmount,
    } = this.state;

    setListSettings({
      childState,
    });
    resetProjectDetails();
    getSelectedTab(0);
    if (scrollTo !== '') {
      scrollToLastElement('projects', scrollTo, true);
    }
    searchProjects(searchValue, filters, takeAmount, 0, { column: sortColumn, sortDirection });
    setComponent({ title: `Projects (${totalCount})` });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      listSettings,
      searchProjects,
      totalCount,
      setComponent,
      projects,
      resetProjects,
    } = this.props;

    const { takeAmount } = this.state;

    if (prevProps.totalCount !== totalCount) {
      setComponent({ title: `Projects (${totalCount})` });
    }

    if (takeAmount !== initialTakeAmount && prevState.takeAmount !== takeAmount) {
      searchProjects(
        listSettings.searchValue,
        listSettings.filters,
        takeAmount - projects.length,
        projects.length,
        { column: listSettings.sortColumn, sortDirection: listSettings.sortDirection },
      );
    }

    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      resetProjects();

      this.setState({
        takeAmount: initialTakeAmount,
      });

      this.scroll.pageLoaded = initialPage;

      searchProjects(
        listSettings.searchValue,
        listSettings.filters,
        initialTakeAmount,
        0,
        { column: listSettings.sortColumn, sortDirection: listSettings.sortDirection },
      );
    }
  }

  componentWillUnmount() {
    const { resetComponent, resetProjects } = this.props;
    resetComponent();
    resetProjects();
  }

  searchProjects = (searchValue) => {
    const { setListSettings } = this.props;
    const { childState } = this.state;
    setListSettings({ searchValue, childState });
  }

  applyFilters = (filters) => {
    const { setListSettings } = this.props;
    const { childState } = this.state;
    const filtersToSend = !isEmptyObject(filters) ? filters : { isEmpty: true };
    setListSettings({ filters: filtersToSend, childState });
  }

  resetFilters = () => {
    const { setListSettings } = this.props;
    const { childState } = this.state;
    setListSettings({ filters: {}, childState });
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettings } = this.props;
    const { childState } = this.state;
    setListSettings({ sortColumn, sortDirection, childState });
  }

  routeToProjectDetails = (id) => {
    history.push({
      pathname: `projects/${id}`,
    });
  }

  changeAmountProjects = (page) => {
    const { isLoading } = this.props;
    const { takeAmount } = this.state;

    const increaseParams = takeAmount + page * magnificationFactor;
    if (!isLoading) {
      this.setState({
        takeAmount: increaseParams,
      });
    }
  }

  addNewProject = () => {
    history.push({ pathname: '/projects/new' });
  }

  render() {
    const {
      offices,
      clients,
      statuses,
      projects,
      accountManagers,
      deliveryManagers,
      clientManagers,
      columnsToDisplay,
      changeColumnsToDisplay,
      showDialog,
      settings,
      alerts,
      projectTypes,
      listSettings: {
        sortColumn,
        sortDirection,
        filters,
        searchValue,
      },
      CanCreateProject,
      totalCount,
    } = this.props;

    return (
      <div className='page-cont'>
        <ListToolbar
          items={[{
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
            className: 'z-index_50',
          }, {
            name: 'Clients',
            id: 'Clients',
            options: sortFilterList(clients) || [],
            multiSelect: false,
            suggestBox: true,
            multiple: true,
            placeholder: 'All Clients',
            filtersItem: filters && filters.Clients,
            className: 'z-index_48',
          }, {
            name: 'Statuses',
            id: 'Statuses',
            options: sortFilterList(statuses) || [],
            multiSelect: true,
            placeholder: 'All Statuses',
            filtersItem: filters && filters.Statuses,
            className: 'z-index_46',
          }, {
            name: 'Business Development Managers',
            id: 'AccountManagers',
            options: sortFilterList(accountManagers) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Business Development Managers',
            filtersItem: filters && filters.AccountManagers,
            className: 'z-index_44',
          }, {
            name: 'Technical Account Managers',
            id: 'DeliveryManagers',
            options: sortFilterList(deliveryManagers) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Technical Account Managers',
            filtersItem: filters && filters.DeliveryManagers,
            className: 'z-index_42',
          }, {
            name: 'Client Managers',
            id: 'ClientManagers',
            options: sortFilterList(clientManagers) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Client Managers',
            filtersItem: filters && filters.ClientManagers,
            className: 'z-index_40',
          }, {
            name: 'Has Alerts',
            id: 'Alerts',
            options: sortFilterList(alerts) || [],
            multiSelect: true,
            placeholder: 'All Projects',
            filtersItem: filters && filters.Alerts,
            className: 'z-index_38',
          }, {
            name: 'Project Type',
            id: 'ProjectTypes',
            options: sortFilterList(projectTypes) || [],
            multiSelect: false,
            suggestBox: true,
            multiple: true,
            placeholder: 'All Types',
            filtersItem: filters && filters.ProjectTypes,
            className: 'z-index_38',
          }]}
          applyFilters={this.applyFilters}
          filters={filters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchProjects}
          columnsToDisplay={columnsToDisplay}
          changeColumnsToDisplay={changeColumnsToDisplay}
          showAddButton={CanCreateProject}
          onAddNew={this.addNewProject}
          searchPlaceholder='Search'
          searchValue={searchValue}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll' id='projects'>
            <InfiniteScroll
              pageStart={initialPage}
              loadMore={debounce(this.changeAmountProjects, 500)}
              hasMore={projects.length < totalCount}
              useWindow={false}
              initialLoad={false}
              ref={(scroll) => this.scroll = scroll}
            >
              <List
                page='projects'
                columnsToDisplay={columnsToDisplay}
                items={projects}
                onItemClick={(id) => this.routeToProjectDetails(id)}
                onEditClick={(id) => this.routeToProjectDetails(id)}
                onDeleteClick={showDialog}
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                hideDots
                dontShowOptions
                timeLeft={settings.ContractFirstNotification}
                fixedHeader
                config={{
                  'Status': {
                    cell: CustomStatusCell,
                  },
                  'Name': { cell: CellProjectIcon },
                  'EndDate': {
                    cell: CellTimeleft,
                    daysToExpire: settings.ProjectExpiration,
                  },
                }}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

ProjectsListComponent.propTypes = {
  columnsToDisplay: PropTypes.array,
  projects: PropTypes.array,
  offices: PropTypes.array,
  clients: PropTypes.array,
  statuses: PropTypes.array,
  accountManagers: PropTypes.array,
  deliveryManagers: PropTypes.array,
  alerts: PropTypes.array,
  clientManagers: PropTypes.array,
  changeColumnsToDisplay: PropTypes.func,
  searchProjects: PropTypes.func,
  resetProjectDetails: PropTypes.func,
  CanCreateProject: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
};

export default connect((store) => ({
  columnsToDisplay: store.projectsReducer.columnsToDisplay,
  projects: [...store.projectsReducer.projects],
  offices: store.filtersReducer.filters.Offices,
  clients: store.filtersReducer.filters.Clients,
  alerts: store.filtersReducer.filters.Alerts,
  projectTypes: store.filtersReducer.filters.ProjectTypes,
  statuses: store.filtersReducer.filters.ProjectStatuses,
  accountManagers: store.filtersReducer.filters.AccountManagers,
  deliveryManagers: store.filtersReducer.filters.DeliveryManagers,
  clientManagers: store.filtersReducer.filters.ClientManagers,
  settings: store.adminReducer.settings,
  totalCount: store.projectsReducer.projectTotalCount,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.projectsReducer.listSettings,
}), {
  fetchProjects,
  changeColumnsToDisplay,
  searchProjects,
  resetProjectDetails,
  getSelectedTab,
  showDialog,
  setComponent,
  resetComponent,
  resetProjects,
  setListSettings,
})(ProjectsListComponent);
