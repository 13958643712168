import moment from 'moment';

export const compare = (a, b, direction, isNullItsMax) => {
  if (isNullItsMax) {
    if (!a) {
      return direction === 'Up' ? 1 : -1;
    }
    if (!b) {
      return direction === 'Up' ? -1 : 1;
    }
    return direction === 'Up' ? (
      a > b ? 1 : (b > a) ? -1 : 0
    ) : (
      a < b ? 1 : (b < a) ? -1 : 0
    );
  }

  if (!a) {
    return direction === 'Up' ? -1 : 1;
  }
  if (!b) {
    return direction === 'Up' ? 1 : -1;
  }
  return direction === 'Up' ? (
    a > b ? 1 : (b > a) ? -1 : 0
  ) : (
    a < b ? 1 : (b < a) ? -1 : 0
  );
};

export const sortEmployeeSkillLevelByAlphabet = (a, b) => {
  if (a.Level.Name > b.Level.Name) {
    return 1;
  }
  if (a.Level.Name < b.Level.Name) {
    return -1;
  }
  return 0;
};

const getTypeFromData = (data) => {
  if (Array.isArray(data)) {
    return 'array';
  }
  const regex = /[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}/;
  if (regex.test(data)) {
    return 'date';
  }
  return typeof data;
};

const sortItems = (column, direction) => {
  return (current, next) => {
    if (column === 'DaysToExpire') {
      return compare(current[column], next[column], direction, true);
    }
    if (column === 'ExpirationDate') {
      return compare(current[column], next[column], direction, true);
    }

    switch (getTypeFromData(current[column])) {
      case 'array': {
        return current[column]['Name']
          ? compare((current[column].map(i => i.Name)).join().toLowerCase(), (next[column].map(i => i.Name)).join().toLowerCase(), direction)
          : compare(current[column].join().toLowerCase(), next[column].join().toLowerCase(), direction);
      }
      case 'string':
        return compare(current[column] && current[column].toLowerCase(), next[column] && next[column].toLowerCase(), direction);
      case 'number':
        return compare(current[column], next[column], direction);
      case 'date':
        return compare(moment(current[column]).unix(), moment(next[column]).unix(), direction);
      case 'object':
        return compare(current[column] && current[column].Name, next[column] && next[column].Name, direction);
      default:
        break;
    }
  };
};

export const sortProjectsForEmployee = (items, column, direction) => {
  return items.sort((current, next) => {
    const primaryComparison = current[column] && next[column] ? sortItems(column, direction)(current, next) : 0;
    if (primaryComparison === 0) {
      if (current.AssignmentStatus.Name === 'Active' && next.AssignmentStatus.Name === 'Active') {
        return current.PositionBillableStatus === 'Billable' ? -1 : 1;
      } else {
        return current.AssignmentStatus.Name === 'Active' ? -1 : 1;
      }
    } else {
      return primaryComparison;
    }
  });
};

export const sortFilterList = (items, direction = 'Up') => {
  if (items) {
    if (items[0] && items[0].Name) {
      return items.sort((a, b) => compare(a.Name, b.Name, direction));
    }
    return items.sort((a, b) => compare(a, b, direction));
  }
};

export const sortList = (items, column, direction) => {
  if (!items || !items[0]) {
    return [];
  }
  if (!column || !direction) {
    return items;
  }

  for (let i = 0; i < items.length; i++) {
    if (Array.isArray(items[i][column]) || moment(items[i][column], moment.ISO_8601, true).isValid()) {
      return Array.isArray(items[i][column]) ? items.sort(sortItems(column, direction, 'array')) : items.sort(sortItems(column, direction, 'date'));
    }
  }
  return items.sort(sortItems(column, direction, 'string'));
};
