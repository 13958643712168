import React, { Component } from 'react';
import classNames from 'classnames';
import { SowRenewalStatusItem } from './sow-renewal-status-item';

export class SowRenewalStatusList extends Component {
  calculationPercent = () => {
    const { items } = this.props;
    const percentForStatus = 100 - items.reduce((acc, item) => {
      return item.IsActive ? acc + +item.Value : acc;
    }, 0);
    return percentForStatus > 0 ? +percentForStatus.toFixed(2) : 0;
  }

  recalculationPercent = (renewalStatusId) => {
    const { items, onChange } = this.props;
    const countCheckedStatuses = items.filter((item) => item.IsActive && !+item.Value && item.RenewalStatusId !== renewalStatusId).length;
    const lastPercent = this.calculationPercent();
    const newStatuses = items.map((item) => !+item.Value && item.IsActive && item.RenewalStatusId !== renewalStatusId
      ? ({
        ...item,
        Value: +(+item.Value + lastPercent / countCheckedStatuses).toFixed(2),
      }) : item);
    onChange(newStatuses);
  }

  render() {
    const {
      items, columnsToDisplay, onChange,
    } = this.props;
    return (
      <div>
        <div className={classNames('c-list__header')}>
          {
            columnsToDisplay && columnsToDisplay.map((column) => (
              <div key={column.RenewalStatusId} className={classNames('c-list__header-col', column.className)}>
                {/* {isCheckboxShown ? <div className='c-list__header-col smallest-col' /> : null} */}
                <div className='c-list__header-name'>
                  {column.breakline ? (
                    <span>
                      {column.Name.split(' ')[0]}
                      <br />
                      {column.Name.split(' ')[1]}
                    </span>
                  ) : (
                    <span>
                      {column.Name}
                    </span>
                  )}
                </div>
              </div>))
          }
        </div>
        {
          items && items.length && items.map((item) => (
            <SowRenewalStatusItem
              item={item}
              onChange={onChange}
              calculationPercent={this.calculationPercent}
              recalculationPercent={this.recalculationPercent}
            />
          ))
        }
      </div>
    );
  }
}
