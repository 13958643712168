import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { destroy, Field, initialize, reduxForm, change } from "redux-form";
import Button from "../components/button";
import renderDatePicker from "../components/date-picker";
import renderDocumentList from "../components/document-list";
import renderSearchDropdown from "../components/search-dropdown";
import renderSelect from "../components/select";
import renderTextField from "../components/text-field";
import {
  required, validateDate
} from 'services/redux-form-validators.js';
import { cancelImport } from "../../../../pages/projects/actions/importContractAction";
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { addNewMSA, editCurrentMSA } from 'pages/projects/actions/contractsActions';
import { getChangeConfigForPatchRequests, combinedConfigsForPatchRequests } from 'utils/getConfigBasedOnCheckedDates';
import history from 'utils/history';

const defaultStyles = {
  container: 'redux-form__field-container edit-SOW__simple-container',
  label: 'redux-form__SOW--label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

class ProjectEditMSAForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      isNeedToChangeDate: false,
      messageForConfirmDialog: '',
      configPatchRequestsForConfirmDialog: null,
    };

    this.disable = {
      Signer: () => {
        const { Company } = this.props;
        return !Company;
      },
    };
  }

  componentDidMount() {
    const { data, initialize } = this.props;
    initialize(data);
  }

  componentDidUpdate(prevProps) {
    const { configPatchRequestsForConfirmDialog, isNeedToChangeDate } = this.state;
    const { StartDate, EndDate } = this.props;

    if (prevProps.StartDate !== StartDate || prevProps.EndDate !== EndDate) {
      if (!EndDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'endDate'),
        });
      }
      if (!StartDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'startDate'),
        });
      }
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  getStartDatesForDatePicker = () => {
    const { ProjectStartDate, projectId } = this.props;
    return [{ date: ProjectStartDate, type: 'Project', id: projectId }];
  }

  getEndDatesForDatePicker = () => {
    const { ProjectEndDate, projectId } = this.props;
    return [{ date: ProjectEndDate, type: 'Project', id: projectId }];
  }

  onCancelClick = () => {
    const { cancelContractImport, Project } = this.props;
    cancelContractImport(Project.Id);
  }

  addMSA = (projectId, isOnlyActiveRoles) => {
    const { addMSA } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    addMSA(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
    history.push({
      pathname: `/projects/${projectId}`,
    });
  }

  editMSA = (projectId, isOnlyActiveRoles) => {
    const { editMSA, location } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    const saveCallback = (location.state && location.state.saveCallback) || `/projects/${projectId}`;

    editMSA(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, saveCallback);
  }

  applyForm = () => {
    const { Id, Project, cancelContractImport, isOnlyActiveRoles } = this.props;
    Id ? this.editMSA(Project.Id, isOnlyActiveRoles) : this.addMSA(Project.Id, isOnlyActiveRoles);
    cancelContractImport(Project.Id);
  }

  closeConfirmationDialog = () => {
    const { change } = this.props;
    const { typeDateFormDeleteIfUserCloseConfirmationDialog } = this.state;
    this.setState({
      showConfirmationDialog: false,
      isNeedToChangeDate: false,
    });
    change(typeDateFormDeleteIfUserCloseConfirmationDialog, null);
  }

  showConfirmationDialog = (configForConfirmDialog) => {
    const { configPatchRequestsForConfirmDialog } = this.state;
    this.setState({
      showConfirmationDialog: true,
      messageForConfirmDialog: configForConfirmDialog.message,
      typeDateFormDeleteIfUserCloseConfirmationDialog: configForConfirmDialog.typeChangedDate,
      configPatchRequestsForConfirmDialog:
        combinedConfigsForPatchRequests(configForConfirmDialog.configForPatchRequests, configPatchRequestsForConfirmDialog),
    });
  }

  onConfirmDialog = () => {
    this.setState({
      isNeedToChangeDate: true,
      showConfirmationDialog: false,
    });
  }

  onChange = (data, value, prevValue, field) => {
    const { change } = this.props;

    switch (field) {
      case 'Company':
        change('Signer', '');
        break;
      default: break;
    }
  }

  render() {
    const {
      showConfirmationDialog, messageForConfirmDialog,
    } = this.state;
    const {
      disabled, allApprovalStatuses, allClients, allSigners, allStatuses, columnsToDisplay, viewFinancialInfo, allDocumentTypes, Contract,
      ApprovalStatus, Company, Signer, StartDate, EndDate, Status, Links, formErrors, showAlertDialog, handleSubmit, Comments,
    } = this.props;
    return (
      <form className='redux-form height-100pc' onSubmit={handleSubmit}>
        {
          showConfirmationDialog ? (
            <ConfirmationDialog
              dialogHeader='CHANGE DATE'
              dialogTitle={messageForConfirmDialog}
              closeButtonTitle='Cancel'
              confirmButtonTitle='OK'
              closeDialog={this.closeConfirmationDialog}
              confirmDialog={this.onConfirmDialog}
            />
          ) : null
        }
        <div>
          <div className='redux-form__row justify-start'>
            <Field
              name='Contract'
              value={Contract || ''}
              component={renderTextField}
              label='MSA ID'
              placeholder='Set MSA ID'
              maxLength='100'
              styles={defaultStyles}
              disabled={disabled}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              inputClassName='edit-SOW__text-field'
            />
            <Field
              name='ApprovalStatus'
              value={ApprovalStatus}
              component={renderSelect}
              options={allApprovalStatuses}
              label='MSA Approval Status'
              placeholder='Set MSA Approval Status'
              styles={defaultStyles}
              disabled={disabled}
              isOptionObject
              extendedValue
              inputProps={{ id: 'msa_approval_status' }}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
          </div>
          <div className='redux-form__row justify-start'>
            <Field
              name='StartDate'
              value={StartDate}
              component={renderDatePicker}
              validate={[required, validateDate.startDate, validateDate.isValid]}
              disabled={disabled}
              showAsterix
              isStartDate
              setPrevValueOnError
              placeholder='--/--/--'
              label='MSA Start Date'
              pickerName='startDateMSA'
              styles={defaultStyles}
              formClassName='edit-SOW__item-form'
              datePickerClassName='edit-SOW__date-picker'
              showAlertDialog={showAlertDialog}
              showConfirmationDialog={this.showConfirmationDialog}
              endDates={this.getEndDatesForDatePicker()}
              typeParent='project'
              typeCurrentDocument='MSA'
              endDateContract={EndDate}
            />
            <Field
              name='EndDate'
              value={EndDate}
              component={renderDatePicker}
              validate={[validateDate.endDate, validateDate.isValid]}
              disabled={disabled}
              isEndDate
              setPrevValueOnError
              placeholder='--/--/--'
              label='MSA End Date'
              pickerName='endDateMSA'
              styles={defaultStyles}
              formClassName='edit-SOW__item-form'
              datePickerClassName='edit-SOW__date-picker'
              showAlertDialog={showAlertDialog}
              showConfirmationDialog={this.showConfirmationDialog}
              endDates={this.getEndDatesForDatePicker()}
              startDates={this.getStartDatesForDatePicker()}
              typeParent='project'
              typeCurrentDocument='MSA'
              startDateContract={StartDate}
            />
          </div>
          <div className='redux-form__row justify-start'>
            <Field
              name='Company'
              value={Company}
              component={renderSearchDropdown}
              options={allClients}
              onChange={this.onChange}
              label='Company Name'
              placeholder='Set Company Name'
              validate={required}
              disabled={disabled}
              inputProps={{ id: 'client' }}
              showAsterix
              isAddingFeature
              styles={defaultStyles}
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__search-dropdown'
            />
            <Field
              name='Signer'
              value={Signer}
              component={renderSearchDropdown}
              options={allSigners && allSigners.filter(signer => Company && signer.ClientId === Company)}
              label='Client MSA Signer'
              placeholder='Set Client MSA Signer'
              validate={required}
              inputProps={{ id: 'invoice_approver' }}
              disabled={disabled}
              showAsterix
              isAddingFeature
              styles={defaultStyles}
              parentEntityId={Company}
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__search-dropdown'
            />
            <Field
              name='Status'
              value={Status}
              component={renderSelect}
              options={allStatuses}
              label='Document Status'
              placeholder='Set Document Status'
              styles={defaultStyles}
              disabled={disabled}
              validate={required}
              showAsterix
              isOptionObject
              extendedValue
              inputProps={{ id: 'document_status' }}
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
          </div>
          <div className='redux-form__row justify-start row__items--align-start'>
            <Field
              name='Comments'
              value={Comments}
              component={renderTextField}
              label='Comments'
              placeholder='Add Comments'
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container margin-left-right-10',
                field: 'full-width-field height-100pc ololo1',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
              disabled={disabled}
              maxLength='4000'
              fullWidth
              multiline
              inputClassName='edit-SOW__text-field'
            />
          </div>
          <div className='redux-form__row justify-start'>
            {viewFinancialInfo && <Field
              name='Links'
              value={Links}
              component={renderDocumentList}
              disabled={disabled}
              columnsToDisplay={columnsToDisplay.documentsList}
              viewFinancialInfo={viewFinancialInfo}
              documentTypes={allDocumentTypes}
              isDocumentType
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container margin-left-right-10',
                field: 'full-width-field height-100pc',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
            />}
          </div>
          <div className='redux-form__SOW--buttons'>
            <Button
              onClick={this.onCancelClick}
              className='button cancel'
              text={disabled ? 'Back' : 'Cancel'}
            />
            <Button
              onClick={this.applyForm}
              disabled={!!formErrors}
              visible={!disabled}
              className={classNames('button next', { 'btn-disabled': !!formErrors })}
              text={'Apply'}
            />
          </div>
        </div>
      </form>   
      )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('projectEditMSAForm', data)),
    destroy: () => dispatch(destroy('projectEditMSAForm')),
    change: (field, value) => dispatch(change('projectEditMSAForm', field, value)),
    cancelContractImport: (id) => dispatch(cancelImport(id)),
    addMSA: (projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => (
      dispatch(addNewMSA(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog))),
    editMSA: (projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, saveCallback) => (
      dispatch(editCurrentMSA(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, saveCallback))),
  }
}

export default connect(state => {
  const isFormReducerExists = state.form.projectEditMSAForm && state.form.projectEditMSAForm.values;
  return {
    Id: isFormReducerExists && state.form.projectEditMSAForm.values.Id,
    Contract: isFormReducerExists && state.form.projectEditMSAForm.values.Contract,
    ApprovalStatus: isFormReducerExists && state.form.projectEditMSAForm.values.ApprovalStatus,
    StartDate: isFormReducerExists && state.form.projectEditMSAForm.values.StartDate,
    EndDate: isFormReducerExists && state.form.projectEditMSAForm.values.EndDate,
    Company: isFormReducerExists && state.form.projectEditMSAForm.values.Company,
    Signer: isFormReducerExists && state.form.projectEditMSAForm.values.Signer,
    Status: isFormReducerExists && state.form.projectEditMSAForm.values.Status,
    Comments: isFormReducerExists && state.form.projectEditMSAForm.values.Comments,
    Links: isFormReducerExists && state.form.projectEditMSAForm.values.Links,
    formErrors: isFormReducerExists && state.form.projectEditMSAForm.syncErrors,
    initial: isFormReducerExists && state.form.projectEditMSAForm.initial,
    isOnlyActiveRoles: state.showRoles.isOnlyActiveRoles,
    ProjectEndDate: state.projectsReducer.projectDetails.EndDate,
    ProjectStartDate: state.projectsReducer.projectDetails.StartDate,
    Project: isFormReducerExists && state.form.projectEditMSAForm.values.Project,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'projectEditMSAForm',
})(ProjectEditMSAForm));