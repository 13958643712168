import { MAX_COUNT_ROLE_ASSIGNMENTS, MAX_COUNT_ROLE_EMPLOYEE_ASSIGNMENTS } from 'utils/const-variable';

const requExpRoleAssignment = RegExp('^((?!0)|(0\\.)|(\\d{1,4}))(\\.\\d{1,2})?$');

export const isCorrectRoleAssignment = (assignment) => {
  return requExpRoleAssignment.test(assignment) && assignment.trim().length && assignment <= MAX_COUNT_ROLE_ASSIGNMENTS;
};

const requExpRoleEmployeeAssignment = RegExp('^((?!0)|(0\\.)|(\\d{1,2}))(\\.\\d{1,2})?$');

export const isCorrectRoleEmployeeAssignment = (assignment) => {
  return requExpRoleEmployeeAssignment.test(assignment) && assignment.trim().length && assignment <= MAX_COUNT_ROLE_EMPLOYEE_ASSIGNMENTS;
};
