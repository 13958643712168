import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultiSuggestBox from 'common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import classNames from 'classnames';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core/';
import './modal-merge.css';

export class ModalMerge extends Component {
  render() {
    const {
      handleChangeMainMergeEntity,
      handleGetMergeElements,
      mainOptions,
      mergeOptions,
      isDisabledSuggest,
      isApplyDisabled,
      cancelMerge,
      onClick,
      toogleModalMerge,
      lookup,
    } = this.props;

    return (
      <div>
        <Dialog onClose={toogleModalMerge} fullWidth={false} maxWidth='md' scroll='body' open>
          <DialogTitle>Merge</DialogTitle>
          <DialogContent className='overflow-initial'>
            <div className='suggest-wrapper'>
              <MultiSuggestBox
                label={`Main ${lookup.toLowerCase()}`}
                placeholder={`Main ${lookup.toLowerCase()}`}
                options={mainOptions}
                onAdd={handleChangeMainMergeEntity}
              />
            </div>
            <div className='suggest-wrapper'>
              <MultiSuggestBox
                label={`${lookup}s for merge`}
                placeholder={`${lookup}s for merge`}
                options={mergeOptions}
                isMulti
                onAdd={handleGetMergeElements}
                isDisabled={isDisabledSuggest}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className='button cancel' onClick={cancelMerge}>
              <span>Cancel</span>
            </div>
            <div
              className={classNames(
                'button next',
                { 'btn-disabled': isApplyDisabled },
              )}
              onClick={onClick}
            >
              <span>Apply</span>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ModalMerge.propTypes = {
  handleChangeMainMergeEntity: PropTypes.func.isRequired,
  handleGetMergeElements: PropTypes.func.isRequired,
  cancelMerge: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  toogleModalMerge: PropTypes.func.isRequired,
  mainOptions: PropTypes.array.isRequired,
  mergeOptions: PropTypes.array.isRequired,
  isDisabledSuggest: PropTypes.bool.isRequired,
  isApplyDisabled: PropTypes.bool.isRequired,
  lookup: PropTypes.string.isRequired,
};
