import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getValueFromObject from 'utils/getValueFromObject';

export const DeepCell = (props) => {
  const { item, column, config } = props;
  const columnConfig = config[column.Id];
  const { keys } = columnConfig;
  const columnValue = keys && keys.length ? getValueFromObject(item, keys) : item[column.Id];
  return (
    <div
      className={classNames(
        'c-list__item-column',
        column.className ? column.className : 'small-col',
      )}
    >
      <span className='col-span' title={columnValue}>
        {columnValue}
      </span>
    </div>
  );
};

DeepCell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
