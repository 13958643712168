export const GET_MANAGE_SKILL_LEVELS = '@admin/GET_MANAGE_SKILL_LEVELS';
export const SET_MANAGE_SKILL_LEVELS = '@admin/SET_MANAGE_SKILL_LEVELS';
export const CREATE_NEW_SKILL_LEVEL = '@admin/CREATE_NEW_SKILL_LEVEL';
export const UPDATE_SKILL_LEVEL = '@admin/UPDATE_SKILL_LEVEL';
export const DELETE_SKILL_LEVEL = '@admin/DELETE_SKILL_LEVEL';
export const UPDATE_ORDER_SKILL_LEVELS = '@admin/UPDATE_ORDER_SKILL_LEVELS';

export const getManageSkillLevels = (data) => ({
  type: GET_MANAGE_SKILL_LEVELS,
  payload: data,
});

export const setManagesSkillLevels = (SkillLevels) => ({
  type: SET_MANAGE_SKILL_LEVELS,
  payload: SkillLevels,
});

export const createNewSkillLevels = (data) => {
  return {
    type: CREATE_NEW_SKILL_LEVEL,
    payload: data,
  };
};

export const updateOrderSkillLevels = (data) => {
  return {
    type: UPDATE_ORDER_SKILL_LEVELS,
    payload: data,
  };
};

export const updateSkillLevels = (Name, Id) => {
  return {
    type: UPDATE_SKILL_LEVEL,
    payload: {
      Name,
      Id,
    },
  };
};

export const deleteSkillLevels = (id) => {
  return {
    type: DELETE_SKILL_LEVEL,
    payload: {
      seniorityLevelId: id,
    },
  };
};
