import { FORBIDEN_STATUS, PAGE_NOT_FOUND_STATUS } from 'utils/const-variable';

export const isHasPermissionForRoute = (response) => {
  switch (response.status) {
    case FORBIDEN_STATUS:
    case PAGE_NOT_FOUND_STATUS:
      return false;
    default:
      return true;
  }
};
