import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import {
  getProjectedRevenueReports,
  changeColumnsToDisplay,
  changeInnerColumnsToDisplay,
  setProjectedRevenueReports,
  setFinanceListSettings,
  resetFinanceListSettings,
  setFinanceListDates,
  resetFinanceListDates,
} from '../../actions/financeActions';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { DeepCell } from 'common/components/list/custom-cell/deep-cell';
import { transitionToContract, transitionToRole } from '../../actions/reportsActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { CellBudget } from 'common/components/list/custom-cell/cell-budget';
import { CellPercent } from 'common/components/list/custom-cell/cell-percent';
import { emptyArray } from 'utils/const-variable';
import isObjectNotEquals from 'utils/object-comparison';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { sortFilterList } from 'common/components/list/sortList';

const BookedRevenueGeneratedColumnEnding = '_BookedRevenue';
const ProjectedRevenueGeneratedColumnEnding = '_ProjectedRevenue';

export class ProjectedRevenueReports extends React.Component {
  constructor(props) {
    super(props);

    this.headerWrapperRef = React.createRef();

    this.state = {
      listConfig: {
        'Company': { cell: CellLinkForRedirect },
        'Project': { cell: CellLinkForRedirect },
        'Budget': { cell: CellBudget },
        'ProjectedRevenueTotal': { cell: CellBudget },
        'RealizedRevenueToDate': { cell: CellBudget },
        'PredictedRevenuePercent': { cell: CellPercent },
        'PredictedBudgetUtilizationPercent': { cell: CellPercent },
        'RealizedRevenuePercent': { cell: CellPercent },
        'RemainingBalance': { cell: CellBudget },
        'SowType': { cell: DeepCell, keys: ['Sow', 'SowType', 'Name'] },
        'Leftover': { cell: CellBudget },
        'RevenueReceived': { cell: CellBudget },
      },
    };
  }

  componentDidMount() {
    const {
      getProjectedRevenueReports, setComponent, listSettings: {
        searchValue, sortColumn, sortDirection, filters,
      },
    } = this.props;
    this.setMonthToShow();
    const columnToSend = this.defineColumnToSend(sortColumn);
    getProjectedRevenueReports(searchValue, columnToSend, sortDirection, filters);
    setComponent({ title: `Projected Revenue Report - ${moment().format('MM/DD/YYYY')}` });
  }

  componentDidUpdate(prevProps) {
    const { getProjectedRevenueReports, listSettings } = this.props;
    const {
      sortColumn, sortDirection, searchValue, filters,
    } = listSettings;
    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      const columnToSend = this.defineColumnToSend(sortColumn);
      getProjectedRevenueReports(searchValue, columnToSend, sortDirection, filters);
      this.setMonthToShow();
    }
  }

  componentWillUnmount() {
    const {
      setProjectedRevenueReports, resetComponent, resetFinanceListSettings, resetFinanceListDates,
    } = this.props;
    setProjectedRevenueReports(emptyArray);
    resetComponent();
    resetFinanceListSettings();
    resetFinanceListDates();
  }

  defineColumnToSend = (column) => {
    if (column.includes(BookedRevenueGeneratedColumnEnding)) {
      return `Booked Revenue ${moment(column, 'M_Y').format('MMM, YY')}`;
    }
    if (column.includes(ProjectedRevenueGeneratedColumnEnding)) {
      return `Projected Revenue ${moment(column, 'M_Y').format('MMM, YY')}`;
    }
    return column;
  }

  buildCorrectPath = (id, column) => {
    const { reports: { finance }, transitionToContract, transitionToRole } = this.props;
    const correctId = (id[column] && id[column].Id) || '';
    if (column === 'Company') {
      return this.transitionTo(`/clients/${correctId}`);
    }
    if (column === 'Project') {
      return this.transitionTo(`/projects/${correctId}`);
    }
    if (column === 'Employee') {
      return this.transitionTo(`/employees/${correctId}`);
    }
    if (column === 'SowIdPoNumber') {
      const contract = {
        id: '',
        rights: 'edit',
        document: '',
        contractId: '',
      };
      finance.forEach((report) => {
        report.Contracts.forEach(item => {
          if (item.Id === id) {
            contract.contractId = item.Id;
            contract.id = report.Project.Id;
            contract.document = item.Type.startsWith('SOW') ? 'sow' : 'po';
          }
        });
      });
      transitionToContract(contract);
    }
    if (column === 'RoleName' || column === 'RoleType') {
      const currentRole = {
        id: '',
        roleId: id.RoleId,
        rights: 'edit',
      };
      finance.forEach((report) => {
        report.Contracts.forEach(contract => {
          contract.Roles.forEach((role) => {
            if (role.RoleId === id.RoleId) {
              currentRole.id = report.Project.Id;
            }
          });
        });
      });
      transitionToRole(currentRole);
    }
  }

  setMonthToShow = () => {
    const {
      columnsToDisplay, changeColumnsToDisplay, innerColumnsToDisplaySOWs, changeInnerColumnsToDisplay,
      filtersDates,
    } = this.props;
    const { listConfig } = this.state;

    const startMonth = moment(filtersDates.StartDate).format('YYYY-MM-DD');
    const endMonth = moment(filtersDates.EndDate).format('YYYY-MM-DD');
    const newConfigFields = {};
    const innerAdditionalColumns = [];
    const additionalColumns = this.defineMonths(startMonth, endMonth).flatMap(elem => {
      newConfigFields[`${elem}${BookedRevenueGeneratedColumnEnding}`] = { cell: CellBudget };
      newConfigFields[`${elem}${ProjectedRevenueGeneratedColumnEnding}`] = { cell: CellBudget };
      const columnForBookedRevenue = {
        Name: `Booked Revenue ${moment(elem, 'M_Y').format('MMM, YYYY')}`,
        Id: `${elem}${BookedRevenueGeneratedColumnEnding}`,
        isAdditionalColumn: true,
        isBudget: true,
        className: 'xs-col small-report-col',
      };
      const columnForProjectedRevenue = {
        Name: `Projected Revenue ${moment(elem, 'M_Y').format('MMM, YYYY')}`,
        Id: `${elem}${ProjectedRevenueGeneratedColumnEnding}`,
        isAdditionalColumn: true,
        isBudget: true,
        className: 'xs-col small-report-col',
      };
      innerAdditionalColumns.push({ ...columnForBookedRevenue, deleteArrow: 'delete-arrow' });
      innerAdditionalColumns.push({ ...columnForProjectedRevenue, deleteArrow: 'delete-arrow' });
      return [columnForBookedRevenue, columnForProjectedRevenue];
    });
    const positionOfMonthsInColumnsToDisplay = 8;
    const positionOfMonthsInInnerColumnsToDisplay = 6;
    const prevAditionalColumnsAmount = columnsToDisplay.filter(col => col.isAdditionalColumn).length;
    const newColumnsToDisplay = [...columnsToDisplay];
    newColumnsToDisplay.splice(positionOfMonthsInColumnsToDisplay, prevAditionalColumnsAmount, ...additionalColumns);
    const newInnerColumnsToDisplaySOWs = [...innerColumnsToDisplaySOWs];
    newInnerColumnsToDisplaySOWs.splice(positionOfMonthsInInnerColumnsToDisplay, prevAditionalColumnsAmount, ...innerAdditionalColumns);

    changeColumnsToDisplay(newColumnsToDisplay);
    changeInnerColumnsToDisplay(newInnerColumnsToDisplaySOWs);
    this.setState({ listConfig: { ...listConfig, ...newConfigFields } });
  }

  defineMonths = (dateStart, dateEnd) => {
    const arr = [];
    dateStart = moment(dateStart);
    dateEnd = moment(dateEnd);
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      arr.push(dateStart.format('M_Y'));
      dateStart.add(1, 'month');
    }
    return arr;
  }

  transitionTo = (path) => {
    return {
      pathname: path,
      state: {
        filters: {},
        search: '',
      },
    };
  }

  getXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { filters, searchValue } = listSettings;
    downloadXLSXDocument(filters, searchValue, 'projected-revenue-reports/export-xlsx?Search=', 'Projected Revenue Reports.xlsx');
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setFinanceListSettings } = this.props;
    setFinanceListSettings({
      sortColumn,
      sortDirection,
    });
  };

  getProjectedRevenueReports = (searchValue) => {
    const { setFinanceListSettings } = this.props;
    setFinanceListSettings({ searchValue });
  }

  resetFilters = () => {
    const { resetFinanceListSettings, resetFinanceListDates } = this.props;
    resetFinanceListSettings();
    resetFinanceListDates();
  }

  applyFilters = (filters) => {
    const { setFinanceListSettings } = this.props;
    setFinanceListSettings({ filters });
  }

  handleChangeDate = (date, id) => {
    const { setFinanceListDates } = this.props;
    setFinanceListDates({ [id]: date ? moment(date).format('MM/DD/YYYY') : '' });
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  renderTrackHorizontal = (props) => (<div {...props} className='track-horizontal' />);

  handleScrollbarsUpdate = (values) => {
    if (this.headerWrapperRef.current) {
      this.headerWrapperRef.current.scrollLeft = values.scrollLeft;
    }
  }

  render() {
    const {
      HasFinancialAccess, reports, columnsToDisplay, innerColumnsToDisplaySOWs, filtersDates, documentStatuses,
      listSettings: {
        sortColumn, sortDirection, searchValue, filters,
      },
    } = this.props;
    const { listConfig } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          searchPlaceholder='Search Reports'
          searchValue={searchValue}
          showExportButton
          exportDocument={this.getXLSXDocument}
          onSearchClick={this.getProjectedRevenueReports}
          validateFilterDates
          items={[{
            name: 'Start Date',
            id: 'StartDate',
            datepicker: true,
            placeholder: 'Date',
            formClassName: 'step__form svg-calendar-add-info suggest-wrapper',
            datePickerClassName: 'step__date-picker filter-container__date-picker',
            value: filtersDates.StartDate,
            onChange: this.handleChangeDate,
            className: 'z-index_50',
            isMonthAndYear: true,
            validate: true,
            minVal: moment().startOf('month').format('MM/DD/YYYY'),
            maxVal: moment().add(11, 'month').startOf('month').format('MM/DD/YYYY'),
            minErrorMassage: 'Start Date should be after current month',
          }, {
            name: 'End Date',
            id: 'EndDate',
            datepicker: true,
            placeholder: 'Date',
            formClassName: 'step__form svg-calendar-add-info suggest-wrapper',
            datePickerClassName: 'step__date-picker filter-container__date-picker',
            value: filtersDates.EndDate,
            onChange: this.handleChangeDate,
            className: 'z-index_48',
            disabled: !filtersDates.StartDate,
            isMonthAndYear: true,
            minVal: filtersDates.StartDate,
            maxVal: moment().add(11, 'months').endOf('month').format('MM/DD/YYYY'),
            validate: true,
            minErrorMassage: 'End Date should be after Start Date',
            maxErrorMassage: 'Data is not available for more than 1 year period',
          }, {
            name: 'Document Status',
            id: 'DocumentStatuses',
            options: sortFilterList(documentStatuses) || [],
            multiSelect: true,
            placeholder: 'All Statuses',
            filtersItem: filters && filters.DocumentStatuses,
          }]}
          filters={filtersDates}
          resetFilters={this.resetFilters}
          applyFilters={this.applyFilters}
        />
        <List
          columnsToDisplay={columnsToDisplay}
          items={reports.finance}
          hideDots
          transition={this.buildCorrectPath}
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          dropdownList={{
            columnsToDisplay: innerColumnsToDisplaySOWs,
            items: reports.finance,
            property: 'ContractsProjectedRevenue',
            getDeeper: true,
            hideDots: true,
            dontShowOptions: true,
            withoutSort: true,
          }}
          fixedHeader
          dontShowOptions
          viewFinancialInfo={HasFinancialAccess}
          config={listConfig}
          isProjectedRevenueList
          // eslint-disable-next-line react/no-unstable-nested-components
          headerWrapper={(props) => (
            <div style={{ overflow: 'hidden' }} ref={this.headerWrapperRef}>{props.children}</div>
          )}
          // eslint-disable-next-line react/no-unstable-nested-components
          contentWrapper={(props) => (
            <Scrollbars
              style={{ height: 'calc(100% - 50px)', flex: 1 }}
              renderTrackHorizontal={this.renderTrackHorizontal}
              onUpdate={this.handleScrollbarsUpdate}
            >
              {props.children}
            </Scrollbars>
          )}
        />
      </div>
    );
  }
}

ProjectedRevenueReports.propTypes = {
  reports: PropTypes.object.isRequired,
  getProjectedRevenueReports: PropTypes.func.isRequired,
  HasFinancialAccess: PropTypes.bool.isRequired,
};

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  columnsToDisplay: store.financeReducer.columnsToDisplay,
  innerColumnsToDisplaySOWs: store.financeReducer.innerColumnsToDisplaySOWs,
  listSettings: store.financeReducer.listSettings,
  filtersDates: store.financeReducer.filtersDates,
  documentStatuses: store.filtersReducer.filters.documentStatuses,
}), {
  getProjectedRevenueReports,
  transitionToContract,
  transitionToRole,
  downloadXLSXDocument,
  changeColumnsToDisplay,
  changeInnerColumnsToDisplay,
  setComponent,
  resetComponent,
  setProjectedRevenueReports,
  setFinanceListSettings,
  resetFinanceListSettings,
  setFinanceListDates,
  resetFinanceListDates,
})(ProjectedRevenueReports);
