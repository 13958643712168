export const validateManagers = (value, allManagers, currentManagers) => {
  let isManagersСonformityOffice = true;
  let checkValidWithoutRequest;
  const choosenManagers = typeof value !== 'undefined' ? value : currentManagers;
  if (choosenManagers) {
    const allManagersId = allManagers.reduce((acc, curValue) => {
      const sumIds = [];
      sumIds.push(curValue.value);
      return [...acc, ...sumIds];
    }, []);
    const isValid = choosenManagers.every(item => allManagersId.includes(item));
    isManagersСonformityOffice = isValid ? !!isValid : false;
    if (value) {
      checkValidWithoutRequest = isManagersСonformityOffice;
    }
  }
  return {
    isValid: isManagersСonformityOffice,
    checkValidWithoutRequest,
  };
};
