import {
  SET_IMPORT_SOW,
  START_IMPORT_LOADER,
  FINISH_IMPORT_LOADER,
  SET_IMPORT_PO,
  SET_IMPORT_AMENDMENT,
  ADD_CONTRACT_TO_PARTIAL_CONTRACT,
  SET_ROLE_TO_PARTIAL_CONTRACT,
  DELETE_IMPORTED_CONTRACT,
  REMOVE_ROLE,
  MOVE_ROLE_BACK_TO_CONTRACT,
  ADD_CONTRACT_CONFIG,
} from '../actions/importContractAction';

const initialState = {
  contract: {},
  partialContract: {
    contract: {},
    roles: [],
  },
  config: [],
  type: '',
  isLoading: false,
};

const importContractReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMPORT_SOW:
      return {
        ...state,
        contract: action.payload,
        type: 'SOW',
      };
    case SET_IMPORT_PO:
      return {
        ...state,
        contract: action.payload,
        type: 'PO',
      };
    case SET_IMPORT_AMENDMENT:
      return {
        ...state,
        contract: action.payload,
        type: 'Amendment',
      };
    case ADD_CONTRACT_TO_PARTIAL_CONTRACT:
      return {
        ...state,
        partialContract: { ...state.partialContract, contract: action.payload },
      };
    case SET_ROLE_TO_PARTIAL_CONTRACT:
      return {
        ...state,
        partialContract: { contract: state.partialContract.contract, roles: [...state.partialContract.roles, action.payload] },
      };
    case REMOVE_ROLE:
      return {
        ...state,
        contract: action.payload,
      };
    case DELETE_IMPORTED_CONTRACT:
      return {
        contract: {},
        partialContract: {
          contract: {},
          roles: [],
        },
        config: [],
        type: '',
        isLoading: false,
      };
    case MOVE_ROLE_BACK_TO_CONTRACT:
      return {
        ...state,
        contract: {
          ...state.contract,
          Roles: action.payload.importRoles,
        },
        partialContract: {
          ...state.partialContract,
          roles: action.payload.partialRoles,
        },
      };
    case START_IMPORT_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case FINISH_IMPORT_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_CONTRACT_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
};

export default importContractReducer;
