import * as React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './progress-bar.css';

class ProgressBarComponent extends React.Component {

  render() {
    const { stepNames, selectedStep } = this.props;

    // const borderWidth = document.getElementsByClassName('progress-bar')[0] ?
    //   document.getElementsByClassName('progress-bar')[0].clientWidth / (stepNames.length - 1) : 0;

    return (
      <div className="progress-bar">
        {
          stepNames.map((stepName, i) => {
            return (
              <div key={i} className="start-point" style={i === stepNames.length - 1 ? { 'flexGrow': '0' } : {}}>
                {
                  i < stepNames.length - 1 ? (
                    <div className={classNames('progress-bar-border', { 'active': selectedStep >= i + 1 })} />
                  ) : null
                }
                <div className={classNames('progress-bar-circle', { 'active': selectedStep >= i })} />
                <div className={'progress-bar-label'} >
                  <span className={classNames({ 'not-first': i > 0 })}>
                    {`${i + 1}.${stepName}`}
                  </span>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

ProgressBarComponent.propTypes = {
  stepNames: PropTypes.array,
  selectedStep: PropTypes.number
};

export default ProgressBarComponent;
