export const getAllCheckedRolesAndEmployees = (roles) => {
  const checkedAllRolesAndEmployees = roles.reduce((acc, role) => {
    const checkedAllEmployees = role.Employees.reduce((employees, employee) => {
      employees.push({ ...employee, checked: true });
      return employees;
    }, []);
    acc.push({
      ...role,
      checked: true,
      Employees: [...checkedAllEmployees],
    });
    return acc;
  }, []);
  return checkedAllRolesAndEmployees;
};
