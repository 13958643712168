import { createBrowserHistory } from 'history';

const replaceHistoryLocationState = (newStateFilters, newStateSearch, newSortObj, scrollTo, amountEntities, childState) => {
  const historyNew = createBrowserHistory();
  const state = { ...historyNew.location.state };
  const { column, sortDirection } = newSortObj || {};
  delete state.filters;
  delete state.search;
  delete state.sortSetting;
  delete state.scrollTo;
  delete state.amountEntities;
  delete state.childState;
  historyNew.replace({
    ...historyNew.location,
    state: {
      filters: newStateFilters || {},
      search: newStateSearch || '',
      sortSetting: newSortObj || {
        column: column || 'Name',
        sortDirection: sortDirection || 'Up',
      },
      scrollTo: scrollTo || '',
      amountEntities: amountEntities || 25,
      childState: childState || {},
    },
  });
};

export const replaseHistiryhStateSearc = (newStateSearch, newSortObj, scrollTo, childState) => {
  const historyNew = createBrowserHistory();
  if (historyNew.location.state) {
    const state = { ...historyNew.location.state };
    delete state.search;
    delete state.scrollTo;
    delete state.childState;
    historyNew.replace({
      ...historyNew.location,
      state: {
        search: newStateSearch,
        sortSetting: newSortObj,
        scrollTo,
        childState,
      },
    });
  } else {
    historyNew.replace({
      ...historyNew.location,
      state: {
        search: '',
        scrollTo: '',
        sortSetting: {
          column: 'Name',
          sortDirection: 'Up',
        },
      },
    });
  }
};

export default replaceHistoryLocationState;
