import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { editContactInfo } from '../../actions/clientsActions';
import history from 'utils/history';
import isObjectNotEquals from 'utils/object-comparison';
import ClientContactsForm from 'common/components/redux-form/create-client-form/client-contacts-form';

export class EditContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        FirstName: '',
        LastName: '',
        JobTitle: '',
        Email: '',
        Cell: '',
        Skype: '',
      },
    };
  }

  componentDidMount() {
    this.getContactEditData();
  }

  componentDidUpdate(prevProps) {
    const { contacts } = this.props;
    if (isObjectNotEquals(prevProps.contacts, contacts)) {
      this.getContactEditData();
    }
  }

  getContactEditData = () => {
    const { contacts, location } = this.props;
    if (typeof contacts !== 'undefined') {
      const currentContact = contacts.find(contact => contact.Id === location.pathname.split('/')[4]);
      this.setState({
        contact: currentContact,
      });
    } else {
      this.onCancel();
    }
  }

  onCancel = () => {
    const { location } = this.props;
    history.push({ pathname: `/clients/${location.pathname.split('/')[2]}`, state: location.state });
  }

  onSave = (data) => {
    const { location, editContactInfo } = this.props;
    const contractObj = {
      contact: {
        FirstName: data.FirstName.trim(),
        LastName: data.LastName.trim(),
        Cell: data.Cell && data.Cell.trim(),
        Email: data.Email && data.Email.trim(),
        JobTitle: data.JobTitle && data.JobTitle.trim(),
        Skype: data.Skype && data.Skype.trim(),
      },
      clientId: location.pathname.split('/')[2],
      contactId: data.Id,
    };
    editContactInfo(contractObj);
    history.push({ pathname: `/clients/${location.pathname.split('/')[2]}`, state: location.state });
  }

  render() {
    const { contact } = this.state;
    const { ManageClientContact } = this.props;
    return (
      <div className='page-cont'>
        <div className='page-header'>
          <span>EDIT CONTACTS</span>
        </div>
        <ClientContactsForm
          data={contact}
          onSubmit={this.onSave}
          onCancel={this.onCancel}
          isHaveRights={ManageClientContact}
        />
      </div>
    );
  }
}

EditContacts.propTypes = {
  contacts: PropTypes.array,
  editContactInfo: PropTypes.func,
  ManageClientContact: PropTypes.bool,
};

export default connect(
  (store) => ({
    contacts: store.clientsReducer.clientInfo.Contacts,
  }),
  {
    editContactInfo,
  },
)(EditContacts);
