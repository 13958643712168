import {
  SET_BENCH_LIST_SETTINGS,
  RESET_BENCH_LIST_SETTINGS,
} from '../actions/benchReportsActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
  filters: {},
};

const initialState = {
  listSettings: defaultListSettings,
};

const benchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BENCH_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_BENCH_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    default:
      return state;
  }
};

export default benchReducer;
