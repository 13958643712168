import { DELETE_MANAGE_KIND, UPDATE_MANAGE_KIND } from '../reducers/manageUserConfirmationReducer';

export const SHOW_MANAGE_USER_CONFIRMATION = '@manage-user-confirmation/show';
export const CLOSE_MANAGE_USER_CONFIRMATION = '@manage-user-confirmation/close';
export const REQUEST_CONFLICTING_USER_MANAGERIAL_INFO = '@edit-user-confirmation/request-conflicting-managerial-info';
export const REQUEST_CONFLICTING_USER_MANAGERIAL_INFO_SUCCESS = '@edit-user-confirmation/request-conflicting-managerial-info-success';
export const CONFIRM_USER_CHANGES = '@manage-user-confirmation/confirm';

export const showUpdateUserConfirmation = (userId, updatedPermissions, updatedSecurityRoleIds, updatedOfficeIds) => ({
  type: SHOW_MANAGE_USER_CONFIRMATION,
  payload: {
    userId,
    manageKind: UPDATE_MANAGE_KIND,
    updatedPermissions,
    updatedSecurityRoleIds,
    updatedOfficeIds,
  },
});

export const showDeleteUserConfirmation = (userId) => ({
  type: SHOW_MANAGE_USER_CONFIRMATION,
  payload: {
    userId,
    manageKind: DELETE_MANAGE_KIND,
  },
});

export const closeManageUserConfirmation = () => ({
  type: CLOSE_MANAGE_USER_CONFIRMATION,
});

export const requestConflictingUserManagerialInfo = (user) => ({
  type: REQUEST_CONFLICTING_USER_MANAGERIAL_INFO,
  payload: user,
});

export const requestConflictingUserManagerialInfoSuccess = (conflictingManagerialInfo) => ({
  type: REQUEST_CONFLICTING_USER_MANAGERIAL_INFO_SUCCESS,
  payload: conflictingManagerialInfo,
});

export const confirmUserChanges = () => ({
  type: CONFIRM_USER_CHANGES,
});
