import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from 'utils/history';
import { List } from 'common/components/list/list';
import Select from 'common/components/form-items/select/select';
import { filterChoosenExtendRoles } from 'services/filterChoosenExtendRoles';
import { transferRoles } from '../../actions/projectsActions';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import './project-extend-role.css';
import CustomIcon from '../../../../common/components/icon/Icon';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellRate } from 'common/components/list/custom-cell/cell-rate';
import moment from 'moment';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { updateCheckedFlagOnRoles } from 'pages/projects/components/utils/updateCheckedFlagOnRoles';

export class ExtendsRole extends Component {
  constructor(props) {
    super(props);
    const { extendedRoles } = this.props;
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Role Name', Id: 'RoleName', className: 'without-width-col',
        },
        {
          Name: 'Role Type', Id: 'Role', isObject: true,
        },
        {
          Name: 'Assignment', Id: 'Assignment',
        },
        {
          Name: 'Ass. Type', Id: 'AssignmentType', isObject: true,
        },
        {
          Name: 'Seniority Level', Id: 'SeniorityLevel', isObject: true,
        },
        {
          Name: 'Billable Status', Id: 'BillableStatus', isObject: true,
        },
        {
          Name: 'Rate', Id: 'Rate', isBudget: true,
        },
        {
          Name: 'SOW/PO Number', Id: 'SowPo',
        },
        {
          Name: 'Start Date', Id: 'StartDate', isDate: true,
        },
        {
          Name: 'End Date', Id: 'EndDate', isDate: true,
        },
        {
          Name: 'Status', Id: 'Status', isObject: true,
        },
      ],
      deepColumn: [
        {
          Name: 'Name', Id: 'Name', className: 'without-width-col', hasBadge: true,
        },
        {
          Name: 'Assignment', Id: 'Assignment',
        },
        {
          Name: 'StartDate', Id: 'StartDate', isDate: true,
        },
        {
          Name: 'EndDate', Id: 'EndDate', isDate: true,
        },
        {
          Name: 'Status', Id: 'Status', isObject: true,
        },
      ],
      contractsLists: [],
      newContract: null,
      endDate: null,
      startDate: null,
      extendedRoles,
    };
  }

  componentDidMount() {
    const { contracts, location, extendedRoles } = this.props;
    this.setState({
      contractsLists: this.getCorrectContractList(contracts),
    });
    !extendedRoles.length && history.goBack({ state: location.state });
  }

  handleChangeStartDate = (date) => {
    const { newContract, contractsLists } = this.state;
    const {
      projectDetails: { StartDate }, showAlertDialog, billingCodesStartDate, billingCodes,
    } = this.props;
    const selectedContract = contractsLists.find(contract => contract.Id === newContract);
    const billingIsSelected = !!newContract;
    const billingCodeStartDate = billingIsSelected && billingCodesStartDate[billingCodes.indexOf(selectedContract.Name)];

    if (!billingIsSelected && moment(StartDate).isAfter(date)) {
      return showAlertDialog('Does the date less than the Project start date?');
    }
    if (billingIsSelected && moment(billingCodeStartDate).isAfter(date)) {
      return showAlertDialog('Does the date less than the SOW/PO start date?');
    }

    this.setState({
      startDate: date,
    });
  }

  handleChangeEndDate = (date) => {
    const { newContract, contractsLists } = this.state;
    const {
      projectDetails: { EndDate }, showAlertDialog, billingCodesEndDate, billingCodes,
    } = this.props;
    const selectedContract = contractsLists.find(contract => contract.Id === newContract);
    const billingIsSelected = !!newContract;
    const billingCodeEndDate = billingIsSelected && billingCodesEndDate[billingCodes.indexOf(selectedContract.Name)];

    if (!billingIsSelected && moment(date).isAfter(EndDate)) {
      return showAlertDialog('Does the date exceed the Project end date?');
    }
    if (billingIsSelected && billingCodeEndDate && moment(date).isAfter(billingCodeEndDate, 'day')) {
      return showAlertDialog('Does the date exceed the SOW/PO end date?');
    }
    if (billingIsSelected && !billingCodeEndDate && moment(date).isAfter(EndDate)) {
      return showAlertDialog('Does the date exceed the Project end date? (SOW/PO doesn`t have end date)');
    }

    this.setState({
      endDate: date,
    });
  };

  getContractNestedItemsNames = (contract, key) => {
    if (contract[key].length) {
      return contract[key].reduce((acc, item) => {
        if (item.Status.Name !== 'Expired') {
          const po = this.getListItem(item);
          acc.push(po);
        }
        return acc;
      }, []);
    }
    return [];
  }

  getCorrectContractList = (contracts) => {
    const { extendedRoles } = this.props;
    const contractsList = [];
    contracts.forEach((item) => {
      if (item.Status.Name !== 'Expired') {
        const sow = this.getListItem(item);
        contractsList.push(sow);
        const POs = this.getContractNestedItemsNames(item, 'POs');
        if (POs.length) {
          contractsList.push(...POs);
        }
        const amedments = this.getContractNestedItemsNames(item, 'Amendments');
        if (amedments.length) {
          contractsList.push(...amedments);
        }
      }
    });
    const currentContract = extendedRoles.length ? extendedRoles[0].SowPo : null;
    const newContracts = contractsList.filter(item => item.Name !== currentContract);
    return newContracts;
  };

  getListItem = (contract) => {
    const listItem = {
      Id: contract.Id,
      Name: contract.Contract,
    };
    return listItem;
  };

  checkRole = (e, parent) => {
    const { extendedRoles } = this.state;
    let roles = extendedRoles;
    roles = filterChoosenExtendRoles(roles, e.target.value, parent, e.target.checked);
    this.setState({
      extendedRoles: roles,
    });
  };

  onChangeCheckecRole = (idRole, parentId) => {
    this.setState((state) => {
      const roles = updateCheckedFlagOnRoles(state.extendedRoles, idRole, parentId, true);
      return {
        extendedRoles: roles,
      };
    });
  }

  handleChangeContract = (e) => {
    const { contracts } = this.props;
    let defaultEndDate;
    let defaultStartDate;
    contracts.forEach((item) => {
      if (item.Id === e.target.value) {
        defaultEndDate = item.EndDate;
        defaultStartDate = item.StartDate;
      }
      item.POs.forEach((item) => {
        if (item.Id === e.target.value) {
          defaultEndDate = item.EndDate;
          defaultStartDate = item.StartDate;
        }
      });

      if (item.Amendments) {
        const amendment = item.Amendments.find((amendment) => amendment.Id === e.target.value);
        if (amendment) {
          defaultEndDate = amendment.EndDate;
          defaultStartDate = amendment.StartDate;
        }
      }
    });

    this.setState({
      newContract: e.target.value,
      endDate: defaultEndDate,
      startDate: defaultStartDate,
    });
  };

  pullOnServer = () => {
    const {
      extendedRoles, newContract, endDate, startDate,
    } = this.state;
    const { projectId, transfer, location } = this.props;
    if (newContract && endDate) {
      const roles = extendedRoles;
      const parentFilter = roles.filter(item => item.checked === true);
      const childFilter = parentFilter.filter(item => item.Employees = item.Employees.filter(child => child.checked === true));
      const transferRoles = [];
      childFilter.forEach((item) => {
        const role = {
          RoleId: item.Id,
        };
        const AssignmentsIds = [];
        if (item.Employees.length) {
          item.Employees.forEach((shadow) => {
            AssignmentsIds.push(shadow.Id);
          });
        }
        role.AssignmentsIds = AssignmentsIds;
        transferRoles.push(role);
      });
      transfer(transferRoles, newContract, projectId, endDate, startDate);
      history.goBack({ state: location.state });
    }
  };

  render() {
    const {
      extendedRoles, columnsToDisplay, deepColumn,
      contractsLists, newContract,
      endDate, startDate,
    } = this.state;
    const { location, HasFinancialAccess } = this.props;
    const isDisabled = true;
    return (
      <Scrollbars
        autoHideDuration={2000}
        autoHideTimeout={300}
        hideTracksWhenNotNeeded
      >
        <div className='extend-roles-cross' onClick={() => history.goBack({ state: location.state })}>
          <CustomIcon iconName='cross' style={{ display: 'flex', padding: '0 0 0 5px' }} />
        </div>
        <div className='extend-roles-body'>
          <h1>EXTEND ROLES</h1>
          <div className='extend-role-select'>
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              clearClassName='svg_select_center'
              optionsClassName='option'
              label='Current Contract'
              placeholder={extendedRoles.length ? extendedRoles[0].SowPo : null}
              selectOptions={contractsLists}
              disabled
              isRequired
              showClear={false}
              inputProps={{
                name: '',
                id: '',
              }}
              name=''
              isOptionObject={false}
            />
          </div>
          <div className='extend-role-select'>
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              clearClassName='svg_select_center'
              optionsClassName='option'
              label='New Contract'
              value={newContract}
              placeholder='Choose New Contract'
              onChange={this.handleChangeContract}
              selectOptions={contractsLists}
              isRequired
              showClear={false}
              inputProps={{
                name: '',
                id: '',
              }}
              name=''
              isOptionObject
            />
          </div>
          <div className='extend-role-select'>
            <DatePicker
              formClassName='step__form extend-datepicker svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              placeholder='MM/DD/YY'
              label='Role Start Date'
              value={startDate}
              onChange={this.handleChangeStartDate}
              isRequired
            />
            <DatePicker
              formClassName='step__form extend-datepicker svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              placeholder='MM/DD/YY'
              label='Role End Date'
              value={endDate}
              onChange={this.handleChangeEndDate}
              isRequired
            />
          </div>
          <List
            columnsToDisplay={columnsToDisplay}
            items={extendedRoles}
            dropdownList={{
              columnsToDisplay: deepColumn,
              items: extendedRoles,
              property: 'Employees',
              getDeeper: true,
              showCheckboxes: true,
              dontShowOptions: true,
              config: {
                'Status': {
                  cell: CustomStatusCell,
                },
              },
            }}
            hideDots
            dontShowOptions
            onClickCheckbox={this.onChangeCheckecRole}
            showCheckboxes
            defaultChecked
            defaultOpenDropDown
            viewFinancialInfo={HasFinancialAccess}
            config={{
              'Status': { cell: CustomStatusCell },
              'Rate': { cell: CellRate },
            }}
          />
        </div>
        <div className='extend-roles-buttons'>
          <div className='buttons buttons_bottom extend-buttons'>
            <div />
            <div className='right-side-buttons'>
              <div className='button cancel' onClick={() => history.goBack({ state: location.state })}>
                <span>Cancel</span>
              </div>
              <div
                onClick={this.pullOnServer}
                className={classNames('button next', { 'btn-disabled': endDate && newContract ? !isDisabled : isDisabled })}
              >
                <span>Apply</span>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

const mapStateToProps = state => ({
  extendedRoles: state.projectsReducer.extendedRoles,
  contracts: state.projectsReducer.projectDetails ? state.projectsReducer.projectDetails.Contracts : [],
  projectId: state.projectsReducer.projectDetails.Id,
  projectDetails: state.projectsReducer.projectDetails,
  billingCodes: state.roleModalReducer.billingCodes,
  billingCodesStartDate: state.roleModalReducer.billingCodesStartDate,
  billingCodesEndDate: state.roleModalReducer.billingCodesEndDate,
});

const mapDispatchToProps = dispatch => ({
  transfer: (roles, id, projectId, endDate, startDate) => {
    dispatch(transferRoles(roles, id, projectId, endDate, startDate));
  },
  showAlertDialog: (message) => dispatch(showAlertDialog(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtendsRole);

ExtendsRole.propTypes = {
  extendedRoles: PropTypes.array.isRequired,
  transfer: PropTypes.func.isRequired,
};
