const compareArraysByItems = (firstArr, secondArr) => {
  if (firstArr.length !== secondArr.length) {
    return false;
  }

  const checkCompare = {};

  firstArr.forEach((item) => {
    const key = (typeof item) + item;
    if (!checkCompare[key]) {
      checkCompare[key] = 0;
    }
    checkCompare[key] += 1;
  });

  return secondArr.every((item) => {
    const key = (typeof item) + item;
    if (checkCompare[key]) {
      checkCompare[key] -= 1;
      return true;
    }
    return false;
  });
};

export default compareArraysByItems;
