import * as React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from '../../../../utils/applicationTitle';
import { setComponent, resetComponent } from '../../../../common/actions/headerActions';
import ListToolbar from '../../../../common/components/list-toolbar/list-toolbar';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { getFilters } from 'common/actions/filtersActions';
import { sortFilterList } from '../../../../common/components/list/sortList';
import Scrollbars from 'react-custom-scrollbars';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from '../../../../services/debounce';
import { List } from '../../../../common/components/list/list';
import moment from 'moment/moment';
import isEmptyObject from '../../../../utils/isEmptyObject';
import { emptyArray, STATUS_BILLABLE_ID } from '../../../../utils/const-variable';
import {
  getClientRatesReports,
  changeColumnsToDisplay,
  setClientRatesListSettings,
  setClientRatesFiltersValue,
  setClientRatesReports,
  resetClientRatesListSettings,
} from '../../actions/clientRatesActions';
import { initialState } from '../../reducers/clientRatesReducer';
import isObjectNotEquals from '../../../../utils/object-comparison';
import { CellBudget } from '../../../../common/components/list/custom-cell/cell-budget';

const filterNames = [
  'Offices',
  'Locations',
  'Positions',
  'RoleSeniorities',
  'BillableProjects',
  'LatestContractDate',
  'BillableStatuses',
];

export class ClientRates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMoreReports: true,
      listConfig: {
        'Total': { cell: CellBudget, allowEmpty: true, isFixed: true },
      },
    };
  }

  componentDidMount() {
    const {
      setComponent, getFilters, listSettings: {
        searchValue, sortColumn, sortDirection, takeAmountReports,
      },
      getClientRatesReports,
      setClientRatesFiltersValue,
    } = this.props;

    getFilters(filterNames);
    const defaultFilters = this.setDefaultFilters();
    getClientRatesReports(defaultFilters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    setClientRatesFiltersValue({
      StartDate: defaultFilters.StartDate,
      EndDate: defaultFilters.EndDate,
    });
    setComponent({ title: `Client Rates Report - ${defaultFilters.StartDate} - ${defaultFilters.EndDate}` });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      getClientRatesReports, listSettings, setComponent,
    } = this.props;
    const {
      filters, searchValue, sortColumn, sortDirection, takeAmountReports,
    } = listSettings;
    if (prevProps.listSettings !== initialState.listSettings && isObjectNotEquals(prevProps.listSettings, listSettings)) {
      getClientRatesReports(filters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    }
    setComponent({ title: `Client Rates Report - ${filters.StartDate} - ${filters.EndDate}` });
  }

  componentWillUnmount() {
    const { setClientRatesReports, resetComponent, resetClientRatesListSettings } = this.props;
    setClientRatesReports(emptyArray);
    resetComponent();
    resetClientRatesListSettings();
  }

  setDefaultFilters = () => {
    const {
      listSettings: { filters }, setClientRatesListSettings,
    } = this.props;
    const currentFilters = {
      ...filters,
      BillableStatuses: filters.BillableStatuses || [STATUS_BILLABLE_ID],
      Offices: filters.Offices || [],
      StartDate: filters.StartDate ? filters.StartDate : moment().startOf('month').format('L'),
      EndDate: filters.EndDate ? filters.EndDate : moment().format('L'),
      IncludeFinishedProjects: filters.IncludeFinishedProjects || true,
    };

    setClientRatesListSettings({ filters: currentFilters });

    return currentFilters;
  };

  applyFilters = (filters) => {
    const { setClientRatesListSettings, textDataForFilters } = this.props;
    const newFilters = {
      ...filters,
      ...textDataForFilters,
    };
    const isExistsFilters = Object.keys(newFilters).some(filter => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setClientRatesListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  };

  resetFilters = () => {
    const { setClientRatesListSettings, setClientRatesFiltersValue, listSettings: { filters } } = this.props;

    const newFilters = {
      StartDate: moment().startOf('month').format('L'),
      EndDate: moment().format('L'),
      IncludeFinishedProjects: filters.IncludeFinishedProjects,
    };

    setClientRatesListSettings({ filters: { ...newFilters, BillableStatuses: [STATUS_BILLABLE_ID] } });
    setClientRatesFiltersValue({ ...newFilters });
  };

  searchClientRatesReports = (searchValue) => {
    const { setClientRatesListSettings } = this.props;
    setClientRatesListSettings({ searchValue });
  };

  handleChangeStartDate = (date) => {
    const { setClientRatesFiltersValue } = this.props;
    setClientRatesFiltersValue({ StartDate: date ? moment(date).format('MM/DD/YYYY') : null });
  };

  handleChangeEndDate = (date) => {
    const { setClientRatesFiltersValue } = this.props;
    setClientRatesFiltersValue({ EndDate: date ? moment(date).format('MM/DD/YYYY') : null });
  };

  changeReportsAmount = (page) => {
    const {
      isLoading,
      listSettings: {
        takeAmountReports,
      },
      setClientRatesListSettings,
    } = this.props;
    const magnificationFactor = 15;
    const increaseParams = takeAmountReports + page * magnificationFactor;
    if (!isLoading) {
      this.checkIfNeedMoreReports(increaseParams);
      setClientRatesListSettings({ takeAmountReports: increaseParams });
    }
  };

  checkIfNeedMoreReports = (takeAmountReports) => {
    const { totalCount } = this.props;
    this.setState({
      hasMoreReports: takeAmountReports <= totalCount,
    });
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setClientRatesListSettings } = this.props;
    setClientRatesListSettings({
      sortColumn,
      sortDirection,
    });
  };

  getRequiredColumns = () => {
    const { columnsToDisplay } = this.props;
    const requiredColumns = [];

    for (const column of columnsToDisplay) {
      if (column.isSelected) {
        requiredColumns.push(column.Name.replace(/ /g, ''));
      }
    }

    return requiredColumns;
  }

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    const requestFilters = { ...filters };
    requestFilters.requiredColumns = this.getRequiredColumns();

    downloadXLSXDocument(
      requestFilters,
      searchValue,
      'client-rates-reports/export-xlsx?SearchValue=',
      `Client Rates Report_${filters.StartDate}_${filters.EndDate}.xlsx`,
    );
  };

  handleToggleForFinishedProjects = () => {
    const { listSettings: { filters }, setClientRatesListSettings } = this.props;

    const newFilters = {
      ...filters,
      IncludeFinishedProjects: !filters.IncludeFinishedProjects,
    };

    setClientRatesListSettings({ filters: newFilters });
  }

  render() {
    const {
      columnsToDisplay, changeColumnsToDisplay, textDataForFilters,
      offices, projects, reports, listSettings: {
        sortColumn, sortDirection, filters, searchValue,
      }, roleTypes, roleSeniorities, HasFinancialAccess, latestContractDate, billableStatuses, locations,
    } = this.props;
    const { hasMoreReports, listConfig } = this.state;

    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          showExportButton
          searchValue={searchValue}
          exportDocument={this.downloadXLSXDocument}
          searchPlaceholder='Search Reports'
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchClientRatesReports}
          columnsToDisplay={columnsToDisplay}
          changeColumnsToDisplay={changeColumnsToDisplay}
          showToggleForFinishedProjects
          handleToggleForFinishedProjects={this.handleToggleForFinishedProjects}
          items={[{
            name: 'Report Start Date',
            id: 'StartDate',
            datepicker: true,
            placeholder: 'Start Date',
            formClassName: 'step__form svg-calendar-add-info date_picker-toolbar',
            datePickerClassName: 'step__date-picker data-pisker__filter--margin-sow',
            value: textDataForFilters.StartDate,
            onChange: this.handleChangeStartDate,
          }, {
            name: 'Report End Date',
            id: 'EndDate',
            datepicker: true,
            placeholder: 'End Date',
            formClassName: 'step__form svg-calendar-add-info date_picker-toolbar',
            datePickerClassName: 'step__date-picker data-pisker__filter--margin-sow',
            value: textDataForFilters.EndDate,
            maxVal: latestContractDate && latestContractDate[0],
            onChange: this.handleChangeEndDate,
          }, {
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
          }, {
            name: 'Locations',
            id: 'Locations',
            options: sortFilterList(locations) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Locations',
            filtersItem: filters && filters.Locations,
          }, {
            name: 'Projects',
            id: 'Projects',
            options: sortFilterList(projects) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Projects',
            filtersItem: filters && filters.Projects,
          }, {
            name: 'Role Types',
            id: 'RoleTypes',
            options: sortFilterList(roleTypes) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Role Types',
            filtersItem: filters && filters.RoleTypes,
          }, {
            name: 'Billable Statuses',
            id: 'BillableStatuses',
            options: sortFilterList(billableStatuses) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Billable Statuses',
            filtersItem: filters && filters.BillableStatuses,
          }, {
            name: 'Role Seniority',
            id: 'RoleSeniorities',
            options: sortFilterList(roleSeniorities) || [],
            multiSelect: true,
            placeholder: 'All Role Seniority',
            filtersItem: filters && filters.RoleSeniorities,
          }]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom-scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <InfiniteScroll
              pageStart={1}
              loadMore={debounce(this.changeReportsAmount, 500)}
              hasMore={hasMoreReports}
              useWindow={false}
              initialLoad={false}
              threshold={500}
            >
              <List
                columnsToDisplay={columnsToDisplay}
                items={reports.clientRates}
                hideDots
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                fixedHeader
                dontShowOptions
                isProjectedRevenueList
                viewFinancialInfo={HasFinancialAccess}
                config={listConfig}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  offices: store.filtersReducer.filters.Offices,
  locations: store.filtersReducer.filters.Locations,
  roleTypes: store.filtersReducer.filters.Positions,
  billableStatuses: store.filtersReducer.filters.BillableStatuses,
  roleSeniorities: store.filtersReducer.filters.RoleSeniorities,
  latestContractDate: store.filtersReducer.filters.LatestContractDate,
  listSettings: store.clientRatesReducer.listSettings,
  textDataForFilters: store.clientRatesReducer.textDataForFilters,
  projects: store.filtersReducer.filters.BillableProjects,
  columnsToDisplay: store.clientRatesReducer.columnsToDisplay,
  totalCount: store.reportsReducer.clientRatesTotalCount,
}), {
  getClientRatesReports,
  getFilters,
  downloadXLSXDocument,
  setComponent,
  resetComponent,
  changeColumnsToDisplay,
  setClientRatesListSettings,
  setClientRatesFiltersValue,
  setClientRatesReports,
  resetClientRatesListSettings,
})(ClientRates);
