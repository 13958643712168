import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Month from './components/month';
import VacationTag from './components/vacation-tag';

import Select from 'common/components/form-items/select/select';
import {
  getVacations,
  showAddVacationModal,
  setSelectedYear,
} from '../../../actions/membersActions';

import './vacations.css';

export class Vacations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear() || '',
      years: [new Date().getFullYear()],
      employeeId: '',
      vacations: [],
    };
  }

  componentDidMount() {
    const { yearsToSelect, setSelectedYear } = this.props;
    const { year } = this.state;
    const years = [];

    for (let i = 0; i < yearsToSelect; i++) {
      years.push(year + i);
    }
    for (let i = 1; i < yearsToSelect; i++) {
      years.push(year - i);
    }
    this.setState({ years: years.sort() });
    setSelectedYear(year);
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    const { employeeId, year } = prevState;

    if (prevProps.employeeId && prevProps.employeeId !== employeeId) {
      prevProps.getVacations(prevProps.employeeId, year);
    }

    return {
      employeeId: prevProps.employeeId,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      employeeId,
      vacations,
      selectedYear,
      getVacations,
    } = this.props;

    if (prevProps.employeeId !== employeeId) {
      this.setState({
        employeeId,
      });
    }

    if (prevProps.vacations !== vacations) {
      this.setState({
        vacations,
      });
    }

    if (prevProps.selectedYear !== selectedYear) {
      getVacations(employeeId, selectedYear);
    }
  }

  getNuberOfDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  }

  getFirstDayInMonth = (year, month) => {
    return new Date(year, month, 0).getDay();
  }

  parseDate = (date) => {
    return {
      day: new Date(date).getDate(),
      month: new Date(date).getMonth(),
      year: new Date(date).getFullYear(),
      weekDay: new Date(date).getDay(),
    };
  }

  handleChangeYear = ({ target }) => {
    const { setSelectedYear } = this.props;

    this.setState({
      year: target.value,
    });

    setSelectedYear(target.value);
  }

  showModal = () => {
    const { showAddVacationModal } = this.props;

    showAddVacationModal();
  }

  renderTags = (vacations) => {
    return vacations && vacations.map((vacation, i) => {
      const { isEditable } = this.props;

      vacation.startDate = moment(vacation.StartDate).format('MM/DD/YYYY');
      vacation.endDate = moment(vacation.EndDate).format('MM/DD/YYYY');

      return (
        <VacationTag
          key={`period-${i}`}
          vacation={vacation}
          isEditable={isEditable}
        />
      );
    });
  }

  render() {
    const { isEditable } = this.props;
    const { year, years, vacations } = this.state;
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const periodsForMonth = [];

    return (
      <div className='vacation-container'>
        <div className='vacation-toolbar'>
          <div className='vacation-year'>
            <Select
              formClassName='vacation-year-selector'
              value={year}
              selectOptions={years}
              inputProps={{
                name: 'vacationYear',
                id: 'vacationYear',
              }}
              showClear={false}
              onChange={this.handleChangeYear}
            />
          </div>
          {
            isEditable && (
              <div
                className='toolbar-button'
                onClick={isEditable && this.showModal}
              >
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='#F26939' />
                </svg>
                <span>Add New</span>
              </div>
            )
          }
        </div>
        <div className='vacation-periods'>
          {
            this.renderTags(vacations)
          }
        </div>
        <div className='vacation-calendar'>
          {
            months.map((month, i) => {
              vacations && vacations.forEach(vacation => {
                (this.parseDate(vacation.startDate).year === year || this.parseDate(vacation.endDate).year === year)
                  && (this.parseDate(vacation.startDate).month === i || this.parseDate(vacation.endDate).month === i)
                  && periodsForMonth.push(vacation);
              });

              return (
                <Month
                  key={month}
                  dateInfo={{
                    daysNumber: this.getNuberOfDaysInMonth(year, i + 1),
                    firstDay: this.getFirstDayInMonth(year, i),
                    monthNumber: i,
                    year,
                    title: month,
                    periods: periodsForMonth,
                    USAWeekFormat: true,
                    markWeekends: true,
                  }}
                  parseDate={this.parseDate}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}

Vacations.propTypes = {
  vacations: PropTypes.array,
  getVacations: PropTypes.func,
  showAddVacationModal: PropTypes.func,
  employeeId: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default connect(store => ({
  vacations: store.membersReducer.vacations,
  selectedYear: store.membersReducer.selectedYear,
}), {
  getVacations,
  showAddVacationModal,
  setSelectedYear,
})(Vacations);
