import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../../icon/Icon';

export class CellBillingCode extends Component {
  render() {
    const { item, column, config } = this.props;
    const contractTimeLeft = config.SowPo.contractsId;

    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col', 'sow-po-col-with-warn')}>
        <div className='column_custom-cell'>
          <span
            className={classNames('col-span cell_column-orient', { 'col-span_custom-cell': contractTimeLeft.includes(item.SowPoId) })}
            title={item.SowPo}
          >
            {item.SowPo}
          </span>
          {contractTimeLeft.includes(item.SowPoId) ? (
            <div>
              <CustomIcon iconName='red-clock' className='custom-cell_clock' />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CellBillingCode.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};
