import React, { Component } from 'react';
import CustomIcon from '../icon/Icon';
import './preloader.css';

export class Preloader extends Component {
  render() {
    return (
      <div className='loader-wrapper'>
        <div className='preloader'>
          <div className='loader-theodolite'>
            <div className='loader-circle' />
          </div>
          <CustomIcon iconName='loader' />
        </div>
      </div>
    );
  }
}
