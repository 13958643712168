export const deleteDocumentsId = (documents) => {
  const newDocuments = documents.reduce((acc, item) => {
    delete item.Id;
    acc.push(item);
    return acc;
  }, []);
  return newDocuments;
};

export const sortDocumentByAlphabet = (arr) => {
  return arr.sort((currValue, secondValue) => {
    if (currValue.Comment > secondValue.Comment) {
      return 1;
    }
    if (currValue.Comment < secondValue.Comment) {
      return -1;
    }
    return 0;
  });
};

export const getDocumentTypeName = (id, arrayDocements) => {
  return arrayDocements.find(item => item.Id === id).Name;
};

export const getUpdatedDocuments = (docArray, parentId, docType) => {
  return docArray.reduce((acc, item) => {
    item.DocumentType
      ? acc.push({
        Comment: item.Comment,
        DocumentType: { Id: item.DocumentType.Id, Name: item.DocumentType.Name },
        URL: item.URL,
        DocumentTypeId: item.DocumentType.Id,
        [docType]: parentId,
      })
      : acc.push(item);
    return acc;
  }, []);
};

export const getEditedDocuments = (docArray, parentId, docType) => {
  return docArray.reduce((acc, item) => {
    if (item.DocumentType) {
      if (item.AddedDocument) {
        delete item.Id;
      }
      acc.push({
        Id: item.Id ? item.Id : null,
        Comment: item.Comment,
        DocumentType: { Id: item.DocumentType.Id, Name: item.DocumentType.Name },
        URL: item.URL,
        DocumentTypeId: item.DocumentType.Id,
        [docType]: parentId,
      });
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
};
