import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import '../vacations.css';

const
  WEEK_LENGTH = 7, // days count in a week
  USA_LAST_WEEK_DAY = 6, // last day in a week [0, .., 6] - Saturday
  USA_FIRS_WEEK_DAY = 0, // first day in a week [0, .., 6] - Sunday
  EUROPE_FIRST_WEEK_DAY = 1, // first day in a week [0, .., 6] - Monday
  USA_MAX_DAY_CELLS = 41,
  EUROPE_MAX_DAY_CELLS = 42;

export default class Month extends React.Component {
  renderDays = () => {
    const { dateInfo } = this.props;

    let
      fullDays = [],
      emptyCellsBefore = [];
    const
      emptyCellsAfter = [],
      month = [];

    for (let i = dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY; i <= dateInfo.firstDay; i++) { // empty cells before first calendar day
      emptyCellsBefore.push(
        <div className='day-container' key={`${dateInfo.monthNumber}-before-${i}`}>
          <span />
        </div>,
      );
    }

    if (emptyCellsBefore.length === WEEK_LENGTH) {
      emptyCellsBefore = [];
    }

    for (let i = 1; i <= dateInfo.daysNumber; i++) { // calendar days
      month.push(
        <div
          className={classNames('day-container day',
            {
              'weekend': dateInfo.markWeekends && (dateInfo.USAWeekFormat
                ? ((i + emptyCellsBefore.length) % WEEK_LENGTH === USA_FIRS_WEEK_DAY)
                || ((i + emptyCellsBefore.length + USA_LAST_WEEK_DAY) % WEEK_LENGTH === USA_FIRS_WEEK_DAY)
                : ((i + emptyCellsBefore.length) % WEEK_LENGTH === USA_FIRS_WEEK_DAY)
                || ((i + emptyCellsBefore.length + EUROPE_FIRST_WEEK_DAY) % WEEK_LENGTH === USA_FIRS_WEEK_DAY)),
            })
          }
          key={`${dateInfo.monthNumber}-day-${i}`}
        >
          <span>{i}</span>
        </div>,
      );
    }

    for (let i = 1; i <= (dateInfo.USAWeekFormat ? USA_MAX_DAY_CELLS : EUROPE_MAX_DAY_CELLS) - dateInfo.daysNumber - dateInfo.firstDay; i++) { // empty cells after last calendar day
      emptyCellsAfter.push(<div className='day-container' key={`${dateInfo.monthNumber}-after-${i}`}>
        <span />
      </div>);
    }

    this.renderPeriods(month);
    fullDays = emptyCellsBefore.concat(month).concat(emptyCellsAfter);
    return fullDays;
  }

  renderPeriods = (month) => {
    const { parseDate, dateInfo } = this.props;
    dateInfo.periods.length && dateInfo.periods.forEach(period => { // vacation periods
      if (parseDate(period.startDate).month === dateInfo.monthNumber && parseDate(period.endDate).month === dateInfo.monthNumber) { // full period in this month
        this.periodInSingleMonth(period, month);
      } else if (parseDate(period.startDate).month === dateInfo.monthNumber) { // end of period in next month
        this.periodFromStartDate(period, month);
      } else if (parseDate(period.endDate).month === dateInfo.monthNumber) { // start of period in prev month
        this.periodToEndDate(period, month);
      } else if (parseDate(period.startDate).month < dateInfo.monthNumber && parseDate(period.endDate).month > dateInfo.monthNumber) { // start of period in prev month and end of period in next month
        this.fullMonthPeriod(period, month);
      }
    });
  }

  getDateWeekDay = (day, isNeedPardate) => {
    const { parseDate, dateInfo: { monthNumber, year } } = this.props;
    const countDays = isNeedPardate ? parseDate(day).day : day;
    return parseDate(`${monthNumber + 1}/${countDays}/${year}`).weekDay;
  }

  getDateStyles = (dataSetting, period, i) => {
    const { dateInfo } = this.props;
    switch (dataSetting) {
      case 'start-date-style': {
        return [{
          'first-selected-day':
            !(this.getDateWeekDay(period.startDate, true) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY)),
        },
        {
          'single-selected-day':
          this.getDateWeekDay(period.startDate, true) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY),
        }];
      }
      case 'avarage-date-style': {
        return [{
          'first-selected-day':
          this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY) || i === 1,
        },
        {
          'last-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY) && i !== 1,
        },
        {
          'single-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY) && i === 1,
        }];
      }
      case 'end-date-style': {
        return [{
          'last-selected-day':
            !(this.getDateWeekDay(period.endDate, true) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY)),
        },
        {
          'single-selected-day':
            this.getDateWeekDay(period.endDate, true) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY),
        }];
      }
      case 'current-date-to-end-month': {
        return [{
          'first-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY),
        },
        {
          'last-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY) || i === dateInfo.daysNumber,
        },
        {
          'single-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY) && i === dateInfo.daysNumber,
        }];
      }
      case 'beginning-month-to-current-date': {
        return [{
          'last-selected-day':
            !(this.getDateWeekDay(period.endDate, true) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY)),
        },
        {
          'single-selected-day':
            this.getDateWeekDay(period.endDate, true) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY),
        }];
      }
      case 'full-month-style': {
        return [{
          'first-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_FIRS_WEEK_DAY : EUROPE_FIRST_WEEK_DAY) || i === 1,
        },
        {
          'last-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY) || i === dateInfo.daysNumber,
        },
        {
          'single-selected-day':
            this.getDateWeekDay(i, false) === (dateInfo.USAWeekFormat ? USA_LAST_WEEK_DAY : USA_FIRS_WEEK_DAY) && i === 1,
        }];
      }
      default:
        return null;
    }
  }

  periodInSingleMonth = (period, month) => {
    const { parseDate, dateInfo } = this.props;

    if (parseDate(period.startDate).day !== parseDate(period.endDate).day) { // start of period
      month[parseDate(period.startDate).day - 1] = (
        <div
          className={classNames('day-container day selected-day', ...this.getDateStyles('start-date-style', period))}
          key={`${dateInfo.monthNumber}-selected-${parseDate(period.startDate).day}`}
        >
          <span>{parseDate(period.startDate).day}</span>
        </div>
      );
      for (let i = parseDate(period.startDate).day + 1; i < parseDate(period.endDate).day; i++) { // period days
        month[i - 1] = (
          <div
            className={classNames('day-container day selected-day', ...this.getDateStyles('avarage-date-style', period, i))}
            key={`${dateInfo.monthNumber}-selected-${i}`}
          >
            <span>{i}</span>
          </div>
        );
      }
      month[parseDate(period.endDate).day - 1] = ( // end of period
        <div
          className={classNames('day-container day selected-day', ...this.getDateStyles('end-date-style', period))}
          key={`${dateInfo.monthNumber}-selected-${parseDate(period.endDate).day}`}
        >
          <span>{parseDate(period.endDate).day}</span>
        </div>
      );
    } else {
      month[parseDate(period.startDate).day - 1] = ( // single period day
        <div className='day-container day selected-day single-selected-day' key={`${dateInfo.monthNumber}-single-${parseDate(period.startDate).day}`}>
          <span>{parseDate(period.startDate).day}</span>
        </div>
      );
    }
  }

  periodFromStartDate = (period, month) => {
    const { parseDate, dateInfo } = this.props;

    if (parseDate(period.startDate).year === dateInfo.year) {
      month[parseDate(period.startDate).day - 1] = ( // start of period
        <div
          className={classNames('day-container day selected-day', ...this.getDateStyles('start-date-style', period))}
          key={`${dateInfo.monthNumber}-selected-${parseDate(period.startDate).day}`}
        >
          <span>{parseDate(period.startDate).day}</span>
        </div>
      );

      for (let i = parseDate(period.startDate).day + 1; i <= dateInfo.daysNumber; i++) { // period days
        month[i - 1] = (
          <div
            className={classNames('day-container day selected-day', ...this.getDateStyles('current-date-to-end-month', period, i))}
            key={`${dateInfo.monthNumber}-selected-${i}`}
          >
            <span>{i}</span>
          </div>
        );
      }
    }
  }

  periodToEndDate = (period, month) => {
    const { parseDate, dateInfo } = this.props;

    if (parseDate(period.endDate).year === dateInfo.year) {
      for (let i = 1; i < parseDate(period.endDate).day; i++) { // period days
        month[i - 1] = (
          <div
            className={classNames('day-container day selected-day', ...this.getDateStyles('avarage-date-style', period, i))}
            key={`${dateInfo.monthNumber}-selected-${i}`}
          >
            <span>{i}</span>
          </div>
        );
      }
      month[parseDate(period.endDate).day - 1] = ( // end of period
        <div
          className={classNames('day-container day selected-day', ...this.getDateStyles('beginning-month-to-current-date', period))}
          key={`${dateInfo.monthNumber}-selected-${parseDate(period.endDate).day}`}
        >
          <span>{parseDate(period.endDate).day}</span>
        </div>
      );
    }
  }

  fullMonthPeriod = (period, month) => {
    const { dateInfo } = this.props;
    for (let i = 1; i < dateInfo.daysNumber + 1; i++) { // period days
      month[i - 1] = (
        <div
          className={classNames('day-container day selected-day', ...this.getDateStyles('full-month-style', period, i))}
          key={`${dateInfo.monthNumber}-selected-${i}`}
        >
          <span>{i}</span>
        </div>
      );
    }
  }

  render() {
    const { dateInfo } = this.props;
    const weekDays = dateInfo.USAWeekFormat
      ? ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return (
      <div className='month-container'>
        <div className='month-name'>{dateInfo.title}</div>
        <div className='week-days'>
          {
            weekDays.map((day, i) => (
              <div className='day-container' key={`${dateInfo.monthNumber}-week-${i}`}>
                <span>{day[0]}</span>
              </div>
            ))
          }
        </div>
        <div className='weeks-container'>
          {
            this.renderDays()
          }
        </div>
      </div>
    );
  }
}

Month.propTypes = {
  dateInfo: PropTypes.object,
  parseDate: PropTypes.func,
};
