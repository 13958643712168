import React from 'react';
import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import { connect } from 'react-redux';
import { Badge } from 'common/components/badges/badges';
import { getColorFromString } from 'services/getColor';
import isObjectNotEquals from 'utils/object-comparison';

import { changeOfficeBasicInfo } from 'common/actions/reduxFormActions.js';
import { required } from 'services/redux-form-validators.js';
import renderTogglingSearchDropdown from '../components/toggling/toggling-search-dropdown';
import renderTogglingTextField from '../components/toggling/toggling-text-field';

import './office-redux-form.css';

const defaultStyles = {
  container: 'redux-form__field-container standart-container-offices',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

class OfficeBasicInfoForm extends React.Component {
  componentDidUpdate(prevProps) {
    const { data, initialize } = this.props;
    if (isObjectNotEquals(prevProps.data, data)) {
      initialize(data);
    }
  }

  onChange = (data, value, prevValue, field) => {
    const {
      change, allCountries, changeOfficeBasicInfo,
    } = this.props;
    const newValue = (allOptions) => allOptions.find(item => item.Id === value).Name;
    switch (field) {
      case 'CountryId':
        change('CountryName', newValue(allCountries));
        this.onBlur();
        break;
      case 'LDapPaths':
        changeOfficeBasicInfo();
        break;
      default:
        break;
    }
  }

  onBlur = () => {
    const { changeOfficeBasicInfo } = this.props;
    changeOfficeBasicInfo();
  }

  render() {
    const {
      isEditable, Name, DomainName, Address, CountryId, CountryName,
      allCountries, handleSubmit,
    } = this.props;
    return (
      <form className='redux-form' onSubmit={handleSubmit}>
        <div className='redux-form__row'>
          <div className='redux-form__header-inputs redux-form__row--nargin-top'>
            {
              Name ? (
                <Badge
                  item={Name}
                  color={getColorFromString(Name)}
                />
              ) : null
            }
            <Field
              name='Name'
              value={Name}
              component={renderTogglingTextField}
              onChange={this.onChange}
              onBlur={this.onBlur}
              isEditable={isEditable}
              valueToShow={String(Name)}
              autoFocus
              maxlength='100'
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container header-inputs-container',
                fieldContent: 'field__view-content standart-content-padding',
                fieldView: 'field__view-container justify-center',
              }}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
            />
          </div>
        </div>
        <div className='redux-form-offices__row redux-form__row--nargin-top'>
          <Field
            name='DomainName'
            value={DomainName}
            component={renderTogglingTextField}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isEditable={isEditable}
            valueToShow={DomainName}
            validate={required}
            label='Domain name'
            placeholder='Set Domain name'
            autoFocus
            maxlength='100'
            styles={defaultStyles}
            formClassName='change-border__input__project'
            inputClassName='change-name__input'
            showAsterixOnFocus
          />
          <Field
            name='CountryId'
            value={CountryId}
            options={allCountries}
            component={renderTogglingSearchDropdown}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isEditable={isEditable}
            valueToShow={CountryName}
            inputProps={{ id: 'country' }}
            label='Country'
            autoFocus
            isAddingFeature={false}
            styles={defaultStyles}
          />
          <Field
            name='Address'
            value={Address}
            component={renderTogglingTextField}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isEditable={isEditable}
            valueToShow={Address}
            label='Address'
            placeholder='Set Address'
            autoFocus
            maxlength='50'
            styles={defaultStyles}
            formClassName='change-border__input__project'
            inputClassName='change-name__input'
          />
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('OfficeBasicInfoForm', data)),
    destroy: () => dispatch(destroy('OfficeBasicInfoForm')),
    change: (field, value) => dispatch(change('OfficeBasicInfoForm', field, value)),
    changeOfficeBasicInfo: () => dispatch(changeOfficeBasicInfo()),
  };
};

export default connect(state => {
  const isFormReducerExists = state.form.OfficeBasicInfoForm && state.form.OfficeBasicInfoForm.values;
  return {
    Address: isFormReducerExists ? state.form.OfficeBasicInfoForm.values.Address : '',
    CountryId: isFormReducerExists ? state.form.OfficeBasicInfoForm.values.CountryId : '',
    DomainName: isFormReducerExists && state.form.OfficeBasicInfoForm.values.DomainName,
    Name: isFormReducerExists && state.form.OfficeBasicInfoForm.values.Name,
    CountryName: isFormReducerExists && state.form.OfficeBasicInfoForm.values.CountryName,
    LDapPaths: isFormReducerExists && state.form.OfficeBasicInfoForm.values.LDapPaths,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'OfficeBasicInfoForm',
})(OfficeBasicInfoForm));
