import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { FormControl, IconButton, InputAdornment } from '@material-ui/core';
import { DatePicker as MaterialDatePicker } from 'material-ui-pickers';
import EventIcon from '@material-ui/icons/Event';

import './date-picker.css';
import CustomIcon from '../../icon/Icon';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.opendp = React.createRef();
    this.dpWrap = React.createRef();
  }

  componentDidMount() {
    const { autoFocus, isAutoFocusOnInput } = this.props;
    if (isAutoFocusOnInput) {
      return this.dpWrap.current.querySelector('input').focus();
    }
    autoFocus && this.opendp.current.open();
  }

  onDateChange = (date) => {
    const {
      onChange, name, isStartDate, isEndDate,
    } = this.props;
    let normalFormat;
    if (isStartDate) {
      normalFormat = date ? moment(date).set({ 'hour': 8, 'minute': 0 }).format('YYYY-MM-DD HH:mm') : null;
    } else if (isEndDate) {
      normalFormat = date ? moment(date).set({ 'hour': 12, 'minute': 0 }).format('YYYY-MM-DD HH:mm') : null;
    } else {
      normalFormat = date ? moment(date).format('YYYY-MM-DD HH:mm') : null;
    }
    if (name) {
      onChange(normalFormat, name);
      return;
    }

    onChange(normalFormat);
  }

  onYearChange = (date) => {
    const [minDate, maxDate] = this.getDateRange();
    const momentDate = moment(date);

    if (momentDate.isBefore(minDate)) {
      this.onDateChange(minDate);
    } else if (momentDate.isAfter(maxDate)) {
      this.onDateChange(maxDate);
    } else {
      this.onDateChange(momentDate.startOf('year'));
    }
  }

  resetDateValue = () => {
    const { value, setPrevValueOnError } = this.props;
    setPrevValueOnError ? this.onDateChange(value) : this.onDateChange(null);
  }

  getMinDateInvalidMessage = () => {
    const { minErrorMassage, minVal } = this.props;
    return minErrorMassage ? minErrorMassage
      : (minVal ? 'End Date should not be before Start Date' : 'Invalid Date');
  }

  getMaxDateInvalidMessage = () => {
    const { maxErrorMassage, maxVal } = this.props;
    return maxErrorMassage ? maxErrorMassage
      : (maxVal ? 'Start Date should be before End Date' : 'Invalid Date');
  }

  getDateRange = () => {
    const { minVal, maxVal } = this.props;

    return [
      minVal ? minVal : moment().add(-10, 'year').format('YYYY-MM-DD HH:mm'),
      maxVal ? maxVal : moment().add(10, 'year').format('YYYY-MM-DD HH:mm'),
    ];
  }

  render() {
    const {
      autoFocus = false,
      onOpen,
      onBlur,
      onError,
      id,
      label,
      value,
      placeholder = '',
      formClassName,
      datePickerClassName,
      disabled,
      isRequired,
      helperText,
      onClose,
      clearValue,
      onMouseLeave,
      onInputChange,
      showCustomHelperText = true,
      isMonthAndYear = false,
      isClearable = true,
    } = this.props;
    const setTimeZone = moment(value).utcOffset();
    const correctDate = value ? moment(value).utcOffset(setTimeZone) : null;
    const [minDate, maxDate] = this.getDateRange();

    return (
      <FormControl className={formClassName} ref={this.dpWrap}>
        {!isMonthAndYear ? <MaterialDatePicker
          open={autoFocus}
          defaultValue={null}
          id={id}
          value={correctDate && correctDate.format('MM/DD/YYYY')}
          label={label}
          placeholder={placeholder}
          className={classNames(
            datePickerClassName,
            {
              'placeholder-datapicker-is-active': true && !disabled,
            },
            {
              'placeholder-datapicker-is-disabled': disabled,
            },
          )}
          clearable={isClearable}
          autoFocus={autoFocus}
          onBlur={onBlur && onBlur}
          onOpen={onOpen && onOpen}
          onClose={onClose && onClose}
          onChange={this.onDateChange}
          onError={onError || this.resetDateValue}
          onMouseLeave={onMouseLeave}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={showCustomHelperText && this.getMinDateInvalidMessage()}
          maxDateMessage={showCustomHelperText && this.getMaxDateInvalidMessage()}
          format='MM/DD/YYYY'
          mask={correctDate => (correctDate ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
          views={['year', 'month', 'day']}
          initialFocusedDate={moment().format('YYYY-MM-DD hh:mm')}
          required={isRequired}
          disableOpenOnEnter
          allowKeyboardControl
          keyboard
          title={helperText || 'MM/DD/YYYY'}
          // eslint-disable-next-line react/no-string-refs
          ref={this.opendp}
          autoComplete='off'
          invalidDateMessage='mm/dd/yyyy'
          InputProps={{
            onChange: onInputChange,
          }}
        /> : <MaterialDatePicker
          defaultValue={null}
          id={id}
          value={correctDate}
          label={label}
          placeholder={placeholder}
          className={classNames(
            datePickerClassName,
            {
              'placeholder-datapicker-is-active': true && !disabled,
            },
            {
              'placeholder-datapicker-is-disabled': disabled,
            },
          )}
          clearable={isClearable}
          onChange={this.onDateChange}
          onYearChange={this.onYearChange}
          onClose={onClose && onClose}
          InputLabelProps={{ shrink: true }}
          onError={onError || this.resetDateValue}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={showCustomHelperText && this.getMinDateInvalidMessage()}
          maxDateMessage={showCustomHelperText && this.getMaxDateInvalidMessage()}
          initialFocusedDate={moment().format('MM/YYYY')}
          views={['year', 'month']}
          disableOpenOnEnter
          title={helperText || 'MM/YYYY'}
          allowKeyboardControl
          // eslint-disable-next-line react/no-string-refs
          ref={this.opendp}
          autoComplete='off'
          invalidDateMessage='mm/dd/yyyy'
          InputProps={{
            onChange: onInputChange,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />}
        {
          clearValue && value && !isRequired ? (
            <div className='clear-svg clear-svg-datapicker' onClick={clearValue}>
              <CustomIcon iconName='cross' />
            </div>
          ) : null
        }
      </FormControl>
    );
  }
}

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  minVal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  formClassName: PropTypes.string,
  datePickerClassName: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
};

export default DatePicker;
