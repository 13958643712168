import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import http from 'services/http';
import history from 'utils/history';

import {
  GET_CLIENTS,
  setClients,
  GET_CLIENT_BY_ID,
  setClientById,
  PUT_CLIENT_CHANGES,
  setClientsInfo,
  FILTER_CLIENT_INFO,
  ADD_NEW_CLIENT,
  ADD_CLIENT_INFO,
  GET_FILTER_CLIENTS,
  setError,
  GET_CLIENTS_NAME_ERROR,
  EDIT_CONTACT_INFO,
  TRANSITION_TO_PROJECT,
  SET_CHANGE_WORKING_PERIOD_RESTRICTION,
  POST_WORKING_PERIOD_RESTRICTION,
  PUT_WORKING_PERIOD_RESTRICTION,
  DELETE_WORKING_PERIOD_RESTRICTION,
  setSowTypes,
  GET_SOW_TYPES,
} from '../actions/clientsActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { CLIENT_NAME_ALREADY_EXISTS, BAD_REQUEST_STATUS } from '../../../utils/const-variable';
import { getProjectDetails, getCreationConfig } from '../../projects/sagas/projectsSaga';
import { showAlertDialog } from 'common/actions/confirmationDialogActions.js';

export function* getAllClients() {
  try {
    yield put(startRequest());
    const clients = yield call(http, {
      method: 'GET',
      url: 'clients',
    });
    yield put(setClients(clients.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getClientById(action) {
  try {
    yield put(startRequest());
    const client = yield call(http, {
      method: 'GET',
      url: `clients/${action.payload.Id}`,
    });

    yield put(setClientById(client.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}

export function* putClientChanges(action) {
  if (action.payload.property === 'Name') {
    try {
      yield call(http, {
        method: 'PUT',
        url: `clients?id=${action.payload.client.Id}`,
        data: { Name: action.payload.client.Name.trim() },
      });
    } catch (error) {
      if (error.response && error.response.status === BAD_REQUEST_STATUS) {
        yield call(putClientsError, CLIENT_NAME_ALREADY_EXISTS);
      } else {
        yield call(getClientById, { payload: { Id: action.payload.client.Id } });
      }
    }
  }
}

export function* filterClientInfo(action) {
  try {
    const projects = yield call(http, {
      method: 'GET',
      url: `clients/${action.payload.id}`,
      params: action.payload.filters,
    });
    yield put(setClientsInfo(projects.data));
  } catch (e) {
    // error message
  }
}

export function* addNewClients(action) {
  const { client, location } = action.payload;
  try {
    yield call(http, {
      method: 'POST',
      url: 'clients',
      data: client,
    });
    yield call(transitionToClientList, { location });
  } catch (e) {
    yield call(transitionToClientList, { location });
    if (e.response.status === BAD_REQUEST_STATUS && e.response.data.message === 'Dublicated Name') {
      yield put(showAlertDialog(e.response.data.message && 'Dublicated Company Name'));
    }
  }
}

export function* addClientsInfo(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: `clients/${action.payload.idClient}/client-contacts`,
      data: action.payload.Contacts,
    });
    yield call(getClientById, { payload: { Id: action.payload.idClient } });
  } catch (e) {
    // error message
  }
}

export function* getFilterClients(action) {
  const { searchValue, sortSettings } = action.payload;
  try {
    yield put(startRequest());
    const clients = yield call(http, {
      method: 'GET',
      url: `clients?search=${encodeURIComponent(searchValue)}&sortField=${sortSettings.sortColumn}&orderByDesc=${sortSettings.sortDirection === 'Down'}`,
    });
    yield put(setClients(clients.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putClientsError(error) {
  try {
    yield put(setError(error));
  } catch (e) {
    // error message
  }
}

export function* updateContact(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `clients/${action.payload.clientId}/client-contacts/${action.payload.contactId}`,
      data: action.payload.contact,
    });
    yield call(getClientById, { payload: { Id: action.payload.clientId } });
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* transitionToProjectData(action) {
  try {
    yield put(startRequest());
    yield getProjectDetails({ payload: { id: action.payload.id } });
    yield getCreationConfig();
    action.payload.path && history.push({
      pathname: action.payload.path,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* setWorkingPeriodRestriction(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `clients/${action.payload.clientId}/working-period-restriction?isEnabled=${action.payload.value}`,
    });
    yield call(getClientById, { payload: { Id: action.payload.clientId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* createWorkingPeriodRestriction(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'POST',
      url: `clients/${action.payload.clientId}/working-period-restriction`,
      data: action.payload.workingPeriodRestriction,
    });
    yield call(getClientById, { payload: { Id: action.payload.clientId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* updateWorkingPeriodRestriction(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'PUT',
      url: `clients/${action.payload.clientId}/working-period-restriction/${action.payload.workingPeriodRestriction.Id}`,
      data: action.payload.workingPeriodRestriction,
    });
    yield call(getClientById, { payload: { Id: action.payload.clientId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* deleteWorkingPeriodRestriction(action) {
  try {
    yield put(startRequest());
    yield call(http, {
      method: 'DELETE',
      url: `clients/${action.payload.clientId}/working-period-restriction/${action.payload.id}`,
    });
    yield call(getClientById, { payload: { Id: action.payload.clientId } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getSowTypes() {
  try {
    yield put(startRequest());
    const sowTypes = yield call(http, {
      method: 'GET',
      url: 'sow_types',
    });
    yield put(setSowTypes(sowTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function transitionToClientList(location) {
  location.pathname && history.push(location);
}

export default function* clientsRootSaga() {
  yield takeEvery(GET_CLIENTS, getAllClients);
  yield takeEvery(GET_CLIENT_BY_ID, getClientById);
  yield takeEvery(PUT_CLIENT_CHANGES, putClientChanges);
  yield takeEvery(FILTER_CLIENT_INFO, filterClientInfo);
  yield takeEvery(ADD_NEW_CLIENT, addNewClients);
  yield takeEvery(ADD_CLIENT_INFO, addClientsInfo);
  yield takeLatest(GET_FILTER_CLIENTS, getFilterClients);
  yield takeEvery(GET_CLIENTS_NAME_ERROR, putClientsError);
  yield takeEvery(EDIT_CONTACT_INFO, updateContact);
  yield takeEvery(TRANSITION_TO_PROJECT, transitionToProjectData);
  yield takeEvery(SET_CHANGE_WORKING_PERIOD_RESTRICTION, setWorkingPeriodRestriction);
  yield takeEvery(POST_WORKING_PERIOD_RESTRICTION, createWorkingPeriodRestriction);
  yield takeEvery(PUT_WORKING_PERIOD_RESTRICTION, updateWorkingPeriodRestriction);
  yield takeEvery(DELETE_WORKING_PERIOD_RESTRICTION, deleteWorkingPeriodRestriction);
  yield takeEvery(GET_SOW_TYPES, getSowTypes);
}
