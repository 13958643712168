import React, { Component } from 'react';
import Modal from '../../../../common/components/modal/modal';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { closeModal } from '../../actions/modalActions';
import './add-modal.css';
import classNames from 'classnames';
import MultiSuggestBox from '../../../../common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import Scrollbars from 'react-custom-scrollbars';
import Button from 'common/components/form-items/button/button';
import {
  addNewUser, getUserOffices,
} from '../../actions/adminActions';
import { sortList } from '../../../../common/components/list/sortList';
import { Badge } from '../../../../common/components/badges/badges';
import { getColorFromString } from '../../../../services/getColor';
import {
  ADMIN_ROLE_NAME, API_ACCESS_ROLE_NAME, ENGINEERING_MANAGER_ROLE_NAME, DELIVARY_MANAGER_ROLE_NAME,
} from '../../../../utils/const-variable';
import isEmptyObject from '../../../../utils/isEmptyObject';
import sortByAplhabet from 'utils/sortByAplhabet';
import CustomIcon from '../../../../common/components/icon/Icon';
import { RenderRoleTypes } from './components/renderRoleType';
import { Permissions } from './components/permissions';
import { getCorrectPermissionsGroup } from './components/getCorrectPermissionsGroup';
import { showUpdateUserConfirmation } from '../../actions/editUserConfirmationActions';

export class AdminModal extends Component {
  constructor(props) {
    super(props);
    const { modalType, userDetails, employeesRoles } = this.props;
    this.state = {
      isModalFieldsRequired: !modalType,
      employees: [],
      user: userDetails || {},
      isAddNew: modalType,
      roles: [],
      employeesRoles,
      convertedRoles: [],
      isDisabled: false,
      adminId: '',
      dataPermissions: {},
      selectedUserOffices: [],
      initialRoleIsApiAccess: false,
    };
  }

  componentDidMount() {
    const { isAddNew } = this.state;
    const {
      userDetails, getUserOffices, officesOptions,
    } = this.props;
    this.setDefaultData();
    this.setAdminId();
    this.setEmployeesOptions();
    !isAddNew && getUserOffices(userDetails.Id);
    isAddNew && this.setUserOffices(officesOptions.filter(office => !office.IsDisabled).map(office => office.Id));
    if (userDetails && !isAddNew) {
      this.setUsers();
    }
    if (userDetails.Permissions) {
      this.setPermissions(userDetails.Permissions);
    }
    this.checkInitialRoles();
  }

  componentDidUpdate(prevProps) {
    const { isAddNew } = this.state;
    const {
      userDetails, employeesRoles, userOffices, officesOptions,
    } = this.props;
    if (userOffices !== prevProps.userOffices) {
      const offices = userOffices && userOffices.length ? userOffices : officesOptions.map(office => office.Id);
      this.setUserOffices(offices);
    }
    if (prevProps.employeesRoles !== employeesRoles) {
      this.setAdminId();
    }
    if (prevProps.userDetails !== userDetails && !isAddNew) {
      this.setUsers();
    }
  }

  setDefaultData = () => {
    const { employees, userDetails } = this.props;
    const { isAddNew, user } = this.state;
    this.setState({
      employees: this.getDataForMultiBox(employees),
      user: userDetails,
    });
    if (isAddNew) {
      this.setState({
        convertedRoles: this.convertRoles(),
      });
    } else {
      this.setState({
        convertedRoles: this.setRoleForUser(user.Roles),
      });
    }
  }

  setUserOffices = (selectedUserOffices) => {
    this.setState({ selectedUserOffices }, () => this.isRequiredFields());
  }

  setAdminId = () => {
    this.setState({
      adminId: this.getAdminId(),
    });
  }

  setUsers = () => {
    const { user } = this.state;
    const { userDetails } = this.props;
    this.setState({
      user: userDetails,
    }, () => {
      this.setState({
        convertedRoles: this.setRoleForUser(user.Roles),
      }, () => {
        this.setRoles();
      });
    });
  }

  setRoles = () => {
    const { user } = this.state;
    const array = [];
    user.Roles.forEach((role) => {
      array.push(role.Id);
    });
    this.setState({
      roles: array,
    }, () => this.isAdminRoleSelected());
  }

  getDataForMultiBox = (arr) => {
    const employeesForMultiBox = arr.reduce((acc, item) => {
      acc.push({
        label: item.Name,
        value: item.Name,
        valueId: item.Id,
      });
      return acc;
    }, []);
    return employeesForMultiBox;
  };

  onChangeUser = (value) => {
    this.setState({
      user: value,
    }, () => this.isRequiredFields());
  };

  convertRoles = () => {
    const { employeesRoles } = this.props;
    const roles = employeesRoles.reduce((acc, item) => {
      acc.push({
        Id: item.Id,
        Name: item.Name,
        DisplayName: item.DisplayName === ENGINEERING_MANAGER_ROLE_NAME ? DELIVARY_MANAGER_ROLE_NAME : item.DisplayName,
        isSelected: false,
        disabled: false,
      });
      return acc;
    }, []);
    return sortList(roles, 'Name', 'Up');
  };

  setRoleForUser = (user) => {
    const arr = this.convertRoles();
    arr.map((item, i) => {
      return user.forEach((role) => {
        if (item.Id === role.Id) {
          arr[i].isSelected = true;
        }
      });
    });
    return arr;
  };

  onChangeRoles = (e) => {
    const { roles } = this.state;
    const role = e.target.id;
    const newRoles = [...roles];

    newRoles.includes(role) ? newRoles.splice(newRoles.indexOf(role), 1) : newRoles.push(role);

    this.setState({
      roles: newRoles,
    }, () => {
      this.isAdminRoleSelected();
      this.setDefaultPermissionsByRole(newRoles);
    });
  };

  isApiAccessRoleSelected = () => {
    const { roles } = this.state;
    this.setState({
      isApiAccess: roles.length && roles.includes(this.getApiAccessId()),
    }, () => this.isRequiredFields());
  };

  isAdminRoleSelected = () => {
    const { roles, adminId } = this.state;
    this.setState({
      isDisabled: roles.length && roles.includes(adminId),
    }, () => this.isApiAccessRoleSelected());
  };

  isRequiredFields = () => {
    const {
      user, roles, isApiAccess, selectedUserOffices,
    } = this.state;
    const isUserValidAndRolesAdded = user && !isEmptyObject(user) && roles.length;
    this.setState({
      isModalFieldsRequired: isApiAccess ? selectedUserOffices && selectedUserOffices.length && isUserValidAndRolesAdded : isUserValidAndRolesAdded,
    });
  };

  filterEmployees = () => {
    const { users, employees } = this.props;
    return employees.filter((employee) => {
      if (users.find(user => user.EmployeeId === employee.Id)) {
        return null;
      }
      return employee;
    });
  };

  setEmployeesOptions = () => {
    this.setState({
      employeesOptions: sortList(this.getDataForMultiBox(this.filterEmployees()), 'label', 'Up'),
    });
  }

  onApply = () => {
    const {
      isModalFieldsRequired, isAddNew, isDisabled, isApiAccess,
      user, roles, adminId, dataPermissions, selectedUserOffices,
    } = this.state;
    const {
      addNewUser, closeModal, officesOptions, showUpdateUserConfirmation,
    } = this.props;
    if (isModalFieldsRequired) {
      const newUser = {
        SecurityRoleIds: isDisabled ? [adminId] : roles,
        Permissions: dataPermissions,
      };
      const arrayAllOfficeIds = officesOptions.filter(office => !office.isDisabled).map((office) => office.Id);
      if (isAddNew) {
        newUser.EmployeeId = user.valueId;
        newUser.OfficeIds = selectedUserOffices;
        addNewUser(newUser);
      } else {
        showUpdateUserConfirmation(user.Id, newUser.Permissions, newUser.SecurityRoleIds, isApiAccess ? selectedUserOffices : arrayAllOfficeIds);
      }
    }
    closeModal();
  }

  getApiAccessId = () => {
    const { employeesRoles } = this.props;
    const apiAccessRole = employeesRoles.find((item) => item.Name === API_ACCESS_ROLE_NAME);
    return apiAccessRole && apiAccessRole.Id;
  };

  getAdminId = () => {
    const { employeesRoles } = this.props;
    const adminRole = employeesRoles.find((item) => item.Name === ADMIN_ROLE_NAME);
    return adminRole && adminRole.Id;
  };

  setPermissions = (permissions) => {
    if (permissions) {
      this.setState({
        dataPermissions: permissions,
      });
    }
  }

  tooglePermission = ({ target }) => {
    const checkbox = target.name;
    const { dataPermissions } = this.state;
    this.setState({
      dataPermissions: {
        ...dataPermissions,
        [checkbox]: !dataPermissions[checkbox],
      },
    });
  }

  setDefaultPermissionsByRole = (roles) => {
    const { employeesRoles } = this.props;
    this.setState({
      dataPermissions: getCorrectPermissionsGroup(roles, employeesRoles),
    });
  }

  handleChangeSelectedOffices = (selectedUserOffices) => {
    this.setState({
      selectedUserOffices,
    }, this.isApiAccessRoleSelected());
  }

  checkInitialRoles = () => {
    const { userDetails } = this.props;
    const isApiAccess = userDetails.Roles && userDetails.Roles.find((role) => role.Name === 'API Access');
    this.setState({
      initialRoleIsApiAccess: !!isApiAccess,
    });
  }

  render() {
    const {
      isAddNew,
      user,
      isDisabled,
      convertedRoles,
      roles,
      isModalFieldsRequired,
      dataPermissions,
      adminId,
      selectedUserOffices,
      employeesOptions,
    } = this.state;
    const { closeModal, officesOptions } = this.props;
    return (
      <Modal>
        <div className='c-modal admin-modal_manage-user'>
          <div className='admin-modal-block admin-modal-bottom-border'>
            {
              isAddNew
                ? <span className='c-role-modal__title'>ADD USER</span>
                : <span className='c-role-modal__title'>EDIT USER</span>
            }
            <CustomIcon iconName='cross' onClick={closeModal} className='pointer' />
          </div>
          <div className='admin-modal-block'>
            {
              isAddNew ? (
                <MultiSuggestBox
                  onAdd={this.onChangeUser}
                  label='Add Someone'
                  placeholder='Enter Name or Email address'
                  options={employeesOptions}
                  isMulti={false}
                  isRequired
                />
              ) : user.Employee ? (
                <div className='c-role-modal__block__row u-space-between padding-top margin-top'>
                  <Badge
                    color={getColorFromString(user.Employee.Name ? user.Employee.Name : '')}
                    item={user.Employee.Name}
                  />
                  <span>{user.Employee.Name ? user.Employee.Name : ''}</span>
                </div>
              ) : null
            }
          </div>
          <div className='u-flex-column admin-modal-main-block admin-modal-block admin-modal-bottom-border'>
            <Scrollbars
              autoHide
              hideTracksWhenNotNeeded
            >
              <span className='admin-modal-block-title'>Choose Role</span>
              <RenderRoleTypes
                roles={convertedRoles}
                isDisabled={isDisabled}
                onChange={this.onChangeRoles}
                checkedRoles={roles}
                officesOptions={officesOptions}
                selectedUserOffices={selectedUserOffices}
                handleChangeSelectedOffices={this.handleChangeSelectedOffices}
              />
              <Permissions
                permissions={dataPermissions}
                onClick={this.tooglePermission}
                isAdmin={roles.includes(adminId)}
              />
            </Scrollbars>
          </div>
          <div className='admin-modal-buttons admin-modal-block'>
            <div className='button cancel' onClick={closeModal}>
              <span>Cancel</span>
            </div>
            <Button
              className={classNames(
                'button next',
                { 'btn-disabled': !isModalFieldsRequired },
              )}
              disabled={!isModalFieldsRequired}
              onClick={this.onApply}
              label='Apply'
            />
          </div>
        </div>
      </Modal>
    );
  }
}

AdminModal.propTypes = {
  closeModal: PropTypes.func,
  employees: PropTypes.array,
  employeesRoles: PropTypes.array,
  addNewUser: PropTypes.func,
  modalType: PropTypes.bool,
  userDetails: PropTypes.object,
  userOffices: PropTypes.array,
  officesOptions: PropTypes.array,
  users: PropTypes.array,
};

export default connect((store) => ({
  employees: store.adminModalReducer.employees,
  employeesRoles: store.adminModalReducer.employeesRoles,
  modalType: store.adminModalReducer.modalType,
  userDetails: store.adminModalReducer.userDetails,
  userOffices: store.adminModalReducer.userOffices,
  user: store.adminModalReducer.userDetails,
  users: store.adminReducer.users,
  officesOptions: sortByAplhabet([...store.projectsReducer.offices]),
  listSettings: store.adminReducer.listSettings,
}), {
  closeModal,
  addNewUser,
  getUserOffices,
  showUpdateUserConfirmation,
})(AdminModal);
