import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import Modal from 'common/components/modal/modal';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import Checkbox from 'common/components/form-items/checkbox/checkbox';

import {
  hideAddVacationModal,
  createVacation,
  editVacation,
} from '../../../../actions/membersActions';

import '../vacations.css';

export class AddVacationModal extends Component {
  constructor(props) {
    super(props);
    const { vacationToChange } = this.props;
    this.state = {
      id: vacationToChange.Id || '',
      startDate: vacationToChange.StartDate ? moment(vacationToChange.StartDate) : null,
      endDate: vacationToChange.EndDate ? moment(vacationToChange.EndDate) : null,
      isFormValid: false,
      isDuplicated: false,
      IsLongTerm: vacationToChange.IsLongTerm || false,
    };
  }

  componentDidMount() {
    this.checkValid();
  }

  checkValid = () => {
    const {
      startDate, endDate, isDuplicated,
    } = this.state;
    this.setState({
      isFormValid:
        moment(startDate).isValid()
        && moment(endDate).isValid()
        && moment(endDate).isSameOrAfter(moment(startDate, 'second'))
        && !isDuplicated,
    });
  }

  isDatesEqual = (chosenDate, existingDate) => {
    return chosenDate.isSame(existingDate, 'day');
  }

  isPeriodIncludesAnother = (firstStartDate, firstEndDate, secondStartDate, secondEndDate) => {
    return firstStartDate.isBefore(secondStartDate, 'day') && firstEndDate.isAfter(secondEndDate, 'day');
  }

  isDateInsideExistingPeriod = (chosenDate, existingStartDate, existingEndDate) => {
    return chosenDate.isAfter(existingStartDate, 'day') && chosenDate.isBefore(existingEndDate, 'day');
  }

  checkDuplicated = () => {
    const {
      startDate, endDate, id,
    } = this.state;
    const {
      vacations,
    } = this.props;
    const
      start = startDate && startDate,
      end = endDate && endDate;

    vacations.forEach(propVacation => {
      const propStart = moment(propVacation.StartDate);
      const propEnd = moment(propVacation.EndDate);
      const isDatesEqual = start && end && (
        this.isDatesEqual(start, propStart) || this.isDatesEqual(end, propEnd)
      );
      const isPeriodIncludesAnother = start && end && (
        this.isPeriodIncludesAnother(start, end, propStart, propEnd) || this.isPeriodIncludesAnother(propStart, propEnd, start, end)
      );
      const isDateInsideExistingPeriod = start && end && (
        this.isDateInsideExistingPeriod(start, propStart, propEnd) || this.isDateInsideExistingPeriod(end, propStart, propEnd)
      );

      id !== propVacation.Id && (
        isDatesEqual || isPeriodIncludesAnother || isDateInsideExistingPeriod
      ) && this.setState({ isDuplicated: true }, () => this.checkValid());
    });

    this.checkValid();
  }

  handleStartDateChange = date => {
    this.setState({
      startDate: moment(date),
      isDuplicated: false,
    }, () => this.checkDuplicated());
  }

  handleEndDateChange = date => {
    this.setState({
      endDate: moment(date),
      isDuplicated: false,
    }, () => this.checkDuplicated());
  }

  cancel = () => {
    const { hideAddVacationModal } = this.props;
    this.setState({
      id: '',
      startDate: null,
      endDate: null,
    }, () => hideAddVacationModal());
  }

  sendvacation = (vacation) => {
    const { id } = this.state;
    const {
      editVacation, employeeId, selectedYear, createVacation,
    } = this.props;
    if (id) {
      vacation.Id = id;
      editVacation(vacation, employeeId, selectedYear);
    } else {
      createVacation(vacation, employeeId, selectedYear);
    }
  }

  applyVacation = () => {
    const { startDate, endDate, IsLongTerm } = this.state;
    const { hideAddVacationModal } = this.props;
    const checkYears = moment(endDate).format('YYYY') - moment(startDate).format('YYYY');
    if (checkYears === 0) {
      const vacation = {
        StartDate: startDate && moment(startDate).format('YYYY-MM-DD HH:mm'),
        EndDate: endDate && moment(endDate).format('YYYY-MM-DD HH:mm'),
        IsLongTerm,
      };
      this.sendvacation(vacation);
    } else {
      this.recursionGetDate({ startDate, endDate, IsLongTerm });
    }
    hideAddVacationModal();
  }

  recursionGetDate = ({ startDate, endDate, IsLongTerm }) => {
    const lastDateOfYear = moment(`${moment(startDate).format('YYYY')}-12-31`);
    const startDateOfYear = moment(`${moment(startDate).format('YYYY')}-01-01`).add('year', 1);
    const checkYears = moment(lastDateOfYear).isBetween(startDate, endDate);
    const startVacation = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      EndDate: moment(lastDateOfYear).format('YYYY-MM-DD'),
      IsLongTerm,
    };
    const endVacation = {
      startDate: moment(startDateOfYear).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      IsLongTerm,
    };
    if (checkYears) {
      this.sendvacation(startVacation);
      return this.recursionGetDate(endVacation);
    }
    endVacation.startDate = moment(startDateOfYear).add('year', -1).format('YYYY-MM-DD');
    this.sendvacation(endVacation);
  }

  handleIsLongTerm = () => {
    const { IsLongTerm } = this.state;
    this.setState({
      IsLongTerm: !IsLongTerm,
    });
  }

  render() {
    const { hideAddVacationModal } = this.props;
    const {
      id, startDate, endDate, isFormValid, isDuplicated, IsLongTerm,
    } = this.state;
    return (
      <Modal>
        <div className='vacations-modal__container'>
          <div className='vacations-modal__header'>
            <span>{id ? 'edit vacation' : 'add vacation'}</span>
            <div onClick={hideAddVacationModal}>
              <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  opacity='0.54'
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z'
                  fill='black'
                />
              </svg>
            </div>
          </div>
          <div className='vacations-modal__content'>
            <div className='vacation-modal__checkbox'>
              <Checkbox
                value='Long term vacation'
                onClick={this.handleIsLongTerm}
                checked={IsLongTerm}
              />
            </div>
            <div className='vacations-modal-body-contetn-center'>
              <DatePicker
                formClassName='step__form'
                datePickerClassName='vacations-modal__date-picker'
                placeholder='--/--/--'
                label='Start Date'
                value={startDate}
                onChange={this.handleStartDateChange}
                name='startDate'
                isRequired
                minVal={moment(`01/01/${new Date().getFullYear() - 3}`)}
                maxVal={moment(`12/31/${new Date().getFullYear() + 6}`)}
                isStartDate
              />
              <DatePicker
                formClassName='step__form'
                datePickerClassName='vacations-modal__date-picker'
                placeholder='--/--/--'
                label='End Date'
                value={endDate}
                onChange={this.handleEndDateChange}
                name='endDate'
                isRequired
                minVal={startDate ? startDate : moment(`01/01/${new Date().getFullYear() - 1}`)}
                maxVal={moment(`12/31/${new Date().getFullYear() + 6}`)}
                isEndDate
              />
            </div>
            <span className={classNames('validation-message', { 'validation-message--hidden': !isDuplicated })}>
              Vacation already exists in this time period
            </span>
          </div>
          <div className='vacations-modal__buttons'>
            <div onClick={this.cancel} className='vacations-modal__cancel button'>Cancel</div>
            <div
              className={classNames('button vacations-modal__apply',
                { 'btn-disabled': !isFormValid })}
              onClick={isFormValid ? this.applyVacation : null}
            >Apply</div>
          </div>
        </div>
      </Modal>
    );
  }
}

AddVacationModal.propTypes = {
  hideAddVacationModal: PropTypes.func,
  createVacation: PropTypes.func,
  editVacation: PropTypes.func,
  employeeId: PropTypes.string,
  vacationToChange: PropTypes.object,
};

export default connect(store => ({
  vacations: store.membersReducer.vacations,
  employeeId: store.membersReducer.employee.Id,
  vacationToChange: store.membersReducer.vacationToChange,
  selectedYear: store.membersReducer.selectedYear,
}), {
  hideAddVacationModal,
  createVacation,
  editVacation,
})(AddVacationModal);
