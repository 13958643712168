/* eslint-disable default-param-last */
export const FETCH_PROJECTS = '@projects/FETCH_PROJECTS';
export const SEARCH_PROJECTS = '@projects/SEARCH_PROJECTS';
export const SET_PROJECTS = '@projects/SET_PROJECTS';
export const SET_OFFICES = '@projects/SET_OFFICES';
export const GET_OFFICES = '@projects/GET_OFFICES';
export const SET_CLIENTS = '@projects/SET_CLIENTS';
export const GET_CLIENTS = '@projects/GET_CLIENTS';
export const SET_STATUSES = '@projects/SET_STATUSES';
export const GET_STATUSES = '@projects/GET_STATUSES';
export const SET_SOW_TYPES = '@projects/SET_SOW_TYPES';
export const SET_PERIODS = '@projects/SET_PERIODS';
export const GET_PERIODS = '@projects/GET_PERIODS';
export const GET_CREATION_CONFIG = '@projects/GET_CREATION_CONFIG';
export const SET_CLIENT_CONTACTS = '@projects/SET_CLIENT_CONTACTS';
// export const SET_INVOICE_APPROVERS = '@projects/SET_INVOICE_APPROVERS';
export const SET_PROJECT_STATUSES = '@projects/SET_PROJECT_STATUSES';
export const SET_PROJECT_TYPES = '@projects/SET_PROJECT_TYPES';
export const SET_APPROVAL_STATUSES = '@projects/SET_APPROVAL_STATUSES';
// export const SET_PO_APPROVERS = '@projects/SET_PO_APPROVERS';
export const CREATE_NEW_PROJECT = '@projects/CREATE_NEW_PROJECT';
export const GET_PROJECT_DETAILS = '@projects/GET_PROJECT_DETAILS';
export const SET_PROJECT_DETAILS = '@projects/SET_PROJECT_DETAILS';
export const RESET_PROJECT_DETAILS = '@projects/RESET_PROJECT_DETAILS';
export const SHOW_NEW_PROJECT_MODAL = '@projects/SHOW_NEW_PROJECT_MODAL';
export const HIDE_NEW_PROJECT_MODAL = '@projects/HIDE_NEW_PROJECT_MODAL';
export const CHANGE_COLUMNS_TO_DISPLAY = '@projects/CHANGE_COLUMNS_TO_DISPLAY';
// export const GET_BUSINESS_GROUPS = '@projects/GET_BUSINESS_GROUPS';
export const SET_BUSINESS_GROUPS = '@projects/SET_BUSINESS_GROUPS';
export const GET_PROJECT_TEAMS = '@projects/GET_PROJECT_TEAMS';
export const GET_PROJECT_TEAM_EMPLOYEES = '@projects/GET_PROJECT_TEAM_EMPLOYEES';
export const SET_PROJECT_TEAMS = '@projects/SET_PROJECT_TEAMS';
export const RESET_PROJECT_TEAMS = '@projects/RESET_PROJECT_TEAMS';
export const CREATE_PROJECT_TEAM = '@projects/CREATE_PROJECT_TEAM';
export const EDIT_PROJECT_TEAM = '@projects/EDIT_PROJECT_TEAM';
export const DELETE_PROJECT_TEAM = '@projects/DELETE_PROJECT_TEAM';
export const GET_PO_STATUSES = '@projects/GET_PO_STATUSES';
export const SET_PO_STATUSES = '@projects/SET_PO_STATUSES';
export const GET_PROJECT_ROLE_CONFIG = '@projects/GET_PROJECT_ROLE_CONFIG';
export const SET_PROJECT_ROLE_CONFIG = '@projects/SET_PROJECT_ROLE_CONFIG';
export const TRANSFER_EXTENDED_ROLES = '@projects/TRANSFER_EXTENDED_ROLES';
export const SET_PROJECT_ROLES = '@projects/SET_PROJECT_ROLES';
export const TRANSFER_ROLES = '@projects/TRANSFER_ROLES';
export const GET_ROLES = '@projects/GET_ROLES';
export const TOOGLE_ROLES_STATUS = '@projects/TOOGLE_ROLES_STATUS';
export const GET_ALL_ROLES_PROJECT = '@projects/GET_ALL_ROLES_PROJECT';
export const SET_ALL_ROLES_PROJECT = '@projects/SET_ALL_ROLES_PROJECT';
export const GET_HISTORY_PROJECT = '@projects/GET_HISTORY_PROJECT';
export const SET_HISTORY_PROJECT = '@projects/SET_HISTORY_PROJECT';
export const TOOGLE_LOAD_HISTORY = '@projects/TOOGLE_LOAD_HISTORY';
export const SET_PROJECTS_TOTAL_COUNT = '@projects/SET_PROJECTS_TOTAL_COUNT';
export const CHECK_PROJECT_NAME_DUBLICATE_ERROR = '@projects/CHECK_PROJECT_NAME_DUBLICATE_ERROR';
export const SET_PROJECT_NAME_DUBLICATE_ERROR = '@projects/SET_PROJECT_NAME_DUBLICATE_ERROR';
export const GET_MANAGERS_BY_OFFICE = '@projects/GET_DELIVERY_MANAGERS_BY_OFFICE';
export const SET_MANAGERS_BY_OFFICE = '@projects/SET_DELIVERY_MANAGERS_BY_OFFICE';
export const GET_ALL_CURRENCIES = '@projects/GET_ALL_CURRENCIES';
export const SET_CURRENCIES = '@projects/SET_CURRENCIES';
export const PATCH_PROJECT_DATA = '@projects/PATCH_PROJECT_DATA';
export const GET_MILESTONES_FOR_SOW = '@contracts/GET_MILESTONES_FOR_SOW';
export const SET_MILESTONES_FOR_SOW = '@contracts/SET_MILESTONES_FOR_SOW';
export const GET_MILESTONES_FOR_PO = '@contracts/GET_MILESTONES_FOR_PO';
export const SET_MILESTONES_FOR_PO = '@contracts/SET_MILESTONES_FOR_PO';
export const GET_MILESTONES_FOR_AMENDMENT = '@contracts/GET_MILESTONES_FOR_AMENDMENT';
export const SET_MILESTONES_FOR_AMENDMENT = '@contracts/SET_MILESTONES_FOR_AMENDMENT';
export const GET_PROJECT_MILESTONES = '@projects/GET_PROJECT_MILESTONES';
export const SET_PROJECT_MILESTONES = '@projects/SET_PROJECT_MILESTONES';
export const PATCH_PROJECT_MILESTONE = '@projects/PATCH_PROJECT_MILESTONE';
export const UPDATE_PROJECT_MILESTONE_EDITING_FORM = '@projects/UPDATE_PROJECT_MILESTONE_EDITING_FORM';
export const RESET_PROJECTS = '@projects/RESET_PROJECTS';
export const SET_LIST_SETTINGS = '@projects/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@projects/RESET_LIST_SETTINGS';
export const GET_SOW_RENEWAL_STATUSES_FOR_SOW = '@projects/GET_SOW_RENEWAL_STATUSES_FOR_SOW';
export const SET_SOW_RENEWAL_STATUSES_FOR_SOW = '@projects/SET_SOW_RENEWAL_STATUSES_FOR_SOW';
export const GET_PREV_PATH_FOR_PROJECTS = '@projects/GET_PREV_PATH_FOR_PROJECTS';
export const GET_ALL_ENGAGEMENT_TYPES = '@projects/GET_ALL_ENGAGEMENT_TYPES';
export const SET_ENGAGEMENT_TYPES = '@projects/SET_ENGAGEMENT_TYPES';
export const PATCH_CONTRACT_DATA = '@projects/PATCH_CONTRACT_DATA';
export const GET_SHADOWING_MILESTONES_REQUEST = '@projects/GET_SHADOWING_MILESTONES_REQUEST';
export const GET_SHADOWING_MILESTONES_SUCCESS = '@projects/GET_SHADOWING_MILESTONES_SUCCESS';
export const RESET_SHADOWING_MILESTONES = '@projects/RESET_SHADOWING_MILESTONES';
export const UPDATE_FISCAL_YEARS = '@projects/UPDATE_FISCAL_YEARS';

export const getPrevPathForProjects = (path) => ({
  type: GET_PREV_PATH_FOR_PROJECTS,
  payload: path,
});

export const getManagersByOffice = (offices = []) => ({
  type: GET_MANAGERS_BY_OFFICE,
  payload: offices,
});

export const setManagersByOffices = (managers) => ({
  type: SET_MANAGERS_BY_OFFICE,
  payload: managers,
});

export const getAllEngagementTypes = () => ({
  type: GET_ALL_ENGAGEMENT_TYPES,
});

export const setEngagementTypes = (engagementTypes) => ({
  type: SET_ENGAGEMENT_TYPES,
  payload: engagementTypes,
});

export const toogleHistoryLoader = () => ({
  type: TOOGLE_LOAD_HISTORY,
});

export const getHistory = (id, skip, take, options) => ({
  type: GET_HISTORY_PROJECT,
  payload: {
    id,
    skip,
    take,
    options,
  },
});

export const setHistory = (history) => ({
  type: SET_HISTORY_PROJECT,
  payload: history,
});

export const getProjectMilestones = (id) => ({
  type: GET_PROJECT_MILESTONES,
  payload: {
    id,
  },
});

export const patchProjectMilestone = (data) => ({
  type: PATCH_PROJECT_MILESTONE,
  payload: data,
});

export const setProjectMilestones = (milestones) => ({
  type: SET_PROJECT_MILESTONES,
  payload: milestones,
});

export const setAllRoles = (roles) => ({
  type: SET_ALL_ROLES_PROJECT,
  payload: roles,
});

export const getAllRoles = (id) => ({
  type: GET_ALL_ROLES_PROJECT,
  payload: { id },
});

export const toogleRolesStatus = () => ({
  type: TOOGLE_ROLES_STATUS,
});

export const getRoles = (id, isOnlyActive) => ({
  type: GET_ROLES,
  payload: {
    id,
    isOnlyActive,
  },
});

export const transferRoles = (roles, id, projectId, endDate, startDate) => ({
  type: TRANSFER_ROLES,
  payload: {
    RolesForExtend: roles,
    EndDate: endDate,
    StartDate: startDate,
  },
  newId: id,
  projectId,
});

export const setProjectRoles = (roles) => ({
  type: SET_PROJECT_ROLES,
  payload: roles,
});

export const setTransferedRoles = (roles) => ({
  type: TRANSFER_EXTENDED_ROLES,
  payload: roles,
});

export const getPoStatuses = () => ({
  type: GET_PO_STATUSES,
});

export const setPoStatuses = (data) => ({
  type: SET_PO_STATUSES,
  payload: data,
});

export const getProjectDetails = (id, isOnlyActive, isLoading = false, params) => ({
  type: GET_PROJECT_DETAILS,
  payload: {
    id,
    isOnlyActive,
    params,
    isLoading,
  },
});

export const setProjectDetails = (projectDetails) => ({
  type: SET_PROJECT_DETAILS,
  payload: {
    projectDetails,
  },
});

export const resetProjectDetails = () => ({
  type: RESET_PROJECT_DETAILS,
  payload: {
    projectDetails: null,
  },
});

export const getProjectTeamEmployees = () => ({
  type: GET_PROJECT_TEAM_EMPLOYEES,
});

export const getProjectTeams = (id) => ({
  type: GET_PROJECT_TEAMS,
  payload: {
    id,
  },
});

export const setProjectTeams = (projectTeams) => {
  return {
    type: SET_PROJECT_TEAMS,
    payload: {
      projectTeams,
    },
  };
};

export const getProjectRoleConfig = (id) => ({
  type: GET_PROJECT_ROLE_CONFIG,
  payload: {
    id,
  },
});

export const setProjectRoleConfig = (projectRoleConfig) => ({
  type: SET_PROJECT_ROLE_CONFIG,
  payload: {
    projectRoleConfig,
  },
});

export const fetchProjects = (filters = {}) => ({
  type: FETCH_PROJECTS,
  payload: {
    filters,
  },
});

export const searchProjects = (value, filters = {}, take = 50, skip = 0, sortSetting) => ({
  type: SEARCH_PROJECTS,
  payload: {
    searchValue: value,
    filters,
    take,
    skip,
    sortSetting,
  },
});

export const setProjects = (projects) => ({
  type: SET_PROJECTS,
  payload: {
    projects,
  },
});

export const setProjectTypes = (projectTypes) => ({
  type: SET_PROJECT_TYPES,
  payload: {
    projectTypes,
  },
});

export const getOffices = (allowDisabled = false) => {
  return {
    type: GET_OFFICES,
    payload: { allowDisabled }
  };
};

export const setOffices = (offices) => {
  return {
    type: SET_OFFICES,
    payload: {
      offices,
    },
  };
};

export const setBusinessGroups = (businessGroups) => {
  return {
    type: SET_BUSINESS_GROUPS,
    payload: {
      businessGroups,
    },
  };
};

export const getClients = () => {
  return {
    type: GET_CLIENTS,
  };
};
export const setClients = (clients) => {
  return {
    type: SET_CLIENTS,
    payload: {
      clients,
    },
  };
};

export const getStatuses = () => {
  return {
    type: GET_STATUSES,
  };
};

export const setStatuses = (statuses) => {
  return {
    type: SET_STATUSES,
    payload: {
      statuses,
    },
  };
};

export const setSOWTypes = (SOWTypes) => ({
  type: SET_SOW_TYPES,
  payload: {
    SOWTypes,
  },
});

export const getPeriods = () => {
  return {
    type: GET_PERIODS,
  };
};

export const setPeriods = (periods) => {
  return {
    type: SET_PERIODS,
    payload: {
      periods,
    },
  };
};

export const getCreationConfig = () => ({
  type: GET_CREATION_CONFIG,
});

export const setClientContacts = (clientContacts) => ({
  type: SET_CLIENT_CONTACTS,
  payload: {
    clientContacts,
  },
});

export const setProjectStatuses = (projectStatuses) => ({
  type: SET_PROJECT_STATUSES,
  payload: {
    projectStatuses,
  },
});

export const setApprovalStatuses = (approvalStatuses) => ({
  type: SET_APPROVAL_STATUSES,
  payload: {
    approvalStatuses,
  },
});

export const createNewProject = (data) => {
  return {
    type: CREATE_NEW_PROJECT,
    payload: data,
  };
};

export const showNewProjectModal = () => {
  return {
    type: SHOW_NEW_PROJECT_MODAL,
    payload: {
      modalNewPropjectIsOpen: true,
    },
  };
};

export const hideNewProjectModal = () => {
  return {
    type: HIDE_NEW_PROJECT_MODAL,
    payload: {
      modalNewPropjectIsOpen: false,
    },
  };
};

export const changeColumnsToDisplay = (columnsToDisplay) => {
  return {
    type: CHANGE_COLUMNS_TO_DISPLAY,
    payload: {
      columnsToDisplay,
    },
  };
};

export const setProjectTotalCount = (count) => {
  return {
    type: SET_PROJECTS_TOTAL_COUNT,
    payload: count,
  };
};

export const checkProjectNameDublicateError = (projectName) => {
  return {
    type: CHECK_PROJECT_NAME_DUBLICATE_ERROR,
    payload: projectName,
  };
};

export const setProjectNameDublicateError = (isError) => {
  return {
    type: SET_PROJECT_NAME_DUBLICATE_ERROR,
    payload: isError,
  };
};

export const getAllCurrencies = () => {
  return {
    type: GET_ALL_CURRENCIES,
  };
};

export const setCurrencies = (currencies) => {
  return {
    type: SET_CURRENCIES,
    payload: currencies,
  };
};

export const patchProjectData = ({ projectId, projectData }) => ({
  type: PATCH_PROJECT_DATA,
  payload: { projectId, projectData },
});

export const getMilestonesForSOW = (sowId, projectId) => ({
  type: GET_MILESTONES_FOR_SOW,
  payload: { projectId, sowId },
});

export const setMilestonesForSOW = (milestones) => ({
  type: SET_MILESTONES_FOR_SOW,
  payload: milestones,
});

export const getMilestonesForPO = (poId, projectId, sowId, isOriginalPo) => ({
  type: GET_MILESTONES_FOR_PO,
  payload: {
    poId, projectId, sowId, isOriginalPo,
  },
});

export const setMilestonesForPO = (milestones) => ({
  type: SET_MILESTONES_FOR_PO,
  payload: milestones,
});

export const getMilestonesForAmendment = (amendmentId, projectId, sowId) => ({
  type: GET_MILESTONES_FOR_AMENDMENT,
  payload: { amendmentId, projectId, sowId },
});

export const setMilestonesForAmendment = (milestones) => ({
  type: SET_MILESTONES_FOR_AMENDMENT,
  payload: milestones,
});

export const getProjectMilestoneEditingForm = (state) => ({
  form: state.projectsReducer.projectMilestoneEditingForm,
});

export const updateProjectMilestoneEditingForm = (milestoneId, updatedFields) => ({
  type: UPDATE_PROJECT_MILESTONE_EDITING_FORM,
  payload: {
    milestoneId,
    updatedFields,
  },
});

export const resetProjects = () => ({
  type: RESET_PROJECTS,
});

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const getRenewalStatusesForSow = (sowId) => ({
  type: GET_SOW_RENEWAL_STATUSES_FOR_SOW,
  payload: sowId,
});

export const setRenewalStatusesForSow = (renewalStatuses) => ({
  type: SET_SOW_RENEWAL_STATUSES_FOR_SOW,
  payload: renewalStatuses,
});

export const patchContractData = (configForRequest) => ({
  type: PATCH_CONTRACT_DATA,
  payload: configForRequest,
});

export const createProjectTeam = (projectId, team) => ({
  type: CREATE_PROJECT_TEAM,
  payload: {
    projectId,
    team,
  },
});

export const editProjectTeam = (projectId, teamId, team) => ({
  type: EDIT_PROJECT_TEAM,
  payload: {
    projectId,
    teamId,
    team,
  },
});

export const deleteProjectTeam = (projectId, teamId) => ({
  type: DELETE_PROJECT_TEAM,
  payload: {
    projectId,
    teamId,
  },
});

export const resetProjectTeam = () => ({
  type: RESET_PROJECT_TEAMS,
});

export const getShadowingMilestones = (statementOfWorkId, projectId) => ({
  type: GET_SHADOWING_MILESTONES_REQUEST,
  payload: {
    statementOfWorkId,
    projectId,
  },
});

export const getShadowingMilestonesSuccess = (shadowingMilestones) => ({
  type: GET_SHADOWING_MILESTONES_SUCCESS,
  payload: shadowingMilestones,
});

export const resetShadowingMilestones = () => ({
  type: RESET_SHADOWING_MILESTONES,
});

export const updateFiscalYears = (payload) => ({
  type: UPDATE_FISCAL_YEARS,
  payload,
});
