import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import http from 'services/http';
import {
  setBenchReports,
  GET_BENCH_REPORTS,
} from '../actions/benchActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { setFiltersWorkforce } from '../actions/workforceActions';

function* getBenchReports() {
  try {
    yield put(startRequest());
    yield getFilters();
    const benchReports = yield call(http, {
      method: 'GET',
      url: `bench_summary-reports`,
    });
    yield put(setBenchReports(benchReports.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* getFilters() {
  try {
    const billabilitystatuses = yield call(http, {
      method: 'GET',
      url: 'role-billability-statuses',
    });
    const assignmenttypes = yield call(http, {
      method: 'GET',
      url: 'role-assignment-types',
    });
    const result = {
      billableStatus: billabilitystatuses.data,
      assignmentTypes: assignmenttypes.data,
    };
    yield put(setFiltersWorkforce(result));
  } catch (e) {
    // error message
  }
}

export default function* reportsBenchSaga() {
  yield takeEvery(GET_BENCH_REPORTS, getBenchReports);
}
