import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomIcon from '../../icon/Icon';
import { ACTIVE, FINISHED } from 'utils/const-variable';

export class CellTimeleft extends Component {
  isExpired = () => {
    const { item, config } = this.props;
    const timeLeft = config.EndDate.daysToExpire;
    const isEnded = item.EndDate
      && (moment(item.EndDate).diff(new Date(), 'day') < timeLeft);

    if (item.Status) {
      if (item.Status.Name === FINISHED || item.Status === FINISHED) {
        return false;
      }

      return isEnded && (item.Status.Name === ACTIVE || item.Status === ACTIVE) || item.ProjectExpiration;
    }
    return isEnded || item.ProjectExpiration;
  }

  render() {
    const {
      item, column,
    } = this.props;
    const date = moment(item[column.Id]).format('MM/DD/YYYY') !== 'Invalid date' ? moment(item[column.Id]).format('MM/DD/YYYY') : null;
    return (
      <div
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col', 'end-date-col-with-warn')}
      >
        <div className='column_custom-cell'>
          <span
            title={date}
            className={
              classNames(
                'col-span cell_column-orient',
                {
                  'col-span_custom-cell': this.isExpired(),
                },
              )
            }
          >
            {date}
          </span>
          <div>
            {this.isExpired()
              ? <CustomIcon iconName='red-clock' className='custom-cell_clock' />
              : null}
          </div>
        </div>
      </div>
    );
  }
}

CellTimeleft.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
