import React, { useState, useEffect, useRef } from 'react';
import CustomIcon from 'common/components/icon/Icon';

export default ({ className, textForTooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef();

  const onClickOutTooltip = e => !tooltipRef.current.contains(e.target) && setShowTooltip(false);
  const onToggleShowTooltip = () => { setShowTooltip(!showTooltip); };

  useEffect(() => {
    document.addEventListener('click', onClickOutTooltip);
    return () => document.removeEventListener('click', onClickOutTooltip);
  }, []);

  return (
    <div ref={tooltipRef} className={className}>
      <CustomIcon iconName='question-mark' onClick={onToggleShowTooltip} />
      {showTooltip && <div>{textForTooltip}</div>}
    </div>
  );
};
