export const GET_MANAGE_LOCATIONS = '@admin/GET_MANAGE_LOCATIONS';
export const SET_MANAGE_LOCATIONS = '@admin/SET_MANAGE_LOCATIONS';
export const PUT_MANAGE_LOCATIONS = '@admin/PUT_MANAGE_LOCATIONS';
export const POST_MANAGE_LOCATIONS = '@admin/POST_MANAGE_LOCATIONS';

export const downloadManageLocations = () => ({
  type: GET_MANAGE_LOCATIONS,
});

export const setManagesLocations = (locations) => ({
  type: SET_MANAGE_LOCATIONS,
  payload: locations,
});

export const putManageLocations = (location) => ({
  type: PUT_MANAGE_LOCATIONS,
  payload: location,
});

export const postManageLocations = (newlocation) => ({
  type: POST_MANAGE_LOCATIONS,
  payload: newlocation,
});
