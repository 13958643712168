export const SHOW_MODAL = '@roleModal/SHOW_MODAL';
export const CLOSE_MODAL = '@roleModal/CLOSE_MODAL';
// export const APPLY_MODAL = '@roleModal/APPLY_MODAL';
export const SET_ROLES = '@roleModal/SET_ROLES';
export const ADD_ROLE_TO_PROJECT = '@roleModal/ADD_ROLE_TO_PROJECT';
// export const EDIT_ROLE = '@roleModal/SET_ROLES';
export const GET_EMPLOYEES = '@roleModal/GET_EMPLOYEES';
export const GET_PROJECT_EMPLOYEES = '@roleModal/GET_PROJECT_EMPLOYEES';
export const SET_EMPLOYEES = '@roleModal/SET_EMPLOYEES';
export const RESET_ROLES = '@roleModal/RESET_ROLES';
export const SET_STATUS = '@roleModal/SET_STATUS';
export const SET_EMPLOYEE_STATUS = '@roleModal/SET_EMPLOYEE_STATUS';
export const GET_STATUS = '@roleModal/GET_STATUS';
export const GET_EMPLOYEE_STATUS = '@roleModal/GET_EMPLOYEE_STATUS';
export const PUT_ROLE = '@roleModal/PUT_ROLE';
export const GET_EDIT_ASSIGNMENT = '@roleModal/GET_EDIT_ASSIGNMENT';
export const PUT_EDIT_ASSIGNMENT = '@roleModal/PUT_EDIT_ASSIGNMENT';
export const GET_RATE_UNITS = '@roleModal/GET_RATE_UNITS';
export const SET_RATE_UNITS = '@roleModal/SET_RATE_UNITS';
export const SET_PROJECT_ID_FOR_ROLE = '@roleModal/SET_PROJECT_ID_FOR_ROLE';
export const SET_BILLING_DATA = '@roleModal/SET_BILLING_DATA';
export const TOGGLE_ASSIGNMENTS_STATUS = '@roleModal/TOGGLE_ASSIGNMENTS_STATUS';

export const setProjectId = (id) => ({
  type: SET_PROJECT_ID_FOR_ROLE,
  payload: id,
});

export const showModal = (roleEmployees = [], billingData = {}, roleModalAction, projectId) => ({
  type: SHOW_MODAL,
  payload: {
    isVisible: false,
    roleEmployees,
    billingData,
    roleModalAction,
    projectId,
  },
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
  payload: {
    isVisible: false,
    roleEmployees: [],
    billingCodes: [],
  },
});

export const setRoles = (roles) => ({
  type: SET_ROLES,
  payload: {
    roles,
    isVisible: false,
  },
});

export const addRoleToProject = (role, isOnlyActive) => ({
  type: ADD_ROLE_TO_PROJECT,
  payload: {
    role,
    isVisible: false,
    isOnlyActive,
  },
});

export const resetRoles = () => ({
  type: RESET_ROLES,
  payload: {
    roles: [],
  },
});

export const getEmployees = (offices, isReduced = false, statuses = null) => ({
  type: GET_EMPLOYEES,
  payload: {
    offices,
    isReduced,
    statuses,
  },
});

export const getProjectEmployees = (projectId) => ({
  type:GET_PROJECT_EMPLOYEES,
  payload: {
    projectId,
  },
});

export const setEmployees = (employees) => ({
  type: SET_EMPLOYEES,
  payload: {
    employees,
  },
});

export const setStatus = (status) => ({
  type: SET_STATUS,
  payload: status,
});

export const setEmployeeStatus = (emplpoyeeStatus) => ({
  type: SET_EMPLOYEE_STATUS,
  payload: emplpoyeeStatus,
});

export const getStatus = () => ({
  type: GET_STATUS,
});

export const getEmployeeStatus = () => ({
  type: GET_EMPLOYEE_STATUS,
});

export const editRole = (role, isOnlyActive) => ({
  type: PUT_ROLE,
  payload: {
    role,
    isVisible: false,
    isOnlyActive,
  },
});

export const getEditAssignment = (assignment) => ({
  type: GET_EDIT_ASSIGNMENT,
  payload: assignment,
});

export const updateEditAssignment = (assignment, isOnlyActive) => ({
  type: PUT_EDIT_ASSIGNMENT,
  payload: assignment,
  isOnlyActive,
});

export const getRateUnits = () => ({
  type: GET_RATE_UNITS,
});

export const setRateUnits = (rateUnits) => ({
  type: SET_RATE_UNITS,
  payload: rateUnits,
});

export const setBillingData = (billingCodes, billingCodesStartDate, billingCodesEndDate) => ({
  type: SET_BILLING_DATA,
  payload: { billingCodes, billingCodesStartDate, billingCodesEndDate },
});

export const toggleAssignmentsStatus = () => ({
  type: TOGGLE_ASSIGNMENTS_STATUS,
});
