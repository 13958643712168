import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { List } from 'common/components/list/list';
import { UpdateManageDateItem } from 'common/components/documents-list/update-manage-date-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { CellLabel } from 'common/components/list/custom-cell/cell-label';
import { CellDatePicker } from 'common/components/list/custom-cell/cell-toogle-datepicker';
import {
  downloadManageApiTokens,
  putManageApiTokens,
  postManageApiTokens,
} from '../../actions/adminManageApiTokens';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageApiTokens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Api token', Id: 'Token', className: 'without-width-col',
        },
        {
          Name: 'Expiration date', Id: 'ExpirationDate', className: 'biggest-col',
        },
      ],
      showAddApiTokenFields: false,
      addApiToken: { Date: '' },
      blockAddApiToken: false,
      blockSaveToken: false,
    };
  }

  componentDidMount() {
    const { downloadManageApiTokens } = this.props;
    downloadManageApiTokens();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  }

  addNewApiToken = () => {
    const { blockAddApiToken } = this.state;
    if (!blockAddApiToken) {
      this.setState({
        showAddApiTokenFields: true,
      });
    }
  }

  onAddClick = () => {
    this.setState({
      showAddApiTokenFields: true,
    });
  }

  copyToClipboard = (id) => {
    const { apiTokens } = this.props;
    const token = apiTokens.find(x => x.Id === id);
    navigator.clipboard.writeText(token ? token.Token : '');
  }

  getNewApiToken = (target) => {
    const { addApiToken } = this.state;
    this.setState({
      addApiToken: {
        ...addApiToken,
        Date: target,
        ExpirationDate: target.split(' ')[0],
      },
    }, this.newApiTokenValidation);
  }

  newApiTokenValidation = () => {
    const { addApiToken } = this.state;
    this.setState({
      blockSaveToken: !!addApiToken.ExpirationDate.trim(),
      blockAddApiToken: !!addApiToken.ExpirationDate.trim(),
    });
  }

  newTokenCancel = () => {
    this.setState({
      addApiToken: { ExpirationDate: '' },
      showAddApiTokenFields: false,
      blockSaveToken: false,
      blockAddApiToken: false,
    });
  }

  newJobTitleApply = () => {
    const { postManageApiTokens } = this.props;
    const { blockSaveToken, addApiToken } = this.state;
    if (blockSaveToken) {
      postManageApiTokens(addApiToken);
      this.setState({
        addJobTitle: { ExpirationDate: '' },
        showAddApiTokenFields: false,
        blockSaveToken: false,
        blockAddApiToken: false,
      });
    }
  }

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `auth/api_tokens/${element.item.Id}`,
      searchUpdateSaga: 'api_tokens',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageApiTokens } = this.props;
    putManageApiTokens(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  }

  render() {
    const {
      listSettings,
      apiTokens,
    } = this.props;
    const {
      columnsToDisplay, showAddApiTokenFields, addApiToken, blockAddApiToken,
    } = this.state;
    const {
      searchValue,
      sortColumn,
      sortDirection,
    } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('manageApiTokens')} />
        <ListToolbar
          onSearchClick={this.handleSearch}
          searchValue={searchValue}
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewApiToken}
          searchPlaceholder='Search API token'
        />
        <List
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(apiTokens, sortColumn, sortDirection), searchValue)}
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          hideAddNewEntity
          onEditClick={this.copyToClipboard}
          editSpan='Copy to clipboard'
          onDeleteClick={this.getElementForDelete}
          isHaveRights
          config={{
            'Token': {
              cell: CellLabel,
            },
            'ExpirationDate': {
              cell: CellDatePicker,
              valueSetter: this.editEntity,
              format: 'DD-MM-YYYY',
            },
          }}
        />
        {showAddApiTokenFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageDateItem
              item={addApiToken}
              onChangeNewDocumentObj={this.getNewApiToken}
              disableSaveDocument={blockAddApiToken}
              newDocumentCancel={this.newTokenCancel}
              newDocumentApply={this.newJobTitleApply}
            />
          </div>
        </div> : null}
      </div>
    );
  }
}

ManageApiTokens.propTypes = {
  downloadManageApiTokens: PropTypes.func.isRequired,
  putManageApiTokens: PropTypes.func.isRequired,
  postManageApiTokens: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  apiTokens: store.adminManageApiTokens.apiTokens,
  listSettings: store.adminManageApiTokens.listSettings,
}), {
  downloadManageApiTokens,
  putManageApiTokens,
  postManageApiTokens,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageApiTokens);
