/* eslint-disable indent */
import React from 'react';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import moment from 'moment';
import { svgEditPencil } from 'services/svgEditPencil';
import classNames from 'classnames';
import '../../redux-form.css';
import { ConfirmationDialog } from '../../../confirmation-dialog/confirmation-dialog';

export default class renderTogglingDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      showConfirmationDialog: false,
    };
    this.inputDate = '';
  }

  toggleEditMode = (editMode) => this.setState({ editMode });

  onInputChange = (e) => this.inputDate = e.target.value

  onChange = data => {
    const { input } = this.props;
    const value = typeof data === 'string' || data === null ? data : data.target.value;
    const timeValue = moment(value);

    if (timeValue.isValid() && timeValue.format('MM/DD/YYY') !== moment(input.value).format('MM/DD/YYY')) {
      input.onChange(value);
    }
    input.onBlur();
    this.toggleEditMode(false);
    this.inputDate = '';
  }

  onBlur = data => {
    const { input, isStartDate, isEndDate } = this.props;
    const value = typeof data === 'string' || data === null ? data : data.target.value;
    const timeValue = moment(value);

    if (timeValue.isValid() && timeValue.format('MM/DD/YYY') !== moment(input.value).format('MM/DD/YYY')) {
      if (isStartDate) {
        input.onChange(timeValue.set({ 'hour': 8, 'minute': 0 }).format('YYYY-MM-DD HH:mm'));
      } else if (isEndDate) {
        input.onChange(timeValue.set({ 'hour': 12, 'minute': 0 }).format('YYYY-MM-DD HH:mm'));
      } else {
        input.onChange(timeValue.format('YYYY-MM-DD HH:mm'));
      }
    }
    input.onBlur();
    this.toggleEditMode(false);
  }

  clearValue = () => {
    const { input } = this.props;
    input.onChange(null);
    this.setState({
      editMode: false,
      showConfirmationDialog: false,
    });
  }

  showClearConfirmation = () => {
    this.setState({
      showConfirmationDialog: true,
    });
  }

  closeDialog = () => {
    this.setState({
      showConfirmationDialog: false,
    });
  }

  render() {
    const { editMode, showConfirmationDialog } = this.state;
    const {
      input, meta, isEditable, label, isStartDate, isEndDate,
      setPrevValueOnError, styles, showClear, minDate, maxDate, showAsterix,
      showAsterixOnFocus, showValueRequired, labelIcon, isClearable, confirmClearing,
      isSaved = true,
    } = this.props;
    const isAsterixVisible = showAsterixOnFocus ? (editMode && isEditable) : showAsterix;
    const isValueRequiredVisible = showValueRequired ? meta.invalid : (meta.invalid && meta.error !== 'Value required');

    return (
      <div
        className={styles.container}
        onMouseLeave={() => this.onChange(this.inputDate)}
      >
        {showConfirmationDialog && <ConfirmationDialog
          dialogHeader='CLEAR'
          dialogTitle='Are you sure to clear leaving notice date?'
          closeButtonTitle='Cancel'
          confirmButtonTitle='Clear'
          closeDialog={this.closeDialog}
          confirmDialog={this.clearValue}
        />}
        {
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          label && <label className={styles.label} onClick={() => this.toggleEditMode(false)}>
            <span>{label} {labelIcon && labelIcon()}</span>
            {
              isAsterixVisible && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          {
            editMode && isEditable
              ? <DatePicker
                  datePickerClassName='project-detail__date-picker'
                  value={input.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onClose={() => this.toggleEditMode(false)}
                  isStartDate={isStartDate}
                  isEndDate={isEndDate}
                  setPrevValueOnError={setPrevValueOnError}
                  clearValue={showClear
                    ? confirmClearing
                      ? this.showClearConfirmation
                      : this.clearValue
                    : false}
                  minVal={minDate}
                  maxVal={maxDate}
                  onInputChange={this.onInputChange}
                  isClearable={isClearable}
                  confirmClearing={confirmClearing}
              /> : (
                <div className={styles.fieldView}>
                  <div
                    onClick={() => this.toggleEditMode(true)}
                    className={classNames(
                      styles.fieldContent,
                      { 'field--editable': isEditable },
                      { 'field--invalid': isValueRequiredVisible || !isSaved },
                    )}
                  >
                    <span>{moment(input.value).isValid() ? moment(input.value).format('MM/DD/YYYY') : 'MM/DD/YYYY'}</span>
                    {isEditable && svgEditPencil(input.name)}
                  </div>
                  {
                    isValueRequiredVisible && (
                      <div className={styles.fieldError}>
                        <span>{meta.error}</span>
                      </div>
                    )
                  }
                  {
                    !isSaved && (
                      <div className={styles.fieldError}>
                        <span>Value wasn't saved</span>
                      </div>
                    )
                  }
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
