import React from 'react';
import { connect } from 'react-redux';
import Select from 'common/components/form-items/select/select';
import MultiSuggestBox from 'common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import Modal from 'common/components/modal/modal';
import {
  closeSkillsModal,
  postMainSkills,
} from '../../actions/membersActions';
import sortArrayByPriority from 'utils/sortArrayByPriority';

import classNames from 'classnames';
import CustomIcon from 'common/components/icon/Icon.js';
import './member-skills-modal.css';

class EmployeeSkillsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SkillLevelId: '',
      skills: [],
      choosedSkills: [],
      options: [],
      isModalFieldsRequired: false,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.employeeSkills && props.employeeSkills.length) {
      return {
        options: props.employeeSkills.map(item => ({
          label: item.Name,
          value: item.Name,
          valueId: item.Id,
        })),
      };
    }
    return null;
  }

  verifyRequiredFields = () => {
    const { SkillLevelId, skills } = this.state;
    this.setState({ isModalFieldsRequired: SkillLevelId !== '' && !!skills.length ? true : false });
  }

  onChangeLevel = ({ target }) => {
    this.setState({
      SkillLevelId: target.value,
    }, () => this.verifyRequiredFields());
  }

  onAddSkill = (values) => {
    this.setState({
      skills: values,
    }, () => this.verifyRequiredFields());
  }

  onApply = () => {
    const { skills, SkillLevelId } = this.state;
    const { postMainSkills, employee } = this.props;
    const mainSkills = skills.map(item => ({
      employeeId: employee.Id,
      skillId: item.valueId,
      skillName: item.label,
      SkillLevelId,
    }));
    postMainSkills(mainSkills);
  }

  clearValue = (inputStateName) => {
    this.setState({
      [inputStateName]: '',
    });
  };

  sortSelectLevel = (sortArrey) => {
    let sortOrder = 1;
    if (sortArrey[0] === '-') {
      sortOrder = -1;
      sortArrey = sortArrey.substr(1);
    }
    return (prev, next) => {
      const result = (prev[sortArrey] < next[sortArrey]) ? -1 : (prev[sortArrey] > next[sortArrey]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  render() {
    const { closeSkillsModal, employeeSkillLevels, skillError } = this.props;
    const { SkillLevelId, options, isModalFieldsRequired } = this.state;
    return (
      <Modal>
        <div className='employee-skills-modal__container'>
          <div className='employee-skills-modal__content'>
            <div className='employee-skills-modal__title'>
              <span>ADD  SKILLS</span>
              <div className='employee-skills-modal__icon-cross' onClick={closeSkillsModal}>
                <CustomIcon iconName='clear-member-skill' />
              </div>
            </div>
            <div className='employee-skils-modal__fields'>
              <Select
                formClassName='step__form employee-skils-modal-option__select'
                selectClassName='step__select'
                optionsClassName='option'
                label='Level'
                value={SkillLevelId.toString()}
                placeholder='Choose Level'
                onChange={this.onChangeLevel}
                selectOptions={sortArrayByPriority(employeeSkillLevels)}
                isOptionObject
                inputProps={{
                  name: 'SkillLevelId',
                  id: 'SkillLevelId',
                }}
              />
              <div className='add-skills-MultiSuggestBox-position'>
                <MultiSuggestBox
                  onAdd={this.onAddSkill}
                  label='Skills'
                  placeholder='Add Skills'
                  options={options}
                  isMulti
                />
              </div>
            </div>
            <div className='skill_error-container'>
              <span>{skillError}</span>
            </div>
            <div className='employee-skills-modal__buttons'>
              <div className='button cancel' onClick={closeSkillsModal}>
                <span>Cancel</span>
              </div>
              <div
                className={classNames('button next',
                  { 'btn-disabled': !isModalFieldsRequired })}
                onClick={this.onApply}
              >
                <span>Apply</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(store => ({
  employeeSkills: store.membersReducer.employeeSkills,
  employeeSkillLevels: store.membersReducer.employeeSkillLevels,
  employee: store.membersReducer.employee,
  skillError: store.membersReducer.skillError,
}), {
  closeSkillsModal,
  postMainSkills,
})(EmployeeSkillsModal);
