import React from 'react';
import SearchDropdown from 'common/components/form-items/search-dropdown/search-dropdown';
import { Badge } from 'common/components/badges/badges';
import { getColorFromString } from 'services/getColor';
import { svgEditPencil } from 'services/svgEditPencil';
import classNames from 'classnames';
import '../../redux-form.css';
import { Tooltip, Typography } from '@material-ui/core';
import CustomIcon from '../../../icon/Icon';

export default class renderTogglingSearchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  toggleEditMode = (editMode) => this.setState({ editMode });

  onChange = (data) => {
    const { input, additionalValidator } = this.props;
    if (data) {
      additionalValidator && additionalValidator(data.value);
      input.onChange(data.value);
    } else {
      input.onChange({ Id: '', Name: '' });
    }
    this.toggleEditMode(false);
    input.onBlur();
  }

  onBlur = () => {
    const { input } = this.props;
    this.toggleEditMode(false);
    input.onBlur();
  }

  render() {
    const { editMode } = this.state;
    const {
      input, meta, valueToShow, options, isEditable, label, autoFocus, styles,
      inputProps, showClear, isAddingFeature, parentEntityId, isOnlyLiteralValues,
      customMassageValidation, dontShowBage, showAsterix, showAsterixOnFocus,
      showValueRequired, isClientManager, showInfoTooltip, showInfoTooltipMessage,
      useVirtualSelect, isSaved = true, hideOnMouseLeave = true,
    } = this.props;

    const isAsterixVisible = showAsterixOnFocus ? (editMode && isEditable) : showAsterix;
    const isValueRequiredVisible = showValueRequired ? meta.invalid : (meta.invalid && meta.error !== 'Value required');

    return (
      <div
        className={styles.container}
        onMouseLeave={hideOnMouseLeave ? this.onBlur : null}
      >
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              isAsterixVisible && <span className='redux-form__required'> *</span>
            }
            {
              showInfoTooltip && !isAsterixVisible
              && <Tooltip title={<Typography>{showInfoTooltipMessage}</Typography>}>
                <div className='info-tooltip'>
                  <CustomIcon iconName='question-mark' />
                </div>
              </Tooltip>
            }
          </label>
        }
        <div className={styles.field}>
          {
            editMode && isEditable
              ? (
                <SearchDropdown
                  formClassName='field__search-dropdown-form'
                  value={typeof input.value === 'object' ? input.value.Id : input.value}
                  name={input.name}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  options={options}
                  showClear={showClear}
                  inputProps={inputProps}
                  isAddingFeature={isAddingFeature}
                  autoFocus={autoFocus}
                  parentEntityId={parentEntityId}
                  isOnlyLiteralValues={isOnlyLiteralValues}
                  isClientManager={isClientManager}
                  useVirtualSelect={useVirtualSelect}
                />
              ) : (
                <div className={styles.fieldView}>
                  <div
                    onClick={() => this.toggleEditMode(true)}
                    className={classNames(
                      styles.fieldContent,
                      { 'field--editable': isEditable },
                      { 'field--invalid': isValueRequiredVisible || !isSaved },
                    )}
                  >
                    {
                      !dontShowBage && valueToShow && <Badge
                        item={valueToShow}
                        color={getColorFromString(valueToShow)}
                      />
                    }
                    <span>{valueToShow}</span>
                    {isEditable && svgEditPencil(input.name)}
                  </div>
                  {
                    isValueRequiredVisible && (
                      <div className={styles.fieldError}>
                        <span>{meta.error}</span>
                      </div>
                    )
                  }
                  {
                    !isSaved && (
                      <div className={styles.fieldError}>
                        <span>Value wasn't saved</span>
                      </div>
                    )
                  }
                  {customMassageValidation ? (
                    <span className='custom_validation-form_multisuggest'>
                      Incorrect Managers by office
                    </span>
                  ) : null}
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
