import { SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE } from '../actions/loginActions';

const initialState = {
  isLoading: false,
  error: false,
  message: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN_FAILURE:
    case SIGNIN_REQUEST:
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        error: action.payload.error,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
