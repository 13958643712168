import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from 'common/components/form-items/input/text-field';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import Button from 'common/components/redux-form/components/button';
import CustomIcon from 'common/components/icon/Icon';
import Checkbox from 'common/components/form-items/checkbox/checkbox';

class BackgroundCheckModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Date: null,
      ExpirationDate: null,
      Request: '',
      IsNotExpiring: false,
      isErrorForm: true,
    };
  }

  componentDidMount() {
    this.setDefaultEditData();
  }

  componentDidUpdate(prevProps) {
    const { editBackgroundCheckObject } = this.props;
    if (prevProps.editBackgroundCheckObject !== editBackgroundCheckObject) {
      this.setDefaultEditData();
    }
  }

  setDefaultEditData = () => {
    const { editBackgroundCheckObject, isEditMode } = this.props;
    if (isEditMode) {
      this.setState({
        Date: editBackgroundCheckObject.Date,
        ExpirationDate: editBackgroundCheckObject.ExpirationDate,
        Request: editBackgroundCheckObject.Request,
        IsNotExpiring: editBackgroundCheckObject.IsNotExpiring,
      }, this.checkForm);
    }
  }

  checkForm = () => {
    const {
      Date, ExpirationDate, IsNotExpiring, Request,
    } = this.state;
    const checkDate = moment(moment(Date).add(-1, 'day')).isBefore(moment(ExpirationDate));
    const isRequestIncorrect = Request === null || (typeof Request === 'string' && Request.trim().length === 0);
    this.setState({
      isErrorForm: !(Date !== null && (ExpirationDate !== null || checkDate || IsNotExpiring) && !isRequestIncorrect),
    });
  }

  confirmAddBackgroundCheck = () => {
    const { confirmDialog, closeDialog, isEditMode } = this.props;
    const {
      isErrorForm, Date, ExpirationDate, Request, IsNotExpiring,
    } = this.state;
    if (!isErrorForm) {
      confirmDialog({
        Date, ExpirationDate, Request, IsNotExpiring,
      }, isEditMode);
      closeDialog();
    }
  }

  handleChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, this.checkForm);
  }

  handleChangeDate = (date, name) => {
    if (name) {
      if (name === 'BackgroundCheckDate' && date === null) {
        this.setState({
          ExpirationDate: null,
        });
      }
      this.setState({
        [name]: date,
      }, this.checkForm);
    }
  }

  handleChangeIsNotExpiring = () => {
    const { IsNotExpiring } = this.state;
    this.setState({
      IsNotExpiring: !IsNotExpiring,
      ExpirationDate: null,
    }, this.checkForm);
  }

  setIsError = () => {
    this.setState({ isErrorForm: true });
  }

  render() {
    const { closeDialog, isEditMode } = this.props;
    const {
      Date, ExpirationDate, Request, IsNotExpiring, isErrorForm,
    } = this.state;

    const minBackgroundCheckDate = moment('01/01/2000', 'DD/MM/YYYY');
    const maxBackgroundCheckExpirationDate = moment('31/12/2050', 'DD/MM/YYYY');

    return (
      <Dialog
        open
        aria-labelledby='responsive-dialog-title'
        className='certification__dialog-width'
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>{!isEditMode ? 'Add Background Check' : 'Edit Background Check'}</span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className='background-check-modal-row'>
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='background-check-step__select background-check-step__text'
              placeholder='Date'
              label='Date'
              value={Date}
              onChange={this.handleChangeDate}
              minVal={minBackgroundCheckDate}
              isRequired
              name='Date'
              isStartDate
              onError={this.checkForm}
            />
            <Checkbox
              checkboxContainerStyle='background-check-step__checkbox margin-left-10'
              label='Is Not Expiring'
              value='Is Not Expiring'
              checked={IsNotExpiring}
              name='IsNotExpiring'
              onClick={this.handleChangeIsNotExpiring}
            />
          </div>
          <div className='background-check-modal-row'>
            <TextField
              formClassName='step__form'
              inputClassName='background-check-step__select background-check-step__text'
              id='Request'
              placeholder='BGC Request'
              label='BGC Request'
              maxlength='30'
              name='Request'
              value={Request}
              onChange={this.handleChangeData}
              isRequired
            />
            {
              !IsNotExpiring && <DatePicker
                formClassName='step__form svg-calendar-add-info margin-left-10'
                datePickerClassName='background-check-step__select background-check-step__text'
                placeholder='Expiration Date'
                label='Expiration Date'
                value={ExpirationDate}
                onChange={this.handleChangeDate}
                disabled={Date === null}
                minVal={Date}
                maxVal={maxBackgroundCheckExpirationDate}
                isRequired={!IsNotExpiring}
                name='ExpirationDate'
                isStartDate
                onError={this.setIsError}
                onBlur={this.handleChangeDate}
              />
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            className='button cancel background-check-dialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmAddBackgroundCheck}
            className={`button ${!isErrorForm ? 'next' : 'btn-disabled'} background-check-dialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

BackgroundCheckModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
  isEditMode: PropTypes.bool,
};

export default BackgroundCheckModal;
