export const GET_ASSIGNMENT_TYPES = '@admin/GET_ASSIGNMENT_TYPES';
export const SET_ASSIGNMENT_TYPES = '@admin/SET_ASSIGNMENT_TYPES';
export const PUT_ASSIGNMENT_TYPES = '@admin/PUT_ASSIGNMENT_TYPES';
export const POST_ASSIGNMENT_TYPES = '@admin/POST_ASSIGNMENT_TYPES';

export const loadAssignmentTypes = () => ({
  type: GET_ASSIGNMENT_TYPES,
});

export const setAssignmentTypes = (assignmentTypes) => ({
  type: SET_ASSIGNMENT_TYPES,
  payload: assignmentTypes,
});

export const updateAssignmentTypes = (assignmentTypes) => ({
  type: PUT_ASSIGNMENT_TYPES,
  payload: assignmentTypes,
});

export const createAssignmentTypes = (assignmentTypes) => ({
  type: POST_ASSIGNMENT_TYPES,
  payload: assignmentTypes,
});
