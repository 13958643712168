import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_ENGAGEMENT_TYPES,
  PUT_MANAGE_ENGAGEMENT_TYPES,
  POST_MANAGE_ENGAGEMENT_TYPES,
  setManageEngagementTypes,
} from '../actions/adminManageEngagementTypes';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageEngagementTypes() {
  try {
    yield put(startRequest());
    const engagementTypes = yield call(http, {
      method: 'GET',
      url: 'engagement_types',
    });

    yield put(setManageEngagementTypes(engagementTypes.data));
  } catch (e) {
    // error message
  } finally {
    yield put(finishRequest());
  }
}

export function* putEngagementType(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: 'engagement_types',
      data: action.payload,
    });
    yield call(manageEngagementTypes);
  } catch (e) {
    // error message
  }
}

export function* postEngagementType(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'engagement_types',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageEngagementTypes);
  } catch (e) {
    // error message
  }
}

export default function* adminManageEngagementTypes() {
  yield takeEvery(GET_MANAGE_ENGAGEMENT_TYPES, manageEngagementTypes);
  yield takeEvery(PUT_MANAGE_ENGAGEMENT_TYPES, putEngagementType);
  yield takeEvery(POST_MANAGE_ENGAGEMENT_TYPES, postEngagementType);
}
