import { SET_ROLES } from '../actions/userRoleActions';
import {
  ADMIN_ROLE_NAME,
} from 'utils/const-variable';

const initialState = {
  roles: [],
  isAdmin: false,
};

export default function userRoleReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
        isAdmin: action.payload.roles.includes(ADMIN_ROLE_NAME),
      };
    default:
      return state;
  }
}
