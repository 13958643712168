import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_CERTIFICATES,
  PUT_MANAGE_CERTIFICATES,
  POST_MANAGE_CERTIFICATES,
  setManageCertificates,
} from '../actions/adminCertificatesActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageCertificates() {
  try {
    yield put(startRequest());
    const cartificates = yield call(http, {
      method: 'GET',
      url: 'certificates',
    });

    yield put(setManageCertificates(cartificates.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putCertificates(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `certificates/${action.payload.Id}`,
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageCertificates);
  } catch (e) {
    // error message
  }
}

export function* postCertificates(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'certificates',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageCertificates);
  } catch (e) {
    // error message
  }
}

export default function* adminManageCertificatesSagas() {
  yield takeEvery(GET_MANAGE_CERTIFICATES, manageCertificates);
  yield takeEvery(PUT_MANAGE_CERTIFICATES, putCertificates);
  yield takeEvery(POST_MANAGE_CERTIFICATES, postCertificates);
}
