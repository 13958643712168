import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TextField from 'common/components/form-items/input/text-field';
import MultiSuggestBox from 'common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import SearchDropdown from 'common/components/form-items/search-dropdown/search-dropdown';
import { List } from 'common/components/list/list';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import { applicationTitle } from 'utils/applicationTitle';
import DocumentTitle from 'react-document-title';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import Scrollbars from 'react-custom-scrollbars';
import { CellDelete } from 'common/components/list/custom-cell/cell-delete';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellTimeleft } from 'common/components/list/custom-cell/cell-timeleft';
import Button from 'common/components/redux-form/components/button';
import history from 'utils/history';
import {
  getRoles, createProjectTeam, editProjectTeam,
} from 'pages/projects/actions/projectsActions';
import './team-page.css';

const columnsToDisplay = [{
  Name: 'Full name', Id: 'Name', deleteArrow: 'delete-arrow', hasBadge: true, className: 'medium-col',
}, {
  Name: 'Office', Id: 'Office', deleteArrow: 'delete-arrow', isObject: true, className: 'medium-col',
}, {
  Name: 'StartDate', Id: 'StartDate', deleteArrow: 'delete-arrow', isDate: true,
}, {
  Name: 'EndDate', Id: 'EndDate', deleteArrow: 'delete-arrow', isDate: true,
}, {
  Name: 'Seniority level', Id: 'SeniorityLevel', deleteArrow: 'delete-arrow', isObject: true, className: 'small-col header-title__fixed-width',
}, {
  Name: 'Role', Id: 'EmployeeRole', deleteArrow: 'delete-arrow', isObject: true, className: 'medium-col',
}, {
  Name: 'Status', Id: 'Status', deleteArrow: 'delete-arrow', isObject: true, className: 'small-col',
}, {
  Name: '', Id: 'Actions', deleteArrow: 'delete-arrow', className: 'small-col',
}];

const validStatuses = [1, 2, 5];
const notApplicable = { Id: 'N/A', Name: 'N/A' };

class TeamPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamName: '',
      assignedEmployees: [],
      teamMembers: [],
      membersList: [],
      leadId: '',
      secondInCommandId: '',
      secondInCommandList: [],
      leadList: [],
      isDuplicateAssignmentInList: false,
      membersToDelete: [],
      showAlertDialog: false,
      teamToEditId: null,
    };
  }

  componentDidMount() {
    const {
      setComponent, projectDetails, employees, projectTeams,
    } = this.props;
    setComponent({
      returnButton: {
        text: 'Projects',
        goToPreviousPage: () => history.push({ pathname: '/projects' }),
      },
      secondReturnButton: {
        text: `${projectDetails && projectDetails.Name} details`,
        goToPreviousPage: () => history.push({ pathname: `/projects/${projectDetails.Id}` }),
      },
    });
    getRoles(projectDetails.Id, true);
    const assignedEmployees = projectDetails.Roles
    && projectDetails.Roles.reduce((acc, curr) => {
      const roleEmployees = curr.Employees
        ? curr.Employees.map(item => {
          const employee = employees.find(emp => emp.Id === item.EmployeeId);
          return {
            ...item,
            EmployeeRole: curr.Role,
            SeniorityLevel: curr.SeniorityLevel,
            Office: employee && employee.Office,
          };
        })
        : [];
      return acc.concat(roleEmployees.filter(item => (item.Status && item.Status.Name) === 'Active'));
    }, []);
    const id = history.location.pathname.split('/')[5] || null;
    const teamToEdit = id && projectTeams.find(team => team.Id === id);
    if (teamToEdit) {
      const members = teamToEdit.Members.reduce((acc, curr) => {
        const member = assignedEmployees.find(item => item.Id === curr.Id);
        member && acc.push({
          label: member.Name,
          value: member.Id,
          employeeId: member.EmployeeId,
        });
        return acc;
      }, []);
      const membersToDelete = members.reduce((acc, curr) => {
        const employee = employees.find(empl => empl.Id === curr.employeeId);
        if (employee) {
          const isStatusValid = validStatuses.some(item => item === employee.Type.Id);
          !isStatusValid && acc.push(curr.label);
        }
        return acc;
      }, []);
      const newMembersList = assignedEmployees.filter(item => members.find(member => member.value === item.Id));
      const lead = newMembersList.find(item => item.EmployeeId === (teamToEdit && teamToEdit.Lead && teamToEdit.Lead.Id));
      const sic = assignedEmployees.find(item => item.EmployeeId === (teamToEdit && teamToEdit.SecondInCommand && teamToEdit.SecondInCommand.Id));
      const sicList = lead
        ? assignedEmployees.filter(item => item.EmployeeId !== (teamToEdit && teamToEdit.Lead && teamToEdit.Lead.Id))
        : assignedEmployees;
      const leadList = sic
        ? newMembersList.filter(item => item.EmployeeId !== (teamToEdit && teamToEdit.SecondInCommand && teamToEdit.SecondInCommand.Id))
        : newMembersList;
      this.setState({
        leadId: teamToEdit.IsLeadApplicable ? lead && lead.Id : notApplicable.Id,
        teamName: teamToEdit && teamToEdit.Name,
        assignedEmployees: assignedEmployees,
        teamMembers: members,
        secondInCommandList: sicList,
        membersList: newMembersList,
        secondInCommandId: teamToEdit.IsSecondInCommandApplicable ? sic && sic.Id : notApplicable.Id,
        teamToEditId: id,
        leadList,
        membersToDelete: membersToDelete,
        showAlertDialog: !!membersToDelete.length,
      });
    } else {
      this.setState({
        assignedEmployees: assignedEmployees,
        secondInCommandList: assignedEmployees,
      });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  handleChangeTeamName = (e) => {
    const { value } = e.target;
    this.setState({
      teamName: value,
    });
  };

  сhangeMembersValue = (values) => {
    const { assignedEmployees, leadId, secondInCommandId } = this.state;
    const newMembersList = assignedEmployees.filter(item => values.find(member => member.value === item.Id));
    const duplicateAssignment = newMembersList.reduce((acc, curr, index, list) => {
      const sameEmployees = list.filter(sameCandidate => (
        sameCandidate.EmployeeId === curr.EmployeeId
        && sameCandidate.Id !== curr.Id
      ));
      return acc.concat(sameEmployees);
    }, []);
    this.setState({
      membersList: newMembersList,
      secondInCommandList: assignedEmployees.filter(item => item.Id !== leadId),
      leadList: newMembersList.filter(item => item.Id !== secondInCommandId),
      isDuplicateAssignmentInList: !!duplicateAssignment.length,
    });
  };

  handleChangeLead = (target) => {
    const value = target && target.value || '';
    const { membersList, secondInCommandId, assignedEmployees } = this.state;
    const lead = membersList.find(item => item.Id === value);
    const newSecondInCommandList = !!value
      ? (lead && lead.Id)
        ? assignedEmployees.filter(item => item.Id !== lead.Id)
        : assignedEmployees
      : assignedEmployees;
    this.setState({
      leadId: value,
      secondInCommandList: newSecondInCommandList,
      secondInCommandId: (value === secondInCommandId && value !== notApplicable.Id) ? '' : secondInCommandId,
    });
  };

  handleChangeSecondInTeam = (target) => {
    const value = target && target.value || '';
    const { membersList, assignedEmployees } = this.state;
    const sic = assignedEmployees.find(item => item.Id === value);
    const newLeadList = !!value
      ? (sic && sic.Id)
        ? membersList.filter(item => item.Id !== sic.Id)
        : membersList
      : membersList;
    this.setState({
      secondInCommandId: value,
      leadList: newLeadList,
    });
  };

  handleChangeSuggestBox = (values) => {
    const {
      leadId, secondInCommandId, assignedEmployees,
    } = this.state;
    const isLeadExist = leadId === notApplicable.Id ? true : values.some(item => item.value === leadId);
    const isSicExist = leadId === notApplicable.Id ? true : values.some(item => item.value === secondInCommandId);
    const sicList = (!values.length || !isLeadExist) ? assignedEmployees : assignedEmployees.filter(item => item.Id !== leadId);
    const leadList = (!values.length || !isSicExist) ? values : values.filter(item => item.Id !== secondInCommandId);
    this.setState({
      teamMembers: values,
      leadId: ((!values.length || !isLeadExist) && leadId !== notApplicable.Id) ? '' : leadId,
      secondInCommandList: sicList,
      leadList,
    });
  };


  handleDeleteMember = (id) => {
    const {
      membersList, teamMembers, leadId, secondInCommandId, assignedEmployees,
    } = this.state;
    const newMembersList = membersList.filter(item => item.Id !== id);
    const newTeamMembers = teamMembers.filter(item => item.value !== id);
    const isNeedToChangeLead = id === leadId;
    const sicList = isNeedToChangeLead ? assignedEmployees : assignedEmployees.filter(item => item.Id !== leadId);
    const leadList = newMembersList.filter(item => item.value !== secondInCommandId);
    this.setState({
      membersList: newMembersList,
      teamMembers: newTeamMembers,
      leadId: isNeedToChangeLead ? '' : leadId,
      secondInCommandList: sicList,
      leadList,
    });
  };

  onSubmit = () => {
    const {
      createProjectTeam, projectDetails, editProjectTeam, employees,
    } = this.props;
    const {
      teamName, leadId, secondInCommandId, teamMembers, teamToEditId, assignedEmployees,
    } = this.state;

    const membersToDelete = teamMembers.reduce((acc, curr) => {
      const employee = employees.find(empl => empl.Id === curr.employeeId);
      if (employee) {
        const isStatusValid = validStatuses.some(item => item === employee.Type.Id);
        !isStatusValid && acc.push(curr.label);
      }
      return acc;
    }, []);
    if (membersToDelete.length) {
      this.setState({
        membersToDelete,
        showAlertDialog: true,
      });
      return;
    }

    const leadEmployeeId = teamMembers.find(item => item.value === leadId);
    const sicEmployeeId = assignedEmployees.find(item => item.Id === secondInCommandId);
    const membersIds = teamMembers.map(item => item.value);
    const team = {
      Name: teamName,
      Members: membersIds,
      LeadId: leadEmployeeId && leadEmployeeId.employeeId || '',
      SecondInCommandId: secondInCommandId !== notApplicable.Id ? sicEmployeeId && sicEmployeeId.EmployeeId : '',
      IsLeadApplicable: leadId !== notApplicable.Id,
      IsSecondInCommandApplicable: secondInCommandId !== notApplicable.Id,
    };
    teamToEditId
      ? editProjectTeam(projectDetails.Id, teamToEditId, team)
      : createProjectTeam(projectDetails.Id, team);
  };

  handleCloseAlert = () => {
    this.setState({
      showAlertDialog: false,
    });
  }

  render() {
    const {
      teamName,
      leadId,
      secondInCommandId,
      membersList,
      assignedEmployees,
      teamMembers,
      isDuplicateAssignmentInList,
      secondInCommandList,
      teamToEditId,
      showAlertDialog,
      membersToDelete,
      leadList,
    } = this.state;
    const { settings } = this.props;
    const message = `Invalid employee type of ${membersToDelete.join(', ')}, please update ${membersToDelete.length > 1 ? 'them' : 'it'} to save changes`;
    return (
      <div className='team-page'>
        <DocumentTitle title={applicationTitle.getTitile(teamToEditId ? 'editTeam' : 'addTeam')} />
        <div className='form-header team-page__header'>
          <span>{teamToEditId ? 'Edit Team' : 'Add Team'}</span>
        </div>
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          {
            showAlertDialog ? (
              <AlertDialog
                dialogHeader='Warning!'
                dialogTitle={message}
                closeDialog={this.handleCloseAlert}
                confirmDialog={this.handleCloseAlert}
                confirmButtonTitle='OK'
              />
            ) : null
          }
          <div className='team-page__container'>
            <div className='team-page__content'>
              <div className='team-page__input'>
                <TextField
                  formClassName='step__form'
                  inputClassName='step__input'
                  id='teamNameInput'
                  label='Team Name'
                  placeholder='Add Name'
                  maxlength='100'
                  name='name'
                  value={teamName}
                  onChange={this.handleChangeTeamName}
                  isRequired
                />
              </div>
              <div className='team-page__input-with-error'>
                <MultiSuggestBox
                  onAdd={this.сhangeMembersValue}
                  className='team-page__members-input'
                  label='Members'
                  placeholder='Add Members'
                  options={assignedEmployees.map(item => ({
                    label: item.Name,
                    value: item.Id,
                    employeeId: item.EmployeeId,
                  }))}
                  isMulti
                  suggesValue={teamMembers}
                  handleChangeSuggestBox={this.handleChangeSuggestBox}
                  blockAddNewItem
                />
                <div className='team-page__error'>{isDuplicateAssignmentInList && 'Remove duplicate member'}</div>
              </div>
              <div className='team-page__input'>
                <SearchDropdown
                  formClassName='step__form'
                  selectClassName='step__search-dropdown'
                  label='Team Lead'
                  placeholder='Choose Team Lead'
                  value={leadId}
                  onChange={this.handleChangeLead}
                  options={[notApplicable, ...leadList]}
                  inputProps={{
                    name: 'teamLead',
                    id: 'team-page_lead',
                  }}
                  showClear
                />
                <SearchDropdown
                  formClassName='step__form'
                  selectClassName='step__search-dropdown'
                  label='Second in command'
                  placeholder='Choose Second in command'
                  value={secondInCommandId}
                  onChange={this.handleChangeSecondInTeam}
                  options={[notApplicable, ...secondInCommandList]}
                  inputProps={{
                    name: 'secondInCommand',
                    id: 'second_in_command',
                  }}
                  showClear
                />
              </div>
              <div className='page-container_for-scroll'>
                <List
                  columnsToDisplay={columnsToDisplay}
                  items={membersList}
                  hideDots
                  dontShowOptions
                  fixedHeader
                  config={{
                    Actions: {
                      cell: CellDelete,
                    },
                    EndDate: {
                      cell: CellTimeleft,
                      daysToExpire: settings.ContractFirstNotification,
                    },
                    Status: { cell: CustomStatusCell },
                  }}
                  onDeleteClick={this.handleDeleteMember}
                />
              </div>
            </div>
          </div>
        </Scrollbars>
        <div className='form-block buttons margin-down-role'>
          <div className='right-side-buttons'>
            <Button
              onClick={history.goBack}
              className='button cancel'
              text='Cancel'
            />
            <Button
              onClick={this.onSubmit}
              disabled={!teamName || isDuplicateAssignmentInList}
              className={classNames('button next', { 'btn-disabled': !teamName || isDuplicateAssignmentInList })}
              text='Apply'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((store) => ({
  settings: store.adminReducer.settings,
  projectDetails: store.projectsReducer.projectDetails,
  projectTeams: store.projectsReducer.projectTeams.Teams,
  employees: store.membersReducer.members,
}), {
  setComponent,
  resetComponent,
  getRoles,
  createProjectTeam,
  editProjectTeam,
})(TeamPage);
