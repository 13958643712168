import { reducer as formReducer } from 'redux-form';

export const reduxFormReducer = {
  form: formReducer.plugin({
    projectBasicInfoForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    projectEditSOWForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    officeBasicInfoForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    employeeBasicInfoForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    ProjectEditAssignment: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    projectEditPOForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    CreateClient: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    projectEditAmendmentForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    projectEditMSAForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    overtimeJustificationModal: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    otherJustificationModal: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    invoiceBillingModal: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
  }),
};
