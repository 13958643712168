import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from 'utils/history';
import { addNewClient } from '../../actions/clientsActions';
import ProgressBarComponent from '../../../../common/components/progress-bar/progress-bar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import './create-client.css';
import CreateClientStep1 from 'common/components/redux-form/create-client-form/create-client-form-step1';
import CreateClientStep2 from 'common/components/redux-form/create-client-form/create-client-form-step2';
import { setComponent, resetComponent } from 'common/actions/headerActions';

export class CreateClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      Contacts: [],
      contact: {
        FirstName: '',
        LastName: '',
        JobTitle: '',
        Email: '',
        Cell: '',
        Skype: '',
      },
    };
  }

  componentDidMount() {
    const { setComponent } = this.props;
    setComponent({ returnButton: { text: 'CLIENTS', goToPreviousPage: this.goToClientsList } });
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  addContacts = (data) => {
    const { Contacts, contact } = this.state;
    const clientContact = {
      ...contact,
      ...data.contact,
    };
    const addContact = Contacts;
    addContact.push(clientContact);
    this.setState({
      Contacts: addContact,
    });
  }

  removeContacts = (contacts) => {
    const { Contacts } = this.state;
    this.setState({
      Contacts: Contacts.filter(e => e !== contacts),
    });
  }

  onNextStep = () => {
    this.setState({
      step: 1,
    });
  };

  onBack = () => {
    this.setState({
      step: 0,
    });
  };

  onSave = (values) => {
    const { addNewClient, location } = this.props;
    const { Contacts } = this.state;
    const newClient = {
      Name: values.Name,
      Contacts,
    };
    addNewClient(newClient, {
      pathname: '/clients',
      state: location.state,
    });
  }

  goToClientsList = () => {
    const { location } = this.props;
    history.push({ pathname: '/clients', state: location.state });
  }

  render() {
    const { step, Contacts } = this.state;
    const { location } = this.props;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('createClient')} />
        <div className='page-toolbar'>
          <div className='page-header'>
            <span>ADD NEW CLIENT</span>
          </div>
          <ProgressBarComponent
            stepNames={[
              'COMPANY INFO',
              'CONTACTS',
            ]}
            selectedStep={step}
          />
        </div>
        {step === 0 && <CreateClientStep1
          onNextStep={this.onNextStep}
          onSubmit={this.onSave}
        />}
        {step === 1 && <CreateClientStep2
          onSubmit={this.onSave}
          onBack={this.onBack}
          addContacts={this.addContacts}
          location={location}
          contacts={Contacts}
          removeContacts={this.removeContacts}
        />}
      </div>
    );
  }
}

CreateClient.propTypes = {
  addNewClient: PropTypes.func,
};

export default connect(
  () => ({}),
  {
    addNewClient,
    setComponent,
    resetComponent,
  },
)(CreateClient);
