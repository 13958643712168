import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';

export class CellRate extends Component {
  render() {
    const { item, column, viewFinancialInfo } = this.props;
    const rate = item.Rate || item.Rate === 0
      ? getAmericanFormat(item.Rate)
      : '';
    return (
      viewFinancialInfo ? (<div
        title={rate || rate === 0 ? rate : null}
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
      >
        <span
          className='col-span cell_column-orient'
        >
          {rate || rate === 0 ? rate : '---'}
        </span>
      </div>) : <div
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
      ><span className='col-span cell_column-orient'>---</span></div>
    );
  }
}

CellRate.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  viewFinancialInfo: PropTypes.bool.isRequired,
};
