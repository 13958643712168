import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from '../../../../../utils/applicationTitle';
import ListToolbar from '../../../../../common/components/list-toolbar/list-toolbar';
import DocumentList from '../../../../../common/components/documents-list/documents-list';
import { recursiveSearch } from '../../../../../services/recursiveSearch';
import { sortList } from '../../../../../common/components/list/sortList';
import { UpdateManageItem } from '../../../../../common/components/documents-list/update-manage-item';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEmployeeTypes, postEmployeeType, putEmployeeType } from '../../../actions/adminManageEmployeeTypes';
import { showDialog } from '../../../../../common/actions/confirmationDialogActions';
import { setListSettingsLookups } from '../../../actions/adminActions';

export class ManageEmployeeTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsToDisplay: [
        {
          Name: 'Employee Types', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddEmployeeTypeFields: false,
      addEmployeeTypeObj: { Name: '' },
      blockAddEmployeeType: false,
      blockSaveEmployeeType: false,
    };
  }

  componentDidMount() {
    const { getEmployeeTypes } = this.props;
    getEmployeeTypes();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ sortColumn, sortDirection });
  };

  addNewEmployeeType = () => {
    const { blockAddEmployeeType } = this.state;
    if (!blockAddEmployeeType) {
      this.setState({
        showAddEmployeeTypeFields: true,
      });
    }
  };

  getNewEmployeeTypeObj = ({ target }) => {
    const { addEmployeeTypeObj } = this.state;
    this.setState({
      addEmployeeTypeObj: {
        ...addEmployeeTypeObj,
        [target.name]: target.value,
      },
    }, this.newEmployeeTypeValidation);
  };

  newEmployeeTypeValidation = () => {
    const { addEmployeeTypeObj } = this.state;
    this.setState({
      blockSaveEmployeeType: !!addEmployeeTypeObj.Name.trim(),
      blockAddEmployeeType: !!addEmployeeTypeObj.Name.trim(),
    });
  };

  newEmployeeTypeCancel = () => {
    this.setState({
      addEmployeeTypeObj: { Name: '' },
      blockAddEmployeeType: false,
      blockSaveEmployeeType: false,
      showAddEmployeeTypeFields: false,
    });
  };

  newEmployeeTypeApply = () => {
    const { postEmployeeType } = this.props;
    const { blockSaveEmployeeType, addEmployeeTypeObj } = this.state;
    if (blockSaveEmployeeType) {
      postEmployeeType(addEmployeeTypeObj);
      this.newEmployeeTypeCancel();
    }
  };

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `employee-types/${element.Id}`,
      searchUpdateSaga: 'employee-types',
    };
    showDialog(dataForDeleting);
  };

  editEntity = (entity) => {
    const { putEmployeeType } = this.props;
    putEmployeeType(entity);
  };

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  render() {
    const { employeeTypes, listSettings } = this.props;
    const {
      columnsToDisplay, showAddEmployeeTypeFields, addEmployeeTypeObj, blockAddEmployeeType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};

    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsEmployeeTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewEmployeeType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search Employee Types'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(employeeTypes, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.getElementForDelete}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {showAddEmployeeTypeFields && <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addEmployeeTypeObj}
              onChangeNewDocumentObj={this.getNewEmployeeTypeObj}
              disableSaveDocument={blockAddEmployeeType}
              newDocumentCancel={this.newEmployeeTypeCancel}
              newDocumentApply={this.newEmployeeTypeApply}
            />
          </div>
        </div>}
      </div>
    );
  }
}

ManageEmployeeTypes.propTypes = {
  getEmployeeTypes: PropTypes.func.isRequired,
  postEmployeeType: PropTypes.func.isRequired,
  putEmployeeType: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  employeeTypes: store.adminManageLookups.employeeTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  getEmployeeTypes,
  postEmployeeType,
  putEmployeeType,
  showDialog,
  setListSettingsLookups,
})(ManageEmployeeTypes);
