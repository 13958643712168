import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/components/tooltip/tooltip';

export class Slider extends Component {
  render() {
    const {
      onChange,
      defaultChecked,
      label,
      name = 'slider',
      className = 'checkbox_extend_container',
      index,
      htmlFor = 'checkbox_extend',
      id = 'checkbox_extend',
      isTooltip,
      textForTooltip,
      tooltipClassName,
      isChecked = defaultChecked,
    } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={className} key={index}>
          <input
            type='checkbox'
            onChange={onChange}
            className='checkbox_extend'
            id={id}
            defaultChecked={defaultChecked}
            name={name}
            checked={isChecked}
          />
          <label htmlFor={htmlFor}>{label}</label>
        </div>
        {
          isTooltip
            ? (
              <Tooltip
                textForTooltip={textForTooltip}
                className={tooltipClassName}
              />
            )
            : null
        }
      </div>
    );
  }
}

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
};
