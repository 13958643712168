import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getFilters } from 'common/actions/filtersActions';
import BillingListComponent from './components/billing-list/billing-list';
import BillingDetails from './components/billing-details/billing-details';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { resetListSettings } from './actions/billingActions';
import './billing-page.css';

const filtersNames = [
  'Offices',
  'Clients',
  'ProjectStatuses',
  'AccountManagers',
  'DeliveryManagers',
  'ClientManagers',
  'EngineeringManagers',
];

export class BillingPageComponent extends React.Component {
  componentDidMount() {
    const { getFilters } = this.props;
    getFilters(filtersNames);
  }

  componentWillUnmount() {
    const { resetListSettings } = this.props;
    resetListSettings();
  }

  getDefaultFilters = () => {
    const defaultFilters = [];
    return defaultFilters;
  }

  BillingList = (props) => {
    return (
      <BillingListComponent
        {...props}
        getDefaultFilters={this.getDefaultFilters}
      />
    );
  };

  BillingDetails = (props) => {
    return (
      <BillingDetails
        {...props}
        getDefaultFilters={this.getDefaultFilters}
      />
    );
  };

  render() {
    return (
      <DocumentTitle title={applicationTitle.getTitile('billing')}>
        <Fragment>
          <Switch>
            <Route exact path='/billing' render={this.BillingList} />
            <Route exact path='/billing/:id' render={this.BillingDetails} />
            <Redirect to='/billing' />
          </Switch>
        </Fragment>
      </DocumentTitle>
    );
  }
}

BillingPageComponent.propTypes = {
  getFilters: PropTypes.func,
};

export default connect(() => ({
}), {
  getFilters,
  resetListSettings,
})(BillingPageComponent);
