import React, { Component } from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import 'react-virtualized-select/styles.css';
import './virtualized-select.css';

export default class VirtualSelect extends Component {
  onClick = (event) => {
    const elementPositions = event.target.getBoundingClientRect();
    const windowHeight = document.documentElement.clientHeight;
    const minLimitToBottom = 200;
    if (windowHeight - elementPositions.bottom < minLimitToBottom) {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  render() {
    const {
      options, value, onChange, onBlur, disabled, isRequired, id,
    } = this.props;
    return (
      <div

        className='virtual-select'
        onClick={this.onClick}
      >
        <VirtualizedSelect
          id={id}
          options={options}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          isDisabled={disabled}
          disabled={disabled}
          isSearchable
          clearable={false}
          isRequired={isRequired}
        />
      </div>
    );
  }
}
