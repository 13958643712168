import {
  SET_WORKFORCE_LIST_SETTINGS,
  RESET_WORKFORCE_LIST_SETTINGS,
  SET_WORKFORCE_FILTERS_VALUE,
  RESET_WORKFORCE_FILTERS_VALUE, CHANGE_WORKFORCE_COLUMNS_TO_DISPLAY,
} from '../actions/workforceActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Employee',
  sortDirection: 'Up',
  filters: {},
  takeAmountReports: 50,
};

const defaultTextDataForFilters = {
  StartDate: '',
  EndDate: '',
};

export const initialState = {
  listSettings: defaultListSettings,
  textDataForFilters: defaultTextDataForFilters,
  columnsToDisplay: [
    {
      Name: 'Employee', Id: 'Employee', className: 'biggest-col', isObject: true, isSelected: true,
    },
    {
      Name: 'Office', Id: 'Office', isObject: true, isSelected: true,
    },
    {
      Name: 'Type', Id: 'Type', isObject: true, isSelected: true,
    },
    {
      Name: 'Job Title', Id: 'JobTitle', className: 'medium-col', isObject: true, isSelected: true,
    },
    {
      Name: 'Client', Id: 'Client', className: 'medium-col', isObject: true, isSelected: true,
    },
    {
      Name: 'Project', Id: 'Project', className: 'medium-col', isObject: true, isSelected: true,
    },
    {
      Name: 'Role Name', Id: 'RoleName', className: 'medium-col', isSelected: true,
    },
    {
      Name: 'Billable Status', Id: 'BillableStatus', className: 'medium-col', isObject: true, isSelected: true,
    },
    {
      Name: 'Location', Id: 'Location', className: 'medium-col', isObject: true, isSelected: true,
    },
    {
      Name: 'Assignment', Id: 'Assignment', className: 'medium-col', isSelected: true,
    },
    {
      Name: 'Assignment Start Date', Id: 'AssignmentStartDate', className: 'medium-col', isDate: true, isSelected: true,
    },
    {
      Name: 'Assignment End Date', Id: 'AssignmentEndDate', className: 'medium-col', isDate: true, isSelected: true,
    },
    {
      Name: 'Current Project Status', Id: 'CurrentProjectStatus', className: 'medium-col', isSelected: true,
    },
  ],
  innerColumnsToDisplay: [
    {
      Name: 'Company', Id: 'Company', isObject: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Project', Id: 'Project', deleteArrow: 'delete-arrow', isObject: true,
    },
    {
      Name: 'Role', Id: 'RoleType', isObject: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'RoleName', Id: 'RoleName', deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Billable', Id: 'BillableStatus', isObject: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Assignment Type', Id: 'AssignmentType', isObject: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Assignment', Id: 'Assignment', deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Start Date', Id: 'StartDate', deleteArrow: 'delete-arrow', isDate: true,
    },
    {
      Name: 'End Date', Id: 'EndDate', isDate: true, deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Status on Project', Id: 'StatusOnProject', deleteArrow: 'delete-arrow', isObject: true,
    },
  ],
};

const workforceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WORKFORCE_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_WORKFORCE_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_WORKFORCE_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: {
          ...state.textDataForFilters,
          ...action.payload,
        },
      };
    case RESET_WORKFORCE_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: defaultTextDataForFilters,
      };
    case CHANGE_WORKFORCE_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    default:
      return state;
  }
};

export default workforceReducer;
