export const SHOW_DIALOG = 'SHOW_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const CONFIRM_DELETE_DIALOG = 'CONFIRM_DELETE_DIALOG';
export const SHOW_ALERT_DIALOG = 'SHOW_ALERT_DIALOG';
export const SHOW_BIG_ALERT_DIALOG = 'SHOW_BIG_ALERT_DIALOG';
export const CLOSE_ALERT_DIALOG = 'CLOSE_ALERT_DIALOG';

export const showDialog = (item) => ({
  type: SHOW_DIALOG,
  payload: item,
});

export const closeDialog = () => ({
  type: CLOSE_DIALOG,
});

export const confirmDeleteDialog = (item) => ({
  type: CONFIRM_DELETE_DIALOG,
  payload: item,
});

export const showAlertDialog = (message) => ({
  type: SHOW_ALERT_DIALOG,
  payload: message,
});

export const showBigAlertDialog = (message, data) => ({
  type: SHOW_BIG_ALERT_DIALOG,
  payload: { message, data },
});

export const closeAlertDialog = () => ({
  type: CLOSE_ALERT_DIALOG,
});
