import { useDispatch, useSelector } from 'react-redux';
import { sortList } from '../../../../common/components/list/sortList';
import { useEffect } from 'react';
import { resetComponent, setComponent } from '../../../../common/actions/headerActions';
import { resetListSettings, resetUsers, searchUsers } from '../../actions/adminActions';
import { closeManageUserConfirmation } from '../../actions/editUserConfirmationActions';

export const useSortedUsers = () => useSelector((state) => {
  const {
    users,
    listSettings: {
      sortColumn,
      sortDirection,
    },
  } = state.adminReducer;

  return sortList(users, sortColumn, sortDirection);
});

export const useHeader = () => {
  const dispatch = useDispatch();
  const usersAmount = useSelector((state) => state.adminReducer.users.length);

  useEffect(() => {
    dispatch(setComponent({ title: `Users (${usersAmount})` }));
  }, [usersAmount]);
};

export const useReset = () => {
  const dispatch = useDispatch();

  const reset = () => {
    dispatch(resetComponent());
    dispatch(resetListSettings());
    dispatch(resetUsers());
    dispatch(closeManageUserConfirmation());
  };

  useEffect(() => {
    reset();

    return () => {
      reset();
    };
  }, []);
};

export const useSearch = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.adminReducer.listSettings.searchValue);

  useEffect(() => {
    dispatch(searchUsers(searchValue));
  }, [searchValue]);
};
