import {
  SET_EXPIRED_CONTRACTS,
  SET_CONTRACTS_ALERTS,
  SET_CONTRACTS_APPROVAL,
  SET_CONTRACTS_TYPES,
  SET_CONTRACTS_TOTAL_COUNT,
  GET_EXPIRED_CONTRACTS,
  SET_IMPORT_FILE,
  SET_FLAG_SHOW_CONFIRM_DIALOG,
  SET_STATUS_FOR_DOWNLOADED_FILE,
  RESET_CONTRACTS,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
} from '../actions/contractsActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Contract',
  sortDirection: 'Up',
  filters: {},
  scrollTo: '',
};

const initialState = {
  contracts: [],
  alerts: [],
  approvalStatus: [],
  contractTypes: [],
  totalCount: 0,
  dontUploadFilters: false,
  downloadedFile: null,
  statusForDownloadedFile: {
    successCount: null,
    errorCount: null,
  },
  showConfirmForDownload: false,
  listSettings: defaultListSettings,
};

const contractsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTRACTS_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_CONTRACTS_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case SET_CONTRACTS_APPROVAL:
      return {
        ...state,
        approvalStatus: action.payload,
      };
    case SET_CONTRACTS_TYPES:
      return {
        ...state,
        contractTypes: action.payload,
      };
    case SET_EXPIRED_CONTRACTS:
      // eslint-disable-next-line no-case-declarations
      const newContractIds = new Set(action.payload.map((contract) => contract.Id));

      return {
        ...state,
        contracts: [...state.contracts.filter((contract) => !newContractIds.has(contract.Id)), ...action.payload],
      };
    case GET_EXPIRED_CONTRACTS:
      return {
        ...state,
        dontUploadFilters: action.payload.dontUploadFilters,
      };
    case SET_IMPORT_FILE:
      return {
        ...state,
        downloadedFile: action.payload,
      };
    case SET_FLAG_SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        showConfirmForDownload: action.payload,
      };
    case SET_STATUS_FOR_DOWNLOADED_FILE:
      return {
        ...state,
        statusForDownloadedFile: action.payload,
      };
    case RESET_CONTRACTS:
      return {
        ...state,
        contracts: [],
        totalCount: 0,
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    default:
      return state;
  }
};

export default contractsReducer;
