import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from './checkbox';
import { defaultData } from './utils';

export class Permissions extends Component {
  renderGropCheckbox = (group, userPermissions) => {
    const { onClick, isAdmin } = this.props;
    const checboxes = [];
    for (const key in group) {
      if (key !== 'label') {
        checboxes.push(
          <Checkbox
            label={group[key].label}
            checked={userPermissions[key]}
            onClick={onClick}
            name={key}
            key={key}
            disabled={key === 'AddToCCInNotifications' && !isAdmin}
          />,
        );
      }
    }
    return checboxes;
  }

  renderCheckboxesForPermissions = (userPermissions) => {
    return defaultData.map((group) => {
      return (
        <div className='admin-permissions_container-block' key={group.label}>
          <span className='admin-permissions_line'>{group.label}</span>
          {this.renderGropCheckbox(group, userPermissions)}
        </div>
      );
    });
  };

  render() {
    const { permissions } = this.props;
    return (
      <div className='admin-permissions_container'>
        {this.renderCheckboxesForPermissions(permissions)}
      </div>
    );
  }
}

Permissions.propTypes = {
  permissions: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
