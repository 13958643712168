import {
  setClient,
  setClientContact,
} from 'common/actions/addEntityActions';

const config = {
  'client': {
    mainUrl: 'clients',
    additionalUrl: '',
    setAction: setClient,
    errorMessage: 'Impossible to add Client.',
  },
  'client_manager': {
    mainUrl: 'clients',
    additionalUrl: '/client-contact',
    setAction: setClientContact,
    errorMessage: 'Impossible to add Client Manager.',
  },
  'invoice_approver': {
    mainUrl: 'clients',
    additionalUrl: '/client-contact',
    setAction: setClientContact,
    errorMessage: 'Impossible to add SOW Approver.',
  },
  'po_approver': {
    mainUrl: 'clients',
    additionalUrl: '/client-contact',
    setAction: setClientContact,
    errorMessage: 'Impossible to add PO Approver.',
  },
};

export const addNewEntity = (entityId, value, parentEntityId) => {
  let responseData = {};
  const fullName = value.trim();
  const firstName = fullName.split(/(\s+)/)[0];
  const lastName = fullName.slice(fullName.indexOf(' '), fullName.length).trim();
  switch (entityId) {
    case 'client':
      responseData = {
        data: {
          Name: fullName,
        },
      };
      break;
    case 'client_manager':
    case 'invoice_approver':
    case 'po_approver':
      responseData = {
        data: {
          FirstName: firstName || '',
          LastName: fullName.split(/(\s+)/).length > 1 ? lastName : null,
        },
        parentEntityId,
      };
      break;
    default:
      break;
  }

  return {
    config: config[entityId],
    responseData,
  };
};
