import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputCurrency from '../../form-items/input-currency/input-currency';
import { CellBudget } from 'common/components/list/custom-cell/cell-budget';
import formatCurrency from 'utils/formatCurrency';

export class CellToggleBudget extends Component {
  constructor(props) {
    super(props);
    const {
      item, column, isEditingMode, isControlledByListItem,
    } = this.props;
    this.state = {
      value: item[column.Id],
      isTextField: isControlledByListItem && isEditingMode,
    };
  }

  toggleViewCell = () => {
    const { isTextField } = this.state;
    const { isEditingMode, isControlledByListItem } = this.props;
    this.setState({
      isTextField: isControlledByListItem ? isEditingMode : !isTextField,
    });
  }

  handleChange = ({ target }) => {
    this.setState({
      value: target.value,
    });
  }

  onBlur = () => {
    const {
      column, config, item, isControlledByListItem,
    } = this.props;
    const { value } = this.state;
    const { valueSetter, editingValueSetter } = config[column.Id];
    const newItem = isControlledByListItem ? {
      [column.Id]: value,
    } : {
      ...item,
      [column.Id]: value,
    };
    valueSetter && valueSetter(newItem);
    this.toggleViewCell();
    if (isControlledByListItem) {
      editingValueSetter && editingValueSetter(newItem, item);
    }
  }

  render() {
    const {
      config, column, isHaveRights, ...other
    } = this.props;
    const {
      value,
      isTextField,
    } = this.state;

    const currency = formatCurrency(config.CurrencyType && config.CurrencyType.currency && config.CurrencyType.currency.Name);
    return (
      isTextField && isHaveRights ? (
        <div
          className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
        >
          <InputCurrency
            formClassName='change-border__input-cell'
            inputClassName='change-name__input'
            startAdornment={currency}
            onChange={this.handleChange}
            onBlur={this.onBlur}
            value={value}
            maxlength='16'
          />
        </div>
      )
        : (
          <CellBudget
            {...other}
            config={config}
            column={column}
            onClick={this.toggleViewCell}
          />
        )
    );
  }
}

CellToggleBudget.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
