import {
  SHOW_MODAL,
  CLOSE_MODAL,
  SET_ROLES,
  SET_EMPLOYEES,
  RESET_ROLES,
  ADD_ROLE_TO_PROJECT,
  SET_STATUS,
  SET_EMPLOYEE_STATUS,
  GET_EDIT_ASSIGNMENT,
  SET_RATE_UNITS,
  SET_PROJECT_ID_FOR_ROLE,
  SET_BILLING_DATA,
  TOGGLE_ASSIGNMENTS_STATUS,
} from '../actions/roleModalActions.js';

const initialState = {
  isVisible: false,
  roleEmployees: [],
  roles: [],
  billingCodes: [],
  billingCodesStartDate: [],
  billingCodesEndDate: [],
  employees: [],
  projectId: null,
  roleModalAction: null,
  editAssignment: {},
  rateUnits: [],
  status: [],
  isOnlyActiveAssignments: true,
};

const roleModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
    case CLOSE_MODAL:
      return {
        ...state,
        isVisible: action.payload.isVisible,
        roleEmployees: action.payload.roleEmployees,
        billingCodes: action.payload.billingData.billingCodes,
        billingCodesStartDate: action.payload.billingData.billingCodesStartDate,
        billingCodesEndDate: action.payload.billingData.billingCodesEndDate,
        projectId: action.payload.projectId,
        roleModalAction: action.payload.roleModalAction,
      };
    case SET_PROJECT_ID_FOR_ROLE:
      return {
        ...state,
        projectId: action.payload,
      };
    case SET_ROLES:
      return {
        ...state,
        isVisible: action.payload.isVisible,
        roles: action.payload.roles,
      };
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload.employees,
      };
    case RESET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };
    case ADD_ROLE_TO_PROJECT:
      return {
        ...state,
        role: action.payload.role,
        isVisible: action.payload.isVisible,
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_EMPLOYEE_STATUS:
      return {
        ...state,
        employeeStatus: action.payload,
      };
    case GET_EDIT_ASSIGNMENT:
      return {
        ...state,
        editAssignment: action.payload,
      };
    case SET_RATE_UNITS:
      return {
        ...state,
        rateUnits: action.payload,
      };
    case SET_BILLING_DATA:
      return {
        ...state,
        billingCodes: action.payload.billingCodes,
        billingCodesStartDate: action.payload.billingCodesStartDate,
        billingCodesEndDate: action.payload.billingCodesEndDate,
      };
    case TOGGLE_ASSIGNMENTS_STATUS:
      return {
        ...state,
        isOnlyActiveAssignments: !state.isOnlyActiveAssignments,
      };
    default: break;
  }
  return state;
};

export default roleModalReducer;
