import * as React from 'react';
import classNames from 'classnames';
import CustomIcon from '../icon/Icon';
import history from 'utils/history';
import { Link } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import './sidebar.css';

class SidebarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarExpanded: true,
      isDropdownOpen: false,
      isAdminDropdownOpen: false,
      pathname: window.location.pathname,
    };

    this.collapseSidebar = this.collapseSidebar.bind(this);
    history.listen(this.listenHistory);
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.state;
    if (prevState.pathname !== pathname) {
      this.setState({
        isDropdownOpen: pathname.includes('/reports'),
        isAdminDropdownOpen: pathname.includes('/admin/lookups'),
      });
    }
  }

  handleToogleDropdown = () => {
    const { isDropdownOpen } = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  }

  handleToogleAdminDropDown = () => {
    const { isAdminDropdownOpen } = this.state;
    this.setState({
      isAdminDropdownOpen: !isAdminDropdownOpen,
    });
  }

  collapseSidebar = () => {
    const { isSidebarExpanded } = this.state;
    this.setState({ isSidebarExpanded: !isSidebarExpanded });
  }

  listenHistory = (location) => {
    this.setState({
      pathname: location.pathname,
    });
  }

  render() {
    const {
      version, admin, hasFinancialAccess, isShowBilling,
    } = this.props;
    const {
      isSidebarExpanded, pathname, isDropdownOpen, isAdminDropdownOpen,
    } = this.state;
    return (
      <div className={isSidebarExpanded ? 'sidebar-cont expanded' : 'sidebar-cont collapsed'}>
        <Scrollbars
          className='scrollbars scrollbars__add-project'
          autoHide
          hideTracksWhenNotNeeded
        >
          <div className='sidebar-container'>
            <div className='sidebar-row'>
              <div className='sidebar-row-svg' onClick={this.collapseSidebar}>
                <CustomIcon iconName='sidebar-menu' />
              </div>
              <div className={isSidebarExpanded ? 'logo text-expanded' : 'logo text-collapsed'}>
                <CustomIcon iconName='sidebar-logo' />
              </div>
            </div>
            <Link to='/dashboard'>
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/dashboard'),
                })}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-dashboard' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Dashboard</span>
                </div>
              </div>
            </Link>
            <div>
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/reports'),
                })}
                onClick={this.handleToogleDropdown}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-reports' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Reports</span>
                </div>
              </div>

            </div>
            {isDropdownOpen && isSidebarExpanded ? (
              <>
                <Link
                  to={{
                    pathname: '/reports/sow-extension-forecast',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>SoW Extension Forecast</span>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: '/reports/bench-summary',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Bench Summary</span>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: '/reports/workforce',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Workforce</span>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: '/reports/bench',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Bench</span>
                  </div>
                </Link>
                {hasFinancialAccess && <Link
                  to={{
                    pathname: '/reports/projected-revenue',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Projected Revenue</span>
                  </div>
                </Link>}
                {admin && (
                  <Link
                    to={{
                      pathname: '/reports/audit',
                    }}
                  >
                    <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                      <span>Audit</span>
                    </div>
                  </Link>
                )}
                {hasFinancialAccess && <Link
                  to={{
                    pathname: '/reports/accounting',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Accounting</span>
                  </div>
                </Link>}
                {hasFinancialAccess && <Link
                  to={{
                    pathname: '/reports/client-rates',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Client Rates</span>
                  </div>
                </Link>}
                {hasFinancialAccess && <Link
                  to={{
                    pathname: '/reports/team-composition',
                  }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Team Composition</span>
                  </div>
                </Link>}
                <Link to={{
                  pathname: '/reports/trainings',
                }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Trainings</span>
                  </div>
                </Link>
                <Link to={{
                  pathname: '/reports/background-checks',
                }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Background Checks</span>
                  </div>
                </Link>
                <Link to={{
                  pathname: '/reports/certifications',
                }}
                >
                  <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                    <span>Certifications</span>
                  </div>
                </Link>
              </>
            ) : null}
            <Link
              to={{
                pathname: '/offices',
              }}
            >
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/offices'),
                })}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-offices' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Offices</span>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname: '/employees',
              }}
            >
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/employees'),
                })}
                onClick={this.refreshHistoryState}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-employees' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Employees</span>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname: '/clients',
              }}
            >
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/clients'),
                })}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-clients' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Clients</span>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname: '/projects',
              }}
            >
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/projects'),
                })}
                onClick={this.refreshHistoryState}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-projects' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Projects</span>
                </div>
              </div>
            </Link>
            {isShowBilling && <Link
              to={{
                pathname: '/billing',
              }}
            >
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/billing'),
                })}
                onClick={this.refreshHistoryState}
              >
                <div className='sidebar-row-svg'>
                  <LibraryBooksOutlinedIcon style={{ width: '24px', color: 'white' }} />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Billing</span>
                </div>
              </div>
            </Link>}
            <Link
              to={{
                pathname: '/contracts',
              }}
            >
              <div
                className={classNames('sidebar-row sidebar-option', {
                  'selected-tab': pathname.includes('/contracts'),
                })}
              >
                <div className='sidebar-row-svg'>
                  <CustomIcon iconName='sidebar-po' />
                </div>
                <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                  <span>Contracts</span>
                </div>
              </div>
            </Link>
            {
              admin ? (
                <div>
                  <hr className='split-dashboard' />
                  <span className='admin-tools'>Admin</span>
                  <Link
                    to={{
                      pathname: '/admin/users',
                    }}
                  >
                    <div
                      className={classNames('sidebar-row sidebar-option', {
                        'selected-tab': pathname.includes('/admin/users'),
                      })}
                    >
                      <div className='sidebar-row-svg'>
                        <CustomIcon iconName='sidebar-manageuser' />
                      </div>
                      <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                        <span>Manage users</span>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: '/admin/api_tokens',
                    }}
                  >
                    <div
                      className={classNames('sidebar-row sidebar-option', {
                        'selected-tab': pathname.includes('/admin/api_tokens'),
                      })}
                    >
                      <div className='sidebar-row-svg'>
                        <CustomIcon iconName='sidebar-manageuser' />
                      </div>
                      <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                        <span>Manage API tokens</span>
                      </div>
                    </div>
                  </Link>
                  <div
                    className={classNames('sidebar-row sidebar-option', {
                      'selected-tab': pathname.includes('/admin/lookups'),
                    })}
                    onClick={this.handleToogleAdminDropDown}
                  >
                    <div className='sidebar-row-svg'>
                      <CustomIcon iconName='admin-lookups' />
                    </div>
                    <div className={classNames('sidebar-row-text', isSidebarExpanded ? 'text-expanded' : 'text-collapsed')}>
                      <span>Manage lookups</span>
                    </div>
                  </div>
                  {isAdminDropdownOpen && isSidebarExpanded ? (
                    <>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-jobtitles',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Job Titles</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-role-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Role Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-project-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Project Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-skills',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Skills</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-business-trips',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Business Trips</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-seniority-levels',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Seniority Levels</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-skill-levels',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Skill Levels</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-sow-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage SoW Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/document-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Document Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/certificates',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Certificates</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-payment-terms',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Payment Terms</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-invoice-dates',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Invoice Dates</span>
                        </div>
                      </Link>

                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-employee-trainings',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage List of Trainings</span>
                        </div>
                      </Link>

                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-renewal-status',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage SoW Renewal Status</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-assignment-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Assignment Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-engagement-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Engagement Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-back-fill-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage BackFill Types</span>
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: '/admin/lookups/manage-employee-types',
                        }}
                      >
                        <div className='sidebar-row-dropdown selected-tab sidebar-row-dropdown'>
                          <span>Manage Employee Types</span>
                        </div>
                      </Link>
                    </>
                  ) : null}
                  <Link
                    to={{
                      pathname: '/admin/settings',
                    }}
                  >
                    <div
                      className={classNames('sidebar-row sidebar-option', {
                        'selected-tab': pathname.includes('/admin/settings'),
                      })}
                    >
                      <div className='sidebar-row-svg'>
                        <CustomIcon iconName='sidebar-settings' />
                      </div>
                      <div className={isSidebarExpanded ? 'sidebar-row-text text-expanded' : 'sidebar-row-text text-collapsed'}>
                        <span>Settings</span>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null
            }
          </div>
        </Scrollbars>
        <div className='sidebar-footer'>
          <hr className='split-dashboard' />
          <span className='version-tools'>{isSidebarExpanded ? `Version: ${version}` : null}</span>
        </div>
      </div>
    );
  }
}

export default SidebarComponent;
