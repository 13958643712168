import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import './add-employee.css';

import TextField from 'common/components/form-items/input/text-field';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import Select from 'common/components/form-items/select/select';
import { sortFilterList } from 'common/components/list/sortList';
import CustomIcon from 'common/components/icon/Icon';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import { Badge } from 'common/components/badges/badges';
import { getStatusColor } from 'services/getStatusColor';
import { getColorFromString } from 'services/getColor';
import {
  PROJECT_ROLES_EMPLOYEES_PRIMARY,
  PROJECT_ROLES_EMPLOYEES_SHADOW,
  PROJECT_ROLES_STATUS_ACTIVE,
  PROJECT_ROLES_STATUS_REMOVED,
  PROJECT_ROLES_STATUS_PROSPECTIVE,
  DATES_CROSSING_MESSAGE,
} from 'utils/const-variable';
import VirtualSelect from 'common/components/form-items/virtualized-select/virtualized-select';
import { isCorrectRoleEmployeeAssignment } from '../../utils/isCorrectRoleAssignments';
import isObjectNotEquals from 'utils/object-comparison';

const EMPLOYEE_ID = 'EmployeeId';
const EMPLOYEE_ASSIGNMENT = 'EmployeeAssignment';
const EMPLOYEE_START_DATE = 'EmployeeStartDate';
const EMPLOYEE_END_DATE = 'EmployeeEndDate';
const EMPLOYEE_STATUS_ID = 'EmployeeStatusId';
const EMPLOYEE_COMMENT = 'EmployeeComment';
const defaultEditingValue = {
  [EMPLOYEE_ID]: false,
  [EMPLOYEE_ASSIGNMENT]: false,
  [EMPLOYEE_START_DATE]: false,
  [EMPLOYEE_END_DATE]: false,
  [EMPLOYEE_STATUS_ID]: false,
  [EMPLOYEE_COMMENT]: false,
};
const ASSIGNMENT_VALIDATION_MESSAGE = 'Invalid Assignment';
const END_DATE_VALIDATION_MESSAGE = 'End date should be after Start Date';
const START_DATE_VALIDATION_MESSAGE = 'Start Date should be before End Date';

export class NewEmployee extends React.Component {
  constructor(props) {
    super(props);

    const { employee } = this.props;
    this.state = {
      employee: {
        ...employee,
      },
      invalidAssignment: false,
      invalidStartDate: false,
      invalidEndDate: false,
      isValidEmployee: true,
      isEmployeeEditing: defaultEditingValue,
      blockEditing: false,
      optionMenuOpened: false,
      isAssignDuplicatedEmployeeDialogOpened: false,
      openAlertDialog: false,
      newEmployee: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { employees: _, ...propsRest } = this.props;
    const { employees: __, ...prevPropsRest } = prevProps;
    const { employee } = this.state;
    if (employee.EmployeeId && !employee.EmployeeStatusId && isObjectNotEquals(propsRest, prevPropsRest)) {
      const { RoleStartDate, RoleEndDate } = this.props;
      this.defineDefaultStatusEmployee(RoleStartDate, RoleEndDate);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.employee.EmployeeId !== state.employee.EmployeeId && !state.blockEditing) {
      return {
        employee: {
          ...props.employee,
        },
      };
    }
    return null;
  }

  validateEmployee = () => {
    const {
      invalidAssignment,
      invalidStartDate,
      invalidEndDate,
      employee,
    } = this.state;
    const isValidEmployee = !invalidAssignment && !invalidStartDate && !invalidEndDate
      && !!(employee.EmployeeAssignment && employee.EmployeeStartDate && employee.EmployeeEndDate && employee.EmployeeStatusId);
    this.setState({
      isValidEmployee,
    }, () => this.editAssignedEmployee());
  }

  addBackupEmployee = () => {
    const { employee } = this.state;
    const { addBackupEmployee, disabled } = this.props;
    !disabled && addBackupEmployee(employee);
  }

  deleteAssignedEmployee = () => {
    const { employee } = this.state;
    const { deleteAssignedEmployee, disabled, primaryAssignment } = this.props;
    const primaryAssignmentKey = primaryAssignment ? primaryAssignment.AssignmentKey : '';
    !disabled && deleteAssignedEmployee(employee, primaryAssignmentKey, false);
  }

  promoteBackupToPrimary = () => {
    const { employee, isValidEmployee } = this.state;
    const {
      promoteBackupToPrimary, disabled, roleEmployeeStatus, primaryAssignment, disabledPromote,
    } = this.props;

    const newAssignmentStatus = roleEmployeeStatus.find(status => status.Name === PROJECT_ROLES_STATUS_REMOVED);
    const backupEmployeeData = {
      ...employee,
      EmployeeStatusId: newAssignmentStatus.Id,
      EmployeeStatus: newAssignmentStatus.Name,
    };
    const promotedEmployeeData = {
      ...employee,
      EmployeeRole: PROJECT_ROLES_EMPLOYEES_PRIMARY,
      primaryAssignmentId: null,
      AssignmentKey: '',
    };

    this.setState({
      employee: { ...backupEmployeeData },
    }, () => !(disabledPromote || disabled)
      && promoteBackupToPrimary(promotedEmployeeData, backupEmployeeData, primaryAssignment.AssignmentKey, isValidEmployee));
  }

  editAssignedEmployee = () => {
    const { employee, isValidEmployee } = this.state;
    const { editAssignedEmployee, primaryAssignment } = this.props;
    const primaryAssignmentKey = primaryAssignment ? primaryAssignment.AssignmentKey : '';
    this.setState({
      blockEditing: false,
    }, () => editAssignedEmployee(employee, primaryAssignmentKey, isValidEmployee));
  }

  editEmployee = (fieldName) => () => {
    const { disabled } = this.props;
    const { isEmployeeEditing, employee } = this.state;
    if (!disabled && employee.EmployeeId) {
      this.setState({
        isEmployeeEditing: {
          ...defaultEditingValue,
          [fieldName]: !isEmployeeEditing[fieldName],
        },
      }, () => !isEmployeeEditing[fieldName]
        && fieldName !== EMPLOYEE_START_DATE
        && fieldName !== EMPLOYEE_END_DATE
        && document.getElementById(fieldName).focus());
    }
  }

  applyNewEmployee = item => {
    const {
      RoleStartDate,
      RoleEndDate,
      RoleAssignment,
      primaryAssignment,
      employees,
    } = this.props;
    const { employee } = this.state;
    const foundEmployee = employees.find(emp => emp.Id === item.value); 
    const officeName = foundEmployee && foundEmployee.Office && foundEmployee.Office.Name;

    this.setState({
      employee: {
        ...employee,
        EmployeeId: item.value,
        EmployeeName: item.label,
        EmployeeOffice: officeName,
        EmployeeStartDate: RoleStartDate,
        EmployeeEndDate: RoleEndDate,
        EmployeeAssignment: RoleAssignment,
        EmployeeRole: primaryAssignment && primaryAssignment.EmployeeId ? PROJECT_ROLES_EMPLOYEES_SHADOW : PROJECT_ROLES_EMPLOYEES_PRIMARY,
        EmployeeComment: '',
      },
      isEmployeeEditing: defaultEditingValue,
      blockEditing: true,
    }, () => {
      this.defineDefaultStatusEmployee(RoleStartDate, RoleEndDate);
      this.closeDialog();
    });
  }

  closeDialog = () => {
    this.setState({
      isAssignDuplicatedEmployeeDialogOpened: false
    });
  }

  closeAlertDialog = () => {
    this.setState({
      openAlertDialog: false
    });
  }

  onChangeEmployee = item => {
    const { assignedEmployeesToProject } = this.props;
    const employee = assignedEmployeesToProject &&
      assignedEmployeesToProject.find(emp => ((typeof emp.Status === 'string' && emp.Status.toLowerCase() != 'removed')
        || (emp.Status.Name && emp.Status.Name.toLowerCase() != 'removed'))
        && (emp.EmployeeId === item.value || emp.Id === item.value));
    const message = employee &&
      `${employee.Status.Name ? employee.Status.Name : employee.Status} ${employee.Name}  assignment already exists in a project. Do you want to add another one?`;
    if (employee) {
      this.setState({
        isAssignDuplicatedEmployeeDialogOpened: true,
        newEmployee: item,
        confirmDialogMessage: message
      });
    } else {
      this.applyNewEmployee(item);
    }
  }

  getFlatEmployeeList = (employees) => {
    const flatEmployeesArray = employees.reduce((flatArray, primary) => {
      !!primary.EmployeeId && flatArray.push(primary);
      primary.backupEmployees && primary.backupEmployees.forEach(backup => !!backup.EmployeeId && flatArray.push(backup));
      return flatArray;
    }, []);
    return flatEmployeesArray;
  }

  onChangeAssignment = e => {
    const { employee } = this.state;
    const newEmployeeData = { ...employee };
    const { value } = e.target;

    newEmployeeData.EmployeeAssignment = value;
    this.setState({
      invalidAssignment: !isCorrectRoleEmployeeAssignment(value),
      employee: newEmployeeData,
    }, () => this.validateEmployee());
  }

  onChangeComment = e => {
    const { employee } = this.state;
    const newEmployeeData = { ...employee };
    const { value } = e.target;

    newEmployeeData.EmployeeComment = value;
    this.setState({
      employee: newEmployeeData,
    }, () => this.validateEmployee());
  }

  handleEmployeeStartDateChange = date => {
    const { employee } = this.state;
    const newEmployeeData = { ...employee };
    newEmployeeData.EmployeeStartDate = date;
    this.setState({
      employee: newEmployeeData,
      invalidStartDate: moment(date).isSameOrAfter(employee.EmployeeEndDate, 'hour'),
      invalidEndDate: moment(date).isSameOrAfter(employee.EmployeeEndDate, 'hour'),
    }, () => {
      this.defineDefaultStatusEmployee(date, employee.EmployeeEndDate);
    });
  }

  handleEmployeeEndDateChange = date => {
    const { employee } = this.state;
    const newEmployeeData = { ...employee };
    newEmployeeData.EmployeeEndDate = date;
    this.setState({
      employee: newEmployeeData,
      invalidStartDate: moment(date).isSameOrBefore(employee.EmployeeStartDate, 'hour'),
      invalidEndDate: moment(date).isSameOrBefore(employee.EmployeeStartDate, 'hour'),
    }, () => {
      this.defineDefaultStatusEmployee(employee.EmployeeStartDate, date);
    });
  }

  getSameEmployee = (employeeForComparing) => {
    const { assignedEmployees } = this.props;
    return this.getFlatEmployeeList(assignedEmployees)
      .filter(employee => (
        employee.EmployeeId === employeeForComparing.EmployeeId
        && employee.AssignmentKey !== employeeForComparing.AssignmentKey
      ));
  }

  checkIsSameEmployeeRemoved = (employeeForComparing) => {
    const sameEmployees = this.getSameEmployee(employeeForComparing);
    return !!sameEmployees.length && sameEmployees.every(employee => employee.EmployeeStatus === PROJECT_ROLES_STATUS_REMOVED);
  }

  onChangeEmployeeStatus = ({ item }) => {
    const { assignedEmployees } = this.props;
    const { employee } = this.state;
    let newEmployeeData = { ...employee };

    if (assignedEmployees.find(emp => emp.EmployeeId === employee.EmployeeId && (emp.AssignmentKey !== employee.AssignmentKey) && (emp.EmployeeStatus && emp.EmployeeStatus.toLowerCase() === 'removed'))) {
      this.setState({
        openAlertDialog: true
      });
    } else {
      newEmployeeData.EmployeeStatusId = item.Id;
      newEmployeeData.EmployeeStatus = item.Name;
      this.setState({
        employee: newEmployeeData,
      }, () => this.validateEmployee());
    }
  }

  defineDefaultStatusEmployee = (startTime, endTime) => {
    const { employee } = this.state;
    const { RoleStatus } = this.props;
    const isSameEmployeesRemoved = this.checkIsSameEmployeeRemoved(employee);
    let status = '';

    if (RoleStatus === PROJECT_ROLES_STATUS_ACTIVE) {
      status = (moment().isAfter(endTime) && PROJECT_ROLES_STATUS_REMOVED)
        || (moment().isBefore(startTime) && PROJECT_ROLES_STATUS_PROSPECTIVE)
        || (moment().isBetween(startTime, endTime) && PROJECT_ROLES_STATUS_ACTIVE);
    } else {
      status = RoleStatus;
    }
    if (employee.EmployeeStatus === PROJECT_ROLES_STATUS_REMOVED && !isSameEmployeesRemoved) {
      status = PROJECT_ROLES_STATUS_REMOVED;
    }

    this.setDefaultStatus(status);
  }

  setDefaultStatus = (status) => {
    const { employee } = this.state;
    const { roleEmployeeStatus } = this.props;
    const newEmployeeData = { ...employee };

    roleEmployeeStatus.forEach(item => {
      if (item.Name === status) {
        newEmployeeData.EmployeeStatusId = item.Id;
        newEmployeeData.EmployeeStatus = item.Name;
      }
    });

    this.setState({
      employee: newEmployeeData,
    }, () => this.validateEmployee());
  };

  toggleOptionMenu = () => {
    const { optionMenuOpened } = this.state;
    this.setState({
      optionMenuOpened: !optionMenuOpened,
    });
  }

  renderActionSVG = () => {
    const { employee } = this.state;
    const { primaryAssignmentIndex, disabled, disabledPromote } = this.props;
    return (
      employee.EmployeeId
        ? primaryAssignmentIndex || primaryAssignmentIndex === 0
          ? (
            <CustomIcon
              iconName='promote-backup'
              className={classNames({ 'pointer active-button': !(disabledPromote || disabled) })}
              onClick={this.promoteBackupToPrimary}
              title='Promote to primary employee'
            />
          ) : (
            <CustomIcon
              iconName='add-backup'
              className={classNames({ 'pointer active-button': !disabled })}
              onClick={this.addBackupEmployee}
              title='Add backup employee'
            />
          ) : null
    );
  }

  render() {
    const {
      employee,
      invalidAssignment,
      invalidStartDate,
      invalidEndDate,
      isEmployeeEditing,
      optionMenuOpened,
      isAssignDuplicatedEmployeeDialogOpened,
      openAlertDialog,
      newEmployee,
      confirmDialogMessage,
    } = this.state;
    const {
      employees,
      disabled,
      roleEmployeeStatus,
      primaryAssignmentIndex,
      showOptionDots,
      isDatesCrossing,
      RoleStartDate,
      RoleEndDate,
    } = this.props;
    const options = sortFilterList(employees).map(employee => {
      return {
        label: employee.Name,
        value: employee.Id,
      };
    });
    const isSameEmployeeOnAssignments = !!(this.getSameEmployee(employee).length);
    const isSameEmployeeOnRemovedAssignments = this.checkIsSameEmployeeRemoved(employee);
    const isCanStatusOrEmployeeChanged = employee.EmployeeStatus !== PROJECT_ROLES_STATUS_REMOVED
      || !isSameEmployeeOnAssignments
      || isSameEmployeeOnRemovedAssignments;

    return (
      <div className={classNames('add-employee-list-line add-employee-new-item', { 'disabled-line': disabled })}>
        <div className='add-employee-list-column employee-col'>
          {
            (primaryAssignmentIndex || primaryAssignmentIndex === 0) ? (
              <div className='add-employee-shadow-icon'>
                <CustomIcon iconName='backup' />
              </div>
            ) : null
          }
          {
            isEmployeeEditing.EmployeeId || !employee.EmployeeId ? (
              <VirtualSelect
                id={EMPLOYEE_ID}
                options={options}
                value={employee.EmployeeId}
                onChange={this.onChangeEmployee}
                onBlur={this.editEmployee(EMPLOYEE_ID)}
                disabled={disabled}
                isRequired
              />
            ) : (
              <div
                className={classNames('u-align-items-center', 'full-width-height', { 'pointer': isCanStatusOrEmployeeChanged })}
                onClick={isCanStatusOrEmployeeChanged && this.editEmployee(EMPLOYEE_ID)}
              >
                {
                  employee.EmployeeName && (
                    <Badge
                      color={getColorFromString(employee.EmployeeName)}
                      item={employee.EmployeeName}
                    />
                  )
                }
                <span
                  className='add-employee-list-value'
                >{employee.EmployeeName || '---'}</span>
              </div>)
          }
        </div>
        <div className='add-employee-list-column'>
          <span className='add-employee-list-value pointer'>{employee.EmployeeOffice}</span>
        </div>
        <div className='add-employee-list-column' title={invalidAssignment ? ASSIGNMENT_VALIDATION_MESSAGE : null}>
          {
            isEmployeeEditing.EmployeeAssignment && !disabled && employee.EmployeeId ? (
              <TextField
                formClassName='add-employee-form'
                inputClassName={classNames('add-employee-input', { 'input-error': invalidAssignment })}
                type='number'
                id={EMPLOYEE_ASSIGNMENT}
                placeholder='Enter Assignment'
                disabled={disabled}
                error={invalidAssignment}
                value={employee.EmployeeAssignment}
                onChange={this.onChangeAssignment}
                onBlur={this.editEmployee(EMPLOYEE_ASSIGNMENT)}
                helperText={invalidAssignment ? ASSIGNMENT_VALIDATION_MESSAGE : null}
              />
            ) : <span
              className={classNames('add-employee-list-value pointer', { 'add-employee-validation-error': invalidAssignment })}
              onClick={this.editEmployee(EMPLOYEE_ASSIGNMENT)}
            >{employee.EmployeeAssignment || '---'}</span>
          }
        </div>
        <div
          className='add-employee-list-column'
          title={invalidStartDate ? START_DATE_VALIDATION_MESSAGE : isDatesCrossing ? DATES_CROSSING_MESSAGE : null}
        >
          {
            isEmployeeEditing.EmployeeStartDate && !disabled && employee.EmployeeId ? (
              <DatePicker
                formClassName='add-employee-form'
                datePickerClassName={classNames('add-employee-input add-employee-date-picker', { 'input-error': invalidStartDate || isDatesCrossing })}
                placeholder='--/--/--'
                id={EMPLOYEE_START_DATE}
                disabled={disabled}
                minVal={RoleStartDate}
                maxVal={RoleEndDate}
                minErrorMassage='Start Date should not be less than role start date'
                maxErrorMassage='Start Date should not be greater than role end date'
                value={employee.EmployeeStartDate}
                onChange={this.handleEmployeeStartDateChange}
                onBlur={this.editEmployee(EMPLOYEE_START_DATE)}
                helperText={invalidStartDate ? START_DATE_VALIDATION_MESSAGE : isDatesCrossing ? DATES_CROSSING_MESSAGE : null}
                name='startDateEmpl'
                isStartDate
              />
            ) : <span
              className={classNames('add-employee-list-value pointer', { 'add-employee-validation-error': invalidStartDate || isDatesCrossing })}
              onClick={this.editEmployee(EMPLOYEE_START_DATE)}
            >{employee.EmployeeStartDate ? moment(employee.EmployeeStartDate).format('MM/DD/YYYY') : '--/--/--'}</span>
          }
        </div>
        <div
          className='add-employee-list-column'
          title={invalidEndDate ? END_DATE_VALIDATION_MESSAGE : isDatesCrossing ? DATES_CROSSING_MESSAGE : null}
        >
          {
            isEmployeeEditing.EmployeeEndDate && !disabled && employee.EmployeeId ? (
              <DatePicker
                formClassName='add-employee-form'
                datePickerClassName={classNames('add-employee-input add-employee-date-picker', { 'input-error': invalidEndDate || isDatesCrossing })}
                placeholder='--/--/--'
                id={EMPLOYEE_END_DATE}
                disabled={disabled}
                minVal={employee.EmployeeStartDate}
                maxVal={RoleEndDate}
                minErrorMassage='End Date should not be less than role start date'
                maxErrorMassage='End Date should not be greater than role end date'
                value={employee.EmployeeEndDate}
                onChange={this.handleEmployeeEndDateChange}
                onBlur={this.editEmployee(EMPLOYEE_END_DATE)}
                helperText={invalidEndDate ? END_DATE_VALIDATION_MESSAGE : isDatesCrossing ? DATES_CROSSING_MESSAGE : null}
                name='endDateEmpl'
                isEndDate
              />
            ) : <span
              className={classNames('add-employee-list-value pointer', { 'add-employee-validation-error': invalidEndDate || isDatesCrossing })}
              onClick={this.editEmployee(EMPLOYEE_END_DATE)}
            >{employee.EmployeeEndDate ? moment(employee.EmployeeEndDate).format('MM/DD/YYYY') : '--/--/--'}</span>
          }
        </div>
        <div className='add-employee-list-column'>
          {
            isEmployeeEditing.EmployeeStatusId && !disabled && employee.EmployeeId ? (
              <Select
                formClassName='add-employee-form'
                selectClassName='add-employee-input add-employee-select'
                optionsClassName='option'
                id={EMPLOYEE_STATUS_ID}
                placeholder='Choose Status'
                value={employee.EmployeeStatusId}
                onChange={this.onChangeEmployeeStatus}
                selectOptions={sortFilterList(roleEmployeeStatus)}
                onBlur={this.editEmployee(EMPLOYEE_STATUS_ID)}
                disabled={disabled}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'EmployeeStatus',
                  id: EMPLOYEE_STATUS_ID,
                }}
                isRequired
                addEmployeeStyles
              />)
              : (
                <div
                  className={classNames('add-employee-list-value', { 'pointer': isCanStatusOrEmployeeChanged })}
                  onClick={isCanStatusOrEmployeeChanged && this.editEmployee(EMPLOYEE_STATUS_ID)}
                >
                  <span
                    className={classNames({ 'add-employee-status': employee.EmployeeStatusId })}
                    style={employee.EmployeeStatusId ? {
                      background: getStatusColor(employee.EmployeeStatus),
                      color: getStatusColor(employee.EmployeeStatus) !== 'transparent' ? '#FFFFFF' : '#000000',
                    } : {}}
                  >{employee.EmployeeStatus || '---'}</span>
                </div>
              )
          }
        </div>
        <div className='add-employee-list-column'>
          {
            isEmployeeEditing.EmployeeComment && !disabled && employee.EmployeeId ? (
              <TextField
                formClassName='add-employee-form'
                inputClassName={classNames('add-employee-input')}
                id={EMPLOYEE_COMMENT}
                placeholder='Enter Comment'
                disabled={disabled}
                value={employee.EmployeeComment}
                onChange={this.onChangeComment}
                onBlur={this.editEmployee(EMPLOYEE_COMMENT)}
              />
            ) : <span
              className={classNames('add-employee-list-value pointer add-employee__span--text', { 'add-employee-validation-error': invalidAssignment })}
              onClick={this.editEmployee(EMPLOYEE_COMMENT)}
              title={employee.EmployeeComment || null}
            >{employee.EmployeeComment || '---'}</span>
          }
        </div>
        <div className='add-employee-list-column svg-col'>
          {this.renderActionSVG()}
          {
            showOptionDots
              ? <div className='menu-options'>
                <CustomIcon iconName='three-dots' className='pointer' onClick={this.toggleOptionMenu} />
              </div>
              : null
          }
          {
            optionMenuOpened && (
              <div className='add-employee__menu-options' onMouseLeave={this.toggleOptionMenu}>
                <div className='add-employee__menu-options__option' onClick={this.deleteAssignedEmployee}>
                  <span>Delete item</span>
                </div>
              </div>
            )
          }
        </div>
        {
          isAssignDuplicatedEmployeeDialogOpened && (
            <ConfirmationDialog
              dialogHeader='DUPLICATE ASSIGNMENT'
              dialogTitle={confirmDialogMessage}
              closeButtonTitle='Cancel'
              confirmButtonTitle='Yes'
              closeDialog={this.closeDialog}
              confirmDialog={() => this.applyNewEmployee(newEmployee)}
            />
          )
        }
        {
          openAlertDialog && (
            <AlertDialog
              dialogHeader='Warning!'
              dialogTitle='Impossible to change status. In role already exists assignment with "Removed" status.'
              confirmButtonTitle='OK'
              closeDialog={this.closeAlertDialog}
              confirmDialog={this.closeAlertDialog}
            />
          )
        }
      </div>
    );
  }
}

NewEmployee.propTypes = {
  employees: PropTypes.array,
  roleEmployeeStatus: PropTypes.array,
  RoleStartDate: PropTypes.object,
  RoleEndDate: PropTypes.object,
  RoleAssignment: PropTypes.number,
  disabled: PropTypes.bool,
  editAssignedEmployee: PropTypes.func,
  addBackupEmployee: PropTypes.func,
  deleteAssignedEmployee: PropTypes.func,
  promoteBackupToPrimary: PropTypes.func,
  isDatesCrossing: PropTypes.bool,
  disabledPromote: PropTypes.bool,
};

export default connect(store => {
  return {
    roleEmployeeStatus: store.roleModalReducer.employeeStatus,
  };
})(NewEmployee);
