import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import {
  getManageSeniorityLevels,
  createNewSeniorityLevels,
  updateSeniorityLevels,
  updateOrderSeniorityLevels,
} from 'pages/admin/actions/adminManageSeniorityLevels';
import { SortableManageList } from 'common/components/documents-list/sortable-manage-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';

export class ManageSeniorityLevels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsToDisplay: [
        {
          Name: 'Seniority Levels', isSelected: true, className: 'without-width-col',
        },
      ],
      showAddRoleFields: false,
      addSeniorityLevel: { Name: '' },
      blockSeniorityLevel: false,
      blockSaveSeniorityLevel: false,
    };
  }

  componentDidMount() {
    const { loadManageSeniorityLevels } = this.props;
    loadManageSeniorityLevels();
  }

  addNewSeniorityLevel = () => {
    const { blockSeniorityLevel } = this.state;
    if (!blockSeniorityLevel) {
      this.setState({
        showAddRoleFields: true,
      });
    }
  }

  getNewSeniorityLevelObj = ({ target }) => {
    const { addSeniorityLevel } = this.state;
    this.setState({
      addSeniorityLevel: {
        ...addSeniorityLevel,
        [target.name]: target.value,
      },
    }, this.newSeniorityLevelValidation);
  }

  newSeniorityLevelValidation = () => {
    const { addSeniorityLevel } = this.state;
    this.setState({
      blockSaveSeniorityLevel: !!addSeniorityLevel.Name.trim(),
      blockSeniorityLevel: !!addSeniorityLevel.Name.trim(),
    });
  };

  newSeniorityLeveleCancel = () => {
    this.setState({
      addSeniorityLevel: { Name: '' },
      showAddRoleFields: false,
      blockSaveSeniorityLevel: false,
      blockSeniorityLevel: false,
    });
  }

  newSeniorityLevelApply = () => {
    const { createNewSeniorityLevels, manageSeniorityLevels } = this.props;
    const { blockSaveSeniorityLevel, addSeniorityLevel } = this.state;
    const priorityArray = manageSeniorityLevels.map((key) => key.Priority);
    const sendNewSeniorityLevelObj = {
      ...addSeniorityLevel,
      Priority: Math.max.apply(null, priorityArray) + 1,
    };
    if (blockSaveSeniorityLevel) {
      createNewSeniorityLevels(sendNewSeniorityLevelObj);
      this.setState({
        addSeniorityLevel: { Name: '' },
        showAddRoleFields: false,
        blockSaveSeniorityLevel: false,
        blockSeniorityLevel: false,
      });
    }
  }


  deleteSeniorityLevel = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `seniority_levels/${data.Id}`,
      searchUpdateSaga: 'seniority_levels',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateSeniorityLevels } = this.props;
    const { Name, Id } = data;
    updateSeniorityLevels(Name, Id);
  }

  newOrderSeniorityLevels = (data) => {
    const { updateOrderSeniorityLevels } = this.props;
    updateOrderSeniorityLevels(data);
  }

  render() {
    const { manageSeniorityLevels } = this.props;
    const {
      columnsToDisplay, showAddRoleFields, addSeniorityLevel, blockSeniorityLevel,
    } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsSeniorityLevels')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewSeniorityLevel}
          searchField={false}
        />
        <SortableManageList
          columnsToDisplay={columnsToDisplay}
          items={manageSeniorityLevels}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteSeniorityLevel}
          onUpdateOrder={this.newOrderSeniorityLevels}
          showItemsEditMenu
          showSortIcons={false}
        />
        {
          showAddRoleFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addSeniorityLevel}
                onChangeNewDocumentObj={this.getNewSeniorityLevelObj}
                disableSaveDocument={blockSeniorityLevel}
                newDocumentCancel={this.newSeniorityLeveleCancel}
                newDocumentApply={this.newSeniorityLevelApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageSeniorityLevels.propTypes = {
  loadManageSeniorityLevels: PropTypes.func,
  createNewSeniorityLevels: PropTypes.func,
  updateSeniorityLevels: PropTypes.func,
  updateOrderSeniorityLevels: PropTypes.func,
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  manageSeniorityLevels: store.adminManageLookups.seniorityLevels,
}), {
  loadManageSeniorityLevels: getManageSeniorityLevels,
  createNewSeniorityLevels,
  updateSeniorityLevels,
  updateOrderSeniorityLevels,
  showDialog,
})(ManageSeniorityLevels);
