import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  fetchProjects,
  getCreationConfig,
  getProjectRoleConfig,
  getOffices,
  getClients,
  getStatuses,
  getPeriods,
  resetListSettings,
  getPrevPathForProjects,
} from './actions/projectsActions';
import { getManageInvoiceDates } from 'pages/admin/actions/adminManageInvoiceDates';
import { getFilters } from 'common/actions/filtersActions';
import { getVersion } from 'common/actions/versionActions';
import { getRateUnits } from './actions/roleModalActions';
import ProjectsListPage from './components/projects-list/projects-list';
import CreateProjectPage from './components/create-project/create-project';
import ProjectDetails from './components/project-details/project-details';
import RoleModal from './components/role-modal/role-modal';
import EditRoleModal from './components/role-modal/edit-role-modal';
import EditAssignment from './components/role-modal/edit-assignment';
import SOWEditPage from './components/edit-project-info/SOW-edit-page/SOW-edit-page';
import POEditPage from './components/edit-project-info/PO-edit-page/PO-edit-page';
import AmendmentEditPage from './components/edit-project-info/Amendment-edit-page/Amendment-edit-page';
import ExtendsRole from './components/project-extend-role/project-extend-role';
import TeamPageComponent from './components/team-page/team-page';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { loadOfficeLocations } from 'pages/projects/actions/filterLocationsActions.js';
import './projects-page.css';
import MSAEditPage from './components/edit-project-info/MSA-edit-page/MSA-edit-page';

const filtersNames = [
  'Offices',
  'Clients',
  'ProjectStatuses',
  'ProjectTypes',
  'AccountManagers',
  'DeliveryManagers',
  'ClientManagers',
  'EngineeringManagers',
];

export class ProjectsPageComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      defaultFilters: {
        Statuses: [3],
      },
    };
  }

  componentDidMount() {
    const {
      getVersion,
      getCreationConfig,
      getProjectRoleConfig,
      getClients,
      getFilters,
      getRateUnits,
      loadOfficeLocations,
      getManageInvoiceDates,
      resetListSettings,
      prevPath,
      location,
    } = this.props;
    const urlIdPath = location.pathname.split('/projects/')[1];
    const patternId = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    getVersion();
    getCreationConfig();
    getProjectRoleConfig();
    getClients();
    getFilters(filtersNames);
    getRateUnits();
    loadOfficeLocations();
    getManageInvoiceDates();
    // if (!prevPath && !patternId.test(urlIdPath)) {
    //   resetListSettings();
    // } else {
    //   this.resetDefaultFilters();
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      version, getVersion, location, getPrevPathForProjects,
    } = this.props;
    const urlIdPath = location.pathname;
    const pattern = new RegExp('/projects', 'i');
    if (prevProps.version !== version) {
      getVersion();
    }
    // if (pattern.test(urlIdPath)) {
    //   getPrevPathForProjects(urlIdPath);
    // }
  }

  componentWillUnmount() {
    const { resetListSettings } = this.props;
    resetListSettings();
  }

  resetDefaultFilters = () => {
    this.setState({
      defaultFilters: null,
    });
  };

  getDefaultFilters = () => {
    const { statuses } = this.props;
    const defaultFilters = statuses.reduce((acc = [], item) => {
      if (item.Name === 'Active' || item.Name === 'Approve SOW/PO' || item.Name === 'Draft'
        || item.Name === 'Finish or Extend Project' || item.Name === 'Prospective' || item.Name === 'Unblock Project') {
        acc.push(item.Id);
        return acc;
      }
      return acc;
    }, []);
    return defaultFilters;
  }

  ProjectsList = (props) => {
    const { CanCreateProject, prevPath } = this.props;
    const { defaultFilters } = this.state;
    return (
      <ProjectsListPage
        {...props}
        defaultFilters={defaultFilters}
        prevPath={prevPath}
        CanCreateProject={CanCreateProject}
        getDefaultFilters={this.getDefaultFilters}
      />
    );
  };

  ProjectDetails = (props) => {
    const {
      EditProjectBasicInfo, EditProjectBasicFinanceInfo, ManageProjectContracts,
      ManageProjectRoles, ManageProjectRoleAssignments, HasFinancialAccess,
      CanCreateProject,
    } = this.props;
    return (
      <ProjectDetails
        {...props}
        EditProjectBasicInfo={EditProjectBasicInfo}
        EditProjectBasicFinanceInfo={EditProjectBasicFinanceInfo}
        ManageProjectContracts={ManageProjectContracts}
        ManageProjectRoles={ManageProjectRoles}
        ManageProjectRoleAssignments={ManageProjectRoleAssignments}
        HasFinancialAccess={HasFinancialAccess}
        CanCreateProject={CanCreateProject}
        getDefaultFilters={this.getDefaultFilters}
        resetDefaultFilters={this.resetDefaultFilters}
      />
    );
  };

  CreateProjectPage = (props) => {
    return (
      <CreateProjectPage
        {...props}
        resetDefaultFilters={this.resetDefaultFilters}
      />
    );
  };

  SOWEditPage = (props) => {
    const {
      ManageProjectContracts, HasFinancialAccess,
    } = this.props;
    return (
      <SOWEditPage
        {...props}
        ManageProjectContracts={ManageProjectContracts}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  POEditPage = (props) => {
    const {
      ManageProjectContracts, HasFinancialAccess,
    } = this.props;
    return (
      <POEditPage
        {...props}
        ManageProjectContracts={ManageProjectContracts}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  AmendmentEditPage = (props) => {
    const {
      ManageProjectContracts, HasFinancialAccess,
    } = this.props;
    return (
      <AmendmentEditPage
        {...props}
        ManageProjectContracts={ManageProjectContracts}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  MSAEditPage = (props) => {
    const {
      ManageProjectContracts, HasFinancialAccess,
    } = this.props;
    return (
      <MSAEditPage
        {...props}
        ManageProjectContracts={ManageProjectContracts}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  EditRoleModal = (props) => {
    const {
      ManageProjectRoles, HasFinancialAccess, ManageProjectRoleAssignments,
    } = this.props;
    return (
      <EditRoleModal
        {...props}
        ManageProjectRoles={ManageProjectRoles}
        HasFinancialAccess={HasFinancialAccess}
        ManageProjectRoleAssignments={ManageProjectRoleAssignments}
      />
    );
  }

  EditAssignment = (props) => {
    const { ManageProjectRoleAssignments } = this.props;
    return (
      <EditAssignment
        {...props}
        ManageProjectRoleAssignments={ManageProjectRoleAssignments}
      />
    );
  }

  ExtendsRole = (props) => {
    const { HasFinancialAccess } = this.props;
    return (
      <ExtendsRole
        {...props}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  }

  TeamPage = (props) => {
    return (<TeamPageComponent {...props} />);
  }

  render() {
    const {
      roleModalIsVisible, CanCreateProject, ManageProjectRoles, ManageProjectContracts,
    } = this.props;

    return (
      <DocumentTitle title={applicationTitle.getTitile('projects')}>
        <>
          <Switch>
            <Route exact path='/projects' render={this.ProjectsList} />
            <Route exact path='/projects/:id/team' component={this.TeamPage} />
            <Route exact path='/projects/:id/team/edit/:id' component={this.TeamPage} />
            {CanCreateProject ? <Route exact path='/projects/new' component={CreateProjectPage} /> : null}
            <Route exact path='/projects/:id' render={this.ProjectDetails} />
            <Route exact path='/projects/:id/edit-sow/:id' component={this.SOWEditPage} />
            <Route exact path='/projects/:id/view-sow/:id' component={this.SOWEditPage} />
            {ManageProjectContracts ? <Route exact path='/projects/:id/add-sow' component={this.SOWEditPage} /> : null}
            <Route exact path='/projects/:id/edit-po/:id' component={this.POEditPage} />
            <Route exact path='/projects/:id/view-po/:id' component={this.POEditPage} />
            {ManageProjectContracts ? <Route exact path='/projects/:id/add-po/:id' component={this.POEditPage} /> : null}
            <Route exact path='/projects/:id/edit-amendment/:id' component={this.AmendmentEditPage} />
            <Route exact path='/projects/:id/view-amendment/:id' component={this.AmendmentEditPage} />
            {ManageProjectContracts ? <Route exact path='/projects/:id/add-amendment/:id' component={this.AmendmentEditPage} /> : null}
            {ManageProjectRoles ? <Route exact path='/projects/new/addrole' component={RoleModal} /> : null}
            <Route exact path='/projects/:id/role/edit/:id' component={this.EditRoleModal} />
            <Route exact path='/projects/:id/assignment/edit/:id' component={this.EditAssignment} />
            <Route exact path='/projects/:id/role/view/:id' component={this.EditRoleModal} />
            {ManageProjectRoles ? <Route expact path='/projects/:id/extend-roles' component={this.ExtendsRole} /> : null}
            {ManageProjectContracts ? <Route exact path='/projects/:id/add-msa' component={this.MSAEditPage} /> : null}
            <Route exact path='/projects/:id/edit-msa/:id' component={this.MSAEditPage} />
            <Route exact path='/projects/:id/view-msa/:id' component={this.MSAEditPage} />
            <Redirect to='/projects' />
          </Switch>
          {
            roleModalIsVisible && <RoleModal />
          }
        </>
      </DocumentTitle>
    );
  }
}

ProjectsPageComponent.propTypes = {
  fetchProjects: PropTypes.func,
  getCreationConfig: PropTypes.func,
  modalNewPropjectIsOpen: PropTypes.bool,
  columnsToDisplay: PropTypes.array,
  projects: PropTypes.array,
  offices: PropTypes.array,
  clients: PropTypes.array,
  statuses: PropTypes.array,
  periods: PropTypes.array,
  managers: PropTypes.array,
  showNewProjectModal: PropTypes.func,
  hideNewProjectModal: PropTypes.func,
  getOffices: PropTypes.func,
  getClients: PropTypes.func,
  getStatuses: PropTypes.func,
  getPeriods: PropTypes.func,
  getVersion: PropTypes.func,
  getFilters: PropTypes.func,
  getRateUnits: PropTypes.func,
  getProjectRoleConfig: PropTypes.func,
  roleModalIsVisible: PropTypes.bool,
  version: PropTypes.string,
};

export default connect((store) => ({
  roleModalIsVisible: store.roleModalReducer.isVisible,
  version: store.versionReducer.version,
  projects: store.projectsReducer.projects,
  document: store.filtersReducer.filters.documentStatuses,
  currentRoles: store.userRoleReducer.roles,
  statuses: store.filtersReducer.filters.ProjectStatuses,
  prevPath: store.projectsReducer.prevPath,
}), {
  getVersion,
  fetchProjects,
  getCreationConfig,
  getProjectRoleConfig,
  getOffices,
  getClients,
  getStatuses,
  getPeriods,
  getFilters,
  getRateUnits,
  loadOfficeLocations,
  getManageInvoiceDates,
  resetListSettings,
  getPrevPathForProjects,
})(ProjectsPageComponent);
