import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getManageInvoiceDates, createNewInvoiceDate, updateInvoiceDate } from 'pages/admin/actions/adminManageInvoiceDates';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageInvoiceDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Invoice Dates', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddInvoiceDateFields: false,
      addInvoiceDate: { Name: '' },
      blockAddInvoiceDate: false,
      blockSaveInvoiceDate: false,
    };
  }

  componentDidMount() {
    const { loadManageInvoiceDates } = this.props;
    loadManageInvoiceDates();
  }

  addNewInvoiceDate = () => {
    const { blockAddInvoiceDate } = this.state;
    if (!blockAddInvoiceDate) {
      this.setState({
        showAddInvoiceDateFields: true,
      });
    }
  }

  getNewInvoiceDateObj = ({ target }) => {
    const { addInvoiceDate } = this.state;
    this.setState({
      addInvoiceDate: {
        ...addInvoiceDate,
        [target.name]: target.value,
      },
    }, this.newInvoiceDateValidation);
  }

  newInvoiceDateValidation = () => {
    const { addInvoiceDate } = this.state;
    this.setState({
      blockSaveInvoiceDate: !!addInvoiceDate.Name.trim(),
      blockAddInvoiceDate: !!addInvoiceDate.Name.trim(),
    });
  };

  newInvoiceDateCancel = () => {
    this.setState({
      addInvoiceDate: { Name: '' },
      showAddInvoiceDateFields: false,
      blockSaveInvoiceDate: false,
      blockAddInvoiceDate: false,
    });
  }

  newResumeApply = () => {
    const { createNewInvoiceDate } = this.props;
    const { blockSaveInvoiceDate, addInvoiceDate } = this.state;
    if (blockSaveInvoiceDate) {
      createNewInvoiceDate(addInvoiceDate);
      this.setState({
        addInvoiceDate: { Name: '' },
        showAddInvoiceDateFields: false,
        blockSaveInvoiceDate: false,
        blockAddInvoiceDate: false,
      });
    }
  }


  deleteInvoiceDate = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `InvoiceDates/${data.Id}`,
      searchUpdateSaga: 'InvoiceDates',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateInvoiceDate } = this.props;
    const { Name, Id } = data;
    updateInvoiceDate(Name, Id);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  render() {
    const { InvoiceDates, listSettings } = this.props;
    const {
      columnsToDisplay, showAddInvoiceDateFields, addInvoiceDate, blockAddInvoiceDate,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsInvoiceDates')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewInvoiceDate}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search payment terms'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(InvoiceDates, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteInvoiceDate}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {
          showAddInvoiceDateFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addInvoiceDate}
                onChangeNewDocumentObj={this.getNewInvoiceDateObj}
                disableSaveDocument={blockAddInvoiceDate}
                newDocumentCancel={this.newInvoiceDateCancel}
                newDocumentApply={this.newResumeApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageInvoiceDates.propTypes = {
  loadManageInvoiceDates: PropTypes.func,
  createNewInvoiceDate: PropTypes.func,
  updateInvoiceDate: PropTypes.func,
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  InvoiceDates: store.adminManageLookups.invoiceDates,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageInvoiceDates: getManageInvoiceDates,
  createNewInvoiceDate,
  updateInvoiceDate,
  showDialog,
  setListSettingsLookups,
})(ManageInvoiceDates);
