import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { loadManageDocumentTypes, createNewDocumentTypes, updateDocumentTypes } from 'pages/admin/actions/adminManageDocumentTypes';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageDocumentTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Document Types', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddDocumentFields: false,
      addDocumentType: { Name: '' },
      blockAddDocumentType: false,
      blockSaveDocumentType: false,
    };
  }

  componentDidMount() {
    const { loadManageDocumentTypes } = this.props;
    loadManageDocumentTypes();
  }

  addNewDocumentType = () => {
    const { blockAddDocumentType } = this.state;
    if (!blockAddDocumentType) {
      this.setState({
        showAddDocumentFields: true,
      });
    }
  }

  onChangeNewDocumentObj = ({ target }) => {
    const { addDocumentType } = this.state;
    this.setState({
      addDocumentType: {
        ...addDocumentType,
        [target.name]: target.value,
      },
    }, this.newDocumentValidation);
  }

  newDocumentValidation = () => {
    const { addDocumentType } = this.state;
    this.setState({
      blockSaveDocumentType: !!addDocumentType.Name.trim(),
      blockAddDocumentType: !!addDocumentType.Name.trim(),
    });
  };

  newDocumentTypeCancel = () => {
    this.setState({
      addDocumentType: { Name: '' },
      showAddDocumentFields: false,
      blockSaveDocumentType: false,
      blockAddDocumentType: false,
    });
  }

  newDocumentApply = () => {
    const { createNewDocumentTypes } = this.props;
    const { blockSaveDocumentType, addDocumentType } = this.state;
    if (blockSaveDocumentType) {
      createNewDocumentTypes(addDocumentType);
      this.setState({
        addDocumentType: { Name: '' },
        showAddDocumentFields: false,
        blockSaveDocumentType: false,
        blockAddDocumentType: false,
      });
    }
  }

  deleteDocumentTypes = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `document_types/${data.Id}`,
      searchUpdateSaga: 'document_types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateDocumentTypes } = this.props;
    const { Name, Id } = data;
    updateDocumentTypes(Name, Id);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  render() {
    const { manageDocumentType, listSettings } = this.props;
    const {
      columnsToDisplay, showAddDocumentFields, addDocumentType, blockAddDocumentType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsDocumentTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewDocumentType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search document type'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(manageDocumentType, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteDocumentTypes}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {
          showAddDocumentFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addDocumentType}
                onChangeNewDocumentObj={this.onChangeNewDocumentObj}
                disableSaveDocument={blockAddDocumentType}
                newDocumentCancel={this.newDocumentTypeCancel}
                newDocumentApply={this.newDocumentApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageDocumentTypes.propTypes = {
  loadManageDocumentTypes: PropTypes.func,
};

export default connect((store) => ({
  manageDocumentType: store.adminManageLookups.documentTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageDocumentTypes,
  createNewDocumentTypes,
  updateDocumentTypes,
  showDialog,
  setListSettingsLookups,
})(ManageDocumentTypes);
