import React from 'react';
import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import isObjectNotEquals from 'utils/object-comparison';
import history from 'utils/history';
import {
  NON_BILLABLE_TRAINING, TIME_AND_MATERIAL, FINISHED, EXTENDED, MONTH_AMOUNT_FOR_FIVE_YEARS, MILESTONE_BACKUP_USED_COLUMN_NAME,
} from 'utils/const-variable';
import { getStatusesForRequiredComment } from 'pages/projects/components/utils/createArrayWithStatusMatch';
import { EditAssociatedRoles } from 'pages/projects/components/edit-associated-roles/edit-associated-roles';
import { editRole } from 'pages/projects/actions/roleModalActions';
import { addNewSOW, editCurrentSOW } from 'pages/projects/actions/contractsActions';
import { addSowToPartialContract, cancelImport } from 'pages/projects/actions/importContractAction';
import { getCorrectDataForEditRole } from 'services/getCorrectDataForEditRole.js';
import {
  required, validateDate, complexRequired, validateMilestone, validateRenewalStatus,
} from 'services/redux-form-validators.js';
import { getAllCheckedRolesAndEmployees } from 'utils/getAllCheckedRolesAndEmployees.js';

import renderTextField from '../components/text-field';
import renderSelect from '../components/select';
import renderDatePicker from '../components/date-picker';
import renderCheckbox from '../components/check-box';
import renderDocumentList from '../components/document-list';
import renderMilestoneList from '../components/milestone-list';
import renderSearchDropdown from '../components/search-dropdown';
import Button from '../components/button';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { getChangeConfigForPatchRequests, combinedConfigsForPatchRequests } from 'utils/getConfigBasedOnCheckedDates';
import { renderRenewalStatusBlock } from '../components/sow-renewal-status-block';
import { getConfigBasedOnCheckedDates } from 'utils/getConfigBasedOnCheckedDates';

import '../redux-form.css';
import './project-edit-SOW-form.css';

const defaultStyles = {
  container: 'redux-form__field-container edit-SOW__simple-container',
  label: 'redux-form__SOW--label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const validateApprover = (value, values, props, field) => {
  const condition = !(props.projectType && props.projectType.Name === NON_BILLABLE_TRAINING && values.Type && values.Type.Name === TIME_AND_MATERIAL);
  return complexRequired(value, values, props, field, condition);
};

const validateComment = (value, values, props, field) => {
  const isMatchObject = { docStatus: values.Status && values.Status.Id, approveStatus: values.ApprovalStatus && values.ApprovalStatus.Id };
  const condition = !!getStatusesForRequiredComment(props.allStatuses, props.allApprovalStatuses).find((item) => !isObjectNotEquals(item, isMatchObject));
  return complexRequired(value, values, props, field, condition);
};

const validateEndDate = (value, values, props, field) => {
  const condition = values.Status && (values.Status.Name === EXTENDED || values.Status.Name === FINISHED);
  return complexRequired(value, values, props, field, condition);
};

const additionalConditionForContract = (role, StartDateContract, EndDateContract) => moment(role.StartDate).isSameOrAfter(StartDateContract, 'day')
      && moment(role.EndDate).isSameOrBefore(EndDateContract, 'day');

class ProjectEditSOWForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      associatedRoles: [],
      showAssociatedRolesModal: false,
      isNeedToChangeDate: false,
      showConfirmationDialog: false,
      messageForConfirmDialog: '',
      configPatchRequestsForConfirmDialog: null,
      importContractDatesCheck: [],
    };

    this.showAsterix = {
      Approver: () => {
        const { projectType, Type } = this.props;
        if (projectType && Type && Type.Name) return projectType.Name !== NON_BILLABLE_TRAINING || Type.Name !== TIME_AND_MATERIAL;
        return true;
      },
      EndDate: () => {
        const { Status } = this.props;
        return Status && (Status.Name === EXTENDED || Status.Name === FINISHED);
      },
      StatusComments: () => {
        const {
          allStatuses, allApprovalStatuses, Status, ApprovalStatus,
        } = this.props;
        const isMatchObject = {
          docStatus: Status && Status.Id,
          approveStatus: ApprovalStatus && ApprovalStatus.Id,
        };
        return !!getStatusesForRequiredComment(allStatuses, allApprovalStatuses).find((item) => !isObjectNotEquals(item, isMatchObject));
      },
    };
    this.disable = {
      Approver: () => {
        const { Company } = this.props;
        return !Company;
      },
    };
  }

  componentDidMount() {
    const {
      data, initialize, ProjectEndDate, datesForCheckChildContracts, ProjectStartDate, importContract,
    } = this.props;
    const importContractDatesCheck = [];
    initialize(data);
    if (Object.keys(importContract).length) {
      if ((ProjectEndDate && data.EndDate) && new Date(ProjectEndDate) < new Date(data.EndDate)) {
        const config = getConfigBasedOnCheckedDates(
          { startDatesParentContracts: this.getStartDatesForDatePicker(), endDatesParentContracts: this.getEndDatesForDatePicker() },
          datesForCheckChildContracts,
          new Date(data.EndDate),
          'SOW',
          false,
          false,
          new Date(data.EndDate),
        );
        config && importContractDatesCheck.push(config);
      }
      if ((ProjectStartDate && data.StartDate) && new Date(ProjectStartDate) > new Date(data.StartDate)) {
        const config = getConfigBasedOnCheckedDates(
          { startDatesParentContracts: this.getStartDatesForDatePicker(), endDatesParentContracts: this.getEndDatesForDatePicker() },
          datesForCheckChildContracts,
          new Date(data.StartDate),
          'SOW',
          true,
          false,
          new Date(data.StartDate),
        );
        config && importContractDatesCheck.push(config);
      }
    }
    this.setState({
      importContractDatesCheck,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data, Milestones, change, StartDate, EndDate, showAlertDialog, Status,
    } = this.props;
    const {
      configPatchRequestsForConfirmDialog, isNeedToChangeDate, importContractDatesCheck, showConfirmationDialog,
    } = this.state;
    const { Milestones: existMilestones, SowRenewalStatuses } = data;
    if (importContractDatesCheck.length && !showConfirmationDialog) {
      this.showConfirmationDialog(importContractDatesCheck.at(-1));
      this.setState({
        importContractDatesCheck: importContractDatesCheck.slice(0, -1),
      });
    }
    if (!prevProps.data.Milestones.length && JSON.stringify(prevProps.data.Milestones) !== JSON.stringify(existMilestones)) {
      change('Milestones', existMilestones);
    }
    if (prevProps.StartDate !== StartDate || prevProps.EndDate !== EndDate) {
      const isStartDateChanged = prevProps.StartDate !== StartDate;
      const isEndDateChanged = prevProps.EndDate !== EndDate;
      const endDateForMilestones = EndDate || moment(StartDate).clone().add(MONTH_AMOUNT_FOR_FIVE_YEARS, 'months').format();
      const milestonesOutOfRange = Milestones.filter(milestone => {
        return ((isStartDateChanged
          && StartDate
          && !moment(moment(StartDate).startOf('month').format()).isBefore(moment(new Date(milestone.Milestone), 'MMMM, YYYY').days(15).format()))
          || (isEndDateChanged
          && endDateForMilestones
          && !moment(moment(endDateForMilestones).endOf('month').format()).isAfter(moment(new Date(milestone.Milestone), 'MMMM, YYYY')
            .days(15).format())));
      });
      if (milestonesOutOfRange.length) {
        showAlertDialog(`${moment(new Date(milestonesOutOfRange[0].Milestone)).format('MMMM YYYY')} milestone is out of range. Please, delete it first.`);
        isStartDateChanged && change('StartDate', null);
        isEndDateChanged && change('EndDate', null);
      }
      if (!EndDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'endDate'),
        });
      }
      if (!StartDate && isNeedToChangeDate) {
        this.setState({
          configPatchRequestsForConfirmDialog: getChangeConfigForPatchRequests(configPatchRequestsForConfirmDialog, 'startDate'),
        });
      }
    }
    if (JSON.stringify(prevProps.data.SowRenewalStatuses) !== JSON.stringify(SowRenewalStatuses)) {
      change('SowRenewalStatuses', SowRenewalStatuses);
    }
    if (prevProps.Status && isObjectNotEquals(prevProps.Status, Status) && Status.Name === 'Extended') {
      change('SowRenewalStatuses', this.setRenewalStatusExtended());
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  onChange = (data, value, prevValue, field) => {
    const { change, milestonesFromDB, Milestones } = this.props;
    const isTypeNoActualTime = data && data.Name !== TIME_AND_MATERIAL;
    const newMilestones = isTypeNoActualTime && Milestones.map(milestone => {
      const milestoneFromDB = milestonesFromDB.find(milestoneFromDB => milestoneFromDB.Milestone === milestone.Milestone);
      return {
        ...milestone,
        UsedAssignmentBackupUser: milestoneFromDB && milestoneFromDB.UsedAssignmentBackupUser || null,
      };
    });
    switch (field) {
      case 'Company':
        change('Approver', '');
        break;
      case 'Type':
        isTypeNoActualTime && change('Milestones', newMilestones);
        break;
      default: break;
    }
  }

  addNewMilestone = (milestones) => {
    const { change } = this.props;
    change('Milestones', milestones);
  }

  checkForAssociatedRoles = () => {
    const {
      StartDate, EndDate, initial, isOnlyActiveRoles, Project, Status,
    } = this.props;
    if (initial.Status && initial.Status.Id !== Status.Id && Status.Name === FINISHED) {
      this.changeAssociatedRoleStatus();
      return false;
    }
    if (initial.StartDate !== StartDate || (initial.EndDate !== EndDate && moment(EndDate).isValid())) {
      this.changeAssociatedRoleDates();
      return false;
    }
    this.editSOW(Project.Id, !isOnlyActiveRoles);
  }

  changeAssociatedRoleStatus = () => {
    const {
      RolesForContarct, Id, isOnlyActiveRoles, Project,
    } = this.props;
    const associatedRoles = RolesForContarct.filter(role => role.SowPoId === Id);
    const allCheckedRolesAndEmployees = getAllCheckedRolesAndEmployees(associatedRoles);
    if (allCheckedRolesAndEmployees.length) {
      this.setState({
        associatedRoles: allCheckedRolesAndEmployees,
        showAssociatedRolesModal: true,
      });
    } else {
      this.editSOW(Project.Id, !isOnlyActiveRoles);
    }
  }

  changeAssociatedRoleDates = () => {
    const {
      RolesForContarct, Id, isOnlyActiveRoles, Project, StartDate, EndDate,
    } = this.props;
    const associatedRoles = RolesForContarct.filter(role => role.SowPoId === Id);
    const checkedRoles = associatedRoles.reduce((roles, role) => {
      const rolesWithCheckedEmployees = role.Employees.reduce((employees, employee) => {
        employees.push({
          ...employee,
          checked: employee.Status.Name !== 'Removed',
          disabledItem: employee.Status.Name === 'Removed' || !additionalConditionForContract(employee, StartDate, EndDate),
        });
        return employees;
      }, []);
      roles.push({
        ...role,
        checked: role.Status.Name !== 'Removed',
        Employees: [...rolesWithCheckedEmployees],
        disabledItem: role.Status.Name === 'Removed' || !additionalConditionForContract(role, StartDate, EndDate),
      });
      return roles;
    }, []);
    if (checkedRoles.length) {
      this.setState({
        associatedRoles: checkedRoles,
        showAssociatedRolesModal: true,
      });
    } else {
      this.editSOW(Project.Id, !isOnlyActiveRoles);
    }
  }

  closeAssociatedRolesModal = () => {
    this.setState({ showAssociatedRolesModal: false });
  }

  applyAssociatedRoles = (roles) => {
    const {
      Project, StartDate, EndDate, isOnlyActiveRoles, editRole, Status, initial,
    } = this.props;
    if (roles.length) {
      const checkedRoles = roles.filter(role => role.checked);
      if (checkedRoles.length) {
        const startDateForUpdateRoles = !moment(StartDate).isSame(initial.StartDate) ? StartDate : null;
        const endDateForUpdateRoles = !moment(EndDate).isSame(initial.EndDate) ? EndDate : null;
        const rolesToUpdate = getCorrectDataForEditRole(checkedRoles, startDateForUpdateRoles, endDateForUpdateRoles, Status, Project.Id);
        rolesToUpdate.forEach(role => editRole(role, !isOnlyActiveRoles));
      }
    }
    this.closeAssociatedRolesModal();
    this.editSOW(Project.Id, !isOnlyActiveRoles);
  }

  applyForm = () => {
    const {
      Id, isOnlyActiveRoles, Project, cancelContractImport, importContract,
    } = this.props;
    Id ? this.checkForAssociatedRoles() : this.addSOW(Project.Id, !isOnlyActiveRoles);
    Object.keys(importContract).length && cancelContractImport(Project.Id);
  }

  addSOW = (projectId, isOnlyActiveRoles) => {
    const { addSOW } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    addSOW(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
    history.push({
      pathname: `/projects/${projectId}`,
    });
  }

  editSOW = (projectId, isOnlyActiveRoles) => {
    const { editSOW, location } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    const saveCallback = (location.state && location.state.saveCallback) || `/projects/${projectId}`;

    editSOW(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, saveCallback);
  }

  closeConfirmationDialog = () => {
    const { change } = this.props;
    const { typeDateForDeleteIfUserCloseConfirmationDialog } = this.state;
    this.setState({
      showConfirmationDialog: false,
      isNeedToChangeDate: false,
    });
    change(typeDateForDeleteIfUserCloseConfirmationDialog, null);
  }

  showConfirmationDialog = (configForConfirmDialog) => {
    const { configPatchRequestsForConfirmDialog } = this.state;
    this.setState({
      showConfirmationDialog: true,
      messageForConfirmDialog: configForConfirmDialog.message,
      typeDateForDeleteIfUserCloseConfirmationDialog: configForConfirmDialog.typeChangedDate,
      configPatchRequestsForConfirmDialog:
        combinedConfigsForPatchRequests(configForConfirmDialog.configForPatchRequests, configPatchRequestsForConfirmDialog),
    });
  }

  onConfirmDialog = () => {
    this.setState({
      isNeedToChangeDate: true,
      showConfirmationDialog: false,
    });
  }

  setRenewalStatusExtended = () => {
    const { SowRenewalStatuses } = this.props;
    return SowRenewalStatuses.map((status) => status.Name === 'Renewal'
      ? ({ ...status, IsActive: true })
      : ({ ...status, IsActive: false }));
  }

  getStartDatesForDatePicker = () => {
    const { ProjectStartDate, projectId } = this.props;
    return [{ date: ProjectStartDate, type: 'Project', id: projectId }];
  }

  getEndDatesForDatePicker = () => {
    const { ProjectEndDate, projectId } = this.props;
    return [{ date: ProjectEndDate, type: 'Project', id: projectId }];
  }

  onSaveAndCreateRoles = () => {
    const {
      addImportSow, isOnlyActiveRoles, Project,
    } = this.props;
    const { isNeedToChangeDate, configPatchRequestsForConfirmDialog } = this.state;
    addImportSow(Project.Id, !isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog);
  }

  onCancelClick = () => {
    const { cancelContractImport, Project } = this.props;
    cancelContractImport(Project.Id);
  }

  render() {
    const {
      showAssociatedRolesModal, associatedRoles, showConfirmationDialog, messageForConfirmDialog,
    } = this.state;
    const {
      disabled, allSOWTypes, allApprovalStatuses, allClients, allApprovers, allStatuses,
      columnsToDisplay, viewFinancialInfo, allDocumentTypes, allPaymentTerms,
      Contract, Type, ApprovalStatus, InvoiceDate, Company, Approver, Id,
      StartDate, EndDate, BusinessGroup, ProjectTeam, Status, PaymentTerm,
      TravelBudget, TravelReimbursement, Links, Milestones, formErrors, BudgetComments, handleSubmit, StatusComments, ProjectEndDate,
      BillingCode, showAlertDialog, invoiceDates, currencyProject, allRenewalStatuses, SowRenewalStatuses, projectId,
      datesForCheckChildContracts, allRolesForContract, importRoles,
    } = this.props;
    const isTypeActualTime = Type && Type.Name === TIME_AND_MATERIAL;
    const milestonesListColumnsToDisplay = isTypeActualTime ? columnsToDisplay.milestonesList
      : columnsToDisplay.milestonesList.filter(column => column.Name !== MILESTONE_BACKUP_USED_COLUMN_NAME);
    return (
      <form className='redux-form height-100pc' onSubmit={handleSubmit}>
        {
          showAssociatedRolesModal ? (
            <EditAssociatedRoles
              items={associatedRoles}
              closeModal={this.closeAssociatedRolesModal}
              apply={this.applyAssociatedRoles}
              startDateContract={StartDate}
              endDateContract={EndDate}
              allRoles={allRolesForContract}
              idContract={Id}
            />
          ) : null
        }
        {
          showConfirmationDialog ? (
            <ConfirmationDialog
              dialogHeader='CHANGE DATE'
              dialogTitle={messageForConfirmDialog}
              closeButtonTitle='Cancel'
              confirmButtonTitle='OK'
              closeDialog={this.closeConfirmationDialog}
              confirmDialog={this.onConfirmDialog}
            />
          ) : null
        }
        <div>
          <div className='redux-form__row justify-start'>
            <Field
              name='Contract'
              value={Contract || ''}
              component={renderTextField}
              onChange={this.onChange}
              label='SOW Id'
              placeholder='Set SOW Id'
              maxlength='100'
              styles={defaultStyles}
              disabled={disabled}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              inputClassName='edit-SOW__text-field'
            />
            <Field
              name='Type'
              value={Type}
              component={renderSelect}
              options={allSOWTypes}
              onChange={this.onChange}
              label='SOW Type'
              placeholder='Set SOW Type'
              styles={defaultStyles}
              disabled={disabled}
              isOptionObject
              extendedValue
              inputProps={{ id: 'sow_type' }}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
            <Field
              name='ApprovalStatus'
              value={ApprovalStatus}
              component={renderSelect}
              options={allApprovalStatuses}
              onChange={this.onChange}
              label='SOW Approval Status'
              placeholder='Set SOW Approval Status'
              styles={defaultStyles}
              disabled={disabled}
              isOptionObject
              extendedValue
              inputProps={{ id: 'sow_approval_status' }}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
            <Field
              name='Company'
              value={Company}
              options={allClients}
              component={renderSearchDropdown}
              onChange={this.onChange}
              label='Company Name'
              placeholder='Set Company Name'
              validate={required}
              disabled={disabled}
              inputProps={{ id: 'client' }}
              showAsterix
              isAddingFeature
              styles={defaultStyles}
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__search-dropdown'
            />
          </div>
          <div className='redux-form__row justify-start'>
            <Field
              name='Approver'
              value={Approver}
              options={allApprovers.filter(approver => Company && approver.ClientId === Company)}
              component={renderSearchDropdown}
              onChange={this.onChange}
              label='Client`s Invoice Approver'
              placeholder='Set Client`s Invoice Approver'
              validate={validateApprover}
              disabled={this.disable.Approver() || disabled}
              inputProps={{ id: 'invoice_approver' }}
              showAsterix={this.showAsterix.Approver()}
              isAddingFeature
              styles={defaultStyles}
              parentEntityId={Company}
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__search-dropdown'
            />
            <Field
              name='BillingCode'
              value={BillingCode || ''}
              component={renderTextField}
              onChange={this.onChange}
              label='Billing Code'
              placeholder='Set Billing Code'
              maxlength='50'
              styles={defaultStyles}
              disabled={disabled}
              formClassName='edit-SOW__item-form'
              inputClassName='edit-SOW__text-field'
            />
          </div>
          <div className='redux-form__row justify-start'>
            <Field
              name='StartDate'
              value={StartDate}
              component={renderDatePicker}
              onChange={this.onChange}
              validate={[required, validateDate.startDate, validateDate.isValid]}
              disabled={disabled}
              showAsterix
              isStartDate
              setPrevValueOnError
              placeholder='--/--/--'
              label='SOW Start Date'
              pickerName='startDateSOW'
              styles={defaultStyles}
              formClassName='edit-SOW__item-form'
              datePickerClassName='edit-SOW__date-picker'
              showAlertDialog={showAlertDialog}
              showConfirmationDialog={this.showConfirmationDialog}
              endDates={this.getEndDatesForDatePicker()}
              startDates={this.getStartDatesForDatePicker()}
              datesForCheckChildContracts={datesForCheckChildContracts}
              typeParent='project'
              typeCurrentDocument='SOW'
              endDateContract={EndDate}
            />
            <Field
              name='EndDate'
              value={EndDate}
              component={renderDatePicker}
              onChange={this.onChange}
              validate={[validateEndDate, validateDate.endDate, validateDate.isValid]}
              showAsterix={this.showAsterix.EndDate()}
              disabled={disabled}
              isEndDate
              setPrevValueOnError
              placeholder='--/--/--'
              label='SOW End Date'
              pickerName='endDateSOW'
              styles={defaultStyles}
              formClassName='edit-SOW__item-form'
              datePickerClassName='edit-SOW__date-picker'
              minDate={StartDate}
              showAlertDialog={showAlertDialog}
              showConfirmationDialog={this.showConfirmationDialog}
              typeParent='project'
              endDates={this.getEndDatesForDatePicker()}
              startDates={this.getStartDatesForDatePicker()}
              datesForCheckChildContracts={datesForCheckChildContracts}
              typeCurrentDocument='SOW'
              startDateContract={StartDate}
            />
            <Field
              name='InvoiceDate'
              value={InvoiceDate}
              component={renderSelect}
              options={invoiceDates}
              onChange={this.onChange}
              label='Invoice Date'
              placeholder='Set Invoice Date'
              styles={defaultStyles}
              disabled={disabled}
              isOptionObject
              extendedValue
              inputProps={{ id: 'invoice_date' }}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
            <Field
              name='BusinessGroup'
              value={BusinessGroup}
              component={renderTextField}
              onChange={this.onChange}
              label='Business Group'
              placeholder='Set Business Group'
              styles={defaultStyles}
              disabled={disabled}
              formClassName='edit-SOW__item-form'
              inputClassName='edit-SOW__text-field'
            />
            <Field
              name='ProjectTeam'
              value={ProjectTeam}
              component={renderTextField}
              onChange={this.onChange}
              label='Project Team'
              placeholder='Set Project Team'
              styles={defaultStyles}
              disabled={disabled}
              formClassName='edit-SOW__item-form'
              inputClassName='edit-SOW__text-field'
            />
          </div>
          <div className='redux-form__row justify-start'>
            <Field
              name='Status'
              value={Status}
              component={renderSelect}
              options={allStatuses}
              onChange={this.onChange}
              label='Document Status'
              placeholder='Set Document Status'
              styles={defaultStyles}
              disabled={disabled}
              isOptionObject
              extendedValue
              inputProps={{ id: 'document_status' }}
              validate={required}
              showAsterix
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
            <Field
              name='PaymentTerm'
              value={PaymentTerm}
              options={allPaymentTerms}
              component={renderSelect}
              onChange={this.onChange}
              label='Payment Term'
              placeholder='Choose Payment Term'
              styles={defaultStyles}
              disabled={disabled}
              isOptionObject
              extendedValue
              inputProps={{ id: 'payment_term' }}
              formClassName='edit-SOW__item-form'
              selectClassName='edit-SOW__select'
            />
            <div className='edit-SOW__simple-container'>
              <Field
                name='TravelBudget'
                value={TravelBudget}
                component={renderCheckbox}
                onChange={this.onChange}
                label='Travel Budget included'
                styles={{
                  ...defaultStyles,
                  container: '',
                }}
                disabled={disabled}
                checkboxContainerStyle='edit-SOW__item-form edit-SOW__checkbox-container'
              />
              <Field
                name='TravelReimbursement'
                value={TravelReimbursement}
                component={renderCheckbox}
                onChange={this.onChange}
                label='Travel reimbursement'
                styles={{
                  ...defaultStyles,
                  container: '',
                }}
                disabled={disabled}
                checkboxContainerStyle='edit-SOW__item-form edit-SOW__checkbox-container'
              />
            </div>
          </div>
          <div className='redux-form__row justify-start row__items--align-start'>
            <Field
              name='StatusComments'
              value={StatusComments}
              component={renderTextField}
              onChange={this.onChange}
              label='Status Comments'
              placeholder='Add Status Comments'
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container margin-left-right-10',
                field: 'full-width-field height-100pc',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
              disabled={disabled}
              showAsterix={this.showAsterix.StatusComments()}
              validate={validateComment}
              maxlength='4000'
              fullWidth
              multiline
              inputClassName='edit-SOW__text-field'
            />
            {
              viewFinancialInfo
              && <Field
                name='BudgetComments'
                value={BudgetComments}
                component={renderTextField}
                onChange={this.onChange}
                label='Budget Comments'
                placeholder='Add Budget Comments'
                styles={{
                  ...defaultStyles,
                  container: 'redux-form__field-container flexible-container margin-left-right-10',
                  field: 'full-width-field height-100pc',
                  fieldContent: 'standart-content-padding align-center height-100pc',
                  fieldView: 'field__view-container height-100pc',
                }}
                disabled={disabled}
                maxlength='4000'
                fullWidth
                multiline
                inputClassName='edit-SOW__text-field'
              />
            }
          </div>
          <div className='redux-form__row justify-start'>
            <Field
              name='SowRenewalStatuses'
              value={SowRenewalStatuses}
              component={renderRenewalStatusBlock}
              onChange={this.onChange}
              allRenewalStatuses={allRenewalStatuses}
              disabled={disabled}
              columnsToDisplay={columnsToDisplay.sowRenewalStatusesList}
              validate={validateRenewalStatus}
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container margin-left-right-10',
                field: 'full-width-field height-100pc',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
            />
          </div>
          <div className='redux-form__row justify-start'>
            {viewFinancialInfo && <Field
              name='Milestones'
              formName='projectEditSOWForm'
              parentType='SOW'
              childType='Amendments'
              value={Milestones}
              component={renderMilestoneList}
              disabled={disabled}
              onChange={this.onChange}
              onAddNewMilestone={this.addNewMilestone}
              columnsToDisplay={milestonesListColumnsToDisplay}
              viewFinancialInfo={viewFinancialInfo}
              startDate={StartDate}
              validate={validateMilestone}
              currencyProject={currencyProject}
              endDate={EndDate}
              projectEndDate={ProjectEndDate}
              SOWId={Id}
              projectId={projectId}
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container margin-left-right-10',
                field: 'full-width-field height-100pc',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
            />}
          </div>
          <div className='redux-form__row justify-start'>
            {viewFinancialInfo && <Field
              name='Links'
              value={Links}
              component={renderDocumentList}
              onChange={this.onChange}
              disabled={disabled}
              columnsToDisplay={columnsToDisplay.documentsList}
              viewFinancialInfo={viewFinancialInfo}
              documentTypes={allDocumentTypes}
              isDocumentType
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container margin-left-right-10',
                field: 'full-width-field height-100pc',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
            />}
          </div>
        </div>
        <div className='redux-form__SOW--buttons'>
          { (importRoles && !!importRoles.length) && <Button
            onClick={this.onSaveAndCreateRoles}
            disabled={!!formErrors}
            visible={!disabled}
            className={classNames('button next button-create-roles', { 'btn-disabled': !!formErrors })}
            text='Save and Create Roles'
          /> }
          <Button
            onClick={this.onCancelClick}
            className='button cancel'
            text={disabled ? 'Back' : 'Cancel'}
          />
          <Button
            onClick={this.applyForm}
            disabled={!!formErrors}
            visible={!disabled}
            className={classNames('button next', { 'btn-disabled': !!formErrors })}
            text='Apply'
          />
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('projectEditSOWForm', data)),
    destroy: () => dispatch(destroy('projectEditSOWForm')),
    change: (field, value) => dispatch(change('projectEditSOWForm', field, value)),
    addSOW:
      (projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => (
        dispatch(addNewSOW(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog))),
    editSOW:
      (projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, saveCallback) => (
        dispatch(editCurrentSOW(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog, saveCallback))),
    editRole: (role, isOnlyActiveRoles) => dispatch(editRole(role, isOnlyActiveRoles)),
    showAlertDialog: (message) => dispatch(showAlertDialog(message)),
    addImportSow: (projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog) => (
      dispatch(addSowToPartialContract(projectId, isOnlyActiveRoles, isNeedToChangeDate, configPatchRequestsForConfirmDialog))),
    cancelContractImport: (id) => dispatch(cancelImport(id)),
  };
};

export default connect(state => {
  const isFormReducerExists = state.form.projectEditSOWForm && state.form.projectEditSOWForm.values;
  return {
    Id: isFormReducerExists && state.form.projectEditSOWForm.values.Id,
    Contract: isFormReducerExists && state.form.projectEditSOWForm.values.Name,
    Type: isFormReducerExists && state.form.projectEditSOWForm.values.Type,
    ApprovalStatus: isFormReducerExists && state.form.projectEditSOWForm.values.ApprovalStatus,
    Company: isFormReducerExists && state.form.projectEditSOWForm.values.Company,
    Approver: isFormReducerExists && state.form.projectEditSOWForm.values.Approver,
    StartDate: isFormReducerExists && state.form.projectEditSOWForm.values.StartDate,
    EndDate: isFormReducerExists && state.form.projectEditSOWForm.values.EndDate,
    BillingCode: isFormReducerExists && state.form.projectEditSOWForm.values.BillingCode,
    BusinessGroup: isFormReducerExists && state.form.projectEditSOWForm.values.BusinessGroup,
    ProjectTeam: isFormReducerExists && state.form.projectEditSOWForm.values.ProjectTeam,
    Status: isFormReducerExists && state.form.projectEditSOWForm.values.Status,
    PaymentTerm: isFormReducerExists && state.form.projectEditSOWForm.values.PaymentTerm,
    TravelBudget: isFormReducerExists && state.form.projectEditSOWForm.values.TravelBudget,
    TravelReimbursement: isFormReducerExists && state.form.projectEditSOWForm.values.TravelReimbursement,
    StatusComments: isFormReducerExists && state.form.projectEditSOWForm.values.StatusComments,
    Links: isFormReducerExists && state.form.projectEditSOWForm.values.Links,
    Milestones: isFormReducerExists && state.form.projectEditSOWForm.values.Milestones,
    POs: isFormReducerExists && state.form.projectEditSOWForm.values.POs,
    Project: isFormReducerExists && state.form.projectEditSOWForm.values.Project,
    BudgetComments: isFormReducerExists && state.form.projectEditSOWForm.values.BudgetComments,
    SowRenewalStatuses: isFormReducerExists && state.form.projectEditSOWForm.values.SowRenewalStatuses,
    formErrors: isFormReducerExists && state.form.projectEditSOWForm.syncErrors,
    initial: isFormReducerExists && state.form.projectEditSOWForm.initial,
    isOnlyActiveRoles: state.showRoles.isOnlyActiveRoles,
    allPaymentTerms: state.adminManageLookups.paymentTerms,
    ProjectStartDate: state.projectsReducer.projectDetails.StartDate,
    ProjectEndDate: state.projectsReducer.projectDetails.EndDate,
    importRoles: state.importContractReducer.contract.Roles,
    importContract: state.importContractReducer.contract,
  };
}, mapDispatchToProps)(reduxForm({
  form: 'projectEditSOWForm',
})(ProjectEditSOWForm));
