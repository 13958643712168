import React, { Component } from 'react';
import CustomIcon from 'common/components/icon/Icon.js';
import './no-editable-block.css';

class NoEditableItemBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHintPopup: false,
    };
  }

  showHintPopup = () => {
    this.setState({
      showHintPopup: true,
    });
  }

  hideHintPopup = () => {
    this.setState({
      showHintPopup: false,
    });
  }

  render() {
    const {
      label, value, spanValueStyles, blockClassName, handleValueBlockClick, isArray,
      showQuestion, questionText,
    } = this.props;
    const { showHintPopup } = this.state;
    return (
      <div
        className={`redux-form__field-container employees-form__standart-container ${blockClassName && blockClassName}`}
        title={!isArray ? value && value.toString() : value.map(item => item && item.name).join(',')}
      >
        <div className='no-edit-field__label label__question-icon--position'>
          <label className='redux-form__label'>{label}</label>
          {showQuestion && <div
            onMouseEnter={this.showHintPopup}
            onMouseLeave={this.hideHintPopup}
          >
            <CustomIcon iconName='question-mark' className='no-edit-field__label--question-icon' />
            {showHintPopup && <div className='filter-button_hint-body no-edit-block__filter-button--hint-body'>
              <span>{questionText}</span>
            </div>}
          </div>}
        </div>
        <div className='field no-editable-block--block-margin'>
          {!isArray
            ? <>{value && <span
              onClick={handleValueBlockClick && handleValueBlockClick}
              style={(spanValueStyles && spanValueStyles.style) && spanValueStyles.style}
              className={(spanValueStyles && spanValueStyles.className) && spanValueStyles.className}
            >{value}</span>}</>
            : <div className='no-editable-block--array'>
              {value.map(element => <span>{element.name}</span>)}
            </div>}
        </div>
      </div>
    );
  }
}

export default NoEditableItemBlock;
