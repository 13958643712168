/* eslint-disable indent */
import React from 'react';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames';
import CustomIcon from '../icon/Icon';
import ManageItem from './manage-item.js';
import { arrayMoveImmutable } from 'array-move';
import sortArrayByPriority from 'utils/sortArrayByPriority';
import { NO_ITEMS_TITLE } from 'utils/const-variable';
import './document-list.css';

const DragHandle = sortableHandle(() => <CustomIcon iconName='silver-sidebar-menu' />);

const SortableItem = SortableElement(({ item, props }) => {
  return (<li className='sort-item__li'>
    <span className='sort-item__span'>
      <DragHandle />
    </span>
    <ManageItem
      key={item.Id || item}
      itemId={item.Id}
      item={item}
      columnsToDisplay={props.columnsToDisplay}
      onItemClick={props.onItemClick}
      onEditClick={props.onEditClick}
      onDeleteClick={props.onDeleteClick}
      hideDots={props.hideDots}
      onEditOptions={props.onEditOptions}
      isEditable={props.isEditable}
      showItemsEditMenu={props.showItemsEditMenu}
      showCheckboxes={props.showCheckboxes}
      checkItem={props.checkItem}
      checkedItem={props.entityForMerge && props.entityForMerge.includes(item.Id)}
    />
  </li>);
});

const SortableList = SortableContainer(({ items, props }) => {
  return (
    <ul className='sort-item__ul'>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={value}
          props={props}
        />
      ))}
    </ul>
  );
});

export class SortableManageList extends React.Component {
  constructor(props) {
    super(props);
    const { items } = this.props;
    this.state = {
      items: sortArrayByPriority(items),
    };
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (prevProps.items !== items) {
      this.setState({
        items: sortArrayByPriority(items),
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onUpdateOrder } = this.props;
    this.setState(({ items }) => ({
      items: arrayMoveImmutable(items, oldIndex, newIndex),
    }), () => {
      const { items } = this.state;
      const newArray = this.newListSortByPriority(items);
      onUpdateOrder(newArray);
    });
  };

  newListSortByPriority = (arr) => {
    return arr.reduce((acc, item, index) => {
      const newObj = {
        ...item,
        Priority: index + 1,
      };
      acc.push(newObj);
      return acc;
    }, []);
  }

  render() {
    const {
      items: itemsProps,
      columnsToDisplay,
      setSortSettings,
      sortColumnName,
      sortDirection,
      actionLabel,
      showCheckboxes,
      showSortIcons,
    } = this.props;
    const { items } = this.state;
    return (
      <div>
        <div className={classNames('c-list__header')}>
          {
            columnsToDisplay && columnsToDisplay.map((column) => column.isSelected
              ? (<div key={JSON.stringify(column)} className={classNames('c-list__header-col', column.className)}>
                {showCheckboxes ? <div className='c-list__header-col smallest-col' /> : null}
                <div className='c-list__header-name'>
                  {column.breakline ? (
                    <span>
                      {column.Name.split(' ')[0]}
                      <br />
                      {column.Name.split(' ')[1]}
                    </span>
                  ) : (
                    <span>
                      {column.Name}
                    </span>
                  )}
                  {showSortIcons
                    ? <div className={`c-list__arrows ${column.deleteArrow ? column.deleteArrow : null}`}>
                      {sortColumnName === column.Id && sortDirection === 'Up'
                        ? <CustomIcon
                            iconName='arrow-up-selected'
                            onClick={() => setSortSettings && setSortSettings(column.Id, 'Up')}
                            className='pointer'
                        />
                        : <CustomIcon
                            iconName='arrow-up-not-selected'
                            onClick={() => setSortSettings && setSortSettings(column.Id, 'Up')}
                            className='pointer'
                        />
                      }
                      {sortColumnName === column.Id && sortDirection === 'Down'
                        ? <CustomIcon
                            iconName='arrow-down-selected'
                            onClick={() => setSortSettings && setSortSettings(column.Id, 'Down')}
                            className='pointer'
                        />
                        : <CustomIcon
                            iconName='arrow-down-notselected'
                            onClick={() => setSortSettings && setSortSettings(column.Id, 'Down')}
                            className='pointer'
                        />
                      }
                    </div>
                    : null}
                </div>
              </div>)
              : null)
          }
          <div className='c-list__options-column' />
          {actionLabel ? <span className='action-lable action-lable-document-margin'>Action</span> : null}
        </div>
        {
          itemsProps.length
            ? (
              <SortableList
                items={items}
                props={this.props}
                onSortEnd={this.onSortEnd}
                useDragHandle
              />
            )
            : <div className='document-list__no-item'>{NO_ITEMS_TITLE}</div>
        }
      </div>
    );
  }
}
