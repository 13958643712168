import React, { Fragment } from 'react';
import classNames from 'classnames';
import DatePicker from 'common/components/form-items/date-picker/date-picker';

export class UpdateManageDateItem extends React.Component {
  render() {
    const {
      id,
      item,
      onChangeNewDocumentObj,
      disableSaveDocument,
      newDocumentCancel,
      newDocumentApply,
    } = this.props;
    return (
      <Fragment>
        <div key={id} className='c-list-item-datepicker'>
          <DatePicker
            formClassName='step__form resume-input'
            inputClassName='step__input'
            id='date'
            placeholder='Date'
            maxlength='4000'
            name='Date'
            value={item && item.Date}
            onChange={onChangeNewDocumentObj}
            autoFocus
            showClear={false}
          />
        </div>
        <div className='resume-input-date'>
          <div className='toolbar-button resume-buttom-cancel' onClick={newDocumentCancel}>
            <span>Cancel</span>
          </div>
          <div
            className={classNames('button next resume-buttom-apply',
              { 'btn-disabled': !disableSaveDocument })}
            onClick={newDocumentApply}
          >
            <span>Apply</span>
          </div>
        </div>
      </Fragment>
    );
  }
}
