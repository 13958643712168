import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_LOCATIONS,
  PUT_MANAGE_LOCATIONS,
  POST_MANAGE_LOCATIONS,
  setManagesLocations,
} from '../actions/adminManageLocations';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageLocations() {
  try {
    yield put(startRequest());
    const locations = yield call(http, {
      method: 'GET',
      url: 'location_offices',
    });
    yield put(setManagesLocations(locations.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putLocation(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `location_offices/${action.payload.Id}`,
      data: {
        Name: action.payload.Name,
        Offices: action.payload.Offices,
      },
    });
    yield call(manageLocations);
  } catch (e) {
    // error message
  }
}

export function* postLocation(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'location_offices',
      data: {
        Name: action.payload.Name,
        Offices: action.payload.Offices,
      },
    });
    yield call(manageLocations);
  } catch (e) {
    // error message
  }
}

export default function* adminManageLocations() {
  yield takeEvery(GET_MANAGE_LOCATIONS, manageLocations);
  yield takeEvery(PUT_MANAGE_LOCATIONS, putLocation);
  yield takeEvery(POST_MANAGE_LOCATIONS, postLocation);
}
