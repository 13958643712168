import React from 'react';
import classNames from 'classnames';
import '../redux-form.css';
import DocumentList from 'common/components/documents-list/documents-list';
import CustomIcon from 'common/components/icon/Icon';
import { UpdateDocumentItem } from 'common/components/documents-list/update-document-item';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import {
  sortDocumentByAlphabet,
  getDocumentTypeName,
} from 'utils/documentsUtils';
import { MAX_QUANTITY_OF_DOCUMENTS } from 'utils/const-variable';
import { sortList } from 'common/components/list/sortList';

const emptyDocument = { URL: '', Comment: '' };

export default class renderDocumentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddNewItem: false,
      isNewItemValid: false,
      newDocument: { ...emptyDocument },
      deleteDocumentIndex: '',
      showConfirmationDialog: false,
      columnDoc: 'Comment',
      sortDirectionDoc: 'Up',
    };
  }

  showAddNew = () => {
    const { input } = this.props;
    if (input.value.length < MAX_QUANTITY_OF_DOCUMENTS) {
      this.setState({
        showAddNewItem: true,
        isNewItemValid: false,
        newDocument: { ...emptyDocument },
      });
    }
  }

  changeNewItem = ({ target }) => {
    const { newDocument } = this.state;
    const { documentTypes } = this.props;
    this.setState(target.name === 'DocumentType'
      ? {
        newDocument: {
          ...newDocument,
          DocumentType: {
            Id: target.value,
            Name: getDocumentTypeName(target.value, documentTypes),
          },
        },
      } : {
        newDocument: {
          ...newDocument,
          [target.name]: target.value,
        },
      }, this.newItemValidation);
  }

  newItemValidation = () => {
    const { isDocumentType } = this.props;
    const { newDocument } = this.state;
    let isValid = false;
    if (isDocumentType) {
      isValid = !!(newDocument.URL.length && newDocument.Comment.length && newDocument.DocumentType);
    } else {
      isValid = !!(newDocument.URL.length && newDocument.Comment.length);
    }
    this.setState({ isNewItemValid: isValid });
  };

  cancelAdding = () => {
    this.setState({
      showAddNewItem: false,
      isNewItemValid: false,
      newDocument: { ...emptyDocument },
    });
  }

  generateTimeKey = () => {
    return new Date().getTime();
  }

  applyAdding = () => {
    const {
      isNewItemValid, newDocument, columnDoc, sortDirectionDoc,
    } = this.state;
    const { input } = this.props;
    const documents = input.value ? input.value : [];
    if (isNewItemValid) {
      const addDocument = {
        ...newDocument,
        Id: String(this.generateTimeKey()),
        AddedDocument: true,
      };
      documents.push(addDocument);
      this.onChange(sortList(documents, columnDoc, sortDirectionDoc));
      this.setState({
        showAddNewItem: false,
        isNewItemValid: false,
        newDocument: { ...emptyDocument },
      });
    }
  }

  editDocument = (item) => {
    const { input, isDocumentType } = this.props;
    const { columnDoc, sortDirectionDoc } = this.state;
    const documents = input.value;
    let newDocument;
    if (isDocumentType) {
      newDocument = {
        Id: item.Id,
        URL: item.URL,
        Comment: item.Comment,
        DocumentType: {
          Id: item.DocumentType.Id,
          Name: item.DocumentType.Name,
        },
        DocumentTypeId: item.DocumentType.Id,
        AddedDocument: item.AddedDocument,
      };
    } else {
      newDocument = {
        Id: item.Id,
        URL: item.URL,
        Comment: item.Comment,
        AddedDocument: item.AddedDocument,
      };
    }
    const updateDocuments = documents.filter(document => document.Id !== newDocument.Id);
    updateDocuments.push(newDocument);
    this.onChange(sortList(updateDocuments, columnDoc, sortDirectionDoc));
  }

  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: false,
      deleteDocumentIndex: '',
    });
  }

  showConfirmationDialog = (item) => {
    this.setState({
      showConfirmationDialog: true,
      deleteDocumentId: item.stateItemId,
    });
  }

  deleteDocument = () => {
    const { deleteDocumentId } = this.state;
    const { input } = this.props;
    const documents = input.value;
    const updateDocuments = documents.filter(document => document.Id !== deleteDocumentId);
    this.setState({
      showAddNewItem: false,
      showConfirmationDialog: false,
      deleteDocumentId: '',
    });
    this.onChange(updateDocuments);
  }

  onChange = (documents) => {
    const { input } = this.props;
    input.onChange(sortDocumentByAlphabet(documents));
  }

  setSortSettingsContract = (column, sortDirection) => {
    this.setState({
      columnDoc: column,
      sortDirectionDoc: sortDirection,
    });
  };

  render() {
    const {
      showAddNewItem, newDocument, isNewItemValid, showConfirmationDialog,
      columnDoc, sortDirectionDoc,
    } = this.state;
    const {
      input, disabled, styles, columnsToDisplay, viewFinancialInfo, documentTypes, isDocumentType,
    } = this.props;
    return (
      <div className={styles.container}>
        {
          !disabled
            ? <div
              className={classNames('add-document__button add-document__button--margin',
                { 'btn-disabled': input.value.length === MAX_QUANTITY_OF_DOCUMENTS })}
              onClick={this.showAddNew}
            >
              <CustomIcon iconName='plus-orange' />
              <span>Add Document</span>
            </div>
            : null
        }
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={sortList(input.value, columnDoc, sortDirectionDoc)}
          actionLabel
          onEditClick={this.editDocument}
          onDeleteClick={this.showConfirmationDialog}
          sortColumnName={columnDoc}
          sortDirection={sortDirectionDoc}
          setSortSettings={this.setSortSettingsContract}
          isEditable={!disabled}
          isDocumentType={isDocumentType}
          documentSelectOptions={documentTypes}
          viewFinancialInfo={viewFinancialInfo}
        />
        {
          showAddNewItem
            ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
              <div className='c-list__item-column resume-member-inputs c-list__item-column'>
                <UpdateDocumentItem
                  item={newDocument}
                  onChangeNewDocumentObj={this.changeNewItem}
                  disableSaveDocument={isNewItemValid}
                  newDocumentCancel={this.cancelAdding}
                  newDocumentApply={this.applyAdding}
                  isDocumentType={isDocumentType}
                  documentSelectOptions={documentTypes}
                />
              </div>
              <div className='c-list__options-column' />
            </div>
            : null
        }
        {
          showConfirmationDialog
          && <ConfirmationDialog
            dialogHeader='DELETE'
            dialogTitle='Are you sure to delete this item?'
            closeButtonTitle='Cancel'
            confirmButtonTitle='Delete'
            closeDialog={this.closeConfirmationDialog}
            confirmDialog={this.deleteDocument}
          />
        }
      </div>
    );
  }
}
