import React from 'react';
import SearchDropdown from 'common/components/form-items/search-dropdown/search-dropdown';
import '../redux-form.css';

export default class renderSearchDropdown extends React.Component {
  onChange = data => {
    const { input } = this.props;
    input.onChange(data.value);
  }

  render() {
    const {
      input, meta, options, label, autoFocus, styles, inputProps, showClear, isAddingFeature, parentEntityId, isOnlyLiteralValues, showAsterix,
      formClassName = 'field__search-dropdown-form', selectClassName, disabled, placeholder,
    } = this.props;
    return (
      <div className={styles.container}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              showAsterix && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          <SearchDropdown
            formClassName={formClassName}
            selectClassName={selectClassName}
            value={input.value}
            name={input.name}
            onChange={this.onChange}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            showClear={showClear}
            inputProps={inputProps}
            isAddingFeature={isAddingFeature}
            autoFocus={autoFocus}
            parentEntityId={parentEntityId}
            isOnlyLiteralValues={isOnlyLiteralValues}
          />
          {
            (meta.invalid && meta.error !== 'Value required') && (
              <div className={styles.fieldError}>
                <span>{meta.error}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
