import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class Checkbox extends Component {
  render() {
    const {
      checked, onClick, label, name, disabled,
    } = this.props;
    return (
      <span className={classNames('checkbox-cont-list-item checkbox-margin two-cols-view admin_check',
        { 'check-box-disabled': disabled })}
      >
        <label>
          <input
            name={name}
            type='checkbox'
            checked={checked ? checked : false}
            onChange={onClick}
            disabled={disabled}
          />
          <span className='configuration-option__checkbox' />
          <span className='configuration-option__label'>
            <span className='role-style'>{label}</span>
          </span>
        </label>
      </span>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
};
