import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import {
  getProjectDetails,
  getProjectTeams,
  getProjectRoleConfig,
  setTransferedRoles,
  getRoles,
  toogleRolesStatus,
  setListSettings,
  getHistory,
  getManagersByOffice,
  getProjectMilestones,
  patchProjectMilestone,
  updateProjectMilestoneEditingForm,
  getAllCurrencies,
  getAllEngagementTypes,
  getProjectTeamEmployees,
} from '../../actions/projectsActions';
import {
  importSow, importPo, importAmendment,
} from 'pages/projects/actions/importContractAction';
import {
  showModal as showRoleModal,
  getProjectEmployees,
  getStatus,
  getEmployeeStatus,
  getEditAssignment,
} from '../../actions/roleModalActions';
import Tabs from 'common/components/tabs/tabs';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { getId } from 'services/location';
import { getSelectedTab } from 'common/actions/selectedTabActions';
import './project-details.css';
import { filterChoosenExtendRoles } from 'services/filterChoosenExtendRoles';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import { ProjectHistory } from '../project-history/project-history';
import isObjectNotEquals from 'utils/object-comparison';
import sortByAplhabet from 'utils/sortByAplhabet';
import { debounce } from 'services/debounce';
import { recursiveSearch } from 'services/recursiveSearch';
import { sortList } from 'common/components/list/sortList';
import ProjectBasicInfoForm from 'common/components/redux-form/project-basic-info-form';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellDate } from 'common/components/list/custom-cell/cell-date';
import { CellDatePicker } from 'common/components/list/custom-cell/cell-toogle-datepicker';
import { CellToggleBudget } from 'common/components/list/custom-cell/cell-toggle-budget';
import { CellSwitcher } from 'common/components/list/custom-cell/cell-switcher';
import { CellRate } from 'common/components/list/custom-cell/cell-rate';
import { CellPercent } from 'common/components/list/custom-cell/cell-percent';
import { CellToggleCheckbox } from 'common/components/list/custom-cell/cell-toggle-checkbox';
import { CellAssignment } from 'common/components/list/custom-cell/cell-assignment';
import { CellBillingCode } from 'common/components/list/custom-cell/cell-billing-code';
import { CellTimeleft } from 'common/components/list/custom-cell/cell-timeleft';
import { CellBudget } from 'common/components/list/custom-cell/cell-budget';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { getDeleteConfig } from 'utils/getDeleteConfig.js';
import { getManageInvoiceDates } from 'pages/admin/actions/adminManageInvoiceDates';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import {
  TIME_AND_MATERIAL,
  MILESTONE_BACKUP_USED_COLUMN_NAME,
  CHANGE_ENGAGEMENT_TYPE,
  ACTIVE,
  EXTENDED,
} from 'utils/const-variable';
import { updateCheckedFlagOnRoles } from 'pages/projects/components/utils/updateCheckedFlagOnRoles';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';

const clientManagerNameReg = /([a-zA-Z]{1,24})+(\s?)+([a-zA-Z]{1,25})?/;

export class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);
    const { selectedTab } = this.props;
    const projectId = getId();
    this.state = {
      CONTRACTS_TAB_INDEX: 0,
      ROLES_TAB_INDEX: 1,
      MILESTONES_TAB_INDEX: 2,
      HISTORY_TAB_INDEX: 3,
      TEAMS_TAB_INDEX: 4,
      tabLinks: ['Contracts', 'Roles', 'Milestones', 'View History', 'Teams'],
      selectedTab,
      columnsToDisplay: [
        // columns for contracts
        [
          {
            Name: 'Contract ID',
            Id: 'Contract',
            className: 'small-col',
          },
          {
            Name: 'Company',
            Id: 'Company',
            isObject: true,
          },
          {
            Name: 'Approver',
            Id: 'Approver',
            isObject: true,
            className: 'medium-col',
          },
          {
            Name: 'Type',
            Id: 'Type',
            isObject: true,
          },
          {
            Name: 'Booked Revenue',
            Id: 'Budget',
            isBudget: true,
          },
          {
            Name: 'Invoiced Revenue',
            Id: 'RealizedRevenue',
            className: 'medium-col',
            isBudget: true,
          },
          {
            Name: 'Revenue Received',
            Id: 'RevenueReceived',
            className: 'medium-col',
            isBudget: true,
          },
          {
            Name: 'Start Date',
            Id: 'StartDate',
            isDate: true,
          },
          {
            Name: 'End Date',
            Id: 'EndDate',
            className: 'small-col',
          },
          {
            Name: 'Status',
            Id: 'Status',
            isObject: true,
            className: 'small-col',
          },
        ],
        // columns for contracts
        // columns for roles
        [
          {
            Name: 'Role Name',
            Id: 'RoleName',
            className: 'medium-col',
          },
          {
            Name: 'Role Type',
            Id: 'Role',
            className: 'medium-col',
            isObject: true,
          },
          {
            Name: 'Assignment',
            Id: 'Assignment',
            WithOpenRole: true,
          },
          {
            Name: 'Ass. Type',
            Id: 'AssignmentType',
            isObject: true,
          },
          {
            Name: 'Seniority Level',
            Id: 'SeniorityLevel',
            isObject: true,
            className: 'small-col header-title__fixed-width',
          },
          {
            Name: 'Billable Status',
            Id: 'BillableStatus',
            isObject: true,
          },
          {
            Name: 'Rate',
            Id: 'Rate',
            isBudget: true,
          },
          {
            Name: 'SOW/PO Number',
            Id: 'SowPo',
            className:
              'medium-col header-title__fixed-width col-span__with-nowrap',
          },
          {
            Name: 'Start Date',
            Id: 'StartDate',
            isDate: true,
          },
          {
            Name: 'End Date',
            Id: 'EndDate',
          },
          {
            Name: 'Status',
            Id: 'Status',
            isObject: true,
            className: 'small-col',
          },
          {
            Name: 'Comment',
            Id: 'Comment',
            isInnerComment: true,
            deleteArrow: 'delete-arrow',
            className: 'small-col white-space_nowrap',
          },
        ],
        // columns for roles
        // columns for projectMilestones
        [
          {
            Name: 'Contract',
            Id: 'Name',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Total Bookings',
            Id: 'Budget',
            isBudget: true,
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Invoiced Revenue',
            Id: 'RealizedRevenue',
            isBudget: true,
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Revenue Received',
            Id: 'RevenueReceived',
            isBudget: true,
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          // {
          //   Name: 'Delta to Goal',
          //   Id: 'RemainingBalance',
          //   isBudget: true,
          //   className: 'without-width-col',
          //   deleteArrow: 'delete-arrow',
          // },
          // {
          //   Name: 'Booked Revenue',
          //   Id: 'BookedRevenue',
          //   isBudget: true,
          //   className: 'without-width-col',
          //   deleteArrow: 'delete-arrow',
          // },
          {
            Name: 'Realized Budget Utilization',
            Id: 'CurrentBudgetUtilization',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Projected Revenue',
            Id: 'ProjectedRevenue',
            isBudget: true,
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Projected Budget Utilization',
            Id: 'ProjectedBudgetUtilization',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Leftover',
            Id: 'Leftover',
            isBudget: true,
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
        ],
        // columns for projectMilestones
        [
          // View History
        ],
        // columns for History
        // columns for teams
        [
          {
            Name: 'Team Name',
            Id: 'Name',
            className: 'medium-col',
          },
          {
            Name: 'Lead',
            Id: 'Lead',
            className: 'medium-col',
            deleteArrow: 'delete-arrow',
            hasBadge: true,
            isObject: true,
          },
          {
            Name: 'Second in Command',
            Id: 'SecondInCommand',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
            hasBadge: true,
            isObject: true,
          },
        ],
      ],
      innerColumnsToDisplay: {
        POs: [
          {
            Name: 'PO Number',
            Id: 'Contract',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'PO Approver',
            Id: 'Approver',
            className: 'medium-col',
            isObject: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Booked Revenue',
            Id: 'Budget',
            className: 'medium-col',
            isBudget: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Invoiced Revenue',
            Id: 'RealizedRevenue',
            className: 'medium-col',
            isBudget: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Revenue Received',
            Id: 'RevenueReceived',
            className: 'medium-col',
            isBudget: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'StartDate',
            Id: 'StartDate',
            className: 'medium-col',
            isDate: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'EndDate',
            Id: 'EndDate',
            className: 'medium-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Status',
            Id: 'Status',
            isObject: true,
            deleteArrow: 'delete-arrow',
            className: 'small-col',
          },
          {
            Name: 'Type',
            Id: 'Type',
            deleteArrow: 'delete-arrow',
          },
        ],
        Employees: [
          {
            Name: 'Name',
            Id: 'Name',
            className: 'without-width-col',
            hasBadge: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Office',
            Id: 'Office',
            isObject: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Assignment',
            Id: 'Assignment',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'StartDate',
            Id: 'StartDate',
            isDate: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'EndDate',
            Id: 'EndDate',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Status',
            Id: 'Status',
            isObject: true,
            deleteArrow: 'delete-arrow',
            className: 'small-col',
          },
          {
            Name: 'Comment',
            Id: 'Comment',
            isInnerComment: true,
            deleteArrow: 'delete-arrow',
            className: 'medium-col white-space_nowrap',
          },
        ],
        Amendment: [
          {
            Name: 'Amendment Number',
            Id: 'Contract',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Amendment Approver',
            Id: 'Approver',
            className: 'medium-col',
            isObject: true,
            deleteArrow: 'delete-arrow',
          },
          // {
          //   Name: 'Booked Revenue',
          //   Id: 'Budget',
          //   className: 'medium-col',
          //   isBudget: true,
          //   deleteArrow: 'delete-arrow',
          // },
          // {
          //   Name: 'Invoiced Revenue',
          //   Id: 'RealizedRevenue',
          //   className: 'medium-col',
          //   isBudget: true,
          //   deleteArrow: 'delete-arrow',
          // },
          {
            Name: 'StartDate',
            Id: 'StartDate',
            className: 'medium-col',
            isDate: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'EndDate',
            Id: 'EndDate',
            className: 'medium-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Status',
            Id: 'Status',
            isObject: true,
            deleteArrow: 'delete-arrow',
            className: 'small-col',
          },
        ],
        Milestones: [
          {
            Name: 'Milestone',
            Id: 'Milestone',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Contract Number',
            Id: 'ContractNumber',
            className: 'without-width-col',
            isObject: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Milestone Amount',
            Id: 'MilestoneAmount',
            className: 'without-width-col',
            isBudget: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Invoiced Amount',
            Id: 'InvoicedAmount',
            className: 'without-width-col',
            isBudget: true,
            deleteArrow: 'delete-arrow',
          },

          {
            Name: 'Revenue Received',
            Id: 'RevenueReceived',
            className: 'without-width-col',
            isBudget: true,
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Invoiced Date',
            Id: 'InvoicedDate',
            className: 'without-width-col',
            isDate: true,
            deleteArrow: 'delete-arrow',
          },
          // {
          //   Name: 'Delta to Goal',
          //   Id: 'RemainingBalance',
          //   className: 'without-width-col',
          //   isBudget: true,
          //   deleteArrow: 'delete-arrow',
          // },
          {
            Name: 'Approved',
            Id: 'Approved',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Backup used',
            Id: 'UsedAssignmentBackupUser',
            className: 'without-width-col',
            deleteArrow: 'delete-arrow',
          },
        ],
        Members: [
          {
            Name: 'Full Name',
            Id: 'Employee',
            className: 'without-width-col',
            hasBadge: true,
            isObject: true,
          },
          {
            Name: 'Office',
            Id: 'Office',
            className: 'medium-col',
            isObject: true,
          },
          {
            Name: 'StartDate',
            Id: 'StartDate',
            isDate: true,
            className: 'small-col',
          },
          {
            Name: 'EndDate',
            Id: 'EndDate',
            isDate: true,
            className: 'small-col',
          },
          {
            Name: 'Seniority Level',
            Id: 'SeniorityLevel',
            className: 'medium-col',
            isObject: true,
          },
          {
            Name: 'Role',
            Id: 'Role',
            isObject: true,
            className: 'medium-col',
          },
          {
            Name: 'Status',
            Id: 'Status',
            isObject: true,
            className: 'small-col',
          },
          {
            Name: 'Position',
            Id: 'TeamPosition',
            className: 'small-col',
            deleteArrow: 'delete-arrow',
            isObject: true,
            hasBadge: true,
            withoutTitle: true,
          },
        ],
      },
      projectBasicInfoData: {},
      sortRolesEmployee: [],
      hightLight: false,
      hightLightClientManager: false,
      transferingRoles: [],
      isActiveExtendButton: false,
      showDialogExtend: false,
      isOpenDeletePopup: false,
      projectHistory: {
        History: [],
        TotalCount: '',
      },
      takeHistory: 15,
      hasMoreHistory: true,
      rolesForRender: [],
      teamsForRender: [],
      idForChecked: [],
      itsEngineer: false,
      projectId,
      showEngagementTypeDialog: false,
    };

    this.scrollbarRef = React.createRef();

    this.mainContractsDeleteConfigs = {
      sow: getDeleteConfig(
        'sows',
        'projects/sow',
        false,
        true,
        projectId,
      ),
      msa: getDeleteConfig(
        'msas',
        'projects/msa',
        false,
        true,
        projectId,
      ),
    };
    this.sowDeleteConfig = getDeleteConfig(
      'sows',
      'projects/sow',
      false,
      true,
      projectId,
    );
    this.msaDeleteConfig = getDeleteConfig(
      'msas',
      'projects/msa',
      false,
      true,
      projectId,
    );
    this.poDeleteConfig = getDeleteConfig(
      'pos',
      'projects/po',
      false,
      true,
      projectId,
    );
    this.amendmentDeleteConfig = getDeleteConfig(
      'amendments',
      'projects/po',
      false,
      true,
      projectId,
    );
    this.roleDeleteConfig = getDeleteConfig(
      `projects/${projectId}/roles/`,
      'projects/roles',
    );
    this.roleEmployeesDeleteConfig = getDeleteConfig(
      '',
      'projects/roles/assignment',
    );
    this.teamDeleteConfig = getDeleteConfig(
      `projects/${projectId}/teams/`,
      'projects/teams',
    );
  }

  componentDidMount() {
    const {
      getProjectDetails,
      getProjectRoleConfig,
      getStatus,
      getEmployeeStatus,
      defaultCheckedShowRoles,
      selectedTab,
      getManageInvoiceDates,
      setComponent,
      getAllCurrencies,
      resetDefaultFilters,
      getAllEngagementTypes,
      getProjectMilestones,
      HasFinancialAccess,
      fiscalYears,
    } = this.props;
    const { projectId } = this.state;
    const params = {};
    if (fiscalYears[projectId]) {
      params.FiscalYear = fiscalYears[projectId];
    }
    getProjectDetails(projectId, !defaultCheckedShowRoles, true, params);
    getProjectRoleConfig(projectId);
    getAllCurrencies();
    getAllEngagementTypes();
    getStatus();
    getEmployeeStatus();
    getManageInvoiceDates();
    this.setSelectedTab(selectedTab);
    this.getRoleSetting();
    setComponent({
      returnButton: {
        text: 'Projects',
        goToPreviousPage: this.goToProjectsList,
      },
    });
    if (HasFinancialAccess) {
      getProjectMilestones(projectId);
    }
    resetDefaultFilters();
    this.resetListSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      projectDetails,
      projectTeams,
      defaultCheckedShowRoles,
      viewHistory,
      getHistory,
      selectedTab,
      getManagersByOffice,
      listSettings,
      getProjectMilestones,
      getSelectedTab,
      HasFinancialAccess,
    } = this.props;
    const {
      takeHistory, rolesForRender, idForChecked, projectId, tabLinks, TEAMS_TAB_INDEX, CONTRACTS_TAB_INDEX,
    } = this.state;
    const { history } = listSettings.childState;
    if (prevProps.selectedTab !== selectedTab) {
      this.setSelectedTab(selectedTab);
    }
    if (isObjectNotEquals(prevProps.projectDetails, projectDetails)) {
      this.setDefaultData();
    }
    if (prevProps.projectDetails && isObjectNotEquals(prevProps.projectDetails.Contracts, projectDetails.Contracts)) {
      if (HasFinancialAccess) {
        getProjectMilestones(projectId);
      }
    }
    if (
      projectDetails
      && projectDetails.Roles
      && prevProps.projectDetails.Roles !== projectDetails.Roles
    ) {
      this.setRolesForRender();
    }
    if (
      projectTeams
      && projectTeams.Teams
      && prevProps.projectTeams.Teams !== projectTeams.Teams
    ) {
      this.setTeamsForRender();
    }
    if (isObjectNotEquals(prevState.rolesForRender, rolesForRender)) {
      this.rolesSortEmployees();
    }
    if (prevProps.defaultCheckedShowRoles !== defaultCheckedShowRoles) {
      this.refreshRoles();
    }
    if (prevProps.viewHistory !== viewHistory) {
      this.setState({
        projectHistory: viewHistory,
      });
    }
    if (prevState.takeHistory !== takeHistory) {
      getHistory(projectId, 0, takeHistory, history);
    }
    if (isObjectNotEquals(prevProps.listSettings.childState.history, history)) {
      getHistory(projectId, 0, takeHistory, history);
    }
    if (prevState.idForChecked !== idForChecked) {
      this.rolesSortEmployees();
    }
    if (
      projectDetails
      && projectDetails.Offices
      && prevProps.projectDetails.Offices !== projectDetails.Offices
    ) {
      getManagersByOffice(projectDetails.Offices.map((item) => item.Id));
    }
    if (projectDetails && projectDetails.EngagementType && projectDetails.EngagementType.Name !== 'Team-based') {
      const isTeamsTabExist = tabLinks.some(tab => tab === 'Teams');
      isTeamsTabExist && this.setState({
        tabLinks: tabLinks.filter(tab => tab !== 'Teams'),
      });
      (selectedTab === TEAMS_TAB_INDEX) && getSelectedTab(CONTRACTS_TAB_INDEX);
    }
    if (projectDetails && projectDetails.EngagementType && projectDetails.EngagementType.Name === 'Team-based') {
      const isTeamsTabExist = tabLinks.some(tab => tab === 'Teams');
      !isTeamsTabExist && this.setState({
        tabLinks: [...tabLinks, 'Teams'],
      });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  resetListSettings = () => {
    const { setListSettings } = this.props;
    const childState = {
      contracts: {
        search: '',
        sortColumn: null,
        sortDirection: null,
      },
      roles: {
        search: '',
        sortColumn: 'RoleName',
        sortDirection: 'Up',
      },
      history: {
        search: '',
        startDateHistory: null,
        endDateHistory: null,
      },
      teams: {
        search: '',
        sortColumn: 'TeamName',
        sortDirection: 'Up',
      },
    };
    setListSettings({ childState });
  }

  goToProjectsList = () => {
    this.resetListSettings();
    history.push({ pathname: '/projects' });
  };

  setRolesForRender = () => {
    const { projectDetails, listSettings } = this.props;
    const { roles } = listSettings.childState;
    this.setState({
      rolesForRender: recursiveSearch(
        sortList(projectDetails.Roles, roles.sortColumn, roles.sortDirection),
        roles.search,
      ),
    });
  };

  setTeamsForRender = () => {
    const { projectTeams, listSettings } = this.props;
    const { teams } = listSettings.childState;
    this.setState({
      teamsForRender: recursiveSearch(
        sortList(projectTeams.Teams, teams.sortColumn, teams.sortDirection),
        teams.search,
      ),
    });
  };

  changeAmountHistory = (page) => {
    const { takeHistory } = this.state;
    const { loading } = this.props;
    const increaseParams = takeHistory + page;
    if (!loading) {
      this.setState(
        {
          takeHistory: increaseParams,
        },
        () => this.isNeedMoreStory(),
      );
    }
  };

  isNeedMoreStory = () => {
    const { takeHistory, projectHistory } = this.state;
    this.setState({
      hasMoreHistory: takeHistory > projectHistory.TotalCount ? false : true,
    });
  };

  setSelectedTab = (selectedTab) => {
    this.setState(
      {
        selectedTab,
      },
      () => {
        this.loadDataForTabs();
      },
    );
  };

  setDefaultData = () => {
    const { projectDetails } = this.props;

    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentMonthMilestones = projectDetails.FinancialData
      && projectDetails.FinancialData.MilestonesByMonth
      && projectDetails.FinancialData.MilestonesByMonth.find(item => {
        const date = moment(item.Date);

        return date.year() === currentYear && date.month() === currentMonth;
      });

    this.setState({
      projectBasicInfoData: {
        Name: projectDetails.Name,
        Id: projectDetails.Id,
        ClientId: projectDetails.Client.Id,
        ClientName: projectDetails.Client.Name,
        Offices: projectDetails.Offices.length
          ? projectDetails.Offices.map((item) => item.Id)
          : [],
        StartDate: projectDetails.StartDate,
        EndDate: projectDetails.EndDate,
        PrimaryOffices: projectDetails.PrimaryOffices.length
          ? projectDetails.PrimaryOffices.map((item) => item.Id)
          : [],
        ClientManagerId:
          projectDetails.ClientManager && projectDetails.ClientManager.Id,
        ClientManagerName:
          projectDetails.ClientManager && projectDetails.ClientManager.Name,
        DeliveryManagers:
          projectDetails.DeliveryManagers
          && projectDetails.DeliveryManagers.map((item) => item.Id),
        DeliveryManagersValues:
          projectDetails.DeliveryManagers
          && projectDetails.DeliveryManagers.map((item) => ({
            label: item.Name,
            value: item.Id,
          })),
        EngineeringManagers:
          projectDetails.EngineeringManagers
          && projectDetails.EngineeringManagers.map((item) => item.Id),
        EngineeringManagersValues:
          projectDetails.EngineeringManagers
          && projectDetails.EngineeringManagers.map((item) => ({
            label: item.Name,
            value: item.Id,
          })),
        AccountManagerId:
          projectDetails.AccountManager && projectDetails.AccountManager.Id,
        AccountManagerName:
          projectDetails.AccountManager && projectDetails.AccountManager.Name,
        Description: projectDetails.Description,
        ProjectType: {
          Id: projectDetails.Billability.Id,
          Name: projectDetails.Billability.Name,
        },
        Status: projectDetails.Status,
        ProjectCode: projectDetails.ProjectCode,
        Aliases: projectDetails.Aliases,
        RealizedRevenue: projectDetails.FinancialData && projectDetails.FinancialData.RealizedRevenue,
        Budget: projectDetails.FinancialData && projectDetails.FinancialData.Budget,
        RemainingBalance: projectDetails.FinancialData && projectDetails.FinancialData.RemainingBalance,
        BookedRevenue: projectDetails.FinancialData && projectDetails.FinancialData.BookedRevenue,
        Leftover: projectDetails.FinancialData && projectDetails.FinancialData.Leftover,
        Currency: projectDetails.Currency,
        EngagementTypeId: projectDetails.EngagementType && projectDetails.EngagementType.Id,
        EngagementTypeName: projectDetails.EngagementType && projectDetails.EngagementType.Name,
        RevenueReceived: projectDetails.FinancialData && projectDetails.FinancialData.RevenueReceived,
        ProjectSize: projectDetails.FinancialData && projectDetails.FinancialData.ProjectSize,
        ProjectLeftover: projectDetails.FinancialData && projectDetails.FinancialData.ProjectLeftover,
        CurrentMonthMilestones: currentMonthMilestones && currentMonthMilestones.MilestonesByMonth || 0,
      },
    });
  };

  onTab = (selectedTab) => {
    const { getSelectedTab } = this.props;
    getSelectedTab(selectedTab);
  };

  filterContracts = (contract, searchValue) => {
    const str = new RegExp(searchValue, 'gi');
    let match = false;
    // eslint-disable-next-line no-unused-vars
    for (const key in contract) {
      match = contract[key] ? contract[key].toString().match(str) : false;
      if (match) break;
    }
    return match;
  };

  searchForTab = (param) => (value) => {
    const {
      projectDetails, setListSettings, listSettings, projectTeams,
    } = this.props;
    const childState = {
      ...listSettings.childState,
      [param]: { ...listSettings.childState[param], search: value },
    };
    setListSettings({ childState });
    if (param === 'roles') {
      const { roles } = childState;
      this.setState({
        rolesForRender: recursiveSearch(
          sortList(projectDetails.Roles, roles.sortColumn, roles.sortDirection),
          roles.search,
        ),
      });
    }

    if (param === 'teams') {
      const { teams } = childState;
      this.setState({
        teamsForRender: recursiveSearch(
          sortList(projectTeams.Teams, teams.sortColumn, teams.sortDirection),
          teams.search,
        ),
      });
    }
  };

  getRoleSetting = () => {
    const {
      showRoleModal,
      getProjectEmployees,
      projectDetails: { Contracts, Id },
    } = this.props;
    const billingData = {
      billingCodes: [],
      billingCodesStartDate: [],
      billingCodesEndDate: [],
    };
    Contracts.forEach((SOW) => {
      billingData.billingCodes.push(SOW.Contract);
      billingData.billingCodesStartDate.push(SOW.StartDate);
      billingData.billingCodesEndDate.push(SOW.EndDate);
      SOW.POs.forEach((PO) => {
        billingData.billingCodes.push(PO.Contract);
        billingData.billingCodesStartDate.push(PO.StartDate);
        billingData.billingCodesEndDate.push(PO.EndDate);
      });
      SOW.Amendments.forEach((Amendment) => {
        billingData.billingCodes.push(Amendment.Contract);
        billingData.billingCodesStartDate.push(Amendment.StartDate);
        billingData.billingCodesEndDate.push(Amendment.EndDate);
      });
    });
    showRoleModal([], billingData, 'ADD_ROLE_TO_PROJECT', Id);
    if (Id) {
      getProjectEmployees(Id);
    }
  };

  showRoleModal = () => {
    this.getRoleSetting();
    history.push({
      pathname: '/projects/new/addrole',
    });
  };

  addNewSOW = () => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/add-sow`,
    });
  };

  importSOW = ({ target }) => {
    const { importSow, projectDetails } = this.props;
    importSow(target.files[0], projectDetails.Id);
    target.value = '';
  };

  importContract = ({ target }, sowId, importType) => {
    const { importPo, importAmendment, projectDetails } = this.props;
    switch (importType) {
      case 'PO': {
        importPo(target.files[0], projectDetails.Id, sowId);
        target.value = '';
        break;
      }
      case 'Amendment': {
        importAmendment(target.files[0], projectDetails.Id, sowId);
        target.value = '';
        break;
      }
      case 'POExtension': {
        importPo(target.files[0], projectDetails.Id, sowId);
        target.value = '';
        break;
      }
      default: break;
    }
  }

  editSOW = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/edit-sow/${id}`,
    });
  };

  viewSOW = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/view-sow/${id}`,
    });
  };

  addNewMSA = () => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/add-msa`,
    });
  };

  editMSA = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/edit-msa/${id}`,
    });
  };

  viewMSA = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/view-msa/${id}`,
    });
  };

  addNewPO = (item) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/add-po/${item.parentId || item.id}`,
    });
  };

  editPO = (POId) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/edit-po/${POId}`,
    });
  };

  viewPO = (POId) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/view-po/${POId}`,
    });
  };

  editRole = (id) => {
    const { projectDetails } = this.props;
    this.getRoleSetting();
    history.push({
      pathname: `/projects/${projectDetails.Id}/role/edit/${id}`,
    });
  };

  editAssignment = (id) => {
    const { getEditAssignment, projectDetails } = this.props;
    const { sortRolesEmployee } = this.state;
    this.getRoleSetting();
    const employeeObj = sortRolesEmployee.reduce((acc, item) => {
      if (
        typeof item.Employees.find((item) => item.Id === id) !== 'undefined'
      ) {
        acc = item.Employees.find((item) => item.Id === id);
      }
      return acc;
    }, {});
    employeeObj.projectId = projectDetails.Id;
    employeeObj.roleId = this.getRoleId(id);
    sortRolesEmployee.find((item) => item.Id === id);
    getEditAssignment(employeeObj);
    history.push({
      pathname: `/projects/${projectDetails.Id}/assignment/edit/${id}`,
    });
  };

  deleteAssignment = (info) => {
    const { showDialog, projectTeams } = this.props;
    const { rolesForRender } = this.state;
    const isTeamMember = projectTeams.Teams.some(team => {
      return (
        team.Members
        && team.Members.some(member => (member.Id === (info.item && info.item.Id)))
      )
        || team.SecondInCommand && (team.SecondInCommand.Id === (info.item && info.item.EmployeeId));
    });

    const backupEmployees = [];
    const { Id } = info.item;

    // eslint-disable-next-line no-unused-vars
    for (const role of rolesForRender) {
      // eslint-disable-next-line no-unused-vars
      for (const employee of role.Employees) {
        if (employee.PrimaryAssignmentId === Id) {
          backupEmployees.push(employee);
        }
      }
    }

    const hasBackupEmployees = backupEmployees.length > 0;
    const backupAssignmentNames = backupEmployees.map((e) => e.Name);
    const message = `After this action employee history will be lost.
    ${isTeamMember
        ? (info.item && info.item.PrimaryAssignmentId)
          ? 'Also, this employee will be deleted from the teams.'
          : 'Also, this employee and his backup employees will be deleted from the teams.'
        : ''}
    ${hasBackupEmployees ? `Also, ${backupAssignmentNames.join(', ')} as backup will be deleted.` : ''}
    Click OK to confirm the action.
    `;

    const updateInfo = {
      ...info,
      url: `${this.getDeleteAssigmentURL(info)}${hasBackupEmployees ? '?deleteBackups=true' : ''}`,
      dialogTitle: message,
      closeButtonTitle: 'Cancel',
      confirmButtonTitle: 'OK',
    };
    showDialog(updateInfo);
  };

  getDeleteAssigmentURL = (info) => {
    return `projects/${info.entityId}/roles/${this.getRoleId(
      info.item.Id,
    )}/assignments/${info.item.Id}`;
  };

  getRoleId = (id) => {
    const { projectDetails } = this.props;
    return projectDetails.Roles.reduce((acc, item) => {
      if (item.Employees.find((item) => item.Id === id)) {
        acc = item.Id;
      }
      return acc;
    }, {});
  };

  viewRole = (id) => {
    const { projectDetails } = this.props;
    this.getRoleSetting();
    history.push({
      pathname: `/projects/${projectDetails.Id}/role/view/${id}`,
    });
  };

  rolesSortEmployees = () => {
    const { rolesForRender } = this.state;
    const sortEmployeeArray = [];
    rolesForRender.forEach((item) => {
      sortEmployeeArray.push({
        Employees: sortByAplhabet(item.Employees).reduce((acc, elem) => {
          if (elem.Role !== 'Primary') {
            return acc;
          }
          acc.push(elem);
          const shadowAssignmentsForEmployee = item.Employees.filter(
            (i) => i.Role === 'Shadow' && i.PrimaryAssignmentId === elem.Id,
          );
          if (shadowAssignmentsForEmployee.length) {
            acc.push(...shadowAssignmentsForEmployee);
          }
          return acc;
        }, []),
      });
    });
    this.setState({
      sortRolesEmployee: sortEmployeeArray,
    });
  };

  checkRepeatedContracts = (contracts) => {
    const contractForRole = {};
    contracts.forEach((item, i) => {
      const value = contracts[i];
      contractForRole[value] = true;
    });
    return Object.keys(contractForRole);
  };

  extendRoles = () => {
    const { transferingRoles } = this.state;
    const { setTransferedRoles, projectDetails } = this.props;
    const onlyContractNames = transferingRoles.map((item) => item.SowPo);
    const onlyUniqSow = this.checkRepeatedContracts(onlyContractNames);
    if (transferingRoles.length) {
      if (onlyUniqSow.length === 1) {
        setTransferedRoles(transferingRoles);
        history.push({
          pathname: `/projects/${projectDetails.Id}/extend-roles`,
        });
      } else {
        this.setState({
          showDialogExtend: true,
        });
      }
    }
  };

  getFilteredByChecked = (roles) => {
    return roles.filter((item) => item.checked === true);
  };

  getDefaultCheckedEmployee = (id, action, isOpenDropdown) => {
    if (action) {
      const { projectDetails } = this.props;
      const { idForChecked } = this.state;
      const rolesCopy = projectDetails.Roles;
      const idDefaultChecked = [];
      const openedRoles = Object.keys(isOpenDropdown);
      rolesCopy.forEach((role, index) => {
        if (role.Id === id && openedRoles.includes(index.toString())) {
          role.Employees.forEach((child) => {
            if (
              typeof child.checked === 'undefined'
              && child.Status.Name !== 'Removed'
            ) {
              if (!idForChecked.includes(child.Id)) {
                idDefaultChecked.push(child.Id);
              }
            }
          });
        }
      });
      this.setState({
        idForChecked: [...idForChecked, ...idDefaultChecked],
      });
    }
  };

  getRolesForExtend = (e, parent, isOpenDropdown) => {
    const { projectDetails } = this.props;
    const isActive = true;
    const rolesCopy = projectDetails.Roles;
    const idItem = e.target.value;
    const action = e.target.checked;
    const openedRoles = Object.keys(isOpenDropdown);
    const filteredRoles = filterChoosenExtendRoles(
      rolesCopy,
      idItem,
      parent,
      action,
      openedRoles,
    );
    const parentFilter = this.getFilteredByChecked(filteredRoles);
    const filteredArr = [];
    parentFilter.forEach((item) => {
      const child = this.getFilteredByChecked(item.Employees);
      if (child.length) {
        const newParent = {
          ...item,
          Employees: child,
        };
        filteredArr.push(newParent);
      } else {
        const newParent = {
          ...item,
          Employees: [],
        };
        filteredArr.push(newParent);
      }
    });
    this.setState(
      {
        transferingRoles: filteredArr,
        isActiveExtendButton: filteredArr.length ? isActive : !isActive,
      },
      () => this.getDefaultCheckedEmployee(idItem, action, isOpenDropdown),
    );
  };

  handleChangeShowRoles = () => {
    const { toogleRolesStatus } = this.props;
    toogleRolesStatus();
  };

  refreshRoles = () => {
    const { projectDetails, getRoles, defaultCheckedShowRoles } = this.props;
    getRoles(projectDetails.Id, !defaultCheckedShowRoles);
  };

  closeDialog = () => {
    this.setState({
      showDialogExtend: false,
    });
  };

  isContractTimeLessTwoWeeks = (amountDay) => {
    const { projectDetails } = this.props;
    const contractsId = [];
    projectDetails.Contracts.forEach((item) => {
      if (item.POs.length) {
        item.POs.forEach((po) => {
          if (
            moment(po.EndDate).diff(new Date(), 'day') < amountDay
            && po.Status.Name === 'Active'
          ) {
            contractsId.push(po.Id);
          }
        });
      }
      if (
        moment(item.EndDate).diff(new Date(), 'day') < amountDay
        && item.Status.Name === 'Active'
      ) {
        contractsId.push(item.Id);
      }
    });
    return contractsId;
  };

  isRolesTimeLessTwoWeeks = (amountDay) => {
    const { projectDetails } = this.props;
    const rolesId = [];
    projectDetails.Roles
      && projectDetails.Roles.forEach((item) => {
        if (item.Employees.length) {
          item.Employees.forEach((po) => {
            if (
              moment(po.EndDate).diff(new Date(), 'day') < amountDay
              && po.Status.Name === 'Active'
            ) {
              rolesId.push(po.Id);
            }
          });
        }
        if (
          moment(item.EndDate).diff(new Date(), 'day') < amountDay
          && item.Status.Name === 'Active'
        ) {
          rolesId.push(item.Id);
        }
      });
    return rolesId;
  };

  deleteProject = () => {
    const { showDialog, projectDetails } = this.props;
    const dataForDeleting = {
      url: `projects/${projectDetails.Id}`,
      searchUpdateSaga: 'deleteProjects',
      history: '/projects',
    };
    showDialog(dataForDeleting);
  };

  handleChooseDateHistory = (date, name) => {
    const { setListSettings, listSettings } = this.props;
    const childState = {
      ...listSettings.childState,
      history: { ...listSettings.childState.history, [name]: date },
    };
    setListSettings({ childState });
  };

  handleClearStartDateHistory = () => {
    const { setListSettings, listSettings } = this.props;
    const childState = {
      ...listSettings.childState,
      history: { ...listSettings.childState.history, startDateHistory: null },
    };
    setListSettings({ childState });
  };

  handleClearEndDateHistory = () => {
    const { setListSettings, listSettings } = this.props;
    const childState = {
      ...listSettings.childState,
      history: { ...listSettings.childState.history, endDateHistory: null },
    };
    setListSettings({ childState });
  };

  setSortSettingsForTab = (param) => (sortColumn, sortDirection) => {
    const {
      projectDetails, setListSettings, listSettings, projectTeams,
    } = this.props;
    const childState = {
      ...listSettings.childState,
      [param]: { ...listSettings.childState[param], sortColumn, sortDirection },
    };
    setListSettings({ childState });
    if (param === 'roles') {
      const { roles } = childState;
      this.setState({
        rolesForRender: recursiveSearch(
          sortList(projectDetails.Roles, roles.sortColumn, roles.sortDirection),
          roles.search,
        ),
      });
    }

    if (param === 'teams') {
      const { teams } = childState;
      this.setState({
        teamsForRender: recursiveSearch(
          sortList(projectTeams.Teams, teams.sortColumn, teams.sortDirection),
          teams.search,
        ),
      });
    }
  };

  addAmendment = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/add-amendment/${id}`,
    });
  };

  viewAmendment = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/view-amendment/${id}`,
    });
  };

  renderAmendmentMenu = (id) => {
    const { ManageProjectContracts, projectDetails } = this.props;

    const canAddAmendment = projectDetails.Contracts.find((statementOfWork) => statementOfWork.Id === id && statementOfWork.POs.length === 0);

    return (
      ManageProjectContracts && canAddAmendment && (
        <div
          className='c-list-item__options-column__option'
          onClick={() => this.addAmendment(id)}
        >
          <span>Add Amendment</span>
        </div>
      )
    );
  };

  renderOverrideRealizedRevenueMenuItem = () => {
    return (
      <div
        className='c-list-item__options-column__option'
        onClick={this.redirectToParentContractView}
      >
        <span>Override Invoiced Revenue</span>
      </div>
    );
  };

  editAmendment = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/edit-amendment/${id}`,
    });
  };

  getCustomUrlForDeleteContract = (url, id, parentId, projectId) => {
    switch (url) {
      case 'sows':
        return `projects/${projectId}/sows/${id}`;
      case 'msas':
        return `projects/${projectId}/msas/${id}`;
      case 'pos':
        return `projects/${projectId}/sows/${parentId}/pos/${id}`;
      case 'amendments':
        return `projects/${projectId}/sows/${parentId}/amendments/${id}`;
      default:
        break;
    }
  };

  renderAmendmentList = (items) => {
    const { innerColumnsToDisplay } = this.state;
    const {
      HasFinancialAccess,
      settings,
      ManageProjectContracts,
      showDialog,
      projectDetails,
      listSettings,
    } = this.props;
    const { contracts } = listSettings.childState;
    return (
      <div
        className='highorder_component-wrapper'
        key={JSON.stringify(
          recursiveSearch(
            sortList(items, contracts.sortColumn, contracts.sortDirection),
            contracts.search,
          ),
        )}
      >
        <List
          columnsToDisplay={innerColumnsToDisplay.Amendment}
          items={recursiveSearch(
            sortList(items, contracts.sortColumn, contracts.sortDirection),
            contracts.search,
          )}
          viewFinancialInfo={HasFinancialAccess}
          onEditClick={
            ManageProjectContracts ? this.editAmendment : this.viewAmendment
          }
          onItemClick={
            ManageProjectContracts ? this.editAmendment : this.viewAmendment
          }
          onDeleteClick={showDialog}
          deleteConfig={this.amendmentDeleteConfig}
          timeLeft={settings.ContractFirstNotification}
          getCustomUrlForDelete={this.getCustomUrlForDeleteContract}
          isHaveRights={ManageProjectContracts}
          hideAddNewEntity
          config={{
            Status: { cell: CustomStatusCell },
            EndDate: {
              cell: CellTimeleft,
              daysToExpire: settings.ContractFirstNotification,
            },
            Budget: {
              cell: CellBudget,
            },
            RealizedRevenue: {
              cell: CellBudget,
            },
            CurrencyType: { currency: projectDetails.Currency },
          }}
        />
      </div>
    );
  };

  sortPOInSow = (contracts) => {
    return contracts.reduce((acc, contract) => {
      contract.POs.forEach((PO, index) => {
        if (PO.Type === 'Original') {
          [contract.POs[index], contract.POs[0]] = [
            contract.POs[0],
            contract.POs[index],
          ];
        }
      });
      acc.push(contract);
      return acc;
    }, []);
  };

  saveMilestoneEditing = ({ Id }) => {
    const { patchProjectMilestone, projectDetails, fiscalYears } = this.props;
    const params = {};
    const projectId = projectDetails.Id;
    if (fiscalYears[projectId]) {
      params.FiscalYear = fiscalYears[projectId];
    }
    patchProjectMilestone({
      projectId,
      Id,
      params,
    });
  };

  redirectToParentContractView = ({ id, parentId }) => {
    const { projectMilestones } = this.props;
    const parentForCurrentMilestone = projectMilestones.find(
      (contractForMilestone) => contractForMilestone.Id === parentId,
    );
    const currentMilestone = parentForCurrentMilestone
      && parentForCurrentMilestone.Milestones.find(
        (milestone) => milestone.Id === id,
      );
    const isSOWParentOfMilestone = currentMilestone
      && currentMilestone.ContractNumber.Id === parentForCurrentMilestone.Id;
    currentMilestone
      && (isSOWParentOfMilestone
        ? this.viewSOW(parentId)
        : this.viewPO(currentMilestone.ContractNumber.Id));
  };

  loadDataForTabs = () => {
    const {
      takeHistory,
      projectId,
      MILESTONES_TAB_INDEX,
      HISTORY_TAB_INDEX,
      ROLES_TAB_INDEX,
      TEAMS_TAB_INDEX,
      selectedTab,
    } = this.state;

    const {
      getHistory, getProjectMilestones, listSettings, getProjectTeams, getProjectTeamEmployees, HasFinancialAccess,
    } = this.props;

    switch (selectedTab) {
      case MILESTONES_TAB_INDEX:
        if (HasFinancialAccess) {
          getProjectMilestones(projectId);
        }
        break;
      case HISTORY_TAB_INDEX:
        getHistory(
          projectId,
          0,
          takeHistory,
          listSettings
          && listSettings.childState
          && listSettings.childState.history,
        );
        break;
      case TEAMS_TAB_INDEX:
        getProjectTeamEmployees();
        getProjectTeams(projectId);
        break;
      case ROLES_TAB_INDEX:
        getProjectTeams(projectId);
        break;
      default:
        break;
    }
  };

  generateColumnsToDisplayForMilestonesSubList = (item, columns) => {
    return item.SowType.Name === TIME_AND_MATERIAL
      ? columns
      : columns.filter(
        (column) => column.Name !== MILESTONE_BACKUP_USED_COLUMN_NAME,
      );
  };

  onChangeCheckecRole = (idRole, parentId) => {
    this.setState((state) => {
      const newRoles = updateCheckedFlagOnRoles(
        state.rolesForRender,
        idRole,
        parentId,
        false,
      );
      const extendRoles = this.getFilteredByChecked(newRoles).map((role) => ({
        ...role,
        Employees: this.getFilteredByChecked(role.Employees).map(
          (employee) => employee,
        ),
      }));
      return {
        rolesForRender: newRoles,
        transferingRoles: extendRoles,
        isActiveExtendButton: !!extendRoles.length,
      };
    });
  };

  showTeamsModal = () => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/team/`,
    });
  }

  editTeam = (id) => {
    const { projectDetails } = this.props;
    history.push({
      pathname: `/projects/${projectDetails.Id}/team/edit/${id}`,
    });
  }

  openEngagementTypeDialog = (callback) => {
    this.setState({
      showEngagementTypeDialog: true,
      engagementTypeDialogCallback: callback,
    });
  }

  closeEngagementTypeDialog = () => {
    this.setState({
      showEngagementTypeDialog: false,
      engagementTypeDialogCallback: null,
    });
  }

  confirmEngagementTypeDialog = () => {
    const { engagementTypeDialogCallback } = this.state;
    engagementTypeDialogCallback && engagementTypeDialogCallback();
    this.closeEngagementTypeDialog();
  }

  handleMilestoneDropdownModeToggle = (isEditingMode, milestoneId) => {
    const { projectMilestones, updateProjectMilestoneEditingForm } = this.props;
    const milestoneFields = isEditingMode
      ? projectMilestones.flatMap((c) => c.Milestones || []).find((m) => m.Id === milestoneId)
      : null;

    updateProjectMilestoneEditingForm(
      milestoneId,
      milestoneFields,
    );
  }

  handleMilestoneFormUpdate = (updatedFields, milestone) => {
    const { updateProjectMilestoneEditingForm } = this.props;

    updateProjectMilestoneEditingForm(milestone.Id, updatedFields);
  }

  sortProjectMilestones = () => {
    const { projectMilestones, projectDetails } = this.props;
    const distantFuture = new Date(9999, 12);

    return projectMilestones.sort((a, b) => {
      const contractA = projectDetails.Contracts.filter(x => x.Id === a.Id)[0];
      const aStatusName = contractA ? contractA.Status.Name : null;

      const contractB = projectDetails.Contracts.filter(x => x.Id === b.Id)[0];
      const bStatusName = contractB ? contractB.Status.Name : null;

      const aDate = a.EndDate ? new Date(a.EndDate) : aStatusName && aStatusName === 'Active' || aStatusName === 'Extended' ? distantFuture : new Date(1);
      const bDate = b.EndDate ? new Date(b.EndDate) : bStatusName && bStatusName === 'Active' || bStatusName === 'Extended' ? distantFuture : new Date(1);
      return bDate - aDate;
    });
  }

  handleViewContract = (id) => {
    const { projectDetails, ManageProjectContracts } = this.props;
    const contracts = [...projectDetails.Contracts, ...projectDetails.MSAs];
    const contract = contracts.find(item => item.Id === id);
    if (contract.Type && contract.Type.Name) {
      if (contract.Type.Name === 'MSA') {
        ManageProjectContracts ? this.editMSA(id) : this.viewMSA(id);
      } else {
        ManageProjectContracts ? this.editSOW(id) : this.viewSOW(id);
      }
    }
  }

  sortContracts = (contracts) => {
    return [...contracts].sort((first, second) => {
      const firstStartDate = new Date(first.StartDate);
      const firstEndDate = first.EndDate ? new Date(first.EndDate) : new Date(9999, 12);
      const secondStartDate = new Date(second.StartDate);
      const secondEndDate = second.EndDate ? new Date(second.EndDate) : new Date(9999, 12);

      if (first.Status && second.Status && (first.Status.Name === ACTIVE || first.Status.Name === EXTENDED)
        && (second.Status.Name === ACTIVE || second.Status.Name === EXTENDED)) {
        if (first.Type && second.Type && first.Type.Name !== 'MSA' && second.Type.Name !== 'MSA') {
          return secondEndDate - firstEndDate || secondStartDate - firstStartDate || (first.Status.Name === ACTIVE ? -1 : 1);
        }
        if (first.Type && second.Type && first.Type.Name === 'MSA' && second.Type.Name === 'MSA') {
          return secondEndDate - firstEndDate || secondStartDate - firstStartDate || (first.Status.Name === ACTIVE ? -1 : 1);
        }
        return first.Type && first.Type.Name === 'MSA' ? 1 : -1;
      } if (first.Status && second.Status && (first.Status.Name === ACTIVE || first.Status.Name === EXTENDED)
        && (second.Status.Name !== ACTIVE || second.Status.Name !== EXTENDED)) {
        return -1;
      } if (first.Status && second.Status && (first.Status.Name !== ACTIVE || first.Status.Name !== EXTENDED)
        && (second.Status.Name === ACTIVE || second.Status.Name === EXTENDED)) {
        return 1;
      }

      return 0;
    });
  }

  render() {
    const {
      projectDetails,
      projectTeams,
      filtersReducer,
      billingTypes,
      showDialog,
      defaultCheckedShowRoles,
      settings,
      clientContacts,
      deliveryManagersByOffice,
      engineeringManagersByOffice,
      accountManagersByOffice,
      EditProjectBasicInfo,
      EditProjectBasicFinanceInfo,
      HasFinancialAccess,
      ManageProjectContracts,
      ManageProjectRoles,
      ManageProjectRoleAssignments,
      CanCreateProject,
      hasDefoultFinancialAccess,
      listSettings,
      projectMilestones,
      allCurrencies,
      isImportLoading,
      allEngagementTypes,
      fiscalYears,
    } = this.props;
    const {
      showDialogExtend,
      selectedTab,
      isActiveExtendButton,
      projectHistory,
      hasMoreHistory,
      rolesForRender,
      projectBasicInfoData,
      columnsToDisplay,
      innerColumnsToDisplay,
      sortRolesEmployee,
      CONTRACTS_TAB_INDEX,
      ROLES_TAB_INDEX,
      HISTORY_TAB_INDEX,
      MILESTONES_TAB_INDEX,
      TEAMS_TAB_INDEX,
      teamsForRender,
      tabLinks,
      showEngagementTypeDialog,
    } = this.state;
    const contractsFilter = projectDetails
      ? projectDetails.Contracts.filter((contract) => this.filterContracts(
        contract,
        listSettings.childState.contracts.search,
      ))
      : [];
    const {
      contracts, roles, history, teams,
    } = listSettings.childState;
    const showEditTeam = projectDetails && projectDetails.EngagementType && projectDetails.EngagementType.Name === 'Team-based';
    const sortedContracts = this.sortContracts([...this.sortPOInSow(projectDetails.Contracts), ...projectDetails.MSAs]);
    return !projectDetails ? null : (
      <Scrollbars
        autoHide
        autoHideTimeout={300}
        hideTracksWhenNotNeeded
        ref={this.scrollbarRef}
      >
        {showDialogExtend ? (
          <AlertDialog
            dialogHeader='Warning!'
            dialogTitle='You need to select roles with the same SOW/PO Number'
            closeDialog={this.closeDialog}
            confirmDialog={this.closeDialog}
            confirmButtonTitle='OK'
          />
        ) : null}
        {showEngagementTypeDialog ? (
          <ConfirmationDialog
            dialogHeader='Warning'
            dialogTitle={CHANGE_ENGAGEMENT_TYPE}
            closeButtonTitle='No'
            confirmButtonTitle='Yes'
            closeDialog={this.closeEngagementTypeDialog}
            confirmDialog={this.confirmEngagementTypeDialog}
          />)
          : null}
        <div className='page-container'>
          <div className='project-info'>
            <ProjectBasicInfoForm
              projectDetails={projectDetails}
              projectTeams={projectTeams}
              data={projectBasicInfoData}
              projectContracts={projectDetails && projectDetails.Contracts}
              isEditable={EditProjectBasicInfo}
              projectMilestones={projectMilestones}
              EditProjectBasicFinanceInfo={EditProjectBasicFinanceInfo}
              viewFinancialInfo={HasFinancialAccess}
              allBillingTypes={billingTypes}
              allClients={sortByAplhabet(filtersReducer.Clients)}
              allOffices={filtersReducer.Offices}
              allClientManagers={sortByAplhabet(clientContacts)}
              allDeliveryManagers={sortByAplhabet(
                deliveryManagersByOffice.map((item) => ({
                  label: item.Name,
                  value: item.Id,
                })),
              )}
              allEngineeringManagers={sortByAplhabet(
                engineeringManagersByOffice.map((item) => ({
                  label: item.Name,
                  value: item.Id,
                })),
              )}
              allAccountManagers={sortByAplhabet(accountManagersByOffice)}
              allCurrencies={allCurrencies}
              deleteProject={this.deleteProject}
              clientManagerNameReg={clientManagerNameReg}
              handleSubmit={formHandleSubmit}
              currencyProject={projectDetails.Currency}
              isClientManager
              allEngagementTypes={allEngagementTypes}
              openEngagementTypeDialog={this.openEngagementTypeDialog}
              isOnlyActiveRoles={!defaultCheckedShowRoles}
              fiscalYears={fiscalYears}
            />
          </div>
          <Tabs
            className='project-info__tabs'
            list={tabLinks}
            selectedIndex={selectedTab}
            onTab={this.onTab}
            timeLeft={
              this.isContractTimeLessTwoWeeks(
                settings.ContractFirstNotification,
              ).length
            }
            rolesTimeLeft={
              this.isRolesTimeLessTwoWeeks(settings.RoleFirstNotification)
                .length
            }
          />
          {selectedTab === CONTRACTS_TAB_INDEX ? (
            <ListToolbar
              onSearchClick={this.searchForTab('contracts')}
              key={`ListToolBar-${CONTRACTS_TAB_INDEX}`}
              searchValue={contracts.search}
              showAddButton={ManageProjectContracts}
              customAddButton='Add New SOW'
              customImportSOWButton='Import New SOW'
              showExportButton={false}
              detailsStyles
              onAddNew={this.addNewSOW}
              showImportSOWButton={ManageProjectContracts}
              onImportFile={this.importSOW}
              blockImportSOWButton={isImportLoading}
              showAddMSAButton={ManageProjectContracts}
              onAddMSA={this.addNewMSA}
            />
          ) : selectedTab === ROLES_TAB_INDEX ? (
            <ListToolbar
              onSearchClick={this.searchForTab('roles')}
              key={`ListToolBar-${ROLES_TAB_INDEX}`}
              searchValue={roles.search}
              showAddButton={ManageProjectRoles}
              showExportButton={false}
              detailsStyles
              onAddNew={this.showRoleModal}
              showTitleText='Need to create SOW'
              blockAddButton={contractsFilter.length === 0}
              addExtend={
                ManageProjectContracts
                && CanCreateProject
                && EditProjectBasicInfo
              }
              showToogleForRoles
              defaultChecked={defaultCheckedShowRoles}
              handleChangeShowRoles={this.handleChangeShowRoles}
              extendRoles={this.extendRoles}
              isActiveExtend={isActiveExtendButton}
            />
          ) : selectedTab === HISTORY_TAB_INDEX ? (
            <ListToolbar
              onSearchClick={this.searchForTab('history')}
              key={`ListToolBar-${HISTORY_TAB_INDEX}`}
              searchValue={history.search}
              showDateRange
              handleChooseDate={this.handleChooseDateHistory}
              clearStartDate={this.handleClearStartDateHistory}
              clearEndDate={this.handleClearEndDateHistory}
              startDateHistory={history.startDateHistory}
              endDateHistory={history.endDateHistory}
              detailsStyles
            />
          ) : selectedTab === TEAMS_TAB_INDEX ? (
            <ListToolbar
              onSearchClick={this.searchForTab('teams')}
              key={`ListToolBar-${TEAMS_TAB_INDEX}`}
              searchValue={teams.search}
              showAddButton={ManageProjectRoles && showEditTeam}
              customAddButton='Add New Team'
              detailsStyles
              onAddNew={this.showTeamsModal}
            />
          ) : null}
          {selectedTab === CONTRACTS_TAB_INDEX ? (
            <List
              key={CONTRACTS_TAB_INDEX}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={recursiveSearch(
                sortList(
                  sortedContracts,
                  contracts.sortColumn,
                  contracts.sortDirection,
                ),
                contracts.search,
              )}
              onItemClick={this.handleViewContract}
              onEditClick={this.handleViewContract}
              onAddClick={this.addNewPO}
              onDeleteClick={showDialog}
              sortColumnName={contracts.sortColumn}
              sortDirection={contracts.sortDirection}
              setSortSettings={this.setSortSettingsForTab('contracts')}
              deleteConfig={this.mainContractsDeleteConfigs}
              renderCustomEntity={this.renderAmendmentMenu}
              isSowOrPO
              getCustomUrlForDelete={this.getCustomUrlForDeleteContract}
              dropdownList={{
                columnsToDisplay: innerColumnsToDisplay.POs,
                items: recursiveSearch(
                  sortList(
                    sortedContracts,
                    contracts.sortColumn,
                    contracts.sortDirection,
                  ),
                  contracts.search,
                ),
                property: 'POs',
                getDeeper: true,
                onEditClick: ManageProjectContracts ? this.editPO : this.viewPO,
                onItemClick: ManageProjectContracts ? this.editPO : this.viewPO,
                onAddClick: this.addNewPO,
                onDeleteClick: showDialog,
                deleteConfig: this.poDeleteConfig,
                timeLeft: settings.ContractFirstNotification,
                isHaveRights: ManageProjectContracts,
                isSowOrPO: true,
                getCustomUrlForDelete: this.getCustomUrlForDeleteContract,
              }}
              highOrderConfig={{
                Amendments: this.renderAmendmentList,
              }}
              timeLeft={settings.ContractFirstNotification}
              isHaveRights={ManageProjectContracts}
              onImportFile={this.importContract}
              viewFinancialInfo={HasFinancialAccess}
              config={{
                Status: { cell: CustomStatusCell },
                EndDate: {
                  cell: CellTimeleft,
                  daysToExpire: settings.ContractFirstNotification,
                },
                Budget: { cell: CellBudget },
                RealizedRevenue: { cell: CellBudget },
                RevenueReceived: { cell: CellBudget },
                CurrencyType: { currency: projectDetails.Currency },
              }}
            />
          ) : selectedTab === ROLES_TAB_INDEX ? (
            <List
              key={ROLES_TAB_INDEX}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={rolesForRender}
              sortColumnName={roles.sortColumn}
              sortDirection={roles.sortDirection}
              setSortSettings={this.setSortSettingsForTab('roles')}
              hideAddNewEntity
              onItemClick={ManageProjectRoles ? this.editRole : this.viewRole}
              onEditClick={ManageProjectRoles ? this.editRole : this.viewRole}
              onDeleteClick={showDialog}
              deleteConfig={this.roleDeleteConfig}
              dropdownList={{
                columnsToDisplay: innerColumnsToDisplay.Employees,
                items: sortRolesEmployee,
                onItemClick: this.editAssignment,
                onEditClick: this.editAssignment,
                onDeleteClick: this.deleteAssignment,
                deleteConfig: this.roleEmployeesDeleteConfig,
                property: 'Employees',
                getDeeper: true,
                showCheckboxes: true,
                timeLeft: settings.RoleFirstNotification,
                withoutSort: true,
                isHaveRights: ManageProjectRoleAssignments,
                hideAddNewEntity: true,
              }}
              defaultOpenDropDown
              showCheckboxes
              checkRole={this.getRolesForExtend}
              onClickCheckbox={this.onChangeCheckecRole}
              timeLeft={settings.RoleFirstNotification}
              isHaveRights={ManageProjectRoles}
              viewFinancialInfo={HasFinancialAccess}
              config={{
                Status: { cell: CustomStatusCell },
                Rate: { cell: CellRate },
                Assignment: { cell: CellAssignment },
                SowPo: {
                  cell: CellBillingCode,
                  contractsId: this.isContractTimeLessTwoWeeks(
                    settings.ContractFirstNotification,
                  ),
                },
                EndDate: {
                  cell: CellTimeleft,
                  daysToExpire: settings.ContractFirstNotification,
                },
                CurrencyType: { currency: projectDetails.Currency },
              }}
            />
          ) : selectedTab === MILESTONES_TAB_INDEX ? (
            <List
              key={MILESTONES_TAB_INDEX}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={this.sortProjectMilestones()}
              generateInnerColumnsToDisplay={
                this.generateColumnsToDisplayForMilestonesSubList
              }
              dropdownList={{
                columnsToDisplay: innerColumnsToDisplay.Milestones,
                items: projectMilestones,
                property: 'Milestones',
                getDeeper: true,
                onEditClick: this.saveMilestoneEditing,
                onAddClick: this.redirectToParentContractView,
                hasEditableFields: true,
                isHaveRights: ManageProjectContracts,
                hideDeleteEntity: true,
                needToAddNewEntity: true,
                titleForAddingEntity: 'Add Milestone',
                editSpan: 'Edit',
                renderCustomEntity: this.renderOverrideRealizedRevenueMenuItem,
                onRenderCustomEntityClick: this.redirectToParentContractView,
                onEditingModeToggle: this.handleMilestoneDropdownModeToggle,
                config: {
                  Milestone: {
                    cell: CellDate,
                    format: 'MMMM, YYYY',
                  },
                  MilestoneAmount: { cell: CellBudget },
                  InvoicedAmount: {
                    cell: CellToggleBudget,
                    editingValueSetter: this.handleMilestoneFormUpdate,
                  },
                  RevenueReceived: {
                    cell: CellToggleBudget,
                    editingValueSetter: this.handleMilestoneFormUpdate,
                  },
                  InvoicedDate: {
                    cell: CellDatePicker,
                    formClassName: 'cell-container_datepicker--auto-width',
                    format: 'MM/DD/YYYY',
                    editingValueSetter: this.handleMilestoneFormUpdate,
                  },
                  RemainingBalance: { cell: CellBudget },
                  Approved: {
                    cell: CellToggleCheckbox,
                    editingValueSetter: this.handleMilestoneFormUpdate,
                  },
                  UsedAssignmentBackupUser: {
                    cell: CellSwitcher,
                  },
                  CurrencyType: { currency: projectDetails.Currency },
                },
              }}
              isHaveRights={ManageProjectContracts}
              viewFinancialInfo={HasFinancialAccess}
              hideDots
              dontShowOptions
              config={{
                Budget: { cell: CellBudget },
                RealizedRevenue: { cell: CellBudget },
                RemainingBalance: { cell: CellBudget },
                ProjectedRevenue: { cell: CellBudget },
                Leftover: { cell: CellBudget },
                BookedRevenue: { cell: CellBudget },
                CurrentBudgetUtilization: { cell: CellPercent },
                ProjectedBudgetUtilization: { cell: CellPercent },
                CurrencyType: { currency: projectDetails.Currency },
                RevenueReceived: { cell: CellBudget },
              }}
            />
          ) : selectedTab === HISTORY_TAB_INDEX ? (
            <ProjectHistory
              history={projectHistory.History}
              changeAmountHistory={debounce(this.changeAmountHistory, 500)}
              hasMoreHistory={hasMoreHistory}
              hasDefoultFinancialAccess={hasDefoultFinancialAccess}
              HasFinancialAccess={HasFinancialAccess}
              scrollbarRef={this.scrollbarRef.current && this.scrollbarRef.current.view}
            />
          ) : selectedTab === TEAMS_TAB_INDEX ? (
            <List
              key={TEAMS_TAB_INDEX}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={teamsForRender}
              sortColumnName={teams.sortColumn}
              sortDirection={teams.sortDirection}
              setSortSettings={this.setSortSettingsForTab('teams')}
              hideAddNewEntity
              hideDots={!(ManageProjectRoles && showEditTeam)}
              dontShowOptions={!(ManageProjectRoles && showEditTeam)}
              isHaveRights={ManageProjectRoles && showEditTeam}
              onEditClick={this.editTeam}
              onDeleteClick={showDialog}
              deleteConfig={this.teamDeleteConfig}
              dropdownList={{
                columnsToDisplay: innerColumnsToDisplay.Members,
                items: projectTeams.Teams,
                property: 'Members',
                getDeeper: true,
                hideDots: true,
                dontShowOptions: true,
              }}
              config={{
                Status: { cell: CustomStatusCell },
                EndDate: {
                  cell: CellTimeleft,
                  daysToExpire: settings.ContractFirstNotification,
                },
              }}
            />
          ) : null}
        </div>
      </Scrollbars>
    );
  }
}

ProjectDetails.propTypes = {
  projectDetails: PropTypes.object,
  selectedTab: PropTypes.number,
  getProjectDetails: PropTypes.func,
  getProjectMilestones: PropTypes.func,
  patchProjectMilestone: PropTypes.func,
  getProjectRoleConfig: PropTypes.func,
  showRoleModal: PropTypes.func,
  getProjectEmployees: PropTypes.func,
  filtersReducer: PropTypes.object,
  billingTypes: PropTypes.array,
  getStatus: PropTypes.func,
  getEmployeeStatus: PropTypes.func,
  getSelectedTab: PropTypes.func,
  setTransferedRoles: PropTypes.func,
  getRoles: PropTypes.func,
  getEditAssignment: PropTypes.func,
  toogleRolesStatus: PropTypes.func,
  showDialog: PropTypes.func,
  settings: PropTypes.object,
  getHistory: PropTypes.func,
  viewHistory: PropTypes.object,
  getManagersByOffice: PropTypes.func.isRequired,
  getManageInvoiceDates: PropTypes.func.isRequired,
  deliveryManagersByOffice: PropTypes.array.isRequired,
  engineeringManagersByOffice: PropTypes.array.isRequired,
  accountManagersByOffice: PropTypes.array.isRequired,
  isImportLoading: PropTypes.bool,
  allEngagementTypes: PropTypes.array.isRequired,
};

export default connect(
  (store) => ({
    projectDetails: store.projectsReducer.projectDetails,
    projectTeams: store.projectsReducer.projectTeams,
    filtersReducer: store.filtersReducer.filters,
    clientContacts: store.projectsReducer.clientContacts,
    billingTypes: store.projectsReducer.projectTypes,
    offices: store.filtersReducer.filters.Offices,
    selectedTab: store.selectedTabReducer.selectedTab,
    loading: store.loadingReducer.isLoading,
    defaultCheckedShowRoles: store.showRoles.isOnlyActiveRoles,
    settings: store.adminReducer.settings,
    viewHistory: store.projectsReducer.ViewHistory,
    deliveryManagersByOffice: store.projectsReducer.deliveryManagersByOffice,
    engineeringManagersByOffice:
      store.projectsReducer.engineeringManagersByOffice,
    accountManagersByOffice: store.projectsReducer.accountManagersByOffice,
    hasDefoultFinancialAccess: store.authReducer.HasFinancialAccess,
    allCurrencies: store.projectsReducer.allCurrencies,
    allEngagementTypes: store.projectsReducer.allEngagementTypes,
    projectMilestones: store.projectsReducer.projectMilestones,
    listSettings: store.projectsReducer.listSettings,
    isImportLoading: store.importContractReducer.isLoading,
    fiscalYears: store.projectsReducer.fiscalYears,
  }),
  {
    getProjectDetails,
    getProjectTeams,
    getProjectRoleConfig,
    showRoleModal,
    getProjectEmployees,
    getStatus,
    getEmployeeStatus,
    getSelectedTab,
    setTransferedRoles,
    showDialog,
    getRoles,
    getEditAssignment,
    toogleRolesStatus,
    getHistory,
    getManagersByOffice,
    getProjectMilestones,
    patchProjectMilestone,
    getManageInvoiceDates,
    updateProjectMilestoneEditingForm,
    setComponent,
    resetComponent,
    setListSettings,
    getAllCurrencies,
    importSow,
    importPo,
    importAmendment,
    getAllEngagementTypes,
    getProjectTeamEmployees,
  },
)(ProjectDetails);
