import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sortFilterList } from 'common/components/list/sortList';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import moment from 'moment';
import {
  getWorkforceReports,
  getFiltersForWorkForce,
  setWorkforceReports,
  setWorkforceListSettings,
  resetWorkforceListSettings,
  setWorkforceFiltersValue,
  changeColumnsToDisplay,
} from '../../actions/workforceActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { getFilters } from 'common/actions/filtersActions';
import { debounce } from 'services/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import Scrollbars from 'react-custom-scrollbars';
import { CellBarChart } from 'common/components/list/custom-cell/cell-barchart';
import { CellSkill } from 'common/components/list/custom-cell/cell-skills';
import { emptyArray } from 'utils/const-variable';
import isEmptyObject from 'utils/isEmptyObject';
import {
  EMPLOYEES_STATUS_CONTRACTOR,
  EMPLOYEES_STATUS_EMPLOYEE,
  EMPLOYEES_STATUS_TRAINEE,
  PROJECT_ROLES_EMPLOYEES_FORMER,
  STATUS_COLOR,
} from 'utils/const-variable';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import {
  BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Modal from 'common/components/modal/modal';
import CustomIcon from 'common/components/icon/Icon';
import isObjectNotEquals from 'utils/object-comparison';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import history from 'utils/history';
import { initialState } from '../../reducers/workforceReducer';
import { STATUS_BILLABLE } from '../../../../utils/const-variable';

const filtersNames = [
  'Offices',
  'EmployeeStatusesNew',
  'Clients',
  'Projects',
  'Locations',
];

export class Workforce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: moment().format('YYYY'),
      defaultYears: [],
      hasMoreReports: true,
    };
  }

  componentDidMount() {
    const {
      getFilters,
      getFiltersForWorkForce,
      statuses,
      setComponent,
      listSettings: {
        searchValue,
        sortColumn,
        sortDirection,
        takeAmountReports,
      },
      getWorkforceReports,
      setWorkforceFiltersValue,
    } = this.props;

    getFiltersForWorkForce();
    getFilters(filtersNames);
    this.getDefaulYearsList();
    const defaultFilters = this.setDefaultFilter(statuses);

    getWorkforceReports(defaultFilters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    setWorkforceFiltersValue({
      StartDate: defaultFilters.StartDate,
      EndDate: defaultFilters.EndDate,
    });
    setComponent({ title: `Workforce report - ${defaultFilters.StartDate} - ${defaultFilters.EndDate}` });
  }

  componentDidUpdate(prevProps) {
    const {
      getWorkforceReports, statuses, listSettings, billableStatus, setComponent,
    } = this.props;
    const {
      filters, searchValue, sortColumn, sortDirection, takeAmountReports,
    } = listSettings;
    if (prevProps.listSettings !== initialState.listSettings && isObjectNotEquals(prevProps.listSettings, listSettings)) {
      getWorkforceReports(filters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    }
    if (isEmptyObject(filters) && prevProps.statuses !== statuses || (!prevProps.billableStatus.length && billableStatus.length)) {
      this.setDefaultFilter(statuses);
    }

    if (filters && filters.StartDate && filters.EndDate) {
      setWorkforceFiltersValue({
        StartDate: filters.StartDate,
        EndDate: filters.EndDate,
      });
      setComponent({ title: `Workforce report - ${filters.StartDate} - ${filters.EndDate}` });
    }
  }

  componentWillUnmount() {
    const { setWorkforceReports, resetComponent, resetWorkforceListSettings } = this.props;
    setWorkforceReports(emptyArray);
    resetComponent();
    resetWorkforceListSettings();
  }

  renderBigBarChart = (dataComponent, toogleComponent) => {
    return (
      <Modal onClick={toogleComponent}>
        <div className='barchart_modal'>
          <CustomIcon iconName='cross' onClick={toogleComponent} className='barchart_modal-cross' />
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              margin={{
                top: 5,
                right: 15,
                left: 15,
                bottom: 10,
              }}
              data={dataComponent}
            >
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey='Billable' stackId='someId' fill={STATUS_COLOR.slightlyDesaturatedBlue} minPointSize={0.1} />
              <Bar dataKey='BillableOvertime' stackId='someId' fill={STATUS_COLOR.softRed} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Modal>
    );
  }

  setDefaultFilter = (statuses) => {
    const {
      listSettings: { filters }, location, setWorkforceListSettings, billableStatus,
    } = this.props;
    const defaultFilters = statuses.reduce((acc, curValue) => {
      const defaultEmpl = [];
      if (
        curValue.Name === EMPLOYEES_STATUS_CONTRACTOR
        || curValue.Name === EMPLOYEES_STATUS_EMPLOYEE
        || curValue.Name === EMPLOYEES_STATUS_TRAINEE
        || curValue.Name === PROJECT_ROLES_EMPLOYEES_FORMER
      ) {
        defaultEmpl.push(curValue.Id);
      }
      return [...acc, ...defaultEmpl];
    }, []);
    const defaultBillableStatus = billableStatus && billableStatus.find(item => item.Name && item.Name === STATUS_BILLABLE);

    const navFilters = location.state && location.state.filters;
    const startDate = moment().startOf('month').format('MM/DD/YYYY');
    const endDate = moment().format('MM/DD/YYYY');

    const currentFilters = defaultFilters.length ? {
      ...filters,
      EmployeeTypes: defaultFilters,
      Offices: navFilters && navFilters.OfficeIds,
      BillableStatuses: defaultBillableStatus && [defaultBillableStatus.Id],
      StartDate: filters.StartDate ? filters.StartDate : startDate,
      EndDate: filters.EndDate ? filters.EndDate : endDate,
    } : {
      ...filters,
      StartDate: startDate,
      EndDate: endDate,
    };

    setWorkforceListSettings({
      filters: currentFilters,
    });

    return currentFilters;
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setWorkforceListSettings } = this.props;
    setWorkforceListSettings({
      sortColumn,
      sortDirection,
    });
  };

  applyFilters = (filters) => {
    const { setWorkforceListSettings, textDataForFilters } = this.props;
    const newFilters = {
      ...filters,
      ...textDataForFilters,
    };
    const isExistsFilters = Object.keys(newFilters).some((filter) => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setWorkforceListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
    this.setState({
      currentYear: filters.Year ? filters.Year : moment().format('YYYY'),
    });
  }

  resetFilters = () => {
    const { setWorkforceListSettings } = this.props;
    setWorkforceListSettings({ filters: {} });
    this.setState({
      currentYear: moment().format('YYYY'),
    });
  }

  getDefaulYearsList = () => {
    const defaultYears = [];
    const years = moment().year();
    for (let i = years - 10; i <= years + 10; i++) {
      const data = {
        Id: i,
        Name: +i,
      };
      defaultYears.push(data);
    }
    this.setState({
      defaultYears,
    });
  }

  getRequiredColumns = () => {
    const { columnsToDisplay } = this.props;
    const requiredColumns = [];

    for (const column of columnsToDisplay) {
      if (column.isSelected) {
        requiredColumns.push(column.Name.replace(/ /g, ''));
      }
    }

    return requiredColumns;
  }

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    const startDate = filters.StartDate ? filters.StartDate : moment().startOf('month').format('MM/DD/YYYY');
    const endDate = filters.EndDate ? filters.EndDate : moment().format('MM/DD/YYYY');
    const requestFilters = { ...filters };
    requestFilters.requiredColumns = this.getRequiredColumns();
    downloadXLSXDocument(requestFilters, searchValue, 'workforce-reports/export-xlsx?Name=', `Workforce Report_${startDate}_${endDate}.xlsx`);
  }

  changeAmountReports = (page) => {
    const {
      isLoading,
      listSettings: {
        takeAmountReports,
      },
      setWorkforceListSettings,
    } = this.props;
    const magnificationFactor = 15;
    const increaseParams = takeAmountReports + page * magnificationFactor;
    if (!isLoading) {
      this.checkIfNeedMoreReports(increaseParams);
      setWorkforceListSettings({ takeAmountReports: increaseParams });
    }
  }

  checkIfNeedMoreReports = (takeAmountReports) => {
    const { totalCount } = this.props;
    this.setState({
      hasMoreReports: takeAmountReports <= totalCount,
    });
  }

  searchWorkforceReports = (searchValue) => {
    const { setWorkforceListSettings } = this.props;
    setWorkforceListSettings({ searchValue });
  }

  handleDropdownListItemClick = (assignmentId) => {
    const { reports: { workforce } } = this.props;

    if (!workforce) {
      return;
    }

    const assignmentProject = workforce
      .map((w) => w.Assignments)
      .flat()
      .map((a) => ({ assignmentId: a.Id, projectId: a.Project.Id }))
      .find((x) => x.assignmentId === assignmentId);

    if (!assignmentProject) {
      return;
    }

    history.push({
      pathname: `/projects/${assignmentProject.projectId}`,
    });
  }

  handleChangeStartDate = (date) => {
    const { setWorkforceFiltersValue } = this.props;
    setWorkforceFiltersValue({ StartDate: date ? moment(date).format('MM/DD/YYYY') : null });
  }

  handleChangeEndDate = (date) => {
    const { setWorkforceFiltersValue } = this.props;
    setWorkforceFiltersValue({ EndDate: date ? moment(date).format('MM/DD/YYYY') : null });
  }

  render() {
    const {
      reports, offices, statuses, billableStatus,
      locations, textDataForFilters, clients, projects, columnsToDisplay, innerColumnsToDisplay, changeColumnsToDisplay, listSettings: {
        sortColumn, sortDirection, filters, searchValue,
      },
    } = this.props;
    const { hasMoreReports } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          showExportButton
          searchValue={searchValue}
          exportDocument={this.downloadXLSXDocument}
          searchPlaceholder='Search Reports'
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchWorkforceReports}
          columnsToDisplay={columnsToDisplay}
          changeColumnsToDisplay={changeColumnsToDisplay}
          items={[{
            name: 'Report Start Date',
            id: 'StartDate',
            datepicker: true,
            placeholder: 'Start Date',
            formClassName: 'step__form svg-calendar-add-info date_picker-toolbar',
            datePickerClassName: 'step__date-picker data-pisker__filter--margin-sow',
            value: textDataForFilters.StartDate,
            onChange: this.handleChangeStartDate,
          },
          {
            name: 'Report End Date',
            id: 'EndDate',
            datepicker: true,
            placeholder: 'End Date',
            formClassName: 'step__form svg-calendar-add-info date_picker-toolbar',
            datePickerClassName: 'step__date-picker data-pisker__filter--margin-sow',
            value: textDataForFilters.EndDate,
            onChange: this.handleChangeEndDate,
          },
          {
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
          },
          {
            name: 'Employee Type',
            id: 'EmployeeTypes',
            options: sortFilterList(statuses) || [],
            multiSelect: true,
            placeholder: 'All Employee Types',
            filtersItem: filters && filters.EmployeeTypes,
          },
          {
            name: 'Clients',
            id: 'Clients',
            options: sortFilterList(clients) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Clients',
            filterItem: filters && filters.Clients,
          },
          {
            name: 'Projects',
            id: 'Projects',
            options: sortFilterList(projects) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Projects',
            filtersItem: filters && filters.Projects,
          },
          {
            name: 'Billable Status',
            id: 'BillableStatuses',
            options: sortFilterList(billableStatus) || [],
            multiSelect: true,
            placeholder: 'All Billable Statuses',
            filtersItem: filters && filters.BillableStatuses,
          },
          {
            name: 'Location',
            id: 'Locations',
            options: sortFilterList(locations) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Locations',
            filtersItem: filters && filters.Locations,
          },
          ]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <InfiniteScroll
              pageStart={1}
              loadMore={debounce(this.changeAmountReports, 500)}
              hasMore={hasMoreReports}
              useWindow={false}
              initialLoad={false}
              threshold={500}
            >
              <List
                columnsToDisplay={columnsToDisplay}
                items={reports.workforce}
                hideDots
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                renderAdditionalComponent={this.renderBigBarChart}
                dropdownList={{
                  columnsToDisplay: innerColumnsToDisplay,
                  items: reports.workforce || [],
                  property: 'Assignments',
                  getDeeper: true,
                  hideDots: true,
                  withoutSort: true,
                  dontShowOptions: true,
                  onItemClick: this.handleDropdownListItemClick,
                }}
                fixedHeader
                dontShowOptions
                config={{
                  'Load': { cell: CellBarChart },
                  'Skills': { cell: CellSkill },
                  'StatusOnProject': { cell: CustomStatusCell },
                }}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

Workforce.propTypes = {
  reports: PropTypes.object.isRequired,
  downloadXLSXDocument: PropTypes.func.isRequired,
  getFiltersForWorkForce: PropTypes.func.isRequired,
};

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  offices: store.filtersReducer.filters.Offices,
  statuses: store.filtersReducer.filters.EmployeeStatusesNew,
  billableStatus: store.reportsReducer.filters.billableStatus,
  locations: store.filtersReducer.filters.Locations,
  totalCount: store.reportsReducer.workforceTotalCount,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.workforceReducer.listSettings,
  textDataForFilters: store.workforceReducer.textDataForFilters,
  clients: store.filtersReducer.filters.Clients,
  projects: store.reportsReducer.filters.projectsName,
  columnsToDisplay: store.workforceReducer.columnsToDisplay,
  innerColumnsToDisplay: store.workforceReducer.innerColumnsToDisplay,
}), {
  getWorkforceReports,
  getFilters,
  downloadXLSXDocument,
  getFiltersForWorkForce,
  setComponent,
  resetComponent,
  setWorkforceReports,
  setWorkforceListSettings,
  resetWorkforceListSettings,
  setWorkforceFiltersValue,
  changeColumnsToDisplay,
})(Workforce);
