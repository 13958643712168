import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './select.css';

import {
  FormControl, Select as MaterialSelect, InputLabel, MenuItem,
} from '@material-ui/core';
import CustomIcon from '../../icon/Icon';

class Select extends React.Component {
  clearValue = () => {
    const { name, clearValue } = this.props;
    if (name) {
      clearValue(name);
    }
    clearValue();
  }

  render() {
    const {
      label,
      value,
      showClear = false,
      formClassName,
      selectClassName,
      optionsClassName,
      inputProps,
      onChange,
      onBlur,
      selectOptions,
      isOptionObject,
      placeholder,
      isRequired,
      disabled,
      extendedValue,
      helperText,
      clearClassName,
      onClose,
      onFocus,
      addEmployeeStyles,
      open,
      MenuProps,
    } = this.props;

    const selectValue = !!value || value === 0 ? value : 'none';

    return (
      <FormControl className={formClassName}>
        {
          label && (
            <InputLabel shrink htmlFor={inputProps && inputProps.id} required={isRequired}>{label}</InputLabel>
          )
        }
        <MaterialSelect
          open={open}
          onClose={onClose}
          onFocus={onFocus}
          onBlur={onBlur}
          value={selectValue}
          id={inputProps && inputProps.id}
          onChange={extendedValue && isOptionObject ? (e) => onChange({ target: e.target, item: selectOptions.find(item => item.Id === e.target.value) })
            : onChange}
          inputProps={inputProps}
          disabled={disabled}
          title={helperText ? helperText : null}
          className={classNames(
            selectClassName,
            {
              'placeholder--is-active': selectValue === 'none' && !disabled && !addEmployeeStyles,
            },
            {
              'placeholder--is-disabled': disabled,
            },
          )}
          MenuProps={MenuProps}
        >
          {
            selectValue === 'none' && placeholder && (
              <MenuItem
                value='none'
                disabled
                className={optionsClassName}
              >
                {placeholder}
              </MenuItem>
            )
          }
          {
            isOptionObject ? selectOptions && selectOptions.map((item, i) => (
              <MenuItem
                key={i}
                value={item.Id}
                className={optionsClassName}
                componentname={item.Name}
              >
                {item.Name}
              </MenuItem>
            ))
              : (
                selectOptions && selectOptions.map((item, i) => (
                  <MenuItem key={i} value={item} className={optionsClassName}>
                    {item}
                  </MenuItem>
                )))
          }
        </MaterialSelect>
        {
          showClear && value && value !== 'none' && !disabled ? (
            <div className={`clear-svg clear-svg-center ${clearClassName}`} onClick={this.clearValue}>
              <CustomIcon iconName='cross' />
            </div>
          ) : null
        }
      </FormControl>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  selectOptions: PropTypes.array,
  isOptionObject: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  extendedValue: PropTypes.bool,
  helperText: PropTypes.string,
  clearValue: PropTypes.func,
};

export default Select;
