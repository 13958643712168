import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_MANAGE_SKILLS,
  setManageSkills,
  PUT_MANAGE_SKILLS,
  POST_MANAGE_SKILLS,
  MERGE_MANAGE_SKILLS,
} from '../actions/adminManageSkills';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageSkills() {
  try {
    yield put(startRequest());
    const skills = yield call(http, {
      method: 'GET',
      url: 'skills',
    });
    yield put(setManageSkills(skills.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* putSkill(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `skills/${action.payload.Id}`,
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageSkills);
  } catch (e) {
    // error message
  }
}

export function* postSkill(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'skills',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageSkills);
  } catch (e) {
    // error message
  }
}


export function* mergeSkills(action) {
  try {
    yield call(http, {
      method: 'PATCH',
      url: 'skills',
      data: action.payload,
    });
    yield call(manageSkills);
  } catch (e) {
    // error message
  }
}

export default function* adminManageSkills() {
  yield takeEvery(GET_MANAGE_SKILLS, manageSkills);
  yield takeEvery(PUT_MANAGE_SKILLS, putSkill);
  yield takeEvery(POST_MANAGE_SKILLS, postSkill);
  yield takeEvery(MERGE_MANAGE_SKILLS, mergeSkills);
}
