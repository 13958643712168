import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderProjectTypeIcon } from 'services/renderProjectTypeIcon';
import classNames from 'classnames';

export class CellProjectIcon extends Component {
  render() {
    const { item, column } = this.props;
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        <div className='c-list-logo' title={item.Billability.Name}>
          {renderProjectTypeIcon(item.Billability.Name)}
        </div>
        <span
          title={item.Name}
          className='col-span cell_column-orien'
        >
          {item.Name}
        </span>
      </div>
    );
  }
}

CellProjectIcon.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
