import React, { Component } from 'react';
import CustomIcon from 'common/components/icon/Icon';

export class ContactsList extends Component {
  render() {
    const { contacts, removeContacts } = this.props;
    return (
      <div className='form-block margin-down-role create-contacts_client'>
        <div className='c-list__cont c-role-modal__list'>
          <div className='c-list__header'>
            <div className='c-list__column u-flex-4'>
              <span className='c-list__header-name'>Name</span>
            </div>
            <div className='c-list__column u-flex-1'>
              <span className='c-list__header-name u-text-center u-width-100pr'>Job title</span>
            </div>
            <div className='c-list__column u-flex-1'>
              <span className='c-list__header-name u-text-center u-width-100pr'>Email</span>
            </div>
            <div className='c-list__column u-flex-1'>
              <span className='c-list__header-name u-text-center u-width-100pr'>Cell</span>
            </div>
            <div className='c-list__column u-flex-1'>
              <span className='c-list__header-name u-text-center u-width-100pr'>Skype</span>
            </div>
            <div className='c-list__column u-flex-1'>
              <span />
            </div>
          </div>
          {
            contacts.map(e => (
              <div key={e.FirstName + e.LastName} className='c-list__item-cont'>
                <div className='c-list__item-column u-flex-4'>
                  <span>{e.FirstName + ' ' + e.LastName}</span>
                </div>
                <div className='c-list__item-column u-flex-1'>
                  <span>{e.JobTitle}</span>
                </div>
                <div className='c-list__item-column u-flex-1 '>
                  <span>{e.Email}</span>
                </div>
                <div className='c-list__item-column u-flex-1'>
                  <span>{e.Cell}</span>
                </div>
                <div className='c-list__item-column u-flex-1 '>
                  <span>{e.Skype}</span>
                </div>
                <div className='c-list__item-column u-flex-1 '>
                  <span
                    className='u-text-end'
                    onClick={() => removeContacts(e)}
                  >
                    <CustomIcon iconName='trash' />
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
