export const GET_SOW_RENEWAL_STATUS = '@admin/GET_SOW_RENEWAL_STATUS';
export const SET_SOW_RENEWAL_STATUS = '@admin/SET_SOW_RENEWAL_STATUS';
export const UPDATE_SOW_RENEWAL_STATUS = '@admin/UPDATE_SOW_RENEWAL_STATUS';
export const CREATE_SOW_RENEWAL_STATUS = '@admin/CREATE_SOW_RENEWAL_STATUS';
export const DELETE_SOW_RENEWAL_STATUS = '@admin/DELETE_SOW_RENEWAL_STATUS';

export const loadSOWRenewalStatuses = () => ({
  type: GET_SOW_RENEWAL_STATUS,
});

export const setSOWRenewalStatus = (renewalStatuses) => ({
  type: SET_SOW_RENEWAL_STATUS,
  payload: renewalStatuses,
});

export const updateSOWRenewalStatus = (renewalStatus) => ({
  type: UPDATE_SOW_RENEWAL_STATUS,
  payload: renewalStatus,
});

export const createSOWRenewalStatus = (newRenewalStatus) => ({
  type: CREATE_SOW_RENEWAL_STATUS,
  payload: newRenewalStatus,
});

export const deleteSOWRenewalStatus = (id) => ({
  type: DELETE_SOW_RENEWAL_STATUS,
  payload: id,
});
