import * as React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from 'services/debounce';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import isEmptyObject from 'utils/isEmptyObject';
import isObjectNotEquals from 'utils/object-comparison';
import {
  getAuditReports,
  setAuditReports,
  setAuditListSettings,
  resetAuditListSettings,
  setAuditListDates,
  resetAuditListDates,
} from '../../actions/auditActions';
import { getUsers } from 'pages/admin/actions/adminActions';
import Scrollbars from 'react-custom-scrollbars';
import { emptyArray } from 'utils/const-variable';
import { CellAuditWrapper, CellAuditEntityPath, CellAuditValues } from 'common/components/list/custom-cell/cells-audit';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import './audit-list.css';

export class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterUsers: [],
      countAmountReports: 50,
      hasMoreReports: true,
      columnsToDisplay: [
        {
          Name: 'Date', Id: 'DateOfChange', className: 'medium-col', isDate: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Author', Id: 'Author', className: 'medium-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Entity path', Id: 'Entity', className: 'without-width-col', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Change type', Id: 'TypeOfChange', className: 'medium-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Previous/New values', Id: 'AuditValues', className: 'without-width-col', isObject: true, deleteArrow: 'delete-arrow',
        },
      ],
    };
  }

  componentDidMount() {
    const {
      getAuditReports, getUsers, setComponent,
      listSettings: { searchValue, filters },
    } = this.props;
    getUsers();
    this.setUsersForFilter();
    getAuditReports(filters, searchValue);
    setComponent({ title: 'Audit report' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { countAmountReports } = this.state;
    const { getAuditReports, users, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    if (prevProps.users !== users) {
      this.setUsersForFilter();
    }
    if (prevState.countAmountReports !== countAmountReports || isObjectNotEquals(prevProps.listSettings, listSettings)) {
      getAuditReports(filters, searchValue, countAmountReports, 0);
    }
  }

  componentWillUnmount() {
    const {
      resetComponent, setAuditReports, resetAuditListDates, resetAuditListSettings,
    } = this.props;
    setAuditReports(emptyArray);
    resetComponent();
    resetAuditListDates();
    resetAuditListSettings();
  }

  setUsersForFilter = () => {
    const { users } = this.props;
    const filterUsers = users.reduce((acc, item) => {
      acc.push({
        Id: item.EmployeeId,
        Name: item.Name,
      });
      return acc;
    }, []);
    this.setState({
      filterUsers,
    });
  }

  applyFilters = (filters) => {
    const { setAuditListSettings, filtersDates: { StartDate, EndDate } } = this.props;
    const newFilters = {
      ...filters,
      StartDate,
      EndDate,
    };
    const isExistsFilters = Object.keys(newFilters).some((filter) => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setAuditListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  }

  resetFilters = () => {
    const { resetAuditListDates, setAuditListSettings } = this.props;
    resetAuditListDates();
    setAuditListSettings({ filters: {} });
  }

  handleChangeDate = (date, name) => {
    const { setAuditListDates } = this.props;
    setAuditListDates({
      [name]: date ? date : undefined,
    });
  }

  changeAmountReports = (page) => {
    const { countAmountReports } = this.state;
    const { isLoading } = this.props;
    const offSet = 15;
    const counterResult = countAmountReports + page * offSet;
    if (!isLoading) {
      this.setState({
        countAmountReports: counterResult,
      }, this.checkIfNeedMoreReports);
    }
  }

  checkIfNeedMoreReports = () => {
    const { countAmountReports } = this.state;
    const { totalCount } = this.props;
    this.setState({
      hasMoreReports: countAmountReports > totalCount ? false : true,
    });
  }

  getAuditReports = (searchValue) => {
    const { setAuditListSettings } = this.props;
    setAuditListSettings({ searchValue });
  }

  render() {
    const {
      reports, entityFilters, HasFinancialAccess, permissions, filtersDates,
      listSettings: { filters, searchValue },
    } = this.props;
    const { columnsToDisplay, filterUsers, hasMoreReports } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          searchValue={searchValue}
          searchPlaceholder='Search Reports'
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.getAuditReports}
          items={[{
            name: 'Authors',
            id: 'Authors',
            options: filterUsers || [],
            suggestBox: true,
            placeholder: 'All Authors',
            filtersItem: filters && filters.Authors,
            className: 'z-index_50',
          },
          {
            name: 'Changed entity',
            id: 'ChangedEntities',
            multiSelect: true,
            placeholder: 'All changed entity',
            options: entityFilters || [],
            filtersItem: filters && filters.ChangedEntities,
            className: 'z-index_48',
          },
          {
            name: 'Start Date',
            id: 'StartDate',
            datepicker: true,
            title: 'Start Date',
            formClassName: 'step__form svg-calendar-add-info filter-container__data-picker data-pisker__filter--margin',
            datePickerClassName: 'step__date-picker filter-container__data-picker--class',
            value: filtersDates.StartDate,
            onChange: this.handleChangeDate,
            className: 'z-index_46',
          },
          {
            name: 'End Date',
            id: 'EndDate',
            datepicker: true,
            title: 'End Date',
            formClassName: 'step__form svg-calendar-add-info audit-filters__datapicker--end-date filter-container__data-picker',
            datePickerClassName: 'step__date-picker filter-container__data-picker--class',
            value: filtersDates.EndDate,
            minVal: filtersDates.StartDate,
            onChange: this.handleChangeDate,
            className: 'z-index_44',
          }]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <InfiniteScroll
              pageStart={1}
              loadMore={debounce(this.changeAmountReports, 500)}
              hasMore={hasMoreReports}
              useWindow={false}
              initialLoad={false}
            >
              <List
                columnsToDisplay={columnsToDisplay}
                items={reports}
                hideDots
                dontShowOptions
                config={{
                  'Entity': { cell: CellAuditWrapper(CellAuditEntityPath), HasFinancialAccess, permissions: permissions.HasFinancialAccess },
                  'AuditValues': { cell: CellAuditWrapper(CellAuditValues), HasFinancialAccess, permissions: permissions.HasFinancialAccess },
                }}
                fixedHeader
                contentWrapper={(props) => (
                  <div className='audit-list__content'>{props.children}</div>
                )}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect((store) => ({
  reports: store.reportsReducer.reports.audit,
  totalCount: store.reportsReducer.auditTotalCount,
  users: store.adminReducer.users,
  offices: store.filtersReducer.filters.Offices,
  entityFilters: store.filtersReducer.filters.updateTypeEntity,
  HasFinancialAccess: store.authReducer.HasFinancialAccess,
  permissions: store.authReducer.user.Permissions,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.auditReducer.listSettings,
  filtersDates: store.auditReducer.filtersDates,
}), {
  getAuditReports,
  getUsers,
  setComponent,
  resetComponent,
  setAuditReports,
  setAuditListSettings,
  resetAuditListSettings,
  setAuditListDates,
  resetAuditListDates,
})(Audit);
