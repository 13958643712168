/* eslint-disable prefer-destructuring */
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import './list-item.css';
import { Badge } from 'common/components/badges/badges';
import { List } from './list';
import { getColorFromString } from 'services/getColor';
import { DELETE_SPAN_REMOVE_ACCESS } from 'utils/const-variable';
import CustomIcon from '../icon/Icon';
import { ACTIVE, DATES_CROSSING_MESSAGE } from 'utils/const-variable';
import { sortList } from 'common/components/list/sortList';
import { CellCheckbox } from './custom-cell/cell-checkbox';
import ImportContract from 'common/components/form-items/button-import-pdf/button-import-pdf';

export class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      column: 'Name',
      sortDirection: 'Up',
      isNeedUpOptions: false,
      isOpenDropdown: {
        parent: {},
      },
      isOpenPOs: {},
      components: [],
    };
  }

  componentDidMount() {
    const {
      defaultOpenDropDown, idItem, highOrderConfig, hasEditableFields,
    } = this.props;
    if (defaultOpenDropDown) {
      this.onToggleDropdown(idItem);
    }
    highOrderConfig && this.renderHighOrderConfig();
    hasEditableFields
      && this.setState({
        isEditingMode: false,
      });
  }

  componentDidUpdate(prevProps) {
    const { highOrderConfig } = this.props;
    if (prevProps.highOrderConfig !== highOrderConfig) {
      highOrderConfig && this.renderHighOrderConfig();
    }
  }

  onToggleDropdown = (id) => {
    const { isOpenDropdown } = this.state;
    const { dropdownList } = this.props;
    const isOpenDrop = isOpenDropdown;
    if (isOpenDrop[id]) delete isOpenDrop[id];
    else isOpenDrop[id] = true;
    if (!dropdownList.deepestProperty) {
      if (isOpenDrop.parent[id]) delete isOpenDrop.parent[id];
      else isOpenDrop.parent[id] = true;
    }
    this.setState({ isOpenDropdown: isOpenDrop });
  };

  setSortSettings = (column, sortDirection) => {
    this.setState({
      column,
      sortDirection,
    });
  };

  toggleShowOptions = (optsObj, event) => {
    const { showOptions } = this.state;
    const { onItemClick, idItem } = this.props;

    if (optsObj.item === 'options') {
      const elementPositions = event.target.getBoundingClientRect();
      const windowHeight = document.documentElement.clientHeight;
      let minLimitToBottom = 0;

      if (optsObj.resultClass) {
        switch (optsObj.resultClass) {
          case 'c-list-item_push-options-up_low-middle':
            minLimitToBottom = 100;
            break;
          case 'c-list-item_push-options-up_middle':
            minLimitToBottom = 130;
            break;
          case 'c-list-item_push-options-up_high':
            minLimitToBottom = 180;
            break;
          case 'c-list-item_push-options-up_low':
            minLimitToBottom = 45;
            break;
          default:
            minLimitToBottom = 50;
            break;
        }
      }

      this.setState({
        showOptions: !showOptions,
        isNeedUpOptions: ((windowHeight - elementPositions.bottom) < minLimitToBottom),
      });
      event.stopPropagation();
    } else if (optsObj.item === 'dropdown') {
      event.stopPropagation();
      this.onToggleDropdown(idItem);
    } else {
      onItemClick && onItemClick(optsObj.item);
    }
  };

  hideOptions = () => {
    this.setState({
      showOptions: false,
    });
  };

  deleteItem =
    (
      onDeleteClick,
      showDeleteSpan,
      item,
      deleteConfig,
      isOnlyActive,
      parentDropdownId,
      dontUseDeleteConfig,
      parentId,
    ) => () => {
      const { getCustomUrlForDelete } = this.props;
      if (
        onDeleteClick
        && showDeleteSpan !== DELETE_SPAN_REMOVE_ACCESS
        && !dontUseDeleteConfig
      ) {
        return onDeleteClick({
          item,
          entityId: deleteConfig && deleteConfig.entityId,
          url:
            deleteConfig && deleteConfig.customUrl
              ? getCustomUrlForDelete(
                deleteConfig.deleteUrlData,
                item.Id,
                parentId,
                deleteConfig.projectId,
              )
              : deleteConfig && `${deleteConfig.deleteUrlData}${item.Id}`,
          searchUpdateSaga: deleteConfig && deleteConfig.searchUpdateSaga,
          isOnlyActive: !isOnlyActive,
          parentDropdownId,
        });
      }
      if (onDeleteClick) {
        return onDeleteClick(item.UserId || item.Id);
      }
    };

  editItem = (item, onEditClick, getFullEditItem, parentDropdownId) => () => {
    const { hasEditableFields } = this.props;
    if (hasEditableFields) {
      this.hideOptions();
      return this.toggleEditingMode();
    }
    if (onEditClick && getFullEditItem) {
      return onEditClick({
        item,
        parentDropdownId,
      });
    }
    onEditClick(item.Id || item.UserId || item.ItemId);
  };

  copyItem = (item, onCopyClick) => () => {
    onCopyClick(item);
  }

  saveEditedFields = () => {
    const { hasEditableFields, onEditClick, item } = this.props;
    hasEditableFields
      && onEditClick({
        Id: item.Id,
      });
    this.toggleEditingMode();
  };

  toggleEditingMode = () => {
    const { item, onEditingModeToggle } = this.props;
    const { isEditingMode, editingFields } = this.state;

    this.setState({
      isEditingMode: !isEditingMode,
      editingFields: isEditingMode ? null : editingFields,
    }, () => {
      onEditingModeToggle && onEditingModeToggle(!isEditingMode, item.Id);
    });
  };

  getDropdownItems = (items, idItem, withoutSort, props) => {
    const { column, sortDirection } = this.state;
    const { parentDropDown } = this.props;
    let result;
    let parentkey;
    if (parentDropDown) {
      parentkey = Object.keys(parentDropDown)[0];
    }
    if (!parentkey) {
      result = items.find((e, i) => i === idItem);
    } else {
      result = items[parentkey][idItem][props];
    }
    return withoutSort ? result : sortList(result, column, sortDirection);
  };

  renderListCell = (column, item, isNull) => {
    const {
      Id,
      isDate,
      isObject,
      isArray,
      isBool,
      isProjectsLenght,
      toFixed,
      isInnerComment,
      isString,
      isTrainingReport,
      isTrainingClient,
      isTrainingDate,
      isBackgroundCheck,
    } = column;
    if (isString) {
      return typeof item[Id] === 'number'
        ? item[Id].toString()
        : isNull
          ? null
          : '———';
    }
    if (toFixed) return item[Id].toFixed(2);
    if (isProjectsLenght) return item[Id].length;
    if (isDate) {
      if (isTrainingReport) {
        const [trainingsProperty, trainingId, fieldToDisplay] = Id.split('.');
        const training = item[trainingsProperty] && item[trainingsProperty].find(t => t.Training && t.Training.Id === trainingId);
        const dateOfPassing = training && (training[fieldToDisplay] && moment(training[fieldToDisplay]).format('MM/DD/YYYY')
          || training.IsNotApplicable && 'N/A');

        return (training && !training.IsOneTime && !training.IsOptional && training.PassedMoreThanYearAgo)
          // We pass isNull when want to receive value for title, to do not assign object to title, need this check
          // Without this check, when we will hover item, we will see [object Object]
          ? isNull
            ? dateOfPassing
            : <span className='error-value'>{dateOfPassing}</span>
          : dateOfPassing;
      }
      if (isBackgroundCheck) {
        return (item[Id] && moment(item[Id]).format('MM/DD/YYYY')) || 'N/A';
      }
      if (isTrainingDate) {
        return (item[Id] && moment(item[Id]).format('MM/DD/YYYY')) || item.IsNotApplicable && 'N/A';
      }
      return item[Id] && moment(item[Id]).format('MM/DD/YYYY');
    }
    if (isObject) {
      switch (Id) {
        case 'Lead': {
          const isLeadApplicable = item && item.IsLeadApplicable;
          return isLeadApplicable ? item[Id] && item[Id].Name : 'N/A';
        }
        case 'SecondInCommand': {
          const isSICApplicable = item && item.IsSecondInCommandApplicable;
          return isSICApplicable ? item[Id] && item[Id].Name : 'N/A';
        }
        case 'CertificationRequester': {
          return (item[Id] && item[Id].Name) || 'Unknown';
        }
        default: {
          return item[Id] && item[Id].Name;
        }
      }
    }
    if (item[Id] && isArray) {
      const formattedOutput = item[Id].map((item) => item.Name || item).join(', ');

      if (isTrainingClient) {
        return formattedOutput || 'Any';
      }

      return formattedOutput;
    }
    if (isBool) {
      return !!item[Id] ? 'Yes' : 'No';
    }
    if (isInnerComment) {
      return <span title={item[Id]}>{item[Id]}</span>;
    }
    return !!item[Id]
      ? item[Id]
      : column.Name === 'Action'
        ? null
        : isNull
          ? null
          : '———';
  };

  getItemError = (item) => {
    const { timeLeft } = this.props;
    const isStatusesCrossing = typeof item.isStatusesCrossing === 'boolean' && item.isStatusesCrossing;
    const isDatesCrossing = typeof item.isDatesCrossing === 'boolean' && item.isDatesCrossing;
    const isRoleInvalid = typeof item.isRoleValid === 'boolean' && !item.isRoleValid;
    const itemError = isDatesCrossing
      || isStatusesCrossing
      || isRoleInvalid
      || item.WithOpenRole
      || item.UnderstaffedRoles
      || item.IsExpiring
      || item.ContractExpiration
      || item.RoleExpiration
      || item.EmployeeExpiration
      || item.UnderstaffedRole
      || item.ProjectExpiration
      || (moment(item.EndDate).diff(new Date(), 'day') < timeLeft
        && item.Status.Name === ACTIVE);
    return itemError;
  };

  renderCustomCell = (CustomCell, column) => {
    const { item, hasEditableFields, ...other } = this.props;
    const { isEditingMode } = this.state;
    return (
      <CustomCell
        {...other}
        item={item}
        isEditingMode={isEditingMode}
        isControlledByListItem={hasEditableFields}
        column={column}
        key={(item.Id || JSON.stringify(item)) + Math.random()}
      />
    );
  };

  itemStopPropagation = (event) => {
    event.stopPropagation();
  };

  renderHighOrderConfig = () => {
    const { highOrderConfig, item } = this.props;
    const options = {};
    const components = [];
    for (const key in highOrderConfig) {
      options[key] = true;
      if (item[key] && !!item[key].length) {
        components.push({
          component: highOrderConfig[key](item[key]),
          key,
        });
      }
    }
    this.setState({
      isOpenPOs: options,
      components: [...components],
    });
  };

  togglePos = () => {
    const { isOpenPOs } = this.state;
    this.setState({
      isOpenPOs: {
        ...isOpenPOs,
        Pos: !isOpenPOs.Pos,
      },
    });
  };

  toggleAmendments = () => {
    const { isOpenPOs } = this.state;
    this.setState({
      isOpenPOs: {
        ...isOpenPOs,
        Amendments: !isOpenPOs.Amendments,
      },
    });
  };

  showPoOriginalTitleItem = (item) => {
    if (!(item.POs && item.POs.length)) {
      if (item.Type === 'Original' || typeof item.Type === 'object') {
        return true;
      }
    }
    return false;
  };

  onAddClick = (item) => () => {
    const { onAddClick } = this.props;
    onAddClick && onAddClick(item);
  };

  onRenderCustomEntityClick = (item) => () => {
    const { onRenderCustomEntityClick } = this.props;
    onRenderCustomEntityClick && onRenderCustomEntityClick(item);
  };

  showImport = (item, type) => {
    switch (type) {
      case 'PO Extension':
        return !!((item.Amendments && !item.Amendments.length) && (item.POs && item.POs.length)) && item.Type && item.Type.Name !== 'MSA';
      case 'PO':
        return this.showPoOriginalTitleItem(item) && item.POs && (item.Amendments && !item.Amendments.length) && item.Type && item.Type.Name !== 'MSA';
      case 'Amendment':
        return item.POs && !item.POs.length && item.Type && item.Type.Name !== 'MSA';
      default: break;
    }
  }

  render() {
    const {
      item,
      dropdownList,
      columnsToDisplay,
      idItem,
      entityName = 'item',
      onItemClick,
      onEditClick,
      onDeleteClick,
      hideDots,
      showCheckboxes,
      checkRole,
      parentId,
      transition,
      isOnlyActive,
      parentDropdownId,
      getFullEditItem,
      showDeleteSpan,
      viewFinancialInfo,
      shadow,
      isHaveRights,
      editSpan,
      config,
      hideAddNewEntity,
      hideEntityEdit,
      parentDropDown,
      deleteConfig,
      dontUseDeleteConfig,
      SOWExtensionOptions,
      renderCustomEntity,
      isSowOrPO,
      highOrderConfig,
      hideDeleteEntity,
      needToAddNewEntity,
      titleForAddingEntity,
      hasEditableFields,
      onRenderCustomEntityClick,
      generateInnerColumnsToDisplay,
      onClickCheckbox,
      onImportFile,
      hideCopyEntity = true,
      onCopyClick,
      shiftOnEditClassName,
    } = this.props;
    const {
      showOptions,
      column,
      sortDirection,
      isNeedUpOptions,
      isOpenDropdown,
      components,
      isOpenPOs,
      isEditingMode,
    } = this.state;
    const { isDatesCrossing } = item;

    const resultClass = (!this.showPoOriginalTitleItem(item) && isHaveRights && needToAddNewEntity)
      || (!this.showPoOriginalTitleItem(item) && needToAddNewEntity && isHaveRights && !item.POs && !isSowOrPO)
      || (!item.POs && this.showPoOriginalTitleItem(item) && !isSowOrPO && !needToAddNewEntity) ? 'c-list-item_push-options-up_low-middle'
      : (item.POs && item.POs.length) || (!item.POs && this.showPoOriginalTitleItem(item))
          || (this.showPoOriginalTitleItem(item) && isHaveRights && !needToAddNewEntity && !item.POs && isSowOrPO) ? 'c-list-item_push-options-up_middle'
        : this.showPoOriginalTitleItem(item) && item.POs
          || (this.showPoOriginalTitleItem(item)) ? 'c-list-item_push-options-up_high' : 'c-list-item_push-options-up_low';

    const items = dropdownList && dropdownList.getDeeper
      ? dropdownList.items.map((item) => {
        return item[dropdownList.property];
      })
      : dropdownList
        ? dropdownList.items
        : null;
    let addNewTitle = null;
    if (isSowOrPO) {
      addNewTitle = (item.POs && item.POs.length) ? 'Add PO Extension' : 'Add Original PO';
    }
    if (titleForAddingEntity) {
      addNewTitle = titleForAddingEntity;
    }
    let parentkey;
    if (parentDropDown) {
      parentkey = Object.keys(parentDropDown)[0];
    }
    const dropdownListToggler = (
      <div
        className='c-list__item-column tiny-col'
        onClick={this.toggleShowOptions.bind(this, { item: 'dropdown', resultClass: null })}
      >
        <CustomIcon
          iconName='dropdown-triangle'
          className={classNames({ 'opened-svg': isOpenDropdown[idItem] })}
        />
      </div>
    );
    const customEntity = renderCustomEntity && renderCustomEntity(item.Id);
    const customEntityActionItem = onRenderCustomEntityClick ? (
      <span onClick={this.onRenderCustomEntityClick({ id: item.Id, parentId })}>
        {customEntity}
      </span>
    ) : (
      customEntity
    );
    const innerColumnsToDisplay = dropdownList
      && (dropdownList.columnsToDisplay && generateInnerColumnsToDisplay
        ? generateInnerColumnsToDisplay(item, dropdownList.columnsToDisplay)
        : dropdownList.columnsToDisplay);
    return (
      <>
        <div
          key={item.Id && item.Id}
          className={classNames('c-list__item-cont', {
            'item-with-error': this.getItemError(item),
            'disabled-item': item.disabledItem,
            'item-overriden': item.IsOverriden,
            'item-yellow-bg': !item.HasEmptyOrOneYearOld && item.WillExpireSoon,
            'item-red-bg': item.HasEmptyOrOneYearOld || item.IsExpired,
          })}
          onClick={onItemClick && this.toggleShowOptions.bind(this, { item: item.Id, resultClass: null })}
          id={item.Id && item.Id}
        >
          {showCheckboxes && (
            <div onClick={this.itemStopPropagation}>
              <CellCheckbox
                item={item}
                checkRole={checkRole}
                parentId={parentId}
                isOpenDropdown={isOpenDropdown}
                onClickCheckbox={onClickCheckbox}
              />
            </div>
          )}
          {(!!dropdownList
            && typeof items[idItem] !== 'undefined'
            && items[idItem].length
            && (!parentDropDown || !!components.length))
          || (dropdownList
            && parentkey
            && items[parentkey].length
            && typeof items[parentkey][idItem] !== 'undefined'
            && items[parentkey][idItem][dropdownList.deepestProperty].length)
          || (!!dropdownList && !!components.length) ? (
              dropdownListToggler
            ) : dropdownList ? (
              <div className='c-list__item-column tiny-col' />
            ) : null}
          {columnsToDisplay.map((column) => {
            const isCustomCell = !!(config && config[column.Id]);
            const isInvalidDate = isDatesCrossing && column.isDate;
            const removePositionBadge = item && item.TeamPosition && item.TeamPosition.Id === 0;
            return column.isSelected
              || typeof column.isSelected === 'undefined' ? (
                isCustomCell ? (
                  this.renderCustomCell(config[column.Id].cell, column)
                ) : (
                  <div
                    key={column.Id}
                    className={classNames(
                      'c-list__item-column',
                      column.className ? column.className : 'small-col',
                    )}
                  >
                    {shadow && (column.Name === 'Name' || column.Name === 'Employee') ? (
                      <CustomIcon icon='backup' />
                    ) : null}
                    {column.hasBadge && !!item[column.Id] && !removePositionBadge && (
                      <Badge
                        color={getColorFromString(
                          column.isObject ? item[column.Id].Name : item[column.Id],
                        )}
                        item={
                          column.isObject ? item[column.Id].Name : item[column.Id]
                        }
                        onlyFirstLetter={column.withoutTitle}
                      />
                    )}
                    {!column.withoutTitle && <span
                      className={classNames('col-span', {
                        error_color: isInvalidDate,
                      })}
                      title={
                        isInvalidDate
                          ? DATES_CROSSING_MESSAGE
                          : this.renderListCell(column, item, true)
                      }
                    >
                      {this.renderListCell(column, item)}
                    </span>}
                  </div>
                )
              ) : null;
          })}
          {!hideDots && !isEditingMode ? (
            <div
              onClick={this.toggleShowOptions.bind(this, { item: 'options', resultClass: null })}
              className={classNames({
                'list-item--actions': hasEditableFields,
                'c-list__options-column c-list__options-column__svg':
                  !hasEditableFields,
              }, shiftOnEditClassName)}
            >
              <button
                className={classNames('list-item--action', {
                  'c-list__options-column c-list__options-column__svg':
                    hasEditableFields,
                })}
                title='Show more'
                onClick={this.toggleShowOptions.bind(this, { item: 'options', resultClass: resultClass })}
              >
                <CustomIcon iconName='three-dots' />
              </button>
              {showOptions && !isEditingMode ? (
                <div
                  className={classNames(
                    'c-list-item__options-column__container',
                    {
                      [resultClass]: isNeedUpOptions,
                    },
                  )}
                  onMouseLeave={this.hideOptions}
                >
                  {isHaveRights ? (
                    <div>
                      {this.showImport(item, 'PO Extension') && (
                        <div
                          onClick={(e) => {
                            this.itemStopPropagation(e);
                          }}
                          className='c-list-item__options-column__option'
                        >
                          <ImportContract
                            id='po'
                            title='Import PO Extension'
                            onChange={onImportFile}
                          />
                        </div>
                      )}
                      {this.showImport(item, 'PO') && (
                        <div
                          onClick={(e) => {
                            this.itemStopPropagation(e);
                          }}
                          className='c-list-item__options-column__option'
                        >
                          <ImportContract
                            id='po'
                            title='Import PO'
                            onChange={(e) => onImportFile(e, item.Id, 'PO')}
                          />
                        </div>
                      )}
                      {this.showImport(item, 'Amendment') && (
                        <div
                          onClick={(e) => {
                            this.itemStopPropagation(e);
                          }}
                          className='c-list-item__options-column__option'
                        >
                          <ImportContract
                            id='amendment'
                            title='Import Amendment'
                            onChange={(e) => onImportFile(e, item.Id, 'Amendment')}
                          />
                        </div>
                      )}
                      {!hideAddNewEntity
                        && ((item.Amendments && !item.Amendments.length) || needToAddNewEntity) && (
                        <div
                          className='c-list-item__options-column__option'
                          onClick={this.onAddClick({ id: item.Id, parentId })}
                        >
                          <span>{addNewTitle ? addNewTitle : 'Add new'}</span>
                        </div>
                      )}
                      {isSowOrPO && (item.POs && !item.POs.length) && customEntityActionItem}
                      {!hideEntityEdit && (
                        <div
                          className='c-list-item__options-column__option'
                          onClick={this.editItem(
                            item,
                            onEditClick,
                            getFullEditItem,
                            parentDropdownId,
                          )}
                        >
                          <span>{editSpan || 'Edit item'}</span>
                        </div>
                      )}
                      {!hideCopyEntity && (
                        <div
                          className='c-list-item__options-column__option'
                          onClick={this.copyItem(item, onCopyClick)}
                        >
                          <span>Copy item</span>
                        </div>
                      )}
                      {!hideDeleteEntity && (
                        <div
                          className='c-list-item__options-column__option'
                          onClick={this.deleteItem(
                            onDeleteClick,
                            showDeleteSpan,
                            item,
                            deleteConfig,
                            isOnlyActive,
                            parentDropdownId,
                            dontUseDeleteConfig,
                            parentId,
                          )}
                        >
                          <span>{showDeleteSpan || 'Delete item'}</span>
                        </div>
                      )}
                      {!isSowOrPO && customEntityActionItem}
                    </div>
                  ) : onEditClick ? (
                    <div
                      className='c-list-item__options-column__option'
                      onClick={
                        onEditClick
                        && onEditClick.bind(this, item.Id || item.UserId)
                      }
                    >
                      <span>View {entityName}</span>
                    </div>
                  ) : null}
                  {SOWExtensionOptions && (
                    <div>
                      <div
                        className='checkbox_list_container'
                        onClick={this.togglePos}
                      >
                        <input
                          type='checkbox'
                          className='checkbox_extend'
                          defaultChecked={isOpenPOs.Pos}
                        />
                        <label htmlFor='checkbox_extend'>Pos</label>
                      </div>
                      <div
                        className='checkbox_list_container'
                        onClick={this.toggleAmendments}
                      >
                        <input
                          type='checkbox'
                          className='checkbox_extend'
                          defaultChecked={isOpenPOs.Amendments}
                        />
                        <label htmlFor='checkbox_extend'>Amendments</label>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            isEditingMode
            && hasEditableFields && (
              <div className='list-item--actions'>
                <button
                  className='c-list__options-column c-list__options-column__svg list-item--action'
                  title='Save'
                  onClick={this.saveEditedFields}
                >
                  <CustomIcon iconName='save' />
                </button>
                <button
                  className='c-list__options-column c-list__options-column__svg list-item--action'
                  title='Cancel'
                  onClick={this.toggleEditingMode}
                >
                  <CustomIcon iconName='cancel' />
                </button>
              </div>
            )
          )}
        </div>
        {!!dropdownList
          && !!dropdownList.items.length
          && !!isOpenDropdown[idItem] && (
          <List
            onImportFile={(e) => onImportFile(e, item.Id, 'POExtension')}
            columnsToDisplay={innerColumnsToDisplay}
            items={
              dropdownList
                && this.getDropdownItems(
                  items,
                  idItem,
                  dropdownList.withoutSort,
                  dropdownList.deepestProperty,
                )
            }
            isInnerList
            onAddClick={dropdownList && dropdownList.onAddClick}
            onEditClick={dropdownList && dropdownList.onEditClick}
            hasEditableFields={dropdownList && dropdownList.hasEditableFields}
            onDeleteClick={dropdownList && dropdownList.onDeleteClick}
            onItemClick={dropdownList && dropdownList.onItemClick}
            showCheckboxes={dropdownList && dropdownList.showCheckboxes}
            isSowOrPO={dropdownList.isSowOrPO}
            checkRole={checkRole}
            parentId={item.Id || (item.Contract && item.Contract.Id)}
            hideDots={dropdownList.hideDots}
            deleteConfig={dropdownList.deleteConfig}
            hideEntityEdit={dropdownList.hideEntityEdit}
            hideDeleteEntity={dropdownList.hideDeleteEntity}
            parentDropdownId={idItem}
            getFullEditItem={dropdownList.getFullEditItem}
            timeLeft={dropdownList.timeLeft}
            editSpan={dropdownList.editSpan}
            dontShowOptions={dropdownList.dontShowOptions}
            dontShowNoItemBlock
            setSortSettings={this.setSortSettings}
            sortColumnName={column}
            sortDirection={sortDirection}
            transition={transition}
            isHaveRights={dropdownList.isHaveRights}
            config={dropdownList.config || config}
            hideAddNewEntity={dropdownList.hideAddNewEntity}
            viewFinancialInfo={viewFinancialInfo}
            dropdownList={dropdownList.dropdownList}
            parentDropDown={isOpenDropdown.parent}
            dontUseDeleteConfig={dropdownList.dontUseDeleteConfig}
            renderCustomEntity={
              dropdownList && dropdownList.renderCustomEntity
            }
            needToAddNewEntity={
              dropdownList && dropdownList.needToAddNewEntity
            }
            titleForAddingEntity={
              dropdownList && dropdownList.titleForAddingEntity
            }
            onRenderCustomEntityClick={
              dropdownList && dropdownList.onRenderCustomEntityClick
            }
            onClickCheckbox={onClickCheckbox}
            getCustomUrlForDelete={
              dropdownList && dropdownList.getCustomUrlForDelete
            }
            onEditingModeToggle={dropdownList && dropdownList.onEditingModeToggle}
          />
        )}
        {
          highOrderConfig
          && !!components.length
          && isOpenDropdown[idItem]
          && components.map((item) => isOpenPOs[item.key] && item.component)
        }
      </>
    );
  }
}

ListItem.propTypes = {
  item: PropTypes.object,
  dropdownList: PropTypes.object,
  columnsToDisplay: PropTypes.array,
  idItem: PropTypes.number,
  isHaveRights: PropTypes.bool,
  viewFinancialInfo: PropTypes.bool,
  deleteConfig: PropTypes.object,
  editSpan: PropTypes.string,
  titleForAddingEntity: PropTypes.string,
  showDeleteSpan: PropTypes.string,
  isOnlyActive: PropTypes.bool,
  needToAddNewEntity: PropTypes.bool,
  onItemClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onRenderCustomEntityClick: PropTypes.func,
  onEditingModeToggle: PropTypes.func,
};

export default connect((store) => ({
  isOnlyActive: store.showRoles.isOnlyActiveRoles,
}))(ListItem);
