import * as React from "react";
import PropTypes from 'prop-types';
import Scrollbars from "react-custom-scrollbars";
import history from 'utils/history';
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import ProjectEditMSAForm from "../../../../../common/components/redux-form/project-edit-MSA-form/project-edit-MSA-form";
import { applicationTitle } from "../../../../../utils/applicationTitle";
import { sortFilterList } from 'common/components/list/sortList';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { loadManageDocumentTypes } from 'pages/admin/actions/adminManageDocumentTypes';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { getEmployees } from 'pages/projects/actions/roleModalActions';

export class MSAEditPage extends React.Component {
  constructor(props) {
    super(props);
    const { projectDetails, ManageProjectContracts, HasFinancialAccess } = this.props;
    this.state = {
      columnsToDisplay: {
        documentsList: [
          {
            Name: 'Documents Links',
            isSelected: true,
            Id: 'Comment',
            className: 'without-width-col',
            pressArrowLeft: true,
          },
          {
            Name: 'Documents Types',
            isType: true,
            Id: 'DocumentType',
            className: 'without-width-col',
            pressArrowLeft: true,
          }
        ]
      },
      ManageProjectContracts,
      HasFinancialAccess,
    }
    this.Id = history.location.pathname.split('/')[4] || null;
    this.location = history.location.pathname.split('/')[3];
    this.emptyForm = {
      Id: '',
      Contract: this.autoGenerateMSANumber(projectDetails.MSAs),
      Company: '',
      Approver: '',
      Type: {},
      Project: { Id: projectDetails.Id, Name: projectDetails.Name },
      StartDate: null,
      EndDate: null,
      ApprovalStatus: {},
      Comments: '',
      Status: {},
      Links: [],
    };
  }

  componentDidMount() {
    const { projectDetails, location, setComponent, loadManageDocumentTypes } = this.props;
    !projectDetails.Name && history.goBack({state: location.state});
    loadManageDocumentTypes();
    const rightPath = location.state && location.state.isWrongPath ? 'contracts' : 'projects';
    setComponent({
      returnButton: { text: rightPath, goToPreviousPage: this.goToMainList },
      secondReturnButton: { text: `${(projectDetails && projectDetails.Name) ? projectDetails.Name : ''} details`, goToPreviousPage: this.goToProjectDetails },
    });
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  autoGenerateMSANumber = (msas) => {
    const num = msas ? msas.filter(item => item.Type && item.Type.Name && item.Type.Name === 'MSA').length + 1 : 1;
    return 'MSA-' + num;
  }

  goToMainList = () => {
    const { location } = this.props;
    const rightPath = location.state && location.state.isWrongPath ? 'contracts' : 'projects';
    history.push({ pathname: `/${rightPath}`, state: location.state });
  }

  goToProjectDetails = () => {
    const { projectDetails, location, cancelImport } = this.props;
    const MSADetails = projectDetails.MSAs.find(msa => msa.Id === this.MSAId);

    MSADetails && MSADetails.Project && cancelImport(MSADetails.Project.Id);
    history.push({ pathname: `/projects/${projectDetails.Id}`, state: location.state });
  }
  
  render() {
    const { columnsToDisplay, HasFinancialAccess, ManageProjectContracts } = this.state;
    const {
      projectDetails,
      approvalStatuses,
      clients,
      clientContacts,
      documentStatuses,
      location,
      documentTypes,
    } = this.props;
    const MSADetails = projectDetails && projectDetails.MSAs.find(contract => contract.Id === this.Id);
    let formTitle;
    switch (this.location) {
      case 'add-msa':
        formTitle = 'Add MSA';
        break;
      case 'edit-msa':
      case 'view-msa':
        formTitle = 'Edit MSA';
        break;
      default: break;
    }
    return (
      <div className='page-container'>
        <DocumentTitle title={applicationTitle.getTitile(this.location === 'edit-msa'
          ? 'editMSA' : this.location === 'add-msa' ? 'addMSA' : 'viewMSA')}
        />
        <div className='form-header'>
          <span>{formTitle}</span>
          <span className='highlighted-text'>{MSADetails && MSADetails.Contract}</span>
        </div>
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          <ProjectEditMSAForm
            data={MSADetails ? {
              ...MSADetails,
              Company:
                MSADetails && MSADetails.Company && MSADetails.Company.Id,
              Signer:
                MSADetails && MSADetails.Approver && MSADetails.Approver.Id,
            }
              : {
                ...this.emptyForm
              }}
            MSADetails={MSADetails}
            disabled={!ManageProjectContracts}
            allApprovalStatuses={sortFilterList(approvalStatuses)}
            allClients={sortFilterList(clients)}
            allSigners={sortFilterList(clientContacts)}
            allStatuses={documentStatuses}
            allDocumentTypes={documentTypes}
            columnsToDisplay={columnsToDisplay}
            viewFinancialInfo={HasFinancialAccess}
            projectType={projectDetails && projectDetails.Billability}
            location={location}
            projectDetails={projectDetails}
            handleSubmit={formHandleSubmit}
            projectId={projectDetails && projectDetails.Id}
          />
        </Scrollbars>
      </div>
    );
  }
}

MSAEditPage.propTypes = {
  projectDetails: PropTypes.object,
  approvalStatuses: PropTypes.array,
  clients: PropTypes.array,
  clientContacts: PropTypes.array,
  documentStatuses: PropTypes.array,
  documentTypes: PropTypes.array,
  loadManageDocumentTypes: PropTypes.func,
  ManageProjectContracts: PropTypes.bool.isRequired,
  HasFinancialAccess: PropTypes.bool.isRequired,
};

export default connect((store) => ({
  projectDetails: store.projectsReducer.projectDetails,
  approvalStatuses: store.projectsReducer.approvalStatuses,
  clients: store.filtersReducer.filters.Clients,
  clientContacts: store.projectsReducer.clientContacts,
  documentStatuses: store.filtersReducer.filters.documentStatuses,
  isOnlyActive: store.showRoles.isOnlyActiveRoles,
  UserOfficeId: store.authReducer.user.UserOfficeId,
  Offices: store.projectsReducer.projectDetails.Offices,
  documentTypes: store.adminManageLookups.documentTypes,
  EmployeeId: store.authReducer.user.EmployeeId,
  resultAlert: store.dialogsReducer.alertDialogReducer.resultAlert,
}), {
  loadManageDocumentTypes,
  setComponent,
  resetComponent,
  getEmployees,
})(MSAEditPage);