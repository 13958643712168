import {
  ACTIVE,
  APPROVE,
  DRAFT,
  EXTENDED,
  FINISHED,
  NOT_APPROVE,
} from '../../../../utils/const-variable';

const findMatchesOnStatuses = (value, approvalStatuses) => {
  return approvalStatuses.find((item) => {
    return item.Name === value;
  }).Id;
};

const getApproveStatus = (id, status, approvalStatuses) => ({
  docStatus: id,
  approveStatus: findMatchesOnStatuses(status, approvalStatuses),
});

export const getStatusesForRequiredComment = (documentStatuses, approvalStatuses) => {
  const arrayWithMatches = [];
  documentStatuses.filter((item) => {
    switch (item.Name) {
      case DRAFT:
        return arrayWithMatches.push(getApproveStatus(item.Id, APPROVE, approvalStatuses));
      case ACTIVE:
        return arrayWithMatches.push(getApproveStatus(item.Id, NOT_APPROVE, approvalStatuses));
      case EXTENDED:
        return arrayWithMatches.push(getApproveStatus(item.Id, APPROVE, approvalStatuses));
      case FINISHED:
        return arrayWithMatches.push(getApproveStatus(item.Id, APPROVE, approvalStatuses));
      default:
        return null;
    }
  });
  return arrayWithMatches;
};
