import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import {
  downloadManageLocations,
  putManageLocations,
  postManageLocations,
} from '../../../actions/adminManageLocations';
import classNames from 'classnames';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { showDialog, showAlertDialog } from 'common/actions/confirmationDialogActions';
import { List } from 'common/components/list/list';
import { CellTextField } from 'common/components/list/custom-cell/cell-toogle-textfield';
import { CellMultiSelect } from 'common/components/list/custom-cell/cell-toogle-multi-select';
import TextField from 'common/components/form-items/input/text-field';
import MultiSelect from 'common/components/form-items/multi-select/multi-select';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';
import { MESSAGE_FOR_DUPLICATED_LOCATION_IN_OFFICE } from 'utils/const-variable';

export class ManageLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Locations', isSelected: true, Id: 'Name', className: 'biggest-col',
        },
        {
          Name: 'Offices', isSelected: true, Id: 'Offices', className: 'without-width-col multi-select__list-cell', deleteArrow: 'delete-arrow',
        },
      ],
      showAddLocationsFields: false,
      addLocationName: '',
      addLocationOffices: [],
      blockAddLocation: false,
      blockSaveLocation: false,
    };
  }

  componentDidMount() {
    const { downloadManageLocations } = this.props;
    downloadManageLocations();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  addNewLocation = () => {
    const { blockAddLocation } = this.state;
    if (!blockAddLocation) {
      this.setState({
        showAddLocationsFields: true,
        addLocation: { Name: '' },
        blockAddLocation: true,
      });
    }
  }

  setNewLocationName = ({ target }) => {
    this.setState({
      addLocationName: target.value,
    }, this.newLocationValidation);
  }

  setNewLocationOffices = (data) => {
    const { addLocationOffices } = this.state;
    const newValue = addLocationOffices.some(id => id === data) ? addLocationOffices.filter(id => id !== data) : addLocationOffices.concat([data]);
    this.setState({
      addLocationOffices: newValue,
    }, this.newLocationValidation);
  }

  newLocationValidation = () => {
    const { addLocationName, addLocationOffices } = this.state;
    this.setState({
      blockSaveLocation: !!(addLocationName.trim() && addLocationOffices.length),
    });
  };

  newLocationCancel = () => {
    this.setState({
      addLocationName: '',
      addLocationOffices: [],
      showAddLocationsFields: false,
      blockSaveLocation: false,
      blockAddLocation: false,
    });
  }

  newLocationApply = () => {
    const { postManageLocations, locations, showAlertDialog } = this.props;
    const { blockSaveLocation, addLocationName, addLocationOffices } = this.state;
    if (locations.find(location => location.Name.toLowerCase() === addLocationName.toLowerCase() && location.Offices.some(office => addLocationOffices.indexOf(office) >= 0))) {
      showAlertDialog(MESSAGE_FOR_DUPLICATED_LOCATION_IN_OFFICE);
      this.setState({
        addLocationName: '',
        blockSaveLocation: false,
      });
      return;
    }

    if (blockSaveLocation) {
      const addLocation = {
        Name: addLocationName,
        Offices: addLocationOffices,
      };
      postManageLocations(addLocation);
      this.setState({
        addLocationName: '',
        addLocationOffices: [],
        showAddLocationsFields: false,
        blockSaveLocation: false,
        blockAddLocation: false,
      });
    }
  }

  getElementForDelete = (id) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `locations/${id}`,
      searchUpdateSaga: 'locations',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageLocations } = this.props;
    putManageLocations(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  render() {
    const { locations, offices, listSettings } = this.props;
    const {
      columnsToDisplay, showAddLocationsFields, addLocationName, addLocationOffices, blockSaveLocation,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsLocations')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewLocation}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search location'
          searchValue={searchValue}
        />
        {showAddLocationsFields ? <div className='c-list__item-cont add-calendar--block'>
          <div className='c-list__item-column c-skill__item biggest-col'>
            <TextField
              formClassName='change-border__input-cell'
              inputClassName='change-name__input'
              autoFocus
              value={addLocationName}
              name='Name'
              onChange={this.setNewLocationName}
              onBlur={this.onBlure}
              maxlength='4000'
            />
          </div>
          <div className='c-list__item-column c-skill__item without-width-col multi-select__list-cell'>
            <div className='field__multi-select-cell'>
              <MultiSelect
                relcontClass='field__multi-select-rel-cont'
                contClass='field__multi-select-cont'
                optionContClass='field__multi-select-option-cont'
                selected={addLocationOffices}
                onChange={this.setNewLocationOffices}
                options={offices}
                placeholder='Select offices'
              />
            </div>
          </div>
          <div className='add-calendar--button-block'>
            <div className='toolbar-button resume-buttom-cancel add-locations__cancel-button' onClick={this.newLocationCancel}>
              <span>Cancel</span>
            </div>
            <div
              className={classNames(
                'button next resume-buttom-apply',
                { 'btn-disabled': !blockSaveLocation },
              )}
              onClick={this.newLocationApply}
            >
              <span>Apply</span>
            </div>
          </div>
        </div> : null}
        <List
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(locations, sortColumn, sortDirection), searchValue)}
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
          isHaveRights
          hideAddNewEntity
          hideEntityEdit
          dontUseDeleteConfig
          onDeleteClick={this.getElementForDelete}
          config={{
            'Name': {
              cell: CellTextField,
              valueSetter: this.editEntity,
            },
            'Offices': {
              cell: CellMultiSelect,
              valueSetter: this.editEntity,
              options: offices,
            },
          }}
        />
      </div>
    );
  }
}

ManageLocations.propTypes = {
  locations: PropTypes.array.isRequired,
  putManageLocations: PropTypes.func.isRequired,
  postManageLocations: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
  downloadManageLocations: PropTypes.func.isRequired,
};

export default connect((store) => ({
  locations: store.adminManageLookups.locations,
  offices: store.projectsReducer.offices,
  listSettings: store.adminManageLookups.listSettings,
}), {
  downloadManageLocations,
  putManageLocations,
  postManageLocations,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
  showAlertDialog,
})(ManageLocations);
