export const GET_MANAGE_ENGAGEMENT_TYPES = '@admin/GET_MANAGE_ENGAGEMENT_TYPES';
export const SET_MANAGE_ENGAGEMENT_TYPES = '@admin/SET_MANAGE_ENGAGEMENT_TYPES';
export const PUT_MANAGE_ENGAGEMENT_TYPES = '@admin/PUT_MANAGE_ENGAGEMENT_TYPES';
export const POST_MANAGE_ENGAGEMENT_TYPES = '@admin/POST_MANAGE_ENGAGEMENT_TYPES';

export const downloadManageEngagementTypes = () => ({
  type: GET_MANAGE_ENGAGEMENT_TYPES,
});

export const setManageEngagementTypes = (types) => ({
  type: SET_MANAGE_ENGAGEMENT_TYPES,
  payload: types,
});

export const putManageEngagementTypes = (types) => ({
  type: PUT_MANAGE_ENGAGEMENT_TYPES,
  payload: types,
});

export const postManageEngagementTypes = (newType) => ({
  type: POST_MANAGE_ENGAGEMENT_TYPES,
  payload: newType,
});
