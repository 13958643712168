import React, { Component } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';

export class CellBillingRoleCheckbox extends Component {
  render() {
    const {
      item,
    } = this.props;
    return (
      <div className='c-list__item-column billing-role-cell'>
        {item.Actual === '0'
          ? <HighlightOffIcon
            style={{ width: '25px', color: 'red' }}
          />
          : <CheckCircleOutlineIcon
            style={{ width: '25px', color: 'green' }}
          />}
      </div>
    );
  }
}

CellBillingRoleCheckbox.propTypes = {
  item: PropTypes.object.isRequired,
};
