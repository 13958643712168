import React, { Fragment } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// Styles
import './modal.css';

const Modal = ({ children, onClick }) => ReactDOM.createPortal(
  <Fragment>
    <div className="c-modal-background" onClick={onClick}>
    </div>
    {children}
  </Fragment>,
  document.getElementById('modal')
);


Modal.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Modal;
