import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sortFilterList } from 'common/components/list/sortList';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import {
  getExpiredContracts,
  handleEditViewContract,
  importFile,
  toggleFlagShow,
  setImportFile,
  downloadFile,
  resetContracts,
  setListSettings,
} from '../../actions/contractsActions';
import { showDialog } from 'common/actions/confirmationDialogActions';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { scrollToLastElement } from 'services/scrollToLastElement.js';
import Scrollbars from 'react-custom-scrollbars';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from 'services/debounce';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellTimeleft } from 'common/components/list/custom-cell/cell-timeleft';
import { CellBudget } from 'common/components/list/custom-cell/cell-budget';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import isObjectNotEquals from 'utils/object-comparison';
import { setComponent, resetComponent } from 'common/actions/headerActions';

const initialTakeAmount = 50;
const initialPage = 1;
const magnificationFactor = 10;

export class ContractsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      takeAmount: initialTakeAmount,
      columnsToDisplay: [
        {
          Name: 'Contract ID', Id: 'Contract', className: 'big-col',
        },
        {
          Name: 'Type', Id: 'ContractType', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Company', Id: 'Client', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Project', Id: 'Project', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Committed Quota Goal', Id: 'Budget', className: 'medium-col', isBudget: true,
        },
        {
          Name: 'Start Date', Id: 'StartDate', className: 'medium-col', isDate: true,
        },
        {
          Name: 'End Date', Id: 'EndDate', className: 'medium-col',
        },
        {
          Name: 'Approval Status', Id: 'ApprovalStatus', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Status', Id: 'Status', className: 'small-col', isObject: true, WithOpenRole: true,
        },
      ],
      isDownloadedImportFile: false,
    };
  }

  componentDidMount() {
    const {
      listSettings, totalCount, setComponent, getExpiredContracts, location, setListSettings,
    } = this.props;

    const { takeAmount } = this.state;

    const {
      searchValue, filters, sortColumn, sortDirection, scrollTo,
    } = listSettings;
    const locationSearchValue = location.state && location.state.searchValue;
    locationSearchValue && setListSettings({ searchValue: locationSearchValue });

    if (scrollTo !== '') {
      scrollToLastElement('contracts', scrollTo, true);
    }

    !locationSearchValue && getExpiredContracts(filters, false, 0, takeAmount, searchValue, { sortColumn, sortDirection });
    setComponent({ title: `Contracts (${totalCount})` });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      listSettings, getExpiredContracts, setComponent, totalCount, contracts, resetContracts,
    } = this.props;

    const { takeAmount } = this.state;

    const {
      searchValue, filters, sortColumn, sortDirection,
    } = listSettings;

    if (takeAmount !== initialTakeAmount && takeAmount !== prevState.takeAmount) {
      getExpiredContracts(filters, true, contracts.length, takeAmount - contracts.length, searchValue, { sortColumn, sortDirection });
    }

    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      resetContracts();

      this.setState({
        takeAmount: initialTakeAmount,
      });

      this.scroll.pageLoaded = initialPage;

      getExpiredContracts(filters, true, 0, initialTakeAmount, searchValue, { sortColumn, sortDirection });
    }

    if (prevProps.totalCount !== totalCount) {
      setComponent({ title: `Contracts (${totalCount})` });
    }
  }

  componentWillUnmount() {
    const { resetComponent, resetContracts } = this.props;
    resetComponent();
    resetContracts();
  }

  setSearchValue = (searchValue) => {
    const { setListSettings } = this.props;
    setListSettings({ searchValue });
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettings } = this.props;
    setListSettings({ sortColumn, sortDirection });
  };

  handleEditViewContract = (id) => {
    const {
      contracts, defaultCheckedShowRoles, handleEditViewContract, ManageProjectContracts, listSettings,
    } = this.props;
    const choosenItem = contracts.find(item => item.Id === id);
    const currentDocument = choosenItem.SowPo === 'SOW' ? 'sow' : choosenItem.SowPo === 'Amendment' ? 'amendment' : choosenItem.SowPo === 'PO' ? 'po' : 'msa';
    const rights = ManageProjectContracts ? 'edit' : 'view';
    handleEditViewContract(choosenItem.Project.Id, !defaultCheckedShowRoles, currentDocument, id, rights, listSettings.searchValue);
  }

  deleteContract = (contract) => {
    const { showDialog } = this.props;
    const currentDocument = contract.item.SowPo === 'SOW' ? 'sow' : 'po';
    let url;
    switch (contract.item.ContractType.Name) {
      case 'SOW':
        url = `projects/${contract.item.Project.Id}/sows/${contract.item.Id}`;
        break;
      case 'PO Original':
      case 'PO Extension':
        url = `projects/${contract.item.Project.Id}/sows/${contract.item.ParentId}/pos/${contract.item.Id}`;
        break;
      case 'Amendment':
        url = `projects/${contract.item.Project.Id}/sows/${contract.item.ParentId}/amendments/${contract.item.Id}`;
        break;
      case 'MSA':
        url = `projects/${contract.item.Project.Id}/msas/${contract.item.Id}`;
        break;
      default: break;
    }
    const dataForDeleting = {
      url: url,
      searchUpdateSaga: `contracts/${currentDocument}`,
    };
    showDialog(dataForDeleting);
  }

  applyFilters = (filters) => {
    const { setListSettings } = this.props;
    setListSettings({
      filters,
    });
  }

  resetFilters = () => {
    const { setListSettings } = this.props;
    setListSettings({ filters: {} });
  }

  changeAmountContracts = (page) => {
    const { isLoading } = this.props;
    const { takeAmount } = this.state;

    const increaseParams = takeAmount + page * magnificationFactor;
    if (!isLoading) {
      this.setState({
        takeAmount: increaseParams,
      });
    }
  }

  onImportFile = (file) => {
    const { importFile } = this.props;
    const formData = new FormData();
    formData.set('ImportFile', file);
    importFile(formData);
  }

  closeConfirmationDialog = () => {
    const { toggleFlagShow, setImportFile } = this.props;
    setImportFile(null);
    toggleFlagShow(false);
  }

  onConfirmDialog = () => {
    const { toggleFlagShow, downloadFile, downloadedFile } = this.props;
    toggleFlagShow(false);
    downloadFile(downloadedFile);
  }

  getTextForConfirmDialog = ({ successCount, errorCount }) => {
    return `Rows imported successfully: ${successCount}\nRows which were not imported: ${errorCount}\nDownload file with import status details?`;
  }

  render() {
    const { columnsToDisplay } = this.state;
    const {
      contracts, settings, alerts, status, approvalStatus, statusForDownloadedFile,
      ManageProjectContracts, HasFinancialAccess, showConfirmForDownload, listSettings, totalCount, contractTypes,
    } = this.props;
    const {
      searchValue, sortColumn, sortDirection, filters,
    } = listSettings;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('contracts')} />
        {
          showConfirmForDownload ? (
            <ConfirmationDialog
              dialogHeader='DOWNLOAD FILE'
              dialogTitle={this.getTextForConfirmDialog(statusForDownloadedFile)}
              closeButtonTitle='Cancel'
              confirmButtonTitle='OK'
              closeDialog={this.closeConfirmationDialog}
              confirmDialog={this.onConfirmDialog}
              isNeedWhiteSpace
            />
          ) : null
        }
        <ListToolbar
          searchPlaceholder='Search Contracts'
          items={[{
            name: 'Has Alerts',
            id: 'Alerts',
            options: sortFilterList(alerts) || [],
            multiSelect: true,
            placeholder: 'All Contracts',
            filtersItem: filters && filters.Alerts,
          },
          {
            name: 'Status',
            id: 'DocumentStatuses',
            options: sortFilterList(status) || [],
            multiSelect: true,
            placeholder: 'All Contracts',
            filtersItem: filters && filters.DocumentStatuses,
          },
          {
            name: 'Approval Status',
            id: 'ApprovalStatuses',
            options: sortFilterList(approvalStatus) || [],
            multiSelect: true,
            placeholder: 'All Contracts',
            filtersItem: filters && filters.ApprovalStatuses,
          },
          {
            name: 'Type',
            id: 'ContractTypes',
            options: sortFilterList(contractTypes) || [],
            multiSelect: true,
            placeholder: 'All contracts',
            filtersItem: filters && filters.ContractTypes,
          }]}
          searchValue={searchValue}
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          showButtonOptions
          onSearchClick={this.setSearchValue}
          onImportFile={this.onImportFile}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll' id='contracts'>
            <InfiniteScroll
              pageStart={initialPage}
              loadMore={debounce(this.changeAmountContracts, 500)}
              hasMore={contracts.length < totalCount}
              useWindow={false}
              initialLoad={false}
              ref={(scroll) => this.scroll = scroll}
            >
              <List
                columnsToDisplay={columnsToDisplay}
                items={contracts}
                onItemClick={this.handleEditViewContract}
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                onEditClick={this.handleEditViewContract}
                onDeleteClick={this.deleteContract}
                timeLeft={settings.ContractFirstNotification}
                isHaveRights={ManageProjectContracts}
                fixedHeader
                viewFinancialInfo={HasFinancialAccess}
                hideAddNewEntity
                config={{
                  'Status': { cell: CustomStatusCell },
                  'EndDate': {
                    cell: CellTimeleft,
                    daysToExpire: settings.ContractFirstNotification,
                  },
                  'Budget': { cell: CellBudget },
                }}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

ContractsList.propTypes = {
  getExpiredContracts: PropTypes.func.isRequired,
  contracts: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  handleEditViewContract: PropTypes.func.isRequired,
  defaultCheckedShowRoles: PropTypes.bool.isRequired,
  alerts: PropTypes.array.isRequired,
  ManageProjectContracts: PropTypes.bool.isRequired,
  HasFinancialAccess: PropTypes.bool.isRequired,
  setListSettings: PropTypes.func,
  listSettings: PropTypes.object,
};

export default connect((store) => ({
  contracts: store.contractsReducer.contracts,
  totalCount: store.contractsReducer.totalCount,
  settings: store.adminReducer.settings,
  defaultCheckedShowRoles: store.showRoles.isOnlyActiveRoles,
  alerts: store.contractsReducer.alerts,
  status: store.filtersReducer.filters.documentStatuses,
  dontUploadFilters: store.contractsReducer.dontUploadFilters,
  approvalStatus: store.contractsReducer.approvalStatus,
  contractTypes: store.contractsReducer.contractTypes,
  showConfirmForDownload: store.contractsReducer.showConfirmForDownload,
  downloadedFile: store.contractsReducer.downloadedFile,
  statusForDownloadedFile: store.contractsReducer.statusForDownloadedFile,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.contractsReducer.listSettings,
}), {
  getExpiredContracts,
  handleEditViewContract,
  showDialog,
  setComponent,
  resetComponent,
  importFile,
  toggleFlagShow,
  setImportFile,
  downloadFile,
  resetContracts,
  setListSettings,
})(ContractsList);
