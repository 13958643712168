import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortList } from 'common/components/list/sortList';
import { List } from 'common/components/list/list';
import Button from 'common/components/form-items/button/button';
import { showModal, getEmployees } from '../../actions/roleModalActions';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import isEmptyObject from 'utils/isEmptyObject';
import { PROJECT_ROLES_EMPLOYEES_PRIMARY } from 'utils/const-variable';
import EditAssignmentModal from '../role-modal/edit-assignment-modal';
import { sortRoleEmployeesCreateProject } from 'utils/sortRoleEmployees';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellRate } from 'common/components/list/custom-cell/cell-rate';
import isObjectNotEquals from 'utils/object-comparison';
import checkIsDatesCrossing from 'utils/checkIsDatesCrossing';
import {
  PROJECT_ROLES_STATUS_REMOVED,
  EMPLOYEES_STATUS_ADMIN_ID,
  EMPLOYEES_STATUS_CONTRACTOR_ID,
  EMPLOYEES_STATUS_EMPLOYEE_ID,
  EMPLOYEES_STATUS_TRAINEE_ID,
  EMPLOYEES_STATUS_CANDIDATE_ID,
} from 'utils/const-variable';

export class Step extends React.Component {
  constructor(props) {
    super(props);
    const { initialState } = this.props;
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Role Name', isSelected: true, Id: 'RoleName', className: 'without-width-col',
        },
        {
          Name: 'Role Type', isSelected: true, Id: 'RoleType', className: 'medium-col',
        },
        {
          Name: 'Seniority Level', isSelected: true, Id: 'SeniorityLevel', className: 'medium-col',
        },
        {
          Name: 'Assigment', isSelected: true, Id: 'Assignment', className: 'medium-col',
        },
        {
          Name: 'Ass. Type', isSelected: true, Id: 'AssignmentType', className: 'medium-col',
        },
        {
          Name: 'Rate', isSelected: true, Id: 'Rate', className: 'small-col color-black-role c-list__status',
        },
        {
          Name: 'Billability', isSelected: true, Id: 'BillableStatus', className: 'medium-col',
        },
        {
          Name: 'Start Date', isSelected: true, Id: 'StartDate', className: 'medium-col', isDate: true,
        },
        {
          Name: 'End Date', isSelected: true, Id: 'EndDate', className: 'medium-col', isDate: true,
        },
        {
          Name: 'SOW/PO Number', isSelected: true, Id: 'BillingCode', className: 'medium-col',
        },
        {
          Name: 'Status', isSelected: true, Id: 'Status', className: 'small-col color-black-role c-list__status',
        },
        {
          Name: 'Comment', isSelected: true, Id: 'Comment', className: 'small-col', isInnerComment: true,
        },
      ],
      columnsToDisplayDropdownList: [
        {
          Name: 'Employee',
          isSelected: true,
          Id: 'Name',
          className: 'without-width-col',
          badge: 'c-list__bg-bl',
          hasBadge: true,
          deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Assignment', isSelected: true, Id: 'Assignment', className: 'small-col', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Start Date', isSelected: true, Id: 'StartDate', className: 'small-col', isDate: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'End Date', isSelected: true, Id: 'EndDate', className: 'small-col', isDate: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Status', isSelected: true, Id: 'Status', className: 'small-col color-black-role c-list__status', deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Comment', Id: 'Comment', isInnerComment: true, deleteArrow: 'delete-arrow',
        },
      ],
      roles: initialState.roles,
      isRolesValid: true,
      openDeleteDialog: false,
      deleteRole: {},
      openAlertDialog: false,
      deleteRoleAssignment: {},
      parentDeleteRoleAssignmentId: '',
      showEditAssignmentModal: false,
      editAssignment: {},
      parentRoleEditAssignmentId: '',
      column: 'Name',
      sortDirection: 'Up',
    };
  }

  componentDidMount() {
    const { initialState } = this.props;
    this.setState({
      roles: initialState.roles,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialState } = this.props;
    const { roles } = this.state;
    if (isObjectNotEquals(prevProps.initialState.roles, initialState.roles)) {
      this.setState({
        roles: initialState.roles,
      });
    }
    if (isObjectNotEquals(prevState.roles, roles)) {
      this.sortRoleEmployee();
    }
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { roles } = this.state;
    onSubmit(roles);
  }

  onBack = () => {
    const { onBack } = this.props;
    onBack();
  }

  showRoleModal = () => {
    const {
      initialState, showRoleModal, getEmployees, onStepChange,
    } = this.props;
    const {
      PONumber, SOWNumber, offices, startDateSOW, endDateSOW, startDatePO, endDatePO,
    } = initialState;
    const billingData = {
      billingCodes: PONumber !== '' ? [SOWNumber, PONumber] : [SOWNumber],
      billingCodesStartDate: startDatePO ? [startDateSOW, startDatePO] : [startDateSOW],
      billingCodesEndDate: endDatePO ? [endDateSOW, endDatePO] : [endDateSOW],
    };
    const statuses = [
      EMPLOYEES_STATUS_ADMIN_ID,
      EMPLOYEES_STATUS_CONTRACTOR_ID,
      EMPLOYEES_STATUS_EMPLOYEE_ID,
      EMPLOYEES_STATUS_TRAINEE_ID,
      EMPLOYEES_STATUS_CANDIDATE_ID
    ];
    showRoleModal(
      [],
      billingData,
      'ADD_ROLE_TO_NEW_PROJECT',
      null,
    );
    getEmployees(offices, false, statuses);
    onStepChange({
      ...this.state,
      step: 3,
    });
  }

  editRolePage = (item) => {
    const {
      initialState, showRoleModal, onStepChange,
    } = this.props;
    const {
      PONumber, SOWNumber, startDateSOW, endDateSOW, startDatePO, endDatePO,
    } = initialState;
    const billingData = {
      billingCodes: PONumber !== '' ? [SOWNumber, PONumber] : [SOWNumber],
      billingCodesStartDate: startDatePO ? [startDateSOW, startDatePO] : [startDateSOW],
      billingCodesEndDate: endDatePO ? [endDateSOW, endDatePO] : [endDateSOW],
    };
    showRoleModal(
      [],
      billingData,
      '',
      null,
    );
    onStepChange({
      ...this.state,
      step: 4,
      editRoles: item,
    });
  }

  compareRoleById = (thisObj, nextObj) => {
    return thisObj.ItemId - nextObj.ItemId;
  }

  // delete Resources block
  setContactDeleteItem = (data) => {
    if (typeof data.parentDropdownId === 'undefined') {
      this.setState({
        deleteRole: data.item,
      }, this.openDialog);
      return;
    }
    this.setState({
      deleteRoleAssignment: data.item,
      parentDeleteRoleAssignmentId: data.parentDropdownId,
    }, this.openDialog);
  }

  openDialog = () => {
    this.setState({
      openDeleteDialog: true,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      deleteRole: {},
      deleteRoleAssignment: {},
      parentDeleteRoleAssignmentId: '',
    });
  }

  deleteRole = () => {
    const { deleteRole, roles } = this.state;
    if (deleteRole.Employees.length) {
      return this.openAlertDialog();
    }
    this.setState({
      roles: roles.filter(item => item.ItemId !== deleteRole.ItemId),
    }, () => this.closeDeleteDialog());
  }

  deleteEmployees = () => {
    const { roles, parentDeleteRoleAssignmentId, deleteRoleAssignment } = this.state;
    const changeableEmployees = roles.find(item => item.ItemId === parentDeleteRoleAssignmentId);
    const deleteEmployee = changeableEmployees.Employees.find(item => item.Id === deleteRoleAssignment.Id);
    if (this.isRelationExists(deleteEmployee, changeableEmployees.Employees)) {
      return this.openAlertDialog();
    }
    const newEmployees = changeableEmployees.Employees.filter(item => item.Id !== deleteRoleAssignment.Id);
    const newRoles = roles.filter(item => {
      if (item.ItemId === parentDeleteRoleAssignmentId) {
        item.Employees = newEmployees;
      }
      return item;
    });
    this.setState({
      roles: newRoles,
    }, () => this.closeDeleteDialog());
  }

  isRelationExists = (deleteEmployee, employees) => {
    if (deleteEmployee.Role === PROJECT_ROLES_EMPLOYEES_PRIMARY) {
      const checkEmployees = employees.find((item) => ((item.PrimaryAssignmentId === deleteEmployee.Id) && true));
      return !!checkEmployees;
    }
    return false;
  };

  confirmDeleteDialog = () => {
    const { deleteRole } = this.state;
    !isEmptyObject(deleteRole) ? this.deleteRole() : this.deleteEmployees();
  }

  // Alert dialog block
  openAlertDialog = () => {
    this.setState({
      openAlertDialog: true,
      openDeleteDialog: false,
      deleteRole: {},
      deleteRoleAssignment: {},
      parentDeleteRoleAssignmentId: '',
    });
  }

  closeAlertDialog = () => {
    this.setState({
      openAlertDialog: false,
    });
  }

  // edit Employees block
  setEditEmployee = (employee) => {
    this.setState({
      editAssignment: employee.item,
      parentRoleEditAssignmentId: employee.parentDropdownId,
    }, () => {
      this.setState({
        showEditAssignmentModal: true,
      });
    });
  }

  closeAssignmentModal = () => {
    this.setState({
      showEditAssignmentModal: false,
    });
  }

  applyAssignmentModal = (obj) => {
    const { parentRoleEditAssignmentId, roles } = this.state;
    const changeableEmployees = roles.find(item => item.ItemId === parentRoleEditAssignmentId);
    const newEmployees = changeableEmployees.Employees.filter(item => item.AssignmentKey !== obj.AssignmentKey);
    newEmployees.push(obj);
    const newRoles = roles.map(item => ({
      ...item,
      Employees: item.ItemId === parentRoleEditAssignmentId ? sortRoleEmployeesCreateProject(newEmployees) : item.Employees,
    }));
    this.setState({
      roles: newRoles,
    }, () => this.closeAssignmentModal());
  }

  sortRoleEmployee = () => {
    this.setState(({ roles }) => ({
      roles: roles.map(item => ({
        ...item,
        Employees: sortRoleEmployeesCreateProject(item.Employees),
      })),
    }), this.validateRoles());
  }

  setSortSettings = (column, sortDirection) => {
    this.setState({
      column,
      sortDirection,
    });
  };

  validateRoles = () => {
    const { roles } = this.state;
    let isRolesValid = true;
    const validatedRoles = roles.map(role => {
      let isRoleValid = true;
      const validatedEmployees = role.Employees.map((employee, index, employees) => {
        const sameEmployees = employees.filter(sameCandidate => (
          sameCandidate.Id === employee.Id && sameCandidate.AssignmentKey !== employee.AssignmentKey
        ));
        const isDatesCrossing = sameEmployees.some(sameEmployee => checkIsDatesCrossing(employee, sameEmployee));
        const isStatusesCrossing = sameEmployees.some(sameEmployee => (
          sameEmployee.Status !== PROJECT_ROLES_STATUS_REMOVED && employee.Status !== PROJECT_ROLES_STATUS_REMOVED
        ));
        if (isDatesCrossing || isStatusesCrossing) {
          isRoleValid = false;
          isRolesValid = false;
        }
        return {
          ...employee,
          isDatesCrossing,
          isStatusesCrossing,
        };
      });
      return {
        ...role,
        Employees: validatedEmployees,
        isRoleValid,
      };
    });

    this.setState({
      isRolesValid,
      roles: validatedRoles,
    });
  }

  // Render
  render() {
    const {
      columnsToDisplay,
      roles,
      columnsToDisplayDropdownList,
      editAssignment,
      showEditAssignmentModal,
      openDeleteDialog,
      deleteRole,
      openAlertDialog,
      column,
      sortDirection,
      isRolesValid,
    } = this.state;
    const {
      rateUnits,
      currencyProject,
    } = this.props;
    const rolesForList = [];
    roles.forEach((role, roleIndex) => {
      rolesForList[roleIndex] = {};
      for (const key in role) {
        switch (key) {
          case 'OvertimeRate':
            rolesForList[roleIndex].RateOvertime = role[key];
            break;
          case 'rateUnit':
            rolesForList[roleIndex].RateUnit = rateUnits[role[key] - 1];
            break;
          case 'overtimeRateUnit':
            rolesForList[roleIndex].OvertimeRateUnit = rateUnits[role[key] - 1];
            break;
          default:
            rolesForList[roleIndex][key] = role[key];
            break;
        }
      }
    });
    return (
      <React.Fragment>
        <div className='toolbar'>
          <div className='right-toolbar-part step-3'>
            <div className='toolbar-button' onClick={this.showRoleModal}>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='#F26939' />
              </svg>
              <span>Add New</span>
            </div>
          </div>
        </div>
        <div className='step'>
          <List
            columnsToDisplay={columnsToDisplay}
            items={sortList(rolesForList.sort(this.compareRoleById), column, sortDirection)}
            dropdownList={{
              columnsToDisplay: columnsToDisplayDropdownList,
              items: rolesForList,
              property: 'Employees',
              onEditClick: this.setEditEmployee,
              onDeleteClick: this.setContactDeleteItem,
              getDeeper: true,
              getFullEditItem: true,
              withoutSort: true,
              hideAddNewEntity: true,
              isHaveRights: true,
              config: {
                'Status': {
                  cell: CustomStatusCell,
                },
              },
            }}
            onAddClick={this.showRoleModal}
            onEditClick={this.editRolePage}
            onDeleteClick={this.setContactDeleteItem}
            sortColumnName={column}
            sortDirection={sortDirection}
            setSortSettings={this.setSortSettings}
            viewFinancialInfo
            hideAddNewEntity
            isHaveRights
            config={{
              'Rate': { cell: CellRate },
              'Status': { cell: CustomStatusCell },
              'CurrencyType': { currency: currencyProject },
            }}
          />
          <div className='buttons'>
            <div className='button cancel' onClick={this.onBack}>
              <span>Back</span>
            </div>
            <Button
              onClick={this.onSubmit}
              disabled={!isRolesValid}
              className={classNames('button next', { 'btn-disabled': !isRolesValid })}
              label='Submit'
            />
          </div>
        </div>
        {!isEmptyObject(editAssignment) ? <EditAssignmentModal
          showModal={showEditAssignmentModal}
          closeModal={this.closeAssignmentModal}
          applyModal={this.applyAssignmentModal}
          assignment={editAssignment}
          editNewProject
        /> : null}
        {openDeleteDialog
          ? <ConfirmationDialog
            dialogHeader='DELETE'
            dialogTitle='Are you sure to delete this item?'
            closeButtonTitle='Cancel'
            confirmButtonTitle='Delete'
            actionData={deleteRole}
            closeDialog={this.closeDeleteDialog}
            confirmDialog={this.confirmDeleteDialog}
          />
          : null}
        {openAlertDialog
          ? <AlertDialog
            dialogHeader='Warning!'
            dialogTitle='Impossible to remove role. Role has associated data.'
            confirmButtonTitle='OK'
            closeDialog={this.closeAlertDialog}
            confirmDialog={this.closeAlertDialog}
          />
          : null}
      </React.Fragment>
    );
  }
}

Step.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
};

export default connect(store => ({
  projectDetails: store.projectsReducer.projectDetails,
  rateUnits: store.roleModalReducer.rateUnits,
}), {
  showRoleModal: showModal,
  getEmployees,
})(Step);
