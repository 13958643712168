export const SIGNIN_REQUEST = '@auth/SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = '@auth/SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = '@auth/SIGNIN_FAILURE';

export const signinRequest = (Email, Password) => ({
  type: SIGNIN_REQUEST,
  payload: {
    error: false,
    isLoading: true,
    Email,
    Password,
  },
});

export const signinFailure = (message) => ({
  type: SIGNIN_FAILURE,
  payload: {
    isLoading: false,
    error: true,
    message,
  },
});

export const signinRequestSuccess = () => ({
  type: SIGNIN_SUCCESS,
  payload: {
    error: false,
    isLoading: false,
  },
});
