import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  Field, reduxForm, destroy, initialize, change,
} from 'redux-form';
import renderTextField from 'common/components/redux-form//components/text-field';
import renderMultiSuggestBox from 'common/components/redux-form/components/multi-suggest';
import renderCheckbox from 'common/components/redux-form/components/check-box';
import renderInputFile from 'common/components/redux-form/components/input-type-file';
import Button from 'common/components/redux-form/components/button';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { required } from 'services/redux-form-validators.js';
import CustomIcon from 'common/components/icon/Icon';

const defaultStyles = {
  container: 'redux-form__field-container standart-container add-feedback__content-block',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

class BillingJustificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      initialize, initData,
    } = this.props;
    initialize(initData);
  }

  componentDidUpdate() { }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  confirmJustificationModal = () => {
    const {
      confirmDialog,
      valid, formData,
    } = this.props;
    if (valid) {
      confirmDialog(formData);
    }
  }


  onChange = (fieldData, value, prevValue, field) => {
    const { change } = this.props;
    switch (field) {
      case 'files':
        change('Attachment', typeof prevValue !== 'undefined' ? [...prevValue, value] : [value]);
        break;
      default: break;
    }
  };

  render() {
    const {
      closeDialog,
      valid,
      formData,
      editMode,
    } = this.props;

    return (
      <Dialog
        open
        onClose={closeDialog}
        aria-labelledby='responsive-dialog-title'
        className='justification__modal-width'
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>{!editMode ? 'Create Overtime Justification' : 'Edit Overtime Justification'}</span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent className='justification__dialog'>
          <form className='redux-form redux-form-justification-width' onSubmit={formHandleSubmit}>
            <div key='justification-form' className='justification-modal-body'>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create'>
                <Field
                  name='Employee'
                  component={renderMultiSuggestBox}
                  handleInputChange={this.handleInputChangeEmployee}
                  options={[
                    { Id: 1, Name: 'Vlad Topalov' }, { Id: 2, Name: 'Maxim Nikitin' }, { Id: 3, Name: 'Tim Cook' },
                  ]}
                  validate={required}
                  onChange={this.onChange}
                  placeholder='Add Employee'
                  label='Employee'
                  inputProps={{ id: 'Employee' }}
                  isClearable={false}
                  showAsterix
                  styles={{
                    ...defaultStyles,
                    label: 'redux-form__label redux-form__label--justification',
                    field: 'field justification-suggestbox-field',
                  }}
                  handleValue={formData && formData.Employee}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Office'
                  component={renderTextField}
                  label='Office'
                  placeholder='Add Office'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='RoleName'
                  component={renderTextField}
                  label='Role Name'
                  placeholder='Add Role Name'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
              </div>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create'>
                <Field
                  name='RoleType'
                  component={renderTextField}
                  label='Role Type'
                  placeholder='Add Role Type'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Assignment'
                  component={renderTextField}
                  label='Assignment'
                  placeholder='Add Assignment'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Overtime'
                  component={renderTextField}
                  label='Overtime'
                  placeholder='Add Overtime'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
              </div>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create'>
                <Field
                  name='OvertimeCoefficient'
                  component={renderTextField}
                  label='Overtime Coefficient'
                  placeholder='Overtime Coefficient'
                  validate={required}
                  maxlength='255'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Comments'
                  component={renderTextField}
                  label='Comments'
                  placeholder='Add Comment'
                  validate={required}
                  maxlength='1000'
                  showAsterix
                  styles={{
                    ...defaultStyles,
                  }}
                  formClassName='step__form svg-calendar-add-info history justification-modal--title'
                  inputClassName='justification-text_field'
                />
                <Field
                  name='Billable'
                  value={formData && formData.Billable ? formData.Billable : false}
                  component={renderCheckbox}
                  onChange={this.onChange}
                  isRequired
                  label='Billable'
                  styles={{
                    ...defaultStyles,
                  }}
                  checkboxContainerStyle='justification-billable-checkbox'
                />
              </div>
              <div className='redux-form__justification-modal-row redux-form__justification-modal-row--create form__justification-file-block'>
                <Field
                  name='Attachment'
                  label='Attachment'
                  component={renderInputFile}
                  changeEditForm={this.onChange}
                  postFile={formData && formData.Attachment}
                  showAsterix
                  accept='.jpeg, .jpg, .txt, .doc, .docx, .pdf, .png'
                  formClassName='cdp-form-file'
                  styles={{
                    ...defaultStyles,
                  }}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions className='justification-modal--dialog-action'>
          <Button
            onClick={closeDialog}
            className='button cancel confirmationdialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmJustificationModal}
            className={`button ${valid ? 'next' : 'btn-disabled'} confirmationdialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

BillingJustificationModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    initialize: () => dispatch(initialize('overtimeJustificationModal')),
    destroy: () => dispatch(destroy('overtimeJustificationModal')),
    change: (field, value) => dispatch(change('overtimeJustificationModal', field, value)),
  };
};

const mapStateDispatchToProps = (store) => {
  const isFormValues = store.form.overtimeJustificationModal && store.form.overtimeJustificationModal.values;
  return {
    hasError: isFormValues && store.form.overtimeJustificationModal.syncErrors,
    formData: isFormValues && store.form.overtimeJustificationModal.values,
  };
};

export default connect(
  mapStateDispatchToProps,
  mapDispatchToProps,
)(reduxForm({
  form: 'overtimeJustificationModal',
})(BillingJustificationModal));
