import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import {
  closeModal,
  addRoleToProject,
  showModal,
  getEmployees,
} from '../../actions/roleModalActions';
import classNames from 'classnames';
import Select from 'common/components/form-items/select/select';
import TextField from 'common/components/form-items/input/text-field';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import { sortFilterList } from 'common/components/list/sortList';
import { sortRoleEmployeesCreateProject } from 'utils/sortRoleEmployees';
import {
  PROJECT_ROLES_EMPLOYEES_PRIMARY,
  STATUS_BILLABLE,
  PROJECT_ROLES_EMPLOYEES_DISABLED,
  PROJECT_ROLES_EMPLOYEES_FORMER,
  DEFAULT_EMPLOYEE_ASSIGNMENTS,
} from 'utils/const-variable';
import { getAmericanFormat } from 'services/getAmericanFormatDigit.js';
import './role-modal.css';
import AddEmployee from './add-employee/add-employee';
import sortArrayByPriority from 'utils/sortArrayByPriority';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { isCorrectRoleAssignment } from '../utils/isCorrectRoleAssignments';

// eslint-disable-next-line no-useless-escape
const reqularExpRate = /^((?!0)|(0\.))\d{1,9}(\,\d{1,9}){0,3}?(\.\d{1,5})?$/;

export class EditRoleCreateProject extends React.Component {
  constructor(props) {
    super(props);
    const { projectId, rateUnits } = this.props;
    this.state = {
      ItemId: 0,
      RoleName: '',
      projectId,
      RoleId: '',
      RoleType: '',
      SeniorityId: '',
      SeniorityLevel: '',
      BillableStatusId: '',
      BillableStatus: '',
      Rate: '',
      OvertimeRate: '',
      AssignmentTypeId: 1,
      AssignmentType: '',
      Assignment: 1,
      EmployeeAssignment: 1,
      StartDate: null,
      EndDate: null,
      StatusId: '',
      Status: '',
      BillingCodeId: '',
      BillingCode: '',
      roleType: '',
      Comment: '',
      PrimaryAssignmentId: '',
      PrimaryEmployeeName: '',
      isModalFieldsRequired: false,
      assignmentIsUnvalid: false,
      invalidRateData: false,
      invalidOverRateData: false,
      isEmployeeListValid: true,
      rateUnit: rateUnits ? rateUnits[0].Id : '',
      overtimeRateUnit: rateUnits ? rateUnits[0].Id : '',
    };
  }

  componentDidMount() {
    const { roleEdit, assignmentTypes } = this.props;
    const assignedEmployees = [];

    sortRoleEmployeesCreateProject(roleEdit.Employees).forEach((employee, index, employees) => {
      if (!employee.PrimaryAssignmentId || employee.Role === PROJECT_ROLES_EMPLOYEES_PRIMARY) {
        const backups = [];
        employees.forEach(backup => backup.PrimaryAssignmentId
          && (
            backup.PrimaryAssignmentId === employee.Id
            || backup.PrimaryAssignmentId === employee.AssignmentKey
            || backup.PrimaryAssignmentId === employee.EmployeeId
          )
          && backups.push(this.getEmployeeDataModel(backup, [])));
        assignedEmployees.push(this.getEmployeeDataModel(employee, backups));
      }
    });

    this.findArrValue(assignmentTypes);
    this.setState({
      RoleName: roleEdit.RoleName,
      ItemId: roleEdit.ItemId,
      projectId: roleEdit.projectId,
      RoleId: roleEdit.RoleId,
      RoleType: roleEdit.RoleType,
      SeniorityId: roleEdit.SeniorityId,
      SeniorityLevel: roleEdit.SeniorityLevel,
      BillableStatusId: roleEdit.BillableStatusId,
      BillableStatus: roleEdit.BillableStatus,
      Rate: getAmericanFormat(roleEdit.Rate),
      OvertimeRate: getAmericanFormat(roleEdit.OvertimeRate),
      AssignmentTypeId: roleEdit.AssignmentTypeId,
      AssignmentType: roleEdit.AssignmentType,
      Assignment: roleEdit.Assignment,
      StartDate: roleEdit.StartDate,
      EndDate: roleEdit.EndDate,
      StatusId: roleEdit.StatusId,
      Status: roleEdit.Status,
      BillingCodeId: roleEdit.BillingCodeId,
      BillingCode: roleEdit.BillingCode,
      Comment: roleEdit.Comment,
      roleType: PROJECT_ROLES_EMPLOYEES_PRIMARY,
      Employees: assignedEmployees,
      rateUnit: roleEdit.rateUnit,
      overtimeRateUnit: roleEdit.overtimeRateUnit,
    }, () => this.requireFields());
  }

  getEmployeeDataModel = (employee, backups) => {
    return {
      AssignmentKey: employee.AssignmentKey,
      EmployeeId: employee.Id,
      EmployeeName: employee.Name,
      EmployeeAssignment: employee.Assignment,
      EmployeeStartDate: employee.StartDate,
      EmployeeEndDate: employee.EndDate,
      EmployeeStatusId: employee.Status && (employee.Status.Id || employee.StatusId),
      EmployeeStatus: employee.Status && (employee.Status.Name || employee.Status),
      EmployeeRole: employee.EmployeeRole || employee.Role,
      EmployeeComment: employee.Comment,
      primaryAssignmentId: employee.PrimaryAssignmentId,
      isValidEmployee: true,
      backupEmployees: backups,
    };
  }

  findArrValue = (arr) => {
    arr.forEach(item => {
      if (item.Name === 'Offshore') {
        this.setState({
          AssignmentTypeId: item.Id,
          AssignmentType: item.Name,
        });
      }
    });
  }

  sortArrays = (array) => {
    return array.sort((a, b) => (a.Name > b.Name
      ? 1
      : (a.Name === b.Name ? 0 : -1)));
  }

  requireFields = () => {
    const {
      RoleName, BillableStatus, Rate, assignmentIsUnvalid,
      BillingCodeId, invalidRateData, invalidOverRateData,
    } = this.state;
    (this.stateRequairedFieldsIsNotEmpty()
      && (BillableStatus === STATUS_BILLABLE ? Rate : true)
      && this.stateRequairedFieldsCheckDate()
      && (BillableStatus === STATUS_BILLABLE ? !!BillingCodeId : true)
      && !assignmentIsUnvalid
      && !invalidRateData
      && !!RoleName
      && !invalidOverRateData) ? (
        this.setState({
          isModalFieldsRequired: true,
        })
      ) : (
        this.setState({
          isModalFieldsRequired: false,
          EmployeeStartDate: null,
          EmployeeEndDate: null,
          EmployeeAssignment: '',
        })
      );
  }

  stateRequairedFieldsIsNotEmpty = () => {
    const {
      SeniorityId, BillableStatusId, AssignmentTypeId, Status, RoleId,
    } = this.state;
    const checkObject = {
      SeniorityId, BillableStatusId, AssignmentTypeId, Status, RoleId,
    };
    let validation = true;
    for (const key in checkObject) {
      if (checkObject[key] === '') {
        validation = false;
      }
    }
    return validation;
  }

  stateRequairedFieldsCheckDate = () => {
    const { StartDate, EndDate } = this.state;
    return StartDate !== null && EndDate !== null && moment(StartDate).isAfter(EndDate) === false;
  }

  // RoleName
  onChangeRoleName = (event) => {
    this.setState({
      RoleName: event.target.value,
    }, () => this.requireFields());
  }

  // Role
  onChangeRole = ({ item }) => {
    this.setState({
      RoleId: item.Id,
      RoleType: item.Name,
    }, () => this.requireFields());
  }

  // SeniorityLevel
  onChangeSeniority = ({ item }) => {
    this.setState({
      SeniorityId: item.Id,
      SeniorityLevel: item.Name,
    }, () => this.requireFields());
  }

  // BillableStatus
  onChangeBillable = ({ item }) => {
    this.setState({
      BillableStatusId: item.Id,
      BillableStatus: item.Name,
    }, () => this.requireFields());
  }

  // Rate
  onChangeRate = (e) => {
    if ((e.target.value.length && !reqularExpRate.test(e.target.value)) || (parseFloat(e.target.value) === 0)) {
      this.setState({
        invalidRateData: true,
      });
    } else {
      this.setState({
        invalidRateData: false,
      });
    }
    this.setState({
      Rate: getAmericanFormat(e.target.value),
    }, () => this.requireFields());
  }

  // OvertimeRate
  onChangeOvertimeRate = (e) => {
    if ((e.target.value.length && !reqularExpRate.test(e.target.value)) || (parseFloat(e.target.value) === 0)) {
      this.setState({
        invalidOverRateData: true,
      });
    } else {
      this.setState({
        invalidOverRateData: false,
      });
    }
    this.setState({
      OvertimeRate: getAmericanFormat(e.target.value),
    }, () => this.requireFields());
  }

  // AssignmentType
  onChangeAssignmentType = ({ item }) => {
    this.setState({
      AssignmentTypeId: item.Id,
      AssignmentType: item.Name,
    }, () => this.requireFields());
  }

  // Assigment
  onChangeAssignment = (e) => {
    if (isCorrectRoleAssignment(e.target.value)) {
      this.setState({
        assignmentIsUnvalid: false,
      });
    } else {
      this.setState({
        assignmentIsUnvalid: true,
      });
    }
    this.setState({
      Assignment: e.target.value,
    }, () => this.requireFields());
  }

  // Comment
  onChangeComment = (event) => {
    this.setState({
      Comment: event.target.value,
    }, () => this.requireFields());
  }

  // SOW/PO number
  onChangeBillingCode = ({ target }) => {
    const { billingCodes, billingCodesStartDate, billingCodesEndDate } = this.props;
    this.setState({
      BillingCodeId: target.value,
      BillingCode: target.value,
    }, () => {
      this.onChangeDate(billingCodesStartDate[billingCodes.indexOf(target.value)], billingCodesEndDate[billingCodes.indexOf(target.value)]);
    });
  }

  onChangeDate = (StartDate, EndDate) => {
    this.setState({
      StartDate,
      EndDate,
    }, () => this.requireFields());
  }

  // StartDate
  handleStartDateChange = date => {
    const { BillingCodeId } = this.state;
    const {
      showAlertDialog, billingCodesStartDate, billingCodes,
    } = this.props;

    const billingIsSelected = !!BillingCodeId;
    const billingCodeStartDate = billingIsSelected && billingCodesStartDate[billingCodes.indexOf(BillingCodeId)];

    if (billingIsSelected && moment(billingCodeStartDate).isAfter(date)) {
      return showAlertDialog('Date is less than SOW/PO start date, please change SOW/PO start date.');
    }
    this.setState({
      StartDate: date,
    }, () => this.requireFields());
  };

  // EndDate
  handleEndDateChange = date => {
    const { BillingCodeId } = this.state;
    const {
      showAlertDialog, billingCodesEndDate, billingCodes,
    } = this.props;

    const billingIsSelected = !!BillingCodeId;
    const billingCodeEndDate = billingCodesEndDate[billingCodes.indexOf(BillingCodeId)];


    if (billingIsSelected && billingCodeEndDate && moment(date).isAfter(billingCodeEndDate)) {
      return showAlertDialog('Date exceeds SOW/PO end date, please change SOW/PO end date.');
    }
    this.setState({
      EndDate: date,
    }, () => this.requireFields());
  };

  // Status
  onChangeStatus = ({ item }) => {
    this.setState({
      Status: item.Name,
      StatusId: item.Id,
    }, () => this.requireFields());
  }

  onChangeRateUnit = ({ item }) => {
    this.setState({
      rateUnit: item.Id,
    }, () => this.requireFields());
  }

  onChangeOvertimeRateUnit = ({ item }) => {
    this.setState({
      overtimeRateUnit: item.Id,
    }, () => this.requireFields());
  }

  clearValue = (property) => () => {
    if (property === 'BillingCodeId') {
      this.setState({
        [property]: '',
        BillingCode: '',
      }, () => this.requireFields());
      return;
    }
    if (property === 'Rate') {
      this.setState({
        [property]: '',
        invalidRateData: '',
      }, () => this.requireFields());
      return;
    }
    if (property === 'OvertimeRate') {
      this.setState({
        [property]: '',
        invalidOverRateData: '',
      }, () => this.requireFields());
      return;
    }
    this.setState({
      [property]: '',
    }, () => this.requireFields());
  };

  checkEmployeeListValid = (isEmployeeListValid, Employees) => {
    this.setState({
      isEmployeeListValid,
      Employees,
    });
  }

  getFlatEmployeeList = (employees) => {
    const flatEmployeesArray = employees.reduce((flatArray, primary) => {
      !!primary.EmployeeId && flatArray.push(primary);
      primary.backupEmployees && primary.backupEmployees.forEach(backup => !!backup.EmployeeId && flatArray.push(backup));
      return flatArray;
    }, []);

    return flatEmployeesArray;
  }

  getListViewDataModel = (employees) => {
    const viewEmployees = employees.map(employee => ({
      AssignmentKey: employee.AssignmentKey,
      Id: employee.EmployeeId,
      Name: employee.EmployeeName,
      Role: employee.EmployeeRole,
      Assignment: employee.EmployeeAssignment,
      StatusId: employee.EmployeeStatusId,
      Status: employee.EmployeeStatus,
      StartDate: employee.EmployeeStartDate,
      EndDate: employee.EmployeeEndDate,
      Comment: employee.EmployeeComment,
      PrimaryAssignmentId: employee.primaryAssignmentId,
    }));

    return viewEmployees;
  }

  applyModal = () => () => {
    const { editRole } = this.props;
    const { isEmployeeListValid, isModalFieldsRequired, Employees } = this.state;
    const state = { ...this.state, Employees: this.getListViewDataModel(this.getFlatEmployeeList(Employees)) };
    isEmployeeListValid && isModalFieldsRequired && editRole(state);
  }

  cancelRolePage = () => () => {
    const { onStepChange } = this.props;
    onStepChange({
      step: 2,
    });
  }

  getAssignerEmployeesToProject = () => {
    const { rolesState } = this.props;
    const employees = [];
    for (const role of rolesState) {
      employees.push.apply(employees, role.Employees)
    }

    return employees;
  }

  render() {
    const {
      RoleId,
      SeniorityId,
      BillableStatusId,
      Rate,
      BillableStatus,
      OvertimeRate,
      AssignmentTypeId,
      assignmentIsUnvalid,
      Assignment,
      BillingCodeId,
      StartDate,
      EndDate,
      Status,
      StatusId,
      isModalFieldsRequired,
      RoleName,
      invalidOverRateData,
      invalidRateData,
      rateUnit,
      overtimeRateUnit,
      Employees,
      isEmployeeListValid,
      Comment,
    } = this.state;
    const {
      roles,
      seniorityLevels,
      billableStatuses,
      assignmentTypes,
      billingCodes,
      roleStatus,
      roleModalAction,
      rateUnits,
      employees,
      projectTeams,
    } = this.props;

    const suitableEmployees = employees.filter(employee => {
      return employee.Type !== PROJECT_ROLES_EMPLOYEES_DISABLED
        && employee.Type !== PROJECT_ROLES_EMPLOYEES_FORMER;
    });
    const assignedEmployeesToProject = this.getAssignerEmployeesToProject();

    return (
      <div className='page-container page-block'>
        <div className='page-header title-border'>
          <span className='title-text'>EDIT ROLE</span>
        </div>
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          <div className='form-block'>
            <div className='step__row'>
              <TextField
                formClassName='role-page-project__form margin-right-50'
                inputClassName='c-SOW-edit__select'
                id='RoleName'
                label='Role Name'
                placeholder='Role Name'
                name='RoleName'
                maxlength='50'
                value={RoleName}
                onChange={this.onChangeRoleName}
                isRequired
                clearValue={this.clearValue('RoleName')}
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50 role-page__role-type-svg--margin'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Role Type'
                placeholder='Choose Type'
                value={RoleId}
                onChange={this.onChangeRole}
                selectOptions={this.sortArrays(roles)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Role',
                  id: 'role',
                }}
                isRequired
                clearValue={this.clearValue('RoleId')}
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50 role-page__role-type-svg--margin'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Seniority Level'
                placeholder='Choose Level'
                value={SeniorityId}
                onChange={this.onChangeSeniority}
                selectOptions={sortArrayByPriority(seniorityLevels)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Seniority Level',
                  id: 'client',
                }}
                isRequired
                clearValue={this.clearValue('SeniorityId')}
              />
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Billable Status'
                placeholder='Choose Status'
                value={BillableStatusId}
                onChange={this.onChangeBillable}
                selectOptions={billableStatuses}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Client',
                  id: 'client',
                }}
                isRequired
                clearValue={this.clearValue('BillableStatusId')}
              />
            </div>
            <div className='step__row margin-up-role'>
              {
                <div>
                  <TextField
                    formClassName='role-page-project__form-150 margin-right-50'
                    inputClassName='c-SOW-edit__input'
                    id='rate'
                    label='Rate'
                    placeholder='0'
                    maxlength='8'
                    value={Rate}
                    onChange={this.onChangeRate}
                    startAdornment='$'
                    isRequired={BillableStatus === STATUS_BILLABLE}
                    clearValue={this.clearValue('Rate')}
                  />
                  {invalidRateData ? <p className='project-input-error project-input-error-margin'>Invalid Rate</p> : null}
                </div>
              }
              {
                <Select
                  formClassName='role-page-project__form-150 margin-right-50'
                  selectClassName='c-SOW-edit__select'
                  optionsClassName='option'
                  label='Rate Unit'
                  placeholder='Choose Rate Unit'
                  value={rateUnit}
                  onChange={this.onChangeRateUnit}
                  selectOptions={rateUnits ? rateUnits : []}
                  isOptionObject
                  extendedValue
                  inputProps={{
                    name: 'rateUnit',
                    id: 'rateUnit',
                  }}
                  isRequired
                />
              }
              {
                <div>
                  <TextField
                    formClassName='role-page-project__form-150 margin-right-50'
                    inputClassName='c-SOW-edit__input'
                    id='rate'
                    label='Overtime Rate'
                    placeholder='0'
                    maxlength='8'
                    value={OvertimeRate}
                    onChange={this.onChangeOvertimeRate}
                    startAdornment='$'
                    clearValue={this.clearValue('OvertimeRate')}
                  />
                  {invalidOverRateData ? <p className='project-input-error project-input-error-margin'>Invalid Rate</p> : null}
                </div>
              }
              {
                <Select
                  formClassName='role-page-project__form-150 margin-right-50'
                  selectClassName='c-SOW-edit__select'
                  optionsClassName='option'
                  label='Overtime Rate Unit'
                  placeholder='Choose Overtime Rate Unit'
                  value={overtimeRateUnit}
                  onChange={this.onChangeOvertimeRateUnit}
                  selectOptions={rateUnits ? rateUnits : []}
                  isOptionObject
                  extendedValue
                  inputProps={{
                    name: 'overtimeRateUnit',
                    id: 'overtimeRateUnit',
                  }}
                  isRequired
                />
              }
              <Select
                formClassName='role-page-project__form-150 margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Assignment Type'
                placeholder='Choose Type'
                value={AssignmentTypeId}
                onChange={this.onChangeAssignmentType}
                selectOptions={this.sortArrays(assignmentTypes)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Assignment Type',
                  id: 'assignmentType',
                }}
                isRequired
                clearValue={this.clearValue('AssignmentTypeId')}
              />
              <TextField
                formClassName='role-page-project__form-150 margin-right-50'
                inputClassName='c-role-modal__input'
                id='assignment'
                label='Assignment'
                type='number'
                placeholder='0'
                isRequired
                error={assignmentIsUnvalid}
                value={Assignment}
                onChange={this.onChangeAssignment}
                clearValue={this.clearValue('Assignment')}
              />
            </div>
            <div className='step__row margin-up-role'>
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='SOW/PO Number'
                placeholder='Choose SoW Id/PO Number'
                value={BillingCodeId}
                onChange={this.onChangeBillingCode}
                selectOptions={billingCodes}
                isOptionObject={false}
                extendedValue
                inputProps={{
                  name: 'SOW/PO Number',
                  id: 'SOW/PONumber',
                }}
                isRequired={BillableStatus === STATUS_BILLABLE}
                clearValue={this.clearValue('BillingCodeId')}
              />
              <DatePicker
                formClassName='role-page-project__form-150  margin-right-50'
                datePickerClassName='c-role-modal__date-picker'
                placeholder='--/--/--'
                label='Start Date'
                value={StartDate}
                onChange={this.handleStartDateChange}
                isRequired
                name='startDate'
                isStartDate
              />
              <DatePicker
                formClassName='role-page-project__form-150  margin-right-50'
                datePickerClassName='c-role-modal__date-picker'
                placeholder='--/--/--'
                label='End Date'
                minVal={StartDate}
                disabled={!StartDate}
                value={EndDate}
                onChange={this.handleEndDateChange}
                helperText='Choose Start Date first'
                isRequired
                name='endDate'
                isEndDate
              />
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Status'
                placeholder='Choose Status'
                value={StatusId}
                onChange={this.onChangeStatus}
                selectOptions={sortFilterList(roleStatus)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Name',
                  id: 'Id',
                }}
                isRequired
                clearValue={this.clearValue('Status')}
              />
            </div>
            <div className='step__row margin-up-role role-modal__comment--width-wraper'>
              <TextField
                formClassName='role-page-project__form margin-right-50'
                inputClassName='c-SOW-edit__select'
                id='Comment'
                label='Comment'
                placeholder='Comment'
                name='Comment'
                maxlength='500'
                value={Comment}
                onChange={this.onChangeComment}
                isRequired={false}
                showClear
                clearValue={this.clearValue('Comment')}
              />
            </div>
          </div>
          <AddEmployee
            projectTeams={projectTeams}
            RoleStartDate={StartDate}
            RoleEndDate={EndDate}
            RoleAssignment={Assignment && DEFAULT_EMPLOYEE_ASSIGNMENTS}
            RoleStatus={Status}
            disabled={!isModalFieldsRequired}
            employees={suitableEmployees}
            checkEmployeeListValid={this.checkEmployeeListValid}
            assignedEmployees={Employees}
            getFlatEmployeeList={this.getFlatEmployeeList}
            assignedEmployeesToProject={assignedEmployeesToProject}
          />
        </Scrollbars>
        <div className='form-block buttons margin-down-role'>
          <div className='right-side-buttons'>
            <div className='button cancel' onClick={this.cancelRolePage(roleModalAction)}>
              <span>Cancel</span>
            </div>
            <div
              className={classNames('button next',
                { 'btn-disabled': !isModalFieldsRequired || assignmentIsUnvalid || !isEmployeeListValid })
              }
              onClick={this.applyModal(roleModalAction)}
            >
              <span>Apply Role</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditRoleCreateProject.propTypes = {
  roles: PropTypes.array,
  seniorityLevels: PropTypes.array,
  billableStatuses: PropTypes.array,
  assignmentTypes: PropTypes.array,
  billingCodes: PropTypes.array,
  billingCodesStartDate: PropTypes.array,
  billingCodesEndDate: PropTypes.array,
  employees: PropTypes.array,
  projectId: PropTypes.string,
  roleModalAction: PropTypes.string,
  rateUnits: PropTypes.array,
  projectTeams: PropTypes.array,
};

export default connect(store => {
  const {
    BillableStatuses = [],
    SeniorityLevels = [],
    Roles = [],
    AssignmentTypes = [],
  } = store.projectsReducer.projectRoleConfig;
  return {
    billableStatuses: BillableStatuses,
    seniorityLevels: SeniorityLevels,
    roles: Roles,
    assignmentTypes: AssignmentTypes,
    employees: store.roleModalReducer.employees,
    roleEmployees: store.roleModalReducer.roleEmployees,
    billingCodes: store.roleModalReducer.billingCodes,
    billingCodesStartDate: store.roleModalReducer.billingCodesStartDate,
    billingCodesEndDate: store.roleModalReducer.billingCodesEndDate,
    currentRoles: store.roleModalReducer.roles,
    projectId: store.roleModalReducer.projectId,
    roleModalAction: store.roleModalReducer.roleModalAction,
    roleStatus: store.roleModalReducer.status,
    roleEmployeeStatus: store.roleModalReducer.employeeStatus,
    rateUnits: store.roleModalReducer.rateUnits,
    projectDetails: store.projectsReducer.projectDetails,
    projectTeams: store.projectsReducer.projectTeams.Teams,
  };
}, {
  closeModal,
  addRoleToProject,
  showModal,
  getEmployees,
  showAlertDialog,
})(EditRoleCreateProject);
