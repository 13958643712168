import { put, takeEvery, call } from 'redux-saga/effects';
import http from 'services/http';

import { SIGNIN_REQUEST, signinFailure } from '../actions/loginActions';
import { setAuthData, setFinancialAccess } from 'common/actions/authActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { API_ACCESS_ROLE_NAME, MESSAGE_ACCESS_DENIED } from 'utils/const-variable';

export function* Authorize(action) {
  try {
    yield put(startRequest());
    const responseData = yield call(http, {
      url: 'auth/sign-in',
      method: 'post',
      data: action.payload,
    });
    if (responseData.data.User.UserRoles.find((role) => role === API_ACCESS_ROLE_NAME)) {
      yield put(finishRequest());
      yield put(signinFailure(MESSAGE_ACCESS_DENIED));
    } else {
      localStorage.setItem('AuthToken', responseData.data.AuthToken);
      localStorage.setItem('RefreshToken', responseData.data.RefreshToken);
      yield put(setAuthData(responseData.data));
      yield put(setFinancialAccess(responseData.data.User.Permissions.HasFinancialAccess ? true : false));
      yield put(finishRequest());
    }
  } catch (error) {
    const messageError = error.response && error.response.data.message;
    yield put(finishRequest());
    yield put(signinFailure(messageError));
  }
}

export default function* LoginRootSaga() {
  yield takeEvery(SIGNIN_REQUEST, Authorize);
}
