import * as React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, FormControl } from '@material-ui/core';
import MultiSelect from 'common/components/form-items/multi-select/multi-select';
import Select from 'common/components/form-items/select/selectStyles';
import SearchDropdown from 'common/components/form-items/search-dropdown/search-dropdown';
import MultiSuggestBox from '../form-items/multi-value-suggestbox/multi-value-suggestbox';
import './filter-item.css';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import { FILTER_ITEM_DATEPICKER_DEFAULT_TITLE } from 'utils/const-variable';
import TextField from 'common/components/form-items/input/text-field';
import isObjectNotEquals from 'utils/object-comparison';

class FilterItemComponent extends React.Component {
  constructor(props) {
    super(props);
    const { multiSelect } = this.props;
    this.state = {
      selected: multiSelect ? [] : '0',
      suggestData: [],
    };
  }

  componentDidMount() {
    const { filtersItem, suggestBox, searchDropdown } = this.props;
    if (filtersItem && (filtersItem.length || typeof filtersItem === 'number')) {
      this.setState({
        selected: filtersItem,
      });
    }
    if (searchDropdown && filtersItem) {
      this.setState({
        selected: filtersItem,
      });
    }
    if (suggestBox && filtersItem && filtersItem.length) {
      this.getSuggestBoxFilterItems();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (isObjectNotEquals(nextProps, this.props) || isObjectNotEquals(nextState, this.state));
  }

  componentDidUpdate(prevProps) {
    const {
      filtersItem, multiSelect, itemList, suggestBox,
    } = this.props;
    if (prevProps.filtersItem !== filtersItem) {
      this.setState({
        selected: filtersItem ? filtersItem : multiSelect ? [] : '0',
      });
    }
    if (prevProps.filtersItem !== filtersItem && typeof filtersItem === 'undefined') {
      this.resetFilters();
    }
    if (prevProps.itemList !== itemList && suggestBox && typeof filtersItem !== 'undefined') {
      this.getSuggestBoxFilterItems();
    }
  }

  getSuggestBoxFilterItems = () => {
    const { itemList, filtersItem } = this.props;
    const filterBoxItem = itemList.filter(item => {
      let findItem;
      filtersItem.forEach(filterItem => {
        if (item.Ids) {
          return item.Ids.indexOf(filterItem) !== -1 ? findItem = filterItem : null;
        }
        return item.Id === filterItem ? findItem = filterItem : null;
      });
      return findItem;
    });
    this.setState({
      suggestData: this.changeDataForMultiBox(filterBoxItem),
    });
  }

  resetFilters = () => {
    const { multiSelect } = this.props;
    this.setState({
      selected: multiSelect ? [] : '0',
      suggestData: [],
    });
  }

  selectOption = event => {
    const { selectOption, id } = this.props;
    this.setState({ selected: event.target.value });
    selectOption(event.target.value, id);
  }

  multiSelectOption = option => {
    const {
      selectOption, id,
    } = this.props;
    const { selected } = this.state;
    const isRepeat = selected.includes(option);
    const selectedOption = isRepeat ? selected.filter(curOption => curOption !== option) : selected.concat([option]);
    this.setState({
      selected: selectedOption,
    });
    selectOption(selectedOption, id);
  }

  onChangeSearchDropdown = option => {
    const { selectOption, id } = this.props;
    this.setState({
      selected: option ? option.value : '',
    });
    if (option) selectOption(option.value, id);
  }

  onChangeUser = (value) => {
    const { id, selectOption } = this.props;
    const copy = [];
    if (value[0] && value[0].valueIds) {
      value && value.forEach(item => copy.push(...item.valueIds));
    } else {
      value && value.forEach(item => copy.push(item.valueId));
    }
    selectOption(copy, id);
  };

  changeDataForMultiBox = (incomingItems) => {
    const employeesForMultiBox = [];
    incomingItems.map((item) => {
      if (item.Ids) {
        return employeesForMultiBox.push({
          label: item.Name,
          value: item.Name,
          valueIds: item.Ids,
        });
      }
      return employeesForMultiBox.push({
        label: item.Name,
        value: item.Name,
        valueId: item.Id,
      });
    });
    return employeesForMultiBox;
  };

  handleChangeSuggestBox = (value) => {
    this.setState({
      suggestData: value,
    });
  };

  handleCHangeDate = (date) => {
    const { onChange, id } = this.props;
    onChange(date, id);
  }

  render() {
    const {
      suggestBox, itemList, multiSelect, searchDropdown, title, placeholder, datepicker,
      formClassName, datePickerClassName, value, onChange, searchDropdownYears, className,
      minVal, maxVal, textField, id, clearValue, isMonthAndYear, disabled, onError, multiple,
      minErrorMassage, maxErrorMassage, hideAllElement,
    } = this.props;
    const { selected, suggestData } = this.state;

    const items = itemList && (hideAllElement ? [] : [[{ Id: '0', Name: 'All' }]]).concat(itemList).map((item, i) => {
      return (
        <MenuItem key={i} value={item.Id} className='option'>
          {item.Name}
        </MenuItem>
      );
    });

    return searchDropdownYears ? (
      <div className='filter-item filter-years'>
        <FormControl>
          <InputLabel htmlFor={title}>{title}</InputLabel>
          <Select
            className='filter-container_years-select'
            value={value}
            onChange={onChange}
            inputProps={{
              name: title,
              id: title,
            }}
          >
            {items}
          </Select>
        </FormControl>
      </div>
    ) : datepicker ? (
      <DatePicker
        formClassName={formClassName}
        datePickerClassName={datePickerClassName}
        placeholder='Choose Date'
        label={title ? title : FILTER_ITEM_DATEPICKER_DEFAULT_TITLE}
        value={value}
        isMonthAndYear={isMonthAndYear}
        onChange={this.handleCHangeDate}
        onError={onError}
        minVal={minVal}
        maxVal={maxVal}
        disabled={disabled}
        minErrorMassage={minErrorMassage}
        maxErrorMassage={maxErrorMassage}
      />
    ) : multiSelect ? (
      <MultiSelect
        contClass='filter-container_multi-select'
        selected={selected}
        title={title}
        onChange={this.multiSelectOption}
        options={itemList}
        placeholder={placeholder}
      />
    ) : searchDropdown ? (
      <SearchDropdown
        formClassName='search-dropdown-filter-container'
        selectClassName='search-dropdown-filter'
        placeholder={placeholder}
        label={title}
        value={selected}
        onChange={this.onChangeSearchDropdown}
        options={itemList}
        isRequired={false}
        inputProps={{
          name: title,
          id: title,
        }}
      />
    ) : suggestBox ? (
      <div className={`filter-item__multi-box multi-box-wrapper suggest-wrapper multi-sagges-box__size ${className ? className : ''}`}>
        <MultiSuggestBox
          onAdd={this.onChangeUser}
          label={title}
          placeholder={placeholder}
          options={this.changeDataForMultiBox(itemList)}
          isMulti
          multiple={multiple}
          suggesValue={suggestData}
          handleChangeSuggestBox={this.handleChangeSuggestBox}
        />
      </div>
    ) : textField ? (
      <TextField
        formClassName='step__form toolbar_textfield-filter'
        inputClassName='step__input'
        label={title}
        placeholder={placeholder}
        maxlength='100'
        name={id}
        value={value}
        onChange={onChange}
        clearValue={clearValue}
        showClear
        clearClassName='toolbar_textfield-filter_clear'
      />
    ) : (
      <div className='filter-item'>
        <FormControl>
          <InputLabel htmlFor={title}>{title}</InputLabel>
          <Select
            value={selected}
            onChange={this.selectOption}
            inputProps={{
              name: title,
              id: title,
            }}
          >
            {items}
          </Select>
        </FormControl>
      </div>
    );
  }
}

FilterItemComponent.propTypes = {
  selectOption: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
  itemList: PropTypes.array,
  multiSelect: PropTypes.bool,
};

export default FilterItemComponent;
