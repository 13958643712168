import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import { sortFilterList } from 'common/components/list/sortList';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { loadManageDocumentTypes } from 'pages/admin/actions/adminManageDocumentTypes';
import { getMilestonesForAmendment, setMilestonesForAmendment } from 'pages/projects/actions/projectsActions';
import ProjectEditAmendmentForm from 'common/components/redux-form/project-edit-Amendment-form/project-edit-Amendment-form';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { cancelImport } from 'pages/projects/actions/importContractAction';
import { convertMilestones } from 'utils/convertMilestones';
import { formatContractDate } from '../../utils/formatContractDate';

export class AmendmentEditPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      projectDetails, location: locationProps, ManageProjectContracts, HasFinancialAccess,
    } = this.props;
    this.state = {
      columnsToDisplay: {
        documentsList: [
          {
            Name: 'Documents Links', isSelected: true, Id: 'Comment', className: 'without-width-col', pressArrowLeft: true,
          },
          {
            Name: 'Documents Types', isType: true, Id: 'DocumentType', className: 'without-width-col', pressArrowLeft: true,
          },
        ],
        milestonesList: [
          {
            Name: 'Milestone', Id: 'Milestone', className: 'without-width-col', isRequired: true,
          },
          {
            Name: 'Milestone Amount', Id: 'MilestoneAmount', className: 'without-width-col', isRequired: true,
          },
          {
            Name: 'Backup used', Id: 'UsedAssignmentBackupUser', className: 'without-width-col',
          },
        ],
      },
      ManageProjectContracts,
      HasFinancialAccess,
    };
    // eslint-disable-next-line prefer-destructuring
    this.location = history.location.pathname.split('/')[3];
    this.Id = history.location.pathname.split('/')[4] || '';
    this.SOWId = this.location === 'add-amendment' ? history.location.pathname.split('/')[4] : '';
    this.emptyForm = {
      ApprovalStatus: {},
      Approver: '',
      BillingCode: '',
      Budget: '',
      BudgetComments: '',
      Contract: '',
      EndDate: null,
      Id: '',
      Links: [],
      Milestones: [],
      RealizedRevenue: '',
      StartDate: null,
      Status: {},
      StatusComments: '',
      Type: '',
      Company: '',
    };
    !projectDetails && history.push({
      pathname: `/projects/${history.location.pathname.split('/')[2]}`,
      state: locationProps.state,
    });
  }

  componentDidMount() {
    const {
      projectDetails, location, loadManageDocumentTypes, getMilestonesForAmendment,
      setComponent, HasFinancialAccess,
    } = this.props;
    !projectDetails.Name && history.back();
    this.defineRightsForEdit();
    loadManageDocumentTypes();
    if (HasFinancialAccess) {
      this.Id && !this.SOWId && getMilestonesForAmendment(this.Id, projectDetails.Id, this.getSOWId());
    }
    const rightPath = location.state && location.state.isWrongPath ? 'contracts' : 'projects';
    setComponent({
      returnButton: { text: rightPath, goToPreviousPage: this.goTyMainList },
      secondReturnButton: { text: `${projectDetails && projectDetails.Name} details`, goToPreviousPage: this.goToProjectDetails },
    });
  }

  componentWillUnmount() {
    const { setMilestonesForAmendment, resetComponent } = this.props;
    const emptyArray = [];
    setMilestonesForAmendment(emptyArray);
    resetComponent();
  }

  defineRightsForEdit = () => {
    if (this.location === 'add-amendment') {
      this.setState({
        HasFinancialAccess: true,
      });
    }
  }

  goTyMainList = () => {
    const { location } = this.props;
    const rightPath = location.state && location.state.isWrongPath ? 'contracts' : 'projects';
    history.push({ pathname: `/${rightPath}`, state: location.state });
  }

  goToProjectDetails = () => {
    const { projectDetails, location, cancelImport } = this.props;
    const SOWDetails = projectDetails.Contracts.find(sow => sow.Id === this.SOWId);

    SOWDetails && SOWDetails.Project && cancelImport(SOWDetails.Project.Id);
    history.push({ pathname: `/projects/${projectDetails.Id}`, state: location.state });
  }

  getSOWId = () => {
    const { projectDetails } = this.props;
    const SOWDetails = projectDetails && projectDetails.Contracts.find(sow => sow.Amendments.some(amendment => amendment.Id === this.Id));
    return this.SOWId || SOWDetails.Id;
  }

  render() {
    const { columnsToDisplay, ManageProjectContracts, HasFinancialAccess } = this.state;
    const {
      projectDetails,
      approvalStatuses,
      clients,
      clientContacts,
      documentStatuses,
      location,
      documentTypes,
      milestones,
      allRoles,
      importContract,
    } = this.props;

    let SOWDetails,
      AmedmentDetails,
      formTitle;
    switch (this.location) {
      case 'add-amendment':
        SOWDetails = projectDetails && projectDetails.Contracts.find(sow => sow.Id === this.SOWId);
        formTitle = 'Add Amendment for SOW ';
        break;
      case 'edit-amendment':
      case 'view-amendment':
        SOWDetails = projectDetails && projectDetails.Contracts.find(sow => sow.Amendments.some(amendment => amendment.Id === this.Id));
        AmedmentDetails = SOWDetails && SOWDetails.Amendments.find(amendment => amendment.Id === this.Id);
        formTitle = 'Edit Amendment for SOW ';
        break;
      default: break;
    }
    const arrayMilestones = importContract && importContract.Milestones ? convertMilestones(importContract.Milestones) : convertMilestones(milestones);
    return (
      <div className='page-container'>
        <DocumentTitle title={applicationTitle.getTitile(this.location === 'edit-amendment'
          ? 'editAmendment' : this.location === 'add-amendment' ? 'addAmendment' : 'viewAmendment')}
        />
        <div className='form-header'>
          <span>{formTitle}</span>
          <span className='highlighted-text'>{SOWDetails && SOWDetails.Contract}</span>
        </div>
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          <ProjectEditAmendmentForm
            data={AmedmentDetails ? {
              ...AmedmentDetails,
              Company: AmedmentDetails && AmedmentDetails.Company && AmedmentDetails.Company.Id,
              Approver: AmedmentDetails && AmedmentDetails.Approver && AmedmentDetails.Approver.Id,
              Milestones: arrayMilestones,
            } : Object.keys(importContract).length ? {
              ...this.emptyForm,
              Company: SOWDetails && SOWDetails.Company && SOWDetails.Company.Id,
              Approver: SOWDetails && SOWDetails.Approver && SOWDetails.Approver.Id,
              Budget: 0,
              ...importContract,
              Contract: importContract.Number || '',
              Milestones: arrayMilestones || [],
              EndDate: formatContractDate(importContract.EndDate) || formatContractDate(SOWDetails.EndDate),
              StartDate: formatContractDate(importContract.StartDate) || formatContractDate(SOWDetails.StartDate),
              ApprovalStatus: approvalStatuses.find(item => item.Id === importContract.ApprovalStatus) || {},
              Status: documentStatuses.find(item => item.Id === importContract.DocumentStatus) || {},
            }
              : {
                ...this.emptyForm,
                Company: SOWDetails && SOWDetails.Company && SOWDetails.Company.Id,
                Approver: SOWDetails && SOWDetails.Approver && SOWDetails.Approver.Id,
                Budget: 0,
              }}
            SOWDetails={SOWDetails}
            projectDetails={projectDetails}
            disabled={!ManageProjectContracts}
            viewFinancialInfo={HasFinancialAccess}
            allApprovalStatuses={sortFilterList(approvalStatuses)}
            allClients={sortFilterList(clients)}
            allApprovers={sortFilterList(clientContacts)}
            allStatuses={sortFilterList(documentStatuses)}
            allDocumentTypes={sortFilterList(documentTypes)}
            currencyProject={projectDetails && projectDetails.Currency && projectDetails.Currency.Name}
            RolesForContarct={projectDetails.Roles}
            allRolesForContract={allRoles}
            projectType={projectDetails && projectDetails.Billability}
            columnsToDisplay={columnsToDisplay}
            location={location}
          />
        </Scrollbars>
      </div>
    );
  }
}

AmendmentEditPage.propTypes = {
  projectDetails: PropTypes.object,
  approvalStatuses: PropTypes.array,
  clientContacts: PropTypes.array,
  documentStatuses: PropTypes.array,
  clients: PropTypes.array,
  loadManageDocumentTypes: PropTypes.func,
};

export default connect(store => ({
  projectDetails: store.projectsReducer.projectDetails,
  clients: store.projectsReducer.clients,
  approvalStatuses: store.projectsReducer.approvalStatuses,
  clientContacts: store.projectsReducer.clientContacts,
  documentStatuses: store.filtersReducer.filters.documentStatuses,
  isOnlyActive: store.showRoles.isOnlyActiveRoles,
  UserOfficeId: store.authReducer.user.UserOfficeId,
  Offices: store.projectsReducer.projectDetails.Offices,
  documentTypes: store.adminManageLookups.documentTypes,
  milestones: store.projectsReducer.Milestones.Amendment,
  allRoles: store.projectsReducer.projectDetails.AllRoles,
  importContract: store.importContractReducer.contract,

}), {
  loadManageDocumentTypes,
  getMilestonesForAmendment,
  setMilestonesForAmendment,
  setComponent,
  resetComponent,
  cancelImport,
})(AmendmentEditPage);
