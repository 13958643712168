export const GET_MANAGE_PAYMENT_TERMS = '@admin/GET_MANAGE_PAYMENT_TERMS';
export const SET_MANAGE_PAYMENT_TERMS = '@admin/SET_MANAGE_PAYMENT_TERMS';
export const CREATE_NEW_PAYMENT_TERM = '@admin/CREATE_NEW_PAYMENT_TERM';
export const UPDATE_PAYMENT_TERM = '@admin/UPDATE_PAYMENT_TERM';
export const DELETE_PAYMENT_TERM = '@admin/DELETE_PAYMENT_TERM';

export const getManagePaymentTerms = () => ({
  type: GET_MANAGE_PAYMENT_TERMS,
});

export const setManagesPaymentTerms = (paymentTerms) => ({
  type: SET_MANAGE_PAYMENT_TERMS,
  payload: paymentTerms,
});

export const createNewPaymentTerm = (data) => {
  return {
    type: CREATE_NEW_PAYMENT_TERM,
    payload: data,
  };
};

export const updatePaymentTerm = (Name, Id) => {
  return {
    type: UPDATE_PAYMENT_TERM,
    payload: {
      Name,
      Id,
    },
  };
};

export const deletePaymentTerm = (id) => {
  return {
    type: DELETE_PAYMENT_TERM,
    payload: {
      roleTypeId: id,
    },
  };
};
