import React, { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const CellEmail = ({
  item,
  column,
}) => {
  const email = item[column.Name];
  const [tooltipLabel, setTooltipLabel] = useState(email);

  const onTooltipCloseHandler = () => {
    setTooltipLabel(email);
  };

  const onIconButtonClickHandler = async (e) => {
    e.stopPropagation();
    let tooltipLabel = '';
    try {
      await navigator.clipboard.writeText(email);
      tooltipLabel = 'Copied into clipboard';
    } catch {
      tooltipLabel = 'Could not copy into clipboard';
    } finally {
      setTooltipLabel(tooltipLabel);
    }
  };

  return (
    <div
      className={`c-list__item-column ${column.className}`}
    >
      <Tooltip
        arrow
        placement='top'
        onClose={onTooltipCloseHandler}
        title={<Typography variant='caption'>{tooltipLabel}</Typography>}
      >
        <IconButton
          aria-label='copy into clipboard'
          size='small'
          onClick={onIconButtonClickHandler}
        >
          <FileCopyIcon fontSize='small' />
        </IconButton>
      </Tooltip>
    </div>
  );
};
