import React from 'react';
import {
  Field, reduxForm, destroy,
} from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '../components/button';
import { required } from 'services/redux-form-validators.js';
import history from 'utils/history';
import './create-client-from.css';
import renderTextField from '../components/text-field';
import { Badge } from 'common/components/badges/badges';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { getColorFromString } from 'services/getColor';

const defaultStyles = {
  container: 'redux-form__field-container standart-container-assignment client-margin_top',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const validateName = (value, values, props, field) => {
  const error = props.clients.find(item => {
    return (value && (item.Name.toLowerCase() === value.toLowerCase()));
  });

  if (error) {
    return required(false, values, props, field, 'Client name already exists');
  }
  return required(value, values, props, field);
};


class CreateClientStep1 extends React.Component {
  onSubmit = () => {
    const { destroy, onSubmit, data } = this.props;
    onSubmit(data);
    history.goBack();
    destroy();
  }

  onCancel = () => {
    const { destroy } = this.props;
    history.goBack();
    destroy();
  }

  render() {
    const { allData, onNextStep } = this.props;
    const companyName = (allData && allData.values ? allData.values.Name : 'Company Name').trim();
    return (
      <form className='redux-form' onSubmit={formHandleSubmit}>
        <div className='redux-form__row_client'>
          <div className='new_employee_photo create-client-photo'>
            <Badge
              color={getColorFromString(companyName)}
              item={companyName}
            />
          </div>
          <Field
            name='Name'
            component={renderTextField}
            label='Company Name'
            placeholder='Add Name'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
            validate={validateName}
            showAsterix
          />
        </div>
        <div className='redux-form__create-client--buttons'>
          <div className='redux-form__create-client--buttons-left'>
            <Button
              onClick={this.onSubmit}
              disabled={(allData && allData.syncErrors) || !allData}
              className={classNames('button next button_width-150', { 'btn-disabled': (allData && allData.syncErrors) || !allData })}
              text='Save and Close'
            />
          </div>
          <div className='redux-form__create-client--buttons-right'>
            <Button
              onClick={this.onCancel}
              className='button cancel'
              text='Cancel'
            />
            <Button
              onClick={onNextStep}
              disabled={(allData && allData.syncErrors) || !allData}
              className={classNames('button next', { 'btn-disabled': (allData && allData.syncErrors) || !allData })}
              text='Next'
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    destroy: () => dispatch(destroy('CreateClient')),
  };
};

const mapStateDispatchToProps = store => {
  const isFormReducerExists = store.form.CreateClient && store.form.CreateClient.values;
  return {
    data: isFormReducerExists && store.form.CreateClient.values,
    allData: isFormReducerExists && store.form.CreateClient,
    clients: store.clientsReducer.clients,
  };
};

export default connect(
  mapStateDispatchToProps,
  mapDispatchToProps,
)(reduxForm({
  form: 'CreateClient',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateClientStep1));
