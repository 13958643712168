import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../../icon/Icon';

export class CellAssignment extends Component {
  render() {
    const { item, column } = this.props;
    return (
      <div className={classNames('c-list__item-column c-list__status', column.className ? column.className : 'small-col')}>
        {item.Assignment}
        {
          item.UnderstaffedRole ? (
            <CustomIcon iconName='open-role' className='open-role' />
          ) : null
        }
      </div>
    );
  }
}

CellAssignment.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
