import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import moment from 'moment';
import http from 'services/http';
import {
  setProjectedRevenueReports,
  GET_PROJECTED_REVENUE_REPORTS,
} from '../actions/financeActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import formatCurrency from 'utils/formatCurrency';

function* getProjectedRevenueReports(action) {
  try {
    const search = action.payload && action.payload.search;
    const column = action.payload && action.payload.column;
    const orderByDesc = action.payload.sortDirection && (action.payload.sortDirection === 'Up' ? false : true);
    yield put(startRequest());
    const reports = yield call(http, {
      method: 'GET',
      url: `projected-revenue-reports?Search=${search ? encodeURIComponent(search) : ''}&SortField=${column ? column : ''}&orderByDesc=${orderByDesc}`,
      params: action.payload.filters,
    });
    reports.data.ProjectProjectedRevenue.forEach(project => {
      project.ProjectContractMilestones.forEach(milestone => {
        project[`${moment(milestone.Milestone).format('M_Y')}_BookedRevenue`] = milestone.BookedRevenue;
        project[`${moment(milestone.Milestone).format('M_Y')}_ProjectedRevenue`] = milestone.ProjectedRevenue;
      });

      project.ContractsProjectedRevenue.forEach(contract => {
        contract.Currency = { ...project.Currency };
        contract.MilestonesProjectedRevenue.forEach(milestone => {
          contract[`${moment(milestone.Milestone).format('M_Y')}_BookedRevenue`] = milestone.BookedRevenue;
          contract[`${moment(milestone.Milestone).format('M_Y')}_ProjectedRevenue`] = milestone.ProjectedRevenue;
        });
      });
    });
    const TotalItems = reports.data.TotalsAkvelonPerCurrency.map(total => ({
      ...total,
      Company: `Akvelon Total per ${formatCurrency(total.Currency.Name)}`,
      ...total.ProjectContractMilestones.reduce((acc, milestone) => {
        acc[`${moment(milestone.Milestone).format('M_Y')}_BookedRevenue`] = milestone.BookedRevenue;
        acc[`${moment(milestone.Milestone).format('M_Y')}_ProjectedRevenue`] = milestone.ProjectedRevenue;
        return acc;
      }, {}),
    }));
    const report = [...reports.data.ProjectProjectedRevenue, ...TotalItems];
    yield put(setProjectedRevenueReports(report));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export default function* reportsProjectedRevenueMainSaga() {
  yield takeLatest(GET_PROJECTED_REVENUE_REPORTS, getProjectedRevenueReports);
}
