export const scrollToLastElement = (wrapper, target, isShift) => {
  const container = document.getElementById(wrapper);
  const containerSize = container.getBoundingClientRect();
  const elem = document.getElementById(target);
  const positionElem = elem && elem.getBoundingClientRect();
  if (positionElem && containerSize) {
    const currentPosition = positionElem.top - containerSize.top - (isShift ? positionElem.height : 0);
    container.scrollTo && container.scrollTo({
      top: currentPosition,
      behavior: 'smooth',
    });
  }
};
