/* eslint-disable indent */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import Tabs from 'common/components/tabs/tabs';
import { List } from 'common/components/list/list';
import { getSelectedTab } from 'common/actions/selectedTabActions';
import { sortList } from 'common/components/list/sortList';
import { CellBudget } from 'common/components/list/custom-cell/cell-budget';
import { getStatusColor } from 'services/getStatusColor';
import NoEditableItemBlock from 'common/components/no-editable-block/no-editable-item-blick';
import { CellBillingRoleCheckbox } from 'common/components/list/custom-cell/cell-billing-roles-checkbox';
import { CellSimpleCheckbox } from 'common/components/list/custom-cell/cell-simple-checkbox';
import { CellFiles } from 'common/components/list/custom-cell/cell-files';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BillingJustificationModal from './components/overtimeJustificationModal';
import BillingOtherModal from './components/otherJustificationModal';
import InvoiceModal from './components/invoiceModal';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import MultiSuggestBox from 'common/components/form-items/multi-value-suggestbox/multi-value-suggestbox';
import { getDataForMultiBox } from 'utils/getDataForMultiBox';
import CustomizedTreeView from './components/fileTree';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { setListSettings } from '../../actions/billingActions';
import history from 'utils/history';

const ROLES_TAB_INDEX = 0;
const JUSTIFICATION_TAB_INDEX = 1;
const JUSTIFICATION_OTHER_TAB_INDEX = 2;
const INVOICE_TAB_INDEX = 3;

export class BillingDetails extends React.Component {
  constructor(props) {
    super(props);
    const { selectedTab } = this.props;
    this.state = {
      selectedTab,
      columnsToDisplay: [
        [
          {
            Name: 'Employee', Id: 'Employee', isObject: true,
          },
          {
            Name: 'Office', Id: 'Office', className: 'small-col',
          },
          {
            Name: 'Role Name', Id: 'RoleName',
          },
          {
            Name: 'Role Type', Id: 'RoleType',
          },
          {
            Name: 'Assignment', Id: 'Assignment',
          },
          {
            Name: 'Expected', Id: 'Expected',
          },
          {
            Name: 'Actual', Id: 'Actual', className: 'small-col',
          },
          {
            Name: 'Total Overtime', Id: 'TotalOvertime',
          },
          {
            Name: 'Total Hours', Id: 'TotalHours',
          },
          {
            Name: 'Rate', Id: 'Rate', className: 'small-col', isBudget: true,
          },
          {
            Name: 'TotalAmount', Id: 'TotalAmount', isBudget: true,
          },
          {
            Name: '', Id: 'Status', deleteArrow: 'delete-arrow',
          },
        ],
        [],
        [
          {
            Name: 'Employee', Id: 'Employee', className: 'without-width-col', isObject: true,
          },
          {
            Name: 'Office', Id: 'Office', className: 'small-col',
          },
          {
            Name: 'Role Name', Id: 'RoleName',
          },
          {
            Name: 'Role Type', Id: 'RoleType',
          },
          {
            Name: 'Assignment', Id: 'Assignment',
          },
          {
            Name: 'Overtime', Id: 'Overtime',
          },
          {
            Name: 'Overtime Coefficient', Id: 'OvertimeCoefficient',
          },
          {
            Name: 'Billable', Id: 'Billable', className: 'small-col',
          },
          {
            Name: 'Attachment', Id: 'Attachment',
          },
          {
            Name: 'Comments', Id: 'Comments', className: 'big-col',
          },
        ],
        [
          {
            Name: 'Invoice', Id: 'Invoice', className: 'big-col',
          },
          {
            Name: 'Actual Amount Paid', Id: 'ActualAmountPaid', className: 'big-col', isString: true,
          },
          {
            Name: 'Comments', Id: 'Comments', className: 'without-width-col',
          },
        ],
      ],
      columnsToDisplayJustificationOther: [
        {
          Name: 'Title', Id: 'Title', className: 'big-col',
        },
        {
          Name: 'Amount', Id: 'Amount',
        },
        {
          Name: 'Description', Id: 'Description', className: 'big-col',
        },
        {
          Name: 'Attachment', Id: 'Attachment',
        },
        {
          Name: 'Comments', Id: 'Comments', className: 'without-width-col',
        },
      ],
      isShowJustificationOvertimeModal: false,
      isEditJustificationOvertimeModal: false,
      editJustificationOvertimeObject: {},
      isShowJustificationOtherModal: false,
      isEditJustificationOtherModal: false,
      editJustificationOtherObject: {},
      isShowInvoiceModal: false,
      isEditInvoiceModal: false,
      editInvoiceObject: {},
      isShowConfirmationDialog: false,
      isShowChangeStatus: false,
    };
  }

  componentDidMount() {
    const { setComponent } = this.props;
    setComponent({ returnButton: { text: 'Billing', goToPreviousPage: this.goToBillingList } });
  }

  componentDidUpdate() {

  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  goToBillingList = () => {
    history.push({ pathname: '/billing' });
  }

  onTab = (selectedTab) => {
    const { getSelectedTab } = this.props;
    this.setSelectedTab(selectedTab);
    getSelectedTab(selectedTab);
  }

  setSelectedTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  setSortSettings = (tabName) => (sortColumn, sortDirection) => {
    const { setListSettings, listSettings } = this.props;
    setListSettings({
      ...listSettings,
      detailsTabsState: {
        ...listSettings.detailsTabsState,
        [tabName]: {
          ...listSettings.detailsTabsState[tabName],
          sortColumn,
          sortDirection,
        },
      },
    });
  }

  setShowJustificationModal = () => {
    this.setState((prevState) => ({
      isShowJustificationOvertimeModal: !prevState.isShowJustificationOvertimeModal,
      isEditJustificationOvertimeModal: prevState.isShowJustificationOvertimeModal ? false : prevState.isEditJustificationOvertimeModal,
    }));
  }

  showEditJustificationModal = (listData) => {
    this.setState({
      editJustificationOvertimeObject: listData.item,
      isEditJustificationOvertimeModal: true,
    }, () => {
      this.setState({
        isShowJustificationOvertimeModal: true,
      });
    });
  }

  setShowJustificationOtherModal = () => {
    this.setState((prevState) => ({
      isShowJustificationOtherModal: !prevState.isShowJustificationOtherModal,
      isEditJustificationOtherModal: prevState.isShowJustificationOtherModal ? false : prevState.isEditJustificationOtherModal,
    }));
  }

  showEditJustificationOtherModal = (listData) => {
    this.setState({
      editJustificationOtherObject: listData.item,
      isEditJustificationOtherModal: true,
    }, () => {
      this.setState({
        isShowJustificationOtherModal: true,
      });
    });
  }

  setShowInvoiceModal = () => {
    this.setState((prevState) => ({
      isShowInvoiceModal: !prevState.isShowInvoiceModal,
      isEditInvoiceModal: prevState.isShowInvoiceModal ? false : prevState.isEditInvoiceModal,
    }));
  }

  showEditInvoiceModal = (listData) => {
    this.setState({
      editInvoiceObject: listData.item,
      isEditInvoiceModal: true,
    }, () => {
      this.setState({
        isShowInvoiceModal: true,
      });
    });
  }

  setShowConfirmationDialog = () => {
    this.setState((prevState) => ({
      isShowConfirmationDialog: !prevState.isShowConfirmationDialog,
    }));
  }

  setShowChangeStatus = () => {
    this.setState((prevState) => ({
      isShowChangeStatus: !prevState.isShowChangeStatus,
    }));
  }

  changeStatus = (data) => {
    // made for visibility of status changes, you can’t do this in the working code...
    const { billingDetails } = this.props;
    billingDetails.Status = data.label;
    // ..
    this.setState((prevState) => ({
      isShowChangeStatus: !prevState.isShowChangeStatus,
    }));
  }

  render() {
    const {
      billingDetails,
      listSettings,
    } = this.props;
    const {
      selectedTab, columnsToDisplay, columnsToDisplayJustificationOther,
      isShowJustificationOvertimeModal, isEditJustificationOvertimeModal, editJustificationOvertimeObject,
      isShowJustificationOtherModal, isEditJustificationOtherModal, editJustificationOtherObject,
      isShowInvoiceModal, isEditInvoiceModal, editInvoiceObject,
      isShowConfirmationDialog,
      isShowChangeStatus,
    } = this.state;
    const {
      roles: rolesState,
      justification: justificationState,
      justificationOther: justificationOtherState,
      invoice: invoiceState,
    } = listSettings.detailsTabsState;
    const isBillingHasError = billingDetails
      && (billingDetails.Roles && (billingDetails.Roles.find(item => item.Actual === '0')
        || ((billingDetails.JustificationOvertime && billingDetails.JustificationOvertime.find(item => !item.Attachment.length))
          || (billingDetails.JustificationOther && billingDetails.JustificationOther.find(item => !item.Attachment.length)))));
    return !billingDetails ? null : (
      <Scrollbars
        autoHide
        autoHideTimeout={300}
        hideTracksWhenNotNeeded
      >
        <div className='page-container'>
          {isShowConfirmationDialog
            ? (<ConfirmationDialog
                dialogHeader='DELETE'
                dialogTitle='Are you sure to delete this item?'
                closeButtonTitle='Cancel'
                confirmButtonTitle='Delete'
                closeDialog={this.setShowConfirmationDialog}
                confirmDialog={this.setShowConfirmationDialog}
            />) : null}
          <div className='project-info billing-details'>
            <div className='billing-details-container'>
              {isShowChangeStatus ? (<div
                className='redux-form__header-inputs billing-header-block'
                data-name='Status'
                onClick={this.onHandleClickEdit}
              >
                <span className='billing-header'>{`${billingDetails.Project} — ${billingDetails.Contract} : ${billingDetails.Period}`}</span>
                <MultiSuggestBox
                  onAdd={this.changeStatus}
                  placeholder='Status'
                  options={getDataForMultiBox([{ Id: 1, Name: 'Insufficient' }, { Id: 2, Name: 'Ready' }, { Id: 3, Name: 'Sent' }])}
                  isMulti={false}
                  autoFocus
                  isClearable={false}
                  onBlur={this.setShowChangeStatus}
                  className='billing-multi-box'
                />
              </div>) : (<div
                data-name='Status'
                className='redux-form__header-inputs billing-header-block'
              >
                <span className='billing-header'>{`${billingDetails.Project} — ${billingDetails.Contract} : ${billingDetails.Period}`}</span>
                <span
                  className='billing-header__status'
                  onClick={this.setShowChangeStatus}
                  style={{ background: getStatusColor(billingDetails.Status) }}
                >{billingDetails.Status}</span>
              </div>)}
            </div>
            <div className='employees-form__row billing-details-block-data'>
              <NoEditableItemBlock
                label='Project'
                value={billingDetails.Project}
              />
              <NoEditableItemBlock
                label='SOW Type'
                value={billingDetails.Type}
              />
              <NoEditableItemBlock
                label='Expected Hours'
                value={billingDetails.ExpectedHours}
              />
              <NoEditableItemBlock
                label='Approved Overtime Hours'
                value={billingDetails.ApprovedOvertimeHours}
              />
              <NoEditableItemBlock
                label='Total Hours'
                spanValueStyles={{
                  className: `${isBillingHasError ? 'error_color' : 'normal_color'}`,
                }}
                value={billingDetails.TotalHours}
              />
            </div><div className='employees-form__row billing-details-block-data'>
              <NoEditableItemBlock
                label='SOW/PO Number'
                value={billingDetails.Contract}
              />
              <NoEditableItemBlock
                label='Billing Term'
                value={billingDetails.Tern}
              />
              <NoEditableItemBlock
                label='Actual Total Hours'
                value={billingDetails.ActualTotalHours}
                spanValueStyles={{
                  className: `${billingDetails.ActualTotalHours > billingDetails.TotalHours ? 'error_color' : ''}`,
                }}
              />
              <NoEditableItemBlock
                label='Approved Expenses'
                value={billingDetails.ApprovedExpences}
              />
              <NoEditableItemBlock
                label='Total Amount'
                spanValueStyles={{
                  className: `${isBillingHasError ? 'error_color' : 'normal_color'}`,
                }}
                value={billingDetails.TotalAmount}
              />
            </div>
            <div className='employees-form__row billing-details-block-data'>
              <div className='redux-form__field-container employees-form__standart-container'>
                <div className='no-edit-field__label label__question-icon--position'>
                  <label className='redux-form__label'>Travel Budget Included</label>
                </div>
                <div className='field no-editable-block--block-margin'>
                  <label className='checkbox-cont-list-item check_role'>
                    <input type='checkbox' disabled='true' checked={billingDetails.TravelBudgetIncluded} />
                    <span className='configuration-option__checkbox' />
                  </label>
                </div>
              </div>
              <div className='redux-form__field-container employees-form__standart-container'>
                <div className='no-edit-field__label label__question-icon--position'>
                  <label className='redux-form__label'>Travel Reimbursement</label>
                </div>
                <div className='field no-editable-block--block-margin'>
                  <label className='checkbox-cont-list-item check_role'>
                    <input type='checkbox' disabled='true' checked={billingDetails.TravelReimbursement} />
                    <span className='configuration-option__checkbox' />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            className='project-info__tabs'
            list={['Roles', 'Timesheets', 'Justification', 'Invoice']}
            selectedIndex={selectedTab}
            onTab={this.onTab}
            isErrorInRolesTab={billingDetails && billingDetails.Roles && billingDetails.Roles.find(item => item.Actual === '0')}
            isErrorInTimesheetsTab={billingDetails.Id === 2}
            isErrorInJustificationTab={billingDetails
              && ((billingDetails.JustificationOvertime && billingDetails.JustificationOvertime.find(item => !item.Attachment.length))
                || (billingDetails.JustificationOther && billingDetails.JustificationOther.find(item => !item.Attachment.length)))}
            isErrorInInvoiceTab={billingDetails && billingDetails.Invoice && billingDetails.Invoice.find(item => !item.Invoice.length)}
          />
          {
            selectedTab === ROLES_TAB_INDEX ? (
              <List
                columnsToDisplay={columnsToDisplay[selectedTab]}
                items={billingDetails.Roles ? sortList(billingDetails.Roles, rolesState.sortColumn, rolesState.sortDirection) : []}
                sortColumnName={rolesState.sortColumn}
                sortDirection={rolesState.sortDirection}
                setSortSettings={this.setSortSettings('roles')}
                isHaveRights={false}
                hideDots
                dontShowOptions
                viewFinancialInfo
                config={{
                  'Rate': { cell: CellBudget },
                  'TotalAmount': { cell: CellBudget },
                  'Status': { cell: CellBillingRoleCheckbox },
                }}
              />
            ) : selectedTab === JUSTIFICATION_TAB_INDEX ? (<CustomizedTreeView />) : selectedTab === JUSTIFICATION_OTHER_TAB_INDEX ? (
              <>
                {isShowJustificationOvertimeModal && <BillingJustificationModal
                  editMode={isEditJustificationOvertimeModal}
                  initData={isEditJustificationOvertimeModal
                    ? {
                      ...editJustificationOvertimeObject,
                      Employee: {
                        label: editJustificationOvertimeObject.Employee.Name,
                        value: editJustificationOvertimeObject.Employee.Name,
                        valueId: editJustificationOvertimeObject.Employee.Id,
                      },
                    }
                    : {}}
                  confirmDialog={this.setShowJustificationModal}
                  closeDialog={this.setShowJustificationModal}
                />}
                <div className='billing-justification-header'>
                  <span className='billing-justification-header-title'>
                    Overtime
                  </span>
                  {billingDetails && billingDetails.JustificationOvertime
                    ? (billingDetails && billingDetails.JustificationOvertime.find(item => !item.Attachment.length)
                      ? <HighlightOffIcon
                          style={{ width: '25px', color: 'red' }}
                      />
                      : <CheckCircleOutlineIcon
                          style={{ width: '25px', color: 'green' }}
                      />)
                    : null}
                  <div className='toolbar-button billing-justification-add-button' onClick={this.setShowJustificationModal}>
                    <i data-icon-name='plus-orange' role='presentation' aria-hidden='true' className='root-50'>
                      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='#F26939' />
                      </svg>
                    </i>
                    <span>Add Justification</span>
                  </div>
                </div>
                <List
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={billingDetails.JustificationOvertime
                    ? sortList(billingDetails.JustificationOvertime, justificationState.sortColumn, justificationState.sortDirection)
                    : []}
                  sortColumnName={justificationState.sortColumn}
                  sortDirection={justificationState.sortDirection}
                  setSortSettings={this.setSortSettings('justification')}
                  hideAddNewEntity
                  getFullEditItem
                  onEditClick={this.showEditJustificationModal}
                  onDeleteClick={this.setShowConfirmationDialog}
                  deleteConfig={this.roleDeleteConfig}
                  isHaveRights
                  config={{
                    'Billable': { cell: CellSimpleCheckbox },
                    'Attachment': { cell: CellFiles },
                  }}
                />
                {isShowJustificationOtherModal && <BillingOtherModal
                  editMode={isEditJustificationOtherModal}
                  initData={isEditJustificationOtherModal ? editJustificationOtherObject : {}}
                  confirmDialog={this.setShowJustificationOtherModal}
                  closeDialog={this.setShowJustificationOtherModal}
                />}
                <div className='billing-justification-header billing-justification-other-header'>
                  <span className='billing-justification-header-title'>
                    Other
                  </span>
                  {billingDetails && billingDetails.JustificationOther
                    ? (billingDetails && billingDetails.JustificationOther.find(item => !item.Attachment.length)
                      ? <HighlightOffIcon
                          style={{ width: '25px', color: 'red' }}
                      />
                      : <CheckCircleOutlineIcon
                          style={{ width: '25px', color: 'green' }}
                      />)
                    : null}
                  <div className='toolbar-button billing-justification-add-button' onClick={this.setShowJustificationOtherModal}>
                    <i data-icon-name='plus-orange' role='presentation' aria-hidden='true' className='root-50'>
                      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='#F26939' />
                      </svg>
                    </i>
                    <span>Add Justification</span>
                  </div>
                </div>
                <List
                  columnsToDisplay={columnsToDisplayJustificationOther}
                  items={billingDetails.JustificationOther
                    ? sortList(billingDetails.JustificationOther, justificationOtherState.sortColumn, justificationOtherState.sortDirection)
                    : []}
                  sortColumnName={justificationOtherState.sortColumn}
                  sortDirection={justificationOtherState.sortDirection}
                  setSortSettings={this.setSortSettings('justificationOther')}
                  hideAddNewEntity
                  onEditClick={this.showEditJustificationOtherModal}
                  getFullEditItem
                  onDeleteClick={this.setShowConfirmationDialog}
                  deleteConfig={this.roleDeleteConfig}
                  isHaveRights
                  viewFinancialInfo
                  config={{
                    'Amount': { cell: CellBudget },
                    'Billable': { cell: CellSimpleCheckbox },
                    'Attachment': { cell: CellFiles },
                  }}
                />
              </>
            ) : selectedTab === INVOICE_TAB_INDEX ? (
              <>
                {
                  isShowInvoiceModal && <InvoiceModal
                    editMode={isEditInvoiceModal}
                    initData={isEditInvoiceModal ? editInvoiceObject : { ActualAmountPaid: 0 }}
                    confirmDialog={this.setShowInvoiceModal}
                    closeDialog={this.setShowInvoiceModal}
                  />
                }
                <div className='billing-invoice-header'>
                  <div className='toolbar-button billing-justification-add-button' onClick={this.setShowInvoiceModal}>
                    <i data-icon-name='plus-orange' role='presentation' aria-hidden='true' className='root-50'>
                      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='#F26939' />
                      </svg>
                    </i>
                    <span>Add Invoice</span>
                  </div>
                </div>
                <List
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={billingDetails.Invoice
                    ? sortList(billingDetails.Invoice, invoiceState.sortColumn, invoiceState.sortDirection)
                    : []}
                  sortColumnName={invoiceState.sortColumn}
                  sortDirection={invoiceState.sortDirection}
                  setSortSettings={this.setSortSettings('invoice')}
                  hideAddNewEntity
                  getFullEditItem
                  onEditClick={this.showEditInvoiceModal}
                  onDeleteClick={this.setShowConfirmationDialog}
                  deleteConfig={this.roleDeleteConfig}
                  isHaveRights
                  config={{
                    'Invoice': { cell: CellFiles },
                  }}
                />
              </>
            ) : null
          }
        </div>
      </Scrollbars>
    );
  }
}

BillingDetails.propTypes = {
  selectedTab: PropTypes.number,
  listSettings: PropTypes.object,
  setListSettings: PropTypes.func,
};

export default connect(store => ({
  billingDetails: store.billingReducer.billingDetails,
  listSettings: store.billingReducer.listSettings,
  selectedTab: store.selectedTabReducer.selectedTab,
  settings: store.adminReducer.settings,
  isHistoryLoad: store.projectsReducer.isHistoryLoad,
}), {
  getSelectedTab,
  setComponent,
  resetComponent,
  setListSettings,
})(BillingDetails);
