export const GET_MANAGE_PROJECT_TYPES = '@admin/GET_MANAGE_PROJECT_TYPES';
export const SET_MANAGE_PROJECT_TYPES = '@admin/SET_MANAGE_PROJECT_TYPES';
export const PUT_MANAGE_PROJECT_TYPES = '@admin/PUT_MANAGE_PROJECT_TYPES';
export const POST_MANAGE_PROJECT_TYPES = '@admin/POST_MANAGE_PROJECT_TYPES';

export const downloadManageProjectTypes = () => ({
  type: GET_MANAGE_PROJECT_TYPES,
});

export const setManageProjectTypes = (types) => ({
  type: SET_MANAGE_PROJECT_TYPES,
  payload: types,
});

export const putManageProjectTypes = (types) => ({
  type: PUT_MANAGE_PROJECT_TYPES,
  payload: types,
});

export const postManageProjectTypes = (newType) => ({
  type: POST_MANAGE_PROJECT_TYPES,
  payload: newType,
});
