import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './tabs.css';
import CustomIcon from '../icon/Icon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const Tabs = ({
  list, selectedIndex, onTab, className, timeLeft, rolesTimeLeft,
  isErrorInRolesTab, isErrorInTimesheetsTab, isErrorInJustificationTab, isErrorInInvoiceTab,
  listToDisplay,
}) => (<div className={classNames('c-tabs', className)}>
  {
    list.map((title, i) => (
      <span
        key={i}
        className={classNames('c-tabs__title', {
          'c-tabs__title--selected': selectedIndex === i,
          'c-tabs__hidden': Array.isArray(listToDisplay) && !listToDisplay.includes(title),
        })}
        onClick={onTab.bind(this, i)}
      >
        <span className='c-tabs__title__text'>{title}</span>
        {title === 'Contracts' && timeLeft ? <CustomIcon iconName='red-clock' className='highlight-tab' /> : null}
        {title === 'Roles' && rolesTimeLeft ? <CustomIcon iconName='red-clock' className='highlight-tab' /> : null}
        {title === 'Roles' && isErrorInRolesTab ? <HighlightOffIcon style={{ width: '15px', color: 'red', marginLeft: '3px' }} /> : null}
        {title === 'Timesheets' && isErrorInTimesheetsTab ? <HighlightOffIcon style={{ width: '15px', color: 'red', marginLeft: '3px' }} /> : null}
        {title === 'Justification' && isErrorInJustificationTab ? <HighlightOffIcon style={{ width: '15px', color: 'red', marginLeft: '3px' }} /> : null}
        {title === 'Invoice' && isErrorInInvoiceTab ? <HighlightOffIcon style={{ width: '15px', color: 'red', marginLeft: '3px' }} /> : null}
      </span>
    ))
  }
</div>);

Tabs.propTypes = {
  list: PropTypes.array,
  selectedIndex: PropTypes.number,
  onTab: PropTypes.func,
  className: PropTypes.string,
};

export default Tabs;
