import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmationHeader, useConfirmationMessage, useUserSelector } from './manage-user-confirmation.hooks';
import { closeManageUserConfirmation, confirmUserChanges, requestConflictingUserManagerialInfo } from '../../actions/editUserConfirmationActions';
import { ConfirmationDialog } from '../../../../common/components/confirmation-dialog/confirmation-dialog';

const ManageUserConfirmation = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.manageUserConfirmation.isOpen);
  const user = useUserSelector();
  const confirmationMessage = useConfirmationMessage(user);
  const confirmationHeader = useConfirmationHeader();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);

  useEffect(() => {
    if (isOpen && user) {
      dispatch(requestConflictingUserManagerialInfo(user));
    }
  }, [isOpen, user]);

  if (!isOpen || !user || isLoading) {
    return null;
  }

  const handleCloseDialog = () => dispatch(closeManageUserConfirmation());
  const handleConfirmDialog = () => dispatch(confirmUserChanges());

  return (
    <ConfirmationDialog
      dialogHeader={confirmationHeader}
      dialogTitle={confirmationMessage}
      closeButtonTitle='Cancel'
      confirmButtonTitle='Save'
      isNeedWhiteSpace
      autoWidth
      closeDialog={handleCloseDialog}
      confirmDialog={handleConfirmDialog}
    />
  );
};

export default ManageUserConfirmation;
