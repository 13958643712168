import { SET_DOCUMENT_STATUSES, SET_FILTERS, SET_FILTERS_CERTIFICATES } from '../actions/filtersActions';
import { SET_CLIENT } from '../actions/addEntityActions';

const initialState = {
  filters: {
    Offices: [],
    OfficesByScope: [],
    Clients: [],
    ProjectStatuses: [],
    ProjectTypes: [],
    AccountManagers: [],
    DeliveryManagers: [],
    EngineeringManagers: [],
    ClientManagers: [],
    Projects: [],
    Positions: [],
    EmployeeStatuses: [],
    EmployeeStatusesNew: [],
    JobTitles: [],
    TeamPositions: [],
    BusinessTrips: [],
    Alerts: [],
    updateTypeEntity: [
      { Id: 'Client', Name: 'Client' },
      { Id: 'Contract', Name: 'Contract' },
      { Id: 'Employee', Name: 'Employee' },
      { Id: 'Project', Name: 'Project' },
      { Id: 'Office', Name: 'Office' },
      { Id: 'User', Name: 'User' },
    ],
    EmployeeAssignmentStatuses: [],
    Certificates: [],
    documentStatuses: [],
    BillableStatuses: [],
    RoleSeniorities: [],
    LatestContractDate: [],
    BillableProjects: [],
    BusinessLocations: [],
    TeamCompositionReportTypes: [],
    TeamCompositionProjects: [],
    Trainings: [],
  },
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };
    case SET_CLIENT:
      return {
        ...state,
        filters: {
          ...state.filters,
          Clients: [...state.filters.Clients, {
            Id: action.payload.Id,
            Name: action.payload.Name,
          }],
        },
      };
    case SET_FILTERS_CERTIFICATES:
      return {
        ...state,
        filters: {
          ...state.filters,
          Certificates: action.payload,
        },
      };
    case SET_DOCUMENT_STATUSES:
      return {
        ...state,
        filters: {
          ...state.filters,
          documentStatuses: action.payload,
        },
      };
    default:
      return state;
  }
}
