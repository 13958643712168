import React, { Fragment } from 'react';
import {
  change, destroy, Field, initialize, reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import isObjectNotEquals from 'utils/object-comparison';
import { changeEmployeeBasicInfo } from 'common/actions/reduxFormActions';
import {
  deleteEmployee,
  updateEmployeeOfficeBeforeRelocate,
} from 'pages/members/actions/membersActions';
import CustomIcon from 'common/components/icon/Icon';
import renderTogglingSearchDropdown from '../components/toggling/toggling-search-dropdown';
import renderTogglingDatePicker from '../components/toggling/toggling-date-picker';
import renderTogglingTextField from '../components/toggling/toggling-text-field';
import renderTogglingSelect from '../components/toggling/toggling-select';
import { getCorrectSkype } from 'services/getCorrectSkype.js';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { required } from 'services/redux-form-validators.js';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from 'common/components/redux-form/components/button';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog';
import {
  ADMIN_ROLE_NAME,
  DELIVARY_MANAGER_ROLE_NAME,
  EMPLOYEES_STATUS_FORMER_ID,
  ENGINEERING_MANAGER_ROLE_NAME,
  HR_ROLE_NAME,
  OFFICE_DIRECTOR_ROLE_NAME,
  PROJECT_ROLES_EMPLOYEES_FORMER,
  PROJECT_ROLES_STATUS_ACTIVE,
  PROJECT_ROLES_STATUS_PROSPECTIVE,
} from 'utils/const-variable';
import DatePicker from 'common/components/form-items/date-picker/date-picker';

import './employee-basic-info-form.css';

const defaultStyles = {
  container: 'redux-form__field-container employees-form__standart-container ',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const listOfFields = [
  'Cell',
  'Office',
  'DateOfBirth',
  'Status',
  'JobTitleId',
  'BusinessTrip',
  'Skype',
  'Location',
  'BusinessLocation',
  'OfficeBeforeRelocate',
  'StartDateInCompany',
  'LeavingNoticeDate',
  'DateOfDismissal',
  'PlannedLeavingDate',
  'BackFillType',
  'BackFillDate',
  'BackFillLink',
  'ResourceManager',
  'HrManager',
  'Notes',
]

const validateCell = (value) => {
  const isValidPhoneNumber = /^[.+](\d[ ]?)+(\s*)?$/;
  return value && !isValidPhoneNumber.test(value.trim()) ? 'Invalid Cell' : false;
};

const validSkype = (value) => {
  if (value) {
    const result = getCorrectSkype(value);
    return (result && result.valid) ? false : 'Invalid Skype';
  }
  return false;
};

class EmployeeBasicInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelete: false,
      isValidDelivery: true,
      isValidEngineer: true,
      isValidAccount: true,
      checkValidDeliveryWithoutRequest: false,
      checkValidEngineerWithoutRequest: false,
      copyMessage: 'Copy into clipboard',
      isDialogOpen: false,
      showChangeStatusDialog: false,
      prevStatus: {},
      effectiveDate: moment(),
      showChangeBusinessLocationDialog: false,
      prevBusinessLocation: {},
      unsavedFields: [],
    };
  }

  componentDidMount() {
    const {
      data, initialize, valid, handleFormError,
    } = this.props;
    const employeeData = {
      ...data,
      JobTitleId: (data.JobTitle && data.JobTitle.Id) || '',
      JobTitleName: (data.JobTitle && data.JobTitle.Name) || '',
      OfficeBeforeRelocate: data.OfficeBeforeRelocate || '',
    };
    initialize(employeeData);

    if (this.getCanEdit() && !valid) {
      handleFormError(true);
    }

    if (valid) {
      handleFormError(false);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      data, initialize, valid, handleFormError,
    } = this.props;
    if (isObjectNotEquals(prevProps.data, data)) {
      const employeeData = {
        ...data,
        JobTitleId: (data.JobTitle && data.JobTitle.Id) || '',
        JobTitleName: (data.JobTitle && data.JobTitle.Name) || '',
        OfficeBeforeRelocate: data.OfficeBeforeRelocate || '',
      };
      initialize(employeeData);
    }

    if (this.getCanEdit() && prevProps.valid !== valid) {
      handleFormError(true);
    }

    if (!prevProps.valid && valid) {
      handleFormError(false);
      this.setState({unsavedFields: []});
    }

    if (isObjectNotEquals(prevProps.data, data) && valid) {
      this.setState({unsavedFields: []});
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  onChange = (data, value, prevValue, field) => {
    switch (field) {
      case 'JobTitleId':
        this.changeJobTitle(value);
        this.onBlur();
        this.checkSaving(value, field);
        break;
      case 'Office':
        this.changeOffice(value);
        this.onBlur();
        this.checkSaving(value, field);
        break;
      case 'Location':
        this.onBlur(true);
        this.checkSaving(value, field);
        break;
      case 'Status':
        this.changeStatus(value, prevValue);
        this.checkSaving(value, field);
        break;
      case 'BusinessLocation':
        this.changeBusinessLocation(prevValue);
        this.checkSaving(value, field);
        break;
      case 'HrManager':
        this.changeHrManager(value);
        this.checkSaving(value, field);
        break;
      case 'ResourceManager':
        this.changeResourceManager(value);
        this.checkSaving(value, field);
        break;
      case 'Cell':
      case 'DateOfBirth':
      case 'OfficeBeforeRelocate':
      case 'BusinessTrip':
      case 'Skype':
      case 'StartDateInCompany':
      case 'DateOfDismissal':
      case 'Relocated':
      case 'LeavingNoticeDate':
      case 'PlannedLeavingDate':
      case 'BackFillType':
      case 'BackFillDate':
      case 'BackFillLink':
      case 'Notes':
        this.onBlur();
        this.checkSaving(value, field);
        break;
      default: break;
    }
  }

  changeJobTitle = (value) => {
    const {
      change, allJobTitles,
    } = this.props;
    if (value.length) {
      const newValue = (allOptions) => allOptions.find(item => item.Id === value).Name;
      change('JobTitleName', newValue(allJobTitles));
    } else {
      change('JobTitleName', '');
    }
  }

  changeOffice = (office) => {
    const {
      change, OfficeBeforeRelocate, BusinessLocation,
    } = this.props;
    (office.Id === OfficeBeforeRelocate.Id) && change('OfficeBeforeRelocate', null);
    change('Location', null);
    if (BusinessLocation && !BusinessLocation.IsPersonal) {
      change('BusinessLocation', null);
    }
    change('ResourceManager', null);
    change('HrManager', null);
  }

  changeStatus = (value, prevValue) => {
    const { Positions } = this.props.data;
    if (value.Name === PROJECT_ROLES_EMPLOYEES_FORMER
      && Positions.find(pos => pos.Status === PROJECT_ROLES_STATUS_ACTIVE || pos.Status === PROJECT_ROLES_STATUS_PROSPECTIVE)) {
      this.setState({
        showChangeStatusDialog: true,
        prevStatus: prevValue,
      });
    } else {
      this.onBlur();
    }
  }

  changeBusinessLocation = (prevValue) => {
    this.setState({
      showChangeBusinessLocationDialog: true,
      prevBusinessLocation: prevValue,
    });
  }

  changeBusinessLocationCancel = () => {
    const { change } = this.props;
    const { prevBusinessLocation } = this.state;
    this.setState({
      showChangeBusinessLocationDialog: false,
    });
    change('BusinessLocation', prevBusinessLocation);
  }

  changeBusinessLocationApply = () => {
    this.setState({
      showChangeBusinessLocationDialog: false,
    });
    this.onBlur();
  }

  changeResourceManager = (value) => {
    const {
      change, resourceManagers, HrManager, changeEmployeeBasicInfo,
    } = this.props;

    const resourceManager = resourceManagers && resourceManagers.find(item => item.Id === value);
    change('ResourceManager', resourceManager);

    if (HrManager) {
      changeEmployeeBasicInfo(null, moment().year());
    } else {
      this.onBlur();
    }
  }

  changeHrManager = (value) => {
    const {
      change, hrManagers, ResourceManager, changeEmployeeBasicInfo,
    } = this.props;

    const hrManager = hrManagers && hrManagers.find(item => item.Id === value);
    change('HrManager', hrManager);

    if (ResourceManager) {
      changeEmployeeBasicInfo(null, moment().year());
    } else {
      this.onBlur();
    }
  }

  onBlur = (event, effectiveDate = null) => {
    const {
      changeEmployeeBasicInfo, valid,
    } = this.props;
    if (!valid && !event) {
      return false;
    }
    changeEmployeeBasicInfo(effectiveDate, moment().year());
  }

  cellQuestionTitle = () => {
    return (
      <CustomIcon
        iconName='question-mark'
        className='question-mark'
        title='Phone number must be started with +'
      />
    );
  }

  leavingNoticeQuestionTitle = () => {
    const { data } = this.props;
    const fillingDate = data.LeavingNoticeFillingDate
      ? moment(data.LeavingNoticeFillingDate).format('DD/MM/YYYY')
      : moment().format('DD/MM/YYYY');
    return (
      <CustomIcon
        iconName='question-mark'
        className='question-mark'
        title={`Filled: ${fillingDate}`}
      />
    );
  }

  onEmailCopyClickHandler = async () => {
    const { Email } = this.props;
    let copyMessage = '';
    try {
      await navigator.clipboard.writeText(Email);
      copyMessage = 'Copied into clipboard';
    } catch {
      copyMessage = 'Could not copy into clipboard';
    } finally {
      this.setState({
        copyMessage,
      });
    }
  }

  onEmailTooltipCloseHandler = () => {
    this.setState({
      copyMessage: 'Copy into clipboard',
    });
  }

  handleDialogConfirm = () => {
    const { Id, deleteEmployee } = this.props;
    deleteEmployee(Id);
    this.setState({
      isDialogOpen: false,
    });
  }

  handleChangeStatusDialogConfirm = () => {
    const { effectiveDate } = this.state;
    this.setState({
      showChangeStatusDialog: false,
    });
    this.onBlur(false, effectiveDate);
  }

  handleChangeStatusDialogClose = () => {
    const { change } = this.props;
    const { prevStatus } = this.state;

    change('Status', prevStatus);
    this.setState({
      showChangeStatusDialog: false,
    });
  }

  onChangeEffectiveDate = (value) => {
    this.setState({
      effectiveDate: value,
    });
  }

  getChangeStatusDialogTitle = () => {
    const { Name, data } = this.props;
    const { effectiveDate } = this.state;

    const activeAssignments = data.Positions.filter(pos => pos.Status === PROJECT_ROLES_STATUS_ACTIVE || pos.Status === PROJECT_ROLES_STATUS_PROSPECTIVE);

    const title = <div>
      {`${Name} has ${activeAssignments.length} active project assignments at the moment:`}
      <ul>
        {activeAssignments.map((assignment, index) => <li key={index}>{assignment.Project} - {assignment.RoleName}</li>)}
      </ul>
      <span className='change-status-dialog--confirmation'>
        {`Do you want to remove ${Name} from all active project assignments with effective date as `}
        <DatePicker
          formClassName='change-status-dialog--date'
          placeholder='Date'
          value={effectiveDate}
          onChange={this.onChangeEffectiveDate}
          isClearable={false}
        />
        ?
      </span>
    </div>;

    return title;
  }

  getChangeBusinessLocationDialogTitle = () => {
    return 'You are going to change the Business Location of an employee, please note that list of National Holidays of the employee will be changed according to the new Business Location';
  }

  getLocationToShow = () => {
    const { baseBusinessLocations, Location } = this.props;

    const location = baseBusinessLocations.find(bl => bl.Id === Location);

    return Location && Location.Name || (location && location.Name) || '';
  }

  getResourceManagerToShow = () => {
    const { resourceManagers, ResourceManager } = this.props;

    const resourceManager = resourceManagers.find(rm => rm.Id === ResourceManager);

    return ResourceManager && ResourceManager.Name || (resourceManager && resourceManager.Name) || '';
  }

  getHrManagerToShow = () => {
    const { hrManagers, HrManager } = this.props;

    const hrManager = hrManagers.find(hr => hr.Id === HrManager);

    return HrManager && HrManager.Name || (hrManager && hrManager.Name) || '';
  }

  getOfficeBusinessLocations = () => {
    const { Office, businessLocations } = this.props;
    const officeBusinessLocations = businessLocations && businessLocations.find(bl => Office && bl.Office && bl.Office.Id === Office.Id);

    return officeBusinessLocations && officeBusinessLocations.BusinessLocations;
  }

  getBusinessLocationToShow = () => {
    const { BusinessLocation } = this.props;
    const officeBusinessLocations = this.getOfficeBusinessLocations();

    const businessLocation = officeBusinessLocations && officeBusinessLocations.find(bl => bl.Id === BusinessLocation);

    return BusinessLocation && BusinessLocation.Name || (businessLocation && businessLocation.Name) || '';
  }

  getCanEdit = () => {
    const { canEditCurrentEmployee, shouldRestrictInformation } = this.props;

    return canEditCurrentEmployee && !shouldRestrictInformation;
  }

  shouldShowDateOfDismissal = () => {
    const {
      DateOfDismissal, PlannedLeavingDate, LeavingNoticeDate, Status,
    } = this.props;

    // return (DateOfDismissal
    //   && ((PlannedLeavingDate && LeavingNoticeDate && (moment(PlannedLeavingDate).diff(moment(), 'days') <= 0)))
    //     || (Status && Status.Id === EMPLOYEES_STATUS_FORMER_ID));
    return true;
  }

  checkSaving = (value, field) => {
    const { data } = this.props;
    const { unsavedFields } = this.state;
    const requiredMessage = required(value, [], this.props, field);
    const isRequired = listOfFields.indexOf(field) >= 0;
    const isValid = !!(isRequired && data[field] === value);

    if (isRequired && !isValid && !requiredMessage) {
      this.setState({ unsavedFields: [...unsavedFields, field] });
    } else {
      this.setState({ unsavedFields: unsavedFields.filter(value => value !== field) });
    }
  }

  render() {
    const {
      isAdmin, BusinessTrip, Cell, DateOfBirth, DateOfDismissal, Email,
      Location, Notes, Office, Skype, StartDateInCompany, Status, handleSubmit, JobTitleId, JobTitleName, OfficeBeforeRelocate,
      allOffices, allStatuses, allJobTitles, allBusinessTrip, Name, data, UserRoles,
      BusinessLocation, baseBusinessLocations, resourceManagers, hrManagers, ResourceManager, HrManager, shouldRestrictInformation,
      LeavingNoticeDate, PlannedLeavingDate, BackFillType, BackFillDate, BackFillLink, hasBackFill, allBackFillTypes,
    } = this.props;
    const {
      copyMessage, isDialogOpen, showChangeStatusDialog, showChangeBusinessLocationDialog,
      unsavedFields,
    } = this.state;
    const allOfficeForRelocated = allOffices.filter(locationOption => locationOption.Id !== (Office && Office.Id));
    const businessLocationsToShow = this.getOfficeBusinessLocations();

    return (
      <>
        {isDialogOpen
        && <ConfirmationDialog
          dialogHeader='Delete Employee'
          dialogTitle={`Do you really want to delete ${Name} from Employees list? This action cannot be undone.`}
          closeButtonTitle='Cancel'
          confirmButtonTitle='Yes'
          closeDialog={() => this.setState({ isDialogOpen: false })}
          confirmDialog={this.handleDialogConfirm}
        />}
        {showChangeStatusDialog
          && <ConfirmationDialog
            dialogHeader='Warning'
            dialogTitle={this.getChangeStatusDialogTitle()}
            closeButtonTitle='No'
            confirmButtonTitle='Yes'
            isNeedWhiteSpace
            autoWidth
            closeDialog={this.handleChangeStatusDialogClose}
            confirmDialog={this.handleChangeStatusDialogConfirm}
          />}
        {showChangeBusinessLocationDialog
          && <ConfirmationDialog
            dialogHeader='Warning'
            dialogTitle={this.getChangeBusinessLocationDialogTitle()}
            closeButtonTitle='Cancel'
            confirmButtonTitle='Save'
            closeDialog={this.changeBusinessLocationCancel}
            confirmDialog={this.changeBusinessLocationApply}
          />}
        <form className='redux-form' onSubmit={handleSubmit}>
          <div className='employees-form__row employees-form__row--nargin-top'>
            <div className='redux-form__field-container employees-form__standart-container '>
              <label className='redux-form__label'>Email</label>
              <div className='field'>
                <div className='field__view-container'>
                  <div className='field__view-content standart-content-padding field--not--editable'>
                    <span>{Email}</span>
                    <Tooltip onClose={this.onEmailTooltipCloseHandler} title={<Typography variant='caption'>{copyMessage}</Typography>}>
                      <IconButton aria-label='copy into clipboard' size='small' onClick={this.onEmailCopyClickHandler}>
                        <FileCopyIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {!shouldRestrictInformation && <Field
              name='Cell'
              value={Cell}
              component={renderTogglingTextField}
              onChange={this.onChange}
              onBlur={this.onBlur}
              isEditable={this.getCanEdit()}
              valueToShow={Cell}
              label='Cell'
              lableIcon={this.cellQuestionTitle}
              placeholder='Set Cell'
              autoFocus
              maxlength='25'
              styles={defaultStyles}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
              validate={validateCell}
              isSaved={!unsavedFields.includes('Cell')}
            />}
            <Field
              name='Office'
              value={Office}
              options={allOffices}
              component={renderTogglingSelect}
              label='Office'
              onChange={this.onChange}
              isEditable={isAdmin}
              valueToShow={Office && Office.Name}
              isOptionObject
              showClear={false}
              inputProps={{ id: 'office' }}
              styles={defaultStyles}
              validate={required}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
              showAsterixOnFocus
              isSaved={!unsavedFields.includes('Office')}
            />
            {!shouldRestrictInformation
            && <>
              <Field
                name='DateOfBirth'
                value={DateOfBirth}
                component={renderTogglingDatePicker}
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                setPrevValueOnError
                label='Date Of Birth'
                styles={defaultStyles}
                minDate={moment().add(-100, 'year').month(0).date(1)}
                maxDate={moment().add(-1, 'days')}
                showClear
                isSaved={!unsavedFields.includes('DateOfBirth')}
              />
              <Field
                name='Status'
                value={Status}
                options={allStatuses}
                component={renderTogglingSelect}
                label='Type'
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                valueToShow={Status && Status.Name}
                isOptionObject
                inputProps={{ id: 'type' }}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                isSaved={!unsavedFields.includes('Status')}
              />
              <Field
                name='JobTitleId'
                value={JobTitleId}
                options={allJobTitles}
                component={renderTogglingSearchDropdown}
                onChange={this.onChange}
                onBlur={this.onBlur}
                isEditable={this.getCanEdit()}
                valueToShow={JobTitleName}
                inputProps={{ id: 'jobTitle' }}
                label='Job Title'
                showClear
                autoFocus
                isAddingFeature={false}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                dontShowBage
                isSaved={!unsavedFields.includes('JobTitleId')}
              />
              <Field
                name='BusinessTrip'
                value={BusinessTrip}
                options={allBusinessTrip}
                component={renderTogglingSelect}
                label='Business Trip'
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                valueToShow={BusinessTrip && BusinessTrip.Name}
                isOptionObject
                showClear
                inputProps={{ id: 'businessTrip' }}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                isSaved={!unsavedFields.includes('BusinessTrip')}
              />
              <Field
                name='Skype'
                value={Skype}
                component={renderTogglingTextField}
                onChange={this.onChange}
                onBlur={this.onBlur}
                isEditable={this.getCanEdit()}
                valueToShow={Skype}
                label='Skype'
                placeholder='Set Skype'
                autoFocus
                maxlength='32'
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                validate={validSkype}
                showClear
                isSaved={!unsavedFields.includes('Skype')}
              />
            </>}
            <Field
              name='Location'
              value={Location}
              options={baseBusinessLocations}
              component={renderTogglingSearchDropdown}
              label='Location'
              placeholder='Choose Location'
              onChange={this.onChange}
              isEditable={this.getCanEdit()}
              valueToShow={this.getLocationToShow()}
              isOptionObject
              showClear={false}
              inputProps={{ id: 'location' }}
              styles={defaultStyles}
              validate={required}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
              showAsterixOnFocus
              showValueRequired
              isSaved={!unsavedFields.includes('Location')}
            />
            {!shouldRestrictInformation
            && <>
              <Field
                name='BusinessLocation'
                value={BusinessLocation}
                options={businessLocationsToShow || []}
                component={renderTogglingSearchDropdown}
                label='Business Location'
                placeholder='Choose Business Location'
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                valueToShow={this.getBusinessLocationToShow()}
                isOptionObject
                showClear={false}
                inputProps={{ id: 'businessLocation' }}
                styles={defaultStyles}
                validate={required}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                showAsterixOnFocus
                showInfoTooltip={BusinessLocation && BusinessLocation.IsPersonal}
                showInfoTooltipMessage='Employee has personal public holidays which might  be different from the Business Location'
                showValueRequired
                isSaved={!unsavedFields.includes('BusinessLocation')}
              />
              <Field
                name='OfficeBeforeRelocate'
                value={OfficeBeforeRelocate}
                options={allOfficeForRelocated}
                component={renderTogglingSelect}
                onChange={this.onChange}
                onBlur={this.onBlur}
                isEditable={this.getCanEdit()}
                valueToShow={OfficeBeforeRelocate && OfficeBeforeRelocate.Name}
                inputProps={{ id: 'relocated' }}
                label='Relocated From'
                isOptionObject
                showClear
                autoFocus
                isAddingFeature={false}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                dontShowBage
                isSaved={!unsavedFields.includes('OfficeBeforeRelocate')}
              />
              <Field
                name='StartDateInCompany'
                value={StartDateInCompany}
                component={renderTogglingDatePicker}
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                setPrevValueOnError
                label='Start Date In Company'
                styles={defaultStyles}
                showClear
                minDate={moment().add(-50, 'year').month(0).date(1)}
                isSaved={!unsavedFields.includes('StartDateInCompany')}
              />
              {/* <Field */}
              {/*   name='LeavingNoticeDate' */}
              {/*   value={LeavingNoticeDate} */}
              {/*   component={renderTogglingDatePicker} */}
              {/*   onChange={this.onChange} */}
              {/*   isEditable={this.getCanEdit()} */}
              {/*   setPrevValueOnError */}
              {/*   label='Leaving Notice Date' */}
              {/*   labelIcon={LeavingNoticeDate && this.leavingNoticeQuestionTitle} */}
              {/*   styles={defaultStyles} */}
              {/*   showClear */}
              {/*   confirmClearing */}
              {/*   isClearable={false} */}
              {/*   minDate={StartDateInCompany && moment(StartDateInCompany)} */}
              {/*   maxDate={moment()} */}
              {/*   isSaved={!unsavedFields.includes('LeavingNoticeDate')} */}
              {/* /> */}
              {this.shouldShowDateOfDismissal() && <Field
                name='DateOfDismissal'
                value={DateOfDismissal}
                component={renderTogglingDatePicker}
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                setPrevValueOnError
                label='Date Of Dismissal'
                styles={defaultStyles}
                minDate={StartDateInCompany && moment(StartDateInCompany)}
                isSaved={!unsavedFields.includes('DateOfDismissal')}
                showClear
              />}
              {!this.shouldShowDateOfDismissal() && LeavingNoticeDate && <Field
                name='PlannedLeavingDate'
                value={PlannedLeavingDate}
                component={renderTogglingDatePicker}
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                setPrevValueOnError
                label='Planned Leaving Date'
                styles={defaultStyles}
                showClear
                minDate={LeavingNoticeDate && moment(LeavingNoticeDate)}
                validate={required}
                showAsterixOnFocus
                showValueRequired
                isSaved={!unsavedFields.includes('PlannedLeavingDate')}
              />}
              {LeavingNoticeDate && hasBackFill && <>
                <Field
                  name='BackFillType'
                  value={BackFillType}
                  options={allBackFillTypes}
                  component={renderTogglingSelect}
                  label='Backfill Type'
                  onChange={this.onChange}
                  isEditable={this.getCanEdit()}
                  valueToShow={BackFillType && BackFillType.Name}
                  isOptionObject
                  showClear
                  inputProps={{ id: 'backFillType' }}
                  styles={defaultStyles}
                  formClassName='change-border__input__project'
                  inputClassName='change-name__input'
                  isSaved={!unsavedFields.includes('BackFillType')}
                />
                <Field
                  name='BackFillDate'
                  value={BackFillDate}
                  component={renderTogglingDatePicker}
                  onChange={this.onChange}
                  isEditable={this.getCanEdit()}
                  setPrevValueOnError
                  label='Backfill date'
                  styles={defaultStyles}
                  showClear
                  minDate={PlannedLeavingDate && moment(PlannedLeavingDate)}
                  isSaved={!unsavedFields.includes('BackFillDate')}
                />
                <Field
                  name='BackFillLink'
                  value={BackFillLink}
                  component={renderTogglingTextField}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  isEditable={this.getCanEdit()}
                  valueToShow={BackFillLink}
                  label='Backfill Link'
                  styles={defaultStyles}
                  isSaved={!unsavedFields.includes('BackFillLink')}
                />
              </>}
              <Field
                name='ResourceManager'
                value={ResourceManager}
                options={resourceManagers}
                component={renderTogglingSearchDropdown}
                label='Direct Manager'
                placeholder='Choose Direct Manager'
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                valueToShow={this.getResourceManagerToShow()}
                isOptionObject
                showClear={false}
                inputProps={{ id: 'resourceManager' }}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                validate={required}
                showAsterixOnFocus
                showValueRequired
                useVirtualSelect
                isSaved={!unsavedFields.includes('ResourceManager')}
                hideOnMouseLeave={false}
              />
              <Field
                name='HrManager'
                value={HrManager}
                options={hrManagers}
                component={renderTogglingSearchDropdown}
                label='HR Manager'
                placeholder='Choose HR Manager'
                onChange={this.onChange}
                isEditable={this.getCanEdit()}
                valueToShow={this.getHrManagerToShow()}
                isOptionObject
                showClear={false}
                inputProps={{ id: 'hrManager' }}
                styles={defaultStyles}
                formClassName='change-border__input__project'
                inputClassName='change-name__input'
                validate={required}
                showAsterixOnFocus
                showValueRequired
                useVirtualSelect
                isSaved={!unsavedFields.includes('HrManager')}
                hideOnMouseLeave={false}
              />
            </>}
          </div>
          {!shouldRestrictInformation && <div className='employees-form__row'>
            <Field
              name='Notes'
              value={Notes}
              component={renderTogglingTextField}
              onChange={this.onChange}
              onBlur={this.onBlur}
              isEditable={this.getCanEdit()}
              valueToShow={Notes}
              label='Notes'
              placeholder='Set Notes'
              maxlength='4000'
              autoFocus
              multiline
              fullWidth
              styles={{
                ...defaultStyles,
                container: 'redux-form__field-container flexible-container',
                field: 'full-width-field height-100pc',
                fieldContent: 'standart-content-padding align-center height-100pc',
                fieldView: 'field__view-container height-100pc',
              }}
              formClassName='change-border__input__project'
              inputClassName='change-name__input'
              isSaved={!unsavedFields.includes('Notes')}
            />
          </div>}
          {data.IsDeletable
            && [ADMIN_ROLE_NAME,
              HR_ROLE_NAME,
              OFFICE_DIRECTOR_ROLE_NAME,
              ENGINEERING_MANAGER_ROLE_NAME,
              DELIVARY_MANAGER_ROLE_NAME].some((role) => UserRoles.includes(role))
            && <div className='delete-button-container'>
              <Button
                text='Delete Employee'
                className='button delete-button'
                onClick={() => this.setState({ isDialogOpen: true })}
              />
            </div>}
        </form>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initialize: data => dispatch(initialize('employeeBasicInfoForm', data)),
    destroy: () => dispatch(destroy('employeeBasicInfoForm')),
    change: (field, value) => dispatch(change('employeeBasicInfoForm', field, value)),
    changeEmployeeBasicInfo: (effectiveDate, year) => dispatch(changeEmployeeBasicInfo(effectiveDate, year)),
    showAlertDialog: (massage) => dispatch(showAlertDialog(massage)),
    updateEmployeeOfficeBeforeRelocate: (Id, newLocationId) => dispatch(updateEmployeeOfficeBeforeRelocate(Id, newLocationId)),
    deleteEmployee: (employeeId) => dispatch(deleteEmployee(employeeId)),
  };
};

export default connect(state => {
  const isFormReducerExists = state.form.employeeBasicInfoForm && state.form.employeeBasicInfoForm.values;
  return {
    BusinessTrip: isFormReducerExists ? state.form.employeeBasicInfoForm.values.BusinessTrip : '',
    Cell: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Cell : '',
    DateOfBirth: isFormReducerExists ? state.form.employeeBasicInfoForm.values.DateOfBirth : '',
    DateOfDismissal: isFormReducerExists ? state.form.employeeBasicInfoForm.values.DateOfDismissal : '',
    Email: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Email : '',
    Id: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Id : '',
    JobTitleId: isFormReducerExists ? state.form.employeeBasicInfoForm.values.JobTitleId : '',
    JobTitleName: isFormReducerExists ? state.form.employeeBasicInfoForm.values.JobTitleName : '',
    OfficeBeforeRelocate: isFormReducerExists ? state.form.employeeBasicInfoForm.values.OfficeBeforeRelocate : '',
    Location: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Location : '',
    BusinessLocation: isFormReducerExists ? state.form.employeeBasicInfoForm.values.BusinessLocation : '',
    Relocated: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Relocated : '',
    Name: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Name : '',
    Notes: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Notes : '',
    Office: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Office : '',
    Skype: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Skype : '',
    StartDateInCompany: isFormReducerExists ? state.form.employeeBasicInfoForm.values.StartDateInCompany : '',
    Status: isFormReducerExists ? state.form.employeeBasicInfoForm.values.Status : '',
    ResourceManager: isFormReducerExists ? state.form.employeeBasicInfoForm.values.ResourceManager : '',
    HrManager: isFormReducerExists ? state.form.employeeBasicInfoForm.values.HrManager : '',
    officesLocations: state.projectsReducer.OfficeLocations,
    UserRoles: state.authReducer.user.UserRoles,
    businessLocations: state.membersReducer.businessLocations,
    baseBusinessLocations: state.officesReducer.baseBusinessLocations,
    resourceManagers: state.membersReducer.resourceManagers,
    hrManagers: state.membersReducer.hrManagers,
    LeavingNoticeDate: isFormReducerExists ? state.form.employeeBasicInfoForm.values.LeavingNoticeDate : '',
    PlannedLeavingDate: isFormReducerExists ? state.form.employeeBasicInfoForm.values.PlannedLeavingDate : '',
    BackFillType: isFormReducerExists ? state.form.employeeBasicInfoForm.values.BackFillType : '',
    BackFillDate: isFormReducerExists ? state.form.employeeBasicInfoForm.values.BackFillDate : '',
    BackFillLink: isFormReducerExists ? state.form.employeeBasicInfoForm.values.BackFillLink : '',
  };
}, mapDispatchToProps)(reduxForm({
  form: 'employeeBasicInfoForm',
})(EmployeeBasicInfoForm));
