import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import http from 'services/http';
import {
  setAuditReports,
  setAuditReportsTotalCount,
  GET_MAIN_AUDIT_REPORTS,
} from '../actions/auditActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

function* getAuditReports(action) {
  try {
    yield put(startRequest());
    const {
      searchvalue,
      skip,
      take,
      filters,
    } = action.payload;
    const auditReports = yield call(http, {
      method: 'GET',
      url: `audit_reports?Search=${encodeURIComponent(searchvalue)}&skip=${skip}&take=${take}`,
      params: filters,
    });
    yield put(setAuditReports(auditReports.data.Histories));
    yield put(setAuditReportsTotalCount(auditReports.data.TotalCount));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export default function* reportsAuditSaga() {
  yield takeLatest(GET_MAIN_AUDIT_REPORTS, getAuditReports);
}
