import React, { Component } from 'react';
import classNames from 'classnames';
import InputCurrency from 'common/components/form-items/input-currency/input-currency';

export class SowRenewalStatusItem extends Component {
  onChangeCheckBox = () => {
    const {
      onChange, item, calculationPercent,
    } = this.props;
    const updatedItem = {
      ...item,
      IsActive: !item.IsActive,
      Value: !item.IsActive ? (+item.Value ? +item.Value : calculationPercent()) : +item.Value,
    };
    onChange(updatedItem, item.RenewalStatusId);
  }

  onChangePercent = ({ target }) => {
    const {
      onChange, item,
    } = this.props;
    const updatedItem = {
      ...item,
      Value: target.value,
    };
    onChange(updatedItem, item.RenewalStatusId);
  }

  onBlurPercentField = () => {
    const { item, recalculationPercent } = this.props;
    recalculationPercent(item.RenewalStatusId);
  }

  render() {
    const { item } = this.props;

    return (
      <div className='c-list__item-renewal-status renewal-status-item__container'>
        <div className='c-list__column step__form--renewal-status-block--field'>
          <div
            className={classNames('c-list__item-column renewal-status-item__checkbox')}
          >
            <label className='checkbox-cont-list-item configuration-option check_role'>
              <input
                type='checkbox'
                value={item.RenewalStatusId}
                checked={item.IsActive}
                onChange={this.onChangeCheckBox}
              />
              <span className='configuration-option__checkbox' />
            </label>
          </div>
          <div>
            <span>{item.Name}</span>
          </div>
        </div>
        <div className='c-list__item-column step__form--renewal-status-block--text-field'>
          <InputCurrency
            id={`value-${item.RenewalStatusId}`}
            formClassName='control--renewal-status-value'
            placeholder=''
            name='Value'
            currency
            disabled={!item.IsActive}
            onChange={this.onChangePercent}
            value={item && item.Value}
            showClear={false}
            onBlur={this.onBlurPercentField}
            maxValue={100}
            endAdornment='%'
          />
        </div>
      </div>
    );
  }
}
