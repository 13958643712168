import moment from 'moment';
import { parseFromAmericanFormat } from './getAmericanFormatDigit';
import { isStringAndNotEmpty } from 'utils/isStringAndNotEmpty.js';
import { isNumber } from 'utils/isNumber.js';

export const mapFromChildContractMilestone = (milestone) => ({
  Id: milestone.id,
  Milestone: milestone.milestoneDate,
  MilestoneAmount: milestone.milestoneAmount,
  UsedAssignmentBackupUser: milestone.backupUsed,
});

export const mapToChildContractMilestone = (milestone) => ({
  id: milestone.Id,
  milestoneDate: moment(milestone.Milestone, 'MMMM, YYYY').format(moment.defaultFormat),
  milestoneAmount: parseFromAmericanFormat(milestone.MilestoneAmount),
  backupUsed: milestone.UsedAssignmentBackupUser,
});

export const mapFromMainContractMilestone = (milestone) => ({
  Id: milestone.id,
  Milestone: milestone.milestoneDate,
  MilestoneAmount: milestone.milestoneAmount,
  InvoicedAmount: milestone.invoicedAmount,
  InvoicedDate: milestone.invoicedDate,
  RemainingBalance: milestone.remainingBalance,
  RemainingBalanceOverridePossibility: milestone.isRemainingBalanceOverriden,
  UsedAssignmentBackupUser: milestone.backupUsed,
  Approved: milestone.approved,
  IsShadowed: milestone.isShadowed,
  RevenueReceived: milestone.revenueReceived,
});

export const mapToMainContractMilestone = (milestone) => ({
  id: milestone.Id,
  milestoneDate: moment(milestone.Milestone, 'MMMM, YYYY').format(moment.defaultFormat),
  milestoneAmount: parseFromAmericanFormat(milestone.MilestoneAmount),
  invoicedAmount:
      ((isStringAndNotEmpty(milestone.InvoicedAmount) || isNumber(milestone.InvoicedAmount))
        && parseFromAmericanFormat(milestone.InvoicedAmount))
      || null,
  invoicedDate: (milestone.InvoicedDate && moment(milestone.InvoicedDate).format(moment.defaultFormat)) || null,
  remainingBalance: (milestone.RemainingBalance && parseFromAmericanFormat(milestone.RemainingBalance)) || null,
  isRemainingBalanceOverriden: milestone.RemainingBalanceOverridePossibility,
  backupUsed: milestone.UsedAssignmentBackupUser,
  approved: milestone.Approved,
  revenueReceived:
    ((isStringAndNotEmpty(milestone.RevenueReceived) || isNumber(milestone.RevenueReceived))
      && parseFromAmericanFormat(milestone.RevenueReceived))
    || null,
});
