import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  label: {
    width: '100%',
    cursor: 'pointer',
    display: 'block',
  },
}));

export default ({ title, onChange }) => {
  const classes = useStyles();

  return (
    <form className={classes.root} id='form'>
      <input
        accept='.xlsx'
        className={classes.input}
        id='contained-button-file'
        name='ImportFile'
        multiple
        type='file'
        onChange={onChange}
      />
      <label
        htmlFor='contained-button-file'
        className={classes.label}
      >
        {title}
      </label>
    </form>
  );
};
