import React, { useState } from 'react';
import {
  Button, Menu, MenuItem,
} from '@material-ui/core';
import ButtonUpload from 'common/components/form-items/button-upload/button-upload';
import { makeStyles } from '@material-ui/core/styles';
import config from 'config.json';

const useStyles = makeStyles(() => ({
  menuButton: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  menuItem: {
    cursor: 'pointer',
  },
  linkItem: {
    textDecoration: 'none',
    color: 'black',
  },
}));

export default ({
  styles,
  onImportFile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onImport = ({ target }) => {
    handleClose();
    onImportFile(target.files[0]);
    target.value = '';
  };

  return (
    <div className={styles}>
      <Button
        className={classes.menuButton}
        onClick={handleClick}
      >
        Import milestones data
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          className={classes.menuItem}
        >
          <a
            href={`${config.host}/templates/billing_summary_template.xlsx`}
            className={classes.linkItem}
          >
          Download import template
          </a>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
        >
          <ButtonUpload title='Import file' onChange={onImport} />
        </MenuItem>
      </Menu>
    </div>
  );
};
