import { EMPLOYEES_STATUS_EMPLOYEE_ID } from '../../../utils/const-variable';
import {
  CHANGE_BACKGROUND_CHECKS_COLUMNS_TO_DISPLAY,
  RESET_BACKGROUND_CHECKS_LIST_SETTINGS,
  SET_BACKGROUND_CHECKS_LIST_SETTINGS
} from '../actions/backgroundChecksActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Employee',
  sortDirection: 'Up',
  filters: {
    EmployeeTypes: [EMPLOYEES_STATUS_EMPLOYEE_ID],
    Clients: ['d6802226-60a6-4973-ab91-47640b7f7f52', 'ab86b785-2bb4-4e33-9e07-306c5bab11b0'],
  },
  takeAmountReports: 50,
};

export const defaultColumnsToDisplay = [
  {
    Name: 'Employee', Id: 'Employee', className: 'big-report-col', isObject: true, isSelected: true, necessary: true,
  }, {
    Name: 'Office', Id: 'Office', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  }, {
    Name: 'Hiring Date', Id: 'HiringDate', className: 'fixed-medium-report-col', isDate: true, isSelected: true,
  }, {
    Name: 'Project', Id: 'Projects', className: 'fixed-medium-report-col', isArray: true, isSelected: true,
  }, {
    Name: 'Direct Manager', Id: 'DirectManager', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  }, {
    Name: 'HR Manager', Id: 'HrManager', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  }, {
    Name: 'BGC Completion', Id: 'CompletionDate', className: 'fixed-medium-report-col', isDate: true, isSelected: true,
  }, {
    Name: 'BGC Expires', Id: 'ExpirationDate', className: 'fixed-medium-report-col', isDate: true, isSelected: true, isBackgroundCheck: true,
  }, {
    Name: 'BGC Request ID', Id: 'Request', className: 'fixed-medium-report-col', isSelected: true,
  },
];

export const initialState = {
  listSettings: defaultListSettings,
  columnsToDisplay: defaultColumnsToDisplay,
};

const backgroundChecksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKGROUND_CHECKS_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_BACKGROUND_CHECKS_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case CHANGE_BACKGROUND_CHECKS_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    default:
      return state;
  }
};

export default backgroundChecksReducer;
