export const GET_MANAGE_ROLE_TYPES = '@admin/GET_MANAGE_ROLE_TYPES';
export const SET_MANAGE_ROLE_TYPES = '@admin/SET_MANAGE_ROLE_TYPES';
export const CREATE_NEW_ROLE_TYPE = '@admin/CREATE_NEW_ROLE_TYPE';
export const UPDATE_ROLE_TYPE = '@admin/UPDATE_ROLE_TYPE';
export const DELETE_ROLE_TYPE = '@admin/DELETE_ROLE_TYPE';

export const getManageRoleTypes = () => ({
  type: GET_MANAGE_ROLE_TYPES,
});

export const setManagesRoleTypes = (roleTypes) => ({
  type: SET_MANAGE_ROLE_TYPES,
  payload: roleTypes,
});

export const createNewRoleTypes = (data) => {
  return {
    type: CREATE_NEW_ROLE_TYPE,
    payload: data,
  };
};

export const updateRoleTypes = (Name, Id, Code) => {
  return {
    type: UPDATE_ROLE_TYPE,
    payload: {
      Name,
      Id,
      Code,
    },
  };
};

export const deleteRoleTypes = (id) => {
  return {
    type: DELETE_ROLE_TYPE,
    payload: {
      roleTypeId: id,
    },
  };
};
