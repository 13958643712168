import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import PropTypes from 'prop-types';
import { getSettings, putSettings } from '../../actions/adminActions';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import history from 'utils/history';
import classNames from 'classnames';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import './settings.css';

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        ContractFirstNotification: '',
        ContractSecondNotification: '',
        ContractThirdNotification: '',
        Id: '',
        RoleFirstNotification: '',
        RoleSecondNotification: '',
        RoleThirdNotification: '',
        ProjectExpiration: '',
        checkChanges: false,
      },
    };
  }

  componentDidMount() {
    const { getSettings, setComponent } = this.props;
    getSettings();
    setComponent({ title: 'Settings' });
  }

  componentDidUpdate(prevProps) {
    const { settings } = this.props;
    if (prevProps.settings !== settings) {
      this.setState({
        settings,
      });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  handleChangeSettings = (e) => {
    const regDigitsOnly = /(\d+)$/;
    const { settings } = this.state;
    if (regDigitsOnly.test(e.target.value) || e.target.value === '') {
      this.setState({
        settings: {
          ...settings,
          [e.target.name]: e.target.value,
        },
        checkChanges: true,
      });
    }
  }

  changeSettings = () => {
    const { settings, checkChanges } = this.state;
    const { putSettings } = this.props;
    if (checkChanges) {
      putSettings(settings.Id, settings);
      this.setState({
        checkChanges: false,
      });
    }
  }

  render() {
    const { settings, checkChanges } = this.state;
    return (
      <Scrollbars
        className='settings-page-toolbar'
        autoHide
        autoHideTimeout={300}
      >
        <DocumentTitle title={applicationTitle.getTitile('settings')} />
        <div className='page-cont'>
          <ListToolbar
            searchField={false}
          />
          <div className='settings-page'>
            <span>Notifications about Contract expire:</span>
            <div className='settings-notifications'>
              <div className='settings-container'>
                <ul>
                  <li>The first</li>
                  <li>The second</li>
                  <li>The third</li>
                </ul>
              </div>
              <div className='settings-container'>
                <ul>
                  <li>
                    <input
                      value={settings.ContractFirstNotification}
                      onChange={this.handleChangeSettings}
                      name='ContractFirstNotification'
                    />
                    <span>day(s)</span>
                  </li>
                  <li>
                    <input
                      value={settings.ContractSecondNotification}
                      onChange={this.handleChangeSettings}
                      name='ContractSecondNotification'
                    />
                    <span>day(s)</span>
                  </li>
                  <li>
                    <input
                      value={settings.ContractThirdNotification}
                      onChange={this.handleChangeSettings}
                      name='ContractThirdNotification'
                    />
                    <span>day(s)</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='settings-page'>
            <span>Notifications about Role/Employee expire:</span>
            <div className='settings-notifications'>
              <div className='settings-container'>
                <ul>
                  <li>The first</li>
                  <li>The second</li>
                  <li>The third</li>
                </ul>
              </div>
              <div className='settings-container'>
                <ul>
                  <li>
                    <input
                      value={settings.RoleFirstNotification}
                      onChange={this.handleChangeSettings}
                      name='RoleFirstNotification'
                    />
                    <span>day(s)</span>
                  </li>
                  <li>
                    <input
                      value={settings.RoleSecondNotification}
                      onChange={this.handleChangeSettings}
                      name='RoleSecondNotification'
                    />
                    <span>day(s)</span>
                  </li>
                  <li>
                    <input
                      value={settings.RoleThirdNotification}
                      onChange={this.handleChangeSettings}
                      name='RoleThirdNotification'
                    />
                    <span>day(s)</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='settings-page'>
              <span>Notifications about Project expire:</span>
              <div className='settings-notifications'>
                <div className='settings-container'>
                  <ul>
                    <li>The first</li>
                  </ul>
                </div>
                <div className='settings-container'>
                  <ul>
                    <li>
                      <input
                        value={settings.ProjectExpiration}
                        onChange={this.handleChangeSettings}
                        name='ProjectExpiration'
                      />
                      <span>day(s)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='buttons settings-buttons'>
          <div />
          <div className='right-side-buttons'>
            <div className='button cancel' onClick={() => history.goBack()}>
              <span>Back</span>
            </div>
            <div
              className={classNames(
                'button next',
                { 'btn-disabled': !checkChanges },
              )}
              onClick={this.changeSettings}
            >
              <span>Apply</span>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

Settings.propTypes = {
  settings: PropTypes.object,
  getSettings: PropTypes.func,
  putSettings: PropTypes.func,
};

export default connect(store => ({
  settings: store.adminReducer.settings,
}), {
  getSettings,
  putSettings,
  setComponent,
  resetComponent,
})(Settings);
