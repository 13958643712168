import { TOOGLE_ROLES_STATUS } from '../../pages/projects/actions/projectsActions';
import { ADD_NOTIFICATIONS, CLEAR_NOTIFICATIONS } from '../actions/notificationsActions';

const initialState = {
  isOnlyActiveRoles: false,
  notifications: [],
};

export default function showRoles(state = initialState, action) {
  switch (action.type) {
    case TOOGLE_ROLES_STATUS:
      return {
        ...state,
        isOnlyActiveRoles: !state.isOnlyActiveRoles,
      };
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
