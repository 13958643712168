import moment from 'moment';

export const generatorUrlForType = (type, id, projectId, SOWId) => {
  switch (type) {
    case 'Project': return `projects/${id}`;
    case 'SOW': return `projects/${projectId}/sows/${id}`;
    case 'PO Original' || 'PO Extension': return `projects/${projectId}/sows/${SOWId}/pos/${id}`;
    case 'Amendment': return `projects/${projectId}/sows/${SOWId}/amendments/${id}`;
    default: return null;
  }
};

export const getConfigBasedOnCheckedDates = (
  datesForCheckParentContracts,
  datesForCheckChildContracts,
  currentDate,
  typeCurrentDocument,
  isStartDate,
  forCreateProject,
) => {
  const { startDatesParentContracts, endDatesParentContracts } = datesForCheckParentContracts || {};
  const { forStartDate, forEndDate } = datesForCheckChildContracts || {};
  if (forCreateProject && startDatesParentContracts && moment(currentDate).isBefore(startDatesParentContracts.date, 'day')) {
    return {
      message: `Date is less than ${startDatesParentContracts.type} start date, please change ${startDatesParentContracts.type} start date.`,
      isAlert: true,
    };
  }
  if (forCreateProject && endDatesParentContracts && moment(currentDate).isAfter(endDatesParentContracts.date, 'day')) {
    return {
      message: `Date is greater than ${endDatesParentContracts.type} end date, please change ${endDatesParentContracts.type} end date.`,
      isAlert: true,
    };
  }
  if (isStartDate && forStartDate && moment(currentDate).isAfter(forStartDate.contract.StartDate, 'day')) {
    return {
      message: `There are ${forStartDate.type} with start date less than ${typeCurrentDocument} start date.`,
      isAlert: true,
    };
  }
  if (!isStartDate && forEndDate && moment(currentDate).isBefore(forEndDate.contract.EndDate, 'day')) {
    return {
      message: `There are ${forEndDate.type} with end date greater than ${typeCurrentDocument} end date.`,
      isAlert: true,
    };
  }
  if (startDatesParentContracts && startDatesParentContracts.length) {
    const invalidDate = startDatesParentContracts.filter((startDate) => startDate.date && moment(currentDate).isBefore(startDate.date, 'day'));
    const typesInvalidDate = invalidDate.map((startDate) => startDate.type);
    const configForPatchRequests = invalidDate.map((startDate) => ({
      url: generatorUrlForType(startDate.type, startDate.id, startDate.projectId, startDate.SOWId),
      data: [
        {
          value: moment(currentDate).set({ 'hour': 8, 'minute': 0 }).format('YYYY-MM-DD HH:mm'),
          path: 'StartDate',
          op: 'replace',
        },
      ],
      typeForAlertMessageAfterPatchRequest: typeCurrentDocument === 'project' ? 'Project' : 'Contract',
      isStartDate: true,
      typeDate: 'start',
    }));
    if (typesInvalidDate.length) {
      return {
        message: `${typeCurrentDocument} ${isStartDate ? 'start' : 'end'} date is less than ${typesInvalidDate.join(' & ')} start date.
        Do you want to change ${typesInvalidDate.join(' & ')} start date?`,
        isAlert: false,
        configForPatchRequests,
        typeChangedDate: isStartDate ? 'StartDate' : 'EndDate',
      };
    }
  }
  if (endDatesParentContracts && endDatesParentContracts.length) {
    const invalidDate = endDatesParentContracts.filter((endDate) => endDate.date && moment(currentDate).isAfter(endDate.date, 'day'));
    const typesInvalidDate = invalidDate.map((endDate) => endDate.type);
    const configForPatchRequests = invalidDate.map((endDate) => ({
      url: generatorUrlForType(endDate.type, endDate.id, endDate.projectId, endDate.SOWId),
      data: [
        {
          value: moment(currentDate).set({ 'hour': 12, 'minute': 0 }).format('YYYY-MM-DD HH:mm'),
          path: 'EndDate',
          op: 'replace',
        },
      ],
      typeForAlertMessageAfterPatchRequest: typeCurrentDocument === 'project' ? 'Project' : 'Contract',
      typeDate: 'end',
    }));
    if (typesInvalidDate.length) {
      return {
        message: `${typeCurrentDocument} ${isStartDate ? 'start' : 'end'} date exceeds ${typesInvalidDate.join(' & ')} end date.
        Do you want to change ${typesInvalidDate.join(' & ')} end date?`,
        isAlert: false,
        configForPatchRequests,
        typeChangedDate: isStartDate ? 'StartDate' : 'EndDate',
      };
    }
  }
  return null;
};

export const getChangeConfigForPatchRequests = (arrayDates, typeDate) => {
  return arrayDates && arrayDates.filter((elem) => elem.typeDate !== typeDate);
};

const chosenRightConfig = (firstConfig, secondConfig) => {
  if (firstConfig.isStartDate) {
    return moment(firstConfig.data[0].value).isBefore(secondConfig.data[0].value, 'day') ? firstConfig : secondConfig;
  }
  return moment(firstConfig.data[0].value).isAfter(secondConfig.data[0].value, 'day') ? firstConfig : secondConfig;
};

export const combinedConfigsForPatchRequests = (newElemConfig, configPatchRequestsForConfirmDialog) => {
  const combinedArray = !!configPatchRequestsForConfirmDialog
    ? configPatchRequestsForConfirmDialog.concat(newElemConfig)
    : newElemConfig;
  const arrayWithRightConfig = combinedArray.reduce((acc, config, index, mas) => {
    const sameElementWithDifferentData = mas.find((elem) => elem.typeDate === config.typeDate && elem.url === config.url
      && !moment(config.data[0].value).isSame(elem.data[0].value));
    if (!sameElementWithDifferentData) { acc.push(config); }
    if (sameElementWithDifferentData) {
      acc.push(chosenRightConfig(sameElementWithDifferentData, config));
    }
    return acc;
  }, []);
  return [...new Set(arrayWithRightConfig.map((elem) => JSON.stringify(elem)))].map((elem) => JSON.parse(elem));
};
