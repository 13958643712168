import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import http from 'services/http';
import history from 'utils/history';
import {
  setBenchReports,
  BENCH_TRANSITION_TO_EMPLOYEE,
  SEARCH_MAIN_BENCH_REPORTS,
} from '../actions/benchReportsActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { getEmployeeDetails } from 'pages/members/sagas/membersSaga';

function* searchBenchReports(action) {
  try {
    yield put(startRequest());
    const {
      searchvalue,
      filters,
    } = action.payload;
    const benchReports = yield call(http, {
      method: 'GET',
      url: `bench-reports?SearchString=${encodeURIComponent(searchvalue)}`,
      params: filters,
    });
    yield put(setBenchReports(benchReports.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToEmployeeDetails(action) {
  try {
    yield put(startRequest());
    yield getEmployeeDetails(action);
    const { payload } = action;
    history.push({
      pathname: `/employees/${payload.id}`,
      state: {
        filters: {},
        search: '',
      },
    });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export default function* reportsBenchMainSaga() {
  yield takeLatest(SEARCH_MAIN_BENCH_REPORTS, searchBenchReports);
  yield takeEvery(BENCH_TRANSITION_TO_EMPLOYEE, transitionToEmployeeDetails);
}
