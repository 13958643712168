export const GET_MANAGE_BUSINESS_TRIPS = '@admin/GET_MANAGE_BUSINESS_TRIPS';
export const SET_MANAGE_BUSINESS_TRIPS = '@admin/SET_MANAGE_BUSINESS_TRIPS';
export const CREATE_NEW_BUSINESS_TRIP = '@admin/CREATE_NEW_BUSINESS_TRIP';
export const UPDATE_BUSINESS_TRIP = '@admin/UPDATE_BUSINESS_TRIP';
export const DELETE_BUSINESS_TRIP = '@admin/DELETE_BUSINESS_TRIP';

export const getManageBusinessTrips = () => ({
  type: GET_MANAGE_BUSINESS_TRIPS,
});

export const setManagesBusinessTrips = (businessTrips) => ({
  type: SET_MANAGE_BUSINESS_TRIPS,
  payload: businessTrips,
});

export const createNewBusinessTrips = (data) => {
  return {
    type: CREATE_NEW_BUSINESS_TRIP,
    payload: data,
  };
};

export const updateBusinessTrips = (Name, Id) => {
  return {
    type: UPDATE_BUSINESS_TRIP,
    payload: {
      Name,
      Id,
    },
  };
};

export const deleteBusinessTrips = (id) => {
  return {
    type: DELETE_BUSINESS_TRIP,
    payload: {
      roleTypeId: id,
    },
  };
};
