import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'common/components/form-items/date-picker/date-picker';

export class DateRange extends Component {
  render() {
    const {
      startDateHistory,
      endDateHistory,
      handleChooseDate,
      clearStartDate,
      clearEndDate,
    } = this.props;
    return (
      <div className='toolbar-date_range'>
        <div className='toolbar-date_range-container'>
          <DatePicker
            formClassName='step__form svg-calendar-add-info history'
            datePickerClassName='history_date'
            placeholder='Choose Start Date'
            value={startDateHistory}
            onChange={handleChooseDate}
            clearValue={clearStartDate}
            name='startDateHistory'
          />
        </div>
        <div className='toolbar-date_range-container'>
          <DatePicker
            formClassName='step__form svg-calendar-add-info history'
            datePickerClassName='history_date'
            placeholder='Choose End Date'
            value={endDateHistory}
            minVal={startDateHistory}
            onChange={handleChooseDate}
            clearValue={clearEndDate}
            name='endDateHistory'
          />
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  startDateHistory: PropTypes.string,
  endDateHistory: PropTypes.string,
  handleChooseDate: PropTypes.func.isRequired,
  clearStartDate: PropTypes.func.isRequired,
  clearEndDate: PropTypes.func.isRequired,
};
