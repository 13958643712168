import {
  SHOW_NEW_PROJECT_MODAL,
  HIDE_NEW_PROJECT_MODAL,
  SET_PROJECTS,
  SET_PROJECT_DETAILS,
  SET_PROJECT_TEAMS,
  RESET_PROJECT_DETAILS,
  SET_CLIENTS,
  SET_OFFICES,
  SET_STATUSES,
  SET_PERIODS,
  CHANGE_COLUMNS_TO_DISPLAY,
  SET_CLIENT_CONTACTS,
  SET_PROJECT_STATUSES,
  SET_APPROVAL_STATUSES,
  SET_SOW_TYPES,
  SET_PROJECT_TYPES,
  SET_PROJECT_ROLE_CONFIG,
  SET_BUSINESS_GROUPS,
  SET_PO_STATUSES,
  TRANSFER_EXTENDED_ROLES,
  SET_PROJECT_ROLES,
  SET_ALL_ROLES_PROJECT,
  SET_HISTORY_PROJECT,
  TOOGLE_LOAD_HISTORY,
  SET_PROJECTS_TOTAL_COUNT,
  SET_PROJECT_NAME_DUBLICATE_ERROR,
  SET_MANAGERS_BY_OFFICE,
  SET_CURRENCIES,
  SET_MILESTONES_FOR_SOW,
  SET_MILESTONES_FOR_PO,
  SET_MILESTONES_FOR_AMENDMENT,
  SET_PROJECT_MILESTONES,
  UPDATE_PROJECT_MILESTONE_EDITING_FORM,
  RESET_PROJECTS,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
  SET_SOW_RENEWAL_STATUSES_FOR_SOW,
  GET_PREV_PATH_FOR_PROJECTS,
  SET_ENGAGEMENT_TYPES,
  RESET_PROJECT_TEAMS,
  GET_SHADOWING_MILESTONES_SUCCESS,
  RESET_SHADOWING_MILESTONES,
  UPDATE_FISCAL_YEARS,
} from '../actions/projectsActions.js';
import { SET_OFFICE_LOCATIONS } from '../actions/filterLocationsActions';
import { SET_CLIENT_CONTACT } from 'common/actions/addEntityActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
  filters: {
    Statuses: [3],
  },
  scrollTo: '',
  childState: {
    contracts: {
      search: '',
      sortColumn: null,
      sortDirection: null,
    },
    roles: {
      search: '',
      sortColumn: 'RoleName',
      sortDirection: 'Up',
    },
    history: {
      search: '',
      startDateHistory: null,
      endDateHistory: null,
    },
    teams: {
      search: '',
      sortColumn: 'TeamName',
      sortDirection: 'Up',
    },
  },
};

const initialState = {
  prevPath: '',
  projectTotalCount: 0,
  projects: [],
  projectDetails: {
    AllRoles: [],
    Roles: [],
    Name: '',
    Contracts: [],
    Status: '',
    Billability: { Id: '', Name: '' },
    Client: { Id: '', Name: '' },
    Offices: [],
    PrimaryOffices: [],
    ClientManager: { Id: '', Name: '' },
    DeliveryManager: { Id: '', Name: '' },
    AccountManager: { Id: '', Name: '' },
    projectNameDublicateError: false,
    EngagementType: { Id: '', Name: '' },
    Leftover: '',
    MSAs: [],
  },
  projectRoleConfig: {},
  clients: [],
  offices: [],
  projectTypes: [],
  deliveryManagersByOffice: [],
  engineeringManagersByOffice: [],
  accountManagersByOffice: [],
  approvalStatuses: [],
  clientContacts: [],
  projectStatuses: [],
  businessGroups: [],
  projectTeams: {
    Teams: [],
  },
  periods: [],
  SOWTypes: [],
  POStatuses: [],
  modalNewPropjectIsOpen: false,
  OfficeLocations: {},
  columnsToDisplay: [
    {
      Name: 'Project Name', isSelected: true, Id: 'Name', className: 'medium-col', necessary: true,
    },
    {
      Name: 'Offices', isSelected: true, Id: 'Offices', className: 'small-col', isArray: true,
    },
    {
      Name: 'Client', isSelected: true, Id: 'Client', className: 'small-col', hasBadge: true, isObject: true,
    },
    {
      Name: 'SOW/PO Number', isSelected: true, Id: 'SowPo', className: 'medium-col', isArray: true,
    },
    {
      Name: 'Contract Type', isSelected: true, Id: 'ContractTypes', className: 'medium-col', isArray: true,
    },
    {
      Name: 'Business Development Manager', isSelected: true, Id: 'AccountManager', className: 'medium-col', hasBadge: true, isObject: true,
    },
    {
      Name: 'Technical Account Manager', isSelected: true, Id: 'DeliveryManagers', className: 'medium-col', isArray: true,
    },
    {
      Name: 'Client Manager', isSelected: true, Id: 'ClientManager', className: 'medium-col', hasBadge: true, isObject: true,
    },
    {
      Name: 'Start Date', isSelected: true, Id: 'StartDate', className: 'small-col', isDate: true,
    },
    {
      Name: 'End Date', isSelected: true, Id: 'EndDate', className: 'small-col',
    },
    {
      Name: 'Status',
      isSelected: true,
      title: true,
      Id: 'Status',
      className: 'small-col',
      WithOpenRole: true,
      isNeedProjectStatus: true,
    },
  ],
  extendedRoles: [],
  ViewHistory: {
    History: [],
    TotalCount: 0,
  },
  isHistoryLoad: false,
  isProjectNameDublicateError: false,
  allCurrencies: [],
  Milestones: {
    SOW: [],
    PO: [],
    Amendment: [],
  },
  projectMilestones: [],
  projectMilestoneEditingForm: {},
  listSettings: defaultListSettings,
  SowRenewalStatuses: [],
  allEngagementTypes: [],
  shadowingMilestones: [],
  fiscalYears: {},
};

// eslint-disable-next-line default-param-last
const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREV_PATH_FOR_PROJECTS:
      return {
        ...state,
        prevPath: action.payload,
      };
    case SHOW_NEW_PROJECT_MODAL:
    case HIDE_NEW_PROJECT_MODAL:
      return {
        ...state,
        modalNewPropjectIsOpen: action.payload.modalNewPropjectIsOpen,
      };
    case TOOGLE_LOAD_HISTORY:
      return {
        ...state,
        isHistoryLoad: !state.isHistoryLoad,
      };
    case SET_HISTORY_PROJECT:
      return {
        ...state,
        ViewHistory: {
          History: action.payload.Histories,
          TotalCount: action.payload.TotalCount,
        },
      };
    case SET_PO_STATUSES:
      return {
        ...state,
        POStatuses: action.payload,
      };
    case SET_MANAGERS_BY_OFFICE:
      return {
        ...state,
        deliveryManagersByOffice: action.payload.deliveryManagersByOffice,
        engineeringManagersByOffice: action.payload.engineeringManagersByOffice,
        accountManagersByOffice: action.payload.accountManagersByOffice,
      };
    case RESET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: {
          Id: '',
          Name: '',
          Status: '',
          Aliases: '',
          ProjectCode: '',
          Description: '',
          RealizedRevenue: 0,
          Offices: [],
          PrimaryOffices: [],
          ClientManager: {},
          AccountManager: {},
          DeliveryManagers: [],
          EngineeringManagers: [],
          StartDate: '',
          EndDate: '',
          Contracts: [],
          ViewHistory: null,
          Roles: [],
          AllRoles: [],
          EngagementType: {},
          MSAs: [],
        },
      };
    case SET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.payload.projectDetails,
      };
    case SET_PROJECT_TEAMS:
      return {
        ...state,
        projectTeams: action.payload.projectTeams,
      };
    case RESET_PROJECT_TEAMS:
      return {
        ...state,
        projectTeams: {
          Teams: [],
        },
      };
    case SET_PROJECT_ROLE_CONFIG:
      return {
        ...state,
        projectRoleConfig: action.payload.projectRoleConfig,
      };
    case SET_PROJECTS:
      // eslint-disable-next-line no-case-declarations
      const newProjectIds = new Set(action.payload.projects.map((project) => project.Id));

      return {
        ...state,
        projects: [...state.projects.filter((project) => !newProjectIds.has(project.Id)), ...action.payload.projects],
      };
    case SET_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: action.payload.projectTypes,
      };
    case SET_OFFICES:
      return {
        ...state,
        offices: action.payload.offices,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
      };
    case SET_STATUSES:
      return {
        ...state,
        statuses: action.payload.statuses,
      };
    case SET_PROJECT_STATUSES:
      return {
        ...state,
        projectStatuses: action.payload.projectStatuses,
      };
    case SET_APPROVAL_STATUSES:
      return {
        ...state,
        approvalStatuses: action.payload.approvalStatuses,
      };
    case SET_SOW_TYPES:
      return {
        ...state,
        SOWTypes: action.payload.SOWTypes,
      };
    case SET_PERIODS:
      return {
        ...state,
        periods: action.payload.periods,
      };
    case SET_CLIENT_CONTACTS:
      return {
        ...state,
        clientContacts: action.payload.clientContacts,
      };
    case SET_CLIENT_CONTACT:
      return {
        ...state,
        clientContacts:
          [...state.clientContacts, {
            Id: action.payload.Id,
            Name: action.payload.Name,
            ClientId: action.payload.ClientId,
          }],
      };
    case SET_BUSINESS_GROUPS:
      return {
        ...state,
        businessGroups: action.payload.businessGroups,
      };
    case CHANGE_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    case TRANSFER_EXTENDED_ROLES:
      return {
        ...state,
        extendedRoles: action.payload,
      };
    case SET_PROJECT_ROLES:
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          Roles: action.payload,
        },
      };
    case SET_ALL_ROLES_PROJECT:
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          AllRoles: action.payload,
        },
      };
    case SET_PROJECTS_TOTAL_COUNT:
      return {
        ...state,
        projectTotalCount: action.payload,
      };
    case SET_PROJECT_NAME_DUBLICATE_ERROR:
      return {
        ...state,
        isProjectNameDublicateError: action.payload,
      };
    case SET_OFFICE_LOCATIONS:
      return {
        ...state,
        OfficeLocations: action.payload.filters,
      };
    case SET_CURRENCIES:
      return {
        ...state,
        allCurrencies: action.payload,
      };
    case SET_MILESTONES_FOR_SOW:
      return {
        ...state,
        Milestones: {
          ...state.Milestones,
          SOW: action.payload,
        },
      };
    case SET_MILESTONES_FOR_PO:
      return {
        ...state,
        Milestones: {
          ...state.Milestones,
          PO: action.payload,
        },
      };
    case SET_MILESTONES_FOR_AMENDMENT:
      return {
        ...state,
        Milestones: {
          ...state.Milestones,
          Amendment: action.payload,
        },
      };
    case SET_PROJECT_MILESTONES:
      return {
        ...state,
        projectMilestones: action.payload,
      };
    case UPDATE_PROJECT_MILESTONE_EDITING_FORM:
      return {
        ...state,
        projectMilestoneEditingForm: {
          ...state.projectMilestoneEditingForm,
          [action.payload.milestoneId]:
            action.payload.updatedFields
              ? {
                ...state.projectMilestoneEditingForm[action.payload.milestoneId],
                ...action.payload.updatedFields,
              }
              : null,
        },
      };
    case RESET_PROJECTS:
      return {
        ...state,
        projects: [],
        projectTotalCount: 0,
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_SOW_RENEWAL_STATUSES_FOR_SOW:
      return {
        ...state,
        SowRenewalStatuses: action.payload,
      };
    case SET_ENGAGEMENT_TYPES:
      return {
        ...state,
        allEngagementTypes: action.payload,
      };
    case GET_SHADOWING_MILESTONES_SUCCESS:
      return {
        ...state,
        shadowingMilestones: action.payload,
      };
    case RESET_SHADOWING_MILESTONES:
      return {
        ...state,
        shadowingMilestones: initialState.shadowingMilestones,
      };
    case UPDATE_FISCAL_YEARS:
      return {
        ...state,
        fiscalYears: { ...state.fiscalYears, ...action.payload },
      };
    default:
      return state;
  }
};

export default projectsReducer;
