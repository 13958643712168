/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  MenuItem, InputLabel, FormControl, Paper, Tooltip, Typography,
} from '@material-ui/core';

import CustomIcon from '../../icon/Icon';
import { addEntity } from 'common/actions/addEntityActions';
import isEmptyObject from 'utils/isEmptyObject';

import './search-dropdown.css';
import VirtualSelect from '../virtualized-select/virtualized-select';

const NoOptionsMessage = (props) => {
  const { selectProps, innerProps } = props;
  const noOptionsContent = (
    <div
      className='no-options-container'
      onClick={selectProps.addEntity(selectProps)}
    >
      {
        selectProps.isAddingFeature ? (
          <div className='add-new-item pointer'>
            <CustomIcon iconName='plus-orange' />
            {selectProps.isClientManager ? <span>Add new client manager</span> : <span>Add new item</span>}
          </div>
        ) : 'No options'
      }
    </div>
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Paper {...innerProps}>
      {noOptionsContent}
    </Paper>
  );
};

const Option = (props) => {
  const {
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
    label,
    value,
  } = props;
  return (
    <Tooltip
      title={label && label.length > 20 ? <Typography>{label}</Typography> : ''}
      key={value}
    >
      <MenuItem
        buttonRef={innerRef}
        selected={isFocused}
        component='div'
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...innerProps}
      >
        {children}
      </MenuItem>
    </Tooltip>
  );
};

const ValueContainer = (props) => {
  const { children, selectProps } = props;
  let newChildren;
  if (selectProps.isOnlyLiteralValues) {
    const valid = children[1].props.value && children[1].props.value.match(selectProps.isOnlyLiteralValues);
    if (children[1].props.value) {
      const secondChildObject = {
        ...children[1],
        props: {
          ...children[1].props,
          value: valid ? valid[0] : '',
          selectProps: {
            ...children[1].props.selectProps,
            inputValue: valid ? valid[0] : '',
          },
        },
      };
      newChildren = [children[0], secondChildObject];
    }
  }
  return <div
    className={classNames(
      'search-dropdown-value-container',
      { 'search-dropdown-clearable': selectProps.isClearable },
      { 'search-dropdown-not-clearable': !selectProps.isClearable },
    )}
  >
    {newChildren ? newChildren : children}
  </div>;
};

const components = {
  NoOptionsMessage,
  Option,
  ValueContainer,
};

class SearchDropdown extends React.Component {
  checkAddEntity = (selectInfo) => () => {
    const { addEntity } = this.props;
    const {
      isAddingFeature, inputValue, id, parentEntityId,
    } = selectInfo;
    if (isAddingFeature && inputValue.trim().length) {
      addEntity(id, inputValue.trim(), parentEntityId);
    }
  }

  handleKeyDown = (event) => {
    switch (event.key) {
      case 'Home':
        this.pressingShiftHome(event);
        break;
      case 'End':
        this.pressingShiftEnd(event);
        break;
      default:
        break;
    }
  };

  pressingShiftHome = (event) => {
    event.shiftKey
      ? event.target.setSelectionRange(0, event.target.selectionStart)
      : event.target.setSelectionRange(0, 0);
  }

  pressingShiftEnd = (event) => {
    const lastPosition = event.target.value.length;
    event.shiftKey
      ? event.target.setSelectionRange(event.target.selectionStart, lastPosition)
      : event.target.setSelectionRange(lastPosition, lastPosition);
  }

  render() {
    const {
      label,
      placeholder,
      options,
      value,
      onChange,
      isRequired,
      inputProps,
      formClassName,
      selectClassName,
      disabled,
      helperText,
      showClear,
      onMouseLeave,
      onBlur,
      name,
      autoFocus = false,
      defaultMenuIsOpen = false,
      isAddingFeature = false,
      parentEntityId = '',
      addEmployeeStyles = false,
      isOnlyLiteralValues,
      isClientManager,
      useVirtualSelect = false,
    } = this.props;
    const option = {};
    const suggestions = options.map(suggestion => {
      if (value === suggestion.Id) {
        option.label = suggestion.Name;
        option.value = suggestion.Id;
      }
      return {
        value: suggestion.Id,
        label: suggestion.Name,
      };
    });
    const selectValue = !!value || value === 0 || !isEmptyObject(value) ? value : 'none';
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        top: 'calc(100% - 8px)',
      }),
    };
    return (
      <FormControl
        title={helperText || null}
        className={formClassName}
        onMouseLeave={onMouseLeave}
        onBlur={onBlur}
      >
        {
          label && (
            <InputLabel shrink htmlFor={inputProps.id} required={isRequired}>{label}</InputLabel>
          )
        }
        {useVirtualSelect
          ? <VirtualSelect
              id={inputProps.id}
              defaultMenuIsOpen={defaultMenuIsOpen}
              autoFocus={autoFocus}
              options={suggestions}
              components={components}
              value={value ? option : value}
              name={name}
              onChange={onChange}
              onKeyDown={this.handleKeyDown}
              placeholder={placeholder}
              className={classNames(
                selectClassName,
                {
                  'placeholder--is-active': selectValue === 'none' && disabled,
                },
                {
                  'placeholder--is-disabled': disabled && !addEmployeeStyles,
                },
              )}
              inputProps={inputProps}
              isDisabled={disabled}
              isClearable={showClear && !disabled}
              addEntity={this.checkAddEntity}
              isAddingFeature={isAddingFeature}
              parentEntityId={parentEntityId}
              isOnlyLiteralValues={isOnlyLiteralValues}
              isClientManager={isClientManager}
              styles={customStyles}
          /> : <Select
            id={inputProps.id}
            defaultMenuIsOpen={defaultMenuIsOpen}
            autoFocus={autoFocus}
            options={suggestions}
            components={components}
            value={value ? option : value}
            name={name}
            onChange={onChange}
            onKeyDown={this.handleKeyDown}
            placeholder={placeholder}
            className={classNames(
              selectClassName,
              {
                'placeholder--is-active': selectValue === 'none' && disabled,
              },
              {
                'placeholder--is-disabled': disabled && !addEmployeeStyles,
              },
            )}
            inputProps={inputProps}
            isDisabled={disabled}
            isClearable={showClear && !disabled}
            addEntity={this.checkAddEntity}
            isAddingFeature={isAddingFeature}
            parentEntityId={parentEntityId}
            isOnlyLiteralValues={isOnlyLiteralValues}
            isClientManager={isClientManager}
            styles={customStyles}
          />}
      </FormControl>
    );
  }
}

SearchDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  addEntity: PropTypes.func,
  isAddingFeature: PropTypes.bool,
  parentEntityId: PropTypes.string,
};

export default connect((store) => ({
  roleModalIsVisible: store.roleModalReducer.isVisible,
  version: store.versionReducer.version,
}), (dispatch) => {
  return {
    addEntity: (entityId, value, parentEntityId) => dispatch(addEntity(entityId, value, parentEntityId)),
  };
})(SearchDropdown);
