import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addClientInfo } from '../../actions/clientsActions';
import history from 'utils/history';
import ClientContactsForm from 'common/components/redux-form/create-client-form/client-contacts-form';

export class CreateContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        FirstName: '',
        LastName: '',
        JobTitle: '',
        Email: '',
        Cell: '',
        Skype: '',
      },
      Contacts: [],
    };
  }

  addContacts = (data) => {
    const { Contacts, contact } = this.state;
    const user = {
      FirstName: data.FirstName.trim(),
      LastName: data.LastName.trim(),
      Cell: data.Cell.trim(),
      Email: data.Email.trim(),
      JobTitle: data.JobTitle.trim(),
      Skype: data.Skype.trim(),
    };
    const clientContact = {
      ...contact,
      ...user,
    };
    const addContact = Contacts;
    addContact.push(clientContact);
    this.setState({
      Contacts: addContact,
    });
  }

  removeContacts = (contacts) => {
    const { Contacts } = this.state;
    this.setState({
      Contacts: Contacts.filter(e => e !== contacts),
    });
  }

  onSave = () => {
    const { Contacts } = this.state;
    const { location, addClientInfo } = this.props;
    const clientObject = {
      Contacts,
      idClient: location.pathname.split('/')[2],
    };
    addClientInfo(clientObject);
    history.push({ pathname: `/clients/${location.pathname.split('/')[2]}`, state: location.state });
  }

  onCancel = () => {
    const { location } = this.props;
    history.push({ pathname: `/clients/${location.pathname.split('/')[2]}`, state: location.state });
  }

  render() {
    const {
      Contacts,
      contact,
    } = this.state;
    return (
      <div className='page-cont'>
        <div className='page-header'>
          <span>ADD NEW CONTACTS</span>
        </div>
        <ClientContactsForm
          data={contact}
          onSubmit={this.onSave}
          onCancel={this.onCancel}
          isHaveRights
          contacts={Contacts}
          removeContacts={this.removeContacts}
          addContacts={this.addContacts}
        />
      </div>
    );
  }
}

CreateContacts.propTypes = {
  addClientInfo: PropTypes.func,
};

export default connect(
  () => ({}),
  {
    addClientInfo,
  },
)(CreateContacts);
