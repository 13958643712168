import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  setSOWRenewalStatus,
  GET_SOW_RENEWAL_STATUS,
  CREATE_SOW_RENEWAL_STATUS,
  UPDATE_SOW_RENEWAL_STATUS,
  DELETE_SOW_RENEWAL_STATUS,
} from '../actions/adminManageSOWRenewalStatus';

import {
  startRequest,
  finishRequest,
} from 'common/actions/loadingActions';

export function* getManageSOWRenewalStatuses() {
  try {
    yield put(startRequest());
    const renewalStatus = yield call(http, {
      method: 'GET',
      url: 'renewal-statuses',
    });

    yield put(setSOWRenewalStatus(renewalStatus.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addSOWRenewalStatus(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'renewal-statuses',
      data,
    });
    yield call(getManageSOWRenewalStatuses);
  } catch (e) {
    // error message
  }
}

export function* putSOWRenewalStatus(action) {
  yield call(http, {
    method: 'PUT',
    url: `renewal-statuses/${action.payload.Id}`,
    data: { Name: action.payload.Name },
  });
  yield call(getManageSOWRenewalStatuses);
}

export function* deleteSOWRenewalStatus(action) {
  try {
    yield call(http, {
      method: 'DELETE',
      url: `renewal-statuses/${action.payload}`,
    });
    yield call(getManageSOWRenewalStatuses);
  } catch (e) {
    // error message
  }
}

export default function* adminManagePaymentTerms() {
  yield takeEvery(GET_SOW_RENEWAL_STATUS, getManageSOWRenewalStatuses);
  yield takeEvery(CREATE_SOW_RENEWAL_STATUS, addSOWRenewalStatus);
  yield takeEvery(UPDATE_SOW_RENEWAL_STATUS, putSOWRenewalStatus);
  yield takeEvery(DELETE_SOW_RENEWAL_STATUS, deleteSOWRenewalStatus);
}
