import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import {
  downloadManageEngagementTypes,
  putManageEngagementTypes,
  postManageEngagementTypes,
} from '../../../actions/adminManageEngagementTypes';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageEngagementTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Engagement Types', isSelected: true, Id: 'Name',
        },
      ],
      showAddEngagementTypeFields: false,
      addEngagementType: { Name: '' },
      blockAddEngagementType: false,
      blockSaveType: false,
    };
  }

  componentDidMount() {
    const { downloadManageEngagementTypes } = this.props;
    downloadManageEngagementTypes();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  }

  addNewEngagementType = () => {
    const { blockAddEngagementType } = this.state;
    if (!blockAddEngagementType) {
      this.setState({
        showAddEngagementTypeFields: true,
      });
    }
  }

  getNewEngagementType = ({ target }) => {
    const { addEngagementType } = this.state;
    this.setState({
      addEngagementType: {
        ...addEngagementType,
        [target.name]: target.value,
      },
    }, this.newTypeValidation);
  }

  newTypeValidation = () => {
    const { addEngagementType } = this.state;
    this.setState({
      blockSaveType: !!addEngagementType.Name.trim(),
      blockAddEngagementType: !!addEngagementType.Name.trim(),
    });
  }

  newTypeCancel = () => {
    this.setState({
      addEngagementType: { Name: '' },
      showAddEngagementTypeFields: false,
      blockSaveType: false,
      blockAddEngagementType: false,
    });
  }

  newJobTitleApply = () => {
    const { postManageEngagementTypes } = this.props;
    const { blockSaveType, addEngagementType } = this.state;
    if (blockSaveType) {
      postManageEngagementTypes(addEngagementType);
      this.setState({
        addJobTitle: { Name: '' },
        showAddEngagementTypeFields: false,
        blockSaveType: false,
        blockAddEngagementType: false,
      });
    }
  }

  getElementForDelete = (element) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `engagement_types/${element.Id}`,
      searchUpdateSaga: 'engagement_types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (entity) => {
    const { putManageEngagementTypes } = this.props;
    putManageEngagementTypes(entity);
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  }

  render() {
    const {
      engagementTypes,
      listSettings,
    } = this.props;
    const {
      columnsToDisplay, showAddEngagementTypeFields, addEngagementType, blockAddEngagementType,
    } = this.state;
    const {
      searchValue,
      sortColumn,
      sortDirection,
    } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsEngagementTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewEngagementType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search engagement type'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(engagementTypes, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.getElementForDelete}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {showAddEngagementTypeFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
          <div className='c-list__item-column resume-member-inputs c-list__item-column'>
            <UpdateManageItem
              item={addEngagementType}
              onChangeNewDocumentObj={this.getNewEngagementType}
              disableSaveDocument={blockAddEngagementType}
              newDocumentCancel={this.newTypeCancel}
              newDocumentApply={this.newJobTitleApply}
            />
          </div>
        </div> : null}
      </div>
    );
  }
}

ManageEngagementTypes.propTypes = {
  downloadManageEngagementTypes: PropTypes.func.isRequired,
  putManageEngagementTypes: PropTypes.func.isRequired,
  postManageEngagementTypes: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

export default connect((store) => ({
  engagementTypes: store.adminManageLookups.engagementTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  downloadManageEngagementTypes,
  putManageEngagementTypes,
  postManageEngagementTypes,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageEngagementTypes);
