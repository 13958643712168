import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'common/components/badges/badges';
import { getColorFromString } from 'services/getColor';
import CustomIcon from 'common/components/icon/Icon';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import './project-history.css';

export class ProjectHistory extends Component {
  checkDateOrEmptyField = (incomingData) => {
    if (moment(incomingData, 'YYYY-MM-DD').isValid() && incomingData.includes('-')) {
      return moment(incomingData).format('YYYY-MM-DD');
    }
    return String(incomingData);
  }

  handleGetScrollParent = () => {
    const { scrollbarRef } = this.props;

    return scrollbarRef;
  }

  render() {
    const {
      history, changeAmountHistory, hasMoreHistory, isLoading, hasDefoultFinancialAccess, HasFinancialAccess,
    } = this.props;
    const hidenProperty = ['RealizedRevenue', 'BudgetComments', 'Budget', 'rate ', 'rateOvertime',
      'ContractsAttachments', 'contractsAttachments'];
    return (
      <>
        {isLoading ? <div className='history-loader'><Loader type='Oval' color='#F26939' height={40} width={40} /></div> : null}
        {history && history.length ? (
          <InfiniteScroll
            pageStart={1}
            loadMore={changeAmountHistory}
            hasMore={hasMoreHistory}
            useWindow={false}
            initialLoad={false}
            threshold={0}
            getScrollParent={this.handleGetScrollParent}
          >
            {history.map((item) => {
              const isLink = item.Entity && (item.Entity.split('.SOWLink').length === 2
                || item.Entity.split('.POLink').length === 2 || item.Entity.split('.AmendmentLink').length === 2);
              return (
                <div className='history-item' key={item.Id}>
                  <div className='history-changes'>
                    <div>
                      <span>{moment(item.DateOfChange, 'YYYY-MM-DD').format('YYYY-MM-DD')}</span>
                    </div>
                    <div className='history-changes_badge'>
                      <Badge
                        item={item.Author && item.Author.Name}
                        color={item.Author && getColorFromString(item.Author.Name)}
                      />
                    </div>
                    <div>
                      <span>{item.Author && item.Author.Name}</span>
                    </div>
                    <div>
                      <span>{item.TypeOfChange && item.TypeOfChange.Name}</span>
                    </div>
                    <div>
                      {(!hasDefoultFinancialAccess || (hasDefoultFinancialAccess && HasFinancialAccess)
                        || !isLink)
                        ? <span> {`${item.Entity}${item.Property ? `.${item.Property}` : ''}`}</span>
                        : <div className='audit-changes-date'>---</div>}
                    </div>
                  </div>
                  {
                    (!hasDefoultFinancialAccess || (hasDefoultFinancialAccess && HasFinancialAccess)
                      || (!hidenProperty.includes(item.Property) && !isLink))
                      ? (item.TypeOfChange && item.TypeOfChange.Name === 'changed'
                        ? <div className='history-changes-date'>
                          <div>
                            <span>{this.checkDateOrEmptyField(item.PrevValue)}</span>
                          </div>
                          <div>
                            <CustomIcon iconName='arrow-history' />
                          </div>
                          <div>
                            <span>{this.checkDateOrEmptyField(item.NewValue)}</span>
                          </div>
                        </div>
                        : <div className='emptyBlock' />) : <div className='history-changes-date history-changes-date-empty'><span>---</span></div>
                  }
                </div>
              );
            })}
          </InfiniteScroll>
        ) : null
        }
      </>
    );
  }
}

ProjectHistory.propTypes = {
  history: PropTypes.array.isRequired,
};
