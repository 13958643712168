import * as React from 'react';
import { connect } from 'react-redux';
import {
  changeColumnsToDisplay,
  getCertificationsReports,
  resetCertificationsReportsListSettings,
  setCertificationsReports,
  setCertificationsReportsListSettings,
} from '../../actions/certificationsActions';
import { getFilters } from '../../../../common/actions/filtersActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { resetComponent, setComponent } from '../../../../common/actions/headerActions';
import { initialState } from '../../reducers/certificationsReducer';
import isObjectNotEquals from '../../../../utils/object-comparison';
import { emptyArray } from '../../../../utils/const-variable';
import isEmptyObject from '../../../../utils/isEmptyObject';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from '../../../../utils/applicationTitle';
import ListToolbar from '../../../../common/components/list-toolbar/list-toolbar';
import { sortFilterList } from '../../../../common/components/list/sortList';
import Scrollbars from 'react-custom-scrollbars';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from '../../../../services/debounce';
import { List } from '../../../../common/components/list/list';
import { CustomStatusCell } from '../../../../common/components/list/custom-cell/custom-status-cell';

const filterNames = [
  'Offices',
  'EmployeeStatusesNew',
  'CertificationStatuses',
];

export class Certifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMoreReports: true,
      certificationsCount: 0,
    };
  }

  componentDidMount() {
    const {
      setComponent, getFilters, listSettings: {
        searchValue, sortColumn, sortDirection, takeAmountReports,
      },
      getCertificationsReports,
    } = this.props;

    getFilters(filterNames);
    const defaultFilters = this.setDefaultFilters();
    getCertificationsReports(defaultFilters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    setComponent({ title: 'Certifications Report' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      getCertificationsReports, listSettings, reports,
    } = this.props;
    const { certificationsCount } = this.state;
    const {
      filters, searchValue, sortColumn, sortDirection, takeAmountReports,
    } = listSettings;
    if (prevProps.listSettings !== initialState.listSettings && isObjectNotEquals(prevProps.listSettings, listSettings)) {
      getCertificationsReports(filters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    }

    if (reports.certifications && reports.certifications.length !== certificationsCount) {
      this.setState({
        certificationsCount: reports.certifications.length,
      });
    }
  }

  componentWillUnmount() {
    const { setCertificationsReports, resetComponent, resetCertificationsReportsListSettings } = this.props;
    setCertificationsReports(emptyArray);
    resetComponent();
    resetCertificationsReportsListSettings();
  }

  setDefaultFilters = () => {
    const {
      listSettings: { filters }, setCertificationsReportsListSettings,
    } = this.props;
    const currentFilters = {
      ...filters,
      Offices: filters.Offices || [],
      EmployeeStatusesNew: filters.EmployeeStatusesNew || [],
      EmployeeCertificateStatuses: filters.EmployeeCertificateStatuses || [],
    };

    setCertificationsReportsListSettings({ filters: currentFilters });

    return currentFilters;
  };

  applyFilters = (filters) => {
    const { setCertificationsReportsListSettings, textDataForFilters } = this.props;
    const newFilters = {
      ...filters,
      ...textDataForFilters,
    };
    const isExistsFilters = Object.keys(newFilters).some(filter => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setCertificationsReportsListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  };

  resetFilters = () => {
    const { resetCertificationsReportsListSettings } = this.props;
    resetCertificationsReportsListSettings();
  };

  searchCertificationsReports = (searchValue) => {
    const { setCertificationsReportsListSettings } = this.props;
    setCertificationsReportsListSettings({ searchValue });
  };

  changeReportsAmount = (page) => {
    const {
      isLoading,
      listSettings: {
        takeAmountReports,
      },
      setCertificationsReportsListSettings,
    } = this.props;
    const magnificationFactor = 15;
    const increaseParams = takeAmountReports + page * magnificationFactor;
    if (!isLoading) {
      setCertificationsReportsListSettings({ takeAmountReports: increaseParams });
    }
  };

  checkIfNeedMoreReports = (takeAmountReports) => {
    const { totalCount } = this.props;
    this.setState({
      hasMoreReports: takeAmountReports <= totalCount,
    });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setCertificationsReportsListSettings } = this.props;
    setCertificationsReportsListSettings({ sortColumn, sortDirection });
  }

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;

    downloadXLSXDocument(
      filters,
      searchValue,
      'certifications-reports/export-xlsx?SearchValue=',
      'Certifications Report.xlsx',
    );
  }

  render() {
    const {
      columnsToDisplay, offices, employeeTypes, statuses, reports,
      listSettings: {
        sortColumn, sortDirection, filters, searchValue,
      },
    } = this.props;
    const { hasMoreReports } = this.state;

    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          showExportButton
          searchValue={searchValue}
          exportDocument={this.downloadXLSXDocument}
          searchPlaceholder='Search Reports'
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchCertificationsReports}
          columnsToDisplay={columnsToDisplay}
          items={[{
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
          }, {
            name: 'Employee Types',
            id: 'EmployeeTypes',
            options: sortFilterList(employeeTypes) || [],
            multiSelect: true,
            placeholder: 'All Employee Types',
            filtersItem: filters && filters.EmployeeTypes,
          }, {
            name: 'Statuses',
            id: 'EmployeeCertificateStatuses',
            options: sortFilterList(statuses) || [],
            multiSelect: true,
            placeholder: 'All Statuses',
            filtersItem: filters && filters.EmployeeCertificateStatuses,
          }]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <InfiniteScroll
              pageStart={1}
              loadMore={debounce(this.changeReportsAmount, 500)}
              hasMore={hasMoreReports}
              useWindow={false}
              initialLoad={false}
              threshold={500}
            >
              <List
                columnsToDisplay={columnsToDisplay}
                items={reports.certifications}
                hideDots
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                fixedHeader
                dontShowOptions
                config={{
                  'Status': {
                    cell: CustomStatusCell,
                  },
                }}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  offices: store.filtersReducer.filters.Offices,
  employeeTypes: store.filtersReducer.filters.EmployeeStatusesNew,
  statuses: store.filtersReducer.filters.CertificationStatuses,
  listSettings: store.certificationsReducer.listSettings,
  columnsToDisplay: store.certificationsReducer.columnsToDisplay,
  totalCount: store.reportsReducer.certificationsTotalCount,
}), {
  getCertificationsReports,
  getFilters,
  downloadXLSXDocument,
  setComponent,
  resetComponent,
  changeColumnsToDisplay,
  setCertificationsReportsListSettings,
  setCertificationsReports,
  resetCertificationsReportsListSettings,
})(Certifications);
