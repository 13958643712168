import {
  put, takeEvery, call,
} from 'redux-saga/effects';
import {
  GET_XLSX_DOCUMENT,
} from '../actions/xlsx-action';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import http from '../../../services/http';

const fileDownload = require('js-file-download');

function* downloadXLSX(action) {
  try {
    yield put(startRequest());
    const result = yield call(http, {
      method: 'GET',
      url: `${action.payload.url}${encodeURIComponent(action.payload.searchvalue)}`,
      resType: 'blob',
      params: action.payload.filters,
    });

    fileDownload(result.data, action.payload.docName);

    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export default function* xlsxSaga() {
  yield takeEvery(GET_XLSX_DOCUMENT, downloadXLSX);
}
