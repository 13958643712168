import { put, takeEvery, call } from 'redux-saga/effects';
import http from 'services/http';
import { addNewEntity } from 'services/addNewEntity';
import { ADD_ENTITY } from '../actions/addEntityActions';

export function* addEntitySaga(action) {
  try {
    const updateData = addNewEntity(action.payload.entityId, action.payload.data, action.payload.parentEntityId);
    if (updateData) {
      const { config, responseData } = updateData;
      const { mainUrl, additionalUrl } = config;
      const parentEntityId = !!responseData.parentEntityId ? '/' + responseData.parentEntityId : '';

      const response = yield call(http, {
        method: 'POST',
        url: `${mainUrl + parentEntityId + additionalUrl}`,
        data: responseData.data,
      });
      yield put(config.setAction(response.data));
    }
  } catch (e) {
    // error message
  }
}

export default function* addEntityRootSaga() {
  yield takeEvery(ADD_ENTITY, addEntitySaga);
}
