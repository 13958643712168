import React from 'react';

export const renderProjectTypeIcon = (billablilty) => {
  switch (billablilty) {
    case 'Billable':
      return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8.00004 1.33337C4.32004 1.33337 1.33337 4.32004 1.33337 8.00004C1.33337 11.68 4.32004 14.6667 8.00004 14.6667C11.68
            14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8.00004 1.33337ZM8.00004 13.3334C5.06004 13.3334
            2.66671 10.94 2.66671 8.00004C2.66671 5.06004 5.06004 2.66671 8.00004 2.66671C10.94 2.66671 13.3334 5.06004 13.3334
            8.00004C13.3334 10.94 10.94 13.3334 8.00004 13.3334ZM8.20671 7.42671C7.02671 7.12671 6.64671 6.80004 6.64671 6.31337C6.64671
            5.75337 7.17337 5.36004 8.04671 5.36004C8.96671 5.36004 9.31337 5.80004 9.34004 6.45337H10.48C10.4467 5.56004 9.90004
            4.74004 8.82004 4.47337V3.33337H7.26671V4.46004C6.26004 4.67337 5.45337 5.32671 5.45337 6.33337C5.45337 7.52671 6.44671
            8.12671 7.89337 8.47337C9.19337 8.78004 9.45337 9.24004 9.45337 9.72004C9.45337 10.0734 9.19337 10.6467 8.05337
            10.6467C6.98671 10.6467 6.56671 10.1667 6.50671 9.55337H5.36004C5.42671 10.6867 6.26671 11.3267 7.26671
            11.5334V12.6667H8.82671V11.5534C9.84004 11.36 10.64 10.78 10.6467 9.70671C10.64 8.24004 9.38004 7.73337 8.20671 7.42671Z'
            fill='#219653'
          />
        </svg>
      );
    case 'Non-billable Investment':
      return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.72 12.2527V5.52984C14.7199 4.85353 14.3071 4.27163 13.7201 4.02295C14.307 4.27163 14.7199 4.85354 14.72
            5.52984V12.2527C14.72 13.0497 14.1469 13.7154 13.3913 13.8595C14.1469 13.7154 14.72 13.0497 14.72 12.2527ZM13.0842
            13.8884C13.0842 13.8884 13.0842 13.8884 13.0842 13.8884H2.91677C2.91677 13.8884 2.91676 13.8884 2.91676 13.8884C2.91677
            13.8884 2.91678 13.8884 2.91679 13.8884H13.0842ZM9.97294 2.1786C10.2825 2.30976 10.5002 2.61667 10.5002
            2.97347V3.89418L10.5002 3.89418V2.97347C10.5002 2.61667 10.2825 2.30976 9.97294 2.1786ZM13.0841
            3.61418H10.7802V2.97347C10.7802 2.34309 10.2678 1.83069 9.63743 1.83069H6.36342C5.73304 1.83069 5.22064
            2.34309 5.22064 2.97347V3.61418H2.91679C1.9848 3.61418 1.2091 4.28265 1.03813 5.16443H1.00307V5.48239L1.00252
            5.49025C1.00201 5.4976 1.00098 5.51262 1.00098 5.52984V12.2527C1.00098 13.3093 1.86028 14.1684 2.91679 14.1684H13.0842C14.1407
            14.1684 15 13.3093 15 12.2527V5.5298C14.9998 4.47343 14.1406 3.61418 13.0841 3.61418ZM13.6873 9.008V11.9729L13.6871
            12.2529C13.6871 12.5851 13.4164 12.8558 13.0841 12.8558H2.91679C2.58447 12.8558 2.31371 12.585 2.31371 12.2529V9.00494C2.80376
            9.34623 3.39873 9.5478 4.04118 9.5478H6.53439C6.53859 10.1746 7.04937 10.6828 7.67714 10.6828H8.32385C8.95163
            10.6828 9.46241 10.1746 9.46661 9.5478H11.9646C12.6032 9.5478 13.1971 9.34795 13.6873 9.008ZM3.91916 9.26512C3.95961
            9.2669 4.00029 9.2678 4.04118 9.2678L6.81435 9.26779H4.04116C4.00027 9.26779 3.9596 9.2669 3.91916 9.26512ZM7.50348
            10.3852C7.5596 10.3967 7.61768 10.4028 7.67714 10.4028H8.32385C8.32385 10.4028 8.32384 10.4028 8.32383
            10.4028H7.67712C7.61766 10.4028 7.55959 10.3967 7.50348 10.3852ZM8.49752 10.3852C8.8903 10.3046 9.18663 9.95628 9.18663
            9.54001V9.2678H11.9646C11.9646 9.2678 11.9646 9.26779 11.9646 9.26779H9.18661V9.54001C9.18661 9.95627 8.89029 10.3046
            8.49752 10.3852ZM12.4414 9.22619C13.0362 9.12125 13.567 8.82364 13.9673 8.39994V8.39997C13.8799 8.49242 13.7864 8.57887
            13.6873 8.65863C13.3323 8.94436 12.9064 9.14415 12.4414 9.22619ZM2.03371 8.39504C2.0337 8.39503 2.03369 8.39502 2.03368
            8.39501V12.2529C2.03368 12.6788 2.337 13.0354 2.73904 13.1178C2.33701 13.0354 2.03371 12.6788 2.03371 12.2529V8.39504ZM13.0841
            4.64691H2.91664C2.44658 4.64691 2.06208 5.01648 2.0361 5.48027L2.03565 6.50969C2.03565 7.42315 2.64974 8.19597 3.48671
            8.43711C2.64973 8.19598 2.03563 7.42315 2.03563 6.50969V5.48027H2.03607C2.06206 5.01648 2.44656 4.64691 2.91662
            4.64691H13.084C13.084 4.64691 13.0841 4.64691 13.0841 4.64691ZM9.18648 7.91853C9.18648 7.50226 8.89015 7.1539 8.49737
            7.07332C8.89014 7.15391 9.18646 7.50227 9.18646 7.91853V8.51507L9.18648 8.51507V7.91853ZM11.9646
            8.23507H9.46649V7.91853C9.46649 7.28815 8.95408 6.77575 8.3237 6.77575H7.677C7.04662 6.77575 6.53422 7.28815 6.53422
            7.91853V8.23507H4.04118C3.09007 8.23507 2.31565 7.46074 2.31565 6.50969L2.31566 5.49593C2.33341 5.17902 2.59658 4.92691
            2.91664 4.92691H13.0841C13.4164 4.92691 13.6871 5.19773 13.6871 5.52984V6.60143L13.6864 6.61296C13.6319 7.52236 12.8751
            8.23507 11.9646 8.23507ZM6.53337 3.61418V3.14342H9.46748V3.61418H6.53337ZM8.15375
            9.36991H7.84695V8.08849H8.15375V9.36991ZM6.25337 2.97347C6.25337 2.91389 6.30384 2.86342
            6.36342 2.86342H9.63743C9.63742 2.86342 9.63742 2.86342 9.63741 2.86342H6.3634C6.30382 2.86342 6.25335 2.91389 6.25335
            2.97347V3.89418L6.25337 3.89418V2.97347ZM7.56695 9.53986V7.91853C7.56695 7.85895 7.61742 7.80848 7.677 7.80848H8.3237C8.3237
            7.80848 8.32369 7.80848 8.32368 7.80848H7.67697C7.61739 7.80848 7.56692 7.85895 7.56692 7.91853V9.53986C7.56692 9.59539
            7.61055 9.64279 7.66457 9.64918C7.61056 9.64278 7.56695 9.59538 7.56695 9.53986Z'
            fill='#F2994A'
          />
        </svg>
      );
    case 'Non-billable Overbuild':
      return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M12.6667 10V12.6667H3.33333V10H12.6667ZM13.3333 8.66667H2.66667C2.3 8.66667 2 8.96667 2 9.33333V13.3333C2 13.7 2.3 14
            2.66667 14H13.3333C13.7 14 14 13.7 14 13.3333V9.33333C14 8.96667 13.7 8.66667 13.3333 8.66667ZM12.6667
            3.33333V6H3.33333V3.33333H12.6667ZM13.3333 2H2.66667C2.3 2 2 2.3 2 2.66667V6.66667C2 7.03333 2.3 7.33333 2.66667
            7.33333H13.3333C13.7 7.33333 14 7.03333 14 6.66667V2.66667C14 2.3 13.7 2 13.3333 2Z'
            fill='#F2994A'
          />
        </svg>
      );
    case 'Non-billable Training':
      return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.7133 9.90665L14.6666 8.95331L13.7133 7.99998L11.3333 10.38L5.61998 4.66665L7.99998 2.28665L7.04665 1.33331L6.09331
            2.28665L5.13998 1.33331L3.71331 2.75998L2.75998 1.80665L1.80665 2.75998L2.75998 3.71331L1.33331 5.13998L2.28665
            6.09331L1.33331 7.04665L2.28665 7.99998L4.66665 5.61998L10.38 11.3333L7.99998 13.7133L8.95331 14.6666L9.90665
            13.7133L10.86 14.6666L12.2866 13.24L13.24 14.1933L14.1933 13.24L13.24 12.2866L14.6666 10.86L13.7133 9.90665Z'
            fill='#F2994A'
          />
        </svg>
      );
    case 'Non-billable Infrustructure':
      return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8 3.152L12 6.752V13H10.4V8.2H5.6V13H4V6.752L8 3.152ZM8 1L0 8.2H2.4V14.6H7.2V9.8H8.8V14.6H13.6V8.2H16L8 1Z'
            fill='#F2994A'
          />
        </svg>
      );
    default:
      break;
  }
};
