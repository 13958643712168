export const GET_MANAGE_CERTIFICATES = '@admin/GET_MANAGE_CERTIFICATES';
export const SET_MANAGE_CERTIFICATES = '@admin/SET_MANAGE_CERTIFICATES';
export const PUT_MANAGE_CERTIFICATES = '@admin/PUT_MANAGE_CERTIFICATES';
export const POST_MANAGE_CERTIFICATES = '@admin/POST_MANAGE_CERTIFICATES';

export const loadManageCertificates = () => ({
  type: GET_MANAGE_CERTIFICATES,
});

export const setManageCertificates = (certificates) => ({
  type: SET_MANAGE_CERTIFICATES,
  payload: certificates,
});

export const putManageCertificates = (certificate) => ({
  type: PUT_MANAGE_CERTIFICATES,
  payload: certificate,
});

export const postManageCertificates = (certificate) => ({
  type: POST_MANAGE_CERTIFICATES,
  payload: certificate,
});
