import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import OfficesListPage from './components/offices-list/offices-list-page';
import OfficesDetailPage from './components/offices-details/offices-details';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { loadOfficeLocations } from 'pages/projects/actions/filterLocationsActions.js';
import { getCountries, resetOffices, resetListSettings } from './actions/officesActions';

export class OfficesPage extends Component {
  componentDidMount() {
    const {
      getCountries, loadOfficeLocations,
    } = this.props;
    getCountries();
    loadOfficeLocations();
  }

  componentWillUnmount() {
    const { resetOffices, resetListSettings } = this.props;
    resetListSettings();
    resetOffices();
  }

  OfficesDetailPage = (props) => {
    return (
      <OfficesDetailPage
        {...this.props}
        {...props}
      />
    );
  };

  render() {
    return (
      <DocumentTitle title={applicationTitle.getTitile('offices')}>
        <Fragment>
          <Switch>
            <Route exact path='/offices' component={OfficesListPage} />
            <Route exact path='/offices/:id' render={this.OfficesDetailPage} />
            <Redirect to='/offices' />
          </Switch>
        </Fragment>
      </DocumentTitle>
    );
  }
}

OfficesPage.propTypes = {
  getCountries: PropTypes.func,
  EditOfficeBasicInfo: PropTypes.bool,
  ManageOfficeCalendar: PropTypes.bool,
  ManageOfficeLdapPath: PropTypes.bool,
};

export default connect(() => ({}), {
  getCountries,
  loadOfficeLocations,
  resetOffices,
  resetListSettings,
})(OfficesPage);
