import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import history from 'utils/history';
import {
  getClientById,
  putClient,
  filterClientInfo,
  clearErrorMessage,
  transitionToProjectData,
  setListSettings,
  setChangeWorkingPeriodRestriction,
  createWorkingPeriodRestriction,
  updateWorkingPeriodRestriction,
  deleteWorkingPeriodRestriction,
  getSowTypes,
} from '../../actions/clientsActions';
import { getSelectedTab } from '../../../../common/actions/selectedTabActions';
import { Badge } from 'common/components/badges/badges';
import { getColorFromString } from 'services/getColor';
import Tabs from 'common/components/tabs/tabs';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { List } from 'common/components/list/list';
import TextField from 'common/components/form-items/input/text-field';
import { getId } from 'services/location';
import { sortList } from '../../../../common/components/list/sortList';
import './client-details-page.css';
import { showDialog } from 'common/actions/confirmationDialogActions';
import CustomIcon from 'common/components/icon/Icon';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { recursiveSearch } from 'services/recursiveSearch';
import { CellLink } from 'common/components/list/custom-cell/cell-link';
import { getDeleteConfig } from 'utils/getDeleteConfig.js';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { CellTimeleft } from 'common/components/list/custom-cell/cell-timeleft';
import { Slider } from '../../../../common/components/slider/slider';
import WorkingPeriodRestrictionModal from './working-period-restriction-modal';
import { ConfirmationDialog } from '../../../../common/components/confirmation-dialog/confirmation-dialog';

const PROJECTS_TAB_INDEX = 0;
const CONTRACTS_TAB_INDEX = 1;
const CONTACTS_TAB_INDEX = 2;
const WORKING_PERIOD_RESTRICTIONS_TAB_INDEX = 3;

export class ClientDetailPage extends Component {
  constructor(props) {
    super(props);
    const { selectedTab, error } = this.props;
    this.state = {
      client: '',
      changeName: false,
      selectedTab,
      filters: {},
      Id: '',
      error: !!error,
      isPossibleToEdit: false,
      isOpenDeletePopup: false,
      columnsToDisplay: [
        [
          {
            Name: 'Project Name', Id: 'Name', className: 'medium-col',
          },
          {
            Name: 'Office', Id: 'Offices', className: 'medium-col', isArray: true,
          },
          {
            Name: 'Contract', Id: 'SowPo', className: 'medium-col', isArray: true,
          },
          {
            Name: 'Business Development Manager', Id: 'AccountManager', className: 'medium-col', isObject: true,
          },
          {
            Name: 'Client Manager', Id: 'ClientManager', className: 'medium-col', isObject: true,
          },
          {
            Name: 'Start Date', Id: 'StartDate', isDate: true,
          },
          {
            Name: 'End Date', Id: 'EndDate', isDate: true,
          },
          {
            Name: 'Status', Id: 'Status', isObject: false, isNeedProjectStatus: true,
          },
        ],
        [
          {
            Name: 'Contract', Id: 'Contract', className: 'big-col', isObject: false,
          },
          {
            Name: 'Type', Id: 'Type', className: 'big-col',
          },
          {
            Name: 'Project', Id: 'Project', className: 'big-col', isObject: true,
          },
          {
            Name: 'Start Date', Id: 'StartDate', isDate: true,
          },
          {
            Name: 'End Date', Id: 'EndDate', isDate: true,
          },
          {
            Name: 'Billing Code', Id: 'BillingCode', className: 'small-col',
          },
        ],
        [
          {
            Name: 'Name', Id: 'Name', className: 'biggest-col', isObject: false,
          },
          {
            Name: 'Job title', Id: 'JobTitle', className: 'biggest-col', isObject: false,
          },
          {
            Name: 'Email', Id: 'Email', className: 'biggest-col', isObject: false,
          },
          {
            Name: 'Cell', Id: 'Cell', className: 'biggest-col', isObject: false,
          },
          {
            Name: 'Skype', Id: 'Skype', className: 'biggest-col', isObject: false,
          },
        ],
        [
          {
            Name: 'Sow Type', Id: 'SowType', className: 'without-width-col', isObject: true,
          },
          {
            Name: 'Max period (months)', Id: 'MaxPeriod', className: 'big-col',
          },
          {
            Name: 'Interval (months)', Id: 'Interval', className: 'big-col',
          },
        ],
      ],
      showAddWorkingPeriodRestrictionModal: false,
      isEditWorkingPeriodRestrictionMode: false,
      editWorkingPeriodRestrictionObject: {},
      showDeleteWorkingPeriodRestrictionDialog: false,
      deleteWorkingPeriodRestrictionId: '',
      isWorkingPeriodRestrictionsEnabled: false,
    };
    this.contactDeleteConfig = getDeleteConfig(`clients/${getId()}/client-contacts/`, 'client/contacts');
  }

  componentDidMount() {
    const {
      getClientById, setComponent, getSowTypes, clientInfo,
    } = this.props;
    const id = getId();
    getClientById(id);
    getSowTypes();
    this.setState({
      Id: id,
      isWorkingPeriodRestrictionsEnabled: clientInfo && clientInfo.IsWorkingPeriodRestrictionEnabled,
    });
    setComponent({ returnButton: { text: 'Clients', goToPreviousPage: this.goToClientsList } });
  }

  componentDidUpdate(prevProps, prevState) {
    const { client, error, selectedTab } = this.props;
    const { client: clientFromState } = this.state;
    if (prevProps.client !== client) {
      this.setState({ client });
    }
    if (client && prevState.isWorkingPeriodRestrictionsEnabled !== client.IsWorkingPeriodRestrictionEnabled) {
      this.setState({ isWorkingPeriodRestrictionsEnabled: client.IsWorkingPeriodRestrictionEnabled });
    }
    if (prevState.client === client && client !== clientFromState) {
      this.setState({ isPossibleToEdit: true });
    }
    if (prevProps.error !== error) {
      this.setState({ error });
    }
    if (prevProps.selectedTab !== selectedTab) {
      this.setState({ selectedTab });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  onTab = (selectedTab) => {
    const { filters, Id } = this.state;
    const { getSelectedTab, filterClientInfo } = this.props;
    getSelectedTab(selectedTab);
    this.setState({
      filters: {
        ...filters,
        Projects: null,
        Contracts: null,
        Contacts: null,
      },
    }, () => {
      filterClientInfo(Id, '');
    });
  };

  handleChangeClientData = ({ target }) => {
    const { clearErrorMessage } = this.props;
    const { client } = this.state;
    clearErrorMessage();
    this.setState({
      client: {
        ...client,
        [target.name]: target.value,
      },
    });
  };

  onNameSpan = () => {
    const { changeName, client } = this.state;
    const { clearErrorMessage } = this.props;
    this.setState({
      changeName: !changeName,
    }, () => clearErrorMessage());
    if (!client.Name) {
      this.setState({
        // eslint-disable-next-line react/destructuring-assignment
        client: this.props.client,
      });
    }
  }

  onName = () => {
    const { changeName, client, isPossibleToEdit } = this.state;
    const { putClient } = this.props;
    this.setState({
      changeName: !changeName,
    });
    if (!client.Name.trim()) {
      this.setState({
        // eslint-disable-next-line react/destructuring-assignment
        client: this.props.client,
      });
    }
    if (isPossibleToEdit && client.Name.trim()) {
      putClient(client, 'Name');
    }
  };

  toggleDeleteOptions = () => {
    const { isOpenDeletePopup } = this.state;
    this.setState({
      isOpenDeletePopup: !isOpenDeletePopup,
    });
  }

  deleteProject = () => {
    const { showDialog, client: { Id } } = this.props;
    const dataForDeleting = {
      url: `clients/${Id}`,
      searchUpdateSaga: 'deleteClients',
      history: '/clients',
    };
    showDialog(dataForDeleting);
  }

  setSearchValue = (tabName) => (searchValue) => {
    const { setListSettings, listSettings } = this.props;
    setListSettings({
      ...listSettings,
      detailsTabsState: {
        ...listSettings.detailsTabsState,
        [tabName]: {
          ...listSettings.detailsTabsState[tabName],
          searchValue,
        },
      },
    });
  }

  setSortSettings = (tabName) => (sortColumn, sortDirection) => {
    const { setListSettings, listSettings } = this.props;
    setListSettings({
      ...listSettings,
      detailsTabsState: {
        ...listSettings.detailsTabsState,
        [tabName]: {
          ...listSettings.detailsTabsState[tabName],
          sortColumn,
          sortDirection,
        },
      },
    });
  }

  goToClientsList = () => {
    history.push({ pathname: '/clients' });
  }

  addNewContract = () => {
    const { client } = this.props;
    history.push({ pathname: `/clients/${client.Id}/addcontacts` });
  }

  editClientContacts = (contactId) => {
    const { client } = this.props;
    history.push({ pathname: `/clients/${client.Id}/editcontacts/${contactId}` });
  }

  getLinkProject = (id) => {
    return { pathname: `/projects/${id}` };
  }

  getContractUrlType = (type, action) => {
    switch (type) {
      case 'SOW':
        return `${action}-sow`;
      case 'PO':
        return `${action}-po`;
      case 'AMENDMENT':
        return `${action}-amendment`;
      default: break;
    }
  }

  goToContract = (id, col, item) => {
    const { transitionToProjectData, ManageClientContact } = this.props;
    const isEditContratc = ManageClientContact ? 'edit' : 'view';
    const urlType = this.getContractUrlType(item.Type, isEditContratc);
    transitionToProjectData(item.Project.Id, `/projects/${item.Project.Id}/${urlType}/${id}`);
  }

  handleChangeWorkingPeriodRestriction = () => {
    const { client, setChangeWorkingPeriodRestriction, getSelectedTab } = this.props;
    const newValue = !client.IsWorkingPeriodRestrictionEnabled;
    setChangeWorkingPeriodRestriction(client.Id, newValue);
    if (client.IsWorkingPeriodRestrictionEnabled) {
      getSelectedTab(PROJECTS_TAB_INDEX);
    }
    this.setState({
      isWorkingPeriodRestrictionsEnabled: newValue,
    });
  }

  getTabsList = () => {
    const { client } = this.props;
    if (client.IsWorkingPeriodRestrictionEnabled) {
      return ['Projects', 'Contracts', 'Contacts', 'Working Period Restrictions'];
    }

    return ['Projects', 'Contracts', 'Contacts'];
  }

  addNewWorkingPeriodRestriction = () => {
    this.setState({
      showAddWorkingPeriodRestrictionModal: true,
      isEditWorkingPeriodRestrictionMode: false,
    });
  }

  addWorkingPeriodRestriction = (wprObject, isEdit) => {
    const { client, updateWorkingPeriodRestriction, createWorkingPeriodRestriction } = this.props;
    if (isEdit) {
      const { editWorkingPeriodRestrictionObject } = this.state;
      const updatedObject = {
        ...wprObject,
        Id: editWorkingPeriodRestrictionObject.Id,
      };
      updateWorkingPeriodRestriction(client.Id, updatedObject);
    } else {
      createWorkingPeriodRestriction(client.Id, wprObject);
    }
  }

  closeWorkingPeriodRestrictionModal = () => {
    this.setState({
      showAddWorkingPeriodRestrictionModal: false,
      isEditWorkingPeriodRestrictionMode: false,
    });
  }

  editWorkingPeriodRestriction = (data) => {
    this.setState({
      editWorkingPeriodRestrictionObject: data.item,
      isEditWorkingPeriodRestrictionMode: true,
    }, this.setState({
      showAddWorkingPeriodRestrictionModal: true,
    }));
  }

  openDeleteWorkingPeriodRestrictionDialog = (id) => {
    this.setState({
      showDeleteWorkingPeriodRestrictionDialog: true,
      deleteWorkingPeriodRestrictionId: id,
    });
  }

  closeDeleteWorkingPeriodRestrictionDialog = () => {
    this.setState({
      showDeleteWorkingPeriodRestrictionDialog: false,
      deleteWorkingPeriodRestrictionId: '',
    });
  }

  confirmDeleteWorkingPeriodRestrictionDialog = () => {
    const { client, deleteWorkingPeriodRestriction } = this.props;
    const { deleteWorkingPeriodRestrictionId } = this.state;
    deleteWorkingPeriodRestriction(client.Id, deleteWorkingPeriodRestrictionId);
    this.closeDeleteWorkingPeriodRestrictionDialog();
  }

  sortWorkingPeriodRestrictions = (first, second) => {
    // keep item with SowType.Id == 0 last in the list
    if (first.SowType && first.SowType.Id === 0) return 1;
    if (second.SowType && second.SowType.Id === 0) return -1;
    return 0;
  }

  checkIfWPRIsDeletable = (wpr) => {
    return (wpr.SowType && wpr.SowType.Id !== 0);
  }

  render() {
    const {
      client, selectedTab, columnsToDisplay, changeName, error, isOpenDeletePopup,
      showAddWorkingPeriodRestrictionModal, isEditWorkingPeriodRestrictionMode,
      editWorkingPeriodRestrictionObject, showDeleteWorkingPeriodRestrictionDialog,
      isWorkingPeriodRestrictionsEnabled,
    } = this.state;
    const {
      EditClientBasicInfo, showDialog, ManageClientContact, CanCreateClient, listSettings, settings,
      sowTypes,
    } = this.props;
    const {
      projects: projectsState,
      contracts: contractsState,
      contacts: contactsState,
      workingPeriodRestrictions: workingPeriodRestrictionsState,
    } = listSettings.detailsTabsState;
    const filteredSowTypes = sowTypes.filter(item => client.WorkingPeriodRestrictions
      && !client.WorkingPeriodRestrictions.find(wpr => wpr.SowType && wpr.SowType.Id === item.Id));
    return (
      <Scrollbars
        autoHide
        autoHideTimeout={300}
        hideTracksWhenNotNeeded
      >
        <div className='page-container'>
          <div className='project-info'>
            <div className='client-info__header'>
              <div className='project-info__header__part'>
                {
                  client && client.Name ? <Badge
                    item={client.Name}
                    color={getColorFromString(client.Name)}
                  />
                    : ''
                }
                {
                  changeName && EditClientBasicInfo ? <TextField
                    inputClassName='change-name__input'
                    formClassName='change-border__input'
                    showClear={false}
                    autoFocus
                    onBlur={this.onName}
                    value={client.Name}
                    onChange={this.handleChangeClientData}
                    clearClassName='change__clear-svg'
                    name='Name'
                  />
                    : <span className='employee-title' onClick={this.onNameSpan}>{client.Name ? client.Name : ' '}</span>
                }
                <Slider
                  label='Working Period Restriction'
                  defaultChecked={false}
                  isChecked={isWorkingPeriodRestrictionsEnabled}
                  onChange={this.handleChangeWorkingPeriodRestriction}
                />
                {error ? <p className='project-input-error clients-name-error'>{error}</p> : null}
              </div>
              {
                CanCreateClient
                  ? <div className='c-list__options-column c-list__options-column__svg'>
                    <CustomIcon iconName='three-dots' onClick={this.toggleDeleteOptions} />
                    {
                      isOpenDeletePopup
                        ? <div className='c-list-item__options-column__container' onMouseLeave={this.toggleDeleteOptions}>
                          <div className='c-list-item__options-column__option' onClick={this.deleteProject}>
                            <span>Delete</span>
                          </div>
                        </div>
                        : null
                    }
                  </div>
                  : null
              }
            </div>
          </div>
          <Tabs
            className='project-info__tabs'
            list={this.getTabsList()}
            selectedIndex={selectedTab}
            onTab={this.onTab}
          />
          {
            selectedTab === PROJECTS_TAB_INDEX ? <ListToolbar
              key={`ListToolbar-${PROJECTS_TAB_INDEX}`}
              searchPlaceholder='Search Projects'
              onSearchClick={this.setSearchValue('projects')}
              searchValue={projectsState.searchValue}
              showAddButton={false}
              showExportButton={false}
              detailsStyles
            /> : selectedTab === CONTRACTS_TAB_INDEX ? <ListToolbar
              key={`ListToolbar-${CONTRACTS_TAB_INDEX}`}
              searchPlaceholder='Search Contracts'
              onSearchClick={this.setSearchValue('contracts')}
              searchValue={contractsState.searchValue}
              showAddButton={false}
              showExportButton={false}
              detailsStyles
            /> : selectedTab === CONTACTS_TAB_INDEX ? <ListToolbar
              key={`ListToolbar-${CONTACTS_TAB_INDEX}`}
              searchPlaceholder='Search Contacts'
              onSearchClick={this.setSearchValue('contacts')}
              searchValue={contactsState.searchValue}
              showAddButton={ManageClientContact}
              showExportButton={false}
              detailsStyles
              onAddNew={this.addNewContract}
            /> : selectedTab === WORKING_PERIOD_RESTRICTIONS_TAB_INDEX
              && isWorkingPeriodRestrictionsEnabled ? <ListToolbar
                key={`ListToolbar-${WORKING_PERIOD_RESTRICTIONS_TAB_INDEX}`}
                showAddButton={EditClientBasicInfo}
                showExportButton={false}
                detailsStyles
                onAddNew={this.addNewWorkingPeriodRestriction}
                disabled={!EditClientBasicInfo}
                searchField={false}
              /> : null
          }
          {
            selectedTab === PROJECTS_TAB_INDEX ? <List
              key={`List-${PROJECTS_TAB_INDEX}`}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={
                client.Projects
                  && recursiveSearch(sortList(client.Projects, projectsState.sortColumn, projectsState.sortDirection), projectsState.searchValue)
              }
              setSortSettings={this.setSortSettings('projects')}
              sortColumnName={projectsState.sortColumn}
              sortDirection={projectsState.sortDirection}
              hideDots
              dontShowOptions
              transition={this.getLinkProject}
              config={{
                Name: { cell: CellLinkForRedirect },
                Status: { cell: CustomStatusCell },
                EndDate: {
                  cell: CellTimeleft,
                  daysToExpire: settings.ProjectExpiration,
                },
              }}
            /> : selectedTab === CONTRACTS_TAB_INDEX ? <List
              key={`List-${CONTRACTS_TAB_INDEX}`}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={
                client.Contracts
                    && recursiveSearch(sortList(client.Contracts, contractsState.sortColumn, contractsState.sortDirection), contractsState.searchValue)
              }
              setSortSettings={this.setSortSettings('contracts')}
              sortColumnName={contractsState.sortColumn}
              sortDirection={contractsState.sortDirection}
              hideDots
              dontShowOptions
              transition={this.goToContract}
              config={{
                Contract: { cell: CellLink },
                EndDate: {
                  cell: CellTimeleft,
                  daysToExpire: settings.ContractFirstNotification,
                },
              }}
            /> : selectedTab === CONTACTS_TAB_INDEX ? <List
              key={`List-${CONTACTS_TAB_INDEX}`}
              columnsToDisplay={columnsToDisplay[selectedTab]}
              items={
                client.Contacts
                    && recursiveSearch(sortList(client.Contacts, contactsState.sortColumn, contactsState.sortDirection), contactsState.searchValue)
              }
              setSortSettings={this.setSortSettings('contacts')}
              sortColumnName={contactsState.sortColumn}
              sortDirection={contactsState.sortDirection}
              onEditClick={this.editClientContacts}
              onDeleteClick={showDialog}
              deleteConfig={this.contactDeleteConfig}
              isHaveRights={ManageClientContact}
              hideAddNewEntity
            /> : selectedTab === WORKING_PERIOD_RESTRICTIONS_TAB_INDEX
              && isWorkingPeriodRestrictionsEnabled ? (
                <>
                  {showAddWorkingPeriodRestrictionModal && <WorkingPeriodRestrictionModal
                    closeDialog={this.closeWorkingPeriodRestrictionModal}
                    confirmDialog={this.addWorkingPeriodRestriction}
                    isEditMode={isEditWorkingPeriodRestrictionMode}
                    editWorkingPeriodRestrictionObject={editWorkingPeriodRestrictionObject}
                    sowTypes={isEditWorkingPeriodRestrictionMode ? sowTypes : filteredSowTypes}
                  />}
                  <List
                    key={`List-${WORKING_PERIOD_RESTRICTIONS_TAB_INDEX}`}
                    columnsToDisplay={columnsToDisplay[selectedTab]}
                    items={
                      client.WorkingPeriodRestrictions
                        && sortList(
                          client.WorkingPeriodRestrictions,
                          workingPeriodRestrictionsState.sortColumn,
                          workingPeriodRestrictionsState.sortDirection,
                        ).sort(this.sortWorkingPeriodRestrictions)
                    }
                    setSortSettings={this.setSortSettings('workingPeriodRestrictions')}
                    sortColumnName={workingPeriodRestrictionsState.sortColumn}
                    sortDirection={workingPeriodRestrictionsState.sortDirection}
                    isHaveRights={EditClientBasicInfo}
                    hideAddNewEntity
                    getFullEditItem
                    onEditClick={this.editWorkingPeriodRestriction}
                    onDeleteClick={this.openDeleteWorkingPeriodRestrictionDialog}
                    hideDots={!EditClientBasicInfo}
                    dontShowOptions={!EditClientBasicInfo}
                    dontUseDeleteConfig
                    isIndividualDeletable
                    checkIfItemIsDeletable={this.checkIfWPRIsDeletable}
                  />
                  {showDeleteWorkingPeriodRestrictionDialog
                  && <ConfirmationDialog
                    dialogHeader='REMOVE WORKING PERIOD RESTRICTION'
                    dialogTitle='Are you sure to remove this item?'
                    closeButtonTitle='Cancel'
                    confirmButtonTitle='Accept'
                    closeDialog={this.closeDeleteWorkingPeriodRestrictionDialog}
                    confirmDialog={this.confirmDeleteWorkingPeriodRestrictionDialog}
                  />}
                </>) : null
          }
        </div>
      </Scrollbars>
    );
  }
}

ClientDetailPage.propTypes = {
  client: PropTypes.object,
  getClientById: PropTypes.func,
  filterClientInfo: PropTypes.func,
  putClient: PropTypes.func,
  EditClientBasicInfo: PropTypes.bool,
  ManageClientContact: PropTypes.bool,
  CanCreateClient: PropTypes.bool,
  error: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  selectedTab: PropTypes.number,
  getSelectedTab: PropTypes.func,
  showDialog: PropTypes.func,
  listSettings: PropTypes.object,
  setListSettings: PropTypes.func,
};

export default connect((store) => ({
  client: store.clientsReducer.clientInfo,
  error: store.clientsReducer.error,
  selectedTab: store.selectedTabReducer.selectedTab,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.clientsReducer.listSettings,
  settings: store.adminReducer.settings,
  sowTypes: store.clientsReducer.sowTypes,
}), {
  getClientById,
  filterClientInfo,
  putClient,
  clearErrorMessage,
  getSelectedTab,
  showDialog,
  transitionToProjectData,
  setComponent,
  resetComponent,
  setListSettings,
  setChangeWorkingPeriodRestriction,
  createWorkingPeriodRestriction,
  updateWorkingPeriodRestriction,
  deleteWorkingPeriodRestriction,
  getSowTypes,
})(ClientDetailPage);
