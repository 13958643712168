import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import history from 'utils/history';

import { getStatus, getEmployeeStatus } from '../../actions/roleModalActions';
import {
  createNewProject, checkProjectNameDublicateError, getManagersByOffice, getAllCurrencies, getAllEngagementTypes,
} from '../../actions/projectsActions';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import ProgressBarComponent from 'common/components/progress-bar/progress-bar';
import Step1 from './step-1.js';
import Step2 from './step-2.js';
import Step4 from './step-4.js';
import RoleModal from '../../components/role-modal/role-modal';
import EditRoleCreateProject from '../../components/role-modal/edit-role-create-project';
import { getManagePaymentTerms } from 'pages/admin/actions/adminManagePaymentTerms';
import {
  PROJECT_TYPE_BILLABLE,
  CREATE_PROJECT_SOW_TYPE_TIME_AND_MATERIAL,
} from 'utils/const-variable';
import { loadManageDocumentTypes } from 'pages/admin/actions/adminManageDocumentTypes';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { loadSOWRenewalStatuses } from 'pages/admin/actions/adminManageSOWRenewalStatus';

import './create-project.css';
import { getManageInvoiceDates } from '../../../admin/actions/adminManageInvoiceDates';

export class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    const { location: locationProps } = this.props;
    this.state = {
      step: 0,
      aliases: '',
      name: '',
      projectCode: '',
      offices: [],
      primaryOffices: [],
      clientId: '',
      SOWClientId: '',
      SOWClientManagerId: '',
      description: '',
      accountManagerId: '',
      deliveryManagerId: '',
      deliveryManagers: [],
      currentDeliveryManagers: [],
      EngineeringManagers: [],
      currentEngineeringManagers: [],
      clientManagerId: '',
      budget: '',
      approvalStatus: '',
      SOWPaymentTerm: '',
      SOWInvoiceDate: '',
      ProjectBillingCode: '',
      POBillingCode: '',
      SOWBillingCode: '',
      startDate: null,
      endDate: null,
      projectType: '',
      projectTypeName: '',
      isStep1FieldsRequired: false,
      isStep2FieldsRequired: false,
      isStep3FieldsRequired: false,
      disabledStep2Selects: true,
      disabledStep1Selects: true,
      SOWNumber: '',
      SOWBudget: '',
      SOWRevenue: '',
      SOWType: '',
      startDateSOW: null, // new Date(Date.now()),
      endDateSOW: null, // new Date(Date.now()),
      SOWDescription: '',
      SOWBudgetComments: '',
      businessGroup: '',
      projectTeam: '',
      SOWDocumentStatusesId: '',
      PODocumentStatusesId: '',
      PONumber: '',
      POBudget: '',
      PORevenue: '',
      POStatus: '',
      POClientId: '',
      startDatePO: null, // new Date(Date.now()),
      endDatePO: null, // new Date(Date.now()),
      POApprover: '',
      PODescription: '',
      POBudgetComments: '',
      stepNames: [],
      invoiceApprover: '',
      travelBudget: false,
      travelReimbursement: false,
      SOWDocuments: [],
      PODocuments: [],
      roles: [],
      editRoles: 0,
      isBillableTrainee: false,
      POPaymentTerm: '',
      POInvoiceDate: '',
      currency: '',
      SowRenewalStatuses: [],
      engagementTypeId: '',
      historyLocation: locationProps.state,
    };
  }

  componentDidMount() {
    const {
      documentStatuses, getStatus, getEmployeeStatus, loadManageDocumentTypes, getManagePaymentTerms, getAllCurrencies,
      setComponent, loadSOWRenewalStatuses, resetDefaultFilters, getAllEngagementTypes, getManageInvoiceDates,
    } = this.props;
    getStatus();
    getEmployeeStatus();
    this.findArrValue(documentStatuses);
    this.setInitialValueForRenewalStatuses();
    getAllEngagementTypes();
    loadManageDocumentTypes();
    getAllCurrencies();
    getManagePaymentTerms();
    loadSOWRenewalStatuses();
    setComponent({ returnButton: { text: 'Projects', goToPreviousPage: this.goTyProjectsList } });
    resetDefaultFilters && resetDefaultFilters();
    getManageInvoiceDates();
  }

  componentDidUpdate(prevProps, prevState) {
    const { documentStatuses, projectTypes, allRenewalStatuses } = this.props;
    const { projectType } = this.state;
    if (prevProps.documentStatuses !== documentStatuses) {
      this.findArrValue(documentStatuses);
    }
    if (prevProps.allRenewalStatuses !== allRenewalStatuses) {
      this.setInitialValueForRenewalStatuses();
    }
    if (prevState.projectType !== projectType) {
      this.autoGenerateSOWNumber(projectTypes);
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  findArrValue = (arr) => {
    arr.forEach((item) => {
      if (item.Name === 'Draft') {
        this.setState({
          SOWDocumentStatusesId: item.Id,
          PODocumentStatusesId: item.Id,
        });
      }
    });
  };

  autoGenerateSOWNumber = (projectTypes) => {
    const { projectType } = this.state;
    const getType = projectTypes.find(item => item.Id === projectType);
    this.setState({
      SOWNumber: getType && getType.Name.includes('Non-billable') ? 'SOW-1' : '',
    });
  };

  onChangeProjectType = (projectType) => {
    this.setState({
      projectType,
    });
  };

  onChangeStep = (state) => {
    const { step } = state;
    this.setState({
      ...this.state,
      ...state,
      step,
    });
  };

  onBack = (state) => {
    const { step } = this.state;
    this.setState({
      ...this.state,
      ...state,
      step: step - 1,
    });
  };

  onSubmit = (state) => {
    const { createNewProject } = this.props;
    this.setState({
      ...this.state,
      ...state,
    }, () => createNewProject(this.state));
  };

  setClientId = (clientId) => {
    const { SOWClientId } = this.state;
    this.setState(
      SOWClientId === ''
        ? {
          SOWClientId: clientId,
          POClientId: clientId,
        }
        : {},
    );
  };

  setSOWType = (type) => {
    this.setState({
      SOWType: type === PROJECT_TYPE_BILLABLE ? '' : CREATE_PROJECT_SOW_TYPE_TIME_AND_MATERIAL,
    });
  }

  getListEmployeeDataModel = (employee) => {
    return {
      AssignmentKey: employee.AssignmentKey,
      Id: employee.EmployeeId,
      Name: employee.EmployeeName,
      Assignment: employee.EmployeeAssignment,
      StartDate: employee.EmployeeStartDate,
      EndDate: employee.EmployeeEndDate,
      StatusId: employee.EmployeeStatusId,
      Status: employee.EmployeeStatus,
      Role: employee.EmployeeRole,
      Comment: employee.EmployeeComment,
      PrimaryAssignmentId: employee.primaryAssignmentId,
    };
  }

  addNewRole = (state) => {
    const Employees = state.Employees.reduce((Employees, employee) => {
      Employees.push(this.getListEmployeeDataModel(employee));
      employee.backupEmployees.forEach(backup => {
        Employees.push(this.getListEmployeeDataModel(backup));
      });
      return Employees;
    }, []);
    const newRole = {
      ...state,
      Employees,
    };
    const { roles } = this.state;
    const rolesCopi = roles;
    rolesCopi.push(newRole);
    this.setState({
      roles: rolesCopi,
    });
  }

  editRole = (obj) => {
    const { roles } = this.state;
    const rolesUpdate = roles.filter((item) => item.ItemId !== obj.ItemId);
    this.setState({
      roles: [...rolesUpdate, obj],
    }, () => this.setState({
      step: 2,
    }));
  }

  checkIsBillableTrainee = (isTrainee) => {
    this.setState({
      isBillableTrainee: isTrainee,
    });
  }

  goTyProjectsList = () => {
    const { location } = this.props;
    history.push({ pathname: '/projects', state: location.state });
  }

  setInitialValueForRenewalStatuses = () => {
    const { allRenewalStatuses } = this.props;
    const initialValue = allRenewalStatuses.map((status) => status.Name === 'New business' ? ({
      RenewalStatusId: status.Id,
      Name: status.Name,
      IsActive: true,
      Value: 100,
    }) : ({
      RenewalStatusId: status.Id,
      Name: status.Name,
      IsActive: false,
      Value: 0,
    }));
    this.setState({
      SowRenewalStatuses: initialValue,
    });
  }

  render() {
    const {
      step,
      aliases,
      projectCode,
      name,
      offices,
      primaryOffices,
      clientId,
      clientManagerId,
      description,
      accountManagerId,
      deliveryManagers,
      budget,
      approvalStatus,
      projectStatus,
      startDate,
      endDate,
      projectType,
      projectTypeName,
      isStep1FieldsRequired,
      disabledStep1Selects,
      SOWClientManagerId,
      SOWNumber,
      SOWBudget,
      SOWRevenue,
      SOWType,
      SOWClientId,
      SOWDocumentStatusesId,
      PODocumentStatusesId,
      startDateSOW,
      endDateSOW,
      SOWDescription,
      PONumber,
      POBudget,
      PORevenue,
      POClientId,
      startDatePO,
      POStatus,
      endDatePO,
      ProjectBillingCode,
      POBillingCode,
      SOWBillingCode,
      invoiceApprover,
      travelBudget,
      travelReimbursement,
      businessGroupId,
      projectTeamId,
      SOWDocuments,
      POApprover,
      PODescription,
      PODocuments,
      isStep2FieldsRequired,
      disabledStep2Selects,
      isBillableTrainee,
      roles,
      editRoles,
      historyLocation,
      currentDeliveryManagers,
      engineeringManagers,
      currentEngineeringManagers,
      SOWPaymentTerm,
      SOWInvoiceDate,
      POPaymentTerm,
      POInvoiceDate,
      currency,
      projectTeam,
      businessGroup,
      SOWBudgetComments,
      POBudgetComments,
      SowRenewalStatuses,
      engagementTypeId,
    } = this.state;
    const {
      location,
      documentTypes,
      checkProjectNameDublicateError,
      getManagersByOffice,
      paymentTerm,
      allCurrencies,
      allRenewalStatuses,
      allInvoiceDates,
    } = this.props;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('createProject')} />
        <div className='page-toolbar'>
          <div className='page-header'>
            <span>Create new project</span>
          </div>
          <ProgressBarComponent
            stepNames={[
              'Project Info',
              'Contract Info',
              'Resources',
            ]}
            selectedStep={step}
          />
        </div>
        {
          step === 0 ? (
            <Step1
              onStepChange={this.onChangeStep}
              onChangeProjectType={this.onChangeProjectType}
              onSave={this.onSubmit}
              initialState={{
                aliases,
                projectCode,
                name,
                offices,
                primaryOffices,
                clientId,
                clientManagerId,
                description,
                accountManagerId,
                deliveryManagers,
                budget,
                approvalStatus,
                projectStatus,
                startDate,
                endDate,
                projectType,
                projectTypeName,
                isStep1FieldsRequired,
                disabledStep1Selects,
                SOWClientManagerId,
                historyLocation,
                currentDeliveryManagers,
                engineeringManagers,
                currentEngineeringManagers,
                currency,
                ProjectBillingCode,
                engagementTypeId,
              }}
              location={location}
              setClientId={this.setClientId}
              setSOWType={this.setSOWType}
              checkIsBillableTrainee={this.checkIsBillableTrainee}
              checkProjectNameDublicateError={checkProjectNameDublicateError}
              getManagersByOffice={getManagersByOffice}
            />
          ) : step === 1 ? (
            <Step2
              initialState={{
                SOWNumber,
                SOWBudget,
                SOWRevenue,
                SOWType,
                SOWClientId,
                SOWClientManagerId,
                SOWDocumentStatusesId,
                PODocumentStatusesId,
                startDateSOW,
                endDateSOW,
                SOWDescription,
                PONumber,
                POBudget,
                PORevenue,
                startDatePO,
                POStatus,
                endDatePO,
                approvalStatus,
                ProjectBillingCode,
                POBillingCode,
                SOWBillingCode,
                invoiceApprover,
                travelBudget,
                travelReimbursement,
                businessGroupId,
                projectTeamId,
                SOWDocuments,
                POApprover,
                POClientId,
                PODescription,
                PODocuments,
                isStep2FieldsRequired,
                disabledStep2Selects,
                SOWPaymentTerm,
                SOWInvoiceDate,
                POPaymentTerm,
                POInvoiceDate,
                businessGroup,
                projectTeam,
                SOWBudgetComments,
                POBudgetComments,
                SowRenewalStatuses,
              }}
              currencyProject={allCurrencies.find((item) => currency === item.Id).Name}
              documentTypes={documentTypes}
              paymentTerm={paymentTerm}
              onSave={this.onSubmit}
              onBack={this.onBack}
              onStepChange={this.onChangeStep}
              isBillableTrainee={isBillableTrainee}
              projectStartDate={startDate}
              projectEndDate={endDate}
              allRenewalStatuses={allRenewalStatuses}
              allInvoiceDates={allInvoiceDates}
            />
          ) : step === 2 ? (
            <Step4
              initialState={{
                roles,
                offices,
                SOWNumber,
                PONumber,
                startDateSOW,
                endDateSOW,
                startDatePO,
                endDatePO,
              }}
              currencyProject={allCurrencies && allCurrencies.find((item) => currency === item.Id)}
              onBack={this.onBack}
              onSubmit={this.onSubmit}
              onStepChange={this.onChangeStep}
            />
          ) : step === 3 ? (
            <RoleModal
              createdProjectName={name}
              location={location}
              rolesState={roles}
              onStepChange={this.onChangeStep}
              currencyProject={allCurrencies && allCurrencies.find((item) => currency === item.Id).Name}
              addNewRole={this.addNewRole}
            />
          ) : step === 4 ? (
            <EditRoleCreateProject
              roleEdit={roles.find((item) => item.ItemId === editRoles)}
              onStepChange={this.onChangeStep}
              editRole={this.editRole}
              rolesState={roles}
            />
          ) : null
        }
      </div>
    );
  }
}

CreateProject.propTypes = {
  projectTypes: PropTypes.array,
  createNewProject: PropTypes.func,
  documentStatuses: PropTypes.array,
  getStatus: PropTypes.func,
  getEmployeeStatus: PropTypes.func,
  documentTypes: PropTypes.array,
  loadManageDocumentTypes: PropTypes.func,
};

export default connect(store => ({
  projectTypes: store.projectsReducer.projectTypes,
  documentStatuses: store.filtersReducer.filters.documentStatuses,
  user: store.authReducer.user,
  documentTypes: store.adminManageLookups.documentTypes,
  paymentTerms: store.adminManageLookups.paymentTerms,
  allCurrencies: store.projectsReducer.allCurrencies,
  allRenewalStatuses: store.adminManageLookups.sowRenewalStatuses,
  allInvoiceDates: store.adminManageLookups.invoiceDates,
}), {
  createNewProject,
  getStatus,
  getEmployeeStatus,
  loadManageDocumentTypes,
  checkProjectNameDublicateError,
  getManagersByOffice,
  getManagePaymentTerms,
  getAllCurrencies,
  setComponent,
  resetComponent,
  loadSOWRenewalStatuses,
  getAllEngagementTypes,
  getManageInvoiceDates,
})(CreateProject);
