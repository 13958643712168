import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomIcon from 'common/components/icon/Icon';

import './alert-dialog.css';

export class AlertDialog extends Component {
  getDialogData = () => {
    const { dialogData } = this.props;
    let dialogMessage = '';
    for (const key in dialogData) {
      if (dialogData[key].length) {
        dialogMessage += '\n • ' + key + ':  "' + dialogData[key].join(', ') + '";';
      }
    }
    return dialogMessage;
  }

  render() {
    const {
      dialogHeader,
      dialogTitle,
      confirmButtonTitle,
      closeDialog,
      actionData,
      confirmDialog,
      dialogData,
    } = this.props;
    return (
      <Dialog
        open
        onClose={closeDialog}
        aria-labelledby='responsive-dialog-title'
        className='dialog-width'
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>{dialogHeader} </span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='cross' />
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className='show-alert-content show-alert-content__margin'> {dialogTitle} </span>
            {dialogData
              ? <span className='show-alert-content'>{this.getDialogData()}</span>
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            className='button next alertdialog-button-size'
            onClick={() => confirmDialog(actionData)}
          >
            <span className='confirmationdialog-button-title'>{confirmButtonTitle}</span>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  dialogHeader: PropTypes.string,
  dialogTitle: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
  actionData: PropTypes.object,
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
};
