import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import TextField from 'common/components/form-items/input/text-field';
import Select from '../../../../common/components/form-items/select/select';
import DatePicker from '../../../../common/components/form-items/date-picker/date-picker';
import Checkbox from '../../../../common/components/form-items/checkbox/checkbox';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateDocumentItem } from 'common/components/documents-list/update-document-item';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import CustomIcon from 'common/components/icon/Icon';
import { sortFilterList } from 'common/components/list/sortList';
import SearchDropdown from '../../../../common/components/form-items/search-dropdown/search-dropdown';
import {
  TIME_AND_MATERIAL,
  CREATE_PROJECT_DELETE_SOW_DIALOG_TITLE,
  CREATE_PROJECT_DELETE_PO_DIALOG_TITLE,
  MAX_QUANTITY_OF_DOCUMENTS,
  EXTENDED,
  FINISHED,
  TOTAL_PERCENT_FOR_RENEWAL_STATUSES,
} from 'utils/const-variable';
import { sortDocumentByAlphabet, getDocumentTypeName, getUpdatedDocuments } from '../../../../utils/documentsUtils';
import isObjectNotEquals from '../../../../utils/object-comparison';
import { getStatusesForRequiredComment } from '../utils/createArrayWithStatusMatch';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { getConfigBasedOnCheckedDates } from 'utils/getConfigBasedOnCheckedDates';
import { getInitialRenewalStatusIdForNewProject } from 'pages/projects/components/utils/getInitialRenewalStatusIdForNewProject';
import { SowRenewalStatusList } from 'common/components/sow-renewal-status-list/sow-renewal-status-list';
import { getTotalPercent } from 'services/getTotalPercentForRenewalStatuses';

// eslint-disable-next-line no-useless-escape

const regexForComment = /\S/;
const checkIsSowEndDateRequired = (SOWDocumentStatusesId, documentStatuses) => {
  return documentStatuses.reduce((isSowEndDateRequired, status) => {
    return ((status.Name === EXTENDED || status.Name === FINISHED) && status.Id === SOWDocumentStatusesId) || isSowEndDateRequired;
  }, false);
};

export class Step extends React.Component {
  constructor(props) {
    super(props);
    const { initialState, isBillableTrainee, documentStatuses } = this.props;
    this.state = {
      SOWNumber: initialState.SOWNumber,
      SOWType: initialState.SOWType,
      approvalStatus: initialState.approvalStatus,
      POBillingCode: initialState.POBillingCode,
      SOWBillingCode: initialState.SOWBillingCode,
      startDateSOW: initialState.startDateSOW,
      endDateSOW: initialState.endDateSOW,
      SOWDescription: initialState.SOWDescription,
      SOWBudgetComments: initialState.SOWBudgetComments,
      PONumber: initialState.PONumber,
      POStatus: initialState.POStatus,
      POApprover: initialState.POApprover || initialState.SOWClientManagerId,
      startDatePO: initialState.startDatePO,
      endDatePO: initialState.endDatePO,
      PODescription: initialState.PODescription,
      POBudgetComments: initialState.POBudgetComments,
      SOWClientId: initialState.SOWClientId,
      POClientId: initialState.POClientId,
      invoiceApprover: initialState.invoiceApprover || initialState.SOWClientManagerId,
      businessGroup: initialState.businessGroup,
      projectTeam: initialState.projectTeam,
      SOWDocumentStatusesId: initialState.SOWDocumentStatusesId,
      PODocumentStatusesId: initialState.PODocumentStatusesId,
      travelBudget: initialState.travelBudget,
      travelReimbursement: initialState.travelReimbursement,
      isStep2FieldsRequired: initialState.isStep2FieldsRequired,
      disabledStep2Selects: initialState.disabledStep2Selects,
      sowEndDateDisabled: true,
      poEndDateDisabled: true,
      SOWDocuments: initialState.SOWDocuments || [],
      PODocuments: initialState.PODocuments || [],
      sowTypeIsRequired: isBillableTrainee,
      SOWPaymentTerm: initialState.SOWPaymentTerm,
      SOWInvoiceDate: initialState.SOWInvoiceDate,
      POPaymentTerm: initialState.POPaymentTerm,
      POInvoiceDate: initialState.POInvoiceDate,
      isSOWCommentFieldRequired: false,
      isPOCommentFieldRequired: false,
      isResetPOButtonDisabled: true,
      columnsToDisplay: {
        documentList: [
          {
            Name: 'Documents Links', isSelected: true, Id: 'URL', className: 'without-width-col', pressArrowLeft: true,
          },
          {
            Name: 'Documents Types', isType: true, Id: 'DocumentType', className: 'without-width-col', pressArrowLeft: true,
          },
        ],
        renewalStatusesList: [
          {
            Name: 'Status Type', Id: 'Status', className: 'col__renewal-status-type',
          },
          {
            Name: 'Value', Id: 'Value', className: 'col__renewal-value',
          },
        ],
      },
      showAddSowDocimentFields: false,
      addSOW: {},
      blockSaveAddSOW: false,
      deleteSOWDocumentIndex: '',
      deletePODocumentIndex: '',
      addPO: {},
      blockSaveAddPO: false,
      showAddPODocimentFields: false,
      showConfirmationDialog: false,
      confirmationDialogTitle: '',
      SowRenewalStatuses: initialState.SowRenewalStatuses,
      isSowEndDateRequired: checkIsSowEndDateRequired(initialState.SOWDocumentStatusesId, documentStatuses),
      isShowSowRenewalStatusList: false,
      showPopover: false,
    };
  }

  componentDidMount() {
    const { SOWClientId, startDateSOW, startDatePO } = this.state;
    this.checkSowType();
    this.setState(SOWClientId
      ? { disabledStep2Selects: false }
      : { disabledStep2Selects: true });
    this.setState(startDateSOW
      ? { sowEndDateDisabled: false }
      : { sowEndDateDisabled: true });
    this.setState(startDatePO
      ? { poEndDateDisabled: false }
      : { poEndDateDisabled: true });
    this.checkCommentFieldIsRequired('SOW');
    this.checkCommentFieldIsRequired('PO');
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      SOWType,
      PONumber,
      POStatus,
      POBillingCode,
      startDatePO,
      endDatePO,
      POPaymentTerm,
      PODescription,
      POBudgetComments,
      isResetPOButtonDisabled,
      POInvoiceDate,
    } = this.state;
    const { initialState, allRenewalStatuses } = this.props;
    const { SOWNumber } = initialState;
    if (prevProps.initialState.SOWNumber !== SOWNumber) {
      this.setState({
        SOWNumber,
      });
    }
    if (prevState.SOWType !== SOWType) {
      this.checkSowType();
    }
    if (isObjectNotEquals(prevProps.allRenewalStatuses, allRenewalStatuses)) {
      this.setState({ SowRenewalStatuses: getInitialRenewalStatusIdForNewProject(allRenewalStatuses) });
    }
    if (
      !isResetPOButtonDisabled
      && !PONumber
      && !POStatus
      && !POBillingCode
      && !startDatePO
      && !endDatePO
      && !POPaymentTerm
      && !PODescription
      && !POBudgetComments
      && !POInvoiceDate
    ) {
      this.setState({ isResetPOButtonDisabled: true });
    }
  }

  requireFields = () => {
    const {
      SOWNumber, SOWType, approvalStatus,
      SOWClientId, invoiceApprover, sowTypeIsRequired, POClientId,
      startDateSOW, endDateSOW, PONumber, POStatus, SOWDescription,
      isSOWCommentFieldRequired, PODescription, isPOCommentFieldRequired,
      startDatePO, endDatePO, POApprover, isSowEndDateRequired, SowRenewalStatuses,
      SOWInvoiceDate, POInvoiceDate,
    } = this.state;
    const totalRenewalStatusPercent = getTotalPercent(SowRenewalStatuses);
    if (!!SOWNumber
      && SOWType !== ''
      && approvalStatus !== ''
      && !!SOWClientId
      && (!!invoiceApprover || sowTypeIsRequired)
      && !!startDateSOW
      && (isSowEndDateRequired ? !!endDateSOW : true)
      && !PONumber
      && !POStatus
      && (regexForComment.test(SOWDescription) || !isSOWCommentFieldRequired)
      && (regexForComment.test(PODescription) || !isPOCommentFieldRequired)
      && !startDatePO
      && !endDatePO
      && +totalRenewalStatusPercent.toFixed(2) === TOTAL_PERCENT_FOR_RENEWAL_STATUSES
      && SOWInvoiceDate !== ''
      && !POInvoiceDate
    ) {
      this.setState({ isStep2FieldsRequired: true });
    } else if (!!PONumber
      && POStatus !== ''
      && !!startDatePO
      && !!endDatePO
      && (!!POApprover || sowTypeIsRequired)
      && !!SOWNumber
      && SOWType !== ''
      && (regexForComment.test(SOWDescription) || !isSOWCommentFieldRequired)
      && (regexForComment.test(PODescription) || !isPOCommentFieldRequired)
      && approvalStatus !== ''
      && !!POClientId
      && (!!invoiceApprover || sowTypeIsRequired)
      && !!startDateSOW
      && POInvoiceDate !== ''
    ) {
      this.setState({ isStep2FieldsRequired: true });
    } else {
      this.setState({ isStep2FieldsRequired: false });
    }
  }

  getStartDateForSow = () => {
    const { projectStartDate } = this.props;
    return {
      date: projectStartDate,
      type: 'project',
    };
  }

  getEndDateForSow = () => {
    const { projectEndDate } = this.props;
    return {
      date: projectEndDate,
      type: 'project',
    };
  }

  getStartDateForPo = () => {
    const { startDateSOW } = this.state;
    return {
      date: startDateSOW,
      type: 'SOW',
    };
  }

  getEndDateForPo = () => {
    const { endDateSOW } = this.state;
    const { projectEndDate } = this.props;
    return {
      date: endDateSOW || projectEndDate,
      type: endDateSOW ? 'SOW' : 'project',
    };
  }

  checkCommentFieldIsRequired = (type) => {
    const {
      SOWDocumentStatusesId, PODocumentStatusesId, approvalStatus, POStatus,
    } = this.state;
    const { documentStatuses, approvalStatuses } = this.props;
    const isMatchObject = {
      docStatus: type === 'SOW' ? SOWDocumentStatusesId : PODocumentStatusesId,
      approveStatus: type === 'SOW' ? approvalStatus : POStatus,
    };
    const isRequired = getStatusesForRequiredComment(documentStatuses, approvalStatuses).find((item) => {
      return !isObjectNotEquals(item, isMatchObject);
    }) !== undefined;
    this.setState(type === 'SOW'
      ? {
        isSOWCommentFieldRequired: isRequired,
      }
      : {
        isPOCommentFieldRequired: isRequired,
      }, () => this.requireFields());
  };

  onChangeSOWId = ({ target }) => {
    this.setState({
      SOWNumber: target.value,
    }, () => this.requireFields());
  }

  onChangeClient = target => {
    this.setState({
      SOWClientId: target ? target.value : '',
      disabledStep2Selects: false,
    }, () => this.requireFields());
  }

  onChangeInvoiceApprover = (item) => {
    this.setState({
      invoiceApprover: item ? item.value : '',
    }, () => this.requireFields());
  }

  onChangeSOWType = ({ target }) => {
    this.setState({
      SOWType: target.value,
    }, () => this.requireFields());
  }

  onChangeApprovalStatus = ({ target }) => {
    this.setState({
      approvalStatus: target.value,
    }, () => this.checkCommentFieldIsRequired('SOW'));
  }

  onChangeSOWPaymentTerm = ({ target }) => {
    this.setState({
      SOWPaymentTerm: target.value,
    }, () => this.requireFields());
  }

  onChangeSOWInvoiceDate = ({ target }) => {
    this.setState({
      SOWInvoiceDate: target.value,
    }, () => this.requireFields());
  }

  onChangePOPaymentTerm = ({ target }) => {
    this.setState({
      POPaymentTerm: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  onChangePOInvoiceDate = ({ target }) => {
    this.setState({
      POInvoiceDate: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  onChangeSOWBillingCode = ({ target }) => {
    this.setState({
      SOWBillingCode: target.value,
    }, () => this.requireFields());
  };

  onChangePOBillingCode = ({ target }) => {
    this.setState({
      POBillingCode: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  };

  onChangePOClient = target => {
    this.setState({
      POClientId: target ? target.value : '',
      disabledStep2Selects: false,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  onChangeBusinessGroup = ({ target }) => {
    this.setState({
      businessGroup: target.value,
    }, () => this.requireFields());
  }

  onChangeProjectTeam = ({ target }) => {
    this.setState({
      projectTeam: target.value,
    }, () => this.requireFields());
  }

  onChangeSOWDocumentStatus = ({ target }) => {
    const { documentStatuses } = this.props;
    this.setState({
      isSowEndDateRequired: checkIsSowEndDateRequired(target.value, documentStatuses),
      SOWDocumentStatusesId: target.value,
    }, () => {
      const { documentStatuses } = this.props;
      const { SOWDocumentStatusesId } = this.state;
      const extendedStatus = documentStatuses.find((status) => status.Name === 'Extended');
      this.checkCommentFieldIsRequired('SOW');
      if (SOWDocumentStatusesId === extendedStatus.Id) {
        this.setState({
          SowRenewalStatuses: this.setRenewalStatusExtended(),
        });
      }
    });
  };

  onChangePODocumentStatus = ({ target }) => {
    this.setState({
      PODocumentStatusesId: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.checkCommentFieldIsRequired('PO'));
  };

  handleSOWStartDateChange = date => {
    const { showAlertDialog } = this.props;
    const config = getConfigBasedOnCheckedDates({
      startDatesParentContracts: this.getStartDateForSow(), endDatesParentContracts: this.getEndDateForSow(),
    }, null, date, 'SOW', true, true);
    if (!config) {
      this.setState({
        startDateSOW: date,
        sowEndDateDisabled: false,
      }, () => this.requireFields());
    } else {
      showAlertDialog(config.message);
    }
  }

  handleSOWEndDateChange = date => {
    const { showAlertDialog } = this.props;
    const startDatesParentContracts = this.getStartDateForSow();
    const endDatesParentContracts = this.getEndDateForSow();
    const config = getConfigBasedOnCheckedDates({ startDatesParentContracts, endDatesParentContracts }, null, date, 'SOW', true, true);
    if (!config) {
      this.setState({
        endDateSOW: date,
      }, () => this.requireFields());
    } else {
      showAlertDialog(config.message);
    }
  }

  clearSOWEndDate = () => {
    this.setState({
      endDateSOW: null,
    }, () => this.requireFields());
  }

  onChangeSOWDescription = (e) => {
    this.setState({
      SOWDescription: e.target.value,
    }, () => this.requireFields());
  }

  onChangePONumber = ({ target }) => {
    this.setState({
      PONumber: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  onChangePOStatus = ({ target }) => {
    this.setState({
      POStatus: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.checkCommentFieldIsRequired('PO'));
  }

  onChangePOApprover = target => {
    this.setState({
      POApprover: target ? target.value : '',
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  onChangePODescription = ({ target }) => {
    this.setState({
      PODescription: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  handlePOStartDateChange = date => {
    const { showAlertDialog } = this.props;
    const startDatesParentContracts = this.getStartDateForPo();
    const endDatesParentContracts = this.getEndDateForPo();
    const config = getConfigBasedOnCheckedDates({ startDatesParentContracts, endDatesParentContracts }, null, date, 'PO', true, true);
    if (!config) {
      this.setState({
        startDatePO: date,
        poEndDateDisabled: false,
        isResetPOButtonDisabled: false,
      }, () => this.requireFields());
    } else {
      showAlertDialog(config.message);
    }
  }

  handlePOEndDateChange = date => {
    const { showAlertDialog } = this.props;
    const startDatesParentContracts = this.getStartDateForPo();
    const endDatesParentContracts = this.getEndDateForPo();
    const config = getConfigBasedOnCheckedDates({ startDatesParentContracts, endDatesParentContracts }, null, date, 'PO', false, true);
    if (!config) {
      this.setState({
        endDatePO: date,
        isResetPOButtonDisabled: false,
      }, () => this.requireFields());
    } else {
      showAlertDialog(config.message);
    }
  }

  handleTravelBudgetChange = () => {
    const { travelBudget } = this.state;
    this.setState({
      travelBudget: !travelBudget,
    }, () => this.requireFields());
  }

  handleTravelReimbursementChange = () => {
    const { travelReimbursement } = this.state;
    this.setState({
      travelReimbursement: !travelReimbursement,
    }, () => this.requireFields());
  }

  onChangeSOWBudgetComments = ({ target }) => {
    this.setState({
      SOWBudgetComments: target.value,
    }, () => this.requireFields());
  }

  onChangePOBudgetComments = ({ target }) => {
    this.setState({
      POBudgetComments: target.value,
      isResetPOButtonDisabled: false,
    }, () => this.requireFields());
  }

  getNewSOWDocumentsChange = ({ target }) => {
    const { addSOW } = this.state;
    const { documentTypes } = this.props;
    if (target.name === 'DocumentType') {
      this.setState({
        addSOW: {
          ...addSOW,
          DocumentType: {
            Id: target.value,
            Name: getDocumentTypeName(target.value, documentTypes),
          },
          DocumentTypeId: target.value,
        },
      }, this.newSOWValidation);
    } else {
      this.setState({
        addSOW: {
          ...addSOW,
          [target.name]: target.value,
        },
      }, this.newSOWValidation);
    }
  }

  newSOWValidation = () => {
    const { addSOW } = this.state;
    this.setState(addSOW.URL.length && addSOW.Comment.length && addSOW.DocumentType
      ? { blockSaveAddSOW: true }
      : { blockSaveAddSOW: false });
  };

  showAddSowFields = () => {
    const { SOWDocuments } = this.state;
    if (SOWDocuments.length < MAX_QUANTITY_OF_DOCUMENTS) {
      this.setState({
        showAddSowDocimentFields: true,
        addSOW: { URL: '', Comment: '' },
        blockSaveAddSOW: false,
      });
    }
  }

  cancelAddSow = () => {
    this.setState({
      showAddSowDocimentFields: false,
      addSOW: {},
      blockSaveAddSOW: false,
    });
  }

  applyAddSow = () => {
    const { blockSaveAddSOW, addSOW, SOWDocuments } = this.state;
    if (blockSaveAddSOW) {
      const newSOWDocuments = SOWDocuments;
      newSOWDocuments.push(addSOW);
      this.setState({
        SOWDocuments: getUpdatedDocuments(newSOWDocuments),
        showAddSowDocimentFields: false,
        blockSaveAddSOW: false,
      }, () => this.requireFields());
    }
  }

  showSOWConfirmationDialog = (item) => {
    this.setState({
      showConfirmationDialog: true,
      confirmationDialogTitle: CREATE_PROJECT_DELETE_SOW_DIALOG_TITLE,
      deleteSOWDocumentIndex: item.stateItemId,
    });
  }

  editSowDocument = (item) => {
    const { SOWDocuments } = this.state;
    const deleteSowDocument = SOWDocuments;
    deleteSowDocument.splice(item.id, 1, item);
    const newDocuments = getUpdatedDocuments(deleteSowDocument);
    this.setState({
      SOWDocuments: sortDocumentByAlphabet(newDocuments),
    });
  }

  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: false,
      confirmationDialogTitle: '',
      deleteSOWDocumentIndex: '',
      deletePODocumentIndex: '',
    });
  }

  showPOConfirmationDialog = (item) => {
    this.setState({
      showConfirmationDialog: true,
      confirmationDialogTitle: CREATE_PROJECT_DELETE_PO_DIALOG_TITLE,
      deletePODocumentIndex: item.stateItemId,
    });
  }

  showAddPOFields = () => {
    const { PODocuments } = this.state;
    if (PODocuments.length < MAX_QUANTITY_OF_DOCUMENTS) {
      this.setState({
        showAddPODocimentFields: true,
        addPO: { URL: '', Comment: '' },
        blockSaveAddPO: false,
      });
    }
  }

  cancelAddPO = () => {
    this.setState({
      showAddPODocimentFields: false,
      addPO: {},
      blockSaveAddPO: false,
    });
  }

  getNewPODocumentsChange = ({ target }) => {
    const { addPO } = this.state;
    const { documentTypes } = this.props;
    if (target.name === 'DocumentType') {
      this.setState({
        addPO: {
          ...addPO,
          DocumentType: {
            Id: target.value,
            Name: getDocumentTypeName(target.value, documentTypes),
          },
          DocumentTypeId: target.value,
        },
      }, this.newPOValidation);
    } else {
      this.setState({
        addPO: {
          ...addPO,
          [target.name]: target.value,
        },
      }, this.newPOValidation);
    }
  }

  newPOValidation = () => {
    const { addPO } = this.state;
    this.setState(addPO.URL.length && addPO.Comment.length && addPO.DocumentType
      ? { blockSaveAddPO: true }
      : { blockSaveAddPO: false });
  };

  applyAddPO = () => {
    const { blockSaveAddPO, addPO, PODocuments } = this.state;
    if (blockSaveAddPO) {
      const newPODocuments = PODocuments;
      newPODocuments.push(addPO);
      this.setState({
        PODocuments: getUpdatedDocuments(newPODocuments),
        showAddPODocimentFields: false,
        blockSaveAddPO: false,
      }, () => this.requireFields());
    }
  }

  editPoDocuments = (item) => {
    const { PODocuments } = this.state;
    const editPoDocument = PODocuments;
    editPoDocument.splice(item.id, 1, item);
    const newDocuments = getUpdatedDocuments(editPoDocument);
    this.setState({
      PODocuments: sortDocumentByAlphabet(newDocuments),
    });
  }

  deleteDocuments = () => {
    const { confirmationDialogTitle } = this.state;
    if (confirmationDialogTitle === CREATE_PROJECT_DELETE_SOW_DIALOG_TITLE) {
      const { SOWDocuments, deleteSOWDocumentIndex } = this.state;
      const deleteSowDocument = SOWDocuments;
      deleteSowDocument.splice(deleteSOWDocumentIndex, 1);
      this.setState({
        SOWDocuments: getUpdatedDocuments(deleteSowDocument),
        showAddSowDocimentFields: false,
        blockSaveAddSOW: false,
        showConfirmationDialog: false,
        confirmationDialogTitle: '',
        deleteSOWDocumentIndex: '',
      }, () => this.requireFields());
      return;
    }
    const { PODocuments, deletePODocumentIndex } = this.state;
    const deletePODocument = PODocuments;
    deletePODocument.splice(deletePODocumentIndex, 1);
    this.setState({
      PODocuments: getUpdatedDocuments(deletePODocument),
      showAddPODocimentFields: false,
      blockSaveAddPO: false,
      showConfirmationDialog: false,
      confirmationDialogTitle: '',
      deletePODocumentIndex: '',
    }, () => this.requireFields());
  }

  checkSowType = () => {
    const { SOWTypes, isBillableTrainee } = this.props;
    const { SOWType } = this.state;
    const isSowRequired = SOWTypes.find((item) => {
      return item.Name === TIME_AND_MATERIAL;
    }).Id === +SOWType && isBillableTrainee;
    this.setState({
      sowTypeIsRequired: isSowRequired,
    }, () => this.requireFields());
  };

  onNextStep = () => {
    const { onStepChange } = this.props;
    const { isStep2FieldsRequired } = this.state;
    if (isStep2FieldsRequired) {
      onStepChange({
        ...this.state,
        step: 2,
      });
    }
  }

  onBack = () => {
    const { onBack } = this.props;
    onBack({
      ...this.state,
    });
  }

  onSave = () => {
    const { isStep2FieldsRequired } = this.state;
    const { onSave } = this.props;
    if (isStep2FieldsRequired) {
      onSave(this.state);
    }
  }

  clearValue = (property) => () => {
    this.setState(
      { [property]: '' },
      () => this.requireFields(),
    );
  }

  onChangeSOWRenewalStatuses = (renewalStatus, renewalStatusId) => {
    const { SowRenewalStatuses } = this.state;
    const rewenalStatuses = renewalStatusId ? SowRenewalStatuses.reduce((acc, item) => {
      if (item.RenewalStatusId === renewalStatusId) {
        acc.push(renewalStatus);
      } else {
        acc.push(item);
      }
      return acc;
    }, []) : [...renewalStatus];
    this.setState({
      SowRenewalStatuses: rewenalStatuses,
    }, () => this.requireFields());
  }

  setRenewalStatusExtended = () => {
    const { SowRenewalStatuses } = this.state;
    return SowRenewalStatuses.map((status) => status.Name === 'Renewal'
      ? ({ ...status, IsActive: true })
      : ({ ...status, IsActive: false }));
  }

  handleChangeShowPopover = () => {
    const { showPopover } = this.state;
    this.setState({
      showPopover: !showPopover,
    });
  }

  resetOriginalPo = () => {
    const { initialState } = this.props;

    this.setState(
      {
        PONumber: '',
        POStatus: '',
        POBillingCode: '',
        POClientId: initialState.POClientId,
        POApprover: initialState.POApprover || initialState.SOWClientManagerId,
        PODocumentStatusesId: initialState.PODocumentStatusesId,
        startDatePO: '',
        endDatePO: '',
        POPaymentTerm: '',
        PODescription: '',
        POBudgetComments: '',
        isPOCommentFieldRequired: false,
        poEndDateDisabled: true,
        isResetPOButtonDisabled: true,
        POInvoiceDate: '',
      },
      () => this.checkCommentFieldIsRequired('PO'),
    );
  }

  render() {
    const {
      SOWNumber,
      SOWType,
      approvalStatus,
      SOWClientId,
      invoiceApprover,
      disabledStep2Selects,
      sowTypeIsRequired,
      SOWBillingCode,
      startDateSOW,
      sowEndDateDisabled,
      endDateSOW,
      businessGroup,
      projectTeam,
      SOWDocumentStatusesId,
      travelBudget,
      travelReimbursement,
      SOWDescription,
      SOWBudgetComments,
      isSOWCommentFieldRequired,
      SOWDocuments,
      PONumber,
      POStatus,
      POBillingCode,
      startDatePO,
      poEndDateDisabled,
      endDatePO,
      POClientId,
      POApprover,
      PODocumentStatusesId,
      PODescription,
      POBudgetComments,
      isPOCommentFieldRequired,
      PODocuments,
      isStep2FieldsRequired,
      columnsToDisplay,
      showAddSowDocimentFields,
      addSOW,
      blockSaveAddSOW,
      showAddPODocimentFields,
      addPO,
      blockSaveAddPO,
      showConfirmationDialog,
      confirmationDialogTitle,
      SOWPaymentTerm,
      SOWInvoiceDate,
      POPaymentTerm,
      POInvoiceDate,
      SowRenewalStatuses,
      isSowEndDateRequired,
      showPopover,
      isResetPOButtonDisabled,
    } = this.state;

    const {
      SOWTypes,
      approvalStatuses,
      clients,
      clientContacts,
      documentStatuses,
      documentTypes,
      paymentTerms,
      allInvoiceDates,
    } = this.props;
    const totalPercent = +getTotalPercent(SowRenewalStatuses).toFixed(2);
    return (
      <div className='step'>
        <Scrollbars
          className='scrollbars scrollbars__add-project'
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          <div className='title'>
            <span>SOW</span>
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='SOW_number'
              label='SOW ID'
              placeholder='Add SOW ID'
              maxlength='100'
              value={SOWNumber}
              onChange={this.onChangeSOWId}
              isRequired
              clearValue={this.clearValue('SOWNumber')}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              clearClassName='svg_select_center'
              optionsClassName='option'
              label='SOW Type'
              value={SOWType.toString()}
              onChange={this.onChangeSOWType}
              selectOptions={SOWTypes}
              isOptionObject
              placeholder='Choose Type'
              inputProps={{
                name: 'SOW Type',
                id: 'sow_type',
              }}
              isRequired
              clearValue={() => this.clearValue('SOWType')}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Approval Status'
              value={approvalStatus.toString()}
              onChange={this.onChangeApprovalStatus}
              selectOptions={approvalStatuses}
              isOptionObject
              placeholder='Choose Status'
              inputProps={{
                name: 'approval status',
                id: 'approval_status',
              }}
              isRequired
              clearValue={this.clearValue('approvalStatus')}
            />
          </div>
          <div className='step__row'>
            <SearchDropdown
              formClassName='step__form'
              selectClassName='step__search-dropdown'
              label='Company Name'
              placeholder='Choose Company'
              value={SOWClientId}
              onChange={this.onChangeClient}
              options={clients}
              isRequired
              inputProps={{
                name: 'Client',
                id: 'client',
              }}
            />
            <SearchDropdown
              formClassName='step__form'
              selectClassName='step__search-dropdown'
              label='Client`s Invoice Approver'
              placeholder='Choose Person'
              value={invoiceApprover}
              onChange={this.onChangeInvoiceApprover}
              options={sortFilterList(clientContacts.filter(item => item.ClientId === SOWClientId))}
              disabled={disabledStep2Selects}
              isRequired={!sowTypeIsRequired}
              inputProps={{
                name: 'invoice approver',
                id: 'invoice_approver',
              }}
              showClear={sowTypeIsRequired}
              isAddingFeature
              parentEntityId={SOWClientId}
            />
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='SOW_Billing_Code'
              label='Billing Code'
              placeholder='Add Billing Code'
              maxlength='50'
              value={SOWBillingCode}
              onChange={this.onChangeSOWBillingCode}
              clearValue={this.clearValue('SOWBillingCode')}
            />
          </div>
          <div className='step__row'>
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              id='SOW_start_date'
              label='SOW Start Date'
              placeholder='Choose Start Date'
              value={startDateSOW}
              onChange={this.handleSOWStartDateChange}
              name='startDateSOW'
              isRequired
              isStartDate
              forCreateProject
            />
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              id='SOW_end_date'
              label='SOW End Date'
              placeholder='Choose End Date'
              minVal={startDateSOW}
              disabled={sowEndDateDisabled}
              value={endDateSOW}
              onChange={this.handleSOWEndDateChange}
              helperText='Choose Start Date first'
              clearValue={this.clearSOWEndDate}
              name='endDateSOW'
              isRequired={isSowEndDateRequired}
              isEndDate
              forCreateProject
            />
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='business-group'
              label='Business Group'
              placeholder='Set Business Group'
              maxlength='100'
              value={businessGroup}
              onChange={this.onChangeBusinessGroup}
              clearValue={this.clearValue('businessGroup')}
            />
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='project-team'
              label='Project Team'
              placeholder='Set Project Team'
              maxlength='100'
              value={projectTeam}
              onChange={this.onChangeProjectTeam}
              clearValue={this.clearValue('projectTeam')}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              clearClassName='svg_select_center'
              optionsClassName='option'
              label='Document Status'
              value={SOWDocumentStatusesId.toString()}
              onChange={this.onChangeSOWDocumentStatus}
              placeholder='Choose Status'
              inputProps={{
                id: 'sow-document-status',
                name: 'SOW Document Status',
              }}
              isOptionObject
              selectOptions={sortFilterList(documentStatuses)}
              showClear={false}
            />
          </div>
          <div className='step__row'>
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Payment Term'
              value={SOWPaymentTerm.toString()}
              onChange={this.onChangeSOWPaymentTerm}
              selectOptions={paymentTerms}
              isOptionObject
              placeholder='Choose Payment Term'
              inputProps={{
                name: 'Payment Term',
                id: 'payment_term',
              }}
              clearValue={this.clearValue('SOWPaymentTerm')}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Invoice Date'
              value={SOWInvoiceDate.toString()}
              onChange={this.onChangeSOWInvoiceDate}
              selectOptions={allInvoiceDates}
              isOptionObject
              isRequired
              placeholder='Choose Invoice Date'
              inputProps={{
                name: 'Invoice Date',
                id: 'invoice_date',
              }}
              clearValue={this.clearValue('SOWInvoiceDate')}
            />
          </div>
          <div className='step__row'>
            <Checkbox
              value='Travel budget included'
              onClick={this.handleTravelBudgetChange}
              checked={travelBudget}
            />
            <Checkbox
              value='Travel reimbursement'
              onClick={this.handleTravelReimbursementChange}
              checked={travelReimbursement}
            />
          </div>
          <div>
            <div
              className={classNames('add-renewal-status__button--margin add-renewal-status__button-disabled')}
            >
              <span>SOW Renewal Status</span>
              <span className='redux-form__required'> *</span>
            </div>
            <div>
              <SowRenewalStatusList
                items={SowRenewalStatuses}
                columnsToDisplay={columnsToDisplay.renewalStatusesList}
                onChange={this.onChangeSOWRenewalStatuses}
              />
              <div className={classNames(
                'div__total-field',
                {
                  'not-equals': totalPercent !== TOTAL_PERCENT_FOR_RENEWAL_STATUSES,
                },
              )}
              >
                {
                  showPopover ? (
                    <div className='popover-renewal-status' onClick={this.handleChangeShowPopover}>
                      <p>Total percentage of SOW Renewal Status should be equal to 100%</p>
                    </div>
                  ) : null
                }
                <CustomIcon iconName='question-mark' onClick={this.handleChangeShowPopover} />
                <span>{`Total: ${totalPercent}%`}</span>
              </div>
            </div>
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__description'
              id='sow-description'
              label='SOW Status Comments'
              placeholder='Add SOW Status Comments'
              maxlength='4000'
              value={SOWDescription}
              onChange={this.onChangeSOWDescription}
              fullWidth
              multiline
              isRequired={isSOWCommentFieldRequired}
            />
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__description'
              label='SOW Budget Comments'
              placeholder='Add SOW Budget Comments'
              maxlength='4000'
              value={SOWBudgetComments}
              onChange={this.onChangeSOWBudgetComments}
              fullWidth
              multiline
            />
          </div>
          <div className='documents-container'>
            <div
              className={classNames(
                'add-document__button add-document__button--margin',
                { 'btn-disabled': SOWDocuments.length === MAX_QUANTITY_OF_DOCUMENTS },
              )}
              onClick={this.showAddSowFields}
            >
              <CustomIcon iconName='plus-orange' />
              <span>Add Document</span>
            </div>
            <DocumentList
              columnsToDisplay={columnsToDisplay.documentList}
              items={sortDocumentByAlphabet(SOWDocuments)}
              actionLabel
              onEditClick={this.editSowDocument}
              onDeleteClick={this.showSOWConfirmationDialog}
              isEditable
              isDocumentType
              documentSelectOptions={documentTypes}
              viewFinancialInfo
            />
            {
              showAddSowDocimentFields
                ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
                  <div className='c-list__item-column resume-member-inputs c-list__item-column'>
                    <UpdateDocumentItem
                      item={addSOW}
                      onChangeNewDocumentObj={this.getNewSOWDocumentsChange}
                      disableSaveDocument={blockSaveAddSOW}
                      newDocumentCancel={this.cancelAddSow}
                      newDocumentApply={this.applyAddSow}
                      isDocumentType
                      documentSelectOptions={documentTypes}
                    />
                  </div>
                </div>
                : null
            }
          </div>
          <div className='title title__margin-step2-po'>
            <span>ORIGINAL PO</span>
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='PO_number'
              label='PO Number'
              placeholder='Add PO Number'
              maxlength='100'
              value={PONumber}
              onChange={this.onChangePONumber}
              isRequired
              clearValue={this.clearValue('PONumber')}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='PO Status'
              placeholder='Choose PO Status'
              value={POStatus}
              onChange={this.onChangePOStatus}
              selectOptions={approvalStatuses}
              isOptionObject
              inputProps={{
                name: 'PO Status',
                id: 'PO_status',
              }}
              isRequired
              clearValue={this.clearValue('POStatus')}
            />
            <TextField
              formClassName='step__form'
              inputClassName='step__input'
              id='PO_Billing_Code'
              label='Billing Code'
              placeholder='Add Billing Code'
              maxlength='50'
              value={POBillingCode}
              onChange={this.onChangePOBillingCode}
              clearValue={this.clearValue('POBillingCode')}
            />
          </div>
          <div className='step__row'>
            <SearchDropdown
              formClassName='step__form'
              selectClassName='step__search-dropdown'
              label='PO Company Name'
              placeholder='Choose Company'
              value={POClientId}
              onChange={this.onChangePOClient}
              options={clients}
              isRequired
              inputProps={{
                name: 'Client',
                id: 'client',
              }}
            />
            <SearchDropdown
              formClassName='step__form'
              selectClassName='step__search-dropdown'
              label='Client`s PO Approver'
              placeholder='Choose PO Approver'
              value={POApprover}
              onChange={this.onChangePOApprover}
              options={sortFilterList(clientContacts.filter(item => item.ClientId === SOWClientId))}
              disabled={disabledStep2Selects}
              isRequired={!sowTypeIsRequired}
              inputProps={{
                name: 'PO approver',
                id: 'po_approver',
              }}
              isAddingFeature
              parentEntityId={SOWClientId}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              clearClassName='svg_select_center'
              optionsClassName='option'
              label='Document Status'
              value={PODocumentStatusesId.toString()}
              onChange={this.onChangePODocumentStatus}
              placeholder='Choose Status'
              inputProps={{
                id: 'po-document-status',
                name: 'PO Document Status',
              }}
              isOptionObject
              selectOptions={sortFilterList(documentStatuses)}
              showClear={false}
            />
          </div>
          <div className='step__row'>
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              id='PO_start_date'
              label='PO Start Date'
              placeholder='Choose Start Date'
              value={startDatePO}
              onChange={this.handlePOStartDateChange}
              isRequired
              name='startDatePO'
              isStartDate
              forCreateProject
            />
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='step__date-picker'
              id='PO_end_date'
              label='PO End Date'
              placeholder='Choose End Date'
              minVal={startDatePO}
              disabled={poEndDateDisabled}
              value={endDatePO}
              onChange={this.handlePOEndDateChange}
              isRequired
              helperText='Choose Start Date first'
              name='endDatePO'
              isEndDate
              forCreateProject
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Payment Term'
              value={POPaymentTerm.toString()}
              onChange={this.onChangePOPaymentTerm}
              selectOptions={paymentTerms}
              isOptionObject
              placeholder='Choose Payment Term'
              inputProps={{
                name: 'Payment Term',
                id: 'payment_term',
              }}
              clearValue={this.clearValue('POPaymentTerm')}
            />
            <Select
              formClassName='step__form'
              selectClassName='step__select'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Invoice Date'
              value={POInvoiceDate.toString()}
              onChange={this.onChangePOInvoiceDate}
              selectOptions={allInvoiceDates}
              isOptionObject
              isRequired
              placeholder='Choose Invoice Date'
              inputProps={{
                name: 'Invoice Date',
                id: 'invoice_date',
              }}
              clearValue={this.clearValue('POInvoiceDate')}
            />
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__description'
              id='po-description'
              label='PO Status Comments'
              placeholder='Add PO Status Comments'
              maxlength='4000'
              value={PODescription}
              onChange={this.onChangePODescription}
              fullWidth
              multiline
              isRequired={isPOCommentFieldRequired}
            />
          </div>
          <div className='step__row'>
            <TextField
              formClassName='step__form'
              inputClassName='step__description'
              id='po-description'
              label='PO Budget Comments'
              placeholder='Add PO Budget Comments'
              maxlength='4000'
              value={POBudgetComments}
              onChange={this.onChangePOBudgetComments}
              fullWidth
              multiline
            />
          </div>
          <div className='buttons'>
            <div
              className={classNames('button save-and-close', { 'btn-disabled': isResetPOButtonDisabled })}
              onClick={this.resetOriginalPo}
            >
              <span>Reset Original PO</span>
            </div>
          </div>
          <div className='documents-container documents-container__block--padding'>
            <div
              className={classNames(
                'add-document__button add-document__button--margin',
                { 'btn-disabled': PODocuments.length === MAX_QUANTITY_OF_DOCUMENTS },
              )}
              onClick={this.showAddPOFields}
            >
              <CustomIcon iconName='plus-orange' />
              <span>Add Document</span>
            </div>
            <DocumentList
              columnsToDisplay={columnsToDisplay.documentList}
              items={sortDocumentByAlphabet(PODocuments)}
              actionLabel
              onEditClick={this.editPoDocuments}
              onDeleteClick={this.showPOConfirmationDialog}
              isEditable
              isDocumentType
              documentSelectOptions={documentTypes}
              viewFinancialInfo
            />
            {
              showAddPODocimentFields
                ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
                  <div className='c-list__item-column resume-member-inputs c-list__item-column'>
                    <UpdateDocumentItem
                      item={addPO}
                      onChangeNewDocumentObj={this.getNewPODocumentsChange}
                      disableSaveDocument={blockSaveAddPO}
                      newDocumentCancel={this.cancelAddPO}
                      newDocumentApply={this.applyAddPO}
                      isDocumentType
                      documentSelectOptions={documentTypes}
                    />
                  </div>
                </div>
                : null
            }
          </div>
        </Scrollbars>
        <div className='buttons'>
          <div
            className={classNames('button save-and-close', { 'btn-disabled': !isStep2FieldsRequired })}
            onClick={this.onSave}
            title={!isStep2FieldsRequired ? 'All required fields should be filled' : null}
          >
            <span>Save and Close</span>
          </div>
          <div className='right-side-buttons'>
            <div className='button cancel' onClick={this.onBack}>
              <span>Back</span>
            </div>
            <div
              className={classNames('button next', { 'btn-disabled': !isStep2FieldsRequired })}
              onClick={this.onNextStep}
              title={!isStep2FieldsRequired ? 'All required fields should be filled' : null}
            >
              <span>Next</span>
            </div>
          </div>
        </div>
        {showConfirmationDialog ? (
          <ConfirmationDialog
            dialogHeader={confirmationDialogTitle}
            dialogTitle='Are you sure to delete this item?'
            closeButtonTitle='Cancel'
            confirmButtonTitle='Delete'
            closeDialog={this.closeConfirmationDialog}
            confirmDialog={this.deleteDocuments}
          />
        ) : null}
      </div>
    );
  }
}

Step.propTypes = {
  onStepChange: PropTypes.func,
  onBack: PropTypes.func,
  initialState: PropTypes.object,
  SOWTypes: PropTypes.array,
  clients: PropTypes.array,
  documentStatuses: PropTypes.array,
  clientContacts: PropTypes.array,
  approvalStatuses: PropTypes.array,
  isBillableTrainee: PropTypes.bool,
};

export default connect((store) => ({
  SOWTypes: store.projectsReducer.SOWTypes,
  POStatuses: store.projectsReducer.approvalStatuses,
  approvalStatuses: store.projectsReducer.approvalStatuses,
  clients: store.filtersReducer.filters.Clients,
  businessGroups: store.projectsReducer.businessGroups,
  projectTeams: store.projectsReducer.projectTeams,
  documentStatuses: store.filtersReducer.filters.documentStatuses,
  paymentTerms: store.adminManageLookups.paymentTerms,
  clientContacts: store.projectsReducer.clientContacts,
}), ({ showAlertDialog }))(Step);
