export const LOAD_MANAGE_DOCUMENT_TYPES = '@admin/LOAD_MANAGE_DOCUMENT_TYPES';
export const SET_MANAGE_DOCUMENT_TYPES = '@admin/SET_MANAGE_DOCUMENT_TYPES';
export const CREATE_NEW_DOCUMENT_TYPE = '@admin/CREATE_NEW_DOCUMENT_TYPE';
export const UPDATE_DOCUMENT_TYPE = '@admin/UPDATE_DOCUMENT_TYPE';

export const loadManageDocumentTypes = () => ({
  type: LOAD_MANAGE_DOCUMENT_TYPES,
});

export const setManagesDocumentTypes = (docTypes) => ({
  type: SET_MANAGE_DOCUMENT_TYPES,
  payload: docTypes,
});

export const createNewDocumentTypes = (data) => {
  return {
    type: CREATE_NEW_DOCUMENT_TYPE,
    payload: data,
  };
};

export const updateDocumentTypes = (Name, Id) => {
  return {
    type: UPDATE_DOCUMENT_TYPE,
    payload: {
      Name,
      Id,
    },
  };
};
