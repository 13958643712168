import {
  all, call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_FILTERS,
  setFilters,
  GET_FILTERS_CERTIFICATES,
  setFiltersCertifications,
  GET_DOCUMENT_STATUSES,
  setDocumentStatuses,
} from '../actions/filtersActions';
import { setSettings } from '../../pages/admin/actions/adminActions';
import { finishRequest, startRequest } from '../actions/loadingActions';

export function* fetchFilters(action) {
  try {
    const [settings, filters, projectAlerts] = yield all([
      call(http, { method: 'GET', url: 'settings' }),
      call(http, { method: 'GET', url: 'filters', params: { filters: action.payload.filters } }),
      call(http, { method: 'GET', url: 'project-alerts' }),
    ]);

    yield put(setSettings(settings.data));
    yield put(setFilters({ ...filters.data, Alerts: projectAlerts.data }));
  } catch (e) {
    // error message
  }
}

export function* loadSertifications() {
  try {
    const certificatios = yield call(http, {
      method: 'GET',
      url: 'certificates',
    });
    yield put(setFiltersCertifications(certificatios.data));
  } catch (e) {
    // error message
  }
}

function* getDocumentStatuses() {
  const statuses = yield select((state) => state.filtersReducer.filters.documentStatuses);

  if (statuses && statuses.some((s) => s)) {
    return;
  }

  try {
    yield put(startRequest());
    const fetchedStatuses = yield call(http, { method: 'GET', url: 'doc-statuses' });

    yield put(setDocumentStatuses(fetchedStatuses.data));
  } finally {
    yield put(finishRequest());
  }
}

export default function* FiltersRootSaga() {
  yield takeEvery(GET_FILTERS, fetchFilters);
  yield takeEvery(GET_FILTERS_CERTIFICATES, loadSertifications);
  yield takeLatest(GET_DOCUMENT_STATUSES, getDocumentStatuses);
}
