import React from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core/';
import { TreeView, TreeItem } from '@material-ui/lab/';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderTwoToneIcon from '@material-ui/icons/FolderTwoTone';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const data = {
  index: 'root',
  children: [
    {
      type: 'folder',
      title: 'US',
      children: [
        {
          type: 'folder',
          title: '2019',
          children: [
            {
              type: 'folder',
              title: 'November',
              children: [
                {
                  type: 'folder',
                  title: 'Vouched',
                  children: [
                    {
                      type: 'file',
                      title: 'DraftStatus_20191101-20191130_Kharkiv.xlsx',
                      date: '21.02.2012 09:14:00',
                    },
                    {
                      type: 'file',
                      title: 'DraftStatus_20191101-20191130_Kharkiv.xlsx',
                      date: '01.04.2012 09:14:00',
                    },
                    {
                      type: 'file',
                      title: 'DraftStatus_20191101-20191130_Kharkiv.xlsx',
                      date: '01.01.2012 09:14:00',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'folder',
      title: 'UA',
      children: [
        {
          type: 'folder',
          title: '2019',
          children: [],
        },
      ],
    },
  ],
};

const validFolderIcon = <CheckCircleOutlineIcon
  style={{ width: 20, color: 'green' }}
/>;

const invalidFolderIcon = <HighlightOffIcon
  style={{ width: 20, color: 'red' }}
/>;

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    width: 'auto',
    alignItems: 'center',
    '&:empty': {
      marginRight: theme.spacing(-1.5),
    },
    '& svg': {
      fontSize: 24,
      '&:not(:last-child)': {
        marginRight: 6,
      },
    },
  },
  group: {
    marginLeft: 8,
    paddingLeft: 14,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
  },
  labelText: {
    display: 'inline-flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  labelIcon: {
    marginRight: theme.spacing(0.5),
  },
}))((props) => {
  const {
    id, labelIcon: LabelIcon, labelText, folderStatusIcon, labelDate, classes, isFile, ...other
  } = props;

  return (
    <TreeItem
      label={
        <>
          {LabelIcon && <LabelIcon className={classes.labelIcon} />}
          <span className={classes.labelText}>
            {isFile ? <>
              <input type='radio' id={id} name='tree-item' />
              <label htmlFor={id} className={classes.labelText}>
                <span>{labelText}</span>
                {labelDate && <small>{labelDate}</small>}
              </label>
              <a href='/billing'>Download</a>
            </>
              : <>
                <span>{labelText}</span>
                {folderStatusIcon}
              </>}
          </span>
        </>
      }
      classes={classes}
      {...other}
    />
  );
});

const validateData = (data) => {
  const { type, children } = data;
  const isFolder = type === 'folder';
  const itHasChildren = children && !!children.length;

  if (isFolder) {
    return itHasChildren ? data.children.every((item) => validateData(item)) : false;
  }

  return true;
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* eslint-disable-next-line max-len */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* eslint-disable-next-line max-len */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  );
}

const renderTree = (item) => {
  const {
    type, title, children, date, index, isValid,
  } = item;
  const isFolder = type === 'folder';
  const itHasChildren = children && !!children.length;
  const folderStatusIcon = typeof isValid === 'boolean' && (isValid ? validFolderIcon : invalidFolderIcon);
  const emptyFolderIcon = isFolder && !itHasChildren && FolderTwoToneIcon;

  if (isFolder) {
    return (<StyledTreeItem nodeId={index} labelIcon={emptyFolderIcon} labelText={title} folderStatusIcon={folderStatusIcon} key={index}>
      {itHasChildren && children.map((item, itemIndex) => renderTree({ ...item, index: `${index}-${itemIndex}` }))}
    </StyledTreeItem>);
  }

  return <StyledTreeItem nodeId={index} labelIcon={DescriptionOutlinedIcon} labelText={title} labelDate={date} key={index} id={index} isFile />;
};

export default function CustomizedGoodTreeView() {
  const validatedChildren = data.children.map(item => ({
    ...item,
    isValid: validateData(item),
  }));

  return (
    validatedChildren.map((item, itemIndex) => (
      <TreeView
        defaultExpandIcon={
          <>
            <PlusSquare />
            <FolderTwoToneIcon />
          </>
        }
        defaultCollapseIcon={
          <>
            <MinusSquare />
            <FolderOutlinedIcon />
          </>
        }
      >
        {renderTree({ ...item, index: `root-${itemIndex}` })}
      </TreeView>
    ))
  );
}
