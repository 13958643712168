import React from 'react';
import MultiSelect from 'common/components/form-items/multi-select/multi-select';
import { svgEditPencil } from 'services/svgEditPencil';
import classNames from 'classnames';
import '../../redux-form.css';

export default class renderTogglingMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  toggleEditMode = (editMode) => this.setState({ editMode });

  onChange = () => data => {
    const { input } = this.props;
    const newValue = input.value.some(id => id === data) ? input.value.filter(id => id !== data) : input.value.concat([data]);
    input.onChange(newValue);
  }

  onBlur = (event) => {
    const { input } = this.props;
    this.toggleEditMode(false);
    input.onBlur(event);
  }

  render() {
    const { editMode } = this.state;
    const {
      input, meta, valueToShow, options, isEditable, label, placeholder, styles, multiSelectStyles,
      showAsterix, showAsterixOnFocus, showValueRequired,
    } = this.props;

    const isAsterixVisible = showAsterixOnFocus ? (editMode && isEditable) : showAsterix;
    const isValueRequiredVisible = showValueRequired ? meta.invalid : (meta.invalid && meta.error !== 'Value required');

    return (
      <div className={styles.container}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              isAsterixVisible && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className={styles.field}>
          {
            editMode && isEditable ? <MultiSelect
              relcontClass={multiSelectStyles.relcontClass}
              contClass={multiSelectStyles.contClass}
              optionContClass={multiSelectStyles.optionContClass}
              selected={input.value}
              onChange={this.onChange()}
              onMouseLeave={this.onBlur}
              options={options}
              placeholder={placeholder}
            />
              : (
                <div className={styles.fieldView}>
                  <div
                    onClick={() => this.toggleEditMode(true)}
                    className={classNames(
                      styles.fieldContent,
                      { 'field--editable': isEditable },
                      { 'field--invalid': isValueRequiredVisible },
                    )}
                    title={valueToShow}
                  >
                    <span>{valueToShow}</span>
                    {isEditable && svgEditPencil(input.name)}
                  </div>
                  {
                    isValueRequiredVisible && (
                      <div className={styles.fieldError}>
                        <span>{meta.error}</span>
                      </div>
                    )
                  }
                </div>
              )
          }
        </div>
      </div>
    );
  }
}
