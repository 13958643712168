export const SET_SOW_REPORTS = '@reports/SET_REPORTS';
export const GET_SOW_REPORTS = '@reports/GET_REPORTS';
export const TRANSITION_TO_CLIENT = '@reports/TRANSITION_TO_CLIENT';
export const TRANSITION_TO_PROJECT = '@reports/TRANSITION_TO_PROJECT';
export const TRANSITION_TO_CONTRACT = '@reports/TRANSITION_TO_CONTRACT';
export const TRANSITION_TO_ROLE = '@reports/TRANSITION_TO_ROLE';
export const TRANSITION_TO_EMPLOYEE = '@reports/TRANSITION_TO_EMPLOYEE';

export const transitionToClient = (Id) => ({
  type: TRANSITION_TO_CLIENT,
  payload: {
    Id,
  },
});

export const transitionToProject = (id) => ({
  type: TRANSITION_TO_PROJECT,
  payload: {
    id,
  },
});

export const transitionToContract = (payload) => ({
  type: TRANSITION_TO_CONTRACT,
  payload,
});

export const transitionToRole = (payload) => ({
  type: TRANSITION_TO_ROLE,
  payload,
});

export const transitionToEmployee = (id) => ({
  type: TRANSITION_TO_EMPLOYEE,
  payload: {
    id,
  },
});

export const loadSowReports = (searchValue = '', column = '', sortDirection = 'Up', filters = {}) => ({
  type: GET_SOW_REPORTS,
  payload: {
    searchValue,
    column,
    sortDirection,
    filters,
  },
});

export const setSowReports = (reports) => ({
  type: SET_SOW_REPORTS,
  payload: reports,
});
