import * as React from 'react';
import classNames from 'classnames';
import CustomIcon from '../icon/Icon';
import DocumentItem from './document-item';
import ManageItem from './manage-item.js';
import { NO_ITEMS_TITLE } from 'utils/const-variable';

export class DocumentList extends React.Component {
  render() {
    const {
      items,
      columnsToDisplay,
      setSortSettings,
      sortColumnName,
      sortDirection,
      actionLabel,
      onItemClick,
      onEditClick,
      onDeleteClick,
      hideDots,
      onEditOptions,
      isEditable = true,
      showItemsEditMenu = true,
      editManageItem,
      isCheckboxShown,
      checkItem,
      entityForMerge,
      isDocumentType,
      documentSelectOptions,
      viewFinancialInfo,
    } = this.props;
    return (
      <div>
        <div className={classNames('c-list__header')}>
          {
            columnsToDisplay && columnsToDisplay.map((column) => (<div key={column.Id} className={classNames('c-list__header-col', column.className)}>
              {isCheckboxShown ? <div className='c-list__header-col smallest-col' /> : null}
              <div className='c-list__header-name'>
                {column.breakline ? (
                  <span>
                    {column.Name.split(' ')[0]}
                    <br />
                    {column.Name.split(' ')[1]}
                  </span>
                ) : (
                    <span>
                      {column.Name}
                    </span>
                  )}
                <div className={`c-list__arrows ${column.deleteArrow ? column.deleteArrow : null}`}>
                  {sortColumnName === column.Id && sortDirection === 'Up'
                    ? <CustomIcon
                      iconName='arrow-up-selected'
                      onClick={() => setSortSettings && setSortSettings(column.Id, 'Up')}
                      className='pointer'
                    />
                    : <CustomIcon
                      iconName='arrow-up-not-selected'
                      onClick={() => setSortSettings && setSortSettings(column.Id, 'Up')}
                      className='pointer'
                    />
                  }
                  {sortColumnName === column.Id && sortDirection === 'Down'
                    ? <CustomIcon
                      iconName='arrow-down-selected'
                      onClick={() => setSortSettings && setSortSettings(column.Id, 'Down')}
                      className='pointer'
                    />
                    : <CustomIcon
                      iconName='arrow-down-not-selected'
                      onClick={() => setSortSettings && setSortSettings(column.Id, 'Down')}
                      className='pointer'
                    />
                  }
                </div>
              </div>
            </div>))
          }
          {actionLabel ? <span className='action-lable action-lable-document-margin'>Action</span> : null}
        </div>
        {
          items && items.length
            ? (
              items && items.length && items.map((item, i) => {
                return (
                  editManageItem
                    ? (<ManageItem
                      key={JSON.stringify(item) || item.id}
                      itemId={i}
                      item={item}
                      columnsToDisplay={columnsToDisplay}
                      onItemClick={onItemClick}
                      onEditClick={onEditClick}
                      onDeleteClick={onDeleteClick}
                      hideDots={hideDots}
                      onEditOptions={onEditOptions}
                      isEditable={isEditable}
                      showItemsEditMenu={showItemsEditMenu}
                      isCheckboxShown={isCheckboxShown}
                      checkItem={checkItem}
                      checkedItem={entityForMerge && entityForMerge.includes(item.Id)}
                    />)
                    : (viewFinancialInfo ? <DocumentItem
                      key={JSON.stringify(item)}
                      keyId={JSON.stringify(item)}
                      itemId={i}
                      item={item}
                      columnsToDisplay={columnsToDisplay}
                      onItemClick={onItemClick}
                      onEditClick={onEditClick}
                      onDeleteClick={onDeleteClick}
                      hideDots={hideDots}
                      onEditOptions={onEditOptions}
                      isEditable={isEditable}
                      showItemsEditMenu={showItemsEditMenu}
                      isDocumentType={isDocumentType}
                      documentSelectOptions={documentSelectOptions}
                    /> : null)
                );
              })
            )
            : <div className='document-list__no-item'>{NO_ITEMS_TITLE}</div>
        }
      </div>
    );
  }
}

DocumentList.propTypes = {
};

export default DocumentList;
