import {
  SET_AUTHDATA, LOGOUT, SET_DEPARTMENTS, CHANGE_VIEW_FINANC_INFO, IS_HAS_FINANCIAL_ACCESS,
} from '../actions/authActions';

const initialState = {
  user: null,
  authToken: null,
  refreshToken: null,
  departments: [],
  HasFinancialAccess: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHDATA:
    case LOGOUT:
      return {
        ...state,
        user: action.payload.user,
        refreshToken: action.payload.refreshToken,
        authToken: action.payload.authToken,
      };
    case SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload.departments,
      };
    case CHANGE_VIEW_FINANC_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          Permissions: {
            ...state.user.Permissions,
            HasFinancialAccess: action.payload,
          },
        },
      };
    case IS_HAS_FINANCIAL_ACCESS:
      return {
        ...state,
        HasFinancialAccess: action.payload,
      };
    default:
      return state;
  }
}
