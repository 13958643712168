export const SET_TEAM_COMPOSITION_LIST_SETTINGS = '@reports/SET_TEAM_COMPOSITION_LIST_SETTINGS';
export const RESET_TEAM_COMPOSITION_LIST_SETTINGS = '@reports/RESET_TEAM_COMPOSITION_LIST_SETTINGS';

export const setTeamCompositionListSettings = (settings) => ({
  type: SET_TEAM_COMPOSITION_LIST_SETTINGS,
  payload: settings,
});

export const resetTeamCompositionListSettings = () => ({
  type: RESET_TEAM_COMPOSITION_LIST_SETTINGS,
});
