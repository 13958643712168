export const GET_CERTIFICATIONS_REPORTS = '@reports/GET_CERTIFICATIONS_REPORTS';
export const SET_CERTIFICATIONS_REPORTS = '@reports/SET_CERTIFICATIONS_REPORTS';
export const SET_CERTIFICATIONS_COUNTER = '@reports/SET_CERTIFICATIONS_COUNTER';
export const SET_CERTIFICATIONS_LIST_SETTINGS = '@reports/SET_CERTIFICATIONS_LIST_SETTINGS';
export const RESET_CERTIFICATIONS_LIST_SETTINGS = '@reports/RESET_CERTIFICATIONS_LIST_SETTINGS';
export const CHANGE_CERTIFICATIONS_COLUMNS_TO_DISPLAY = '@reports/CHANGE_CERTIFICATIONS_COLUMNS_TO_DISPLAY';

export const getCertificationsReports = (filters, searchValue, take, skip, sortSettings) => ({
  type: GET_CERTIFICATIONS_REPORTS,
  payload: {
    searchValue,
    filters,
    take,
    skip,
    sortSettings,
  },
});

export const setCertificationsReports = (reports) => ({
  type: SET_CERTIFICATIONS_REPORTS,
  payload: reports,
});

export const setCertificationsCounter = (count) => ({
  type: SET_CERTIFICATIONS_COUNTER,
  payload: count,
});

export const setCertificationsReportsListSettings = (settings) => ({
  type: SET_CERTIFICATIONS_LIST_SETTINGS,
  payload: settings,
});

export const resetCertificationsReportsListSettings = () => ({
  type: RESET_CERTIFICATIONS_LIST_SETTINGS,
});

export const changeColumnsToDisplay = (columnsToDisplay) => ({
  type: CHANGE_CERTIFICATIONS_COLUMNS_TO_DISPLAY,
  payload: { columnsToDisplay },
});
