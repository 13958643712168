import {
  call, put, takeEvery, takeLatest, select, debounce,
} from 'redux-saga/effects';
import http from 'services/http';
import { initialize } from 'redux-form';
import { BAD_REQUEST_STATUS, CONFLICT } from 'utils/const-variable';

import {
  GET_COUNTRIES,
  GET_OFFICE_BY_ID,
  GET_OFFICES,
  SEARCH_OFFICE, setCountries,
  setOfficeDetails,
  setOffices,
  setOfficeError,
  GET_HOLIDAYS,
  POST_HOLIDAYS,
  PUT_HOLIDAYS,
  DELETE_HOLIDAYS,
  setHolidays,
  PUT_LDAP_PATH,
  GET_BASE_BUSINESS_LOCATIONS,
  setBaseBusinessLocations,
  POST_BUSINESS_LOCATION,
  DELETE_OFFICE_BUSINESS_LOCATION,
  GET_OFFICE_BUSINESS_LOCATIONS,
  setOfficeBusinessLocations,
  PUT_BUSINESS_LOCATION,
} from '../actions/officesActions';
import { showAlertDialog } from 'common/actions/confirmationDialogActions.js';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import {
  CHANGE_OFFICE_BASIC_INFO,
  getOfficeBasicInfoFormData,
} from 'common/actions/reduxFormActions';
import { getCorrectLdapPaths } from 'utils/getCorrectLdapPaths';
import { addNotify } from '../../../common/actions/notificationsActions';
import { addNewNotification } from '../../../services/addNewNotification';
import moment from 'moment';
import { isFormDataChanged } from '../../../utils/isFormDataChanged';
import { getEmployeeBusinessLocations, loadEmployeeHolidays } from '../../members/actions/membersActions';
import { updateEmployeeBusinessLocation } from '../../members/sagas/membersSaga';

export function* getAllOffices() {
  try {
    yield put(startRequest());

    const offices = yield call(http, {
      method: 'GET',
      url: 'offices',
    });
    yield put(setOffices(offices.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}

export function* getCountries() {
  try {
    const counties = yield call(http, {
      method: 'GET',
      url: 'countries',
    });
    yield put(setCountries(counties.data));
  } catch (e) {
    // error message
  }
}

export function* searchOffices(action) {
  try {
    yield put(startRequest());
    const offices = yield call(http, {
      method: 'GET',
      url: `offices?searchName=${encodeURIComponent(action.payload.searchValue)}`,
    });
    yield put(setOffices(offices.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // error message
  }
}

export function* getOfficeDetails(action) {
  try {
    yield put(startRequest());
    const officeDetails = yield call(http, {
      method: 'GET',
      url: `offices/${action.payload}`,
    });

    yield put(setOfficeDetails(officeDetails.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    yield put(setOfficeError());
  }
}

export function* updateOfficeData() {
  const form = yield select(getOfficeBasicInfoFormData);
  if (!isFormDataChanged(form.data)) {
    return;
  }

  try {
    if (!form.data.syncErrors) {
      const putData = {
        Address: form.data.values.Address,
        CountryId: form.data.values.CountryId,
        DomainName: form.data.values.DomainName,
        Name: form.data.values.Name,
      };
      yield call(http, {
        method: 'PUT',
        url: `offices/${form.data.values.Id}`,
        data: putData,
      });
      yield call(getOfficeDetails, { payload: form.data.values.Id });
    }
  } catch (e) {
    if (form.data && e.response && e.response.status === BAD_REQUEST_STATUS) {
      yield put(showAlertDialog(e.response.data.message === 'Dublicated Name' ? 'Office name already exists' : e.response.data.message));
      yield refreshOfficeData(form.data.values.Id);
    }
  }
}

export function* refreshOfficeData(data) {
  const officeDetails = yield call(http, {
    method: 'GET',
    url: `offices/${data}`,
  });
  const reduxFormOfficeData = {
    Id: data,
    Address: officeDetails.data.Address,
    CountryId: officeDetails.data.Country.Id,
    CountryName: officeDetails.data.Country.Name,
    DomainName: officeDetails.data.DomainName,
    Name: officeDetails.data.Name,
    LDapPaths: getCorrectLdapPaths(officeDetails.data.LdapPaths),
  };
  yield put(initialize('OfficeBasicInfoForm', reduxFormOfficeData));
}

export function* loadHolidays(action) {
  try {
    const counties = yield call(http, {
      method: 'GET',
      url: `offices/${action.payload.id}/days-off?year=${action.payload.year}`,
    });
    yield put(setHolidays(counties.data));
  } catch (e) {
    // error message
  }
}

export function* addHoliday(action) {
  try {
    let locationId = action.payload.LocationId;
    if (action.payload.Location) {
      locationId = yield call(http, {
        method: 'POST',
        url: `offices/${action.payload.OfficeId}/business-locations`,
        data: action.payload.Location,
      });
    }
    action.payload.LocationId = locationId.data ? locationId.data : locationId;
    yield call(http, {
      method: 'POST',
      url: `offices/${action.payload.OfficeId}/days-off`,
      data: action.payload,
    });
    yield call(loadHolidays, { payload: { id: action.payload.OfficeId, year: action.payload.year || action.payload.Year } });
    yield call(getOfficeBusinessLocations, { payload: action.payload.OfficeId });
    if (action.payload.EmployeeId) {
      yield call(updateEmployeeBusinessLocation, {
        payload: {
          employeeId: action.payload.EmployeeId,
          businessLocationId: action.payload.LocationId,
          year: action.payload.year || action.payload.Year,
        },
      });
      yield put(loadEmployeeHolidays(action.payload.EmployeeId, action.payload.year || action.payload.Year));
      yield put(getEmployeeBusinessLocations(action.payload.EmployeeId));
    }
  } catch (e) {
    // error message
  }
}

export function* editHoliday(action) {
  try {
    let locationId = action.payload.LocationId;
    if (action.payload.Location) {
      locationId = yield call(http, {
        method: 'POST',
        url: `offices/${action.payload.OfficeId}/business-locations`,
        data: action.payload.Location,
      });
    }
    action.payload.LocationId = locationId.data ? locationId.data : locationId;
    yield call(http, {
      method: 'PUT',
      url: `offices/${action.payload.OfficeId}/days-off/${action.payload.Id}`,
      data: action.payload,
    });
    yield call(loadHolidays, { payload: { id: action.payload.OfficeId, year: action.payload.Year } });
    yield call(getOfficeBusinessLocations, { payload: action.payload.OfficeId });
    if (action.payload.EmployeeId) {
      yield put(loadEmployeeHolidays(action.payload.EmployeeId, action.payload.Year));
    }
  } catch (e) {
    // error message
  }
}

export function* deleteHoliday(action) {
  try {
    const { holiday, officeId, year } = action.payload;

    const result = yield call(http, {
      method: 'DELETE',
      url: `offices/${officeId}/days-off/${holiday.Id}`,
    });

    yield call(loadHolidays, { payload: { id: officeId, year: year } });
    if (action.payload.EmployeeId) {
      yield put(loadEmployeeHolidays(action.payload.EmployeeId, action.payload.year));
    }

    const target = `${moment(holiday.Date).format('MM-DD')} ${holiday.Description}`;
    const message = addNewNotification(result, target, ' has been deleted');

    yield put(addNotify(message));
  } catch (e) {
    // error message
  }
}

export function* updateLdap(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `offices/${action.payload.officeId}/ldap_paths`,
      data: { LdapPaths: action.payload.ldap },
    });
    yield call(getOfficeDetails, { payload: action.payload.officeId });
  } catch (e) {
    if (e.response.status === CONFLICT) {
      yield put(showAlertDialog(e.response.data.description));
    }
    if (e.response.status === BAD_REQUEST_STATUS) {
      yield put(showAlertDialog(e.response.data.message));
      yield call(getOfficeDetails, { payload: action.payload.officeId });
    }
  }
}

export function* getBaseBusinessLocations() {
  try {
    const businessLocations = yield call(http, {
      method: 'GET',
      url: 'offices/business-locations?onlyPrimary=true',
    });
    yield put(setBaseBusinessLocations(businessLocations.data));
  } catch (e) {
    // error message
  }
}

export function* getOfficeBusinessLocations(action) {
  try {
    const data = yield call(http, {
      method: 'GET',
      url: `offices/${action.payload}/business-locations`,
    });
    yield put(setOfficeBusinessLocations(data.data));
  } catch (e) {
    // error message
  }
}

export function* addBusinessLocation(action) {
  try {
    const data = yield call(http, {
      method: 'POST',
      url: `offices/${action.payload.OfficeId}/business-locations`,
      data: action.payload,
    });
    yield call(loadHolidays, { payload: { id: action.payload.OfficeId, year: action.payload.year } });
    yield call(getOfficeBusinessLocations, { payload: action.payload.OfficeId });
    if (action.payload.EmployeeId) {
      yield call(updateEmployeeBusinessLocation, {
        payload: {
          employeeId: action.payload.EmployeeId,
          businessLocationId: data.data,
          year: action.payload.year,
        },
      });
      yield put(loadEmployeeHolidays(action.payload.EmployeeId, action.payload.year));
      yield put(getEmployeeBusinessLocations(action.payload.EmployeeId));
    }
  } catch (e) {
    // error message
  }
}

export function* updateBusinessLocation(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `offices/${action.payload.OfficeId}/business-locations/${action.payload.Id}`,
      data: action.payload,
    });
    yield call(loadHolidays, { payload: { id: action.payload.OfficeId, year: action.payload.year } });
    yield call(getOfficeBusinessLocations, { payload: action.payload.OfficeId });
  } catch (e) {
    // error message
  }
}

export function* deleteBusinessLocation(action) {
  try {
    const { businessLocation, officeId, year } = action.payload;

    const result = yield call(http, {
      method: 'DELETE',
      url: `offices/${officeId}/business-locations/${businessLocation.Id}`,
    });

    yield call(loadHolidays, { payload: { id: officeId, year: year } });
    yield call(getOfficeBusinessLocations, { payload: officeId });

    const target = `Business location ${businessLocation.Name} in office ${action.payload.officeName}`;
    const message = addNewNotification(result, target, ' has been deleted');

    yield put(addNotify(message));
  } catch (e) {
    // error message
  }
}

export default function* officesRootSage() {
  yield takeEvery(GET_OFFICES, getAllOffices);
  yield takeLatest(SEARCH_OFFICE, searchOffices);
  yield takeLatest(GET_OFFICE_BY_ID, getOfficeDetails);
  yield takeEvery(PUT_LDAP_PATH, updateLdap);
  yield takeEvery(GET_COUNTRIES, getCountries);
  yield takeEvery(GET_HOLIDAYS, loadHolidays);
  yield takeEvery(POST_HOLIDAYS, addHoliday);
  yield takeEvery(PUT_HOLIDAYS, editHoliday);
  yield takeEvery(DELETE_HOLIDAYS, deleteHoliday);
  yield debounce(100, CHANGE_OFFICE_BASIC_INFO, updateOfficeData);
  yield takeEvery(POST_BUSINESS_LOCATION, addBusinessLocation);
  yield takeEvery(PUT_BUSINESS_LOCATION, updateBusinessLocation);
  yield takeEvery(GET_OFFICE_BUSINESS_LOCATIONS, getOfficeBusinessLocations);
  yield takeEvery(GET_BASE_BUSINESS_LOCATIONS, getBaseBusinessLocations);
  yield takeEvery(DELETE_OFFICE_BUSINESS_LOCATION, deleteBusinessLocation);
}
