import React, { Component } from 'react';
import { Icon } from 'office-ui-fabric-react';
import { mergeStyles, registerIcons } from 'office-ui-fabric-react/lib-commonjs/Styling';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Save, Cancel } from '@material-ui/icons';
import * as PropTypes from 'prop-types';

registerIcons({
  icons: {
    'save': <Save />,
    'cancel': <Cancel />,
    'red-clock': (
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.99992 0.333252C3.33325 0.333252 0.333252 3.33325 0.333252 6.99992C0.333252
          10.6666 3.33325 13.6666 6.99992 13.6666C10.6666 13.6666 13.6666 10.6666 13.6666
          6.99992C13.6666 3.33325 10.6666 0.333252 6.99992 0.333252ZM9.79992 9.79992L6.33325
          7.66659V3.66659H7.33325V7.13325L10.3333 8.93325L9.79992 9.79992Z'
          fill='#C00000'
        />
      </svg>
    ),
    'three-dots': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12
          8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10
          12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'trash': (
      <svg width='14' height='18' viewBox='0 0 14 18' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752
          17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM3.3335 6.25H10.6668V15.4167H3.3335V6.25ZM10.2085
          1.66667L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667H10.2085Z'
        // fill='#A5A5A5'
        />
      </svg>
    ),
    'plus-orange': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='#F26939' />
      </svg>
    ),
    'cross': (
      <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          opacity='0.54'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z'
          fill='black'
        />
      </svg>
    ),
    'arrow-left-details': (
      <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000155878 6L6.00016 1.23266e-07L7.41016 1.41Z' fill='#F2994A' />
      </svg>
    ),
    'archive': (
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.1167 4.35833L15.9583 2.95833C15.7333 2.675 15.3917 2.5 15 2.5H5C4.60833 2.5 4.26667 2.675 4.03333
          2.95833L2.88333 4.35833C2.64167 4.64167 2.5 5.01667 2.5 5.41667V15.8333C2.5 16.75 3.25 17.5 4.16667
          17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V5.41667C17.5 5.01667 17.3583 4.64167 17.1167 4.35833ZM5.2
          4.16667H14.8L15.475 4.975H4.53333L5.2 4.16667ZM4.16667 15.8333V6.66667H15.8333V15.8333H4.16667ZM11.2083
          8.33333H8.79167V10.8333H6.66667L10 14.1667L13.3333 10.8333H11.2083V8.33333Z'
          fill='#F26939'
        />
      </svg>
    ),
    'sync': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0)'>
          <path
            d='M11.987 0.00378418C12.0126 0.00378418 12.0382 0.035583 12.0767 0.0928216C12.1087 0.1437 14.8067
            3.35542 18.0751 7.22857C21.3434 11.1017 24.0094 14.288 23.9966 14.3071C23.9902 14.3262 21.3883
            16.444 18.2161 19.007C15.0438 21.57 12.3458 23.7514 12.2241 23.8532C12.0126 24.0249 11.9998 24.0313
            11.9229 23.955C11.878 23.9104 9.1736 21.7163 5.91165 19.077C2.6497 16.4376 -0.00984127 14.2626
            0.00297582 14.2308C0.0157929 14.199 2.69456 11.0254 5.96933 7.17133C9.2441 3.31726 11.9229 0.130981
            11.9357 0.0801021C11.9485 0.0292234 11.9677 0.00378418 11.987 0.00378418Z'
            fill='#F26939'
          />
          <path
            d='M11.5437 20.1823C11.172 20.0488 10.7234 19.6672 10.5376 19.311C10.3069 18.8722
            10.3261 18.1853 10.5824 17.6384C10.7811 17.2313 10.7811 17.2186 10.6529 17.1232C10.5824
            17.0724 9.73009 16.6017 8.76881 16.0866C7.80752 15.5714 6.96801 15.1199 6.91033
            15.0881C6.83343 15.0436 6.70525 15.0881 6.47455 15.2344C5.72475 15.705 4.9429
            15.6541 4.32768 15.0881C3.88549 14.6811 3.75732 14.3949 3.75732 13.8098C3.75732
            13.2437 3.87268 12.9512 4.23797 12.5887C4.73142 12.099 5.3915 11.9336 6.02595 12.1435C6.37201
            12.258 6.37842 12.258 6.59631 12.0926C6.85265 11.9018 8.90979 10.0384 9.90312 9.10348L10.5888
            8.45477L10.3838 8.02866C9.9672 7.17008 10.1787 6.21611 10.9093 5.64372C11.3066 5.33209 11.6014
            5.23669 12.1717 5.23669C13.5047 5.23669 14.434 6.62314 13.9341 7.86331C13.7995 8.20674 13.7995
            8.2131 13.9277 8.42297C14.0366 8.60741 15.8887 10.827 16.8692 11.959C17.0422 12.1562 17.2345
            12.3152 17.2986 12.3152C17.3627 12.3152 17.5806 12.2516 17.7856 12.1753C18.7405 11.8 19.7466
            12.2198 20.144 13.1611C20.4003 13.7652 20.176 14.5793 19.6313 15.069C19.2468 15.4124 18.9648
            15.5206 18.4521 15.5206C18.0932 15.5206 17.9587 15.4824 17.5806 15.2789C17.2986 15.1262 17.0807
            15.0499 16.991 15.0754C16.8372 15.1135 14.4724 16.7226 13.9469 17.1423C13.6265 17.4031 13.6008
            17.4985 13.7675 17.8864C13.9277 18.268 13.9085 18.8658 13.729 19.2411C13.5304 19.6544 13.0369
            20.0678 12.6075 20.195C12.1717 20.3159 11.909 20.3159 11.5437 20.1823ZM11.6527 13.1738V9.54866L11.4476
            9.26883C11.3322 9.1162 11.2233 8.989 11.2041 8.989C11.1207 8.989 6.98082 12.7222 6.98082 12.7985C6.98082
            12.8176 7.04491 13.0084 7.12181 13.2119C7.19871 13.4218 7.25639 13.7207 7.24998 13.8924C7.24357 14.0642
            7.24357 14.2549 7.24357 14.3185C7.24998 14.4648 8.63423 15.3425 10.7106 16.5318C11.281 16.8625 11.4796
            16.9452 11.5501 16.8879C11.6334 16.818 11.6527 16.112 11.6527 13.1738ZM13.4086 16.3791C16.1515 14.6683
            16.6641 14.3249 16.6962 14.1977C16.7154 14.1214 16.741 13.8225 16.7474 13.5236L16.7667 12.9893L15.3696
            11.3421C13.87 9.58046 13.3189 8.98264 13.1907 8.98264C13.1458 8.98264 12.9215 9.10984 12.6908 9.26247L12.2679
            9.5423L12.2871 13.1611C12.3063 16.4046 12.332 16.9515 12.4537 16.9515C12.4665 16.9579 12.9023 16.6971
            13.4086 16.3791Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    'add-extend': (
      <svg width='78' height='24' viewBox='0 0 78 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M37.75 12.7422H32.8203V16.7734H38.5469V18H31.3203V6.625H38.4688V7.85938H32.8203V11.5156H37.75V12.7422ZM43.0234
          12.6328L44.8984 9.54688H46.5859L43.8203 13.7266L46.6719 18H45L43.0469 14.8359L41.0938 18H39.4141L42.2656
          13.7266L39.5 9.54688H41.1719L43.0234 12.6328ZM50.0859 7.5V9.54688H51.6641V10.6641H50.0859V15.9062C50.0859
          16.2448 50.1562 16.5 50.2969 16.6719C50.4375 16.8385 50.6771 16.9219 51.0156 16.9219C51.1823 16.9219
          51.4115 16.8906 51.7031 16.8281V18C51.3229 18.1042 50.9531 18.1562 50.5938 18.1562C49.9479 18.1562 49.4609
          17.9609 49.1328 17.5703C48.8047 17.1797 48.6406 16.625 48.6406 15.9062V10.6641H47.1016V9.54688H48.6406V7.5H50.0859ZM56.8672
          18.1562C55.7214 18.1562 54.7891 17.7812 54.0703 17.0312C53.3516 16.276 52.9922 15.2682 52.9922 14.0078V13.7422C52.9922
          12.9036 53.151 12.1562 53.4688 11.5C53.7917 10.8385 54.2396 10.3229 54.8125 9.95312C55.3906 9.57812 56.0156 9.39062
          56.6875 9.39062C57.7865 9.39062 58.6406 9.7526 59.25 10.4766C59.8594 11.2005 60.1641 12.237 60.1641
          13.5859V14.1875H54.4375C54.4583 15.0208 54.7005 15.6953 55.1641 16.2109C55.6328 16.7214 56.2266 16.9766 56.9453
          16.9766C57.4557 16.9766 57.888 16.8724 58.2422 16.6641C58.5964 16.4557 58.9062 16.1797 59.1719 15.8359L60.0547
          16.5234C59.3464 17.612 58.2839 18.1562 56.8672 18.1562ZM56.6875 10.5781C56.1042 10.5781 55.6146 10.7917 55.2188
          11.2188C54.8229 11.6406 54.5781 12.2344 54.4844 13H58.7188V12.8906C58.6771 12.1562 58.4792 11.5885 58.125
          11.1875C57.7708 10.7812 57.2917 10.5781 56.6875 10.5781ZM63.2109 9.54688L63.2578 10.6094C63.9036 9.79688
          64.7474 9.39062 65.7891 9.39062C67.5755 9.39062 68.4766 10.3984 68.4922 12.4141V18H67.0469V12.4062C67.0417
          11.7969 66.901 11.3464 66.625 11.0547C66.3542 10.763 65.9297 10.6172 65.3516 10.6172C64.8828 10.6172 64.4714
          10.7422 64.1172 10.9922C63.763 11.2422 63.487 11.5703 63.2891 11.9766V18H61.8438V9.54688H63.2109ZM70.3203
          13.7031C70.3203 12.4062 70.6276 11.3646 71.2422 10.5781C71.8568 9.78646 72.6615 9.39062 73.6562 9.39062C74.6458 9.39062
          75.4297 9.72917 76.0078 10.4062V6H77.4531V18H76.125L76.0547 17.0938C75.4766 17.8021 74.6719 18.1562 73.6406
          18.1562C72.6615 18.1562 71.862 17.7552 71.2422 16.9531C70.6276 16.151 70.3203 15.1042 70.3203 13.8125V13.7031ZM71.7656
          13.8672C71.7656 14.8255 71.9635 15.5755 72.3594 16.1172C72.7552 16.6589 73.3021 16.9297 74 16.9297C74.9167
          16.9297 75.5859 16.5182 76.0078 15.6953V11.8125C75.5755 11.0156 74.9115 10.6172 74.0156 10.6172C73.3073
          10.6172 72.7552 10.8906 72.3594 11.4375C71.9635 11.9844 71.7656 12.7943 71.7656 13.8672Z'
          fill='black'
        />
        <path
          d='M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58001 4 4.01001 7.58 4.01001 12C4.01001 16.42 7.58001 20 12 20C15.73 20 18.84
          17.45 19.73 14H17.65C16.83 16.33 14.61 18 12 18C8.69001 18 6.00001 15.31 6.00001 12C6.00001 8.69 8.69001 6 12 6C13.66
          6 15.14 6.69 16.22 7.78L13 11H20V4L17.65 6.35Z'
          fill='#F26939'
        />
      </svg>
    ),
    'export': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.59 9.59L13 13.17L13 4L11 4L11 13.17L7.41 9.58L6 11L12 17L18 11L16.59 9.59ZM18 18L6 18L6 20L18 20L18 18Z'
          fill='#F26939'
        />
      </svg>
    ),
    'arrow-up-selected': (
      <svg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.71429 3.57144L3.50001 1.25015e-05L0.285723 3.57144L6.71429 3.57144Z'
          fill='#FF0000'
        />
      </svg>
    ),
    'arrow-up-not-selected': (
      <svg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.71429 3.57144L3.50001 1.25015e-05L0.285723 3.57144L6.71429 3.57144Z'
          fill='#4B4B4B'
        />
      </svg>
    ),
    'arrow-down-selected': (
      <svg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.285706 0.428619L3.49999 4.00005L6.71428 0.428619L0.285706 0.428619Z'
          fill='#FF0000'
        />
      </svg>
    ),
    'arrow-down-not-selected': (
      <svg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.285706 0.428619L3.49999 4.00005L6.71428 0.428619L0.285706 0.428619Z'
          fill='#4B4B4B'
        />
      </svg>
    ),
    'shadow': (
      <svg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0)'>
          <path
            d='M20.8998 3.7334C19.8871 3.7334 18.2459 4.56892 17.2332 5.60006C16.2204 6.63121
          16.2204 7.46673 17.2332 7.46673C18.2459 7.46673 19.8871 6.63121 20.8998 5.60006C21.9126
          4.56892 21.9126 3.7334 20.8998 3.7334Z'
            fill='#979797'
          />
          <path
            d='M7.33343 14.1867V14.9334H6.6001V17.92L10.2668 14.1867H11.0001L6.6001
          18.6667V20.9067L11.7334 15.68H12.4668L19.8001 8.21338H13.2001L7.33343 14.1867Z'
            fill='#979797'
          />
          <path
            d='M3.30013 4.47992C4.31265 4.47992 5.13346 3.64418 5.13346 2.61325C5.13346 1.58232
          4.31265 0.746582 3.30013 0.746582C2.28761 0.746582 1.4668 1.58232 1.4668 2.61325C1.4668
          3.64418 2.28761 4.47992 3.30013 4.47992Z'
            fill='#979797'
          />
          <path
            d='M5.86667 14.1866H6.6V5.22656H0V14.1866H0.733333V21.6532H2.93333V12.6932H3.66667V21.6532H5.86667V14.1866Z'
            fill='#979797'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width='22' height='28' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    'edit-pencil': (
      <svg className='role-edit-svg-options' width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.42749 2.53983C8.38526 2.49029 8.32571 2.45962 8.26161 2.45441C8.1975 2.44919 8.13395 2.46984 8.08458
          2.51193L0.668173 10.5268C0.580167 10.6231 0.522087 10.7439 0.501273 10.8739L0.000574679 13.8121C-0.00152877 13.8382
          0.00215754 13.8644 0.0113582 13.8888C0.0205588 13.9132 0.0350358 13.9351 0.053709 13.953C0.0723821 13.9709 0.0947686
          13.9842 0.119197 13.992C0.143625 13.9998 0.169465 14.0019 0.194785 13.9981L3.03815 13.3255C3.16429 13.2973 3.27941
          13.2315 3.36891 13.1365L10.7884 5.11847C10.8265 5.06596 10.8432 5.0003 10.8347 4.93546C10.8261 4.87063 10.7932 4.81175
          10.7428 4.77135L8.42749 2.53983Z'
          fill='#C4C4C4'
        />
        <path
          d='M13.0491 2.31044L10.7398 0.0789194C10.6903 0.0283864 10.6232 1.68086e-07 10.5532 1.68086e-07C10.4832
          1.68086e-07 10.4161 0.0283864 10.3665 0.0789194L8.90086 1.66268C8.84435 1.72075 8.81262 1.7993 8.81262 1.88118C8.81262
          1.96306 8.84435 2.04162 8.90086 2.09969L11.1586 4.26922C11.2154 4.32695 11.2923 4.35935 11.3725 4.35935C11.4527
          4.35935 11.5296 4.32695 11.5864 4.26922L13.0521 2.68546C13.0994 2.63477 13.1254 2.56731 13.1249 2.49732C13.1243
          2.42734 13.0971 2.36033 13.0491 2.31044Z'
          fill='#C4C4C4'
        />
      </svg>
    ),
    'dropdown-triangle': (
      <svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 8L5 4L-3.49691e-07 0L0 8Z' fill='#4B4B4B' />
      </svg>
    ),
    'sidebar-menu': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z' fill='white' />
      </svg>
    ),
    'sidebar-logo': (
      <svg width='120' height='16' viewBox='0 0 120 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.59141e-07 15.3616L6.61986 0.0593109H9.68907L16.4293 15.3616H13.3601L11.4343 11.2098H6.43932L7.40221
          8.71877H10.652L8.12437 2.96557L3.00903 15.3616H4.59141e-07ZM20.4614 0.0593109V15.3616H23.3501V0.0593109H20.4614ZM30.1505
          0.0593109L24.2528 6.40562V8.24427L30.5717 15.4209H34.5436L26.7803 7.23598L33.6409 0.0593109H30.1505ZM53.1996
          0.0593109L46.3992 15.3616H43.33L36.7101 0.0593109H40.1404L44.9549 11.803L49.7693 0.0593109H53.1996ZM72.5176
          0.0593109V15.3616H81.6048L82.6279 12.9299H75.4062V0.0593109H72.5176ZM88.345 7.71047C88.345 11.2692 89.9699 13.0485
          93.1595 13.0485C96.2889 13.0485 97.8536 11.2692 97.8536 7.71047C97.8536 4.2111 96.2889 2.43176 93.1595 2.43176C89.9097
          2.43176 88.345 4.15179 88.345 7.71047ZM85.336 7.76978C85.336 2.55039 87.9238 4.5251e-07 93.1595 4.5251e-07C98.2748
          4.5251e-07 100.863 2.6097 100.863 7.76978C100.863 12.9299 98.2748 15.5396 93.1595 15.5396C88.1645 15.4803 85.5767 12.9299
          85.336 7.76978ZM105.677 15.3616V0.0593109H108.566L117.232 11.0319V0.0593109H120V15.3616H117.111L108.445
          4.15179V15.3616H105.677Z'
          fill='white'
        />
        <path
          d='M67.5226 13.6416C67.643 13.8195 67.7633 13.9975 67.7633 14.2347C67.7633 14.4719 67.7633 14.6499 67.643 14.8278C67.5226
          15.0651 67.4023 15.243 67.1615 15.3023C66.9208 15.4209 66.7403 15.4209 66.4995 15.4209H52.2368C52.0562 15.3616 51.8757 15.3023
          51.7553 15.243C51.6349 15.1244 51.5146 15.0057 51.3942 14.8871C51.334 14.7092 51.2739 14.4719 51.2739 14.2347C51.2739 13.9975
          51.3942 13.8195 51.5146 13.6416C51.7553 13.345 51.996 13.2264 52.3571 13.2857L66.0783 13.2264C66.3792 13.2264 66.6199 13.2264
          66.8606 13.2264C67.1615 13.2857 67.4022 13.4637 67.5226 13.6416Z'
          fill='#D86A43'
        />
        <path
          d='M66.6199 0.0592952C66.8004 0.118607 66.9809 0.177917 67.1615 0.237229C67.342 0.29654 67.4624 0.415164 67.5827
          0.593098C67.6429 0.711721 67.7031 0.889654 67.7633 1.06759C67.8235 1.24552 67.7633 1.42346 67.7031 1.60139C67.6429 1.77933
          67.5226 1.95726 67.342 2.07588C67.1615 2.1945 66.9809 2.31313 66.8004 2.31313L59.7593 2.25381C59.5787 2.25381 59.3982 2.25381
          59.1575 2.25381C58.9769 2.25381 58.7362 2.25381 58.5557 2.25381C58.3751 2.25381 58.1946 2.1945 58.014 2.13519C57.8335 2.07588
          57.7131 1.89795 57.5928 1.72001C57.5326 1.60139 57.4724 1.42346 57.4724 1.24552C57.4724 1.06759 57.4724 0.889654 57.5326
          0.771032C57.5928 0.593098 57.7733 0.415164 57.8937 0.296541C58.0742 0.177919 58.2548 0.118607 58.4353 0.0592952H64.6339H66.6199Z'
          fill='#D86A43'
        />
        <path
          d='M66.7402 6.70219C67.1013 6.76151 67.4022 6.88013 67.5828 7.236C67.7031 7.35462 67.7633 7.53255 67.7633 7.71049C67.7633
          7.88842 67.7633 8.06636 67.7031 8.18498C67.6429 8.36291 67.5828 8.48153 67.4022 8.60016C67.2819 8.71878 67.1013 8.8374 66.9208
          8.8374H66.4995H55.4263C55.2458 8.77809 55.005 8.71878 54.8847 8.54084C54.7041 8.36291 54.5838 8.18498 54.5838 8.00704C54.5236
          7.82911 54.5236 7.59187 54.5838 7.41393C54.644 7.236 54.7643 7.05806 54.9449 6.88013C55.1254 6.76151 55.3059 6.64288 55.6068
          6.64288L66.7402 6.70219Z'
          fill='#D86A43'
        />
      </svg>
    ),
    'sidebar-dashboard': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 3H13V9H21V3ZM11 3H3V13H11V3ZM21
          11H13V21H21V11ZM11 15H3V21H11V15Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-reports': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17ZM19.5 19.1H4.5V5H19.5V19.1ZM19.5 3H4.5C3.4 3 2.5 3.9 2.5 5V19C2.5
          20.1 3.4 21 4.5 21H19.5C20.6 21 21.5 20.1 21.5 19V5C21.5 3.9 20.6 3 19.5 3Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-offices': (
      <svg width='24' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z' fill='white' />
      </svg>
    ),
    'sidebar-employees': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9
          15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5
          8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9
          7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5
          8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96
          11.87 14.46 12 15 12Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-clients': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14 6V4H10V6H14ZM4 8V19H20V8H4ZM20 6C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L2.01
          8C2.01 6.89 2.89 6 4 6H8V4C8 2.89 8.89 2 10 2H14C15.11 2 16 2.89 16 4V6H20Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-projects': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20
          18H4V8H20V18Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-po': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7 15H14V17H7V15ZM7 11H17V13H7V11ZM7 7H17V9H7V7ZM19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.86 3
          4.73 3.01 4.6 3.04C4.21 3.12 3.86 3.32 3.59 3.59C3.41 3.77 3.26 3.99 3.16 4.23C3.06 4.46 3 4.72 3 5V19C3 19.27 3.06 19.54
          3.16 19.78C3.26 20.02 3.41 20.23 3.59 20.42C3.86 20.69 4.21 20.89 4.6 20.97C4.73 20.99 4.86 21 5 21H19C20.1 21 21 20.1 21
          19V5C21 3.9 20.1 3 19 3ZM12 2.75C12.41 2.75 12.75 3.09 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25C11.59 4.25 11.25 3.91 11.25
          3.5C11.25 3.09 11.59 2.75 12 2.75ZM19 19H5V5H19V19Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-manageuser': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20.84 4.22C20.79 4.1 20.73 3.99 20.66 3.88C20.52 3.67 20.33 3.48 20.12 3.34C20.01 3.27 19.9 3.21 19.78
          3.16C19.54 3.06 19.28 3 19 3H18V1H16V3H8V1H6V3H5C4.58 3 4.2 3.13 3.88 3.34C3.67 3.48 3.48 3.67 3.34 3.88C3.27
          3.99 3.21 4.1 3.16 4.22C3.06 4.46 3 4.72 3 5V19C3 20.1 3.89 21 5 21H19C19.28 21 19.54 20.94 19.78 20.84C19.9
          20.79 20.01 20.73 20.12 20.66C20.33 20.52 20.52 20.33 20.66 20.12C20.87 19.8 21 19.41 21 19V5C21 4.72 20.94 4.46 20.84
          4.22ZM5 19V5H19V19H5ZM12 12.88C9.97 12.88 6 13.96 6 16.46V18H18V16.47C18 13.96 14.03 12.88 12 12.88ZM8.31 16C9 15.44 10.69
          14.88 12 14.88C13.31 14.88 15.01 15.44 15.69 16H8.31ZM12 12C13.65 12 15 10.65 15 9C15 7.35 13.65 6 12 6C10.35 6 9 7.35 9
          9C9 10.65 10.35 12 12 12ZM12 8C12.55 8 13 8.45 13 9C13 9.55 12.55 10 12 10C11.45 10 11 9.55 11 9C11 8.45 11.45 8 12 8Z'
          fill='white'
        />
      </svg>
    ),
    'sidebar-settings': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73
          9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.57 5.11 19.4 5.02 19.22 5.02C19.16 5.02 19.1 5.03 19.05 5.05L16.56
          6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99999C9.74999 2 9.53999 2.18
          9.50999 2.42L9.12999 5.07C8.51999 5.32 7.95999 5.66 7.43999 6.05L4.94999 5.05C4.88999 5.03 4.82999 5.02
          4.76999 5.02C4.59999 5.02 4.42999 5.11 4.33999 5.27L2.33999 8.73C2.20999 8.95 2.26999 9.22
          2.45999 9.37L4.56999 11.02C4.52999 11.34 4.49999 11.67 4.49999 12C4.49999 12.33 4.52999 12.66 4.56999
          12.98L2.45999 14.63C2.26999 14.78 2.21999 15.05 2.33999 15.27L4.33999 18.73C4.42999 18.89 4.59999 18.98
          4.77999 18.98C4.83999 18.98 4.89999 18.97 4.94999 18.95L7.43999 17.95C7.95999 18.35 8.51999 18.68 9.12999
          18.93L9.50999 21.58C9.53999 21.82 9.74999 22 9.99999 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87
          18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.11 18.97 19.17 18.98 19.23 18.98C19.4 18.98
          19.57 18.89 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM17.45 11.27C17.49
          11.58 17.5 11.79 17.5 12C17.5 12.21 17.48 12.43 17.45 12.73L17.31 13.86L18.2 14.56L19.28 15.4L18.58
          16.61L17.31 16.1L16.27 15.68L15.37 16.36C14.94 16.68 14.53 16.92 14.12 17.09L13.06 17.52L12.9 18.65L12.7
          20H11.3L11.11 18.65L10.95 17.52L9.88999 17.09C9.45999 16.91 9.05999 16.68 8.65999 16.38L7.74999 15.68L6.68999
          16.11L5.41999 16.62L4.71999 15.41L5.79999 14.57L6.68999 13.87L6.54999 12.74C6.51999 12.43 6.49999
          12.2 6.49999 12C6.49999 11.8 6.51999 11.57 6.54999 11.27L6.68999 10.14L5.79999 9.44L4.71999 8.6L5.41999
          7.39L6.68999 7.9L7.72999 8.32L8.62999 7.64C9.05999 7.32 9.46999 7.08 9.87999 6.91L10.94 6.48L11.1 5.35L11.3
          4H12.69L12.88 5.35L13.04 6.48L14.1 6.91C14.53 7.09 14.93 7.32 15.33 7.62L16.24 8.32L17.3 7.89L18.57
          7.38L19.27 8.59L18.2 9.44L17.31 10.14L17.45 11.27ZM12 8C9.78999 8 7.99999 9.79 7.99999 12C7.99999
          14.21 9.78999 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM12 14C10.9 14 9.99999 13.1 9.99999
          12C9.99999 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14Z'
          fill='white'
        />
      </svg>
    ),
    'list-toolbar-settings': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73
          9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.57 5.11 19.4 5.02 19.22 5.02C19.16 5.02 19.1 5.03 19.05 5.05L16.56
          6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99999C9.74999 2 9.53999 2.18
          9.50999 2.42L9.12999 5.07C8.51999 5.32 7.95999 5.66 7.43999 6.05L4.94999 5.05C4.88999 5.03 4.82999 5.02
          4.76999 5.02C4.59999 5.02 4.42999 5.11 4.33999 5.27L2.33999 8.73C2.20999 8.95 2.26999 9.22
          2.45999 9.37L4.56999 11.02C4.52999 11.34 4.49999 11.67 4.49999 12C4.49999 12.33 4.52999 12.66 4.56999
          12.98L2.45999 14.63C2.26999 14.78 2.21999 15.05 2.33999 15.27L4.33999 18.73C4.42999 18.89 4.59999 18.98
          4.77999 18.98C4.83999 18.98 4.89999 18.97 4.94999 18.95L7.43999 17.95C7.95999 18.35 8.51999 18.68 9.12999
          18.93L9.50999 21.58C9.53999 21.82 9.74999 22 9.99999 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87
          18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.11 18.97 19.17 18.98 19.23 18.98C19.4 18.98
          19.57 18.89 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM17.45 11.27C17.49
          11.58 17.5 11.79 17.5 12C17.5 12.21 17.48 12.43 17.45 12.73L17.31 13.86L18.2 14.56L19.28 15.4L18.58
          16.61L17.31 16.1L16.27 15.68L15.37 16.36C14.94 16.68 14.53 16.92 14.12 17.09L13.06 17.52L12.9 18.65L12.7
          20H11.3L11.11 18.65L10.95 17.52L9.88999 17.09C9.45999 16.91 9.05999 16.68 8.65999 16.38L7.74999 15.68L6.68999
          16.11L5.41999 16.62L4.71999 15.41L5.79999 14.57L6.68999 13.87L6.54999 12.74C6.51999 12.43 6.49999
          12.2 6.49999 12C6.49999 11.8 6.51999 11.57 6.54999 11.27L6.68999 10.14L5.79999 9.44L4.71999 8.6L5.41999
          7.39L6.68999 7.9L7.72999 8.32L8.62999 7.64C9.05999 7.32 9.46999 7.08 9.87999 6.91L10.94 6.48L11.1 5.35L11.3
          4H12.69L12.88 5.35L13.04 6.48L14.1 6.91C14.53 7.09 14.93 7.32 15.33 7.62L16.24 8.32L17.3 7.89L18.57
          7.38L19.27 8.59L18.2 9.44L17.31 10.14L17.45 11.27ZM12 8C9.78999 8 7.99999 9.79 7.99999 12C7.99999
          14.21 9.78999 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM12 14C10.9 14 9.99999 13.1 9.99999
          12C9.99999 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'question-mark': (
      <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.875 11.25H8.125V10H6.875V11.25ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5
          13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563
          2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5
          12.5ZM7.5 3.75C6.11875 3.75 5 4.86875 5 6.25H6.25C6.25 5.5625 6.8125 5 7.5 5C8.1875 5 8.75 5.5625 8.75
          6.25C8.75 7.5 6.875 7.34375 6.875 9.375H8.125C8.125 7.96875 10 7.8125 10 6.25C10 4.86875 8.88125 3.75
          7.5 3.75Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'edit-pencil-small': (
      <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.54'>
          <path
            d='M6.01964 1.81402C5.98948 1.77864 5.94695 1.75673 5.90116 1.75301C5.85537 1.74928 5.80998 1.76403 5.77471
                      1.7941L0.477267 7.51902C0.414405 7.58782 0.37292 7.6741 0.358053 7.76697L0.000410485 9.86566C-0.00109198
                      9.88427 0.00154111 9.90299 0.00811298 9.92041C0.0146849 9.93784 0.0250256 9.95352 0.0383636 9.9663C0.0517016
                      9.97907 0.067692 9.9886 0.0851408 9.99417C0.10259 9.99975 0.121047 10.0012 0.139133 9.99849L2.17011
                      9.5181C2.26021 9.49795 2.34244 9.45095 2.40637 9.38305L7.70598 3.65592C7.73326 3.61841 7.74512 3.5715
                      7.73905 3.5252C7.73298 3.47889 7.70945 3.43683 7.67347 3.40797L6.01964 1.81402Z'
            fill='white'
          />
          <path
            d='M9.32097 1.65032L7.67148 0.0563711C7.63611 0.020276 7.58817 1.20061e-07 7.53817 1.20061e-07C7.48818 1.20061e-07
                      7.44023 0.020276 7.40487 0.0563711L6.35795 1.18763C6.31758 1.22911 6.29492 1.28522 6.29492 1.3437C6.29492 1.40219
                      6.31758 1.4583 6.35795 1.49978L7.97059 3.04945C8.01121 3.09068 8.06614 3.11382 8.12341 3.11382C8.18067 3.11382
                      8.23561 3.09068 8.27622 3.04945L9.32313 1.91819C9.35688 1.88198 9.37552 1.83379 9.37511 1.78381C9.37471 1.73382
                      9.35529 1.68595 9.32097 1.65032Z'
            fill='white'
          />
        </g>
      </svg>
    ),
    'cross-small': (
      <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path opacity='0.54' fillRule='evenodd' clipRule='evenodd' d='M10 1L9 0L5 4L1 0L0 1L4 5L0 9L1 10L5 6L9 10L10 9L6 5L10 1Z' fill='white' />
      </svg>
    ),
    'arrow-history': (
      <svg width='30' height='8' viewBox='0 0 37 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M36.3536 4.35355C36.5488 4.15829 36.5488 3.84171 36.3536 3.64645L33.1716 0.464466C32.9763 0.269204 32.6597 0.269204
          32.4645 0.464466C32.2692 0.659728 32.2692 0.976311 32.4645 1.17157L35.2929 4L32.4645 6.82843C32.2692 7.02369 32.2692 7.34027
          32.4645 7.53553C32.6597 7.7308 32.9763 7.7308 33.1716 7.53553L36.3536 4.35355ZM0 4.5H36V3.5H0V4.5Z'
          fill='#F26939'
        />
      </svg>
    ),
    'open-role': (
      <svg width='9' height='22' viewBox='0 0 9 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.10157 7.38031H7.08262C8.11851 7.38031 8.97807 8.21783 9.00011
          9.27576V13.6838C9.00011 14.6095 8.22871 15.3809 7.30302 15.3809V19.7448C7.30302 20.7366 6.50958 21.53 5.51778
          21.53H3.66641C2.67461 21.53 1.88117 20.7366 1.88117 19.7448V15.3809C0.933444 15.3809 0.184082 14.6095 0.184082
          13.6838V9.2978C0.184082 8.23987 1.04364 7.38031 2.10157 7.38031ZM3.80566 14.9268H4.97754L5.14355 10H3.63965L3.80566
          14.9268ZM4.96289 15.9082C4.81966 15.7682 4.62923 15.6982 4.3916 15.6982C4.15723 15.6982 3.9668 15.7699 3.82031 15.9131C3.67708
          16.0531 3.60547 16.2305 3.60547 16.4453C3.60547 16.6602 3.67708 16.8376 3.82031 16.9775C3.9668 17.1175 4.15723 17.1875 4.3916
          17.1875C4.62923 17.1875 4.81966 17.1175 4.96289 16.9775C5.10938 16.8376 5.18262 16.6602 5.18262 16.4453C5.18262 16.2272 5.10938
          16.0482 4.96289 15.9082Z'
          fill='#BF0000'
        />
        <path
          d='M4.59211 6.52076C6.23538 6.52076 7.56752 5.18863 7.56752 3.54536C7.56752 1.90208 6.23538 0.569946 4.59211
          0.569946C2.94884 0.569946 1.6167 1.90208 1.6167 3.54536C1.6167 5.18863 2.94884 6.52076 4.59211 6.52076Z'
          fill='#BF0000'
        />
      </svg>
    ),
    'pencil': (
      <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.42749 2.53983C8.38526 2.49029 8.32571 2.45962 8.26161 2.45441C8.1975
          2.44919 8.13395 2.46984 8.08458 2.51193L0.668173 10.5268C0.580167 10.6231 0.522087
          10.7439 0.501273 10.8739L0.000574679 13.8121C-0.00152877 13.8382 0.00215754 13.8644
          0.0113582 13.8888C0.0205588 13.9132 0.0350358 13.9351 0.053709 13.953C0.0723821 13.9709
          0.0947686 13.9842 0.119197 13.992C0.143625 13.9998 0.169465 14.0019 0.194785 13.9981L3.03815
          13.3255C3.16429 13.2973 3.27941 13.2315 3.36891 13.1365L10.7884 5.11847C10.8265 5.06596 10.8432
          5.0003 10.8347 4.93546C10.8261 4.87063 10.7932 4.81175 10.7428 4.77135L8.42749 2.53983Z'
          fill='#C4C4C4'
        />
        <path
          d='M13.0491 2.31044L10.7398 0.0789194C10.6903 0.0283864 10.6232 1.68086e-07 10.5532
          1.68086e-07C10.4832 1.68086e-07 10.4161 0.0283864 10.3665 0.0789194L8.90086 1.66268C8.84435
          1.72075 8.81262 1.7993 8.81262 1.88118C8.81262 1.96306 8.84435 2.04162 8.90086 2.09969L11.1586
          4.26922C11.2154 4.32695 11.2923 4.35935 11.3725 4.35935C11.4527 4.35935 11.5296 4.32695 11.5864
          4.26922L13.0521 2.68546C13.0994 2.63477 13.1254 2.56731 13.1249 2.49732C13.1243 2.42734 13.0971
          2.36033 13.0491 2.31044Z'
          fill='#C4C4C4'
        />
      </svg>
    ),
    'theodelite-big-icon': (
      <svg width='448' height='89' viewBox='0 0 448 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M328 86.4035L334.62 71.1012H337.689L344.429 86.4035H341.36L339.434 82.2517H334.439L335.402 79.7606H338.652L336.124
          74.0074L331.009 86.4035H328ZM348.461 71.1012V86.4035H351.35V71.1012H348.461ZM358.15 71.1012L352.253 77.4475V79.2861L358.572
          86.4628H362.544L354.78 78.2779L361.641 71.1012H358.15ZM381.2 71.1012L374.399 86.4035H371.33L364.71 71.1012H368.14L372.955
          82.8448L377.769 71.1012H381.2ZM400.518 71.1012V86.4035H409.605L410.628 83.9717H403.406V71.1012H400.518ZM416.345
          78.7523C416.345 82.311 417.97 84.0904 421.159 84.0904C424.289 84.0904 425.854 82.311 425.854 78.7523C425.854 75.253
          424.289 73.4736 421.159 73.4736C417.91 73.4736 416.345 75.1937 416.345 78.7523ZM413.336 78.8117C413.336 73.5923
          415.924 71.0419 421.159 71.0419C426.275 71.0419 428.863 73.6516 428.863 78.8117C428.863 83.9717 426.275 86.5814
          421.159 86.5814C416.164 86.5221 413.577 83.9717 413.336 78.8117ZM433.677 86.4035V71.1012H436.566L445.232
          82.0738V71.1012H448V86.4035H445.111L436.445 75.1937V86.4035H433.677Z'
          fill='white'
        />
        <path
          d='M395.523 84.6834C395.643 84.8614 395.763 85.0393 395.763 85.2765C395.763 85.5138 395.763 85.6917 395.643
          85.8697C395.523 86.1069 395.402 86.2848 395.161 86.3441C394.921 86.4628 394.74 86.4628 394.499 86.4628H380.237C380.056
          86.4035 379.876 86.3441 379.755 86.2848C379.635 86.1662 379.515 86.0476 379.394 85.929C379.334 85.751 379.274
          85.5138 379.274 85.2765C379.274 85.0393 379.394 84.8614 379.515 84.6834C379.755 84.3869 379.996 84.2682
          380.357 84.3276L394.078 84.2682C394.379 84.2682 394.62 84.2682 394.861 84.2682C395.161 84.3276 395.402 84.5055
          395.523 84.6834Z'
          fill='#D86A43'
        />
        <path
          d='M394.62 71.1012C394.8 71.1605 394.981 71.2198 395.161 71.2791C395.342 71.3384 395.462 71.4571 395.583
          71.635C395.643 71.7536 395.703 71.9316 395.763 72.1095C395.823 72.2874 395.763 72.4654 395.703 72.6433C395.643
          72.8212 395.523 72.9992 395.342 73.1178C395.161 73.2364 394.981 73.355 394.8 73.355L387.759 73.2957C387.579 73.2957
          387.398 73.2957 387.157 73.2957C386.977 73.2957 386.736 73.2957 386.556 73.2957C386.375 73.2957 386.195 73.2364 386.014
          73.1771C385.833 73.1178 385.713 72.9398 385.593 72.7619C385.533 72.6433 385.472 72.4654 385.472 72.2874C385.472 72.1095
          385.472 71.9316 385.533 71.8129C385.593 71.635 385.773 71.4571 385.894 71.3384C386.074 71.2198 386.255 71.1605 386.435
          71.1012H392.634H394.62Z'
          fill='#D86A43'
        />
        <path
          d='M394.74 77.7441C395.101 77.8034 395.402 77.922 395.583 78.2779C395.703 78.3965 395.763 78.5744 395.763
          78.7524C395.763 78.9303 395.763 79.1082 395.703 79.2268C395.643 79.4048 395.583 79.5234 395.402 79.642C395.282
          79.7607 395.101 79.8793 394.921 79.8793H394.499H383.426C383.246 79.82 383.005 79.7606 382.885 79.5827C382.704 79.4048
          382.584 79.2268 382.584 79.0489C382.524 78.871 382.524 78.6337 382.584 78.4558C382.644 78.2779 382.764 78.0999 382.945
          77.922C383.125 77.8034 383.306 77.6848 383.607 77.6848L394.74 77.7441Z'
          fill='#D86A43'
        />
        <path
          d='M309.281 80.3925C309.281 81.8456 308.947 83.0145 308.279 83.8993C307.611 84.7782 306.715 85.2177 305.59
          85.2177C304.389 85.2177 303.46 84.7928 302.804 83.9432L302.725 85.0419H301.23V71.5419H302.856V76.578C303.513
          75.7636 304.418 75.3563 305.572 75.3563C306.727 75.3563 307.632 75.7928 308.288 76.6659C308.95 77.5389 309.281
          78.7343 309.281 80.2518V80.3925ZM307.655 80.2079C307.655 79.1005 307.441 78.245 307.014 77.6415C306.586
          77.038 305.971 76.7362 305.168 76.7362C304.096 76.7362 303.325 77.2343 302.856 78.2303V82.3436C303.354 83.3397
          304.131 83.8378 305.186 83.8378C305.965 83.8378 306.571 83.536 307.005 82.9325C307.438 82.329 307.655 81.4208 307.655
          80.2079ZM314.344 82.66L316.559 75.5321H318.299L314.476 86.5096C313.884 88.0917 312.943 88.8827 311.654 88.8827L311.347
          88.8563L310.74 88.7421V87.4237L311.18 87.4589C311.73 87.4589 312.158 87.3475 312.463 87.1249C312.773 86.9022
          313.028 86.495 313.228 85.9032L313.588 84.9364L310.195 75.5321H311.971L314.344 82.66Z'
          fill='white'
        />
        <path d='M84.8402 55.3789H93.4007V24.5502H105.241V16.7049H73V24.5502H84.8402V55.3789Z' fill='white' />
        <path
          d='M111.825 55.3789H120.385V39.854H136.172V55.3789H144.733V16.7049H136.172V32.0087H120.385V16.7049H111.825V55.3789Z'
          fill='white'
        />
        <path
          d='M154.058 55.3789H183.687V47.8098H162.563V39.6883H180.907V32.1192H162.563V24.2739H183.409V16.7049H154.058V55.3789Z'
          fill='white'
        />
        <path
          d='M210.198 56.0419C222.205 56.0419 230.932 47.0363 230.932 36.0419C230.932 24.9369 222.316 16.0419 210.309 16.0419C198.302
          16.0419 189.575 25.0474 189.575 36.0419C189.575 47.1468 198.191 56.0419 210.198 56.0419ZM210.309 48.1966C203.416 48.1966
          198.524 42.6717 198.524 36.0419C198.524 29.3015 203.305 23.8872 210.198 23.8872C217.09 23.8872 221.982 29.412 221.982
          36.0419C221.982 42.7822 217.202 48.1966 210.309 48.1966Z'
          fill='white'
        />
        <path
          d='M238.526 55.3789H253.701C265.93 55.3789 274.38 46.9258 274.38 36.0419C274.38 25.0474 265.93 16.7049 253.701
          16.7049H238.526V55.3789ZM247.086 47.6993V24.3844H253.701C260.705 24.3844 265.43 29.191 265.43 36.0419C265.43
          43.0032 260.705 47.6993 253.701 47.6993H247.086Z'
          fill='white'
        />
        <path
          d='M300.908 56.0419C312.915 56.0419 321.642 47.0363 321.642 36.0419C321.642 24.9369 313.026 16.0419 301.019
          16.0419C289.012 16.0419 280.285 25.0474 280.285 36.0419C280.285 47.1468 288.901 56.0419 300.908 56.0419ZM301.019
          48.1966C294.126 48.1966 289.234 42.6717 289.234 36.0419C289.234 29.3015 294.015 23.8872 300.908 23.8872C307.801 23.8872
          312.692 29.412 312.692 36.0419C312.692 42.7822 307.912 48.1966 301.019 48.1966Z'
          fill='white'
        />
        <path d='M329.236 55.3789H357.197V47.6441H337.796V16.7049H329.236V55.3789Z' fill='white' />
        <path d='M364.042 55.3789H372.602V16.7049H364.042V55.3789Z' fill='white' />
        <path d='M391.387 55.3789H399.948V24.5502H411.788V16.7049H379.547V24.5502H391.387V55.3789Z' fill='white' />
        <path
          d='M418.372 55.3789H448V47.8098H426.877V39.6883H445.221V32.1192H426.877V24.2739H447.722V16.7049H418.372V55.3789Z'
          fill='white'
        />
        <line
          x1='3.5'
          y1='-3.5'
          x2='21.1997'
          y2='-3.5'
          transform='matrix(-0.546565 0.837416 -0.940835 -0.338864 12.5 62.0312)'
          stroke='white'
          strokeWidth='7'
          strokeLinecap='round'
        />
        <line x1='29.5' y1='66.5' x2='29.5' y2='80.5' stroke='white' strokeWidth='7' strokeLinecap='round' />
        <line
          x1='3.5'
          y1='-3.5'
          x2='20.2164'
          y2='-3.5'
          transform='matrix(0.533649 0.845706 -0.944383 0.328848 40.3438 63.2848)'
          stroke='white'
          strokeWidth='7'
          strokeLinecap='round'
        />
        <path
          d='M39.756 31.6976C39.756 37.3814 35.1483 41.9891 29.4645 41.9891C23.7807 41.9891 19.1731 37.3814 19.1731 31.6976C19.1731
          26.0138 23.7807 21.4062 29.4645 21.4062C35.1483 21.4062 39.756 26.0138 39.756 31.6976Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6 61.337C6 59.2908 7.5638 57.6321 9.49285 57.6321L48.6128 57.6321C50.5418 57.6321 52.1056 59.2908 52.1056
          61.337C52.1056 63.3832 50.5418 65.0419 48.6128 65.0419L9.49285 65.0419C7.5638 65.0419 6 63.3832 6 61.337Z'
          fill='#F26939'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.8799 3.70492C15.8799 1.65875 17.4319 -1.35684e-07 19.3465 -3.03059e-07L38.7594 -2.00019e-06C40.6739
          -2.16756e-06 42.226 1.65875 42.226 3.70492C42.226 5.75109 40.6739 7.40984 38.7594 7.40984L19.3465 7.40984C17.4319
          7.40984 15.8799 5.75109 15.8799 3.70492Z'
          fill='#F26939'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.64673 13.3988C7.64673 11.4553 9.19252 9.87976 11.0993 9.87976H47.0065C48.9133 9.87976 50.4591 11.4553 50.4591
          13.3988V49.9965C50.4591 51.9399 48.9133 53.5155 47.0065 53.5155H11.0993C9.19252 53.5155 7.64673 51.9399 7.64673
          49.9965V13.3988ZM14.552 16.9178V46.4774H43.5539V16.9178H14.552Z'
          fill='#F26939'
        />
      </svg>
    ),
    'bell_with_notification': (
      <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8 22C9.1 22 10 21.1 10 20H6C6 21.1 6.9 22 8 22ZM14 16V11C14 7.93 12.37 5.36 9.5 4.68V4C9.5 3.17 8.83 2.5 8
          2.5C7.17 2.5 6.5 3.17 6.5 4V4.68C3.64 5.36 2 7.92 2 11V16L0 18V19H16V18L14 16ZM12 17H4V11C4 8.52 5.51 6.5 8 6.5C10.49
          6.5 12 8.52 12 11V17Z'
          fill='#A5A5A5'
        />
        <circle cx='19' cy='3' r='3' fill='#EB5757' />
      </svg>
    ),
    'bell_without_notification': (
      <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8 22C9.1 22 10 21.1 10 20H6C6 21.1 6.9 22 8 22ZM14 16V11C14 7.93 12.37 5.36 9.5 4.68V4C9.5 3.17 8.83 2.5 8
          2.5C7.17 2.5 6.5 3.17 6.5 4V4.68C3.64 5.36 2 7.92 2 11V16L0 18V19H16V18L14 16ZM12 17H4V11C4 8.52 5.51 6.5 8 6.5C10.49
          6.5 12 8.52 12 11V17Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'Employee-Created': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#27AE60' />
        <path
          d='M9.99992 12.1667C8.43992 12.1667 5.33325 12.9467 5.33325 14.5V15.6667H14.6666V14.5C14.6666 12.9467 11.5599
          12.1667 9.99992 12.1667ZM6.89325 14.3333C7.45325 13.9467 8.80659 13.5 9.99992 13.5C11.1933 13.5 12.5466 13.9467
          13.1066 14.3333H6.89325ZM9.99992 11C11.2866 11 12.3333 9.95334 12.3333 8.66668C12.3333 7.38001 11.2866 6.33334
          9.99992 6.33334C8.71325 6.33334 7.66659 7.38001 7.66659 8.66668C7.66659 9.95334 8.71325 11 9.99992 11ZM9.99992
          7.66668C10.5533 7.66668 10.9999 8.11334 10.9999 8.66668C10.9999 9.22001 10.5533 9.66668 9.99992 9.66668C9.44659 9.66668
          8.99992 9.22001 8.99992 8.66668C8.99992 8.11334 9.44659 7.66668 9.99992 7.66668ZM14.6933 12.2067C15.4666 12.7667 15.9999
          13.5133 15.9999 14.5V15.6667H18.6666V14.5C18.6666 13.1533 16.3333 12.3867 14.6933 12.2067ZM13.9999 11C15.2866 11 16.3333
          9.95334 16.3333 8.66668C16.3333 7.38001 15.2866 6.33334 13.9999 6.33334C13.6399 6.33334 13.3066 6.42001 12.9999 6.56668C13.4199
          7.16001 13.6666 7.88668 13.6666 8.66668C13.6666 9.44668 13.4199 10.1733 12.9999 10.7667C13.3066 10.9133 13.6399 11 13.9999 11Z'
          fill='white'
        />
      </svg>
    ),
    'Employee-error': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#EB5757' />
        <path
          d='M9.99992 12.1667C8.43992 12.1667 5.33325 12.9467 5.33325 14.5V15.6667H14.6666V14.5C14.6666 12.9467 11.5599 12.1667
          9.99992 12.1667ZM6.89325 14.3333C7.45325 13.9467 8.80659 13.5 9.99992 13.5C11.1933 13.5 12.5466 13.9467 13.1066
          14.3333H6.89325ZM9.99992 11C11.2866 11 12.3333 9.95334 12.3333 8.66668C12.3333 7.38001 11.2866 6.33334 9.99992
          6.33334C8.71325 6.33334 7.66659 7.38001 7.66659 8.66668C7.66659 9.95334 8.71325 11 9.99992 11ZM9.99992 7.66668C10.5533
          7.66668 10.9999 8.11334 10.9999 8.66668C10.9999 9.22001 10.5533 9.66668 9.99992 9.66668C9.44659 9.66668 8.99992 9.22001
          8.99992 8.66668C8.99992 8.11334 9.44659 7.66668 9.99992 7.66668ZM14.6933 12.2067C15.4666 12.7667 15.9999 13.5133 15.9999
          14.5V15.6667H18.6666V14.5C18.6666 13.1533 16.3333 12.3867 14.6933 12.2067ZM13.9999 11C15.2866 11 16.3333 9.95334 16.3333
          8.66668C16.3333 7.38001 15.2866 6.33334 13.9999 6.33334C13.6399 6.33334 13.3066 6.42001 12.9999 6.56668C13.4199 7.16001
          13.6666 7.88668 13.6666 8.66668C13.6666 9.44668 13.4199 10.1733 12.9999 10.7667C13.3066 10.9133 13.6399 11 13.9999 11Z'
          fill='white'
        />
      </svg>
    ),
    'Project-Created': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#27AE60' />
        <path
          d='M17.3333 7.99999H11.9999L10.6666 6.66666H6.66659C5.93325 6.66666 5.33992 7.26666 5.33992 7.99999L5.33325 16C5.33325
          16.7333 5.93325 17.3333 6.66659 17.3333H17.3333C18.0666 17.3333 18.6666 16.7333 18.6666 16V9.33332C18.6666 8.59999 18.0666
          7.99999 17.3333 7.99999ZM17.3333 16H6.66659V9.33332H17.3333V16Z'
          fill='white'
        />
      </svg>
    ),
    'Project-error': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#EB5757' />
        <path
          d='M17.3333 7.99999H11.9999L10.6666 6.66666H6.66659C5.93325 6.66666 5.33992 7.26666 5.33992 7.99999L5.33325 16C5.33325
          16.7333 5.93325 17.3333 6.66659 17.3333H17.3333C18.0666 17.3333 18.6666 16.7333 18.6666 16V9.33332C18.6666 8.59999 18.0666
          7.99999 17.3333 7.99999ZM17.3333 16H6.66659V9.33332H17.3333V16Z'
          fill='white'
        />
      </svg>
    ),
    'Contract-Created': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#27AE60' />
        <path
          d='M8.66667 14H13.3333V15.3333H8.66667V14ZM8.66667 11.3333H15.3333V12.6667H8.66667V11.3333ZM8.66667
          8.66666H15.3333V9.99999H8.66667V8.66666ZM16.6667 5.99999H13.88C13.6 5.22666 12.8667 4.66666 12 4.66666C11.1333 4.66666
          10.4 5.22666 10.12 5.99999H7.33333C7.24 5.99999 7.15333 6.00666 7.06667 6.02666C6.80667 6.07999 6.57333 6.21332 6.39333
          6.39332C6.27333 6.51332 6.17333 6.65999 6.10667 6.81999C6.04 6.97332 6 7.14666 6 7.33332V16.6667C6 16.8467 6.04 17.0267
          6.10667 17.1867C6.17333 17.3467 6.27333 17.4867 6.39333 17.6133C6.57333 17.7933 6.80667 17.9267 7.06667 17.98C7.15333
          17.9933 7.24 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.33332C18 6.59999 17.4 5.99999 16.6667 5.99999ZM12
          5.83332C12.2733 5.83332 12.5 6.05999 12.5 6.33332C12.5 6.60666 12.2733 6.83332 12 6.83332C11.7267 6.83332 11.5 6.60666
          11.5 6.33332C11.5 6.05999 11.7267 5.83332 12 5.83332ZM16.6667 16.6667H7.33333V7.33332H16.6667V16.6667Z'
          fill='white'
        />
      </svg>
    ),
    'Contract-error': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#EB5757' />
        <path
          d='M8.66667 14H13.3333V15.3333H8.66667V14ZM8.66667 11.3333H15.3333V12.6667H8.66667V11.3333ZM8.66667
          8.66666H15.3333V9.99999H8.66667V8.66666ZM16.6667 5.99999H13.88C13.6 5.22666 12.8667 4.66666 12 4.66666C11.1333 4.66666
          10.4 5.22666 10.12 5.99999H7.33333C7.24 5.99999 7.15333 6.00666 7.06667 6.02666C6.80667 6.07999 6.57333 6.21332 6.39333
          6.39332C6.27333 6.51332 6.17333 6.65999 6.10667 6.81999C6.04 6.97332 6 7.14666 6 7.33332V16.6667C6 16.8467 6.04 17.0267
          6.10667 17.1867C6.17333 17.3467 6.27333 17.4867 6.39333 17.6133C6.57333 17.7933 6.80667 17.9267 7.06667 17.98C7.15333
          17.9933 7.24 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.33332C18 6.59999 17.4 5.99999 16.6667 5.99999ZM12
          5.83332C12.2733 5.83332 12.5 6.05999 12.5 6.33332C12.5 6.60666 12.2733 6.83332 12 6.83332C11.7267 6.83332 11.5 6.60666
          11.5 6.33332C11.5 6.05999 11.7267 5.83332 12 5.83332ZM16.6667 16.6667H7.33333V7.33332H16.6667V16.6667Z'
          fill='white'
        />
      </svg>
    ),
    'Document-Created': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#27AE60' />
        <path
          d='M8.66667 14H13.3333V15.3333H8.66667V14ZM8.66667 11.3333H15.3333V12.6667H8.66667V11.3333ZM8.66667
          8.66666H15.3333V9.99999H8.66667V8.66666ZM16.6667 5.99999H13.88C13.6 5.22666 12.8667 4.66666 12 4.66666C11.1333 4.66666
          10.4 5.22666 10.12 5.99999H7.33333C7.24 5.99999 7.15333 6.00666 7.06667 6.02666C6.80667 6.07999 6.57333 6.21332 6.39333
          6.39332C6.27333 6.51332 6.17333 6.65999 6.10667 6.81999C6.04 6.97332 6 7.14666 6 7.33332V16.6667C6 16.8467 6.04 17.0267
          6.10667 17.1867C6.17333 17.3467 6.27333 17.4867 6.39333 17.6133C6.57333 17.7933 6.80667 17.9267 7.06667 17.98C7.15333
          17.9933 7.24 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.33332C18 6.59999 17.4 5.99999 16.6667 5.99999ZM12
          5.83332C12.2733 5.83332 12.5 6.05999 12.5 6.33332C12.5 6.60666 12.2733 6.83332 12 6.83332C11.7267 6.83332 11.5 6.60666
          11.5 6.33332C11.5 6.05999 11.7267 5.83332 12 5.83332ZM16.6667 16.6667H7.33333V7.33332H16.6667V16.6667Z'
          fill='white'
        />
      </svg>
    ),
    'Document-error': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#EB5757' />
        <path
          d='M8.66667 14H13.3333V15.3333H8.66667V14ZM8.66667 11.3333H15.3333V12.6667H8.66667V11.3333ZM8.66667
          8.66666H15.3333V9.99999H8.66667V8.66666ZM16.6667 5.99999H13.88C13.6 5.22666 12.8667 4.66666 12 4.66666C11.1333 4.66666
          10.4 5.22666 10.12 5.99999H7.33333C7.24 5.99999 7.15333 6.00666 7.06667 6.02666C6.80667 6.07999 6.57333 6.21332 6.39333
          6.39332C6.27333 6.51332 6.17333 6.65999 6.10667 6.81999C6.04 6.97332 6 7.14666 6 7.33332V16.6667C6 16.8467 6.04 17.0267
          6.10667 17.1867C6.17333 17.3467 6.27333 17.4867 6.39333 17.6133C6.57333 17.7933 6.80667 17.9267 7.06667 17.98C7.15333
          17.9933 7.24 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.33332C18 6.59999 17.4 5.99999 16.6667 5.99999ZM12
          5.83332C12.2733 5.83332 12.5 6.05999 12.5 6.33332C12.5 6.60666 12.2733 6.83332 12 6.83332C11.7267 6.83332 11.5 6.60666
          11.5 6.33332C11.5 6.05999 11.7267 5.83332 12 5.83332ZM16.6667 16.6667H7.33333V7.33332H16.6667V16.6667Z'
          fill='white'
        />
      </svg>
    ),
    'cross-white': (
      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          opacity='0.54'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 1.2L10.8 0L6 4.8L1.2 0L0 1.2L4.8 6L0 10.8L1.2 12L6 7.2L10.8 12L12 10.8L7.2 6L12 1.2Z'
          fill='white'
        />
      </svg>
    ),
    'search-magnifier': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5
          3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14
          14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99
          5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z'
          fill='#dfdfdf'
        />
      </svg>
    ),
    'delete-searchbox': (<svg
      className='clear-search'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19
        17.59L13.41 12L19 6.41Z'
        fill='#dfdfdf'
      />
    </svg>
    ),
    'filters-settings-off': (
      <svg className='choosed-filters' width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='3' cy='3' r='3' fill='#EB5757' />
      </svg>
    ),
    'filters-settings-on': (
      <svg className='choosed-filters' width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='3' cy='3' r='3' fill='transparent' />
      </svg>
    ),
    'configuration-icon-off': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z' fill='#f26939' />
      </svg>
    ),
    'configuration-icon-on': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z' fill='#A5A5A5' />
      </svg>
    ),
    'multi-select-svg': (
      <svg width='12' height='5' viewBox='0 0 12 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 0L5.97518 5L11.9504 0H0Z' fill='#4B4B4B' />
      </svg>
    ),
    'loader': (
      <svg width='174' height='151' viewBox='0 0 174 151' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5.49385 150.648H9.46593V135.233H14.9598V131.311H0V135.233H5.49385V150.648Z' fill='#828282' />
        <path d='M18.0146 150.648H21.9867V142.885H29.3118V150.648H33.2839V131.311H29.3118V138.963H21.9867V131.311H18.0146V150.648Z' fill='#828282' />
        <path d='M37.611 150.648H51.3586V146.863H41.5573V142.802H50.0689V139.018H41.5573V135.095H51.2296V131.311H37.611V150.648Z' fill='#828282' />
        <path
          d='M63.6597 150.979C69.2309 150.979 73.2804 146.477 73.2804 140.979C73.2804 135.427 69.2825 130.979 63.7113 130.979C58.14
          130.979 54.0906 135.482 54.0906 140.979C54.0906 146.532 58.0885 150.979 63.6597 150.979ZM63.7113 147.057C60.513 147.057
          58.2432 144.294 58.2432 140.979C58.2432 137.609 60.4614 134.902 63.6597 134.902C66.858 134.902 69.1277 137.664 69.1277
          140.979C69.1277 144.349 66.9096 147.057 63.7113 147.057Z'
          fill='#828282'
        />
        <path
          d='M76.8039 150.648H83.8453C89.5197 150.648 93.4402 146.421 93.4402 140.979C93.4402 135.482 89.5197 131.311 83.8453
          131.311H76.8039V150.648ZM80.776 146.808V135.151H83.8453C87.0952 135.151 89.2876 137.554 89.2876 140.979C89.2876 144.46
          87.0952 146.808 83.8453 146.808H80.776Z'
          fill='#828282'
        />
        <path
          d='M105.749 150.979C111.32 150.979 115.37 146.477 115.37 140.979C115.37 135.427 111.372 130.979 105.801 130.979C100.23
          130.979 96.1801 135.482 96.1801 140.979C96.1801 146.532 100.178 150.979 105.749 150.979ZM105.801 147.057C102.602 147.057
          100.333 144.294 100.333 140.979C100.333 137.609 102.551 134.902 105.749 134.902C108.947 134.902 111.217 137.664 111.217
          140.979C111.217 144.349 108.999 147.057 105.801 147.057Z'
          fill='#828282'
        />
        <path d='M118.893 150.648H131.867V146.78H122.866V131.311H118.893V150.648Z' fill='#828282' />
        <path d='M135.043 150.648H139.015V131.311H135.043V150.648Z' fill='#828282' />
        <path d='M147.732 150.648H151.704V135.233H157.198V131.311H142.238V135.233H147.732V150.648Z' fill='#828282' />
        <path d='M160.252 150.648H174V146.863H164.199V142.802H172.71V139.018H164.199V135.095H173.871V131.311H160.252V150.648Z' fill='#828282' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.5127 87.7895C67.0232 88.6937 67.8761 91.2381 66.4176 93.4727L53.0634 113.933C51.6049 116.168 48.3875 117.246
          45.877 116.342C43.3665 115.438 42.5137 112.893 43.9721 110.659L57.3264 90.1982C58.7848 87.9637 62.0022 86.8853 64.5127
          87.7895Z'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M83.4345 87.4895C86.1029 87.4895 88.266 89.6527 88.266 92.321L88.266 111.647C88.266 114.315 86.1029 116.478 83.4345
          116.478C80.7662 116.478 78.603 114.315 78.603 111.647L78.603 92.321C78.603 89.6527 80.7662 87.4895 83.4345 87.4895Z'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M102.966 86.2939C105.486 85.4164 108.683 86.5344 110.107 88.791L122.422 108.306C123.846 110.563 122.957 113.104 120.437
          113.981C117.917 114.859 114.72 113.741 113.296 111.484L100.982 91.9687C99.5577 89.712 100.446 87.1713 102.966 86.2939Z'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M50.9946 85.1939C50.9946 82.3693 53.1533 80.0796 55.8162 80.0796L109.818 80.0796C112.481 80.0796 114.64 82.3693 114.64
          85.1939C114.64 88.0185 112.481 90.3083 109.818 90.3083L55.8162 90.3083C53.1533 90.3083 50.9946 88.0185 50.9946 85.1939Z'
          fill='#F26939'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.6331 5.63727C64.6331 2.81269 66.7755 0.522919 69.4184 0.522918L96.2164 0.522916C98.8593 0.522916 101.002 2.81269
          101.002 5.63727C101.002 8.46185 98.8593 10.7516 96.2164 10.7516L69.4184 10.7516C66.7755 10.7516 64.6331 8.46185 64.6331
          5.63727Z'
          fill='#F26939'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M53.2678 19.0189C53.2678 16.3361 55.4017 14.1612 58.0339 14.1612H107.601C110.233 14.1612 112.367 16.3361 112.367
          19.0189V69.5392C112.367 72.222 110.233 74.3969 107.601 74.3969H58.0339C55.4017 74.3969 53.2678 72.222 53.2678
          69.5392V19.0189ZM62.8 23.8766V64.6815H102.835V23.8766H62.8Z'
          fill='#F26939'
        />
        <path
          d='M96.9914 39.5925C95.6922 36.2309 93.2261 33.4494 90.0442 31.7569C86.8624 30.0645 83.1776 29.5741 79.6639 30.3757C76.1502
          31.1773 73.0425 33.2171 70.9094 36.122C68.7764 39.027 67.7605 42.6028 68.0477 46.1953C68.3349 49.7878 69.9059 53.1568
          72.4734 55.686C75.0408 58.2151 78.4331 59.7354 82.0295 59.9686C85.6259 60.2018 89.186 59.1323 92.0586 56.9558C94.9312
          54.7794 96.9241 51.6414 97.6728 48.1161L93.1787 47.1617C92.6593 49.6073 91.2768 51.7841 89.2841 53.2939C87.2913 54.8038
          84.8216 55.5457 82.3267 55.3839C79.8318 55.2222 77.4786 54.1675 75.6975 52.413C73.9164 50.6585 72.8266 48.3214 72.6274
          45.8292C72.4281 43.337 73.1329 40.8564 74.6126 38.8412C76.0923 36.826 78.2482 35.411 80.6857 34.8549C83.1232 34.2989
          85.6794 34.639 87.8867 35.8131C90.094 36.9872 91.8048 38.9167 92.706 41.2488L96.9914 39.5925Z'
          fill='url(#paint0_angular)'
        />
      </svg>
    ),
    'list-item-comment': (
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H4L2 16V2H18V14Z' fill='#A5A5A5' />
      </svg>
    ),
    'clear-member-skill': (
      <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          opacity='0.54'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z'
          fill='black'
        />
      </svg>
    ),
    'confirmation-dialog-cross': (
      <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          opacity='0.54'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z'
          fill='black'
        />
      </svg>
    ),
    'confirmation-dialog-question': (
      <svg
        width='42'
        height='42'
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20.9998 0.166504C9.49984 0.166504 0.166504 9.49984 0.166504 20.9998C0.166504 32.4998 9.49984 41.8332 20.9998
          41.8332C32.4998 41.8332 41.8332 32.4998 41.8332 20.9998C41.8332 9.49984 32.4998 0.166504 20.9998 0.166504ZM23.0832
          35.5832H18.9165V31.4165H23.0832V35.5832ZM27.3957 19.4373L25.5207 21.354C24.0207 22.8748 23.0832 24.1248 23.0832
          27.2498H18.9165V26.2082C18.9165 23.9165 19.854 21.8332 21.354 20.3123L23.9373 17.6873C24.7082 16.9373 25.1665
          15.8957 25.1665 14.7498C25.1665 12.4582 23.2915 10.5832 20.9998 10.5832C18.7082 10.5832 16.8332 12.4582 16.8332
          14.7498H12.6665C12.6665 10.1457 16.3957 6.4165 20.9998 6.4165C25.604 6.4165 29.3332 10.1457 29.3332 14.7498C29.3332
          16.5832 28.5832 18.2498 27.3957 19.4373Z'
          fill='#D7D7D7'
        />
      </svg>
    ),
    'promote-backup': (
      <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.651 7.59884C11.5638 7.51539 11.461 7.44997 11.3485 7.40634C11.1253 7.31466 10.875 7.31466 10.6518 7.40634C10.5393
          7.44997 10.4365 7.51539 10.3493 7.59884L7.59933 10.3488C7.42672 10.5215 7.32975 10.7556 7.32975 10.9997C7.32975 11.2438 7.42672
          11.4779 7.59933 11.6505C7.77194 11.8231 8.00606 11.9201 8.25017 11.9201C8.49428 11.9201 8.72839 11.8231 8.901 11.6505L10.0835
          10.4588V13.7497C10.0835 13.9928 10.1801 14.2259 10.352 14.3979C10.5239 14.5698 10.7571 14.6663 11.0002 14.6663C11.2433 14.6663
          11.4764 14.5698 11.6483 14.3979C11.8203 14.2259 11.9168 13.9928 11.9168 13.7497V10.4588L13.0993 11.6505C13.1846 11.7364 13.2859
          11.8046 13.3976 11.8512C13.5093 11.8977 13.6292 11.9217 13.7502 11.9217C13.8712 11.9217 13.991 11.8977 14.1027 11.8512C14.2144
          11.8046 14.3158 11.7364 14.401 11.6505C14.4869 11.5653 14.5551 11.4639 14.6017 11.3522C14.6482 11.2405 14.6721 11.1207 14.6721
          10.9997C14.6721 10.8787 14.6482 10.7589 14.6017 10.6471C14.5551 10.5354 14.4869 10.4341 14.401 10.3488L11.651 7.59884ZM11.0002
          1.83301C9.18717 1.83301 7.41489 2.37062 5.90744 3.37787C4.39999 4.38512 3.22507 5.81675 2.53127 7.49174C1.83747 9.16673 1.65594
          11.0098 2.00964 12.788C2.36333 14.5662 3.23637 16.1995 4.51835 17.4815C5.80034 18.7635 7.43368 19.6365 9.21184 19.9902C10.99
          20.3439 12.8331 20.1624 14.5081 19.4686C16.1831 18.7748 17.6147 17.5999 18.622 16.0924C19.6292 14.585 20.1668 12.8127 20.1668
          10.9997C20.1668 9.79589 19.9297 8.60389 19.4691 7.49174C19.0084 6.37959 18.3332 5.36907 17.482 4.51786C16.6308 3.66666 15.6203
          2.99145 14.5081 2.53078C13.3959 2.07011 12.204 1.83301 11.0002 1.83301ZM11.0002 18.333C9.54977 18.333 8.13195 17.9029 6.92598
          17.0971C5.72002 16.2913 4.78009 15.146 4.22505 13.806C3.67001 12.466 3.52478 10.9915 3.80774 9.56901C4.0907 8.14648 4.78913
          6.83981 5.81472 5.81422C6.8403 4.78864 8.14698 4.09021 9.56951 3.80725C10.992 3.52429 12.4665 3.66952 13.8065 4.22456C15.1465
          4.7796 16.2918 5.71953 17.0976 6.92549C17.9034 8.13145 18.3335 9.54928 18.3335 10.9997C18.3335 12.9446 17.5609 14.8099 16.1856
          16.1851C14.8104 17.5604 12.9451 18.333 11.0002 18.333Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'add-backup': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.7098 21.7101L20.7098 17.7101C20.8031 17.6169 20.877 17.5062 20.9275 17.3844C20.9779 17.2626 21.0039 17.132 21.0039
          17.0001C21.0039 16.7338 20.8981 16.4784 20.7098 16.2901C20.5215 16.1018 20.2661 15.996 19.9998 15.996C19.7335 15.996 19.4781
          16.1018 19.2898 16.2901L16.9998 18.5901L16.9998 7.00014C16.9998 6.73492 16.8945 6.48057 16.7069 6.29303C16.5194 6.1055 16.265
          6.00014 15.9998 6.00014C15.7346 6.00014 15.4802 6.1055 15.2927 6.29303C15.1052 6.48057 14.9998 6.73492 14.9998 7.00014L14.9998
          21.0001C15.0008 21.1976 15.0602 21.3903 15.1705 21.554C15.2809 21.7177 15.4372 21.8451 15.6198 21.9201C15.8019 21.9967 16.0026
          22.0176 16.1966 21.9803C16.3906 21.9429 16.5692 21.8489 16.7098 21.7101ZM12.9998 17.0001L12.9998 3.00014C12.9988 2.80271 12.9394
          2.61 12.8291 2.44628C12.7188 2.28256 12.5624 2.15516 12.3798 2.08014C12.1977 2.00356 11.997 1.98264 11.803 2.02001C11.609 2.05739
          11.4304 2.15138 11.2898 2.29014L7.28982 6.29014C7.19609 6.3831 7.12169 6.4937 7.07092 6.61556C7.02015 6.73742 6.99402 6.86813 6.99402
          7.00014C6.99402 7.13215 7.02015 7.26286 7.07092 7.38472C7.12169 7.50657 7.19609 7.61718 7.28982 7.71014C7.38278 7.80387 7.49338 7.87826
          7.61524 7.92903C7.7371 7.9798 7.8678 8.00594 7.99981 8.00594C8.13183 8.00594 8.26253 7.9798 8.38439 7.92903C8.50625 7.87826 8.61685
          7.80387 8.70981 7.71014L10.9998 5.41014L10.9998 17.0001C10.9998 17.2654 11.1052 17.5197 11.2927 17.7072C11.4802 17.8948 11.7346
          18.0001 11.9998 18.0001C12.265 18.0001 12.5194 17.8948 12.7069 17.7072C12.8945 17.5197 12.9998 17.2654 12.9998 17.0001Z'
          fill='#979797'
        />
        <path
          d='M9 13C9 13.3156 8.74416 13.5714 8.42857 13.5714H5.57143V16.4286C5.57143 16.7442 5.31559 17 5 17C4.68441 17 4.42857 16.7442 4.42857
          16.4286V13.5714H1.57143C1.25584 13.5714 1 13.3156 1 13C1 12.6844 1.25584 12.4286 1.57143 12.4286H4.42857V9.57143C4.42857 9.25584 4.68441 9 5
          9C5.31559 9 5.57143 9.25584 5.57143 9.57143V12.4286H8.42857C8.74416 12.4286 9 12.6844 9 13Z'
          fill='#979797'
        />
      </svg>
    ),
    'backup': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.7098 21.7101L18.7098 17.7101C18.8031 17.6169 18.877 17.5062 18.9275 17.3844C18.9779 17.2626 19.0039 17.132 19.0039
          17.0001C19.0039 16.7338 18.8981 16.4784 18.7098 16.2901C18.5215 16.1018 18.2661 15.996 17.9998 15.996C17.7335 15.996 17.4781
          16.1018 17.2898 16.2901L14.9998 18.5901L14.9998 7.00014C14.9998 6.73492 14.8945 6.48057 14.7069 6.29303C14.5194 6.1055 14.265
          6.00014 13.9998 6.00014C13.7346 6.00014 13.4802 6.1055 13.2927 6.29303C13.1052 6.48057 12.9998 6.73492 12.9998 7.00014L12.9998
          21.0001C13.0008 21.1976 13.0602 21.3903 13.1705 21.554C13.2809 21.7177 13.4372 21.8451 13.6198 21.9201C13.8019 21.9967 14.0026
          22.0176 14.1966 21.9803C14.3906 21.9429 14.5692 21.8489 14.7098 21.7101ZM10.9998 17.0001L10.9998 3.00014C10.9988 2.80271 10.9394
          2.61 10.8291 2.44628C10.7188 2.28256 10.5624 2.15516 10.3798 2.08014C10.1977 2.00356 9.99699 1.98264 9.803 2.02001C9.60901 2.05739
          9.43044 2.15138 9.28981 2.29014L5.28982 6.29014C5.19609 6.3831 5.12169 6.4937 5.07092 6.61556C5.02015 6.73742 4.99402 6.86813 4.99402
          7.00014C4.99402 7.13215 5.02015 7.26286 5.07092 7.38472C5.12169 7.50657 5.19609 7.61718 5.28982 7.71014C5.38278 7.80387 5.49338 7.87826
          5.61524 7.92903C5.7371 7.9798 5.8678 8.00594 5.99981 8.00594C6.13183 8.00594 6.26253 7.9798 6.38439 7.92903C6.50625 7.87826 6.61685
          7.80387 6.70981 7.71014L8.99981 5.41014L8.99981 17.0001C8.99981 17.2654 9.10517 17.5197 9.29271 17.7072C9.48024 17.8948 9.7346 18.0001
          9.99981 18.0001C10.265 18.0001 10.5194 17.8948 10.7069 17.7072C10.8945 17.5197 10.9998 17.2654 10.9998 17.0001Z'
          fill='#979797'
        />
      </svg>
    ),
    'admin-lookups': (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM20 5V8H5V5H20ZM15
          19H10V10H15V19ZM5 10H8V19H5V10ZM17 19V10H20V19H17Z'
          fill='white'
        />
      </svg>
    ),
    'silver-sidebar-menu': (
      <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z' fill='silver' />
      </svg>
    ),
    'files': (
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.00004 1.66675H11.6667L16.6667 6.66675V16.6667C16.6667 17.5834 15.9167
          18.3334 15 18.3334H4.99171C4.07504 18.3334 3.33337 17.5834 3.33337 16.6667L3.34171 3.33341C3.34171 2.41675 4.08337 1.66675
          5.00004 1.66675ZM5.00004 3.33341V16.6667H15V7.50008H10.8334V3.33341H5.00004ZM10.9208 10.3991L9.99996 8.33325L9.0791
          10.3991L6.82977 10.6365L8.50997 12.1507L8.04068 14.3633L9.99996 13.2333L11.9592 14.3633L11.4899 12.1507L13.1701
          10.6365L10.9208 10.3991Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'big-files': (
      <svg width='25' height='25' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.00004 1.66675H11.6667L16.6667 6.66675V16.6667C16.6667 17.5834 15.9167
          18.3334 15 18.3334H4.99171C4.07504 18.3334 3.33337 17.5834 3.33337 16.6667L3.34171 3.33341C3.34171 2.41675 4.08337 1.66675
          5.00004 1.66675ZM5.00004 3.33341V16.6667H15V7.50008H10.8334V3.33341H5.00004ZM10.9208 10.3991L9.99996 8.33325L9.0791
          10.3991L6.82977 10.6365L8.50997 12.1507L8.04068 14.3633L9.99996 13.2333L11.9592 14.3633L11.4899 12.1507L13.1701
          10.6365L10.9208 10.3991Z'
          fill='#A5A5A5'
        />
      </svg>
    ),
    'copy': (
      <FileCopyIcon fontSize='small' />
    ),
    're-calculate': (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='21'
        viewBox='0 0 24 24'
        fill='black'
      >
        <path
          d='M2 0v24h20v-24h-20zm5 22h-3v-3h3v3zm0-4h-3v-3h3v3zm0-4h-3v-3h3v3zm4
          8h-3v-3h3v3zm0-4h-3v-3h3v3zm0-4h-3v-3h3v3zm4 8h-3v-3h3v3zm0-4h-3v-3h3v3zm0-4h-3v-3h3v3zm5
          8h-3v-7h3v7zm0-8h-3v-3h3v3zm0-6h-16v-6h16v6zm-1-1h-14v-4h14v4z'
        />
      </svg>
    ),
    'override': (
      <BorderColorIcon fontSize='small' />
    ),
  },
});

class CustomIcon extends Component {
  render() {
    const {
      iconName, style, className, onClick, onMouseLeave, onMouseEnter, id, name, title,
    } = this.props;
    return (
      <Icon
        id={id}
        iconName={iconName}
        style={style}
        className={mergeStyles(className, { display: 'flex' })}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        name={name}
        title={title}
      />
    );
  }
}

CustomIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default CustomIcon;
