import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { GET_CERTIFICATIONS_REPORTS, setCertificationsCounter, setCertificationsReports } from '../actions/certificationsActions';
import { finishRequest, startRequest } from '../../../common/actions/loadingActions';
import http from '../../../services/http';

function* getCertificationsReports(action) {
  try {
    yield put(startRequest());
    const {
      filters, take, skip, searchValue, sortSettings,
    } = action.payload;
    const orderByDesc = !(sortSettings && sortSettings.sortDirection === 'Up');
    const certificationsReports = yield call(http, {
      method: 'GET',
      url: `certifications-reports?Search=${
        encodeURIComponent(searchValue)}&skip=${skip}&take=${take}&SortField=${sortSettings && sortSettings.column}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setCertificationsCounter(certificationsReports.data.TotalCount));
    yield put(setCertificationsReports(certificationsReports.data.Certifications));
  } finally {
    yield put(finishRequest());
  }
}

export default function* reportsCertificationsSaga() {
  yield takeEvery(GET_CERTIFICATIONS_REPORTS, getCertificationsReports);
}
