export const SET_LIST_SETTINGS = '@contracts/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@contracts/RESET_LIST_SETTINGS';
export const SET_EXPIRED_CONTRACTS = '@contracts/SET_CONTRACTS';
export const GET_EXPIRED_CONTRACTS = '@contracts/GET_EXPIRED_CONTRACTS';
export const EDIT_VIEW_CONTRACTS = '@contracts/EDIT_VIEW_CONTRACTS';
export const SET_CONTRACTS_ALERTS = '@contracts/SET_CONTRACTS_ALERTS';
export const SET_CONTRACTS_APPROVAL = '@contracts/SET_CONTRACTS_APPROVAL';
export const SET_CONTRACTS_TYPES = '@contracts/SET_CONTRACTS_TYPES';
export const SET_CONTRACTS_TOTAL_COUNT = '@contracts/SET_CONTRACTS_TOTAL_COUNT';
export const IMPORT_FILE = '@contracts/IMPORT_FILE';
export const SET_IMPORT_FILE = '@contracts/SET_IMPORT_FILE';
export const SET_FLAG_SHOW_CONFIRM_DIALOG = '@contracts/SET_FLAG_SHOW_CONFIRM_DIALOG';
export const DOWNLOAD_FILE = '@contracts/DOWNLOAD_FILE';
export const SET_STATUS_FOR_DOWNLOADED_FILE = '@contracts/SET_STATUS_FOR_DOWNLOADED_FILE';
export const RESET_CONTRACTS = '@contracts/RESET_CONTRACTS';

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const setContractsAlerts = (alerts) => ({
  type: SET_CONTRACTS_ALERTS,
  payload: alerts,
});

export const setContractsApprovalStatus = (approvalStatus) => ({
  type: SET_CONTRACTS_APPROVAL,
  payload: approvalStatus,
});

export const setContractsTypes = (contractTypes) => ({
  type: SET_CONTRACTS_TYPES,
  payload: contractTypes,
});

export const setExpiredContracts = (contracts) => ({
  type: SET_EXPIRED_CONTRACTS,
  payload: contracts,
});

export const getExpiredContracts = (filters = {}, dontUploadFilters, skip, take, searchValue, sortSetting) => ({
  type: GET_EXPIRED_CONTRACTS,
  payload: {
    filters,
    dontUploadFilters,
    skip,
    take,
    sortSetting,
    searchValue,
  },
});

export const handleEditViewContract = (id, isOnlyActive, document, idDocument, rights, searchValue) => ({
  type: EDIT_VIEW_CONTRACTS,
  payload: {
    id,
    isOnlyActive,
    document,
    idDocument,
    rights,
    searchValue,
  },
});

export const setTotalCountContracts = (count) => ({
  type: SET_CONTRACTS_TOTAL_COUNT,
  payload: count,
});

export const importFile = (formData) => ({
  type: IMPORT_FILE,
  payload: formData,
});

export const setImportFile = (file) => ({
  type: SET_IMPORT_FILE,
  payload: file,
});

export const toggleFlagShow = (flag) => ({
  type: SET_FLAG_SHOW_CONFIRM_DIALOG,
  payload: flag,
});

export const downloadFile = (file) => ({
  type: DOWNLOAD_FILE,
  payload: file,
});

export const setStatusForDownloadedFile = (statusForFile) => ({
  type: SET_STATUS_FOR_DOWNLOADED_FILE,
  payload: statusForFile,
});

export const resetContracts = () => ({
  type: RESET_CONTRACTS,
});
