import {
  SET_CLIENT_BY_ID,
  SET_CLIENTS,
  SET_CLIENT_INFO,
  SET_CLIENTS_NAME_ERROR,
  CLEAR_ERROR_MESSAGE,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
  SET_SOW_TYPES,
} from '../actions/clientsActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
  detailsTabsState: {
    projects: {
      searchValue: '',
      sortColumn: 'Name',
      sortDirection: 'Up',
    },
    contracts: {
      searchValue: '',
      sortColumn: 'Contract',
      sortDirection: 'Up',
    },
    contacts: {
      searchValue: '',
      sortColumn: 'Name',
      sortDirection: 'Up',
    },
    workingPeriodRestrictions: {
      sortColumn: 'SowType',
      sortDirection: 'Up',
    },
  },
};

const initialState = {
  clients: [],
  clientInfo: {},
  columnsToDisplay: [
    {
      Name: 'Company Name',
      Id: 'Name',
      hasBadge: true,
      className: 'huge-col',
    },
    {
      Name: 'Projects',
      Id: 'Projects',
      className: 'medium-col',
      isProjectsLenght: true,
    },
  ],
  error: '',
  listSettings: defaultListSettings,
  sowTypes: [],
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
      };
    case SET_CLIENT_BY_ID:
      return {
        ...state,
        clientInfo: action.payload.client,
      };
    case SET_CLIENT_INFO:
      return {
        ...state,
        clientInfo: action.payload.filterInfo,
      };
    case SET_CLIENTS_NAME_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: '',
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_SOW_TYPES:
      return {
        ...state,
        sowTypes: action.payload,
      };
    default:
      return state;
  }
};

export default clientsReducer;
