import {
  call, put, takeEvery, takeLatest, select, debounce, all,
} from 'redux-saga/effects';
import history from 'utils/history';
import http from 'services/http';
import moment from 'moment';

import {
  FETCH_PROJECTS,
  SEARCH_PROJECTS,
  GET_OFFICES,
  GET_CLIENTS,
  GET_STATUSES,
  GET_PERIODS,
  GET_CREATION_CONFIG,
  CREATE_NEW_PROJECT,
  GET_MANAGERS_BY_OFFICE,
  setManagersByOffices,
  setProjects,
  setOffices,
  setClients,
  setStatuses,
  setPeriods,
  setClientContacts,
  setProjectStatuses,
  setApprovalStatuses,
  setSOWTypes,
  setProjectTypes,
  setProjectDetails,
  setProjectTeams,
  GET_PROJECT_TEAMS,
  GET_PROJECT_DETAILS,
  GET_PROJECT_ROLE_CONFIG,
  setProjectRoleConfig,
  GET_PO_STATUSES,
  setPoStatuses,
  setProjectRoles,
  TRANSFER_ROLES,
  GET_ROLES,
  setAllRoles,
  GET_HISTORY_PROJECT,
  setHistory,
  setProjectTotalCount,
  CHECK_PROJECT_NAME_DUBLICATE_ERROR,
  setProjectNameDublicateError,
  GET_ALL_CURRENCIES,
  setCurrencies,
  PATCH_PROJECT_DATA,
  GET_MILESTONES_FOR_AMENDMENT,
  GET_MILESTONES_FOR_SOW,
  GET_MILESTONES_FOR_PO,
  setMilestonesForAmendment,
  setMilestonesForPO,
  setMilestonesForSOW,
  GET_PROJECT_MILESTONES,
  PATCH_PROJECT_MILESTONE,
  setProjectMilestones,
  getProjectMilestoneEditingForm,
  setRenewalStatusesForSow,
  GET_SOW_RENEWAL_STATUSES_FOR_SOW,
  PATCH_CONTRACT_DATA,
  GET_ALL_ENGAGEMENT_TYPES,
  setEngagementTypes,
  CREATE_PROJECT_TEAM,
  EDIT_PROJECT_TEAM,
  DELETE_PROJECT_TEAM,
  GET_PROJECT_TEAM_EMPLOYEES,
  resetProjectTeam,
  GET_ALL_ROLES_PROJECT,
  GET_SHADOWING_MILESTONES_REQUEST,
  getShadowingMilestonesSuccess,
} from '../actions/projectsActions';
import { setMembers } from 'pages/members/actions/membersActions';
import { GET_OFFICE_LOCATIONS, setOfficeLocations } from '../actions/filterLocationsActions';
import { resetRoles } from '../actions/roleModalActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { addNewNotification } from 'services/addNewNotification.js';
import { addNotify } from '../../../common/actions/notificationsActions';
import {
  CHANGE_PROJECT_BASIC_INFO,
  getProjectBasicInfoFormData,
} from 'common/actions/reduxFormActions';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { INVOICE_AMOUNT_NEGATIVE_VALUE_ERROR_CODE, INVOICE_AMOUNT_NEGATIVE_VALUE_ERROR_MESSAGE } from 'utils/const-variable';
import { getAssignmentsWithShadows, getAssignmentModel } from 'utils/sortRoleEmployees';
import { getDocumentStatuses } from '../../../common/actions/filtersActions';
import { mapFromChildContractMilestone, mapFromMainContractMilestone } from '../../../services/milestone';
import { parseFromAmericanFormat } from '../../../services/getAmericanFormatDigit';
import { isFormDataChanged } from 'utils/isFormDataChanged';

export function* fecthProjects(action) {
  try {
    yield put(startRequest());
    const projects = yield call(http, {
      method: 'GET',
      url: 'projects',
      params: action.payload.filters,
    });
    yield put(setProjectTotalCount(projects.data.TotalCount));
    yield put(setProjects(projects.data.Projects));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* searchProjects(action) {
  try {
    yield put(startRequest());
    const {
      filters, take, skip, searchValue, sortSetting,
    } = action.payload;
    const orderByDesc = sortSetting && sortSetting.sortDirection === 'Up' ? false : true;
    const projects = yield call(http, {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `projects?name=${encodeURIComponent(searchValue)}&skip=${skip}&take=${take}&SortField=${sortSetting && sortSetting.column}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setProjectTotalCount(projects.data.TotalCount));
    yield put(setProjects(projects.data.Projects));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getAllRoles(action) {
  try {
    const allRoles = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/roles?isOnlyActive=false`,
    });
    yield put(setAllRoles(allRoles.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  } finally {
    yield put(finishRequest());
  }
}

export function* getProjectDetails(action) {
  try {
    yield put(startRequest());
    const project = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}`,
      params: action.payload.params,
      transformResponse: (data) =>
        JSON.parse(data, (key, value) =>
          typeof value === 'number' && value.toString().indexOf('.') >= 0 ? value.toFixed(2) : value)
    });
    yield put(setProjectDetails(project.data));
    yield getPoStatuses();
    yield getRoles(action);
    const allRoles = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/roles?isOnlyActive=false`,
    });
    yield put(setAllRoles(allRoles.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getProjectTeams(action) {
  try {
    yield put(startRequest());
    const teams = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/teams`,
    });
    yield put(setProjectTeams(teams.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  } finally {
    yield put(finishRequest());
  }
}

export function* getProjectTeamEmployees() {
  try {
    yield put(startRequest());
    const members = yield call(http, {
      method: 'GET',
      url: 'employees',
      params: {
        Statuses: [1, 2, 5],
        reduced: true,
      },
    });
    yield put(setMembers(members.data.Employees));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  } finally {
    yield put(finishRequest());
  }
}

export function* createProjectTeam(action) {
  try {
    yield put(startRequest());
    const { projectId, team } = action.payload;
    yield call(http, {
      method: 'POST',
      url: `projects/${projectId}/teams`,
      data: team,
    });
    history.push({
      pathname: `/projects/${projectId}`,
    });
  } catch (e) {
    if (e && e.response && e.response.data && e.response.data.description) {
      yield put(showAlertDialog(e.response.data.description));
    }
  } finally {
    yield put(finishRequest());
  }
}

export function* editProjectTeam(action) {
  try {
    yield put(startRequest());
    const { projectId, teamId, team } = action.payload;
    yield call(http, {
      method: 'PUT',
      url: `projects/${projectId}/teams/${teamId}`,
      data: team,
    });
    history.push({
      pathname: `/projects/${projectId}`,
    });
  } catch (e) {
    if (e && e.response && e.response.data && e.response.data.description) {
      yield put(showAlertDialog(e.response.data.description));
    }
  } finally {
    yield put(finishRequest());
  }
}

export function* deleteProjectTeam(action) {
  try {
    yield put(startRequest());
    const { projectId, teamId } = action.payload;
    yield call(http, {
      method: 'DELETE',
      url: `projects/${projectId}/teams/${teamId}`,
    });
    const teams = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/teams`,
    });
    yield put(setProjectTeams(teams.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  } finally {
    yield put(finishRequest());
  }
}

export function* getHistory(action) {
  try {
    yield put(startRequest());
    const { payload: { options } } = action;
    const additionlParams = options ? `&keyWord=${encodeURIComponent(options.search)}&from=${options.startDateHistory}&to=${options.endDateHistory}` : '';
    const history = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/history?skip=${action.payload.skip}&take=${action.payload.take}${additionlParams}`,
    });

    yield put(setHistory(history.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getRoles(action) {
  try {
    const data = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/roles?isOnlyActive=${action.payload.isOnlyActive}`,
    });
    yield put(setProjectRoles(data.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getProjectRoleConfig(action) {
  try {
    const project = yield call(http, {
      method: 'GET',
      url: 'roles',
      params: {
        projectId: action.payload.id,
      },
    });
    yield put(setProjectRoleConfig(project.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}
export function* getProjectMilestones(action) {
  try {
    yield put(startRequest());
    const response = yield call(http, {
      method: 'GET',
      url: `projects/${action.payload.id}/contract-milestones`,
    });
    yield put(setProjectMilestones(response.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* patchProjectMilestone({ payload: { projectId, Id, params } }) {
  try {
    const { form } = yield select(getProjectMilestoneEditingForm);

    const data = form[Id];
    if (!data) {
      return;
    }

    yield call(http, {
      method: 'PATCH',
      url: `billing/projects/${projectId}/milestones/${Id}`,
      data: {
        invoicedAmount: data.InvoicedAmount && parseFromAmericanFormat(data.InvoicedAmount) || null,
        invoicedDate: data.InvoicedDate && moment(data.InvoicedDate).format(moment.defaultFormat) || null,
        approved: data.Approved,
        revenueReceived: data.RevenueReceived && parseFromAmericanFormat(data.RevenueReceived) || null,
      },
    });

    yield call(getProjectMilestones, { payload: { id: projectId } });
    yield call(getProjectDetails, { payload: { id: projectId, params } });
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
    if (e && e.response && e.response.data && e.response.data.code === INVOICE_AMOUNT_NEGATIVE_VALUE_ERROR_CODE) {
      yield put(showAlertDialog(INVOICE_AMOUNT_NEGATIVE_VALUE_ERROR_MESSAGE));
    }
  }
}

export function* getAllOffices(action) {
  try {
    const offices = yield call(http, {
      method: 'GET',
      url: `offices?allowDisabled=${action.payload.allowDisabled}`,
    });
    yield put(setOffices(offices.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getPoStatuses() {
  try {
    const poStatuses = yield call(http, {
      method: 'GET',
      url: 'po-statuses',
    });
    yield put(setPoStatuses(poStatuses.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getAllClients() {
  try {
    const clients = yield call(http, {
      method: 'GET',
      url: 'clients',
    });
    yield put(setClients(clients.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getAllStatuses() {
  try {
    const statuses = yield call(http, {
      method: 'GET',
      url: 'projects/statuses',
    });
    yield put(setStatuses(statuses.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getAllPeriods() {
  try {
    const periods = yield call(http, {
      method: 'GET',
      url: 'periods',
    });
    yield put(setPeriods(periods.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* getCreationConfig() {
  try {
    yield put(startRequest());

    const [
      clientManagers,
      approvalStatuses,
      sowTypes,
      projectTypes,
      projectStatuses,
      poStatuses,
    ] = yield all([
      call(http, { method: 'GET', url: 'client-contacts' }),
      call(http, { method: 'GET', url: 'projects/approval-statuses' }),
      call(http, { method: 'GET', url: 'sow_types' }),
      call(http, { method: 'GET', url: 'projects/types' }),
      call(http, { method: 'GET', url: 'projects/statuses' }),
      call(http, { method: 'GET', url: 'po-statuses' }),
      put(getDocumentStatuses()),
    ]);

    yield put(setClientContacts(clientManagers.data));
    yield put(setApprovalStatuses(approvalStatuses.data));
    yield put(setSOWTypes(sowTypes.data));
    yield put(setProjectTypes(projectTypes.data));
    yield put(setProjectStatuses(projectStatuses.data));
    yield put(setPoStatuses(poStatuses.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* createNewProjectSaga(action) {
  try {
    const data = action.payload;
    if (data.startDate) data.startDate = moment(data.startDate).format('YYYY-MM-DD HH:mm');
    if (data.endDate) data.endDate = moment(data.endDate).format('YYYY-MM-DD HH:mm');
    if (data.startDateSOW) data.startDateSOW = moment(data.startDateSOW).format('YYYY-MM-DD HH:mm');
    if (data.endDateSOW) data.endDateSOW = moment(data.endDateSOW).format('YYYY-MM-DD HH:mm');
    if (data.startDatePO) data.startDatePO = moment(data.startDatePO).format('YYYY-MM-DD HH:mm');
    if (data.endDatePO) data.endDatePO = moment(data.endDatePO).format('YYYY-MM-DD HH:mm');
    const reqData = {
      name: data.name,
      description: data.description,
      aliases: data.aliases,
      projectCode: data.projectCode,
      startDate: data.startDate,
      endDate: data.endDate,
      ProjectType: {
        Id: data.projectType,
        Name: data.projectTypeName,
      },
      accountManagerId: data.accountManagerId,
      DeliveryManagers: data.currentDeliveryManagers.map(item => item.valueId),
      EngineeringManagers: data.EngineeringManagers,
      offices: data.offices,
      PrimaryOffices: data.primaryOffices,
      clientId: data.clientId,
      clientManagerId: data.clientManagerId,
      paymentTerm: data.SOWPaymentTerm,
      Currency: data.currency,
      BillingCode: data.ProjectBillingCode,
      engagementTypeId: data.engagementTypeId,
    };

    const projectRoles = data.roles;
    const withoutEmptyObjPO = [];
    if (data.PODocuments.length) {
      data.PODocuments.forEach((item) => {
        if (item.URL !== '' && item.Comment !== '') {
          withoutEmptyObjPO.push(item);
        }
      });
    }
    const withoutEmptyObjSOW = [];
    if (data.SOWDocuments.length) {
      data.SOWDocuments.forEach((item) => {
        if (item.URL !== '' && item.Comment !== '') {
          withoutEmptyObjSOW.push(item);
        }
      });
    }
    const arraySowRenewalStatuses = data.SowRenewalStatuses.map((status) => (
      { RenewalStatusId: status.RenewalStatusId, Value: status.Value, IsActive: status.IsActive }));
    if (data.isStep2FieldsRequired) {
      if (!!data.SOWNumber) {
        reqData.sow = {
          name: data.SOWNumber,
          SowTypeId: data.SOWType,
          clientId: data.SOWClientId,
          approvalStatus: data.approvalStatus,
          SOWApproverId: data.invoiceApprover,
          startDate: data.startDateSOW,
          endDate: data.endDateSOW,
          travelBudget: data.travelBudget,
          PaymentTermId: data.SOWPaymentTerm,
          travelReimbursement: data.travelReimbursement,
          BillingCode: data.SOWBillingCode,
          StatusComments: data.SOWDescription && data.SOWDescription.trim(),
          BudgetComments: data.SOWBudgetComments && data.SOWBudgetComments.trim(),
          businessGroup: data.businessGroup,
          projectTeam: data.projectTeam,
          documentStatus: data.SOWDocumentStatusesId,
          Milestones: [],
          SowRenewalStatuses: arraySowRenewalStatuses,
          invoiceDateId: data.SOWInvoiceDate,
        };
      }
      if (!!data.PONumber) {
        reqData.po = {
          name: data.PONumber,
          startDate: data.startDatePO,
          endDate: data.endDatePO,
          BillingCode: data.POBillingCode,
          documentStatus: data.PODocumentStatusesId,
          POApproverId: data.POApprover,
          ClientId: data.POClientId,
          approvalStatus: data.POStatus,
          StatusComments: data.PODescription && data.PODescription.trim(),
          BudgetComments: data.POBudgetComments && data.POBudgetComments.trim(),
          PaymentTermId: data.POPaymentTerm,
          Milestones: [],
          invoiceDateId: data.POInvoiceDate,
        };
      }
      reqData.sowLinks = withoutEmptyObjSOW;
      reqData.poLinks = withoutEmptyObjPO;
    }
    if (!!projectRoles.length) {
      reqData.roles = projectRoles.map(item => item = {
        RoleName: item.RoleName,
        expertRoleId: item.RoleId,
        expertRoleName: item.RoleType,
        SeniorityId: item.SeniorityId,
        billability: item.BillableStatusId,
        rate: item.Rate,
        rateOvertime: item.OvertimeRate,
        overtimeRateUnit: item.overtimeRateUnit,
        rateUnit: item.rateUnit,
        type: item.AssignmentTypeId,
        allocation: item.Assignment,
        contractName: item.BillingCodeId,
        Comment: item.Comment,
        startDate: moment(item.StartDate).format('YYYY-MM-DD HH:mm'),
        endDate: moment(item.EndDate).format('YYYY-MM-DD HH:mm'),
        status: item.StatusId,
        assignments: getAssignmentsWithShadows(item.Employees).reduce((acc, employee) => {
          if (employee.Id.length) {
            acc.push(getAssignmentModel(employee));
          }
          return acc;
        }, []),
      });
    }
    const project = yield call(http, {
      method: 'POST',
      url: 'projects',
      data: reqData,
    });
    yield put(resetRoles());
    history.push({
      pathname: '/projects',
      state: data.historyLocation,
    });
    const message = addNewNotification(project, 'Project');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Project');
    yield put(addNotify(message));
  }
}

export function* transferRoles(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: `projects/${action.projectId}/roles/extend_roles?newContractId=${action.newId}`,
      data: action.payload,
    });

    const isOnlyActiveRoles = yield select((state) => !state.showRoles.isOnlyActiveRoles);

    yield getRoles({ payload: { id: action.projectId, isOnlyActive: isOnlyActiveRoles } });
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* validationProjectName(action) {
  try {
    const nameValidationResult = yield call(http, {
      method: 'POST',
      url: 'validations/project_name',
      data: { Value: action.payload },
    });
    yield put(setProjectNameDublicateError(nameValidationResult.data.IsExist));
  } catch (e) {
    // eslint-disable-next-line no-console
    // error message
  }
}

export function* editProjectBasicInfo(action) {
  try {
    const { isOnlyActive, params } = action.payload;
    const form = yield select(getProjectBasicInfoFormData);
    if (!isFormDataChanged(form.data)) {
      return;
    }
    const { values, initial } = form.data;
    const data = {
      Name: values.Name,
      Aliases: values.Aliases,
      ProjectCode: values.ProjectCode,
      Description: values.Description,
      StartDate: values.StartDate,
      EndDate: values.EndDate,
      Currency: values.Currency.Id,
      ProjectType: values.ProjectType,
      EngagementTypeId: values.EngagementTypeId,
      ClientId: values.ClientId,
      ClientManagerId: values.ClientManagerId,
      AccountManagerId: values.AccountManagerId,
      Offices: values.Offices,
      PrimaryOffices: values.PrimaryOffices,
      DeliveryManagers: values.DeliveryManagers,
      EngineeringManagers: values.EngineeringManagers,
    };
    if (initial.Budget !== values.Budget && values.Year) {
      data.FinancialData = {
        Year: values.Year,
        Budget: values.Budget,
      };
    }
    if (!form.data.syncErrors) {
      const project = yield call(http, {
        method: 'PUT',
        url: `projects/${values.Id}`,
        data,
      });
      yield getProjectDetails({ payload: { id: values.Id, isOnlyActive, params } });
      const defaultSkipElement = 0;
      const defaultTakeElements = 6;
      const history = yield call(http, {
        method: 'GET',
        url: `projects/${values.Id}/history?skip=${defaultSkipElement}&take=${defaultTakeElements}`,
      });
      yield put(setHistory(history.data));
      const message = addNewNotification(project, 'Project', ' is updated');
      yield put(addNotify(message));
      if (values.EngagementTypeName !== 'Team-based') {
        yield put(resetProjectTeam());
      }
    }
  } catch (e) {
    if (e && e.response && e.response.data && e.response.data.description) {
      yield put(showAlertDialog(e.response.data.description));
    }
  }
}

export function* downloadDeliveryByOffice(action) {
  const offices = action.payload.length ? action.payload.join('&offices=') : '';
  try {
    yield put(startRequest());
    const deliveryManagersByOffice = yield call(http, {
      method: 'GET',
      url: `delivery_managers?offices=${offices}`,
    });
    const engineeringManagersByOffice = yield call(http, {
      method: 'GET',
      url: `engineering_managers?offices=${offices}`,
    });
    const accountManagersByOffice = yield call(http, {
      method: 'GET',
      url: `account_managers?offices=${offices}`,
    });
    const allManagers = {
      deliveryManagersByOffice: deliveryManagersByOffice.data,
      engineeringManagersByOffice: engineeringManagersByOffice.data,
      accountManagersByOffice: accountManagersByOffice.data,
    };
    yield put(setManagersByOffices(allManagers));
  } catch (e) {
    // error
  } finally {
    yield put(finishRequest());
  }
}

export function* loadEngagementTypes() {
  try {
    const engagementTypes = yield call(http, {
      method: 'GET',
      url: 'engagement_types',
    });

    yield put(setEngagementTypes(engagementTypes.data));
  } catch (e) {
    // error
  }
}

export function* loadFilterLocations() {
  try {
    const officeLocations = yield call(http, {
      method: 'GET',
      url: 'office_locations',
    });
    yield put(setOfficeLocations(officeLocations.data));
  } catch (e) {
    // error message
  }
}

export function* loadCurrencies() {
  try {
    const currentAllCurrencies = yield select((state) => state.projectsReducer.allCurrencies);
    if (!currentAllCurrencies.length) {
      yield put(startRequest());
      const currencies = yield call(http, {
        method: 'GET',
        url: 'currencies',
      });
      yield put(setCurrencies(currencies.data));
      yield put(finishRequest());
    }
  } catch (e) {
    // error
    yield put(finishRequest());
  }
}

export function* patchProjectData(action) {
  try {
    const { projectData, projectId } = action.payload;
    const project = yield call(http, {
      method: 'PATCH',
      url: `projects/${projectId}`,
      data: projectData,
    });
    const message = addNewNotification(project, 'Project', ' updated');
    yield put(addNotify(message));
  } catch (e) {
    const message = addNewNotification(e, 'Project', ' not updated');
    yield put(addNotify(message));
  }
}

export function* getMilestonesForSow(action) {
  try {
    const { projectId, sowId } = action.payload;
    yield put(startRequest());
    const milestones = yield call(http, {
      method: 'GET',
      url: `billing/projects/${projectId}/statements-of-work/${sowId}/milestones`,
    });

    const mappedMilestones = milestones.data.map((milestone) => mapFromMainContractMilestone(milestone));

    yield put(setMilestonesForSOW(mappedMilestones));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getMilestonesForPo(action) {
  try {
    const {
      projectId, poId, isOriginalPo,
    } = action.payload;

    yield put(startRequest());

    if (isOriginalPo) {
      const milestones = yield call(http, {
        method: 'GET',
        url: `billing/projects/${projectId}/original-purchase-orders/${poId}/milestones`,
      });

      const mappedMilestones = milestones.data.map((milestone) => mapFromMainContractMilestone(milestone));

      yield put(setMilestonesForPO(mappedMilestones));
    } else {
      const milestones = yield call(http, {
        method: 'GET',
        url: `billing/projects/${projectId}/extension-purchase-orders/${poId}/milestones`,
      });

      const mappedMilestones = milestones.data.map((milestone) => mapFromChildContractMilestone(milestone));

      yield put(setMilestonesForPO(mappedMilestones));
    }

    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getMilestonesForAmendment(action) {
  try {
    const { projectId, amendmentId } = action.payload;
    yield put(startRequest());
    const milestones = yield call(http, {
      method: 'GET',
      url: `billing/projects/${projectId}/amendments/${amendmentId}/milestones`,
    });

    const mappedMilestones = milestones.data.map((milestone) => mapFromChildContractMilestone(milestone));

    yield put(setMilestonesForAmendment(mappedMilestones));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* getSowRenewalStatuses(action) {
  try {
    const sowId = action.payload;
    yield put(startRequest());
    const sowRenewalStatuses = yield call(http, {
      method: 'GET',
      url: `sows/${sowId}/sow-renewal-statuses`,
    });
    yield put(setRenewalStatusesForSow(sowRenewalStatuses.data));
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* patchContractData(action) {
  try {
    const { url, data, typeForAlertMessageAfterPatchRequest } = action.payload;
    const contract = yield call(http, {
      method: 'PATCH',
      url,
      data,
    });
    const message = addNewNotification(contract, typeForAlertMessageAfterPatchRequest, ' updated');
    yield put(addNotify(message));
  } catch (e) {
    const { typeForAlertMessageAfterPatchRequest } = action.payload;
    const message = addNewNotification(e, typeForAlertMessageAfterPatchRequest, ' not updated');
    yield put(addNotify(message));
  }
}

export function* getShadowingMilestones({ payload: { statementOfWorkId, projectId } }) {
  try {
    yield put(startRequest());

    const shadowingMilestones = yield call(http, {
      method: 'GET',
      url: `billing/projects/${projectId}/statements-of-work/${statementOfWorkId}/shadowing-milestones`,
    });

    if (Array.isArray(shadowingMilestones.data)) {
      yield put(getShadowingMilestonesSuccess(shadowingMilestones.data));
    }
  } finally {
    yield put(finishRequest());
  }
}

export default function* projectsRootSaga() {
  yield takeEvery(GET_CREATION_CONFIG, getCreationConfig);
  yield takeEvery(FETCH_PROJECTS, fecthProjects);
  yield takeEvery(SEARCH_PROJECTS, searchProjects);
  yield takeEvery(GET_PROJECT_DETAILS, getProjectDetails);
  yield takeEvery(GET_PROJECT_ROLE_CONFIG, getProjectRoleConfig);
  yield takeEvery(GET_PROJECT_MILESTONES, getProjectMilestones);
  yield takeEvery(PATCH_PROJECT_MILESTONE, patchProjectMilestone);
  yield takeEvery(GET_OFFICES, getAllOffices);
  yield takeEvery(GET_CLIENTS, getAllClients);
  yield takeEvery(GET_STATUSES, getAllStatuses);
  yield takeEvery(GET_PERIODS, getAllPeriods);
  yield takeEvery(CREATE_NEW_PROJECT, createNewProjectSaga);
  yield takeEvery(GET_PO_STATUSES, getPoStatuses);
  yield takeEvery(TRANSFER_ROLES, transferRoles);
  yield takeEvery(GET_ROLES, getRoles);
  yield takeEvery(GET_HISTORY_PROJECT, getHistory);
  yield debounce(2000, CHECK_PROJECT_NAME_DUBLICATE_ERROR, validationProjectName);
  yield takeEvery(GET_OFFICE_LOCATIONS, loadFilterLocations);
  yield takeEvery(GET_ALL_CURRENCIES, loadCurrencies);
  yield debounce(100, CHANGE_PROJECT_BASIC_INFO, editProjectBasicInfo);
  yield takeEvery(PATCH_PROJECT_DATA, patchProjectData);
  yield takeEvery(GET_MANAGERS_BY_OFFICE, downloadDeliveryByOffice);
  yield takeLatest(GET_MILESTONES_FOR_SOW, getMilestonesForSow);
  yield takeEvery(GET_MILESTONES_FOR_PO, getMilestonesForPo);
  yield takeEvery(GET_MILESTONES_FOR_AMENDMENT, getMilestonesForAmendment);
  yield takeEvery(GET_SOW_RENEWAL_STATUSES_FOR_SOW, getSowRenewalStatuses);
  yield takeEvery(PATCH_CONTRACT_DATA, patchContractData);
  yield takeEvery(GET_ALL_ENGAGEMENT_TYPES, loadEngagementTypes);
  yield takeEvery(GET_PROJECT_TEAMS, getProjectTeams);
  yield takeEvery(GET_PROJECT_TEAM_EMPLOYEES, getProjectTeamEmployees);
  yield takeEvery(CREATE_PROJECT_TEAM, createProjectTeam);
  yield takeEvery(EDIT_PROJECT_TEAM, editProjectTeam);
  yield takeEvery(DELETE_PROJECT_TEAM, deleteProjectTeam);
  yield takeEvery(GET_ALL_ROLES_PROJECT, getAllRoles);
  yield takeEvery(GET_SHADOWING_MILESTONES_REQUEST, getShadowingMilestones);
}
