import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  setManagesInvoiceDates,
  GET_MANAGE_INVOICE_DATES,
  CREATE_NEW_INVOICE_DATE,
  UPDATE_INVOICE_DATE,
} from '../actions/adminManageInvoiceDates';

import {
  startRequest,
  finishRequest,
} from 'common/actions/loadingActions';

export function* getManageInvoiceDates() {
  try {
    yield put(startRequest());
    const invoiceDates = yield call(http, {
      method: 'GET',
      url: 'InvoiceDates',
    });

    yield put(setManagesInvoiceDates(invoiceDates.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addInvoiceDate(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'InvoiceDates',
      data,
    });
    yield call(getManageInvoiceDates);
  } catch (e) {
    // error message
  }
}

export function* putInvoiceDate(action) {
  yield call(http, {
    method: 'PUT',
    url: `InvoiceDates/${action.payload.Id}`,
    data: { Name: action.payload.Name },
  });
  yield call(getManageInvoiceDates);
}

export default function* adminManageInvoiceDates() {
  yield takeEvery(GET_MANAGE_INVOICE_DATES, getManageInvoiceDates);
  yield takeEvery(CREATE_NEW_INVOICE_DATE, addInvoiceDate);
  yield takeEvery(UPDATE_INVOICE_DATE, putInvoiceDate);
}
