import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import {
  closeModal,
  addRoleToProject,
  showModal,
  getEmployees,
} from '../../actions/roleModalActions';
import classNames from 'classnames';
import history from 'utils/history';
import Select from 'common/components/form-items/select/select';
import TextField from 'common/components/form-items/input/text-field';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import InputCurrency from 'common/components/form-items/input-currency/input-currency';
import { sortFilterList } from 'common/components/list/sortList';
import './role-modal.css';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import { showAlertDialog } from 'common/actions/confirmationDialogActions';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import sortArrayByPriority from 'utils/sortArrayByPriority';
import {
  PROJECT_ROLES_EMPLOYEES_PRIMARY,
  STATUS_BILLABLE,
  PROJECT_ROLES_EMPLOYEES_DISABLED,
  PROJECT_ROLES_EMPLOYEES_FORMER,
  DEFAULT_EMPLOYEE_ASSIGNMENTS,
  PROJECT_ROLES_STATUS_ACTIVE,
} from 'utils/const-variable';
import AddEmployee from './add-employee/add-employee';
import { isCorrectRoleAssignment } from '../utils/isCorrectRoleAssignments';
import formatCurrency from 'utils/formatCurrency';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { addRoleToPartialContract, cancelImport, stepBackImportRole } from '../../actions/importContractAction';
import Button from 'common/components/redux-form/components/button';
import isObjectNotEquals from 'utils/object-comparison';

// eslint-disable-next-line no-useless-escape
const reqularExpRate = /^((?!0)|(0\.))\d{1,9}(\,\d{1,9}){0,3}?(\.\d{1,5})?$/;
const addRoleNewProjectType = 'ADD_ROLE_TO_NEW_PROJECT';
const addRoleToProjectType = 'ADD_ROLE_TO_PROJECT';

export class RoleModal extends React.Component {
  constructor(props) {
    super(props);

    const {
      rolesState, projectId, rateUnits,
    } = this.props;

    this.state = {
      ItemId: (rolesState ? rolesState.length : 0),
      projectId,
      RoleName: '',
      RoleId: '',
      RoleType: '',
      SeniorityId: '',
      SeniorityLevel: '',
      BillableStatusId: '',
      BillableStatus: '',
      Rate: '',
      OvertimeRate: '',
      Comment: '',
      AssignmentTypeId: 1,
      AssignmentType: 'Offshore',
      Assignment: 1,
      StartDate: null,
      EndDate: null,
      BillingCodeId: '',
      BillingCode: '',
      StatusId: '',
      Status: '',
      roleType: PROJECT_ROLES_EMPLOYEES_PRIMARY,
      Employees: [],
      importedAssignments: [],
      isModalFieldsRequired: false,
      assignmentIsUnvalid: false,
      showConfirm: false,
      isDialogOpened: false,
      actionData: {},
      invalidRateData: false,
      invalidOverRateData: false,
      rateUnit: rateUnits ? rateUnits[0].Id : '',
      overtimeRateUnit: rateUnits ? rateUnits[0].Id : '',
      isEmployeeListValid: true,
      showConfirmationDialog: false,
      importDocumentBillingCodes: null,
      importDocumentBillingCodesStartDate: null,
      importDocumentBillingCodesEndDate: null,
    };
  }

  componentDidMount() {
    const {
      assignmentTypes, location, projectDetails, createdProjectName, setComponent, importedRoles,
    } = this.props;
    this.findArrValue(assignmentTypes);
    !(projectDetails.Name || createdProjectName) && history.goBack({ state: location.state });
    setComponent({ title: 'ADD ROLE' });
    if (importedRoles && importedRoles.length > 0) {
      this.getRoleImportDataInputs(importedRoles[0]);
      this.setImportedAssignments();
    }
  }

  componentDidUpdate(prevProps) {
    const { importedRoles, employees } = this.props;
    if (importedRoles !== prevProps.importedRoles && (importedRoles && importedRoles.length > 0)) {
      this.getRoleImportDataInputs(importedRoles[0]);
    }
    if (isObjectNotEquals(prevProps.importedRoles, importedRoles) || employees.length !== prevProps.employees.length) {
      this.setImportedAssignments();
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  closeConfirmationDialog = () => {
    this.setState({
      ...this.state,
      showConfirmationDialog: false,
    });
  }

  showConfirmationDialog = () => {
    this.setState({
      ...this.state,
      showConfirmationDialog: true,
    });
  }

  findArrValue = (arr) => {
    arr.forEach(item => {
      if (item.Name === 'Offshore') {
        this.setState({
          AssignmentTypeId: item.Id,
          AssignmentType: item.Name,
        });
      }
    });
  }

  sortArrays = (array) => {
    return array.sort((a, b) => (a.Name > b.Name
      ? 1
      : (a.Name === b.Name ? 0 : -1)));
  }

  isValidRequired = () => {
    const {
      RoleName, RoleId, SeniorityId, BillableStatusId, Rate,
      BillableStatus, AssignmentTypeId, assignmentIsUnvalid,
      StartDate, EndDate, BillingCodeId, Status,
      invalidRateData, invalidOverRateData,
    } = this.state;

    return !!RoleName && !!RoleId && !!SeniorityId && !!BillableStatusId && !!AssignmentTypeId && !!Status
      && !assignmentIsUnvalid && !invalidRateData && !invalidOverRateData
      && !moment(StartDate).isAfter(EndDate) && !!StartDate && !!EndDate
      && (Rate || BillableStatus !== STATUS_BILLABLE)
      && (BillingCodeId !== '' || BillableStatus !== STATUS_BILLABLE);
  }

  requireFields = () => {
    const isModalFieldsRequired = this.isValidRequired();
    this.setState({
      isModalFieldsRequired,
    });
  }

  // RoleName
  onChangeRoleName = (event) => {
    this.setState({
      RoleName: event.target.value,
    }, () => this.requireFields());
  }

  // Role
  onChangeRole = ({ item }) => {
    this.setState({
      RoleId: item.Id,
      RoleType: item.Name,
    }, () => this.requireFields());
  }

  // SeniorityLevel
  onChangeSeniority = ({ item }) => {
    this.setState({
      SeniorityId: item.Id,
      SeniorityLevel: item.Name,
    }, () => this.requireFields());
  }

  // BillableStatus
  onChangeBillable = ({ item }) => {
    this.setState({
      BillableStatusId: item.Id,
      BillableStatus: item.Name,
    }, () => this.requireFields());
  }

  // Rate
  onChangeRate = (e) => {
    if ((e.target.value.length && !reqularExpRate.test(e.target.value)) || (parseFloat(e.target.value) === 0)) {
      this.setState({
        invalidRateData: true,
      });
    } else {
      this.setState({
        invalidRateData: false,
      });
    }
    this.setState({
      Rate: e.target.value,
    }, () => this.requireFields());
  }

  // OvertimeRate
  onChangeOvertimeRate = (e) => {
    if ((e.target.value.length && !reqularExpRate.test(e.target.value)) || (parseFloat(e.target.value) === 0)) {
      this.setState({
        invalidOverRateData: true,
      });
    } else {
      this.setState({
        invalidOverRateData: false,
      });
    }
    this.setState({
      OvertimeRate: e.target.value,
    }, () => this.requireFields());
  }

  // AssignmentType
  onChangeAssignmentType = ({ item }) => {
    this.setState({
      AssignmentTypeId: item.Id,
      AssignmentType: item.Name,
    }, () => this.requireFields());
  }

  // Assigment
  onChangeAssignment = (e) => {
    isCorrectRoleAssignment(e.target.value) && this.setState({
      Assignment: e.target.value,
    }, () => this.requireFields());
  }

  // Comment
  onChangeComment = (event) => {
    this.setState({
      Comment: event.target.value,
    }, () => this.requireFields());
  }

  setImportedAssignments = () => {
    const { importedRoles } = this.props;
    const importedAssignments = [];
    if (importedRoles && importedRoles[0] && importedRoles[0].Assignments) {
      importedRoles[0].Assignments.forEach((assignment) => {
        const importedAssignment = {
          EmployeeId: assignment.EmployeeId,
          EmployeeName: assignment.Name,
          EmployeeStartDate: assignment.StartDate,
          EmployeeEndDate: assignment.EndDate,
        };
        importedAssignments.push(importedAssignment);
      });
      if (importedAssignments.length) {
        this.setState({
          importedAssignments,
        });
      }
    }
  }

  // SOW/PO number
  onChangeBillingCode = ({ target }) => {
    const {
      billingCodes,
      billingCodesStartDate,
      billingCodesEndDate,
      projectDetails,
    } = this.props;
    const { importDocumentBillingCodesEndDate, importDocumentBillingCodesStartDate, importDocumentBillingCodes } = this.state;
    projectDetails && projectDetails.Contracts.forEach((contract) => {
      if (contract.Contract === target.value && contract.Status.Name !== PROJECT_ROLES_STATUS_ACTIVE) {
        this.toogleDialog();
      }
      if (contract.POs.length) {
        contract.POs.forEach((PO) => {
          if (PO.Contract === target.value && PO.Status.Name !== PROJECT_ROLES_STATUS_ACTIVE) {
            this.toogleDialog();
          }
        });
      }
    });
    this.setState({
      BillingCodeId: target.value,
      BillingCode: target.value,
    }, (importDocumentBillingCodesEndDate && importDocumentBillingCodesStartDate && importDocumentBillingCodes)
      ? () => this.onChangeDate(
        importDocumentBillingCodesStartDate[importDocumentBillingCodes.indexOf(target.value)],
        importDocumentBillingCodesEndDate[importDocumentBillingCodes.indexOf(target.value)],
      )
      : () => this.onChangeDate(billingCodesStartDate[billingCodes.indexOf(target.value)], billingCodesEndDate[billingCodes.indexOf(target.value)]));
  }

  onChangeDate = (StartDate, EndDate) => {
    this.setState({
      StartDate,
      EndDate,
    }, () => this.requireFields());
  }

  toogleDialog = () => {
    const { showConfirm } = this.state;
    this.setState({
      showConfirm: !showConfirm,
    });
  };

  // StartDate
  handleStartDateChange = date => {
    const {
      BillingCodeId, importDocumentBillingCodesEndDate, importDocumentBillingCodesStartDate, importDocumentBillingCodes,
    } = this.state;
    const {
      projectDetails: { StartDate, EndDate }, showAlertDialog, billingCodesStartDate, billingCodes, billingCodesEndDate,
    } = this.props;

    const billingIsSelected = !!BillingCodeId;
    const billingCodeStartDate = billingIsSelected
      && (
        importDocumentBillingCodes
          ? importDocumentBillingCodesStartDate[importDocumentBillingCodes.indexOf(BillingCodeId)]
          : billingCodesStartDate[billingCodes.indexOf(BillingCodeId)]
      );
    const billingCodeEndDate = importDocumentBillingCodes
      ? importDocumentBillingCodesEndDate[importDocumentBillingCodes.indexOf(BillingCodeId)]
      : billingCodesEndDate[billingCodes.indexOf(BillingCodeId)];
    if (!billingIsSelected && StartDate && moment(date).isBefore(StartDate, 'day')) {
      return showAlertDialog('Date is less than project start date, please change project start date.');
    }
    if (billingIsSelected && moment(billingCodeStartDate).isAfter(date, 'day')) {
      return showAlertDialog('Date is less than SOW/PO start date, please change SOW/PO start date.');
    }
    if (!billingIsSelected && moment(date).isAfter(EndDate, 'day')) {
      return showAlertDialog('Date exceeds project end date, please change project end date.');
    }
    if (billingIsSelected && billingCodeEndDate && moment(date).isAfter(billingCodeEndDate, 'day')) {
      return showAlertDialog('Date exceeds SOW/PO end date, please change SOW/PO end date.');
    }
    if (billingIsSelected && !billingCodeEndDate && moment(date).isAfter(EndDate, 'day')) {
      return showAlertDialog('Date exceeds project end date, please change project end date. (SOW/PO doesn`t have end date)');
    }

    this.setState({
      StartDate: date,
    }, () => this.requireFields());
  };

  // EndDate
  handleEndDateChange = date => {
    const { BillingCodeId, importDocumentBillingCodesEndDate, importDocumentBillingCodes } = this.state;
    const {
      projectDetails: { EndDate }, showAlertDialog, billingCodesEndDate, billingCodes,
    } = this.props;

    const billingIsSelected = !!BillingCodeId;
    const billingCodeEndDate = importDocumentBillingCodes
      ? importDocumentBillingCodesEndDate[importDocumentBillingCodes.indexOf(BillingCodeId)]
      : billingCodesEndDate[billingCodes.indexOf(BillingCodeId)];

    if (!billingIsSelected && moment(date).isAfter(EndDate)) {
      return showAlertDialog('Date exceeds project end date, please change project end date.');
    }
    if (billingIsSelected && billingCodeEndDate && moment(date).isAfter(billingCodeEndDate)) {
      return showAlertDialog('Date exceeds SOW/PO end date, please change SOW/PO end date.');
    }
    if (billingIsSelected && !billingCodeEndDate && moment(date).isAfter(EndDate)) {
      return showAlertDialog('Date exceeds project end date, please change project end date. (SOW/PO doesn`t have end date)');
    }

    this.setState({
      EndDate: date,
    }, () => this.requireFields());
  };

  // Status
  onChangeStatus = ({ item }) => {
    this.setState({
      Status: item.Name,
      StatusId: item.Id,
    }, () => this.requireFields());
  }

  onChangeRateUnit = ({ item }) => {
    this.setState({
      rateUnit: item.Id,
    }, () => this.requireFields());
  }

  onChangeOvertimeRateUnit = ({ item }) => {
    this.setState({
      overtimeRateUnit: item.Id,
    }, () => this.requireFields());
  }

  clearStatus = () => {
    this.setState({
      Status: '',
      StatusId: '',
    }, () => this.requireFields());
  }

  clearValue = (property) => () => {
    if (property === 'BillingCodeId') {
      this.setState({
        [property]: '',
        BillingCode: '',
      }, () => this.requireFields());
      return;
    }
    if (property === 'Rate') {
      this.setState({
        [property]: '',
        invalidRateData: '',
      }, () => this.requireFields());
      return;
    }
    if (property === 'OvertimeRate') {
      this.setState({
        [property]: '',
        invalidOverRateData: '',
      }, () => this.requireFields());
      return;
    }
    this.setState({
      [property]: '',
    }, () => this.requireFields());
  };

  checkEmployeeListValid = (isEmployeeListValid, Employees) => {
    this.setState({
      isEmployeeListValid,
      Employees,
    });
  }

  getFlatEmployeeList = (employees) => {
    const flatEmployeesArray = employees.reduce((flatArray, primary) => {
      !!primary.EmployeeId && flatArray.push(primary);
      primary.backupEmployees.forEach(backup => !!backup.EmployeeId && flatArray.push(backup));
      return flatArray;
    }, []);

    return flatEmployeesArray;
  }

  applyModal = (action) => {
    const { isModalFieldsRequired, isEmployeeListValid } = this.state;
    const {
      addRoleToProject,
      isOnlyActive,
      projectId,
      location,
      addNewRole,
      onStepChange,
      importedRoles,
      addRoleToPartialContract,
      projectDetails,
      importedContract,
    } = this.props;
    if (isModalFieldsRequired && isEmployeeListValid) {
      if (importedRoles && importedRoles.length > 0) {
        addRoleToPartialContract({ role: this.state, projectId: projectId || projectDetails.Id, isOnlyActive });
        return;
      }
      if (importedRoles && importedContract.roles && importedContract.roles.length > 0 && !importedRoles.length) return;
      if (action === addRoleNewProjectType) {
        addNewRole(this.state);
        onStepChange({
          ...this.state,
          step: 2,
        });
      } else if (action === addRoleToProjectType) {
        const state = { ...this.state };
        addRoleToProject(state, !isOnlyActive);
        history.push({
          pathname: `/projects/${projectId}`,
          state: location.state,
        });
      }
    }
  }

  cancelRolePage = (action) => {
    const { onStepChange, projectId, location } = this.props;
    if (action === addRoleNewProjectType) {
      onStepChange({
        ...this.state,
        step: 2,
      });
    } else if (action === addRoleToProjectType) {
      history.push({
        pathname: `/projects/${projectId}`,
        state: location.state,
      });
    }
  }

  cancelImportContract = () => {
    const { projectDetails, cancelImport } = this.props;
    cancelImport(projectDetails.Id);
  }

  onBackClick = () => {
    const { stepBackImportRole } = this.props;
    stepBackImportRole();
  }

  getCurrency = () => {
    const { projectDetails } = this.props;
    const currencyProject = projectDetails && projectDetails.Currency && projectDetails.Currency.Name;
    return formatCurrency(currencyProject);
  }

  getRoleImportDataInputs = (roleImport) => {
    const {
      importedRoles, projectId, rateUnits, importedContract, importedContractType, billingCodes, billingCodesEndDate, billingCodesStartDate,
    } = this.props;
    const billingCode = importedContractType === 'SOW' ? importedContract.SOWId
      : importedContractType === 'PO' || 'Amendment' ? importedContract.Number : '';
    if (roleImport !== null) {
      this.setState({
        ItemId: (importedRoles ? importedRoles.length : 0),
        projectId,
        RoleName: '',
        RoleId: roleImport.ExpertRole && roleImport.ExpertRole.Id || '',
        RoleType: roleImport.ExpertRole && roleImport.ExpertRole.Name || '',
        SeniorityId: roleImport.SeniorityLevel && roleImport.SeniorityLevel.Id || '',
        BillableStatusId: '',
        BillableStatus: '',
        Rate: '',
        OvertimeRate: '',
        Comment: '',
        AssignmentTypeId: roleImport.AssignmentType && roleImport.AssignmentType.Id || 2,
        Assignment: roleImport.Allocation || 1,
        StartDate: importedContract.StartDate || null,
        EndDate: importedContract.EndDate || null,
        BillingCodeId: billingCode || '',
        BillingCode: billingCode,
        StatusId: '',
        Status: '',
        roleType: PROJECT_ROLES_EMPLOYEES_PRIMARY,
        Employees: [],
        isModalFieldsRequired: false,
        assignmentIsUnvalid: false,
        showConfirm: false,
        isDialogOpened: false,
        actionData: {},
        invalidRateData: false,
        invalidOverRateData: false,
        rateUnit: rateUnits ? rateUnits[0].Id : '',
        overtimeRateUnit: rateUnits ? rateUnits[0].Id : '',
        isEmployeeListValid: true,
        showConfirmationDialog: false,
        ...roleImport,
        AssignmentType: roleImport.AssignmentType && roleImport.AssignmentType.Name || 'Offshore',
        SeniorityLevel: roleImport.SeniorityLevel && roleImport.SeniorityLevel.Name || '',
        importDocumentBillingCodes: billingCode ? [...billingCodes, billingCode] : billingCodes,
        importDocumentBillingCodesStartDate: importedContract.StartDate ? [...billingCodesStartDate, importedContract.StartDate] : billingCodesStartDate,
        importDocumentBillingCodesEndDate: importedContract.EndDate ? [...billingCodesEndDate, importedContract.EndDate] : billingCodesEndDate,
      }, () => {
        this.requireFields();
      });
    }
  }

  getAssignerEmployeesToProject = () => {
    const { rolesState, projectDetails } = this.props;
    const employees = [];
    const roles = rolesState ? rolesState : projectDetails.Roles;
    
    for (const role of roles) {
      employees.push.apply(employees, role.Employees)
    }

    return employees;
  }

  render() {
    const {
      showConfirm,
      RoleId,
      SeniorityId,
      BillableStatusId,
      Employees,
      Rate,
      BillableStatus,
      OvertimeRate,
      AssignmentTypeId,
      assignmentIsUnvalid,
      Assignment,
      BillingCodeId,
      StartDate,
      EndDate,
      StatusId,
      Status,
      isModalFieldsRequired,
      RoleName,
      invalidRateData,
      invalidOverRateData,
      rateUnit,
      overtimeRateUnit,
      isEmployeeListValid,
      Comment,
      showConfirmationDialog,
      importDocumentBillingCodes,
      importedAssignments,
    } = this.state;
    const {
      roles,
      seniorityLevels,
      billableStatuses,
      assignmentTypes,
      billingCodes,
      roleStatus,
      roleModalAction,
      rateUnits,
      employees,
      currencyProject,
      importedRoles,
      projectTeams,
    } = this.props;
    const suitableEmployees = employees.filter(employee => {
      return employee.Type !== PROJECT_ROLES_EMPLOYEES_DISABLED
        && employee.Type !== PROJECT_ROLES_EMPLOYEES_FORMER;
    });
    const currency = currencyProject && formatCurrency(currencyProject);
    const assignedEmployeesToProject = this.getAssignerEmployeesToProject();

    return (
      <div className='page-container page-block'>
        <DocumentTitle title={applicationTitle.getTitile(roleModalAction === addRoleToProjectType ? 'addRole' : 'createProject')} />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          {
            showConfirmationDialog ? (
              <ConfirmationDialog
                dialogHeader='Are you sure'
                dialogTitle='All your import data will be lost'
                closeButtonTitle='Cancel'
                confirmButtonTitle='OK'
                closeDialog={this.closeConfirmationDialog}
                confirmDialog={this.cancelImportContract}
              />
            ) : null
          }
          {
            showConfirm ? (
              <AlertDialog
                dialogHeader='Warning!'
                dialogTitle='Selected contract doesnt have active status. Are you sure ?'
                closeDialog={this.toogleDialog}
                confirmDialog={this.toogleDialog}
                confirmButtonTitle='OK'
              />
            ) : null
          }
          <div className='form-block'>
            <div className='step__row'>
              <TextField
                formClassName='role-page-project__form margin-right-50'
                inputClassName='c-SOW-edit__select'
                id='RoleName'
                label='Role Name'
                placeholder='Role Name'
                name='RoleName'
                maxlength='50'
                value={RoleName}
                onChange={this.onChangeRoleName}
                isRequired
                clearValue={this.clearValue('RoleName')}
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50 role-page__role-type-svg--margin'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Role Type'
                placeholder='Choose Type'
                value={RoleId.toString()}
                onChange={this.onChangeRole}
                selectOptions={this.sortArrays(roles)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Role',
                  id: 'role',
                }}
                isRequired
                clearValue={this.clearValue('RoleId')}
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50 role-page__role-type-svg--margin'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Seniority Level'
                placeholder='Choose Level'
                value={SeniorityId.toString()}
                onChange={this.onChangeSeniority}
                selectOptions={sortArrayByPriority(seniorityLevels)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Seniority Level',
                  id: 'client',
                }}
                isRequired
                clearValue={this.clearValue('SeniorityId')}
              />
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Billable Status'
                placeholder='Choose Status'
                value={BillableStatusId.toString()}
                onChange={this.onChangeBillable}
                selectOptions={billableStatuses}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Client',
                  id: 'client',
                }}
                isRequired
                clearValue={this.clearValue('BillableStatusId')}
              />
            </div>
            <div className='step__row margin-up-role'>
              <div>
                <InputCurrency
                  formClassName='role-page-project__form-150 margin-right-50'
                  inputClassName='c-SOW-edit__input'
                  id='rate'
                  label='Rate'
                  placeholder='0'
                  maxlength='16'
                  value={Rate}
                  onChange={this.onChangeRate}
                  startAdornment={currency || this.getCurrency()}
                  isRequired={BillableStatus === STATUS_BILLABLE}
                  clearValue={() => this.clearValue('Rate')}
                />
                {invalidRateData ? <p className='project-input-error project-input-error-margin'>Invalid Rate</p> : null}
              </div>
              <Select
                formClassName='role-page-project__form-150 margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Rate Unit'
                placeholder='Choose Rate Unit'
                value={rateUnit}
                onChange={this.onChangeRateUnit}
                selectOptions={rateUnits ? rateUnits : []}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'rateUnit',
                  id: 'rateUnit',
                }}
                isRequired
              />
              <div>
                <InputCurrency
                  formClassName='role-page-project__form-150 margin-right-50'
                  inputClassName='c-SOW-edit__input'
                  id='rate'
                  label='Overtime Rate'
                  placeholder='0'
                  maxlength='16'
                  value={OvertimeRate}
                  onChange={this.onChangeOvertimeRate}
                  startAdornment={currency || this.getCurrency()}
                  clearValue={() => this.clearValue('OvertimeRate')}
                />
                {invalidOverRateData ? <p className='project-input-error project-input-error-margin'>Invalid Rate</p> : null}
              </div>
              <Select
                formClassName='role-page-project__form-150 margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Overtime Rate Unit'
                placeholder='Choose Overtime Rate Unit'
                value={overtimeRateUnit}
                onChange={this.onChangeOvertimeRateUnit}
                selectOptions={rateUnits ? rateUnits : []}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'overtimeRateUnit',
                  id: 'overtimeRateUnit',
                }}
                isRequired
              />
              <Select
                formClassName='role-page-project__form-150 margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Assignment Type'
                placeholder='Choose Type'
                value={AssignmentTypeId.toString()}
                onChange={this.onChangeAssignmentType}
                selectOptions={this.sortArrays(assignmentTypes)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Assignment Type',
                  id: 'assignmentType',
                }}
                isRequired
                clearValue={this.clearValue('AssignmentTypeId')}
              />
              <TextField
                formClassName='role-page-project__form-150 margin-right-50'
                inputClassName='c-role-modal__input'
                id='assignment'
                label='Assignment'
                type='number'
                placeholder='0'
                isRequired
                error={assignmentIsUnvalid}
                value={String(Assignment)}
                onChange={this.onChangeAssignment}
                clearValue={this.clearValue('Assignment')}
              />
            </div>
            <div className='step__row margin-up-role'>
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='SOW/PO Number'
                placeholder='Choose SoW Id/PO Number'
                value={BillingCodeId.toString()}
                onChange={this.onChangeBillingCode}
                selectOptions={importDocumentBillingCodes || billingCodes}
                isOptionObject={false}
                extendedValue
                inputProps={{
                  name: 'SOW/PO Number',
                  id: 'SOW/PONumber',
                }}
                isRequired={BillableStatus === STATUS_BILLABLE}
                clearValue={this.clearValue('BillingCodeId')}
              />
              <DatePicker
                formClassName='role-page-project__form-150  margin-right-50'
                datePickerClassName='c-role-modal__date-picker'
                placeholder='--/--/--'
                label='Start Date'
                value={StartDate}
                onChange={this.handleStartDateChange}
                isRequired
                name='startDate'
                isStartDate
              />
              <DatePicker
                formClassName='role-page-project__form-150  margin-right-50'
                datePickerClassName='c-role-modal__date-picker'
                placeholder='--/--/--'
                label='End Date'
                minVal={StartDate}
                disabled={!StartDate}
                value={EndDate}
                onChange={this.handleEndDateChange}
                helperText='Choose Start Date first'
                isRequired
                name='endDate'
                isEndDate
              />
              <Select
                formClassName='role-page-project__form margin-right-50'
                selectClassName='c-SOW-edit__select'
                optionsClassName='option'
                label='Status'
                placeholder='Choose Status'
                value={StatusId.toString()}
                onChange={this.onChangeStatus}
                selectOptions={sortFilterList(roleStatus)}
                isOptionObject
                extendedValue
                inputProps={{
                  name: 'Name',
                  id: 'Id',
                }}
                isRequired
                clearValue={this.clearStatus}
              />
            </div>
            <div className='step__row margin-up-role role-modal__comment--width-wraper'>
              <TextField
                formClassName='role-page-project__form margin-right-50'
                inputClassName='c-SOW-edit__select'
                id='Comment'
                label='Comment'
                placeholder='Comment'
                name='Comment'
                maxlength='100'
                value={Comment}
                onChange={this.onChangeComment}
                isRequired={false}
                showClear
                clearValue={this.clearValue('Comment')}
              />
            </div>
          </div>
          <AddEmployee
            RoleStartDate={StartDate}
            RoleEndDate={EndDate}
            RoleAssignment={Assignment && DEFAULT_EMPLOYEE_ASSIGNMENTS}
            RoleStatus={Status}
            disabled={!isModalFieldsRequired}
            employees={suitableEmployees}
            checkEmployeeListValid={this.checkEmployeeListValid}
            getFlatEmployeeList={this.getFlatEmployeeList}
            assignedEmployees={Employees}
            projectTeams={projectTeams}
            importedAssignments={importedAssignments}
            assignedEmployeesToProject={assignedEmployeesToProject}
          />
        </Scrollbars>
        <div className='form-block buttons margin-down-role'>
          <div className='right-side-buttons'>
            {importedRoles && <Button
              onClick={this.onBackClick}
              className='button cancel'
              text='Back'
            />}
            {(importedRoles && importedRoles.length > 0) ? <div className='button cancel' onClick={this.showConfirmationDialog}>
              <span>Cancel</span>
            </div> : <div className='button cancel' onClick={() => this.cancelRolePage(roleModalAction)}>
              <span>Cancel</span>
            </div>}
            {(importedRoles && importedRoles.length > 1) ? <div
              className={classNames('button next button-apply-and-create', {
                'btn-disabled':
                  !isModalFieldsRequired
                  || assignmentIsUnvalid
                  || !isEmployeeListValid,
              })}
              onClick={() => this.applyModal(roleModalAction)}
            >
              <span>Apply and create next</span>
            </div> : <div
              className={classNames('button next', { 'btn-disabled': !isModalFieldsRequired || assignmentIsUnvalid || !isEmployeeListValid })}
              onClick={() => this.applyModal(roleModalAction)}
            >
              <span>Apply Role</span>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

RoleModal.propTypes = {
  roles: PropTypes.array,
  seniorityLevels: PropTypes.array,
  billableStatuses: PropTypes.array,
  assignmentTypes: PropTypes.array,
  billingCodes: PropTypes.array,
  billingCodesStartDate: PropTypes.array,
  billingCodesEndDate: PropTypes.array,
  employees: PropTypes.array,
  projectId: PropTypes.string,
  roleModalAction: PropTypes.string,
  addRoleToProject: PropTypes.func,
  rateUnits: PropTypes.array,
};

export default connect(store => {
  const {
    BillableStatuses = [],
    SeniorityLevels = [],
    Roles = [],
    AssignmentTypes = [],
  } = store.projectsReducer.projectRoleConfig;
  return {
    billableStatuses: BillableStatuses,
    seniorityLevels: SeniorityLevels,
    roles: Roles,
    assignmentTypes: AssignmentTypes,
    employees: store.roleModalReducer.employees,
    roleEmployees: store.roleModalReducer.roleEmployees,
    billingCodes: store.roleModalReducer.billingCodes,
    billingCodesStartDate: store.roleModalReducer.billingCodesStartDate,
    billingCodesEndDate: store.roleModalReducer.billingCodesEndDate,
    currentRoles: store.roleModalReducer.roles,
    projectId: store.roleModalReducer.projectId,
    roleModalAction: store.roleModalReducer.roleModalAction,
    roleStatus: store.roleModalReducer.status,
    roleEmployeeStatus: store.roleModalReducer.employeeStatus,
    isOnlyActive: store.showRoles.isOnlyActiveRoles,
    projectDetails: store.projectsReducer.projectDetails,
    rateUnits: store.roleModalReducer.rateUnits,
    EmployeeId: store.authReducer.user.EmployeeId,
    currentRole: store.userRoleReducer.roles,
    importedRoles: store.importContractReducer.contract.Roles,
    importedContract: store.importContractReducer.partialContract.contract,
    importedContractType: store.importContractReducer.type,
    projectTeams: store.projectsReducer.projectTeams.Teams,
  };
}, {
  closeModal,
  addRoleToProject,
  showModal,
  getEmployees,
  showAlertDialog,
  setComponent,
  resetComponent,
  addRoleToPartialContract,
  stepBackImportRole,
  cancelImport,
})(RoleModal);
