export const SET_LIST_SETTINGS = '@billing/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@billing/RESET_LIST_SETTINGS';
export const FETCH_BILLING = '@billing/FETCH_BILLING';
export const SEARCH_BILLING = '@billing/SEARCH_BILLING';
export const SET_BILLING = '@billing/SET_BILLING';
export const GET_BILLING_DETAILS = '@billing/GET_BILLING_DETAILS';
export const SET_BILLING_DETAILS = '@billing/SET_BILLING_DETAILS';
export const RESET_BILLING_DETAILS = '@billing/RESET_BILLING_DETAILS';
export const CHANGE_COLUMNS_TO_DISPLAY = '@billing/CHANGE_COLUMNS_TO_DISPLAY';

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const fetchBilling = (filters = {}) => ({
  type: FETCH_BILLING,
  payload: {
    filters,
  },
});

export const searchBilling = (searchValue, filters = {}, take = 50, skip = 0, sortSetting) => ({
  type: SEARCH_BILLING,
  payload: {
    searchValue,
    filters,
    take,
    skip,
    sortSetting,
  },
});

export const setBilling = (billing) => ({
  type: SET_BILLING,
  payload: {
    billing,
  },
});

export const getBillingDetails = (id, isOnlyActive, isLoading = false) => ({
  type: GET_BILLING_DETAILS,
  payload: {
    id,
    isOnlyActive,
    isLoading,
  },
});

export const setBillingDetails = (billingDetails) => ({
  type: SET_BILLING_DETAILS,
  payload: billingDetails,
});

export const resetBillingDetails = () => ({
  type: RESET_BILLING_DETAILS,
  payload: null,
});

export const changeColumnsToDisplay = (columnsToDisplay) => {
  return {
    type: CHANGE_COLUMNS_TO_DISPLAY,
    payload: {
      columnsToDisplay,
    },
  };
};
