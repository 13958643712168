export const GET_BENCH_REPORTS = '@reports/GET_BENCH_REPORTS';
export const SET_BENCH_REPORTS = '@reports/SET_BENCH_REPORTS';

export const getBenchReports = () => ({
  type: GET_BENCH_REPORTS,
});

export const setBenchReports = (reports) => ({
  type: SET_BENCH_REPORTS,
  payload: reports,
});
