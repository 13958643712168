import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import DatePicker from 'common/components/form-items/date-picker/date-picker';

export class CellDatePicker extends Component {
  constructor(props) {
    super(props);
    const {
      item, column, isEditingMode, isControlledByListItem,
    } = this.props;
    this.state = {
      value: item[column.Id],
      isDatePicker: isControlledByListItem && isEditingMode,
    };
  }

  toggleViewCell = () => {
    const { isEditingMode, isControlledByListItem } = this.props;
    this.setState(({ isDatePicker }) => ({
      isDatePicker: isControlledByListItem ? isEditingMode : !isDatePicker,
    }));
  }

  handleChange = (date) => {
    this.setState({
      value: date ? moment(date).format('MM-DD-YYYY') : null,
    }, this.onBlur);
  }

  onBlur = () => {
    const {
      column, config, item, isControlledByListItem,
    } = this.props;
    const { value } = this.state;
    const { valueSetter, editingValueSetter } = config[column.Id];
    const newItem = isControlledByListItem ? {
      [column.Id]: value,
    } : {
      ...item,
      [column.Id]: value,
    };
    valueSetter && valueSetter(newItem);
    this.toggleViewCell();
    if (isControlledByListItem) {
      editingValueSetter && editingValueSetter(newItem, item);
    }
  }

  render() {
    const {
      column,
      config,
      item,
      isHaveRights,
      isEditingMode,
    } = this.props;
    const {
      value,
      isDatePicker,
    } = this.state;
    const columnConfig = config[column.Id];
    const format = columnConfig.format || 'MM-DD';
    const columnClassNames = column.className ? column.className : 'small-col';
    const formClassName = `${columnConfig.formClassName ? columnConfig.formClassName : ''} change-border__input-cell  container_datepicker`;
    return (
      <div
        className={classNames('c-list__item-column', 'c-list__item-column--datepicker', columnClassNames, { 'fixed-width': isEditingMode })}
      >
        {
          isDatePicker && isHaveRights ? (
            <DatePicker
              formClassName={formClassName}
              datePickerClassName='cell-container_datepicker'
              placeholder='Choose Date'
              value={value}
              onChange={this.handleChange}
            />
          ) : (
            <span
              className='c-list-item-datepicker--input'
              onClick={this.toggleViewCell}
            >
              {item[column.Id] ? moment(item[column.Id]).format(format) : null}
            </span>
          )
        }
      </div>
    );
  }
}

CellDatePicker.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
