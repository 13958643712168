import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListItem from './list-item';
import { NO_ITEMS_TITLE } from 'utils/const-variable';
import { ListColumns } from './list-columns';
import isObjectNotEquals from 'utils/object-comparison';
import './list.css';

export class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsideComponent: false,
      dataForInsideComponent: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (isObjectNotEquals(nextProps, this.props) || isObjectNotEquals(nextState, this.state));
  }

  toogleInsideComponent = () => {
    const { showInsideComponent } = this.state;
    this.setState({
      showInsideComponent: !showInsideComponent,
    });
  }

  getDataForInsideComponent = (data) => {
    this.setState({
      dataForInsideComponent: data,
    }, () => this.toogleInsideComponent());
  }

  setSortSettings = (sortColumn, sortDirection) => () => {
    const { setSortSettings } = this.props;
    setSortSettings && setSortSettings(sortColumn, sortDirection);
  }

  render() {
    const {
      items,
      dropdownList,
      columnsToDisplay,
      sortColumnName,
      sortDirection,
      isInnerList,
      onItemClick,
      onEditClick,
      onDeleteClick,
      onAddClick,
      onCopyClick,
      editSpan,
      deleteSpan,
      hideHeader,
      hideDots,
      showCheckboxes,
      checkRole,
      parentId,
      deleteConfig,
      parentDropdownId,
      getFullEditItem,
      timeLeft,
      dontShowOptions,
      viewFinancialInfo,
      dontShowNoItemBlock,
      transition,
      isHaveRights,
      columnsHeaders,
      fixedHeader,
      config,
      hideAddNewEntity,
      hideEntityEdit,
      hideDeleteEntity,
      hideCopyEntity,
      defaultOpenDropDown,
      renderAdditionalComponent,
      parentDropDown,
      dontUseDeleteConfig,
      highOrderConfig,
      isSowOrPO,
      SOWExtensionOptions,
      renderCustomEntity,
      needToAddNewEntity,
      titleForAddingEntity,
      hasEditableFields,
      onRenderCustomEntityClick,
      isProjectedRevenueList,
      isAccountingList,
      generateInnerColumnsToDisplay,
      onClickCheckbox,
      getCustomUrlForDelete,
      onImportFile,
      headerWrapper,
      contentWrapper,
      onEditingModeToggle,
      checkIfItemIsDeletable,
      isIndividualDeletable = false,
      shouldUpdate = false,
      shiftOnEditClassName = '',
    } = this.props;

    const { showInsideComponent, dataForInsideComponent } = this.state;

    const DefaultWrapper = ((props) => props.children);
    const HeaderWrapper = headerWrapper || DefaultWrapper;
    const ContentWrapper = contentWrapper || DefaultWrapper;

    if (shouldUpdate) {
      this.forceUpdate();
    }

    return (
      <div className={classNames('list-with-horizontal-track', {
        'inner-list': isInnerList && items && items.length,
        'page-container_for-projected-revenue': isProjectedRevenueList,
        'page-container_for-accounting': isAccountingList,
      })}
      >
        {showInsideComponent ? renderAdditionalComponent(dataForInsideComponent, this.toogleInsideComponent) : null}
        <HeaderWrapper>
          {columnsHeaders && <div className={classNames('c-list__header hide_header-border')}>
            {columnsHeaders.map((item, i) => {
              let headerWidth;
              columnsToDisplay.forEach(column => {
                if (column.connectedHeader && column.connectedHeader === item.Id) {
                  return headerWidth = item.width;
                }
                return false;
              });
              const style = {
                width: `${headerWidth}px`,
                maxWidth: `${headerWidth}px`,
              };
              return (
                <div
                  className={classNames('header_col', item.className, item.needShowBorder)}
                  style={style}
                  key={i}
                >
                  {item.Name}
                </div>
              );
            })}
          </div>}
          <div className={classNames({ 'fixed-wrapper_c-list__header': fixedHeader })}>
            {items && items.length
              ? (
                !hideHeader && (
                  <div className={classNames('c-list__header')}>
                    {showCheckboxes && <div className='c-list__column tiny-col' />}
                    {!!dropdownList && <div className='c-list__column tiny-col' />}
                    <ListColumns
                      columnsToDisplay={columnsToDisplay}
                      setSortSettings={this.setSortSettings}
                      viewFinancialInfo={viewFinancialInfo}
                      sortColumnName={sortColumnName}
                      sortDirection={sortDirection}
                    />
                    {!dontShowOptions && <div className={classNames(
                      { 'list-item--actions': hasEditableFields },
                      'c-list__options-column',
                      shiftOnEditClassName,
                    )}
                    >
                      Action
                    </div>}
                  </div>
                )
              )
              : dontShowNoItemBlock ? null : <div className='list__no-item--border'>{NO_ITEMS_TITLE}</div>}
          </div>
        </HeaderWrapper>
        <ContentWrapper>
          <div className={classNames({ 'fixed-wrapper_c-list__content': fixedHeader })}>
            {
              items && !!items.length && items.map((item, i) => {
                let shadow = false;
                if (item.Role === 'Shadow') {
                  shadow = true;
                }
                return (
                  <ListItem
                    key={i}
                    item={item}
                    dropdownList={dropdownList}
                    columnsToDisplay={columnsToDisplay}
                    idItem={i}
                    onItemClick={onItemClick}
                    onEditClick={onEditClick}
                    hasEditableFields={hasEditableFields}
                    onDeleteClick={onDeleteClick}
                    deleteConfig={item.Type && item.Type.Name === 'MSA' ? deleteConfig.msa : item.POs ? deleteConfig.sow : deleteConfig}
                    onAddClick={onAddClick}
                    onCopyClick={onCopyClick}
                    shadow={shadow}
                    editSpan={editSpan}
                    showDeleteSpan={deleteSpan}
                    hideDots={hideDots}
                    showCheckboxes={showCheckboxes}
                    checkRole={checkRole}
                    parentId={parentId}
                    parentDropdownId={parentDropdownId}
                    getFullEditItem={getFullEditItem}
                    timeLeft={timeLeft}
                    transition={transition}
                    isHaveRights={isHaveRights}
                    getDataForInsideComponent={this.getDataForInsideComponent}
                    viewFinancialInfo={viewFinancialInfo}
                    config={config}
                    hideAddNewEntity={item.Type && item.Type.Name === 'MSA' ? true : hideAddNewEntity}
                    hideEntityEdit={hideEntityEdit}
                    hideDeleteEntity={isIndividualDeletable && checkIfItemIsDeletable && !checkIfItemIsDeletable(item) ? true : hideDeleteEntity}
                    hideCopyEntity={hideCopyEntity}
                    defaultOpenDropDown={defaultOpenDropDown}
                    parentDropDown={parentDropDown}
                    dontUseDeleteConfig={dontUseDeleteConfig}
                    highOrderConfig={highOrderConfig}
                    SOWExtensionOptions={SOWExtensionOptions}
                    isSowOrPO={item.Type && item.Type.Name === 'MSA' ? false : isSowOrPO}
                    renderCustomEntity={item.Type && item.Type.Name === 'MSA' ? null : renderCustomEntity}
                    needToAddNewEntity={needToAddNewEntity}
                    titleForAddingEntity={titleForAddingEntity}
                    onRenderCustomEntityClick={onRenderCustomEntityClick}
                    generateInnerColumnsToDisplay={generateInnerColumnsToDisplay}
                    onClickCheckbox={onClickCheckbox}
                    getCustomUrlForDelete={getCustomUrlForDelete}
                    onImportFile={onImportFile}
                    onEditingModeToggle={onEditingModeToggle}
                    shiftOnEditClassName={shiftOnEditClassName}
                  />
                );
              })
            }
          </div>
        </ContentWrapper>
      </div>
    );
  }
}

List.propTypes = {
  items: PropTypes.array,
  columnsToDisplay: PropTypes.array,
  dropdownList: PropTypes.object,
  sortColumnName: PropTypes.string,
  sortDirection: PropTypes.string,
  titleForAddingEntity: PropTypes.string,
  isInnerList: PropTypes.bool,
  viewFinancialInfo: PropTypes.bool,
  hasEditableFields: PropTypes.bool,
  onItemClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onAddClick: PropTypes.func,
  setSortSettings: PropTypes.func,
  onDeleteClick: PropTypes.func,
  renderAdditionalComponent: PropTypes.func,
  onRenderCustomEntityClick: PropTypes.func,
};
