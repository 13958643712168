import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';
import {
  GET_ASSIGNMENT_TYPES,
  PUT_ASSIGNMENT_TYPES,
  POST_ASSIGNMENT_TYPES,
  setAssignmentTypes,
} from '../actions/adminAssignmentTypesAction';
import { startRequest, finishRequest } from 'common/actions/loadingActions';

export function* manageAssignmentTypes() {
  try {
    yield put(startRequest());
    const assignmentTypes = yield call(http, {
      method: 'GET',
      url: 'assignment_types',
    });

    yield put(setAssignmentTypes(assignmentTypes.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* updateAssignmentTypes(action) {
  try {
    yield call(http, {
      method: 'PUT',
      url: `assignment_types/${action.payload.Id}`,
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageAssignmentTypes);
  } catch (e) {
    // error message
  }
}

export function* createAssignmentTypes(action) {
  try {
    yield call(http, {
      method: 'POST',
      url: 'assignment_types',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(manageAssignmentTypes);
  } catch (e) {
    // error message
  }
}

export default function* adminManageAssignmentTypes() {
  yield takeEvery(GET_ASSIGNMENT_TYPES, manageAssignmentTypes);
  yield takeEvery(PUT_ASSIGNMENT_TYPES, updateAssignmentTypes);
  yield takeEvery(POST_ASSIGNMENT_TYPES, createAssignmentTypes);
}
