import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class CellCheckbox extends Component {
  onClickCheckbox = (e) => {
    const { onClickCheckbox, parentId } = this.props;
    onClickCheckbox(e.target.value, parentId);
  }

  render() {
    const {
      item,
    } = this.props;
    return (
      <div className='c-list__item-column checkbox_width'>
        <label className='checkbox-cont-list-item configuration-option check_role'>
          <input
            fasdf={Math.random()}
            key={Math.random()}
            item={JSON.stringify(item)}
            type='checkbox'
            value={item.Id}
            onClick={this.onClickCheckbox}
            checked={!!item.checked}
          />
          <span className='configuration-option__checkbox' />
        </label>
      </div>
    );
  }
}

CellCheckbox.propTypes = {
  item: PropTypes.object.isRequired,
};
