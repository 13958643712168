import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import http from 'services/http';
import history from 'utils/history';
import {
  GET_ACCOUNTING_REPORTS, setAccountingReports,
  setAccountCount,
  ACCOUNTING_TRANSITION_TO_COMPANY,
  ACCOUNTING_TRANSITION_TO_PROJECT,
  ACCOUNTING_TRANSITION_TO_EMPLOYEE,
} from '../actions/accountingActions';
import { startRequest, finishRequest } from 'common/actions/loadingActions';
import { getClientById } from 'pages/clients/actions/clientsActions';
import { transitionToEditProject } from '../../members/sagas/membersSaga';
import { getEmployeeDetails } from 'pages/members/actions/membersActions';

function* getAccountingReports(action) {
  try {
    const {
      take,
      skip,
      sortDirection,
      column,
      filters,
    } = action.payload;
    yield put(startRequest());
    const search = action.payload && action.payload.searchvalue;
    const orderByDesc = sortDirection === 'Up' ? false : true;
    const accountingReports = yield call(http, {
      method: 'GET',
      url: `accounting-reports?Search=${encodeURIComponent(search)}&skip=${skip}&take=${take}&SortField=${column}&orderByDesc=${orderByDesc}`,
      params: filters,
    });
    yield put(setAccountingReports(accountingReports.data.Records));
    yield put(setAccountCount(accountingReports.data.TotalCount));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

function* transitionToCompany(action) {
  yield put(startRequest());
  yield getClientById(action);
  const { payload } = action;
  history.push({
    pathname: `/clients/${payload.Id}`,
    state: {
      filters: {},
      search: '',
    },
  });
  yield put(finishRequest());
}

function* transitionToProject(action) {
  yield put(startRequest());
  yield transitionToEditProject(action);
  yield put(finishRequest());
}

function* transitionToEmployee(action) {
  const { payload } = action;
  yield put(startRequest());
  yield getEmployeeDetails(payload.id);
  history.push({
    pathname: `/employees/${payload.id}`,
    state: {
      filters: {},
      search: '',
    },
  });
  yield put(finishRequest());
}

export default function* reportsAccountingSaga() {
  yield takeEvery(GET_ACCOUNTING_REPORTS, getAccountingReports);
  yield takeEvery(ACCOUNTING_TRANSITION_TO_COMPANY, transitionToCompany);
  yield takeEvery(ACCOUNTING_TRANSITION_TO_PROJECT, transitionToProject);
  yield takeEvery(ACCOUNTING_TRANSITION_TO_EMPLOYEE, transitionToEmployee);
}
