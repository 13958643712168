import { call, put, takeEvery } from 'redux-saga/effects';
import http from 'services/http';

import {
  setManagesBusinessTrips,
  GET_MANAGE_BUSINESS_TRIPS,
  CREATE_NEW_BUSINESS_TRIP,
  UPDATE_BUSINESS_TRIP,
} from '../actions/adminManageBisunessTrips';

import {
  startRequest,
  finishRequest,
} from 'common/actions/loadingActions';

export function* getManageBusinessTrips() {
  try {
    yield put(startRequest());
    const businessTrips = yield call(http, {
      method: 'GET',
      url: 'business_trips',
    });

    yield put(setManagesBusinessTrips(businessTrips.data));
    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequest());
  }
}

export function* addBusinessTrips(action) {
  try {
    const data = action.payload;
    yield call(http, {
      method: 'POST',
      url: 'business_trips',
      data,
    });
    yield call(getManageBusinessTrips);
  } catch (e) {
    // error message
  }
}

export function* putBusinessTrips(action) {
  yield call(http, {
    method: 'PUT',
    url: `business_trips/${action.payload.Id}`,
    data: { Name: action.payload.Name },
  });
  yield call(getManageBusinessTrips);
}

export default function* adminManageBusinessTrips() {
  yield takeEvery(GET_MANAGE_BUSINESS_TRIPS, getManageBusinessTrips);
  yield takeEvery(CREATE_NEW_BUSINESS_TRIP, addBusinessTrips);
  yield takeEvery(UPDATE_BUSINESS_TRIP, putBusinessTrips);
}
