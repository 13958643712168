export const GET_MANAGE_SENIORITY_LEVELS = '@admin/GET_MANAGE_SENIORITY_LEVELS';
export const SET_MANAGE_SENIORITY_LEVELS = '@admin/SET_MANAGE_SENIORITY_LEVELS';
export const CREATE_NEW_SENIORITY_LEVEL = '@admin/CREATE_NEW_SENIORITY_LEVEL';
export const UPDATE_SENIORITY_LEVEL = '@admin/UPDATE_SENIORITY_LEVEL';
export const DELETE_SENIORITY_LEVEL = '@admin/DELETE_SENIORITY_LEVEL';
export const UPDATE_ORDER_SENIORITY_LEVELS = '@admin/UPDATE_ORDER_SENIORITY_LEVELS';

export const getManageSeniorityLevels = (data) => ({
  type: GET_MANAGE_SENIORITY_LEVELS,
  payload: data,
});

export const setManagesSeniorityLevels = (SeniorityLevels) => ({
  type: SET_MANAGE_SENIORITY_LEVELS,
  payload: SeniorityLevels,
});

export const createNewSeniorityLevels = (data) => {
  return {
    type: CREATE_NEW_SENIORITY_LEVEL,
    payload: data,
  };
};

export const updateOrderSeniorityLevels = (data) => {
  return {
    type: UPDATE_ORDER_SENIORITY_LEVELS,
    payload: data,
  };
};

export const updateSeniorityLevels = (Name, Id) => {
  return {
    type: UPDATE_SENIORITY_LEVEL,
    payload: {
      Name,
      Id,
    },
  };
};

export const deleteSeniorityLevels = (id) => {
  return {
    type: DELETE_SENIORITY_LEVEL,
    payload: {
      seniorityLevelId: id,
    },
  };
};
