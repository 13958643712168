import React from 'react';
import PropTypes from 'prop-types';
import CustomIcon from 'common/components/icon/Icon';
import '../redux-form.css';

export default class renderInputFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {
        name: '',
        file: null,
      },
      isFullDataForSend: false,
    };
  }

  send = (uploadedFile) => {
    const { postFile } = this.props;
    const { isFullDataForSend } = this.state;
    if (isFullDataForSend) {
      postFile(uploadedFile);
    }
  }

  onChangeFile = (event) => {
    const { changeEditForm } = this.props;
    const { file } = this.state;
    this.setState({
      file: {
        ...file,
        [event.target.name]: event.target.files[0],
        name: event.target.files[0].name,
      },
      isFullDataForSend: true,
    }, () => {
      const { file } = this.state;
      const { postFile } = this.props;
      changeEditForm && changeEditForm(event, file, postFile, 'files');
    });
  }

  onFormSubmit = (event) => {
    const { handleChangeForm } = this.props;
    const { file } = this.state;
    event.preventDefault();
    if (handleChangeForm) {
      handleChangeForm(file);
    } else {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('name', file.name);
      this.send(formData);
    }
    this.resetFile();
  }

  resetFile = () => {
    const file = document.getElementById('file');
    file.value = '';
    this.setState({
      file: {
        name: '',
        file: null,
      },
      isFullDataForSend: false,
    });
  }

  render() {
    const {
      error, accept, errorTitle, formClassName, label, styles, showAsterix, postFile,
    } = this.props;
    // const isDisabledForm = isDisabled !== undefined ? isDisabled || !isFullDataForSend : !isFullDataForSend;
    return (
      <form onSubmit={this.onFormSubmit} className={formClassName}>
        {
          label && <label className={styles.label}>
            <span>{label}</span>
            {
              showAsterix && <span className='redux-form__required'> *</span>
            }
          </label>
        }
        <div className='cel-file-icon'>
          <input
            type='file'
            onChange={this.onChangeFile}
            id='file'
            name='file'
            accept={accept}
            className='input_type-file'
          />
          <label htmlFor='file' className='btn btn-tertiary js-labelFile'>
            <i className='icon fa fa-check' />
            <span className='js-fileName'>Upload File</span>
          </label>
          {
            (typeof postFile !== 'undefined' && !!postFile.length)
              && postFile.map(item => <CustomIcon iconName='big-files' className='cel-file-icon-style' title={item.name} />)
          }
        </div>
        {error && <span className='error'>{errorTitle ? errorTitle : 'Request entity too large'}</span>}
      </form>
    );
  }
}

renderInputFile.propTypes = {
  postFile: PropTypes.func.isRequired,
  error: PropTypes.bool,
  accept: PropTypes.string.isRequired,
};
