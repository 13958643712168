import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { resetListSettings } from './actions/contractsActions';
import ContractsList from './components/contracts-list/contracts-list';

export class ContractsPage extends Component {
  componentWillUnmount() {
    const { resetListSettings } = this.props;
    resetListSettings();
  }

  ContractsList = (props) => {
    const { ManageProjectContracts, HasFinancialAccess } = this.props;
    return (
      <ContractsList
        {...props}
        ManageProjectContracts={ManageProjectContracts}
        HasFinancialAccess={HasFinancialAccess}
      />
    );
  };

  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path='/contracts' render={this.ContractsList} />
          <Redirect to='/contracts' />
        </Switch>
      </Fragment>
    );
  }
}

export default connect(() => ({}), {
  resetListSettings,
})(ContractsPage);
