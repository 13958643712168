import React, { Component } from 'react';
import classNames from 'classnames';

export class CellLabel extends Component {
  render() {
    const {
      item,
      column,
    } = this.props;
    return (
      <div
        className={classNames('c-list__item-column c-skill__item', column.className ? column.className : 'small-col')}
      >
        <span
          className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
        >
          {item ? item[column.Id] : null}
        </span>

      </div>
    );
  }
}
