import * as React from 'react';
import classNames from 'classnames';
import MilestoneItem from './milestone-item';
import { NO_ITEMS_TITLE } from 'utils/const-variable';
import './milestone-list.css';

export class MilestoneList extends React.Component {
  render() {
    const {
      items,
      columnsToDisplay,
      isCheckboxShown,
      onChange,
      selectedMilestones,
      onDeleteItem,
      onCopyItem,
      disabled,
      onTogglingShowOverride,
      selectOptionsForMilestones,
      currencyProject,
    } = this.props;
    return (
      <div>
        <div className={classNames('c-list__header')}>
          {
            columnsToDisplay && columnsToDisplay.map((column) => (<div key={column.Id} className={classNames('c-list__header-col', column.className)}>
              {isCheckboxShown ? <div className='c-list__header-col smallest-col' /> : null}
              <div className='c-list__header-name c-list__header-name--no-arrows'>
                {column.breakline ? (
                  <span>
                    {column.Name.split(' ')[0]}
                    <br />
                    {column.Name.split(' ')[1]}
                  </span>
                ) : (
                  <span>
                    {column.Name}
                  </span>
                )}
                {
                  column.isRequired ? (
                    <span className='redux-form__required'> *</span>
                  ) : null
                }
              </div>
            </div>))
          }
          <span className='milestones-action-label action-lable action-lable-document-margin'>Action</span>
        </div>
        {
          items && items.length
            ? items && items.length && items.map((item, i) => (
              <MilestoneItem
                key={i}
                itemId={i}
                item={item}
                onChange={onChange}
                onDeleteItem={onDeleteItem}
                selectOptionsForMilestones={selectOptionsForMilestones}
                selectedMilestones={selectedMilestones}
                onCopyItem={onCopyItem}
                disabled={disabled}
                onTogglingShowOverride={onTogglingShowOverride}
                currencyProject={currencyProject}
                columnsToDisplay={columnsToDisplay}
              />)) : <div className='document-list__no-item'>{NO_ITEMS_TITLE}</div>
        }
      </div>
    );
  }
}

MilestoneList.propTypes = {
};

export default MilestoneList;
