import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class CellSimpleCheckbox extends Component {
  render() {
    const {
      item, column,
    } = this.props;
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        <label className='checkbox-cont-list-item configuration-option check_role'>
          <input
            type='checkbox'
            defaultChecked={item[column.Id]}
            disabled
          />
          <span className='configuration-option__checkbox' />
        </label>
      </div>
    );
  }
}

CellSimpleCheckbox.propTypes = {
  item: PropTypes.object.isRequired,
};
