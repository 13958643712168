export const GET_MANAGE_API_TOKENS = '@admin/GET_MANAGE_API_TOKENS';
export const SET_MANAGE_API_TOKENS = '@admin/SET_MANAGE_API_TOKENS';
export const PUT_MANAGE_API_TOKENS = '@admin/PUT_MANAGE_API_TOKENS';
export const POST_MANAGE_API_TOKENS = '@admin/POST_MANAGE_API_TOKENS';

export const downloadManageApiTokens = () => ({
  type: GET_MANAGE_API_TOKENS,
});

export const setManageApiTokens = (tokens) => ({
  type: SET_MANAGE_API_TOKENS,
  payload: tokens,
});

export const putManageApiTokens = (token) => ({
  type: PUT_MANAGE_API_TOKENS,
  payload: token,
});

export const postManageApiTokens = (newToken) => ({
  type: POST_MANAGE_API_TOKENS,
  payload: newToken,
});
