import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'common/components/form-items/checkbox/checkbox';

export class CellSwitcher extends Component {
  render() {
    const { column, item } = this.props;
    const checked = item[column.Id];
    return (
      <div
        className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}
      >
        <Checkbox
          checked={checked}
          disabled
        />
      </div>
    );
  }
}

CellSwitcher.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
