export const GET_MANAGE_INVOICE_DATES = '@admin/GET_MANAGE_INVOICE_DATES';
export const SET_MANAGE_INVOICE_DATES = '@admin/SET_MANAGE_INVOICE_DATES';
export const CREATE_NEW_INVOICE_DATE = '@admin/CREATE_NEW_INVOICE_DATE';
export const UPDATE_INVOICE_DATE = '@admin/UPDATE_INVOICE_DATE';
export const DELETE_INVOICE_DATE = '@admin/DELETE_INVOICE_DATE';

export const getManageInvoiceDates = () => ({
  type: GET_MANAGE_INVOICE_DATES,
});

export const setManagesInvoiceDates = (invoiceDate) => ({
  type: SET_MANAGE_INVOICE_DATES,
  payload: invoiceDate,
});

export const createNewInvoiceDate = (data) => {
  return {
    type: CREATE_NEW_INVOICE_DATE,
    payload: data,
  };
};

export const updateInvoiceDate = (Name, Id) => {
  return {
    type: UPDATE_INVOICE_DATE,
    payload: {
      Name,
      Id,
    },
  };
};

export const deleteInvoiceDate = (id) => {
  return {
    type: DELETE_INVOICE_DATE,
    payload: {
      invoiceDateId: id,
    },
  };
};
