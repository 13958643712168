import React from 'react';
import classNames from 'classnames';
import TextField from 'common/components/form-items/input/text-field';
import Select from 'common/components/form-items/select/select';

export class UpdateDocumentItem extends React.Component {
  render() {
    const {
      item,
      onChangeNewDocumentObj,
      disableSaveDocument,
      newDocumentCancel,
      newDocumentApply,
      isDocumentType,
      documentSelectOptions,
    } = this.props;
    return (
      <div className='document-item__container'>
        <TextField
          formClassName={classNames('step__form step__form--document-block', { 'step__form--document-block-no-select': !isDocumentType })}
          id='link'
          placeholder='Add Link'
          maxlength='4000'
          name='URL'
          value={item && item.URL}
          onChange={onChangeNewDocumentObj}
          autoFocus
          showClear={false}
        />
        <TextField
          formClassName={classNames('step__form step__form--document-block', { 'step__form--document-block-no-select': !isDocumentType })}
          id='description'
          placeholder='Add Description'
          maxlength='100'
          name='Comment'
          value={item && item.Comment}
          onChange={onChangeNewDocumentObj}
          showClear={false}
        />
        {isDocumentType
          && <Select
            formClassName='step__form step__form--document-block--select'
            value={item && item.DocumentType && item.DocumentType.Id}
            onChange={onChangeNewDocumentObj}
            selectOptions={documentSelectOptions}
            isOptionObject
            placeholder='Choose Document Type'
            showClear={false}
            inputProps={{
              name: 'DocumentType',
              id: 'DocumentType',
            }}
          />}
        <div className='toolbar-button document-item__buttom-cancel' onClick={newDocumentCancel}>
          <span>Cancel</span>
        </div>
        <div
          className={classNames('button next document-item__buttom-apply',
            { 'btn-disabled': !disableSaveDocument })}
          onClick={newDocumentApply}
        >
          <span>Apply</span>
        </div>
      </div>
    );
  }
}
