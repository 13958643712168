import {
  SET_SOW_LIST_SETTINGS,
  SET_SOW_LIST_FILTERS_VALUE,
  RESET_SOW_LIST_SETTINGS,
  RESET_SOW_LIST_FILTERS_VALUE,
} from '../actions/sowActions';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'DaysToExpire',
  sortDirection: 'Up',
  filters: {},
};

const defaultTextDataForFilters = {
  EndDate: '',
  DaysToExpire: '',
  Contract: '',
  Comment: '',
  RoleName: '',
  Employee: '',
  Email: '',
  Rate: '',
  Availability: '',
  AssignmentComment: '',
};

const initialState = {
  listSettings: defaultListSettings,
  textDataForFilters: defaultTextDataForFilters,
};

const sowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOW_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_SOW_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_SOW_LIST_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: {
          ...state.textDataForFilters,
          ...action.payload,
        },
      };
    case RESET_SOW_LIST_FILTERS_VALUE:
      return {
        ...state,
        textDataForFilters: defaultTextDataForFilters,
      };
    default:
      return state;
  }
};

export default sowReducer;
