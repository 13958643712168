import React from 'react';
import CustomIcon from 'common/components/icon/Icon';
import classNames from 'classnames';


export const CellDelete = (props) => {
  const { item, column, onDeleteClick } = props;
  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteClick(item.Id);
  };

  return (<div
    className={classNames('c-list__item-column', { 'small-col': column.className })}
    onClick={handleDelete}
  >
    <CustomIcon
      iconName='trash'
      title='Delete'
    />
  </div>);
};
