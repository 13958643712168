import * as React from 'react';
import MultiSelect from './multi-select';
import isObjectNotEquals from 'utils/object-comparison';

const allOption = { Id: 'all', Name: 'All' };

class MultiSelectAll extends React.Component {
  onChange = (Id) => {
    const { onChange, selected, options = [] } = this.props;
    const allCheckedOptions = options.map(option => option.Id).sort();
    if (Id === allOption.Id) {
      const checkedOptions = isObjectNotEquals([...selected].sort(), allCheckedOptions) ? allCheckedOptions : [];
      onChange(checkedOptions);
      return;
    }
    const value = selected.includes(Id)
      ? selected.filter((option => option !== Id))
      : [...selected, Id];

    onChange(value);
  }

  render() {
    const { options, selected } = this.props;
    const checkedOptions = isObjectNotEquals(
      [...selected].sort(),
      options.map(option => option.Id).sort(),
    ) ? selected : [allOption.Id, ...selected];

    const newOptions = [allOption, ...options];

    return <MultiSelect
      {...this.props}
      onChange={this.onChange}
      options={newOptions}
      selected={checkedOptions}
    />;
  }
}

export default MultiSelectAll;
