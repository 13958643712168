import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { loadAssignmentTypes, createAssignmentTypes, updateAssignmentTypes } from 'pages/admin/actions/adminAssignmentTypesAction';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateManageItem } from 'common/components/documents-list/update-manage-item';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { showDialog } from 'common/actions/confirmationDialogActions';
import { sortList } from 'common/components/list/sortList';
import { recursiveSearch } from 'services/recursiveSearch';
import { setListSettingsLookups, resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageAssignmentTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Assignment Types', isSelected: true, className: 'without-width-col', Id: 'Name',
        },
      ],
      showAddTypesFields: false,
      addAssignmentType: { Name: '' },
      blockAddAssignmentType: false,
      blockSaveTypesType: false,
    };
  }

  componentDidMount() {
    const { loadManageAssignmentTypes } = this.props;
    loadManageAssignmentTypes();
  }

  addAssignmentType = () => {
    const { blockAddAssignmentType } = this.state;
    if (!blockAddAssignmentType) {
      this.setState({
        showAddTypesFields: true,
      });
    }
  }

  getNewTypeObj = ({ target }) => {
    const { addAssignmentType } = this.state;
    this.setState({
      addAssignmentType: {
        ...addAssignmentType,
        [target.name]: target.value,
      },
    }, this.newTypeValidation);
  }

  newTypeValidation = () => {
    const { addAssignmentType } = this.state;
    this.setState({
      blockSaveTypesType: !!addAssignmentType.Name.trim(),
      blockAddAssignmentType: !!addAssignmentType.Name.trim(),
    });
  };

  newAssignmentTypesCancel = () => {
    this.setState({
      addAssignmentType: { Name: '' },
      showAddTypesFields: false,
      blockSaveTypesType: false,
      blockAddAssignmentType: false,
    });
  }

  newAssignmentTypeApply = () => {
    const { createAssignmentTypes } = this.props;
    const { blockSaveTypesType, addAssignmentType } = this.state;
    if (blockSaveTypesType) {
      createAssignmentTypes(addAssignmentType);
      this.setState({
        addAssignmentType: { Name: '' },
        showAddTypesFields: false,
        blockSaveTypesType: false,
        blockAddAssignmentType: false,
      });
    }
  }


  deleteAssignmentTypes = (data) => {
    const { showDialog } = this.props;
    const dataForDeleting = {
      url: `assignment_types/${data.Id}`,
      searchUpdateSaga: 'assignment_types',
    };
    showDialog(dataForDeleting);
  }

  editEntity = (data) => {
    const { updateAssignmentTypes } = this.props;
    const { Name, Id } = data;
    updateAssignmentTypes({ Name, Id });
  }

  handleSearch = (searchValue) => {
    const { setListSettingsLookups } = this.props;
    setListSettingsLookups({ searchValue });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettingsLookups } = this.props;
    const newSort = {
      sortColumn,
      sortDirection,
    };
    setListSettingsLookups(newSort);
  };

  render() {
    const { assignmentTypes, listSettings } = this.props;
    const {
      columnsToDisplay, showAddTypesFields, addAssignmentType, blockAddAssignmentType,
    } = this.state;
    const { searchValue, sortColumn, sortDirection } = listSettings || {};
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('lookupsAssignmentTypes')} />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addAssignmentType}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search assignment types'
          searchValue={searchValue}
        />
        <DocumentList
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(sortList(assignmentTypes, sortColumn, sortDirection), searchValue)}
          actionLabel
          onEditClick={this.editEntity}
          isEditable
          editManageItem
          onDeleteClick={this.deleteAssignmentTypes}
          showItemsEditMenu
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          sortDirection={sortDirection}
        />
        {
          showAddTypesFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <UpdateManageItem
                item={addAssignmentType}
                onChangeNewDocumentObj={this.getNewTypeObj}
                disableSaveDocument={blockAddAssignmentType}
                newDocumentCancel={this.newAssignmentTypesCancel}
                newDocumentApply={this.newAssignmentTypeApply}
              />
            </div>
          </div> : null
        }
      </div>
    );
  }
}

ManageAssignmentTypes.propTypes = {
  loadManageAssignmentTypes: PropTypes.func,
  createAssignmentTypes: PropTypes.func,
  updateAssignmentTypes: PropTypes.func,
  showDialog: PropTypes.func,
};

export default connect((store) => ({
  assignmentTypes: store.adminManageLookups.assignmentTypes,
  listSettings: store.adminManageLookups.listSettings,
}), {
  loadManageAssignmentTypes: loadAssignmentTypes,
  createAssignmentTypes,
  updateAssignmentTypes,
  showDialog,
  setListSettingsLookups,
  resetListSettingsLookups,
})(ManageAssignmentTypes);
