import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from 'common/components/form-items/select/select';
import TextField from 'common/components/form-items/input/text-field';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import Button from 'common/components/redux-form/components/button';
import CustomIcon from 'common/components/icon/Icon';

class CertificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CertificationTypeId: '',
      CertificationSpec: '',
      CertificateDate: null,
      ExpirationDate: null,
      CertificationRequesterId: null,
      CertificationLink: '',
      isErrorForm: true,
    };
  }

  componentDidMount() {
    this.setDefaultEditData();
  }

  componentDidUpdate(prevProps) {
    const { editCertificateObject } = this.props;
    if (prevProps.editCertificateObject !== editCertificateObject) {
      this.setDefaultEditData();
    }
  }

  setDefaultEditData = () => {
    const { editCertificateObject, isEditMode } = this.props;
    if (isEditMode) {
      this.setState({
        CertificationTypeId: editCertificateObject.Title.Id,
        CertificationSpec: editCertificateObject.CertificationSpec,
        CertificateDate: editCertificateObject.CertificateDate,
        ExpirationDate: editCertificateObject.ExpirationDate,
        CertificationRequesterId: editCertificateObject.CertificationRequester ? editCertificateObject.CertificationRequester.Id : null,
        CertificationLink: editCertificateObject.CertificationLink,
      }, this.checkForm);
    }
  }

  checkForm = () => {
    const {
      CertificationTypeId, CertificationSpec, CertificateDate, ExpirationDate,
    } = this.state;
    const checkDate = moment(moment(CertificateDate).add('day', -1)).isBefore(moment(ExpirationDate));
    this.setState({
      isErrorForm: !(CertificationTypeId !== '' && CertificationSpec.length && CertificateDate !== null && (ExpirationDate === null || checkDate)),
    });
  }

  confirmAddCertificate = () => {
    const { confirmDialog, closeDialog, isEditMode } = this.props;
    const {
      isErrorForm, CertificationTypeId, CertificationSpec, CertificateDate, ExpirationDate, CertificationRequesterId, CertificationLink,
    } = this.state;
    if (!isErrorForm) {
      confirmDialog({
        CertificationTypeId, CertificationSpec, CertificateDate, ExpirationDate, CertificationRequesterId, CertificationLink,
      }, isEditMode);
      closeDialog();
    }
  }

  handleChangeData = (e) => {
    if (e.target.name === 'CertificationTypeId') {
      const { certificatesOptions } = this.props;
      this.setState({
        CertificationTypeId: certificatesOptions.find(item => item.Id === e.target.value).Id,
      }, this.checkForm);
      return;
    }
    this.setState({
      [e.target.name]: e.target.value,
    }, this.checkForm);
  }

  handleChangeDate = (date, name) => {
    if (name) {
      if (name === 'CertificateDate' && date === null) {
        this.setState({
          ExpirationDate: null,
        });
      }
      this.setState({
        [name]: date,
      }, this.checkForm);
    }
  }

  setIsError = () => {
    this.setState({ isErrorForm: true });
  }

  clearCertificationRequesterId = () => {
    this.setState({ CertificationRequesterId: null });
  }

  render() {
    const {
      closeDialog, certificatesOptions, certificationRequesterOptions, isEditMode,
    } = this.props;
    const {
      CertificationTypeId, CertificationSpec, CertificateDate, ExpirationDate, CertificationRequesterId, CertificationLink, isErrorForm,
    } = this.state;

    const minCertificateDate = moment('01/01/2000', 'DD/MM/YYYY');
    const maxCertificateExpirationDate = moment('31/12/2050', 'DD/MM/YYYY');

    return (
      <Dialog
        open
        aria-labelledby='responsive-dialog-title'
        className='certification__dialog-width'
      >
        <DialogTitle id='responsive-dialog-title'>
          <span className='show-dialog-title'>{!isEditMode ? 'Add Certificate' : 'Edit Certificate'}</span>
          <span className='dialog-cancel-svg' onClick={closeDialog}>
            <CustomIcon iconName='confirmation-dialog-cross' />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className='certificate-modal-row'>
            <Select
              formClassName='step__form'
              selectClassName='certification-step__select certification-step__select-padding'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Certification type'
              value={CertificationTypeId}
              placeholder='Choose Certification type'
              name='CertificationTypeId'
              onChange={this.handleChangeData}
              selectOptions={certificatesOptions}
              isOptionObject
              isRequired
              inputProps={{
                name: 'CertificationTypeId',
                id: 'CertificationTypeId',
              }}
            />
            <TextField
              formClassName='step__form margin-left-10'
              inputClassName='certification-step__select certification-step__text'
              id='CertificationSpecialization'
              label='Certification specialization'
              placeholder='Add specialization'
              maxlength='100'
              name='CertificationSpec'
              value={CertificationSpec}
              onChange={this.handleChangeData}
              isRequired
            />
          </div>
          <div className='certificate-modal-row'>
            <Select
              formClassName='step__form'
              selectClassName='certification-step__select certification-step__select-padding'
              optionsClassName='option'
              clearClassName='svg_select_center'
              label='Certification requester'
              value={CertificationRequesterId}
              placeholder='Choose Certification requester'
              name='CertificationRequesterId'
              onChange={this.handleChangeData}
              selectOptions={certificationRequesterOptions}
              isOptionObject
              showClear
              clearValue={this.clearCertificationRequesterId}
              inputProps={{
                name: 'CertificationRequesterId',
                id: 'CertificationRequesterId',
              }}
            />
            <DatePicker
              formClassName='step__form svg-calendar-add-info margin-left-10'
              datePickerClassName='certification-step__select certification-step__text'
              placeholder='Certificate since'
              label='Certificate since'
              value={CertificateDate}
              onChange={this.handleChangeDate}
              minVal={minCertificateDate}
              isRequired
              name='CertificateDate'
              isStartDate
              onError={this.checkForm}
            />
          </div>
          <div className='certificate-modal-row'>
            <DatePicker
              formClassName='step__form svg-calendar-add-info'
              datePickerClassName='certification-step__select certification-step__text'
              placeholder='Expiration Date'
              label='Expiration Date'
              value={ExpirationDate}
              onChange={this.handleChangeDate}
              disabled={CertificateDate === null}
              minVal={CertificateDate}
              maxVal={maxCertificateExpirationDate}
              name='ExpirationDate'
              isStartDate
              onError={this.setIsError}
              onBlur={this.handleChangeDate}
            />
            <TextField
              formClassName='step__form margin-left-10'
              inputClassName='certification-step__select certification-step__text'
              id='CertificationLink'
              label='Certification link'
              placeholder='Add link'
              maxlength='100'
              name='CertificationLink'
              value={CertificationLink}
              onChange={this.handleChangeData}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            className='button cancel confirmationdialog-button-size'
            text='Cancel'
          />
          <Button
            onClick={this.confirmAddCertificate}
            className={`button ${!isErrorForm ? 'next' : 'btn-disabled'} confirmationdialog-button-size`}
            text='Confirm'
          />
        </DialogActions>
      </Dialog>
    );
  }
}

CertificationModal.propTypes = {
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
  certificatesOptions: PropTypes.array,
  isEditMode: PropTypes.bool,
};

export default CertificationModal;
