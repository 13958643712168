import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ClientsListPage from './components/clients-list/clients-list-page';
import ClientDetailPage from './components/clients-detail/clients-details-page';
import CreateClient from './components/create-client/create-client';
import CreateContacts from './components/client-contacts/create-contacts';
import EditContacts from './components/client-contacts/edit-contacts';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { resetListSettings } from './actions/clientsActions';
import './clients-page.css';

export class ClientsPage extends Component {
  componentWillUnmount() {
    const { resetListSettings } = this.props;
    resetListSettings();
  }

  ClientsList = (props) => {
    const { CanCreateClient, ManageClientContact } = this.props;
    return (
      <ClientsListPage
        {...props}
        CanCreateClient={CanCreateClient}
        ManageClientContact={ManageClientContact}
      />
    );
  };

  ClientDetail = (props) => {
    const { EditClientBasicInfo, ManageClientContact, CanCreateClient } = this.props;
    return (
      <ClientDetailPage
        {...props}
        EditClientBasicInfo={EditClientBasicInfo}
        ManageClientContact={ManageClientContact}
        CanCreateClient={CanCreateClient}
      />
    );
  };

  EditContacts = (props) => {
    const { ManageClientContact } = this.props;
    return (
      <EditContacts
        {...props}
        ManageClientContact={ManageClientContact}
      />
    );
  };

  render() {
    const { CanCreateClient, ManageClientContact } = this.props;
    return (
      <DocumentTitle title={applicationTitle.getTitile('clients')}>
        <Fragment>
          <Switch>
            <Route exact path='/clients' render={this.ClientsList} />
            {CanCreateClient ? <Route exact path='/clients/new' component={CreateClient} /> : null }
            <Route exact path='/clients/:id' render={this.ClientDetail} />
            {ManageClientContact ? <Route exact path='/clients/:id/addcontacts' component={CreateContacts} /> : null}
            <Route exact path='/clients/:id/editcontacts/:idClient' render={this.EditContacts} />
            <Redirect to='/clients' />
          </Switch>
        </Fragment>
      </DocumentTitle>
    );
  }
}

export default connect(null, { resetListSettings })(ClientsPage);
