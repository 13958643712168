import sortByAplhabet from 'utils/sortByAplhabet';

export const getCorrectManagersForMultiSuggest = (managers) => {
  if (managers.length) {
    return sortByAplhabet(managers).map(item => ({
      label: item.Name,
      value: item.Name,
      valueId: item.Id,
    }));
  }
};
