import React from 'react';
import {
  Field, reduxForm, destroy, change,
} from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '../components/button';
import { required } from 'services/redux-form-validators.js';
import history from 'utils/history';
import renderTextField from '../components/text-field';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import { regexForEmail } from 'utils/const-variable.js';
import { getCorrectSkype } from 'services/getCorrectSkype.js';
import { ContactsList } from 'pages/clients/components/common/contacts-list.js';

const defaultStyles = {
  container: 'redux-form__field-container standart-container-create_client',
  label: 'redux-form__label',
  field: 'field',
  fieldContent: 'field__view-content standart-content-padding',
  fieldError: 'field__error-container',
  fieldView: 'field__view-container',
};

const validateEmail = (value, values, props, field) => {
  const isEmailValid = regexForEmail.test(value);
  if (typeof value === 'undefined') {
    return false;
  }
  return required(isEmailValid, values, props, field, 'Incorrect Email');
};

const validateSkype = (value, values, props, field) => {
  if (typeof value === 'undefined') {
    return false;
  }
  const result = getCorrectSkype(value);
  return required(result.valid, values, props, field, 'Incorrect Skype');
};

const validateCell = (value) => {
  const isValidPhoneNumber = /^[.+](\d[ ]?)+(\s*)?$/;
  return value && !isValidPhoneNumber.test(value.trim()) ? 'Invalid Cell' : false;
};

class CreateClientStep2 extends React.Component {
  onSubmit = () => {
    const {
      destroy, onSubmit, data, location,
    } = this.props;
    onSubmit(data);
    history.push({
      pathname: '/clients',
      state: location.state,
    });
    destroy();
  }

  addContacts = () => {
    const { addContacts, data } = this.props;
    addContacts(data);
    this.clearAllFields();
  }

  clearAllFields = () => {
    const { change } = this.props;
    change('contact.FirstName', '');
    change('contact.LastName', '');
    change('contact.JobTitle', '');
    change('contact.Email', '');
    change('contact.Cell', '');
    change('contact.Skype', '');
  }

  render() {
    const {
      allData,
      onBack,
      contacts,
      removeContacts,
    } = this.props;
    return (
      <form className='redux-form' onSubmit={formHandleSubmit}>
        <div className='redux-form__row_client'>
          <Field
            name='contact.FirstName'
            component={renderTextField}
            label='First Name'
            placeholder='Add First Name'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
            validate={required}
            showAsterix
          />
          <Field
            name='contact.LastName'
            component={renderTextField}
            label='Last Name'
            placeholder='Add Last Name'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
            validate={required}
            showAsterix
          />
          <Field
            name='contact.JobTitle'
            component={renderTextField}
            label='Job Title'
            placeholder='Add Job title'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
          />
        </div>
        <div className='redux-form__row_client'>
          <Field
            name='contact.Email'
            component={renderTextField}
            label='Email'
            placeholder='Add Email'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
            validate={validateEmail}
          />
          <Field
            name='contact.Cell'
            component={renderTextField}
            label='Cell'
            placeholder='Must be started with +'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
            validate={validateCell}
          />
          <Field
            name='contact.Skype'
            component={renderTextField}
            label='Skype'
            placeholder='Add Skype'
            styles={defaultStyles}
            formClassName='edit-assignment__item-form assignment_text-form'
            inputClassName='edit-assignment__text-field'
            validate={validateSkype}
          />
        </div>
        <div className='create-client-button-contact-step2'>
          <Button
            onClick={this.addContacts}
            disabled={(allData && allData.syncErrors) || !allData}
            className={classNames('button next', { 'btn-disabled': (allData && allData.syncErrors) || !allData })}
            text='Add New'
          />
        </div>
        <ContactsList
          contacts={contacts}
          removeContacts={removeContacts}
        />
        <div className='redux-form__create-client--buttons-step2 buttons buttons_bottom'>
          <Button
            onClick={onBack}
            className='button cancel'
            text='Back'
          />
          <Button
            onClick={this.onSubmit}
            disabled={!contacts.length}
            className={classNames('button next', { 'btn-disabled': !contacts.length })}
            text='Apply'
          />
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    destroy: () => dispatch(destroy('CreateClient')),
    change: (field, value) => dispatch(change('CreateClient', field, value)),
  };
};

const mapStateDispatchToProps = store => {
  const isFormReducerExists = store.form.CreateClient && store.form.CreateClient.values;
  return {
    data: isFormReducerExists ? store.form.CreateClient.values : {},
    allData: isFormReducerExists ? store.form.CreateClient : {},
  };
};


export default connect(
  mapStateDispatchToProps,
  mapDispatchToProps,
)(reduxForm({
  form: 'CreateClient',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateClientStep2));
