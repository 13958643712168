import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from 'common/components/icon/Icon';
import './multi-select.css';
import { generateId } from 'services/id';
import { Select as MaterialSelect } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  showOptions = () => {
    const { showOptions } = this.state;
    this.setState({ showOptions: !showOptions });
  }

  onChange = (Id) => {
    const { onChange } = this.props;
    onChange(Id);
  }

  hideOptions = () => {
    this.setState({ showOptions: false });
  }

  clearValue = () => {
    const { clearValue } = this.props;
    clearValue();
  }

  closeOptionsOnTabClick = (event) => {
    const { tabId } = this.props;
    if (event.target.id === `select-${tabId}` && event.key === 'Tab') {
      this.showOptions();
    }
  }

  render() {
    const {
      title, options, selected, placeholder, className, isRequired, clearValue, onBlur, name, tabId, onMouseLeave,
      relcontClass = 'multi-select__relative-container', contClass = 'multi-select__container', optionContClass = '',
      disabled,
    } = this.props;
    const { showOptions } = this.state;
    const titleBySelectedOptions = selected.some(id => id === 'all') ? 'All' : selected.map(id => {
      const selectedOption = options.find(o => o.Id === id);
      return selectedOption && selectedOption.Name;
    }).join(', ');
    const fieldTitle = selected && selected.length && options.length
      ? titleBySelectedOptions
      : placeholder;
    return (
      <div className={relcontClass} onBlur={onBlur} onKeyDown={tabId && this.closeOptionsOnTabClick} onMouseLeave={onMouseLeave}>
        <FormControl disabled={disabled}>
          <div className={classNames(contClass, className)}>
            {
              title && (
                <div className='multi-select__label'>
                  <span>{title}</span>
                  {
                    isRequired && <span className='multi-select_is-required'> *</span>
                  }
                </div>
              )
            }
            <div
              className={classNames('multi-select__text-field', {
                'multi-select__disabled': disabled,
              })}
              onClick={!disabled && this.showOptions}
              title={fieldTitle}
            >
              <span
                className={classNames(
                  {
                    'multi-select__text': selected && !!selected.length,
                  },
                )}
              >
                {fieldTitle}
              </span>
              <CustomIcon iconName='multi-select-svg' />
            </div>
            {tabId ? <MaterialSelect
              className='multi-select__input-tabindex--not-display'
              onFocus={this.showOptions}
              inputProps={{
                name: tabId,
                id: `select-${tabId}`,
              }}
              value={selected}
            /> : null}
            <div
              className={classNames('multi-select__options-container', optionContClass, { 'multi-select__expanded': showOptions })}
              onMouseLeave={this.hideOptions}
              ref={e => this.container = e}
            >
              {
                options.map((option, i) => {
                  const id = generateId();
                  return (
                    <label key={i} className='checkbox-cont-list-item configuration-option'>
                      <input
                        id={id}
                        type='checkbox'
                        checked={selected && selected.includes(option.Id)}
                        onChange={this.onChange.bind(this, option.Id)}
                        name={name}
                        tabIndex={i}
                      />
                      <span className='configuration-option__checkbox' />
                      <label htmlFor={id} className='configuration-option__label'>
                        <span>{option.Name}</span>
                      </label>
                    </label>
                  );
                })
              }
            </div>
            {
              selected && selected.length && clearValue ? (
                <div className='clear-svg' onClick={this.clearValue}>
                  <CustomIcon iconName='cross' />
                </div>
              ) : null
            }
          </div>
        </FormControl>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.array,
  className: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  clearValue: PropTypes.func,
  name: PropTypes.string,
};

export default MultiSelect;
