import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from 'common/components/icon/Icon';
import moment from 'moment';

export const CellAuditWrapper = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isShowFinanceInfo: false,
      };
    }

    componentDidMount() {
      this.checkProperty();
    }

    componentDidUpdate(prevProps) {
      const { item } = this.props;
      if (prevProps.item.Property !== item.Property || prevProps.item.Entity !== item.Entity) {
        this.checkProperty();
      }
    }

    checkProperty = () => {
      const { item } = this.props;
      const isLink = item.Entity && (item.Entity.split('.SOWLink').length === 2
        || item.Entity.split('.POLink').length === 2 || item.Entity.split('.AmendmentLink').length === 2);
      const hidenProperty = ['RealizedRevenue', 'BudgetComments', 'Budget', 'rate ', 'rateOvertime',
        'ContractsAttachments', 'contractsAttachments'];
      this.setState({
        isShowFinanceInfo: !hidenProperty.includes(item.Property) && !isLink,
      });
    };

    render() {
      const { isShowFinanceInfo } = this.state;
      return <WrappedComponent
        {...this.props}
        isShowFinanceInfo={isShowFinanceInfo}
      />;
    }
  };
};

export class CellAuditEntityPath extends Component {
  render() {
    const {
      item, column, config, isShowFinanceInfo,
    } = this.props;
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        {(!config[column.Id].HasFinancialAccess || (config[column.Id].HasFinancialAccess && config[column.Id].permissions)
          || isShowFinanceInfo) ? <span>{`${item.Entity}${item.Property ? `.${item.Property}` : ''}`}</span>
          : <div className='audit-changes-date'>---</div>
        }
      </div>
    );
  }
}

CellAuditEntityPath.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export class CellAuditValues extends Component {
  checkDateOrEmptyField = (incomingData) => {
    if (moment(incomingData, 'MM/DD/YYYY').isValid() && incomingData.includes('/')) {
      return moment(incomingData, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }
    return String(incomingData);
  }

  render() {
    const {
      item, column, config, isShowFinanceInfo,
    } = this.props;
    return (
      <div className={classNames('c-list__item-column', column.className ? column.className : 'small-col')}>
        {(!config[column.Id].HasFinancialAccess || (config[column.Id].HasFinancialAccess && config[column.Id].permissions)
          || isShowFinanceInfo)
          ? (item.TypeOfChange && item.TypeOfChange.Name === 'changed'
            ? <div className='audit-changes-date'>
              <span title={this.checkDateOrEmptyField(item.PrevValue)}>{this.checkDateOrEmptyField(item.PrevValue)}</span>
              {<CustomIcon iconName='arrow-history' />}
              <span title={this.checkDateOrEmptyField(item.NewValue)}>{this.checkDateOrEmptyField(item.NewValue)}</span>
            </div> : <div className='audit-changes-date'>---</div>)
          : <div className='audit-changes-date'>---</div>
        }
      </div>
    );
  }
}

CellAuditValues.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
