export const GET_CLIENT_RATES_REPORTS = '@reports/GET_CLIENT_RATES_REPORTS';
export const SET_CLIENT_RATES_REPORTS = '@reports/SET_CLIENT_RATES_REPORTS';
export const SET_CLIENT_RATES_COUNTER = '@reports/SET_CLIENT_RATES_COUNTER';
export const SET_CLIENT_RATES_LIST_SETTINGS = '@reports/SET_CLIENT_RATES_LIST_SETTINGS';
export const RESET_CLIENT_RATES_LIST_SETTINGS = '@reports/RESET_CLIENT_RATES_LIST_SETTINGS';
export const SET_CLIENT_RATES_FILTERS_VALUE = '@reports/SET_CLIENT_RATES_FILTERS_VALUE';
export const RESET_CLIENT_RATES_FILTERS_VALUE = '@reports/RESET_CLIENT_RATES_FILTERS_VALUE';
export const CHANGE_CLIENT_RATES_COLUMNS_TO_DISPLAY = '@reports/CHANGE_CLIENT_RATES_COLUMNS_TO_DISPLAY';

export const getClientRatesReports = (filters, searchValue, take, skip, sortSettings) => ({
  type: GET_CLIENT_RATES_REPORTS,
  payload: {
    searchValue,
    filters,
    take,
    skip,
    sortSettings,
  },
});

export const setClientRatesReports = (reports) => ({
  type: SET_CLIENT_RATES_REPORTS,
  payload: reports,
});

export const setClientRatesCounter = (count) => ({
  type: SET_CLIENT_RATES_COUNTER,
  payload: count,
});
export const setClientRatesListSettings = (settings) => ({
  type: SET_CLIENT_RATES_LIST_SETTINGS,
  payload: settings,
});

export const resetClientRatesListSettings = () => ({
  type: RESET_CLIENT_RATES_LIST_SETTINGS,
});

export const setClientRatesFiltersValue = (filters) => ({
  type: SET_CLIENT_RATES_FILTERS_VALUE,
  payload: filters,
});
export const changeColumnsToDisplay = (columnsToDisplay) => ({
  type: CHANGE_CLIENT_RATES_COLUMNS_TO_DISPLAY,
  payload: { columnsToDisplay },
});
