import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './edit-associated-roles.css';
import { List } from 'common/components/list/list';
import { filterChoosenExtendRoles } from 'services/filterChoosenExtendRoles';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import CustomIcon from '../../../../common/components/icon/Icon';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { updateCheckedFlagOnRoles } from 'pages/projects/components/utils/updateCheckedFlagOnRoles';
import sortByAplhabet from 'utils/sortByAplhabet';

export class EditAssociatedRoles extends Component {
  constructor(props) {
    super(props);
    const { items } = this.props;
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Role Name', Id: 'RoleName', className: 'without-width-col',
        },
        {
          Name: 'Role Type', Id: 'Role', isObject: true,
        },
        {
          Name: 'Assignment', Id: 'Assignment',
        },
        {
          Name: 'Seniority Level', Id: 'SeniorityLevel', isObject: true,
        },
        {
          Name: 'SOW/PO Number', Id: 'SowPo',
        },
        {
          Name: 'Start Date', Id: 'StartDate', isDate: true,
        },
        {
          Name: 'End Date', Id: 'EndDate', isDate: true,
        },
        {
          Name: 'Status', Id: 'Status', isObject: true, className: 'small-col',
        },
      ],
      deepColumn: [
        {
          Name: 'Name', Id: 'Name', className: 'without-width-col', hasBadge: true,
        },
        {
          Name: 'Assignment', Id: 'Assignment',
        },
        {
          Name: 'StartDate', Id: 'StartDate', isDate: true,
        },
        {
          Name: 'EndDate', Id: 'EndDate', isDate: true,
        },
        {
          Name: 'Status', Id: 'Status', isObject: true, className: 'small-col',
        },
        {
          Name: 'Comment', Id: 'Comment', isInnerComment: true, deleteArrow: 'delete-arrow',
        },
      ],
      choosenRoles: items,
    };
  }

  componentDidMount() {
    this.sortRoles();
  }

  checkRole = (e, parent) => {
    const { choosenRoles } = this.state;
    let roles = choosenRoles;
    roles = filterChoosenExtendRoles(roles, e.target.value, parent, e.target.checked);
    this.setState({
      choosenRoles: roles,
    });
  };

  getEmployeeDataModel = (employee, backups) => {
    return {
      ...employee,
      isValidEmployee: true,
      backupEmployees: backups,
      createdOnFrontSide: false,
      checked: employee.checked,
    };
  }

  getArrayAllAssignmentForRole = (activeAssigment, allAssigment) => {
    const arrayWithoutActiveAssignment = allAssigment.map((elem) => {
      const assignment = activeAssigment.find((item) => item.Id === elem.Id);
      return assignment || elem;
    });
    return arrayWithoutActiveAssignment;
  }

  applyResult = () => {
    const { apply, allRoles, idContract } = this.props;
    const { choosenRoles } = this.state;
    const allRolesForContract = allRoles.reduce((acc, item) => {
      const activeRole = choosenRoles.find((role) => role.Id === item.Id);
      if (item.SowPoId !== idContract) {
        return acc;
      }
      if (!activeRole) {
        acc.push(item);
        return acc;
      }
      acc.push({
        ...activeRole,
        Employees: this.getArrayAllAssignmentForRole(activeRole.Employees, item.Employees),
      });
      return acc;
    }, []);
    const roles = allRolesForContract.map((role) => ({
      ...role,
      Employees: role.Employees.reduce((assignedEmployees, employee, index, employees) => {
        if (!employee.PrimaryAssignmentId) {
          const backups = employees.reduce((backups, backup) => {
            backup.PrimaryAssignmentId === employee.Id && backups.push(this.getEmployeeDataModel(backup, []));
            return backups;
          }, []);
          assignedEmployees.push(this.getEmployeeDataModel(employee, backups));
        }
        return assignedEmployees;
      }, []),
    }));
    apply(roles);
  }

  onChangeCheckecRole = (idRole, parentId) => {
    const { startDateContract, endDateContract } = this.props;
    this.setState((state) => {
      const roles = updateCheckedFlagOnRoles(state.choosenRoles, idRole, parentId, startDateContract, endDateContract, true);
      return {
        choosenRoles: roles,
      };
    });
  }

  sortRoles = () => {
    const { choosenRoles } = this.state;
    const sortRolesArray = choosenRoles.map((role) => ({
      ...role,
      Employees: sortByAplhabet(role.Employees).reduce((acc, elem) => {
        if (elem.Role !== 'Primary') {
          return acc;
        }
        acc.push(elem);
        const shadowAssignmentsForEmployee = role.Employees.filter(i => i.Role === 'Shadow' && i.PrimaryAssignmentId === elem.Id);
        if (shadowAssignmentsForEmployee.length) {
          acc.push(...shadowAssignmentsForEmployee);
        }
        return acc;
      }, []),
    }));
    this.setState({
      choosenRoles: sortRolesArray,
    });
  };

  render() {
    const {
      columnsToDisplay, deepColumn, choosenRoles,
    } = this.state;
    const {
      closeModal,
    } = this.props;
    return (
      <div className='shadow-container-edit-associated-roles'>
        <div className='wrapper-edit-associated-roles'>
          <Scrollbars
            autoHideDuration={2000}
            autoHideTimeout={300}
            hideTracksWhenNotNeeded
          >
            <div className='edit-associated-roles-cross'>
              <CustomIcon iconName='cross' className='cross-associated' onClick={closeModal} />
            </div>
            <h1>Update Roles/Employees Dates</h1>
            <p>Please select the Roles and Employees to match the updated dates or statuses of the Document.</p>
            <div className='edit-associated-roles-items'>
              <List
                columnsToDisplay={columnsToDisplay}
                items={choosenRoles}
                dropdownList={{
                  columnsToDisplay: deepColumn,
                  items: choosenRoles,
                  property: 'Employees',
                  getDeeper: true,
                  hideDots: true,
                  withoutSort: true,
                  showCheckboxes: true,
                  dontShowOptions: true,
                  config: {
                    'Status': {
                      cell: CustomStatusCell,
                    },
                  },
                }}
                defaultOpenDropDown
                hideDots
                dontShowOptions
                showCheckboxes
                onClickCheckbox={this.onChangeCheckecRole}
                config={{
                  'Status': {
                    cell: CustomStatusCell,
                  },
                }}
              />
            </div>
            <div className='edit-associated-roles-buttons'>
              <div className='buttons buttons_bottom associated-roles-buttons'>
                <div />
                <div className='right-side-buttons'>
                  <div className='button cancel' onClick={closeModal}>
                    <span>Cancel</span>
                  </div>
                  <div
                    onClick={this.applyResult}
                    className={classNames('button next', { 'btn-disabled': false })}
                  >
                    <span>Apply</span>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

EditAssociatedRoles.propTypes = {
  items: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
};
