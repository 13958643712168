import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ToolBarCheckbox extends Component {
  render() {
    const { header, onChange, value } = this.props;
    return (
      <label className='checkbox-cont-list-item configuration-option'>
        <input
          id={header.Id}
          type='checkbox'
          value={value}
          defaultChecked={header.isSelected}
          onChange={onChange}
        />
        <span className='configuration-option__checkbox' />
        <label htmlFor={header.Id} className='configuration-option__label'>
          <span>{header.Name}</span>
        </label>
      </label>
    );
  }
}

ToolBarCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
};
