export const SET_AUTHDATA = '@auth/SET_AUTHDATA';
export const GET_TOKEN = '@auth/GET_TOKEN';
export const LOGOUT = '@auth/LOGOUT';
export const GET_DEPARTMENTS = '@auth/GET_DEPARTMENTS';
export const SET_DEPARTMENTS = '@auth/SET_DEPARTMENTS';
export const CHANGE_VIEW_FINANC_INFO = '@auth/CHANGE_VIEW_FINANC_INFO';
export const IS_HAS_FINANCIAL_ACCESS = '@auth/IS_HAS_FINANCIAL_ACCESS';

export const setAuthData = ({ User, AuthToken, RefreshToken }) => ({
  type: SET_AUTHDATA,
  payload: {
    user: User,
    authToken: AuthToken,
    refreshToken: RefreshToken,
  },
});

export const logout = () => ({
  type: LOGOUT,
  payload: {
    user: null,
    refreshToken: null,
    authToken: null,
  },
});

export const getToken = () => ({
  type: GET_TOKEN,
});

export const getDepartments = () => ({
  type: GET_DEPARTMENTS,
});

export const setDepartments = (departments) => ({
  type: SET_DEPARTMENTS,
  payload: {
    departments,
  },
});

export const changeViewFinancInfo = (isView) => ({
  type: CHANGE_VIEW_FINANC_INFO,
  payload: isView,
});

export const setFinancialAccess = (isHas) => ({
  type: IS_HAS_FINANCIAL_ACCESS,
  payload: isHas,
});
