import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { sortFilterList } from 'common/components/list/sortList';
import { updateEditAssignment } from '../../actions/roleModalActions';
import { getAllRoles } from '../../actions/projectsActions';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import './role-modal.css';
import ProjectEditAssignment from 'common/components/redux-form/project-edit-assignment-form/project-edit-assignment-form';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { formHandleSubmit } from 'utils/formHandleSubmit';

export class EditAssignment extends React.Component {
  constructor(props) {
    super(props);
    const { ManageProjectRoleAssignments } = this.props;
    this.state = {
      EmployeeId: '',
      EmployeeName: '',
      EmployeeStartDate: null,
      EmployeeEndDate: null,
      EmployeeAssignment: '',
      RoleType: '',
      Status: {},
      EmployeeComment: '',
      employeeAssignmentIsUnvalid: false,
      isModalFieldsRequired: false,
      ManageProjectRoleAssignments,
      primaryEmpl: '',
      showConfirmationDialog: false,
      confirmationDialogCallback: null,
    };
  }

  componentDidMount() {
    const { getAllRoles, assignment } = this.props;
    this.getAssignmentData();
    assignment && assignment.projectId && getAllRoles(assignment.projectId);
  }

  componentDidUpdate(prevProps) {
    const { assignment } = this.props;
    if (prevProps.assignment !== assignment) {
      this.getAssignmentData();
    }
  }

  setPrimaryEmployee = () => {
    const { employees, assignment } = this.props;
    const employee = employees.find(empl => empl.Id === assignment.PrimaryAssignmentId);
    this.setState({
      primaryEmpl: employee ? employee.Name : '',
    });
  }

  getAssignmentData = () => {
    const { assignment } = this.props;
    this.setState({
      EmployeeId: assignment.EmployeeId,
      EmployeeName: assignment.Name,
      EmployeeStartDate: assignment.StartDate,
      EmployeeEndDate: assignment.EndDate,
      EmployeeAssignment: assignment.Assignment,
      EmployeeComment: assignment.Comment,
      RoleType: assignment.Role,
      Status: assignment.Status,
    }, () => {
      this.setPrimaryEmployee();
    });
  }

  handleApplyModal = (data, cb) => {
    const {
      location, projectTeams, assignment,
    } = this.props;
    const id = location.pathname.split('/')[5];
    const isTeamMember = projectTeams.some(team => {
      return (
        team.Members
        && team.Members.some(member => (member.Id === id) && (member.Status && member.Status.Id !== data.Status.Id))
      )
      || team.SecondInCommand && (team.SecondInCommand.Id === assignment.EmployeeId) && (data.Status.Id !== 2);
    });
    if (isTeamMember) {
      this.setState({
        showConfirmationDialog: true,
        confirmationDialogCallback: () => this.applyModal(data, cb),
      });
    } else {
      this.applyModal(data, cb);
    }
  }

  applyModal = (data, cb) => {
    const {
      assignment, updateEditAssignment, isOnlyActiveRoles, location,
    } = this.props;
    const updateData = {
      projectId: assignment.projectId,
      roleId: assignment.roleId,
      assignmentId: location.pathname.split('/')[5],
      assignment: {
        Assignment: data.EmployeeAssignment,
        StartDate: data.EmployeeStartDate,
        EndDate: data.EmployeeEndDate,
        Status: data.Status.Id,
        Comment: data.EmployeeComment,
      },
    };
    updateEditAssignment(updateData, !isOnlyActiveRoles);
    cb();
  }

  handleCloseDialog = () => {
    this.setState({
      showConfirmationDialog: false,
      confirmationDialogCallback: null,
    });
  }

  handleConfirmDialog = () => {
    const { confirmationDialogCallback } = this.state;
    confirmationDialogCallback && confirmationDialogCallback();
    this.handleCloseDialog();
  }

  render() {
    const {
      EmployeeId,
      EmployeeName,
      EmployeeStartDate,
      EmployeeEndDate,
      EmployeeAssignment,
      RoleType,
      Status,
      EmployeeComment,
      employeeAssignmentIsUnvalid,
      isModalFieldsRequired,
      ManageProjectRoleAssignments,
      primaryEmpl,
      showConfirmationDialog,
    } = this.state;
    const { roleEmployeeStatus, location } = this.props;
    return (
      <div className='page-container page-block'>
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          hideTracksWhenNotNeeded
        >
          <DocumentTitle title={applicationTitle.getTitile('editProjectEmployees')} />
          <div className='form-block  '>
            {showConfirmationDialog ? (
              <ConfirmationDialog
                dialogHeader='Warning'
                dialogTitle='If you change the status of an assignment, it will be removed from teams. Are you sure?'
                closeButtonTitle='No'
                confirmButtonTitle='Yes'
                closeDialog={this.handleCloseDialog}
                confirmDialog={this.handleConfirmDialog}
              />)
              : null
            }
            <ProjectEditAssignment
              data={{
                EmployeeId,
                EmployeeName,
                EmployeeStartDate,
                EmployeeEndDate,
                EmployeeAssignment,
                RoleType,
                Status,
                EmployeeComment,
                employeeAssignmentIsUnvalid,
                isModalFieldsRequired,
                ManageProjectRoleAssignments,
                primaryEmpl,
              }}
              handleSubmit={formHandleSubmit}
              isEditable={!ManageProjectRoleAssignments}
              roleEmployeeStatus={sortFilterList(roleEmployeeStatus)}
              RoleType={RoleType}
              location={location}
              onSubmit={this.handleApplyModal}
            />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

EditAssignment.propTypes = {
  employees: PropTypes.array,
  roleEmployeeStatus: PropTypes.array,
};

export default connect(store => {
  return {
    assignment: store.roleModalReducer.editAssignment,
    employees: store.roleModalReducer.employees,
    roleEmployeeStatus: store.roleModalReducer.employeeStatus,
    readOnly: store.userRoleReducer.readOnly,
    isOnlyActiveRoles: store.showRoles.isOnlyActiveRoles,
    projectTeams: store.projectsReducer.projectTeams.Teams,
  };
}, {
  updateEditAssignment,
  getAllRoles,
})(EditAssignment);
