import moment from 'moment';
import { PROJECT_ROLES_EMPLOYEES_PRIMARY, PROJECT_ROLES_EMPLOYEES_SHADOW } from './const-variable';

const sortArrays = (array) => {
  return array.sort((a, b) => a.Name > b.Name
    ? 1
    : (a.Name === b.Name ? 0 : -1));
};

export const getAssignmentsWithShadows = (assignments) => assignments.reduce((assignmentsWithShadows, primary) => {
  if (!primary.PrimaryAssignmentId) {
    const ShadowAssignments = assignments.filter(shadow => primary.AssignmentKey === shadow.PrimaryAssignmentId);
    assignmentsWithShadows.push({
      ...primary,
      ShadowAssignments,
    });
  }

  return assignmentsWithShadows;
}, []);

export function getAssignmentModel(employee) {
  return {
    employeeId: employee.Id,
    employeeFullName: employee.Name,
    allocation: employee.Assignment,
    startDate: moment(employee.StartDate).format('YYYY-MM-DD HH:mm'),
    endDate: moment(employee.EndDate).format('YYYY-MM-DD HH:mm'),
    status: employee.StatusId,
    comment: employee.Comment,
    primaryAssignmentId: employee.PrimaryAssignmentId,
    ShadowAssignments: employee.ShadowAssignments && employee.ShadowAssignments.length ? employee.ShadowAssignments.map(getAssignmentModel) : [],
  };
}

export const sortRoleEmployees = (employees) => {
  let sortEmployeeArray = {};
  sortEmployeeArray = {
    Employees: sortArrays(employees).reduce((acc, elem) => {
      if (elem.Role !== PROJECT_ROLES_EMPLOYEES_PRIMARY) {
        return acc;
      }
      acc.push(elem);
      const shadowAssignmentsForEmployee = employees.filter(i => i.Role === PROJECT_ROLES_EMPLOYEES_SHADOW && i.PrimaryAssignmentId === elem.Id || i.PrimaryAssignmentId === elem.AssignmentKey);
      if (shadowAssignmentsForEmployee.length !== 0) {
        acc.push(...shadowAssignmentsForEmployee);
      }
      return acc;
    }, []),
  };
  return sortEmployeeArray.Employees;
};

export const sortRoleEmployeesCreateProject = (employees) => {
  let sortEmployeeArray = {};
  sortEmployeeArray = {
    Employees: sortArrays(employees).reduce((acc, elem) => {
      if (elem.Role !== PROJECT_ROLES_EMPLOYEES_PRIMARY) {
        return acc;
      }
      acc.push(elem);
      const shadowAssignmentsForEmployee = employees.filter(i => i.Role === PROJECT_ROLES_EMPLOYEES_SHADOW && i.PrimaryAssignmentId === elem.Id || i.PrimaryAssignmentId === elem.AssignmentKey);
      if (shadowAssignmentsForEmployee.length !== 0) {
        acc.push(...shadowAssignmentsForEmployee);
      }
      return acc;
    }, []),
  };
  return sortEmployeeArray.Employees;
};
