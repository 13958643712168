export const SHOW_ADMIN_MODAL = '@admin-modal/SHOW_ADMIN_MODAL';
export const CLOSE_ADMIN_MODAL = '@admin-modal/CLOSE_ADMIN_MODAL';
export const GET_INFO_FOR_ADMIN = '@admin-modal/GET_INFO_FOR_ADMIN';
export const SET_INFO_FOR_ADMIN = '@admin-modal/SET_INFO_FOR_ADMIN';

export const setDataForManageUsers = (employees = [], employeesRoles = [], securityRoleSubstitutesConfig = []) => ({
  type: SET_INFO_FOR_ADMIN,
  payload: {
    employees,
    employeesRoles,
    securityRoleSubstitutesConfig,
  },
});

export const showModal = (modalType) => ({
  type: SHOW_ADMIN_MODAL,
  payload: {
    modalType,
  },
});

export const closeModal = () => ({
  type: CLOSE_ADMIN_MODAL,
});

export const getInfoForAdmin = () => ({
  type: GET_INFO_FOR_ADMIN,
});
